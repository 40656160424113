import { useCallback, useEffect, useState } from 'react';
import { StepProps, Steps } from 'antd';

import { IStateData } from 'features/Contract/interfaces';
import { getIsDisableModal, getStepStatus } from 'utils/steps';
import { stateCode } from 'constants/stateCode';

import StepWithDate from '../StepsDescription/StepWithDate';
import StepWithStatus from '../StepsDescription/StepWithStatus';
import StepWithFile from '../StepsDescription/StepWithFile';
// import StepWithApprove from '../StepsDescription/StepWithApprove';
import StepWithApproveFile from '../StepsDescription/StepWithApproveFile';
import StepWithLink from '../StepsDescription/StepWithLink';
import StepWithApprovedDate from '../StepsDescription/StepWithApprovedDate';
import StepWithEmailFile from '../StepsDescription/StepWithEmailFile';

type ContractStepsListProps = {
  stepsData?: IStateData[];
  showModal: Function;
  conFormPublicUrl: string | undefined;
};

const ContractStepsList = ({ stepsData, showModal, conFormPublicUrl }: ContractStepsListProps) => {
  const [stepItems, setStepItems] = useState<StepProps[] | undefined>(undefined);

  const populateStepsItemData = useCallback(() => {
    let stepsItems: StepProps[] | undefined = [];

    if (stepsData) {
      for (let i = 0; i < stepsData.length; i++) {
        let item = stepsData[i];
        const isDisable = getIsDisableModal(item.isCurrentState, item.isCompleteState);
        const _status = getStepStatus(item.isCurrentState, item.isCompleteState);

        let _item: StepProps = {
          title: `${item.stateName}`,
          status: _status,
          disabled: isDisable,
          onClick: isDisable ? undefined : () => showModal(i),
        };

        switch (i) {
          case stateCode.step3:
            _item['description'] = <StepWithApprovedDate stepData={item} />;
            break;
          case stateCode.step8:
          case stateCode.step13:
            _item['description'] = <StepWithDate stepData={item} />;
            break;
          case stateCode.step4:
            _item['description'] = <StepWithStatus stepData={item} />;
            break;
          case stateCode.step5:
          case stateCode.step7:
          case stateCode.step9:
          case stateCode.step11:
          case stateCode.step14:
          case stateCode.step16:
            _item['description'] = <StepWithFile stepData={item} isDisable={isDisable} />;
            break;
          case stateCode.step10:
            _item['description'] = (
              <StepWithApproveFile
                stepData={item}
                isDisable={isDisable}
                isShowApproveStatus={true}
                dateLabel="วันที่บันทึก"
              />
            );
            break;
          case stateCode.step15:
            _item['description'] = (
              <StepWithApproveFile stepData={item} isDisable={isDisable} dateLabel="วันที่บันทึก" />
            );
            break;
          case stateCode.step17:
            _item['description'] = (
              <StepWithLink stepData={item} conFormPublicUrl={conFormPublicUrl} />
            );
            break;
          case stateCode.step1:
          case stateCode.step2:
            _item['description'] = <div style={{ marginBottom: 15 }} />;
            break;
          case stateCode.step6:
          case stateCode.step12:
            _item['description'] = <StepWithEmailFile isDisable={isDisable} />;
            break;
          default:
            break;
        }

        stepsItems.push(_item);
      }
    }
    return stepsItems;
  }, [stepsData, showModal, conFormPublicUrl]);

  useEffect(() => {
    const stepsItems = populateStepsItemData();
    setStepItems(stepsItems);
  }, [populateStepsItemData]);

  return (
    <div className="content-box" style={{ marginLeft: 40 }}>
      {stepItems?.length === 0 && <p>สถานะของสัญญายังไม่ถูกสร้าง กรุณาตรวจสอบข้อมูลอีกครั้ง</p>}
      <Steps direction="vertical" items={stepItems} />
    </div>
  );
};

export default ContractStepsList;
