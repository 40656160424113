import axiosInstance from 'utils/axios';
import { CurrencyData, FacilityData, ServiceData, ServiceTypeData, UnitData } from './interface';

// NOTE: Currencies
export const listAllCurrencies = () => {
  return axiosInstance.get(`/master/currencies/all`);
};
export const createNewCurrency = (data: CurrencyData) => {
  return axiosInstance.post(`/master/currencies/create`, data);
};
export const updateCurrency = (currencyId: number | string, data: CurrencyData) => {
  return axiosInstance.put(`master/currencies/${currencyId}/update`, data);
};

// NOTE: Units
export const listAllUnits = () => {
  return axiosInstance.get(`/master/unit/all`);
};
export const createNewUnit = (data: UnitData) => {
  return axiosInstance.post(`/master/unit/create`, data);
};
export const updateUnit = (unitId: number | string, data: UnitData) => {
  return axiosInstance.put(`/master/unit/${unitId}/update`, data);
};

// NOTE: Facilities
export const listAllFacilities = () => {
  return axiosInstance.get(`/master/facilities/all`);
};
export const createNewFacility = (data: FacilityData) => {
  return axiosInstance.post(`/master/facilities/create`, data);
};
export const updateFacility = (facilityId: number | string, data: FacilityData) => {
  return axiosInstance.put(`/master/facilities/${facilityId}/update`, data);
};

// NOTE: ServiceTypes
export const listAllServiceTypes = () => {
  return axiosInstance.get(`/master/service-type/all`);
};
export const createNewServiceType = (data: ServiceTypeData) => {
  return axiosInstance.post(`/master/service-type/create`, data);
};
export const updateServiceType = (serviceTypeId: number | string, data: ServiceTypeData) => {
  return axiosInstance.put(`/master/service-type/${serviceTypeId}/update`, data);
};

// NOTE: Services
export const listAllServices = () => {
  return axiosInstance.get(`/master/service/all`);
};
export const createNewService = (data: ServiceData) => {
  return axiosInstance.post(`/master/service/create`, data);
};
export const updateService = (serviceId: number | string, data: ServiceData) => {
  return axiosInstance.put(`/master/service/${serviceId}/update`, data);
};
