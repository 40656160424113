import { FormInstance } from 'antd';
import { useState } from 'react';
import { IOperators } from '../interfaces';

const useModal = (formOperators: FormInstance<any>) => {
  const [isOpenModalOperators, setIsOpenModalOperators] = useState<boolean>(false);
  const [recordOperators, setRecordOperators] = useState<IOperators>();
  function onOpenModalOperators(record: IOperators) {
    setRecordOperators(record);
    setIsOpenModalOperators(true);
  }
  const onCancelModalOperators = () => {
    setRecordOperators(undefined);
    formOperators.resetFields();
    setIsOpenModalOperators(false);
  };

  return { onOpenModalOperators, onCancelModalOperators, isOpenModalOperators, recordOperators };
};

export default useModal;
