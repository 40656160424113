import { Space, Row, Col } from 'antd';
import { IStepDescription } from './interfaces';
// import { dayJsformatDateToBCYear } from 'utils/date';

interface IStepWithLink extends IStepDescription {
  conFormPublicUrl: string | undefined;
}

const StepWithLink = ({ stepData, conFormPublicUrl }: IStepWithLink) => {
  return (
    <div>
      {/* <Row>
        <Col>
          <Space>
            <label>วันที่ทำรายการ :</label>
            <span>
              {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
            </span>
          </Space>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <Space>
            <label>Link Web เผยแพร่ข้อเสนอ :</label>
            <span>{conFormPublicUrl || ' - '}</span>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithLink;
