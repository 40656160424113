import { FormInstance } from 'antd';
import { useState } from 'react';
const useSearch = (formSearch: FormInstance<any>, setError: any) => {
  const [groupCode, setGroupCode] = useState<string | undefined>();
  const [userLogin, setUserLogin] = useState<string | undefined>();
  const onSearchUser = (values: any) => {
    setGroupCode(
      values.usergroup ? (values.usergroup.length === 0 ? undefined : values.usergroup) : undefined
    );
    setUserLogin(
      values.passUser ? (values.passUser.length === 0 ? undefined : values.passUser) : undefined
    );
    if (
      !values.usergroup ||
      values.usergroup.length === 0 ||
      !values.passUser ||
      values.passUser.length === 0
    )
      setError(undefined);
  };
  const onReset = () => {
    setGroupCode(undefined);
    setUserLogin(undefined);
    setError(undefined);
    formSearch.resetFields();
  };

  return { onSearchUser, groupCode, userLogin, onReset };
};

export default useSearch;
