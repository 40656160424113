import { Form, FormInstance } from 'antd';

import useDialogAlert from 'hooks/useDialogAlert';
import useModal from 'hooks/useModal';
import { TApiResponse, useApi } from 'hooks/useApi';

import { confirmOfferDoc } from 'api/offer';

import { dayJsformatDateObjToString } from 'utils/date';

import { IOfferData, IOfferServes } from '../interfaces';
import { OFFER_TYPE_RDRO, OFFER_TYPE_RISO } from 'constants/offerFormType';
import useValidation from './useValidation';
import { OFFER_EDIT_TAB } from '../constant';

type UseConfirmDocModalValue = {
  confirmDocFormRef: FormInstance<any>;
  isModalOpen: boolean;
  isShowPublicUrlInput: boolean;
  isConfirmingDoc: boolean;
  onHandleCancel: () => void;
  showModal: () => void;
  onConfirmDoc: () => void;
  onSubmitConfirmDoc: () => void;
};

type UseConfirmDocModalProps = {
  offerFormType: string;
  offerId: number | string | undefined;
  editOfferFormRef: FormInstance<any>;
  currentOfferServices: IOfferServes[];
  validateRequiredFiles: () => number;
  setOfferData: React.Dispatch<React.SetStateAction<IOfferData>>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
};

const useConfirmDocModal = ({
  offerFormType,
  offerId,
  editOfferFormRef,
  currentOfferServices,
  validateRequiredFiles,
  setOfferData,
  setActiveTab,
}: UseConfirmDocModalProps): UseConfirmDocModalValue => {
  const [confirmDocFormRef] = Form.useForm();
  const { error, success } = useDialogAlert();
  const { makeRequest, loading }: TApiResponse = useApi();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const { validateOfferByOfferType } = useValidation();

  const isShowPublicUrlInput =
    offerFormType === OFFER_TYPE_RISO || offerFormType === OFFER_TYPE_RDRO;

  const onHandleCancel = () => {
    confirmDocFormRef.resetFields();
    handleCancel();
  };

  const onConfirmDoc = async () => {
    try {
      await validateOfferByOfferType({
        offerType: offerFormType,
        editOfferFormRef,
        currentOfferServices,
      });
      const isDocValid = validateRequiredFiles();
      if (isDocValid !== -1)
        return error({
          header: 'กรุณาแนบไฟล์ที่จำเป็นให้ครบ',
          sub: '',
        });
      else {
        showModal();
      }
    } catch (e: any) {
      setActiveTab(OFFER_EDIT_TAB);
      return error({
        header: e.message || 'กรุณาตรวจสอบข้อมูลให้ครบถ้วน',
        sub: '',
      });
    }
  };

  const onSubmitConfirmDoc = async () => {
    const data = confirmDocFormRef.getFieldsValue();
    data.offerFormDate = dayJsformatDateObjToString(data?.offerFormDate);
    if (offerId) {
      try {
        const response = await makeRequest(() => confirmOfferDoc({ offerId, data }));
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' }, () => {
          setOfferData(response?.data?.data);
          confirmDocFormRef.resetFields();
          handleCancel();
        });
      } catch (e) {
        console.error(e);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    } else {
      return error({
        header: 'ไม่พบ offerId',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  return {
    confirmDocFormRef,
    isModalOpen,
    isShowPublicUrlInput,
    isConfirmingDoc: loading,
    onHandleCancel,
    showModal,
    onConfirmDoc,
    onSubmitConfirmDoc,
  };
};

export default useConfirmDocModal;
