import { Input, InputProps } from 'antd';
import React from 'react';

const InputText = (props: InputProps) => {
  return <Input className="inputText" {...props} />;
};

export const InputPassword = (props: InputProps) => {
  return <Input.Password className="inputText" {...props} />;
};

export default InputText;
