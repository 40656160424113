import dayjs from 'dayjs';
import {
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  DATE_FORMAT_WITH_TIME,
  DATE_FORMAT_WITH_TIME_12PM,
  YEAR_FORMAT,
} from 'constants/date';
// import th from 'dayjs/locale/th';
// import moment from 'moment';

var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);
const DayJSWithTimeZone = dayjs as any;

const asianBangkok = 'Asia/Bangkok';

export const dayJsformatDateObjToString12PM = (dateObject: Date) => {
  if (!dateObject) return '';
  return dayjs(dateObject).format(DATE_FORMAT_WITH_TIME_12PM);
};

export const dayJsformatDateObjToString = (dateObject: Date) => {
  if (!dateObject) return '';
  return dayjs(dateObject).format(DATE_FORMAT_WITH_TIME);
};

export const dayJsformatDateToBCYear = (dateString: string | null | undefined) => {
  if (!dateString) return '';
  return dayjs(dateString).add(543, 'y').format(DATE_FORMAT);
};

// export const dayJsformatDateToBCYearWithAsianTimeZone = (dateString: string) => {
//   return DayJSWithTimeZone(dateString).tz(asianBangkok).add(543, 'y').format(DATE_FORMAT);
// };

export const getJSDateObj = (dateString: string | null | undefined) => {
  if (!dateString) return null;
  return new Date(dateString);
};

// export const formatDateToBCYear = (dateString: string) => {
//   return moment(dateString).utcOffset('+0000').add(543, 'y').format(DATE_FORMAT);
// };

export const formatDateToBCYearFull = (dateString: string | null | undefined) => {
  if (!dateString) return '';
  return dayjs(dateString).add(543, 'y').format(DATE_FORMAT_FULL);
};

export const formatDateToOnlyBCYear = (dateString: string | null | undefined) => {
  if (!dateString) return '';
  return dayjs(dateString).add(543, 'y').format(YEAR_FORMAT);
};

// export const removeTZFromDateStr = (dateString: string) => {
//   if (!dateString) return '';
//   return dateString?.replace(/Z$/, '');
// };

// export const createDateObjFromStrWOTimeZone = (dateString: string) => {
//   // FIXME: use getJSDateObj instead
//   if (!dateString) return null;
//   return new Date(removeTZFromDateStr(dateString));
// };

export const getStringDateFromDayJs = (day: dayjs.Dayjs): string => {
  // NOTE: Only accept local time (+07:00), not UTC
  return dayjs(day).format();
};

export const getYearFromDateString = (dateString: string | null | undefined) => {
  if (!dateString) return '';
  const date = dayJsformatDateToBCYear(dateString);
  const year = date.split('/')[2];
  return year;
};

export const convertDataFormDatePickerToString = (value: any): string | null => {
  if (!value) {
    return null;
  }
  if (value instanceof Date) {
    return dayjs(value).format();
  } else {
    return dayjs(value.toDate()).format();
  }
};

export const convertDateFromApiToDatePickerWithTimeZoneAsian = (value: string): Date | null => {
  if (!value) {
    return null;
  }
  return DayJSWithTimeZone(value).tz(asianBangkok).toDate();
};

export const convertMonthsToYearMonths = (months: number) => {
  if (months <= 0)
    return {
      years: 0,
      months: 0,
    };
  return {
    years: Math.floor(months / 12),
    months: months % 12,
  };
};

export const convertToMonths = (years: number = 0, months: number = 0) => {
  return years * 12 + months;
};

export const calculateDuration = (startDate: Date, endDate: Date) => {
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  let yearDiff = endYear - startYear;
  let monthDiff = endMonth - startMonth;

  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }

  return { years: yearDiff, months: monthDiff };
};
