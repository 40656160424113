/* eslint-disable */

import { Col, Form, Row, Select } from 'antd';
import style from './DisagreementForm.module.css';
import InputText from '../../../../components/InputTextComponent';
import { ThaiDatepickerComponent } from '../../../../components';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Operator } from '../../../../api/operator/interface';
import { useApi } from '../../../../hooks/useApi';
import { getAllOperators } from '../../../../api/operator';
import { Dayjs } from 'dayjs';

export interface Option {
  value: number;
  label: string;
  disabled: boolean;
}

export interface DisagreementFormData {
  id: number;
  formNo: string;
  formDate: Dayjs | Date;
  petitionerId: number;
  accusedId: number;
  topic: string;
}

interface DisagreementFormInterface {
  data?: DisagreementFormData;
  onFormDataChange: (data: DisagreementFormData) => void;
}

const DisagreementForm = (props: DisagreementFormInterface): JSX.Element => {
  const [operators, setOperators] = useState<Operator[]>([]);
  const [listAccused, setListAccused] = useState<Option[]>([]);
  const [listPetitioner, setListPetitioner] = useState<Option[]>([]);
  const { makeRequest: getOperatorsRequest } = useApi();
  const [form] = useForm<DisagreementFormData>();

  useEffect(() => {
    updateDropDownList();
  }, [operators]);

  const updateDropDownList = useCallback(() => {
    const formValue = form.getFieldsValue();
    if (formValue.petitionerId && formValue.accusedId) {
      const newListAccused = operators.map(i => {
        return {
          value: i.id,
          label: i.operName,
          disabled: i.id === formValue.petitionerId,
        };
      });
      const newListPetitioner = operators.map(i => {
        return {
          value: i.id,
          label: i.operName,
          disabled: i.id === formValue.accusedId,
        };
      });

      setListPetitioner(newListPetitioner);
      setListAccused(newListAccused);

    } else if (formValue.petitionerId) {
      const newList = operators.map(i => {
        return {
          value: i.id,
          label: i.operName,
          disabled: i.id === formValue.petitionerId,
        };
      });
      setListAccused(newList);

    } else if (formValue.accusedId) {
      const newList = operators.map(i => {
        return {
          value: i.id,
          label: i.operName,
          disabled: i.id === formValue.accusedId,
        };
      });

      setListPetitioner(newList);

    } else {
      const mapOperators: Option[] = operators?.map(i => ({
        value: i.id,
        label: i.operName,
        disabled: false,
      }));

      setListAccused(mapOperators);
      setListPetitioner(mapOperators);
    }
  }, [operators]);

  const onFormDataChange = () => {
    props.onFormDataChange(form.getFieldsValue());
  };

  const getOperators = useCallback(async () => {
    return await getOperatorsRequest(() => {
      return getAllOperators();
    });
  }, []);

  useEffect(() => {
    getOperators().then(res => {
      const data = res.data.data;
      setOperators(data);
      updateDropDownList();
    });
  }, []);

  useEffect(() => {
    form.setFields([
      {
        name: 'id',
        value: props.data?.id,
      },
      {
        name: 'formNo',
        value: props.data?.formNo,

      },
      {
        name: 'formDate',
        value: props.data?.formDate || new Date(),
      },
      {
        name: 'petitionerId',
        value: props.data?.petitionerId,
      },
      {
        name: 'accusedId',
        value: props.data?.accusedId,
      },
      {
        name: 'topic',
        value: props.data?.topic,
      },
    ]);
  }, [props.data]);

  return (
    <>
      <>
        <Form
          name='basic'
          form={form}
          initialValues={{ remember: true }}
          autoComplete='off'
          requiredMark={false}
          onValuesChange={onFormDataChange}
        >
          <div className='form-manage-rao-row-col'>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6} className={style.col}>
                <div>เลขที่หนังสือนำส่ง :</div>
              </Col>
              <Col className='gutter-row' span={6}>
                <div>
                  <Form.Item
                    name='formNo'

                  >
                    <InputText type='text' />
                  </Form.Item>
                </div>
              </Col>
              <Col span={6} className={style.col}>
                <div>วันที่หนังสือนำส่งตั้งแต่ :</div>
              </Col>
              <Col className='gutter-row' span={6}>
                <div>
                  <Form.Item name='formDate'>
                    <ThaiDatepickerComponent
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6} className={style.col}>
                <div>ผู้ร้อง :</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>
                  <Form.Item name='petitionerId'>
                    <Select
                      style={{ width: '100%' }}
                      placeholder='กรุณาเลือกผู้ร้อง'
                      showSearch
                      filterOption={(input, option) => {
                        return (option?.label.indexOf(input) || -1) >= 0;
                      }}
                      onSelect={() => {
                        updateDropDownList();
                      }}
                      options={listPetitioner}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6} className={style.col}>
                <div>ผู้ถูกร้อง :</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>
                  <Form.Item name='accusedId'>
                    <Select
                      style={{ width: '100%' }}
                      placeholder='กรุณาเลือกผู้ถูกร้อง'
                      showSearch
                      filterOption={(input, option) => {
                        return (option?.label.indexOf(input) || -1) >= 0;
                      }}
                      onSelect={() => {
                        updateDropDownList();
                      }}
                      options={listAccused}
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={6} className={style.col}>
                <div>เรื่อง :</div>
              </Col>
              <Col className='gutter-row' span={18}>
                <div>
                  <Form.Item
                    name='topic'

                  >
                    <InputText type='text' />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </>
    </>
  );
};
export default DisagreementForm;
