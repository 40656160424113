import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listServicesByOfferType } from 'api/offer/services';
import { onFilterOption } from 'utils/select';

type OfferServiceDropdownProps = {
  offerType: string;
  onChangeOption: (option: any) => void;
  servType?: number | undefined | null;
  label?: string;
  isRequired?: boolean;
};

const OfferServiceDropdown = ({
  onChangeOption,
  label,
  offerType,
  servType,
  isRequired = true,
}: OfferServiceDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listServicesByOfferType({ offerType, servType }));
  }, [makeRequest, offerType, servType]);

  return (
    <>
      <Form.Item
        colon
        name="raoServId"
        label={label ? label : 'บริการ'}
        wrapperCol={{ span: 24 }}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'ประเภทบริการ'}` }]
            : []
        }
      >
        <Select
          labelInValue
          showSearch
          filterOption={onFilterOption}
          allowClear={isRequired ? false : true}
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          onChange={(_, options) => onChangeOption(options)}
          options={
            data
              ? data.map((item: any) => ({
                  value: item.raoServId,
                  label: item.raoServName,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OfferServiceDropdown;
