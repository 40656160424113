import { Col, Row } from 'antd';

import RDROFacilityTable from './RDROFacilityTable';
import RDROActionButton from './RDROActionButton';
import RDROFacilityModal from './RDROFacilityModal';

const RDROFacility = () => {
  return (
    <>
      <RDROFacilityModal />
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col span={24}>
          <RDROActionButton />
        </Col>
        <Col span={24}>
          <RDROFacilityTable />
        </Col>
      </Row>
    </>
  );
};

export default RDROFacility;
