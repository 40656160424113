import { useEffect, useState } from 'react';

import { HeaderTitleComponent } from 'components';
import { ICreateContractTab } from 'features/Contract/interfaces';

import ContractForm from '../ContractForm';
import ContractServicesFacilities from '../ContractServicesFacilities';

import { contractTypeTh } from './utils/contractTypeTh';

const CreateContractTab = ({
  contractFormRef,
  contractData,
  offerFormType,
  isDisabled,
  contractType,
  isChortorEdit = false,
}: ICreateContractTab) => {
  const [selectedOfferFormId, setSelectedOfferFormId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (contractData) {
      setSelectedOfferFormId(contractData.offerFormId);
    }
  }, [contractData]);

  return (
    <>
      <ContractForm
        contractFormRef={contractFormRef}
        contractData={contractData}
        offerFormType={offerFormType}
        onChangeOfferFormId={setSelectedOfferFormId}
        isDisabled={isDisabled}
        isChortorEdit={isChortorEdit}
        selectedOfferFormId={selectedOfferFormId}
      />
      <HeaderTitleComponent style={{ margin: '15px 0px' }}>
        {contractTypeTh(contractType)}
      </HeaderTitleComponent>
      <ContractServicesFacilities selectedOfferFormId={selectedOfferFormId} />
    </>
  );
};

export default CreateContractTab;
