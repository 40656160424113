import { Col, Table, UploadFile } from 'antd';

import { FileListDataType } from 'features/Contract/interfaces';

import { getFileTableColumn } from './columns';

type OfferUploadTableProps = {
  isLoading: boolean;
  isDisabled: boolean;
  fileList: FileListDataType[];
  onTableUploadFile: Function;
  offerId: number | string | undefined;
  onRemoveRecordFile: (record: any) => void;
  onDownloadRecordFile: (record: any) => void;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any> | undefined>>;
};

const OfferUploadTable = ({
  isLoading,
  isDisabled,
  fileList,
  onTableUploadFile,
  offerId,
  onRemoveRecordFile,
  onDownloadRecordFile,
  setCurrentUploadFile,
}: OfferUploadTableProps) => {
  return (
    <Col span={24}>
      <Table
        bordered
        size="small"
        loading={isLoading}
        columns={getFileTableColumn({
          isDisabled,
          onRemoveRecordFile,
          onDownloadRecordFile,
          onTableUploadFile,
          setCurrentUploadFile,
          offerId,
        })}
        dataSource={fileList}
        rowKey={(value) => {
          return String(value.index);
        }}
        pagination={false}
      />
    </Col>
  );
};

export default OfferUploadTable;
