import { useEffect } from 'react';
import { Col, Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractOfficer } from 'api/contract';

import { ThaiDatepickerComponent } from 'components';
import { getName } from 'utils/string';

type AssigneesDropdownProps = {
  isRequiredAssignees?: boolean;
};

const AssigneesDropdown = ({ isRequiredAssignees = false }: AssigneesDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listContractOfficer());
  }, [makeRequest]);

  return (
    <>
      <Col span={24}>
        <Form.Item
          colon
          labelAlign="right"
          label="ผู้ได้รับมอบหมาย"
          name="assignees"
          labelCol={{ span: 6 }}
          rules={
            isRequiredAssignees ? [{ required: true, message: 'กรุณาเลือกผู้ได้รับมอบหมาย' }] : []
          }
        >
          <Select
            allowClear
            loading={loading}
            mode="multiple"
            placeholder="-- กรุณาเลือกผู้ได้รับมอบหมาย --"
            options={
              data
                ? data.map((item: any) => ({
                    value: item.officerId,
                    label: getName(item),
                  }))
                : []
            }
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          colon
          labelAlign="right"
          label="วันที่มอบหมายงาน"
          name="assignDate"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: 'กรุณากรอกวันที่มอบหมายงาน' }]}
        >
          {/*@TODO: แก้ไขให้ใช้ ThaiDatepickerComponent แทน เพือให้ user นำ data เก่าเข้าได้*/}
          {/*<ThaiDatepickerComponent minDate={new Date()} />*/}
          <ThaiDatepickerComponent />
        </Form.Item>
      </Col>
    </>
  );
};

export default AssigneesDropdown;
