/* eslint-disable */

import { Checkbox, Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { useApi } from 'hooks/useApi';
import { Disagreement } from 'api/disagreement/interface';
import { useForm } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormFilesUpload from './components/Form-Files-Upload';
import InputText from 'components/InputTextComponent';
import { validateFormNotEmpty } from 'utils/form';
import { getAccusedForm, updateAccusedForm, UpdateDisagreementState } from 'api/disagreement';
import TextArea from 'antd/es/input/TextArea';
import { convertDataFormDatePickerToString, convertDateFromApiToDatePickerWithTimeZoneAsian } from 'utils/date';

interface Form10Prop {
  isCompleted: boolean;
  disagreement?: Disagreement,
  completeDate?: string,
  stepState: number,
  onActionSuccess: () => void
}

const Form10 = ({ isCompleted, stepState, disagreement, completeDate, onActionSuccess }: Form10Prop): JSX.Element => {
  const [form] = useForm();
  const [disableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableNextState, setDisableNextState] = useState(true);
  const [validateRules] = useState({
    discussFormNo: true,
    discussFormDate: true,
    receiveDiscussAccusedFormDate: true,
  });
  const { makeRequest: UpdateAccusedFormRequest } = useApi();
  const { makeRequest: UpdateStateFormRequest } = useApi();
  const { makeRequest: GetDisagreementForm10Request } = useApi();

  const validateForm = (): void => {
    const result = Object.entries(validateRules).every(([key, value]) => {
      if (!value) {
        return true;
      } else {
        return validateFormNotEmpty(form.getFieldValue(key));
      }
    });
    setDisableNextState(!result);
  };

  useEffect(() => {
    validateForm();
  }, [validateRules]);

  useEffect(() => {
    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: new Date(),
        },
      ]);
    }
  }, [disagreement, completeDate]);

  useEffect(() => {
    if (disagreement) {
      GetDisagreementForm10Request(async () => {
        const res = await getAccusedForm(disagreement?.formId, stepState);
        const result = res.data.data;
        if (result) {
          form.setFields([
            {
              name: 'discussFormDate',
              value: convertDateFromApiToDatePickerWithTimeZoneAsian(result.discussFormDate),
            },
            {
              name: 'receiveDiscussAccusedFormDate',
              value: convertDateFromApiToDatePickerWithTimeZoneAsian(result.receiveDiscussAccusedFormDate),
            },
            {
              name: 'discussFormNo',
              value: result.discussFormNo,
            },
            {
              name: 'remark',
              value: result.remark,
            },
          ]);
        }
        validateForm();
        return res;
      });
    }
  }, [disagreement]);

  const [checkbox, setCheckBox] = useState(false);

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (disagreement) {
      await UpdateAccusedFormRequest(async () => {
        return await updateAccusedForm({
          formId: disagreement.formId,
          stateId: stepState,
          completeDate: convertDataFormDatePickerToString(form.getFieldValue('completeDate')),
          discussFormDate: convertDataFormDatePickerToString(form.getFieldValue('discussFormDate')),
          discussFormNo: form.getFieldValue('discussFormNo'),
          remark: form.getFieldValue('remark'),
          receiveDiscussAccusedFormDate: convertDataFormDatePickerToString(form.getFieldValue('receiveDiscussAccusedFormDate')),
        }).catch(() => setLoading(false));
      });
      if (checkbox) {
        setLoading(true);
        await UpdateStateFormRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId).catch(() => {
            setLoading(false);
          });
        });
      }
    }
    setLoading(false);
    onActionSuccess();
  };
  return <>
    <Form form={form} onValuesChange={() => {
      validateForm();
    }}>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='เลขที่หนังสือคำโต้แย้ง' name='discussFormNo'>
            <InputText
              style={{ width: '300px' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='วันที่ได้รับคำโต้แย้ง' name='discussFormDate'>
            <ThaiDatepickerComponent
              style={{
                width: '240px',
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item labelCol={{
            span: 16,
          }} labelAlign={'right'} label='วันที่ได้รับคำร้อง คำโต้แย้ง และเอกสารหลักฐานที่ถูกต้องครบถ้วน'
                     name='receiveDiscussAccusedFormDate'>
            <ThaiDatepickerComponent
              style={{
                width: '240px',
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item labelCol={{
            span: 4,
          }} labelAlign={'right'} label='รายละเอียดเอกสาร' name='remark'>
            <TextArea style={{ width: '300px' }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <div style={{
      marginTop: '24px',
    }}>
      <FormFilesUpload stepState={stepState} disagreementFormId={disagreement?.formId || 0} state={10} />
    </div>
    <Form form={form} onValuesChange={() => {
      validateForm();
    }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox disabled={isCompleted || disableNextState} checked={checkbox}
                    onChange={onCheckBoxChange}>ยืนยันการพิจารณา</Checkbox>
        </Col>
        <Col span={8}>
          <Form.Item labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }
      }>
        <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onSubmit}>
          บันทึกรายการ
        </ButtonComponent>
      </div>
    </Form>
  </>;
};

export default Form10;
