import { useEffect } from 'react';
import { Form, Row, Col, Input, Select } from 'antd';

import { formLayout } from 'constants/form';
import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';
import { IStepModalForm } from './interfaces';
import { ThaiDatepickerComponent } from 'components';

const StepFormWithStatus = ({ form, stepData, isDisable, onUpdateOfferState }: IStepModalForm) => {
  const onFinish = (values: any) => {
    let date = new Date(values.actionDate);
    let data = {
      formStateId: stepData?.formStateId,
      stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: values.actionCode,
      actionName: values.actionName.label ? values.actionName.label : values.actionName,
      actionDate: dayJsformatDateObjToString(date),
      approvedNo: '',
      approvedDate: null,
      isCompleteState: false,
      isCurrentState: true,
    };
    onUpdateOfferState(data);
  };

  useEffect(() => {
    if (stepData) {
      form.setFieldsValue({
        stateName: stepData.stateName,
        actionDate: stepData.actionDate ? getJSDateObj(stepData.actionDate) : undefined,
        actionCode: stepData.actionCode,
        actionName: stepData.actionName,
      });
    }
  }, [form, stepData]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      name="stepModalForm"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะข้อเสนอ" name="stateName">
            <Input disabled size="large" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label="วันที่รับเรื่อง"
            name="actionDate"
            rules={[{ required: true, message: 'กรุณาเลือกวันที่รับเรื่อง' }]}
          >
            <ThaiDatepickerComponent disabled={isDisable} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label="สถานะข้อเสนอ"
            name="actionCode"
            rules={[{ required: true, message: 'กรุณาเลือกสถานะข้อเสนอ' }]}
          >
            <Select
              size="large"
              placeholder="-- กรุณาระบุ --"
              onChange={(_, option) => {
                form.setFieldValue('actionName', option);
              }}
              disabled={isDisable}
              options={[
                {
                  value: '2',
                  label: '2-อยู่ระหว่างจัดทำหนังสือให้ส่งข้อเสนอฯ',
                },
                {
                  value: '3',
                  label: '3-อยู่ระหว่างจัดทำหนังสือให้ปรับปรุงข้อเสนอฯ',
                },
                { value: '4', label: '4-กรณีข้อมูลครบถ้วนถูกต้อง' },
              ]}
            />
          </Form.Item>
          <Form.Item name="actionName" hidden>
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default StepFormWithStatus;
