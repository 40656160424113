import type { ColumnsType } from 'antd/es/table';

import { IOfferServes } from 'features/Offer/interfaces';

import ServiceDropdown from '../ServiceDropdown';

type ColumnsProps = {
  servicesData: IOfferServes[];
  isLoadingServices: boolean;
};

export const getColumns = ({ servicesData, isLoadingServices }: ColumnsProps) => {
  const columns: ColumnsType<IOfferServes> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '100px',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'บริการ',
      dataIndex: 'raoServId',
      key: 'raoServId',
      align: 'center',
      render: (value, record) => {
        return (
          <ServiceDropdown
            record={record}
            serviceId={value}
            serviceOptions={servicesData}
            isLoadingServices={isLoadingServices}
          />
        );
      },
    },
  ];
  return columns;
};
