import { Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { IProvince } from 'types/Authorization';
import { onFilterOption } from 'utils/select';

type ProvincesDropdownProps = {
  onChangeProvince: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
  isLoadingProvinces: boolean;
  provinces: IProvince[];
};

const ProvincesDropdown = ({
  onChangeProvince,
  isLoadingProvinces,
  provinces,
}: ProvincesDropdownProps) => {
  return (
    <Form.Item
      colon
      label="จังหวัด"
      name="conProvinceId"
      rules={[{ required: true, message: 'กรุณาเลือกจังหวัด' }]}
    >
      <Select
        showSearch
        loading={isLoadingProvinces}
        placeholder="-- กรุณาระบุ --"
        optionFilterProp="children"
        filterOption={onFilterOption}
        onChange={onChangeProvince}
        options={
          provinces
            ? provinces.map((item: any) => ({
                value: item.provinceId,
                label: item.provinceName,
              }))
            : []
        }
      />
    </Form.Item>
  );
};

export default ProvincesDropdown;
