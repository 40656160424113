import { SearchUserProps } from 'features/ManageUser/interfaces';
import SearchUserForm from './components/SearchUserForm';

const SearchUser = ({ onSearchUser, onReset, formSearch, mappingGroupData }: SearchUserProps) => {
  const onFinish = (values: any) => {
    onSearchUser(values);
    //   let _values = values;
    //   _values.startDate = dayJsformatDateObjToString(values.startDate);
    //   _values.endDate = dayJsformatDateObjToString(values.endDate);
    //   onSearchOffer(_values);
  };

  return (
    <div className="content-box">
      <SearchUserForm
        onFinish={onFinish}
        onReset={onReset}
        form={formSearch}
        mappingGroupData={mappingGroupData}
      />
    </div>
  );
};

export default SearchUser;
