import { Col, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import UploadButton from 'features/Contract/components/UploadButton';
import PrimaryButton from 'components/PrimaryButton';

import DocTypeDropdown from '../DoctypeDropdown';

type DocTypeUploadButtonProps = {
  offerId: number | string | undefined;
  isOpenFileDialog: boolean;
  isDisabledUploadFile: boolean;
  offerType: string | undefined;
  setSelectedDocTypeOption: React.Dispatch<React.SetStateAction<string | undefined>>;
  onUploadFile: (offerId: number | string | undefined) => Promise<void>;
  onValidateDocType: () => void;
  setIsOpenFileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any> | undefined>>;
};

const DocTypeUploadButton = ({
  offerId,
  offerType,
  isOpenFileDialog,
  isDisabledUploadFile,
  setSelectedDocTypeOption,
  onUploadFile,
  onValidateDocType,
  setIsOpenFileDialog,
  setCurrentUploadFile,
}: DocTypeUploadButtonProps) => {
  return (
    <>
      <Col span={15}>
        <DocTypeDropdown
          isDisabled={isDisabledUploadFile}
          setSelectedDocTypeOption={setSelectedDocTypeOption}
          setIsOpenFileDialog={setIsOpenFileDialog}
          offerType={offerType}
        />
      </Col>
      <Col span={7}>
        <UploadButton
          handleUpload={() => onUploadFile(offerId)}
          setCurrentUploadFile={setCurrentUploadFile}
          isOpenFileDialog={isOpenFileDialog}
          isDisabled={isDisabledUploadFile}
        >
          <PrimaryButton
            icon={<UploadOutlined />}
            disabled={isDisabledUploadFile}
            onClick={() => {
              onValidateDocType();
            }}
          >
            อัพโหลดเอกสาร
          </PrimaryButton>
        </UploadButton>
      </Col>
    </>
  );
};

export default DocTypeUploadButton;
