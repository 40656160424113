import { useCallback } from 'react';
import { FormInstance } from 'antd';

import useDialogAlert from 'hooks/useDialogAlert';

import { composeCreateData, composeSaveData } from '../utils/composeData';
import { IContServesData, IFacilitiesType } from '../interfaces';
import { EDIT_PAGE, NEW_PAGE } from 'constants/page';

type TUseContract = {
  currentContServes: IContServesData[];
  pageFunction: string | undefined;
  contractFormRef: FormInstance<any>;
  currentContFacilities: IFacilitiesType[];
  user: any;
  offerFormType: string;
};

const useContract = ({
  currentContServes,
  pageFunction,
  contractFormRef,
  currentContFacilities,
  user,
  offerFormType,
}: TUseContract) => {
  const { error } = useDialogAlert();

  const onPrepareContractData = useCallback(() => {
    if (!user) {
      error({
        header: 'ไม่พบข้อมูลผู้ใช้งาน',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
      return;
    }

    let data = {};

    switch (pageFunction) {
      case EDIT_PAGE:
        data = composeSaveData({
          formData: contractFormRef.getFieldsValue(true),
          currentContServes,
          currentContFacilities,
        });
        return data;
      case NEW_PAGE:
        data = composeCreateData({
          formData: contractFormRef.getFieldsValue(true),
          ownerId: user?.referenceNo,
          offerFormType,
          currentContServes,
          currentContFacilities,
        });
        return data;
      default:
        return data;
    }
  }, [
    contractFormRef,
    currentContFacilities,
    currentContServes,
    error,
    offerFormType,
    pageFunction,
    user,
  ]);

  return { onPrepareContractData };
};

export default useContract;
