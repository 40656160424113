import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';

import buttonCSS from 'styles/components/button.module.css';
type Props = {
  form: FormInstance<any>;
  onFinishOfferContent: (values: any) => void;
  onFinishFailedOfferContent: (errorInfo: any) => void;
  onModalCancel: () => void;
  operatorList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  listAssignees:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
};

const ModalAssignmentContent = (props: Props) => {
  const { form, onFinishOfferContent, onFinishFailedOfferContent, onModalCancel, listAssignees } =
    props;
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  return (
    <Content>
      {' '}
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishOfferContent}
        onFinishFailed={onFinishFailedOfferContent}
        autoComplete="off"
        requiredMark={false}
        {...layout}
        labelWrap
      >
        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
          <Col span={16}>
            <Form.Item
              colon
              labelAlign="right"
              label="ผู้ได้รับมอบหมาย :"
              name="officerIds"
              rules={[{ required: true, message: 'กรุณาเลือกผู้ได้รับมอบหมาย' }]}
            >
              <Select
                placeholder="-- กรุณาระบุ --"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                mode="multiple"
                allowClear
                options={listAssignees}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 20 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              //   icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ยืนยัน
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              //   icon={<ClearOutlined />}
              onClick={onModalCancel}
              size="large"
              htmlType="reset"
            >
              ยกเลิก
            </Button>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default ModalAssignmentContent;
