import { ColProps, Form, Select } from 'antd';

type PublishLinkStatusDropdownProps = {
  name: string;
  labelCol?: ColProps | undefined;
};

const PublishLinkStatusDropdown = ({
  name,
  labelCol = undefined,
}: PublishLinkStatusDropdownProps) => {
  return (
    <>
      <Form.Item colon name={name} label="สถานะลิงก์เว็บ" labelCol={labelCol}>
        <Select
          allowClear
          placeholder="-- กรุณาระบุ --"
          options={[
            { value: true, label: 'ใช้งาน' },
            { value: false, label: 'ไม่ใช้งาน' },
          ]}
        />
      </Form.Item>
    </>
  );
};

export default PublishLinkStatusDropdown;
