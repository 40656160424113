import { ReactNode, useCallback } from 'react';
import { Spin, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/lib/layout/layout';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import { OFFER_ATTACHMENT_TAB, OFFER_EDIT_TAB, OFFER_REVIEW_TAB } from 'features/Offer/constant';

import EditOfferTab from '../EditOfferTab';
import OfferAttachmentTab from '../OfferAttachmentTab';

type EditOfferWrapperProps = {
  OfferReviewComponent?: ReactNode;
};

const EditOfferWrapper = ({ OfferReviewComponent }: EditOfferWrapperProps) => {
  const {
    activeTab,
    isNewPage,
    isLoadingOfferData,
    isConfirmingOffer,
    isLoadingOperatorInfo,
    isDisabledAttachmentTab,
    setActiveTab,
  } = useOfferDataContext();

  const getTabItems = useCallback(() => {
    let tabItems: TabsProps['items'] = [
      {
        key: OFFER_EDIT_TAB,
        label: `จัดทำข้อเสนอ`,
        children: <EditOfferTab />,
      },
      {
        key: OFFER_ATTACHMENT_TAB,
        label: `แนบเอกสาร`,
        disabled: isNewPage || isDisabledAttachmentTab,
        children: <OfferAttachmentTab />,
      },
    ];
    if (OfferReviewComponent) {
      tabItems = [
        {
          key: OFFER_REVIEW_TAB,
          label: `พิจารณาข้อเสนอ`,
          children: OfferReviewComponent,
        },
        ...tabItems,
      ];
    }
    return tabItems;
  }, [OfferReviewComponent, isNewPage, isDisabledAttachmentTab]);

  return (
    <div>
      <Spin
        tip="กำลังดำเนินการ..."
        spinning={isLoadingOfferData || isLoadingOperatorInfo || isConfirmingOffer}
      >
        <Content>
          <Tabs
            onChange={(activeKey) => {
              setActiveTab(activeKey);
            }}
            items={getTabItems()}
            activeKey={activeTab}
          />
        </Content>
      </Spin>
    </div>
  );
};

export default EditOfferWrapper;
