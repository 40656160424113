import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getCurrency } from 'api/master';
import { onFilterOption } from 'utils/select';

type CurrencyDropdownProps = {
  onChangeOption: (value: any) => void;
  label?: string;
  disabled?: boolean;
  isRequired?: boolean;
  labelCol?: any;
};

const CurrencyDropdown = ({
  onChangeOption,
  label,
  labelCol,
  disabled = false,
  isRequired = true,
}: CurrencyDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => getCurrency());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        name="currencyId"
        label={label ? label : 'สกุลเงิน'}
        labelCol={labelCol ? labelCol : { span: 10 }}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'สกุลเงิน'}` }]
            : undefined
        }
      >
        <Select
          labelInValue
          showSearch
          filterOption={onFilterOption}
          allowClear={isRequired ? false : true}
          loading={loading}
          disabled={disabled}
          placeholder="-- กรุณาระบุ --"
          onChange={(_, options) => onChangeOption(options)}
          options={
            data
              ? data.map((item: any) => ({
                  value: item.currencyId,
                  label: item.currencyName,
                  desc: item.currencyDesc,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default CurrencyDropdown;
