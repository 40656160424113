import { getStringOrEmpty } from 'utils/string';

export const LOCAL_ICBS_KEY = 'icbs';

export const APIS = {
  LoginUser: 'api/login',
  getThaiProvince: 'kongvut/thai-province-data/master/api_province_with_amphure_tambon.json',
  getListROA: '/offer',
  gitListROAId: '/offer',
  getoOperatorsApi: '/operators',
  getProvinces: '/master/provinces',
  getDistricts: '/master/provinces/',
  getSubdistricts: '/master/districts/',
  getRaoService: '/master/rao-service',
  getRaoCurrencies: '/master/currencies',
  getRaoUnit: '/master/offer-unit?raoServId=',
  getRaoFacilities: '/master/facilities',
  getServiceAnnounceRate: '/offer/RIO/serviceAnnounceRate',
  createRaoAccessOffer: '/offer/create',
  downloadRaoDoc: '/offer/',
  uploadAttachment: '/offer/',
  deleteFileRao: '/offer/attachment/',
  getListDocument: '/master/doc/doc-type',
  downloadFileRao: '/offer/attachment/',
  getListHist: '/hist/offer/',
  getListHistID: '/hist/previous-offer/',
};

export const HOST = {
  localUrl: getStringOrEmpty(process.env.REACT_APP_API_BASE_URL),
  urlThaiProvince: getStringOrEmpty(process.env.REACT_APP_API_Province_URL),
  productionUrl: getStringOrEmpty(process.env.REACT_APP_API_BASE_URL),
  url_ICDS_APP: getStringOrEmpty(process.env.REACT_APP_API_ICDS_APP_URL_SERVER),
  url_ICDS_APP_SERVER: getStringOrEmpty(process.env.REACT_APP_API_ICDS_APP_URL_SERVER),
  api_context: getStringOrEmpty(process.env.REACT_APP_API_ICDS_CONTEXT),
};
