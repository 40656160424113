import { Col, Form, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import useCancelContractModalContext from 'features/Contract/hooks/useCancelContractModalContext';
import { listCONTCancelDocType } from 'api/contract';

import PrimaryButton from 'components/PrimaryButton';
import DocTypeDropdown from '../DocTypeDropdown';
import UploadButton from '../UploadButton';
import UploadedFilesListTable from '../UploadedFilesListTable';

import { formLayout } from 'constants/form';

const UploadFilesSection = () => {
  const {
    isOpenFileDialog,
    isUploadingFile,
    isDeletingFile,
    uploadFormRef,
    conFormId,
    fileList,
    setDocId,
    setDocName,
    setIsOpenFileDialog,
    setSelectedDocTypeOption,
    setCurrentUploadFile,
    onUploadFile,
    onValidateDocType,
    onRemoveFile,
    downloadFile,
    handleUpload,
  } = useCancelContractModalContext();

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      name="uploadAttachmentForm"
      form={uploadFormRef}
    >
      <Row gutter={[16, 16]}>
        <Col span={15}>
          <DocTypeDropdown
            isDisabled={false}
            setDocId={setDocId}
            setDocName={setDocName}
            setIsOpenFileDialog={setIsOpenFileDialog}
            api={listCONTCancelDocType}
            setSelectedDocTypeOption={setSelectedDocTypeOption}
          />
        </Col>
        <Col span={7}>
          <UploadButton
            handleUpload={onUploadFile}
            setCurrentUploadFile={setCurrentUploadFile}
            isOpenFileDialog={isOpenFileDialog}
            isDisabled={false}
          >
            <PrimaryButton
              icon={<UploadOutlined />}
              disabled={false}
              onClick={() => {
                onValidateDocType();
              }}
            >
              อัพโหลดเอกสาร
            </PrimaryButton>
          </UploadButton>
        </Col>
        <Col span={24}>
          <UploadedFilesListTable
            isLoading={isUploadingFile || isDeletingFile}
            isDisabled={false}
            onRemoveFile={onRemoveFile}
            downloadFile={downloadFile}
            fileList={fileList}
            handleUpload={handleUpload}
            setCurrentUploadFile={setCurrentUploadFile}
            conFormId={conFormId}
          />
        </Col>
      </Row>
    </Form>
  );
};

export default UploadFilesSection;
