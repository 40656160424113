import { Row, Col, Form, Button } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';

import buttonCSS from 'styles/components/button.module.css';
import { dayJsformatDateObjToString12PM } from 'utils/date';

import Input from 'components/InputComponent';
import ThaiDatepickerComponent from 'components/ThaiDatepickerComponent';

import OfferTypeDropdown from '../OfferTypeDropdown';
import OperatorDropdown from '../OperatorDropdown';
import OfferStatusDropdown from '../OfferStatusDropdown';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

type SearchOfferProps = {
  onSearchOffer: Function;
};

const SearchOffer = ({ onSearchOffer }: SearchOfferProps) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    let _values = { ...values };
    _values.offerFormDateStart = _values.offerFormDateStart
      ? dayJsformatDateObjToString12PM(values.offerFormDateStart)
      : null;
    _values.offerFormDateEnd = _values.offerFormDateEnd
      ? dayJsformatDateObjToString12PM(values.offerFormDateEnd)
      : null;
    onSearchOffer(_values);
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="content-box">
      <Form
        {...layout}
        labelWrap
        size="large"
        name="searchOfferForm"
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <OfferTypeDropdown />
          </Col>
          <Col span={12}>
            <OperatorDropdown />
          </Col>
          <Col span={12}>
            <OfferStatusDropdown />
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="เลขที่หนังสือผู้ประกอบการ"
              name="offerFormNo"
              // rules={[{ required: true, message: 'กรุณากรอกเลขที่หนังสือผู้ประกอบการ' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่หนังสือผู้ประกอบการตั้งแต่"
              name="offerFormDateStart"
              // rules={[{ required: true, message: 'กรุณากรอกวันที่หนังสือผู้ประกอบการตั้งแต่' }]}
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่หนังสือผู้ประกอบการถึง"
              name="offerFormDateEnd"
              // rules={[{ required: true, message: 'กรุณากรอกวันที่หนังสือผู้ประกอบการถึง' }]}
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              onClick={onReset}
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchOffer;
