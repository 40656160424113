import { Col, Form, Input, Row } from 'antd';

const { TextArea } = Input;

const OperatorInfoForm = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item colon label="หน่วยงานภายใน/บุคคล" name="operName">
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="ชื่อ-นามสกุล"
          name="conName"
          rules={[{ required: true, message: 'กรุณากรอกชื่อ-นามสกุล' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="โทรศัพท์"
          name="conTel"
          rules={[{ required: true, message: 'กรุณากรอกโทรศัพท์' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item colon label="โทรสาร" name="conFax">
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="จดหมายอิเล็กทรอนิกส์"
          name="conMail"
          rules={[{ required: true, message: 'กรุณากรอกจดหมายอิเล็กทรอนิกส์' }]}
        >
          <TextArea />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default OperatorInfoForm;
