import React, { useCallback, useEffect, useState } from 'react';
import Search from './components/Search/Search';
import { useOutletContext } from 'react-router-dom';
import { Form } from 'antd';
import SearchResults from './components/SearchResults/SearchResults';
import useAuth from 'hooks/useAuth';

import { TApiResponse, useApi } from 'hooks/useApi';
import { getOperatorAll } from 'api/operator';
import { createOfferofficerCT, listOfferFormStates, listOfficerOffer } from 'api/officerCT';
import ModalOffer from './components/Modal/ModalOffer/ModalOffer';
import ModalHistory from 'components/ModalHistory/ModalHistory';
import { listAttachHistory } from 'api/attachHistory';
import useDialogAlert from 'hooks/useDialogAlert';
import { getListOfferAssignees } from 'api/assignTasks';
import { getOperators } from 'api/master';

type Props = {};

const ListOfficerOffer = (props: Props) => {
  const [form] = Form.useForm();
  const [formOffer] = Form.useForm();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { user } = useAuth();
  const [modalOpenOffer, setModalOpenOffer] = useState<boolean>(false);
  const [modalOpenHistory, setModalOpenHistory] = useState<boolean>(false);
  const [filterOffer, setFilterOffer] = useState<{
    offerFormNo: string | null;
    offerFormStateCode: string | null;
    offerFormTypeCode: string | null;
    taxId: string | null;
  }>({ offerFormNo: null, offerFormStateCode: null, offerFormTypeCode: null, taxId: null });
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  const {
    makeRequest: getListAssigneesRequest,
    data: assigneesList,
  }: // loading: assigneesListLoading,
  TApiResponse = useApi();
  const { makeRequest: getOperatorListRequest, data: operator }: TApiResponse = useApi();
  const { makeRequest: getListOfferFormStatesRequest, data: offerFormStates }: TApiResponse =
    useApi();
  const { makeRequest: getListAttachHistoryRequest, data: attachHistory }: TApiResponse = useApi();
  const { makeRequest: createOfferofficerCTequest }: TApiResponse = useApi();

  const [operatorList, setOperatorList] = useState<
    {
      value: any;
      label: any;
    }[]
  >();
  const [offerFormStatesList, setOfferFormStatesList] = useState<
    {
      value: any;
      label: any;
    }[]
  >();
  const [listAssignees, setListAssignees] = useState<
    {
      value: any;
      label: any;
    }[]
  >();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const { error: errorAlert, success, error } = useDialogAlert();

  useEffect(() => {
    setHeaderTitle('ค้นหาข้อเสนอโดย ชท.');
    // eslint-disable-next-line
  }, []);
  const getListAssignees = useCallback(() => {
    getListAssigneesRequest(() => getListOfferAssignees());
  }, [getListAssigneesRequest]);
  const getReviewOfferList = useCallback(() => {
    makeRequest(() =>
      listOfficerOffer(pageSize, currentPageNumber, user?.referenceNo, filterOffer)
    );
  }, [makeRequest, pageSize, currentPageNumber, filterOffer, user]);
  const getOperatorList = useCallback(() => {
    getOperatorListRequest(() => getOperatorAll());
  }, [getOperatorListRequest]);
  const getListOfferFormStates = useCallback(() => {
    getListOfferFormStatesRequest(() => listOfferFormStates());
  }, [getListOfferFormStatesRequest]);
  const getListAttachHistory = useCallback(
    (id: number | string) => {
      getListAttachHistoryRequest(() => listAttachHistory(id));
    },
    [getListAttachHistoryRequest]
  );

  useEffect(() => {
    if (user) {
      getReviewOfferList();
    }
    getOperatorList();
    getListOfferFormStates();
    getListAssignees();
  }, [getReviewOfferList, getOperatorList, getListOfferFormStates, user, getListAssignees]);
  useEffect(() => {
    if (assigneesList) {
      let arrayAssignees = [];
      for (let i = 0; i < assigneesList.length; i++) {
        const element = assigneesList[i];
        arrayAssignees.push({
          value: element.id,
          label: element.name,
        });
      }

      setListAssignees(arrayAssignees);
    }
  }, [operator, offerFormStates, assigneesList]);
  useEffect(() => {
    if (offerFormStates) {
      let arrayOfferFormStates = [];

      for (let i = 0; i < offerFormStates.length; i++) {
        const element = offerFormStates[i];
        arrayOfferFormStates.push({
          value: element.code,
          label: element.name,
        });
      }
      setOfferFormStatesList(arrayOfferFormStates);
    }
    if (operator) {
      let arrayOperator = [];
      for (let i = 0; i < operator.length; i++) {
        const element = operator[i];
        arrayOperator.push({
          value: element.taxId,
          label: element.operName,
        });
      }

      setOperatorList(arrayOperator);
    }
    if (operator) {
      let arrayOperator = [];
      for (let i = 0; i < operator.length; i++) {
        const element = operator[i];
        arrayOperator.push({
          value: element.taxId,
          label: element.operName,
        });
      }

      setOperatorList(arrayOperator);
    }
  }, [operator, offerFormStates]);

  useEffect(() => {
    if (attachHistory) {
      if (attachHistory.length === 0) {
        error({ header: 'ไม่มีประวัติไฟล์ข้อเสนอ', sub: '' });
      } else {
        setModalOpenHistory(true);
      }
    }
  }, [attachHistory, error]);
  const onCreateOfficer = useCallback(
    async (data: any) => {
      try {
        await createOfferofficerCTequest(() => createOfferofficerCT(data));
        getReviewOfferList();
        onModalCancel();
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
      } catch (errorInfo) {
        console.error(errorInfo);
        errorAlert({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [errorAlert, success, createOfferofficerCTequest, getReviewOfferList]
  );
  const onFinishSearch = (values: any) => {
    setFilterOffer({
      offerFormNo: values.clientNo === undefined ? null : values.clientNo,
      offerFormStateCode: values.offerStatus === undefined ? null : values.offerStatus,
      offerFormTypeCode: values.offerType === undefined ? null : values.offerType,
      taxId: values.client === undefined ? null : values.client,
    });
  };

  const onFinishFailedSearch = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onFinishOfferContent = async (values: any) => {
    if (operator) {
      console.log('operator', operator);
      const { data } = await getOperators(values.operators);

      console.log('filter', data);
      const obj = {
        operators: { ...data.data },
        offerFormType: values.offerType,
        officerIds: [...values.officerIds],
      };
      await onCreateOfficer(obj);
    }
  };

  const onFinishFailedOfferContent = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onModalOpenOffer = () => {
    setModalOpenOffer(true);
  };
  const onModalOpenHistory = (id: number | string) => {
    getListAttachHistory(id);
    // setModalOpenHistory(true);
  };
  const onModalHistoryCancel = () => {
    setModalOpenHistory(false);
  };
  const onModalCancel = () => {
    setModalOpenOffer(false);
  };
  return (
    <div>
      <Search
        form={form}
        onFinishSearch={onFinishSearch}
        onFinishFailedSearch={onFinishFailedSearch}
        operatorList={operatorList}
        offerFormStatesList={offerFormStatesList}
      />
      <SearchResults
        onModalOpenOffer={onModalOpenOffer}
        onModalOpenHistory={onModalOpenHistory}
        data={data}
        loading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        pageable={pageable}
      />
      <ModalOffer
        modalOpenOffer={modalOpenOffer}
        onModalCancel={onModalCancel}
        title="เลือกประเภทข้อเสนอ"
        width={1000}
        form={formOffer}
        onFinishFailedOfferContent={onFinishFailedOfferContent}
        onFinishOfferContent={onFinishOfferContent}
        operatorList={operatorList}
        listAssignees={listAssignees}
      />
      <ModalHistory
        modalOpenOffer={modalOpenHistory}
        onModalCancel={onModalHistoryCancel}
        title="ไฟล์จัดทำข้อเสนอ"
        width={700}
        attachHistory={attachHistory}
      />
    </div>
  );
};

export default ListOfficerOffer;
