// NOTE: [24 Nov 2023] config for ReviewPage
/*
  STATUS: 6, 12
    - Enable everything
  STATUS: OHTER THAN 6, 12
    - Hide ยืนยันการบันทึก, ยืนยันการจัดส่งเอกสาร button
    - Enable to edit
  STATUS: 17, 18 (Finished Status)
    - Hide every buttons except back button
*/

// NOTE: UPDATED [9 Jan 2024] config for ReviewPage
/*
  STATUS: 1 - 16
    - Hide ยืนยันการบันทึก, ยืนยันการจัดส่งเอกสาร button
    - Enable to edit
  STATUS: 17, 18 (Finished Status)
    - Hide every buttons except back button
    - Disable upload file
*/

const ALLOW_IMPROVE_STATUS: any = [];
const FINISHED_STATUS = ['17', '18'];

const isFinishedStatus = (offerFormStatus: string) => FINISHED_STATUS.includes(offerFormStatus);

const isNotAllowImproveStatus = (offerFormStatus: string) =>
  !ALLOW_IMPROVE_STATUS.includes(offerFormStatus);

const isHideConfirmOfferButton = (offerFormStatus: string) =>
  isNotAllowImproveStatus(offerFormStatus) || isFinishedStatus(offerFormStatus);

const isHideSaveOfferButton = isFinishedStatus;
const isDisabledUploadFile = isFinishedStatus;
const isDisabledAttachmentTab = false;
const isDisabled = isFinishedStatus;
const isHideConfirmDocButton = isHideConfirmOfferButton;
const isShowImproveOfferButton = false;

const configReviewPage = {
  isDisabledUploadFile,
  isDisabledAttachmentTab,
  isDisabled,
  isShowImproveOfferButton,
  isHideSaveOfferButton,
  isHideConfirmOfferButton,
  isHideConfirmDocButton,
};

export default configReviewPage;
