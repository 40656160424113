import { Col, Row } from 'antd';

import RIOServiceTable from '../RIOServiceTable';
import RIOServiceModal from '../RIOServiceModal';
import RIOServePayModal from '../RIOServePayModal';
import ServiceActionButton from '../ServiceActionButton';

const RIOService = () => {
  return (
    <>
      <RIOServiceModal />
      <RIOServePayModal />
      <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
        <Col span={24}>
          <ServiceActionButton />
        </Col>
        <Col span={24}>
          <RIOServiceTable />
        </Col>
      </Row>
    </>
  );
};

export default RIOService;
