import axiosInstance from 'utils/axios';

export interface OfferReportData {
  offerFormType?: string;
  startDate: string;
  endDate: string;
}

export interface ReportsQuery {
  pageSize: number;
  currentPageNumber: number;
  reportStartDate?: string;
  reportEndDate?: string;
  offerFormType?: string;
}

export interface ApprovedContractReportData {
  offerFormType: string;
  startDate?: string;
  endDate?: string;
}

// NOTE: Report 001 - จากเดิมชื่อว่า รายงานสรุปข้อมูลข้อเสนอ แก้ชื่อเป็น รายงานข้อมูลข้อเสนอที่ได้รับความเห็นชอบแล้ว
export const createOfferSummaryReports = (data: OfferReportData) => {
  return axiosInstance.post('report001/create', data);
};

export const listOfferSummaryReports = (query: ReportsQuery) => {
  return axiosInstance.get('report001', {
    params: query,
  });
};

export const getOfferSummaryReportByJobId = (jobId: string) => {
  return axiosInstance.get(`report001/${jobId}`);
};

export const downloadOfferSummaryReportByJobId = (jobId: string) => {
  return axiosInstance.get(`report001/${jobId}/download`, {
    responseType: 'blob',
  });
};

// NOTE: Report 002 - รายงานสถานะการพิจารณาข้อเสนอ
export const createOfferReviewStatusReport = (data: OfferReportData) => {
  return axiosInstance.post('report002/create', data);
};

export const listOfferReviewStatusReport = (query: ReportsQuery) => {
  return axiosInstance.get('report002', {
    params: query,
  });
};

export const getOfferReviewStatusReportByJobId = (jobId: string) => {
  return axiosInstance.get(`report002/${jobId}`);
};

export const downloadOfferReviewStatusReportByJobId = (jobId: string) => {
  return axiosInstance.get(`report002/${jobId}/download`, {
    responseType: 'blob',
  });
};

// NOTE: Report 003 - รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว
export const createApprovedContractStatusReport = (data: ApprovedContractReportData) => {
  return axiosInstance.post('report003/create', data);
};

export const listApprovedContractStatusReport = (query: ReportsQuery) => {
  return axiosInstance.get('report003', {
    params: query,
  });
};

export const getApprovedContractStatusReportByJobId = (jobId: string) => {
  return axiosInstance.get(`report003/${jobId}`);
};

export const downloadApprovedContractStatusReportByJobId = (jobId: string) => {
  return axiosInstance.get(`report003/${jobId}/download`, {
    responseType: 'blob',
  });
};

// NOTE: Report 004 - รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว
export const createReport004 = (data: ApprovedContractReportData) => {
  return axiosInstance.post('report004/create', data);
};

export const listReport004 = (query: ReportsQuery) => {
  return axiosInstance.get('report004', {
    params: query,
  });
};

export const getReport004 = (jobId: string) => {
  return axiosInstance.get(`report004/${jobId}`);
};

export const downloadReport004 = (jobId: string) => {
  return axiosInstance.get(`report004/${jobId}/download`, {
    responseType: 'blob',
  });
};

// Report ปุ่มยืนยันการบันทึก (Offer)
export const downloadOfferDoc = (offerId: string | number) => {
  return axiosInstance.get(`report-offer-summary/${offerId}`, {
    responseType: 'blob',
  });
};

// Report สรุปผลการดำเนินงานตรวจสอบ
export const downloadAuditResultsReport = (query: { startDate: string; EndDate: string }) => {
  return axiosInstance.get(`report-inspection-summary`, {
    responseType: 'blob',
    params: query,
  });
};
