import { Modal } from 'antd';
import { ModalUserProps } from 'features/ManageUser/interfaces';
import React from 'react';
import ModalUserForm from './ModalUserForm';

const ModalUser = ({
  isOpenModalUser,
  onCancelModalUser,
  formUser,
  record,
  roles,
  onSubmitForm,
  officerLeaderData,
}: ModalUserProps) => {
  return (
    <Modal
      width={800}
      title={record ? 'แก้ไขผู้ใช้' : 'เพิ่มผู้ใช้'}
      open={isOpenModalUser}
      onOk={undefined}
      onCancel={() => onCancelModalUser()}
      footer={<></>}
      centered
    >
      <ModalUserForm
        formUser={formUser}
        record={record}
        roles={roles}
        onSubmitForm={onSubmitForm}
        onCancelModalUser={onCancelModalUser}
        officerLeaderData={officerLeaderData}
      />
    </Modal>
  );
};

export default ModalUser;
