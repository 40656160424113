import { FunctionComponent, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import AuthGuard from './Guards/AuthGuard';
// import { Desktop } from '../utils/displayResponsive';
import { SiderComponent } from 'components/SiderComponent/SiderComponent';
import Layout from 'components/LayoutComponent/LayoutComponent';
import { useLocation } from 'react-router-dom';

interface IAuthenticatedLayoutProps {
  isHidden?: boolean;
  permission?: string;
}

const AuthenticatedLayout: FunctionComponent<IAuthenticatedLayoutProps> = ({ permission }) => {
  const { pathname } = useLocation();
  const [headerTitle, setHeaderTitle] = useState<string>('');
  const [isClearHeader, setIsClearHeader] = useState<boolean>(true);

  useEffect(() => {
    if (isClearHeader) {
      setHeaderTitle('');
    }
  }, [pathname, isClearHeader]);

  return (
    <AuthGuard permission={permission}>
      <div className="background-log">
        {/* <Desktop> */}
        <SiderComponent headerTitle={headerTitle}>
          <Layout>
            <Outlet context={{ setHeaderTitle, setIsClearHeader }} />
          </Layout>
        </SiderComponent>
        {/* </Desktop> */}
      </div>
    </AuthGuard>
  );
};

export default AuthenticatedLayout;
