import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import InputText from 'components/InputTextComponent';
import { SearchUserFormProps } from 'features/ManageUser/interfaces';
import React from 'react';
import buttonCSS from 'styles/components/button.module.css';

const SearchUserForm = ({ onFinish, onReset, form, mappingGroupData }: SearchUserFormProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Form
        labelWrap
        form={form}
        requiredMark={false}
        name="searchOfferForm"
        onFinish={(val: any) => onFinish(val)}
        style={{ width: '30vw' }}
      >
        <Row gutter={[24, 24]}>
          {' '}
          <Col span={24}>
            <Form.Item colon labelAlign="right" label="กลุ่มผู้ใช้งาน" name="usergroup">
              <Select
                placeholder="-- เลือกกลุ่มผู้ใช้งาน --"
                value={form.getFieldValue('usergroup')}
                options={
                  mappingGroupData &&
                  mappingGroupData.map((val) => ({
                    value: val.groupCode,
                    label: val.groupName,
                  }))
                }
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item colon labelAlign="right" label="รหัสผู้ใช้งาน" name="passUser">
              <InputText />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              แสดงข้อมูล
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              onClick={() => onReset()}
            >
              ล้างข้อมูล
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchUserForm;
