import { useCallback, useEffect, useState } from 'react';

import { Col, Form, Row, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useApi, TApiResponse } from 'hooks/useApi';
import { formLayout } from 'constants/form';
import { uploadStateFile, listStateFileByFormStateId } from 'api/contract/state';

import useFileManagement from 'features/Contract/hooks/useFileManagement';
import PrimaryButton from 'components/PrimaryButton';
import DocTypeDropdown from '../../DocTypeDropdown';
import UploadButton from '../../UploadButton';
import UploadedFileListTable from './UploadedFileListTable';

type UploadStateFileProps = {
  isDisabled: boolean;
  stateId: number | undefined;
  stateCode: string | undefined;
};

const UploadStateFile = ({ isDisabled, stateId, stateCode }: UploadStateFileProps) => {
  const [uploadFormRef] = Form.useForm();
  const {
    fileList,
    isUploadingFile,
    isDeletingFile,
    handleUpload,
    setCurrentUploadFile,
    onRemoveFile,
    downloadFile,
    setFileList,
  } = useFileManagement();
  const {
    makeRequest: _listStateFileByFormStateId,
    data: stateFileListData,
    loading: isLoadingStateFileList,
  }: TApiResponse = useApi();

  const [isOpenFileDialog, setIsOpenFileDialog] = useState(false);
  const [docId, setDocId] = useState<number | undefined>(undefined);
  const [docName, setDocName] = useState<string>('');
  const [selectedDocTypeOption, setSelectedDocTypeOption] = useState<any>(undefined);

  const onUploadFile = useCallback(async () => {
    if (docId && stateId) {
      await handleUpload({
        docId,
        docName,
        required: selectedDocTypeOption?.required,
        id: stateId,
        api: uploadStateFile,
      });
      uploadFormRef.setFieldValue('docId', undefined);
      setDocId(undefined);
      setSelectedDocTypeOption(undefined);
    }
  }, [docId, docName, handleUpload, uploadFormRef, stateId, selectedDocTypeOption]);

  const onValidateDocType = useCallback(() => {
    try {
      uploadFormRef.validateFields(['docId']);
    } catch (error) {
      console.error(error);
    }
  }, [uploadFormRef]);

  useEffect(() => {
    if (stateId) _listStateFileByFormStateId(() => listStateFileByFormStateId(stateId));
  }, [_listStateFileByFormStateId, stateId]);

  useEffect(() => {
    if (stateFileListData) {
      setFileList(stateFileListData);
    }
  }, [setFileList, stateFileListData]);

  return (
    <Form {...formLayout} labelWrap labelAlign="left" form={uploadFormRef} name="stepModalForm">
      <Spin tip="กำลังอัพโหลดไฟล์..." spinning={isUploadingFile || isDeletingFile}>
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <DocTypeDropdown
              isDisabled={isDisabled}
              setDocId={setDocId}
              setDocName={setDocName}
              setIsOpenFileDialog={setIsOpenFileDialog}
              stateCode={stateCode}
              setSelectedDocTypeOption={setSelectedDocTypeOption}
            />
          </Col>
          <Col span={7}>
            <UploadButton
              handleUpload={onUploadFile}
              setCurrentUploadFile={setCurrentUploadFile}
              isOpenFileDialog={isOpenFileDialog}
              isDisabled={isDisabled}
            >
              <PrimaryButton
                icon={<UploadOutlined />}
                disabled={isDisabled}
                onClick={() => {
                  onValidateDocType();
                }}
              >
                อัพโหลดเอกสาร
              </PrimaryButton>
            </UploadButton>
          </Col>
          <Col span={24}>
            <UploadedFileListTable
              isLoading={isUploadingFile || isDeletingFile || isLoadingStateFileList}
              isDisabled={isDisabled}
              onRemoveFile={onRemoveFile}
              downloadFile={downloadFile}
              fileList={fileList}
              totalItems={fileList && fileList.length}
              handleUpload={handleUpload}
              setCurrentUploadFile={setCurrentUploadFile}
              stateId={stateId}
            />
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default UploadStateFile;
