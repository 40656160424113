import { Row, Col, Form, Button, Input } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';

import { dayJsformatDateObjToString12PM } from 'utils/date';
import { formLayout } from 'constants/form';

import ThaiDatepickerComponent from 'components/ThaiDatepickerComponent';

import buttonCSS from 'styles/components/button.module.css';
import ContractTypeDropdown from '../ContractTypeDropdown';
import ContractStatusDropdown from '../ContractStatusDropdown';
import OwnerDropdown from '../OwnerDropdown';

type SearchInquiryFormProps = {
  onSearchInquiryForm: Function;
};

const SearchInquiryForm = ({ onSearchInquiryForm }: SearchInquiryFormProps) => {
  const [searchFormRef] = Form.useForm();

  const onFinish = (values: any) => {
    let _values = { ...values };
    _values.contractStartDate = values.contractStartDate
      ? dayJsformatDateObjToString12PM(values.contractStartDate)
      : null;
    _values.contractEndDate = values.contractEndDate
      ? dayJsformatDateObjToString12PM(values.contractEndDate)
      : null;
    onSearchInquiryForm(_values);
  };

  const onReset = () => {
    searchFormRef.resetFields();
  };

  return (
    <div className="content-box">
      <Form
        {...formLayout}
        labelWrap
        form={searchFormRef}
        name="SearchInquiryForm"
        onFinish={onFinish}
        size="large"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <ContractTypeDropdown label="ประเภทสัญญา" />
          </Col>
          <Col span={12}>
            <OwnerDropdown
              label="ผู้ประกอบการ"
              name="ownerId"
              isLabelInValue={false}
              isIdValue={true}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="เลขที่สัญญา"
              name="contractNo"
              // rules={[{ required: true, message: 'กรุณากรอกเลขที่สัญญา' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <ContractStatusDropdown />
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่สัญญาตั้งแต่"
              name="contractStartDate"
              // rules={[{ required: true, message: 'กรุณากรอกวันที่สัญญาตั้งแต่' }]}
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่สัญญาถึง"
              name="contractEndDate"
              // rules={[{ required: true, message: 'กรุณากรอกวันที่สัญญาถึง' }]}
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              onClick={onReset}
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchInquiryForm;
