/* eslint-disable */

import { ArticleType, ConsiderRequest, Disagreement } from 'api/disagreement/interface';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { Checkbox, Col, Form, Modal, Row, Select, Space, Table } from 'antd';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useApi } from 'hooks/useApi';
import {
  createConsiderRequest,
  deleteConsiderRequest,
  getAllConsiderRequest,
  getArticleType,
  updateConsiderRequest,
  updateDisagreementForm,
  UpdateDisagreementState,
} from 'api/disagreement';
import { ColumnsType } from 'antd/es/table';
import { DeleteFilled, EditOutlined } from '@ant-design/icons';
import {
  convertDataFormDatePickerToString,
  convertDateFromApiToDatePickerWithTimeZoneAsian,
  dayJsformatDateToBCYear,
} from '../../../../../../utils/date';
import TextArea from 'antd/es/input/TextArea';
import { validateFormNotEmpty } from 'utils/form';

interface Form3Prop {
  isCompleted: boolean;
  disagreement?: Disagreement;
  completeDate?: string;
  onActionSuccess: () => void;
}

interface DataType {
  key: React.Key;
  name: string;
  no: string;
  date: string;
  remark: string;
  reviewStatus: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ลบ',
    align: 'center',
    dataIndex: 'no',
    render: (_, record) => (
      <ButtonComponent
        disabled={true}
        onClick={() => {
          // record.onDeleteClick()
        }}
      >
        <DeleteFilled
          disabled={true}
          style={{
            fontSize: 24,
          }}
        />
      </ButtonComponent>
    ),
  },
  {
    title: 'ลำดับที่',
    width: '120px',
    dataIndex: 'no',
    align: 'center',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'การดำเนินงาน',
    dataIndex: 'name',
  },
  {
    title: 'กฏหมาย/ประกาศ',
    width: '200px',
    dataIndex: 'no',
  },
  {
    title: 'ผลการตรวจสอบ',
    width: '200px',
    dataIndex: 'reviewStatus',
    render: (_, record) => {
      let redText = false;
      if (record.reviewStatus) {
        redText =
          ['ไม่มี', 'ไม่ชัดเจน', 'เกิน 1 ปี', 'ไม่ครบ'].some((i) =>
            record?.reviewStatus.includes(i)
          ) && record.reviewStatus !== 'เกิน 1 ปี แต่เป็นประโยชน์แก่ส่วนรวม/เหตุจำเป็นอื่น';
      }
      return (
        <div>
          {record.remark ? <span style={{ color: 'red' }}>*</span> : ''}{' '}
          {redText ? (
            <span style={{ color: 'red' }}>{record.reviewStatus}</span>
          ) : (
            record.reviewStatus
          )}
        </div>
      );
    },
  },
  {
    title: 'วันที่',
    width: '140px',
    dataIndex: 'date',
    render: (_, record) => (
      <>
        <div>{record.date ? dayJsformatDateToBCYear(record.date) : ''}</div>
      </>
    ),
  },
  {
    title: 'แก้ไข',
    align: 'center',
    width: '140px',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a>
          <div
            style={{ display: 'flex', gap: '10px' }}
            onClick={() => {
              record.onEditClick();
            }}
          >
            <EditOutlined />
            <div>แก้ไข</div>
          </div>
        </a>
      </Space>
    ),
  },
];

const Form3 = ({
  isCompleted,
  disagreement,
  completeDate,
  onActionSuccess,
}: Form3Prop): JSX.Element => {
  const [form] = useForm();
  const [form2] = useForm();
  const [disableSubmit] = useState(false);
  const [mode, setMode] = useState('add');
  const [consider, setConsider] = useState<ConsiderRequest>();
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [showActionModal, setShowActionModal] = useState(false);
  const [disableFormSubmit, setDisableFormSubmit] = useState(true);
  const [articleNo, setArticleNo] = useState<string>();
  const [articles, setArticle] = useState<ArticleType[]>([]);
  const [considersState, setConsidersState] = useState<DataType[]>([]);
  const [validateRules] = useState({
    date: true,
    reviewStatus: true,
  });
  const { makeRequest: GetArticleType } = useApi();
  const { makeRequest: CreateConsiderRequest } = useApi();
  const { makeRequest: UpdateConsiderRequest } = useApi();
  const { makeRequest: GetConsiderRequest } = useApi();
  const { makeRequest: DeleteConsiderRequest } = useApi();
  const { makeRequest: UpdateDisagreementStateRequest } = useApi();
  const { makeRequest: UpdateDisagreementFormRequest } = useApi();
  const [options, setOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const [reviewOptions, setReviewOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);

  const validateForm = (): void => {
    const result = Object.entries(validateRules).every(([key, value]) => {
      if (!value) {
        return true;
      } else {
        return validateFormNotEmpty(form.getFieldValue(key));
      }
    });
    setDisableFormSubmit(!result);
  };

  const onSubmit = async () => {
    if (disagreement) {
      await UpdateDisagreementFormRequest(async () => {
        return await updateDisagreementForm(
          disagreement.formId,
          3,
          convertDataFormDatePickerToString(form2.getFieldValue('completeDate'))
        );
      });
      if (checkbox) {
        await UpdateDisagreementStateRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId);
        });
      }
      onActionSuccess();
    }
  };

  const onOptionSelect = (value: number) => {
    const article = articles.find((i) => i.articleId === value);
    if (article) {
      setArticleNo(article.articleNo);
      form.resetFields(['reviewStatus']);
      setReviewOptions(
        article.reviewStatus.map((r) => {
          return {
            label: r,
            value: r,
          };
        })
      );
      if (consider?.reviewStatus) {
        form.setFields([
          {
            name: 'reviewStatus',
            value: consider.reviewStatus,
          },
        ]);
      }
    }
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  const onCancel = () => {
    resetForm();
  };

  const resetForm = () => {
    setShowActionModal(false);
    setDisableFormSubmit(true);
    setArticleNo('');
    form.resetFields(['articleId', 'reviewStatus']);
  };

  const onDelete = useCallback(
    async (id: number) => {
      DeleteConsiderRequest(async () => {
        const res = await deleteConsiderRequest(id);
        const considers: ConsiderRequest[] = await getListConsider();
        setConsidersState(mapConsiderRequestToDataType(considers, getMap(articles)));
        return res;
      });
    },
    [articles]
  );

  const onOK = async () => {
    if (disagreement) {
      if (mode === 'add') {
        await CreateConsiderRequest(async () => {
          return await createConsiderRequest({
            articleId: form.getFieldValue('articleId'),
            formId: disagreement.formId,
            stateCode: 3,
            reviewDate: form.getFieldValue('date')
              ? convertDataFormDatePickerToString(form.getFieldValue('date'))
              : '',
            reviewStatus: form.getFieldValue('reviewStatus'),
          });
        });
        const considers: ConsiderRequest[] = await getListConsider();
        setConsidersState(mapConsiderRequestToDataType(considers, getMap(articles)));
        setShowActionModal(false);
        resetForm();
      } else if (mode === 'edit' && consider) {
        await UpdateConsiderRequest(async () => {
          return await updateConsiderRequest({
            reqId: consider.reqId,
            articleId: form.getFieldValue('articleId'),
            reviewDate: form.getFieldValue('date')
              ? convertDataFormDatePickerToString(form.getFieldValue('date'))
              : '',
            reviewStatus: form.getFieldValue('reviewStatus'),
            remark: form.getFieldValue('remark'),
          });
        });
        const considers: ConsiderRequest[] = await getListConsider();
        setConsidersState(mapConsiderRequestToDataType(considers, getMap(articles)));
        setShowActionModal(false);
        resetForm();
      }
    }
  };

  useEffect(() => {
    if (consider) {
      onOptionSelect(consider.articleId);
    }
  }, [consider]);

  const onEdit = (consider: ConsiderRequest, articleNo: string) => {
    setArticleNo(articleNo);
    setDisableFormSubmit(false);
    setMode('edit');
    setShowActionModal(true);
    setConsider(consider);
    form.setFields([
      {
        name: 'articleId',
        value: consider.articleId,
      },
      {
        name: 'date',
        value: consider.reviewDate
          ? convertDateFromApiToDatePickerWithTimeZoneAsian(consider.reviewDate)
          : '',
      },
      {
        name: 'reviewStatus',
        value: consider.reviewStatus,
      },
      {
        name: 'remark',
        value: consider.remark,
      },
    ]);
    validateForm();
  };

  const onAdd = () => {
    setMode('add');
    setShowActionModal(true);
    form.setFields([
      {
        name: 'date',
        value: new Date(),
      },
    ]);
  };

  const getListConsider = async () => {
    if (disagreement) {
      return GetConsiderRequest(async () => {
        const res: any = await getAllConsiderRequest(disagreement.formId, 3);
        const considerRequests: ConsiderRequest[] = res.data.data;
        return considerRequests;
      });
    }
  };

  const mapConsiderRequestToDataType = (
    considerRequest: ConsiderRequest[],
    articleMap: Record<
      string,
      {
        id: number;
        no: string;
        name: string;
      }
    >
  ): DataType[] => {
    return considerRequest.map((i) => {
      return {
        key: i.reqId,
        name: articleMap[i.articleId].name,
        no: articleMap[i.articleId].no,
        date: i.reviewDate,
        reviewStatus: i.reviewStatus,
        remark: i.remark,
        onEditClick: () => onEdit(i, articleMap[i.articleId].no),
        onDeleteClick: () => onDelete(i.reqId),
      };
    });
  };

  const getMap = (payload: ArticleType[]): Record<string, any> => {
    const map: Record<string, any> = {};
    payload.forEach((i) => {
      map[i.articleId] = {
        id: i.articleId,
        no: i.articleNo,
        name: i.articleName,
      };
    });
    return map;
  };

  useEffect(() => {
    const newOptions = articles.map((i) => {
      return {
        label: i.articleName,
        value: i.articleId,
      };
    });
    setOptions(newOptions);
  }, [articles]);

  useEffect(() => {
    GetArticleType(async () => {
      const res: any = await getArticleType('2');
      const articles: ArticleType[] = res.data.data;
      const considers: ConsiderRequest[] = await getListConsider();
      setConsidersState(mapConsiderRequestToDataType(considers, getMap(articles)));
      setArticle(articles);
      return res;
    });
    if (completeDate) {
      form2.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form2.setFields([
        {
          name: 'completeDate',
          value: new Date(),
        },
      ]);
    }
  }, [disagreement, completeDate]);

  const onFormChange = () => {
    validateForm();
    setTimeout(() => {
      if (form.getFieldValue('reviewStatus')) {
        setDisableFormSubmit(false);
      } else {
        setDisableFormSubmit(true);
      }
    });
  };

  return (
    <>
      {/*<ButtonComponent onClick={onAdd}>*/}
      {/*  เพิ่มรายการ*/}
      {/*</ButtonComponent>*/}
      <div
        style={{
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        <Table columns={columns} dataSource={considersState} pagination={false} />
      </div>
      <Modal
        key="test"
        title="เพิ่มรายการ/แก้ไข"
        open={showActionModal}
        onCancel={onCancel}
        width={1100}
        footer={[
          <ButtonComponent key="addConsider" onClick={onOK} disabled={disableFormSubmit}>
            บันทึกรายการ
          </ButtonComponent>,
        ]}
      >
        <Form form={form} onValuesChange={onFormChange}>
          <Row
            gutter={[16, 16]}
            style={{
              marginTop: 24,
            }}
          >
            <Col span={24}>
              <Form.Item
                labelCol={{
                  span: 5,
                }}
                labelAlign={'right'}
                label="กฎหมาย/ประกาศที่เกี่ยวข้อง"
                name="articleId"
              >
                <Select
                  disabled={true}
                  placeholder="กรุณาเลือกกฎหมาย/ประกาศที่เกี่ยวข้อง"
                  style={{ width: '100%' }}
                  onChange={onOptionSelect}
                  options={options}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  span: 5,
                }}
                labelAlign={'right'}
                label="กฎหมาย/ประกาศ"
              >
                <div>{articleNo || '-'}</div>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  span: 5,
                }}
                labelAlign={'right'}
                name="reviewStatus"
                label="ผลการตรวจสอบ"
              >
                <Select
                  placeholder="กรุณาเลือกผลการตรวจสอบ"
                  style={{ width: '100%' }}
                  options={reviewOptions}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  span: 5,
                }}
                labelAlign={'right'}
                label="วันที่"
                name="date"
              >
                <ThaiDatepickerComponent />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                labelCol={{
                  span: 5,
                }}
                labelAlign={'right'}
                label="รายละเอียดเอกสาร"
                name="remark"
              >
                <TextArea />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Form form={form2}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Checkbox disabled={isCompleted} checked={checkbox} onChange={onCheckBoxChange}>
              ยืนยันการพิจารณา
            </Checkbox>
          </Col>
          <Col span={8}>
            <Form.Item labelAlign={'left'} label="วันที่พิจารณา" name="completeDate">
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ButtonComponent
          disabled={disableSubmit}
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          บันทึกรายการ
        </ButtonComponent>
      </div>
    </>
  );
};
export default Form3;
