export const validateFormNotEmpty = (value: any): boolean => {
  if (value === undefined) {
    return false;
  }
  if (value === null) {
    return false;
  }
  return value !== '';

};
