import { SaveOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { getTableIndex } from 'utils/table';
import { IOfferPublishUrl } from '../interfaces';
import {
  getFormattedApproveDate,
  getFormattedString,
} from 'features/OfficerPublishUrl/utils/string';

type ColumnsProps = {
  page: number;
  pageSize: number;
  onSelectRecord: Function;
};

export const columns = ({ page, pageSize, onSelectRecord }: ColumnsProps) => {
  const columns: ColumnsType<IOfferPublishUrl> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      render: (_, __, index) => <>{getTableIndex(page, pageSize, index)}</>,
    },
    {
      title: 'ชื่อผู้ประกอบการ',
      dataIndex: 'operName',
      key: 'operName',
    },
    {
      title: 'เลขที่/วันที่หนังสือ ผู้ประกอบการ',
      dataIndex: 'offerFormNo',
      key: 'offerFormNo',
      align: 'center',
      render: (_, record) => {
        return getFormattedString({ formNo: record?.offerFormNo, date: record?.offerFormDate });
      },
    },
    {
      title: 'มติเห็นชอบ',
      dataIndex: 'nbtcApprovedNo',
      key: 'nbtcApprovedNo',
      align: 'center',
      render: (_, record) => {
        return getFormattedApproveDate({
          formNo: record?.nbtcApprovedNo,
          date: record?.nbtcApprovedDate,
        });
      },
    },
    {
      title: 'Link Web เปิดเผยข้อเสนอ',
      dataIndex: 'offerFormPublicUrl',
      key: 'offerFormPublicUrl',
    },
    {
      title: 'สถานะลิงก์เว็บ',
      dataIndex: 'isActivePublicUrl',
      key: 'isActivePublicUrl',
      align: 'center',
      render: (value) => (value ? 'ใช้งาน' : 'ไม่ใช้งาน'),
    },
    {
      title: 'บันทึก',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div
          onClick={() => {
            onSelectRecord(record);
          }}
        >
          <SaveOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
        </div>
      ),
    },
  ];
  return columns;
};
