import { Table } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import { columns } from './columns';

import { IPageable } from 'types/pagination';
import { IContractData } from 'features/Contract/interfaces';

type ContractOfficerListTableProps = {
  data: IContractData[];
  loading: boolean;
  pageSize: number;
  pageable?: IPageable;
  currentPageNumber: number;
  paginationSetting: any;
  onOpenHistoryModal: Function;
  getEditPath: Function;
};

const ContractOfficerListTable = ({
  data,
  loading,
  pageSize,
  currentPageNumber,
  paginationSetting,
  onOpenHistoryModal,
  getEditPath,
}: ContractOfficerListTableProps) => {
  const navigator = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="content-box tabs-scroll">
      <Table
        columns={columns({
          navigator,
          page: currentPageNumber,
          pageSize,
          getEditPath,
          onOpenHistoryModal,
          pathname,
        })}
        dataSource={data}
        loading={loading}
        rowKey={'conFormId'}
        size="middle"
        pagination={paginationSetting}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default ContractOfficerListTable;
