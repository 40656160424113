import { useEffect } from 'react';

import { TApiResponse, useApi } from 'hooks/useApi';
import { listProvinces } from 'api/master';

const useProvince = () => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listProvinces());
  }, [makeRequest]);

  return { provinces: data, isLoadingProvinces: loading };
};

export default useProvince;
