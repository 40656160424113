import { Space } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';

type FooterProps = {
  handleCancel: Function;
  onSubmitForm: Function;
};

const Footer = ({ handleCancel, onSubmitForm }: FooterProps) => {
  return (
    <Space>
      <PrimaryButton icon={<SaveOutlined />} onClick={() => onSubmitForm()}>
        ยืนยัน
      </PrimaryButton>
      <PrimaryButton icon={<CloseOutlined />} onClick={() => handleCancel()}>
        ยกเลิก
      </PrimaryButton>
    </Space>
  );
};

export default Footer;
