import { Button } from 'antd';

import buttonCSS from 'styles/components/button.module.css';

const PrimaryButton = ({ icon, children, ...rest }: any) => {
  return (
    <Button
      className={buttonCSS.primary}
      type="primary"
      shape="round"
      size="large"
      icon={icon ? icon : ''}
      {...rest}
    >
      {children ? children : ''}
    </Button>
  );
};

export default PrimaryButton;
