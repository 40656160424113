import { Col, Row } from 'antd';

import ServiceModal from './components/ServiceModal';
import ServicesTable from './components/ServicesTable';
import ContServePayModal from './components/ContServePayModal';
import ServiceActionButtons from './components/ServiceActionButtons';

type ContractServicesProps = {
  selectedOfferFormId: number | string | undefined;
};

const ContractServices = ({ selectedOfferFormId }: ContractServicesProps) => {
  return (
    <>
      <ServiceModal selectedOfferFormId={selectedOfferFormId} />
      <ContServePayModal />
      <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
        <Col span={24}>
          <ServiceActionButtons />
        </Col>
        <Col span={24}>
          <ServicesTable />
        </Col>
      </Row>
    </>
  );
};

export default ContractServices;
