import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RISO,
} from 'constants/offerFormType';
import { subRoutePaths } from 'constants/routes';

export const getOfferDetailPath = ({
  offerType,
  offerId,
}: {
  offerType: string | undefined;
  offerId: string | number;
}) => {
  switch (offerType) {
    case OFFER_TYPE_RISO:
      return subRoutePaths.offerRISODetail(offerId);
    case OFFER_TYPE_RDRO:
      return subRoutePaths.offerRDRODetail(offerId);
    case OFFER_TYPE_RIO:
      return subRoutePaths.offerRIODetail(offerId);
    case OFFER_TYPE_RAO:
      return subRoutePaths.offerRAODetail(offerId);
    default:
      return `/`;
  }
};

export const getOfferEditPath = ({
  offerType,
  offerId,
}: {
  offerType: string | undefined;
  offerId: string | number;
}) => {
  switch (offerType) {
    case OFFER_TYPE_RISO:
      return subRoutePaths.offerRISOEdit(offerId);
    case OFFER_TYPE_RDRO:
      return subRoutePaths.offerRDROEdit(offerId);
    case OFFER_TYPE_RIO:
      return subRoutePaths.offerRIOEdit(offerId);
    case OFFER_TYPE_RAO:
      return subRoutePaths.offerRAOEdit(offerId);
    default:
      return `/`;
  }
};

export const getOfferHistoryPath = ({
  offerType,
  offerId,
}: {
  offerType: string | undefined;
  offerId: string | number;
}) => {
  switch (offerType) {
    case OFFER_TYPE_RISO:
      return subRoutePaths.offerRISOHistory(offerId);
    case OFFER_TYPE_RDRO:
      return subRoutePaths.offerRDROHistory(offerId);
    case OFFER_TYPE_RIO:
      return subRoutePaths.offerRIOHistory(offerId);
    case OFFER_TYPE_RAO:
      return subRoutePaths.offerRAOHistory(offerId);
    default:
      return `/`;
  }
};
