import { Checkbox, Col, Form, Select } from 'antd';
import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';
import {
  getServiceDropdownValueByName,
  getServiceLabelTextDropdown,
  setTitleData,
} from 'features/Contract/utils/serviceDropdown';

const ContractServiceDropdown = () => {
  const { currentContServes } = useContractServicesContext();
  const { onToggleExtraServePay, isAddExtraServePay } = useContractFacilitiesContext();

  return (
    <>
      <Col span={18}>
        <Form.Item
          colon
          label="บริการ"
          name="servId"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[{ required: true, message: 'กรุณาเลือกบริการ' }]}
        >
          <Select
            loading={currentContServes ? false : true}
            allowClear
            labelInValue
            mode="multiple"
            placeholder="-- กรุณาเลือกบริการ --"
            options={
              currentContServes
                ? currentContServes.map((item) => {
                    return {
                      value: getServiceDropdownValueByName(item),
                      label: getServiceLabelTextDropdown(item),
                      title: setTitleData(item),
                    };
                  })
                : []
            }
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Checkbox onChange={onToggleExtraServePay} checked={isAddExtraServePay}>
          เพิ่มอัตราค่าตอบแทน
        </Checkbox>
      </Col>
    </>
  );
};

export default ContractServiceDropdown;
