/* eslint-disable */

import { Disagreement } from 'api/disagreement/interface';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { updateDisagreementFormSeven, UpdateDisagreementState } from 'api/disagreement';
import { useApi } from 'hooks/useApi';
import dayjs from 'dayjs';
import FormFilesUpload from './components/Form-Files-Upload';
import { convertDataFormDatePickerToString, convertDateFromApiToDatePickerWithTimeZoneAsian } from 'utils/date';


const Form7 = ({ disagreement, stepState, isCompleted, completeDate, onActionSuccess }: {
  stepState: number;
  disagreement?: Disagreement,
  isCompleted: boolean,
  completeDate?: string,
  onActionSuccess: () => void
}): JSX.Element => {
  const [form] = useForm();
  const { makeRequest: UpdateDisagreementFormSevenRequest } = useApi();
  const { makeRequest: UpdateDisagreementStateRequest } = useApi();

  const [result, setResult] = useState<boolean>();
  const [disable, setDisable] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckBox] = useState(false);
  const [agreeDicuss, setAgreeDicuss] = useState<boolean | null>(null);

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  useEffect(() => {
    if (typeof agreeDicuss !== 'boolean') {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  }, [agreeDicuss]);

  const onSubmit = async () => {
    setLoading(true);
    const formSeven = form.getFieldsValue();
    if (disagreement) {
      await UpdateDisagreementFormSevenRequest(async () => {
        return await updateDisagreementFormSeven({
          disagreementId: disagreement.formId,
          agreeDiscuss: agreeDicuss,
          accusedAgreeDiscuss: formSeven.accusedAgreeDicuss,
          petitionerAgreeDiscuss: formSeven.petitionerAgreeDicuss,
          completeDate: convertDataFormDatePickerToString(formSeven.completeDate),
        }).catch(() => {
          setLoading(false);
        });
      });
      if (checkbox) {
        await UpdateDisagreementStateRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId, agreeDicuss ? 'Y' : 'N').catch(() => {
            setLoading(false);
          });
        });
      }
    }
    setLoading(false);
    onActionSuccess();
  };

  useEffect(() => {
    if (disagreement) {
      const sevenState = disagreement.disAgreementStates.find(i => i.stateCode === 7);
      setDisableSubmit(disagreement.agreeDiscuss === null || disagreement.agreeDiscuss === undefined);
      setResult(disagreement.agreeDiscuss);
      form.setFields([
        {
          name: 'accusedAgreeDicuss',
          value: disagreement.accusedAgreeDiscuss,
        },
        {
          name: 'petitionerAgreeDicuss',
          value: disagreement.petitionerAgreeDiscuss,
        },
        {
          name: 'agreeDicuss',
          value: disagreement.agreeDiscuss,
        },
      ]);
      if (sevenState?.isCompleteState) {
        setDisable(true);
      }
      onFormValueChange();
    }
    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: dayjs(new Date()).toDate(),
        },
      ]);
    }
  }, [disagreement, completeDate]);

  const onFormValueChange = () => {
    const pd = form.getFieldValue('petitionerAgreeDicuss');
    const ad = form.getFieldValue('accusedAgreeDicuss');
    const validate = [ad, pd].every(i => (i === true || i === false));
    const ans = [ad, pd].every(i => i === true);
    if (validate) {
      setResult(ans);
      form.setFieldValue('agreeDicuss', [ad, pd].every(i => i === true));
      setAgreeDicuss([ad, pd].every(i => i === true));
    } else {
      setResult(undefined);
    }
  };
  return <>
    <Form form={form} onValuesChange={onFormValueChange} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} style={{
      marginBottom: 0,
      marginTop: 24,
    }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item label='ผู้ร้องชื่อ' name='petitioner'>
            <div>{disagreement ? disagreement.petitionerOperatorName : ''}</div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='ผู้ถูกร้องชื่อ' name='accused'>
            <div>{disagreement ? disagreement.accusedOperatorName : ''}</div>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='ความประสงค์' name='petitionerAgreeDicuss'>
            <Select
              disabled={disable}
              style={{ width: '100%' }}
              placeholder='กรุณาระบุ'
              options={[
                { value: true, label: 'ไกล่เกลี่ย' },
                { value: false, label: 'ไม่ไกล่เกลี่ย' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='ความประสงค์' name='accusedAgreeDicuss'>
            <Select
              disabled={disable}
              style={{ width: '100%' }}
              placeholder='กรุณาระบุ'
              options={[
                { value: true, label: 'ไกล่เกลี่ย' },
                { value: false, label: 'ไม่ไกล่เกลี่ย' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label='ผลสรุป'>
            {
              typeof (result) === 'boolean' ? result ? 'ไกล่เกลี่ย' : 'ไม่ไกล่เกลี่ย' : '-'
            }
          </Form.Item>
        </Col>

      </Row>
      <br />
    </Form>

    <div>
      <FormFilesUpload stepState={stepState} disagreementFormId={disagreement?.formId || 0} state={7} />
    </div>

    <Form form={form} onValuesChange={onFormValueChange} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox disabled={isCompleted} checked={checkbox} onChange={onCheckBoxChange}>ยืนยันการพิจารณา</Checkbox>
        </Col>
        <Col span={24}>
          <Form.Item labelCol={{ span: 4 }} wrapperCol={{
            span: 6,
          }
          } labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div style={
            {
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }
          }>
            <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onSubmit}>
              บันทึกรายการ
            </ButtonComponent>
          </div>
        </Col>
      </Row>
    </Form>
  </>;
};

export default Form7;
