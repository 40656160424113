import { useEffect } from 'react';
import { Col, Divider, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib';

import useDialogAlert from 'hooks/useDialogAlert';
import useUploadEmailFile from './hooks/useUploadEmailFile';

import { updateStateNotifyEmail } from 'api/review';

import UploadEmailFile from './UploadEmailFile';
import { ThaiDatepickerComponent } from 'components';

import { formLayout } from 'constants/form';
import { StepsDataType } from 'features/OfferConsideration/interfaces';
import { getJSDateObj, dayJsformatDateObjToString } from 'utils/date';

type StepFormWithEmailFileProps = {
  form: FormInstance<any>;
  stepData: StepsDataType | undefined;
  isDisable: boolean;
  refTrigger: number;
  onUpdateOfferState: (data: any, api?: any) => void;
};

const StepFormWithEmailFile = ({
  form,
  stepData,
  isDisable,
  refTrigger,
  onUpdateOfferState,
}: StepFormWithEmailFileProps) => {
  const { error: errorModal } = useDialogAlert();
  const {
    isOpenFileDialog,
    isUploadingFile,
    isDeletingFile,
    isLoadingFileList,
    uploadFormRef,
    fileList,
    onUploadFile,
    setCurrentUploadFile,
    onValidateDocType,
    onChangeDocType,
    onRemoveRecordFile,
    onDownloadRecordFile,
    onValidateAtleastOneFile,
  } = useUploadEmailFile({
    stateId: stepData?.formStateId,
    refTrigger,
  });

  const onFinish = async (values: any) => {
    try {
      await onValidateAtleastOneFile();

      let data: any = {
        formStateId: stepData?.formStateId,
        stateName: stepData?.stateName,
        stateCode: stepData?.stateCode,
        actionCode: '',
        actionName: '',
        actionDate: dayJsformatDateObjToString(values.actionDate),
        isCompleteState: false,
        isCurrentState: true,
      };

      onUpdateOfferState(data, updateStateNotifyEmail);
    } catch (e: any) {
      errorModal({
        header: e.message || 'เกิดข้อผิดพลาดขึ้น (error: #8243)',
        sub: e.message ? '' : 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  useEffect(() => {
    if (stepData) {
      form.setFieldsValue({
        stateName: stepData.stateName,
        actionDate: stepData.actionDate ? getJSDateObj(stepData.actionDate) : undefined,
      });
    }
  }, [form, stepData]);

  return (
    <>
      <Form
        {...formLayout}
        labelWrap
        labelAlign="left"
        form={form}
        name="stepModalForm"
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item colon label="สถานะข้อเสนอ" name="stateName">
              <Input size="large" disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon
              label="วันที่หนังสือ"
              name="actionDate"
              rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
            >
              <ThaiDatepickerComponent
                disabled={isDisable}
                // onChange={(value: any) => {
                //   console.log('value: ', value);
                //   console.log('Date Obj: ', new Date(value));
                //   console.log('Date Obj: ', new Date(value).getTimezoneOffset());
                //   console.log('Date Obj: ', dayjs(value));
                //   console.log('Is UTC: ', dayjs(value).isUTC());
                //   console.log('Is UTC: ', dayjs(value).utc().isUTC());
                // }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider orientation="left" orientationMargin="0">
        อัพโหลดไฟล์
      </Divider>
      <UploadEmailFile
        form={form}
        isDisable={isDisable}
        stepData={stepData}
        isOpenFileDialog={isOpenFileDialog}
        isUploadingFile={isUploadingFile}
        isDeletingFile={isDeletingFile}
        isLoadingFileList={isLoadingFileList}
        uploadFormRef={uploadFormRef}
        fileList={fileList}
        onUploadFile={onUploadFile}
        setCurrentUploadFile={setCurrentUploadFile}
        onValidateDocType={onValidateDocType}
        onChangeDocType={onChangeDocType}
        onRemoveRecordFile={onRemoveRecordFile}
        onDownloadRecordFile={onDownloadRecordFile}
      />
    </>
  );
};

export default StepFormWithEmailFile;
