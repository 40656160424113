import { Key, useEffect, useState } from 'react';
import { Form } from 'antd';

import useDialogAlert from 'hooks/useDialogAlert';
import useServiceFacilityValidation from './useServiceFacilityValidation';
import useModal from 'hooks/useModal';

import { OTHER_OPTION } from 'features/Offer/constant';

import { IContServesData, IFacilitiesType, IOfferFacilities } from '../interfaces';
import { getDataWithKey, getUndefinedIfZero } from '../utils';
import {
  getServiceDropdownValueByName,
  getServiceLabelTextDropdown,
  setTitleData,
} from '../utils/serviceDropdown';

type TUseManageContractFacilities = {
  contFacilities: IFacilitiesType[];
  currentContServes: IContServesData[];
};

const useManageContractFacilities = ({
  contFacilities,
  currentContServes,
}: TUseManageContractFacilities) => {
  const [facilityTableFormRef] = Form.useForm();
  const [facilityModalFormRef] = Form.useForm();

  const { error } = useDialogAlert();
  const { validateIsServicesAtLeastOne } = useServiceFacilityValidation();

  const [currentContFacilities, setCurrentContFacilities] = useState<IFacilitiesType[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Key[]>([]);
  const [editingFacility, setEditingFacility] = useState<IFacilitiesType | undefined>(undefined);
  const [isAddExtraServePay, setIsAddExtraServePay] = useState<boolean>(false);

  const {
    isModalOpen: isFacilityModalOpen,
    showModal: showFacilityModal,
    handleCancel: handleFacilityModalCancel,
  } = useModal();

  const onCheckDuplicateFacility = (option: any) => {
    const isDuplicateFacility = currentContFacilities.some((item) => item.facId === option.value);
    if (isDuplicateFacility) {
      return error(
        {
          header: 'ไม่สามารถเลือกสิ่งอำนวยความสะดวกซ้ำกันได้',
          sub: '',
        },
        () => {
          facilityModalFormRef.setFieldsValue({ facId: undefined });
        }
      );
    }
  };

  const onResetCurrentContFacilities = () => {
    setCurrentContFacilities([]);
  };

  /** -------------------------------------------------------------------
   * NOTE: Facilities Table
   * -------------------------------------------------------------------
   */
  const onSelectMultipleFacilities = (selectedRowKeys: Key[], _: any[]) => {
    setSelectedFacilities(selectedRowKeys);
  };

  const onEditContractFacility = (record: IFacilitiesType) => {
    if (!record) return;
    showFacilityModal();
    setEditingFacility(record);
    if (record?.payCondition) setIsAddExtraServePay(true);

    facilityModalFormRef.setFieldsValue({
      facId: {
        value: record.facId,
        label: record.facName,
      },
      facDesc: record.facDesc,
      payCondition: record.payCondition,
      payCompensation: record.payCompensation,
      // currencyId: getUndefinedIfZero(record.currencyId),
      // currencyId: { value: getUndefinedIfZero(record.currencyId), label: record.currencyName },
      currencyId: getUndefinedIfZero(record.currencyId)
        ? { value: record.currencyId, label: record.currencyName }
        : undefined,
      currencyDesc: record.currencyDesc,
      // unitId: getUndefinedIfZero(record.unitId),
      unitId: getUndefinedIfZero(record.unitId)
        ? { value: record.unitId, label: record.unitName }
        : undefined,
      unitDesc: record.unitDesc,
      payPeriod: getUndefinedIfZero(record.payPeriod),
      payPeriodUnit: getUndefinedIfZero(record.payPeriodUnit),
      servId: record.contFacilitiesServes?.map((item: IOfferFacilities) => {
        return {
          value: getServiceDropdownValueByName(item),
          label: getServiceLabelTextDropdown(item),
          title: setTitleData(item),
        };
      }),
    });
  };

  const onAddNewContractFacility = () => {
    try {
      if (!currentContServes) throw new Error('กรุณาเลือกบริการก่อนดำเนินการต่อ');
      validateIsServicesAtLeastOne(currentContServes);
      showFacilityModal();
    } catch (e: any) {
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น (error: #2854) ',
        sub: '',
      });
    }
  };

  const onDeleteContractFacilities = () => {
    const newContFacilities = currentContFacilities.filter(
      (item) => !selectedFacilities.includes(item.key)
    );
    setCurrentContFacilities(newContFacilities);
    setSelectedFacilities([]);
  };

  const onCloseContractFacilityModal = () => {
    facilityModalFormRef.resetFields();
    setIsAddExtraServePay(false);
    setEditingFacility(undefined);
    handleFacilityModalCancel();
  };

  const onSubmitContractFacility = async () => {
    try {
      // // NOTE: Validate form
      // await facilityModalFormRef.validateFields();

      // NOTE: Compose contract facility data
      let newContFacilities: IFacilitiesType[];
      const formValues = facilityModalFormRef.getFieldsValue();
      const formattedNewOfferFacilities = {
        facId: formValues?.facId.value,
        facName: formValues?.facId.label,
        facDesc: formValues?.facDesc || '',
        payCondition: formValues?.payCondition || '',
        payCompensation: formValues?.payCompensation || '',
        currencyId: formValues?.currencyId?.value,
        currencyName: formValues?.currencyId?.label,
        currencyDesc: formValues?.currencyDesc,
        unitId: formValues?.unitId?.value,
        unitName: formValues?.unitId?.label,
        unitDesc: formValues?.unitDesc,
        payPeriod: formValues?.payPeriod,
        payPeriodUnit: formValues?.payPeriodUnit,
        contFacilitiesServes: formValues?.servId.map((item: any) => {
          return {
            servId: item?.title?.value,
            servName: item?.title?.label === item?.title?.desc ? OTHER_OPTION : item?.title?.label,
            serviceDesc: item?.title?.desc,
            servTypeName: item?.title?.servTypeName,
          };
        }),
      };

      // NOTE: Check case whether edit or add new
      if (editingFacility) {
        // NOTE: Edit
        newContFacilities = currentContFacilities.map((item) => {
          if (item.key === editingFacility.key) {
            return {
              key: item.key,
              ...formattedNewOfferFacilities,
            };
          }
          return item;
        });
      } else {
        // NOTE: Add new
        newContFacilities = [
          ...currentContFacilities,
          { key: currentContFacilities.length + 1, ...formattedNewOfferFacilities },
        ];
      }

      setCurrentContFacilities(newContFacilities);
      setEditingFacility(undefined);
      onCloseContractFacilityModal();
    } catch (e: any) {
      error({
        header: e.message || 'กรุณากรอกฟอร์มให้ครบถ้วน',
        sub: '',
      });
    }
  };

  const onToggleExtraServePay = () => {
    setIsAddExtraServePay(!isAddExtraServePay);
  };
  // -------------------------------------------------------------------

  useEffect(() => {
    setCurrentContFacilities(getDataWithKey(contFacilities));
  }, [contFacilities]);

  return {
    isFacilityModalOpen,
    isAddExtraServePay,
    facilityModalFormRef,
    facilityTableFormRef,
    editingFacility,
    selectedFacilities,
    currentContFacilities,
    onSelectMultipleFacilities,
    onEditContractFacility,
    onAddNewContractFacility,
    onDeleteContractFacilities,
    onCloseContractFacilityModal,
    onSubmitContractFacility,
    onCheckDuplicateFacility,
    onToggleExtraServePay,
    onResetCurrentContFacilities,
  };
};

export default useManageContractFacilities;
