import { useCallback, useEffect, useMemo } from 'react';
import { SaveOutlined } from '@ant-design/icons';

import { useApi, TApiResponse } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';

import PrimaryButton from 'components/PrimaryButton';

import { CONFIRM_SAVE_STATE } from 'features/Contract/constant';
import { updateContractToWaitFile } from 'api/contract';
import useContractDataContext from 'features/Contract/hooks/useContractDataContext';
import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';

type ConfirmSaveButtonProps = {
  isDisabled: boolean;
  isNewPage: boolean;
  isReviewPage: boolean;
  onGetContractById: Function;
  conFormId: string | undefined;
  stateCode: number | undefined;
  setIsUpdatingState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmSaveButton = ({
  isDisabled,
  isNewPage,
  isReviewPage,
  onGetContractById,
  conFormId,
  stateCode,
  setIsUpdatingState,
}: ConfirmSaveButtonProps) => {
  const { validateContractPage } = useContractDataContext();
  const { currentContServes } = useContractServicesContext();
  const { error } = useDialogAlert();
  const { makeRequest, loading }: TApiResponse = useApi();

  const isNotConfirmSaveState = useMemo(() => {
    if (isReviewPage) return false;
    if (stateCode) return !CONFIRM_SAVE_STATE.includes(stateCode);
    else return true;
  }, [stateCode, isReviewPage]);

  const onConfirmSave = useCallback(async () => {
    try {
      await validateContractPage({ currentContServes });
      if (conFormId) {
        try {
          await makeRequest(() => updateContractToWaitFile(conFormId));
          onGetContractById();
        } catch (errorInfo) {
          error({
            header: 'ไม่สามารถอัพเดทสถานะได้',
            sub: 'กรุณาลองใหม่อีกครั้ง',
          });
        }
      }
    } catch (errorInfo: any) {
      return error({
        header: errorInfo ? errorInfo.message : 'กรุณากรอกฟอร์มให้ครบถ้วน',
        sub: '',
      });
    }
  }, [onGetContractById, makeRequest, conFormId, error, validateContractPage, currentContServes]);

  useEffect(() => {
    setIsUpdatingState(loading);
  }, [loading, setIsUpdatingState]);

  return (
    <PrimaryButton
      disabled={isDisabled || isNewPage || isNotConfirmSaveState}
      icon={<SaveOutlined />}
      onClick={onConfirmSave}
    >
      ยืนยันการบันทึก
    </PrimaryButton>
  );
};

export default ConfirmSaveButton;
