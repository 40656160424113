import { useCallback, useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { useApi } from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import useProfile from 'hooks/useProfile';

import { getAvailableProfile } from 'api/authen';
import CookiesService from 'service/CookiesService';
import { removeCookieCredentials } from 'utils/logout';

type ProfileDataType = {
  companyName: string;
  referenceNo: string;
};

const ProfileSwitchingButton = () => {
  const { user } = useAuth();
  const { makeRequest, data } = useApi();
  const { onSelectProfile } = useProfile();
  const [allProfiles, setAllProfiles] = useState([]);

  const onSwitchProfile = useCallback(
    (refNo: string) => {
      removeCookieCredentials();
      onSelectProfile({ refNo });
    },
    [onSelectProfile]
  );

  const getProfilesMenuItems = useCallback(() => {
    const refNo = CookiesService.get('REFERENCE_NO');
    return allProfiles
      .filter((profile: ProfileDataType) => profile.referenceNo !== refNo)
      .map((profile: ProfileDataType) => {
        return {
          label: (
            <span onClick={() => onSwitchProfile(profile.referenceNo)}>
              <UserOutlined /> : {profile.companyName}
            </span>
          ),
          key: profile.referenceNo,
        };
      });
  }, [allProfiles, onSwitchProfile]);

  useEffect(() => {
    makeRequest(() => getAvailableProfile());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setAllProfiles(data);
    }
  }, [data, setAllProfiles]);

  return (
    <div>
      {allProfiles?.length > 0 ? (
        <Dropdown
          menu={{
            items: getProfilesMenuItems(),
            selectable: false,
          }}
        >
          <Button>
            <span>
              <UserOutlined /> : {user?.fullName}
            </span>
          </Button>
        </Dropdown>
      ) : (
        <Button>
          <span>
            <UserOutlined /> : {user?.fullName}
          </span>
        </Button>
      )}
    </div>
  );
};

export default ProfileSwitchingButton;
