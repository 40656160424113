import axiosInstance from 'utils/axios';

export const listAssignTasks = (
  pageSize: number | string,
  currentPageNumber: number | string,
  filter: {
    offerFormTypeCode: string | null;
    taxId: string | null;
    offerFormNo: string | null;
    offerFormStateCode: string | null;
    respUserId: string | null;
  }
) => {
  return axiosInstance.post(
    `/offer/officer/assign-tasks?currentPageNumber=${currentPageNumber}&pageSize=${pageSize}`,
    filter
  );
};

export const listAssignTasksOfferFormStates = () => {
  return axiosInstance.get(`/offer/officer/assign-tasks/offer-form-states`);
};
export const getListOfferAssignees = () => {
  return axiosInstance.get(`/offer/officer/assignees`);
};
export const updateListOfferAssignees = (id: string | number, data: { officerIds: number[] }) => {
  return axiosInstance.put(`/offer/officer/assign-tasks/${id}/assignees`, data);
};

export const improveAndAssignOffer = (data: { offerFormId: number; officerIds: number[] }) => {
  return axiosInstance.post(`/offer/improveAndAssgin`, data);
};
