import { useMemo, useState } from 'react';
import { Divider, FormInstance } from 'antd';

import StateInputForm from './StateInputForm';
import UploadStateFile from './UploadStateFile';

import { IStateData } from 'features/Contract/interfaces';
import { dayJsformatDateObjToString } from 'utils/date';

const STATE_WITHOUT_FILE_UPLOAD = '10';
const ACTION_CODE_WITHOUT_FILE_UPLOAD = '5';

type StepFormWithApproveFileProps = {
  form: FormInstance<any>;
  stepData: IStateData | undefined;
  onUpdateOfferState: Function;
  isDisable: boolean;
  isHideApproveForm?: boolean;
  isShowActionStateOption?: boolean;
  dateLabel?: string;
};

const StepFormWithApproveFile = ({
  form,
  stepData,
  onUpdateOfferState,
  isDisable,
  dateLabel,
  isShowActionStateOption = false,
  isHideApproveForm = false,
}: StepFormWithApproveFileProps) => {
  const [selectedActionCode, setSelectedActionCode] = useState<string>('');
  const isNotDisplayFileUpload = useMemo(() => {
    if (
      stepData?.stateCode === STATE_WITHOUT_FILE_UPLOAD &&
      selectedActionCode === ACTION_CODE_WITHOUT_FILE_UPLOAD
    ) {
      return false;
    }
    return true;
  }, [stepData, selectedActionCode]);

  const onFinish = (values: any) => {
    let _actionDate = new Date(values.actionDate);

    let data: any = {
      stateCode: stepData?.stateCode,
      actionCode: '',
      actionDate: dayJsformatDateObjToString(_actionDate),
      isCompleteState: false,
      isCurrentState: true,
    };

    if (!isHideApproveForm) {
      const _approvedDate = new Date(values.approvedDate);
      data.approvedDate = dayJsformatDateObjToString(_approvedDate);
      data.approvedNo = values.approvedNo;
    }

    if (isShowActionStateOption) {
      data.actionCode = values.actionCode;
    }

    onUpdateOfferState(data);
  };

  return (
    <div>
      <StateInputForm
        form={form}
        isDisable={isDisable}
        isHideApproveForm={isHideApproveForm}
        onFinish={onFinish}
        isShowActionStateOption={isShowActionStateOption}
        stepData={stepData}
        setSelectedActionCode={setSelectedActionCode}
        dateLabel={dateLabel}
      />
      {isNotDisplayFileUpload && (
        <>
          <Divider orientation="left" orientationMargin="0">
            อัพโหลดไฟล์
          </Divider>
          <UploadStateFile
            isDisabled={isDisable}
            stateId={stepData?.formStateId}
            stateCode={stepData?.stateCode}
          />
        </>
      )}
    </div>
  );
};

export default StepFormWithApproveFile;
