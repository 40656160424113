// export function offerStatus(){

import { DefaultOptionType } from 'antd/es/select';

// }
export const offerType: DefaultOptionType[] | undefined = [
  { value: 'RAO', label: 'RAO' },
  { value: 'RIO', label: 'RIO' },
  { value: 'RDRO', label: 'RDRO' },
  { value: 'RISO', label: 'RISO' },
];

export const offerStatus: DefaultOptionType[] | undefined = [
  { value: 'รอยืนยันการบันทึก', label: 'รอยืนยันการบันทึก' },
  { value: 'รอพิมพ์ข้อเสนอ', label: 'รอพิมพ์ข้อเสนอ' },
  { value: 'รอแนบเอกสาร', label: 'รอแนบเอกสาร' },
  { value: 'จัดส่งเอกสารแล้ว', label: 'จัดส่งเอกสารแล้ว' },
  {
    value: 'วันที่ ชท. ได้รับเรื่องข้อเสนอฯ ครั้งแรก',
    label: 'วันที่ ชท. ได้รับเรื่องข้อเสนอฯ ครั้งแรก',
  },
  {
    value: 'อยู่ระหว่างจัดทำหนังสือให้ส่งข้อเสนอฯ (กรณีไม่ได้ส่ง)',
    label: 'อยู่ระหว่างจัดทำหนังสือให้ส่งข้อเสนอฯ (กรณีไม่ได้ส่ง)',
  },
  {
    value: 'อยู่ระหว่างจัดทำหนังสือให้ปรับปรุงข้อเสนอฯ (กรณีส่งแล้ว แต่ไม่ครบถ้วนสอดคล้อง)',
    label: 'อยู่ระหว่างจัดทำหนังสือให้ปรับปรุงข้อเสนอฯ (กรณีส่งแล้ว แต่ไม่ครบถ้วนสอดคล้อง)',
  },
  { value: 'กรณีข้อมูลครบถ้วนถูกต้อง', label: 'กรณีข้อมูลครบถ้วนถูกต้อง' },
  {
    value: 'ส่งออกหนังสือให้ส่ง // ปรับปรุงข้อเสนอฯ แล้วเสร็จ // อยู่ระหว่างรอผู้ประกอบการนำส่ง',
    label: 'ส่งออกหนังสือให้ส่ง // ปรับปรุงข้อเสนอฯ แล้วเสร็จ // อยู่ระหว่างรอผู้ประกอบการนำส่ง',
  },
  {
    value:
      'วันที่ ชท. ได้รับข้อเสนอฯ จนครบถ้วนสมบูรณ์ // อยู่ระหว่างจัดทำวาระเสนอที่ประชุมคณะอนุกลั่นกรองฯ',
    label:
      'วันที่ ชท. ได้รับข้อเสนอฯ จนครบถ้วนสมบูรณ์ // อยู่ระหว่างจัดทำวาระเสนอที่ประชุมคณะอนุกลั่นกรองฯ',
  },
  {
    value:
      'ส่งออกวาระเสนอที่ประชุมคณะอนุกลั่นกรองฯ แล้วเสร็จ // อยู่ระหว่างรอที่ประชุมคณะอนุกลั่นกรองฯ พิจารณา',
    label:
      'ส่งออกวาระเสนอที่ประชุมคณะอนุกลั่นกรองฯ แล้วเสร็จ // อยู่ระหว่างรอที่ประชุมคณะอนุกลั่นกรองฯ พิจารณา',
  },
  {
    value:
      'ที่ประชุมคณะอนุกลั่นกรองฯ พิจารณาแล้วเสร็จ (มติคณะอนุกลั่นกรองฯ ครั้งที่/วันที่) อยู่ระหว่างจัดทำวาระเสนอที่ประชุม กสทช.',
    label:
      'ที่ประชุมคณะอนุกลั่นกรองฯ พิจารณาแล้วเสร็จ (มติคณะอนุกลั่นกรองฯ ครั้งที่/วันที่) อยู่ระหว่างจัดทำวาระเสนอที่ประชุม กสทช.',
  },
  {
    value:
      'ที่ประชุมคณะอนุกลั่นกรองฯ พิจารณาแล้วเสร็จ (มติคณะอนุกลั่นกรองฯ ครั้งที่/วันที่) อยู่ระหว่างจัดทำหนังสือให้ปรับปรุงข้อเสนอฯ',
    label:
      'ที่ประชุมคณะอนุกลั่นกรองฯ พิจารณาแล้วเสร็จ (มติคณะอนุกลั่นกรองฯ ครั้งที่/วันที่) อยู่ระหว่างจัดทำหนังสือให้ปรับปรุงข้อเสนอฯ',
  },
  {
    value: 'ส่งออกวาระเสนอที่ประชุม กสทช. แล้วเสร็จ // อยู่ระหว่างรอที่ประชุม กสทช. พิจารณา',
    label: 'ส่งออกวาระเสนอที่ประชุม กสทช. แล้วเสร็จ // อยู่ระหว่างรอที่ประชุม กสทช. พิจารณา',
  },
  {
    value: 'ที่ประชุม กสทช. พิจารณาแล้วเสร็จ (มติ กสทช. ครั้งที่/วันที่)',
    label: 'ที่ประชุม กสทช. พิจารณาแล้วเสร็จ (มติ กสทช. ครั้งที่/วันที่)',
  },
  { value: 'ส่งออกหนังสือแจ้งมติ กสทช. แล้วเสร็จ', label: 'ส่งออกหนังสือแจ้งมติ กสทช. แล้วเสร็จ' },
  {
    value: 'บริษัทฯ เปิดเผยข้อเสนอฯ เป็นการทั่วไปแล้วเสร็จ',
    label: 'บริษัทฯ เปิดเผยข้อเสนอฯ เป็นการทั่วไปแล้วเสร็จ',
  },
];
