import { routesData } from 'constants/routesWithPerm';
import ListAssignment from 'features/Assignment/ListAssignment';
import ListAssignmentContract from 'features/Contract/pages/Assignment/ListAssignmentContract';
import EditContract from 'features/Contract/pages/EditContract';
import ListContract from 'features/Contract/pages/ListContract';
import EditOfficerContract from 'features/Contract/pages/Officer/EditOfficerContract';
import ListOfficerContract from 'features/Contract/pages/Officer/ListOfficerContract';
import ApprovedContractReport from 'features/Contract/pages/Report/ApprovedContractReport';
import Report004 from 'features/Contract/pages/Report/Report004';
import EditReviewContract from 'features/Contract/pages/Review/EditReviewContract';
import ListReviewContract from 'features/Contract/pages/Review/ListReviewContract';
import GraphDashboard from 'features/Dashboard/GraphDashboard';
import OperatorDashboard from 'features/Dashboard/OperatorDashboard';
import CreateDisagreement from 'features/Disagreement/CreateDisagreement/CreateDisagreement';
import DetailDisagreement from 'features/Disagreement/DetailDisagreement/DetailDisagreement';
import ListDisagreement from 'features/Disagreement/ListDisagreement/ListDisagreement';
import ManageOfferRAO from 'features/ManageOffer/ManageOfferRAO/ListManageOfferRAO';
import ListManageOfferRDRO from 'features/ManageOffer/ManageOfferRDRO/ListManageOfferRDRO';
import ListManageOfferRIO from 'features/ManageOffer/ManageOfferRIO/ListManageOfferRIO';
import ListManageOfferRISO from 'features/ManageOffer/ManageOfferRISO/ListManageOfferRISO';
import ListOfficerOffer from 'features/ManageOfficerOffer/ListOffer';
import EditOffer from 'features/Offer/pages/EditOffer';
import OfferHistory from 'features/Offer/pages/OfferHistory';
import OfferAuditResultsReport from 'features/Offer/pages/Report/OfferAuditResultsReport';
import OfferReviewStatusReport from 'features/Offer/pages/Report/OfferReviewStatusReport';
import OfferSummaryReport from 'features/Offer/pages/Report/SummaryReport';
import EditOfferReview from 'features/OfferConsideration/pages/EditOfferReview';
import ListOfferReview from 'features/OfferConsideration/pages/ListOfferReview';
import ContractPublishUrl from 'features/OfficerPublishUrl/ContractPublisUrl';
import OfferPublishUrl from 'features/OfficerPublishUrl/OfferPublishUrl';
import ListOperatorsLMS from 'features/OperatorsLMS/ListOperatorsLMS';
import ListReportDispute from 'features/ReportDispute/ListReport';
import ReportDataAndConnection from 'features/ReportDataAndConnection/SearchReport/SearchReport';
import ManageUser from 'features/ManageUser/ListUser';
import ListImportDataDocuments from 'features/DataAndConnection/Operator/ImportDocument/ListDocument/ListDocument';
import CreateImportDataDocuments from 'features/DataAndConnection/Operator/ImportDocument/CreateImportData/CreateImportData';
import DetailImportDataDocuments from 'features/DataAndConnection/Operator/ImportDocument/DetailImportData/DetailImportData';
import ListImportDataDocumentsOfficer from 'features/DataAndConnection/Officer/ImportDocument/ListDocument/ListDocument';
import CreateImportDataLikeOperator from 'features/DataAndConnection/Officer/ImportDocument/CreateImportDataLikeOperator/CreateImportDataLikeOperator';
import DetailImportDataDocumentsOfficer from 'features/DataAndConnection/Officer/ImportDocument/DetailImportData/DetailImportData';
import DetailImportDataEdit from 'features/DataAndConnection/Officer/ImportDocument/DetailImportDataEdit/DetailImportDataEdit';
import ListDocumentForCreate from 'features/DataAndConnection/Officer/ImportDocument/ListDocumentForCreate/ListDocument';
import FormDownload from 'features/FormDownload/FormDownload';
import AuditLog from 'features/AuditLog/AuditLog';
import ListCurrencies from 'features/MasterData/Currencies';
import ListUnits from 'features/MasterData/Units';
import ListFacilities from 'features/MasterData/Facilities';
import ListServiceTypes from 'features/MasterData/ServiceTypes';
import ListServices from 'features/MasterData/Services';
import ListInformationInquire from 'features/InformationInquire/ListInformationInquire';
import ListInquiryContract from 'features/Contract/pages/Inquiry/ListInquiryContract';
import DetailInquiryContract from 'features/Contract/pages/Inquiry/DetailInquiryContract';
import ListAgreementForInformation from 'features/Disagreement/ListDisagreementViewOnly/ListDisagreementViewOnly';

export const getFilteredPrivateRoutes = (permissions: string[] | undefined) => {
  return mainRoutes.filter((route) => permissions?.some((perm) => perm === route?.permission));
};

export const mainRoutes = [
  {
    path: routesData.dashboardGraph.route,
    element: <GraphDashboard />,
    permission: routesData.dashboardGraph.permission,
  },
  {
    path: routesData.dashboardOperator.route,
    element: <OperatorDashboard />,
    permission: routesData.dashboardOperator.permission,
  },
  {
    path: routesData.offerRAO.route,
    element: <ManageOfferRAO />,
    permission: routesData.offerRAO.permission,
  },
  {
    path: routesData.offerRIO.route,
    element: <ListManageOfferRIO />,
    permission: routesData.offerRIO.permission,
  },
  {
    path: routesData.offerRDRO.route,
    element: <ListManageOfferRDRO />,
    permission: routesData.offerRDRO.permission,
  },
  {
    path: routesData.offerRISO.route,
    element: <ListManageOfferRISO />,
    permission: routesData.offerRISO.permission,
  },
  {
    path: routesData.offerReview.route,
    element: <ListOfferReview />,
    permission: routesData.offerReview.permission,
  },
  {
    path: routesData.offerOfficer.route,
    element: <ListOfficerOffer />,
    permission: routesData.offerOfficer.permission,
  },
  {
    path: routesData.offerAssignment.route,
    element: <ListAssignment />,
    permission: routesData.offerAssignment.permission,
  },
  {
    path: routesData.offerPublishUrl.route,
    element: <OfferPublishUrl />,
    permission: routesData.offerPublishUrl.permission,
  },
  {
    path: routesData.offerOperatorLMS.route,
    element: <ListOperatorsLMS />,
    permission: routesData.offerOperatorLMS.permission,
  },
  {
    path: routesData.disagreementList.route,
    element: <ListDisagreement />,
    permission: routesData.disagreementList.permission,
  },
  {
    path: routesData.contractUsage.route,
    element: <ListContract />,
    permission: routesData.contractUsage.permission,
  },
  {
    path: routesData.contractConnect.route,
    element: <ListContract />,
    permission: routesData.contractConnect.permission,
  },
  {
    path: routesData.contractNetwork.route,
    element: <ListContract />,
    permission: routesData.contractNetwork.permission,
  },
  {
    path: routesData.contractInfra.route,
    element: <ListContract />,
    permission: routesData.contractInfra.permission,
  },
  {
    path: routesData.contractReview.route,
    element: <ListReviewContract />,
    permission: routesData.contractReview.permission,
  },
  {
    path: routesData.contractOfficer.route,
    element: <ListOfficerContract />,
    permission: routesData.contractOfficer.permission,
  },
  {
    path: routesData.contractPublishUrl.route,
    element: <ContractPublishUrl />,
    permission: routesData.contractPublishUrl.permission,
  },
  {
    path: routesData.contractAssignment.route,
    element: <ListAssignmentContract />,
    permission: routesData.contractAssignment.permission,
  },
  {
    path: routesData.reportDisputeOverview.route,
    element: <ListReportDispute />,
    permission: routesData.reportDisputeOverview.permission,
  },
  {
    path: routesData.reportDisputeTracking.route,
    element: <ListReportDispute />,
    permission: routesData.reportDisputeTracking.permission,
  },
  {
    path: routesData.reportContractReviewStatus.route,
    element: <ApprovedContractReport />,
    permission: routesData.reportContractReviewStatus.permission,
  },
  {
    path: routesData.reportApprovedContract.route,
    element: <Report004 />,
    permission: routesData.reportApprovedContract.permission,
  },
  {
    path: routesData.reportOfferReviewStatus.route,
    element: <OfferReviewStatusReport />,
    permission: routesData.reportOfferReviewStatus.permission,
  },
  {
    path: routesData.reportApprovedOffer.route,
    element: <OfferSummaryReport />,
    permission: routesData.reportApprovedOffer.permission,
  },
  {
    path: routesData.reportOfferAuditResults.route,
    element: <OfferAuditResultsReport />,
    permission: routesData.reportOfferAuditResults.permission,
  },
  {
    path: routesData.reportDataConnection.route,
    element: <ReportDataAndConnection />,
    permission: routesData.reportDataConnection.permission,
  },
  {
    path: routesData.manageUser.route,
    element: <ManageUser />,
    permission: routesData.manageUser.permission,
  },
  {
    path: routesData.dataConnectionOperator.route,
    element: <ListImportDataDocuments />,
    permission: routesData.dataConnectionOperator.permission,
  },
  {
    path: routesData.dataConnectionReview.route,
    element: <ListImportDataDocumentsOfficer />,
    permission: routesData.dataConnectionReview.permission,
  },
  {
    path: routesData.dataConnectionOfficer.route,
    element: <ListDocumentForCreate />,
    permission: routesData.dataConnectionOfficer.permission,
  },
  {
    path: routesData.downloadForm.route,
    element: <FormDownload />,
    permission: routesData.downloadForm.permission,
  },
  {
    path: routesData.auditLogLoginHistory.route,
    element: <AuditLog />,
    permission: routesData.auditLogLoginHistory.permission,
  },
  {
    path: routesData.masterDataCurrencies.route,
    element: <ListCurrencies />,
    permission: routesData.masterDataCurrencies.permission,
  },
  {
    path: routesData.masterDataUnits.route,
    element: <ListUnits />,
    permission: routesData.masterDataUnits.permission,
  },
  {
    path: routesData.masterDataFacilities.route,
    element: <ListFacilities />,
    permission: routesData.masterDataFacilities.permission,
  },
  {
    path: routesData.masterDataServiceTypes.route,
    element: <ListServiceTypes />,
    permission: routesData.masterDataServiceTypes.permission,
  },
  {
    path: routesData.masterDataServices.route,
    element: <ListServices />,
    permission: routesData.masterDataServices.permission,
  },
  {
    path: routesData.inquiryOffer.route,
    element: <ListInformationInquire />,
    permission: routesData.inquiryOffer.permission,
  },
  {
    path: routesData.inquiryContract.route,
    element: <ListInquiryContract />,
    permission: routesData.inquiryContract.permission,
  },
  {
    path: routesData.inquiryDisagreement.route,
    element: <ListAgreementForInformation />,
    permission: routesData.inquiryDisagreement.permission,
  },
];

export const subRoutes = [
  {
    path: routesData.offerRAONew.route,
    element: <EditOffer />,
    permission: routesData.offerRAONew.permission,
  },
  {
    path: routesData.offerRAOEdit.route,
    element: <EditOffer />,
    permission: routesData.offerRAOEdit.permission,
  },
  {
    path: routesData.offerRAODetail.route,
    element: <EditOffer />,
    permission: routesData.offerRAODetail.permission,
  },
  {
    path: routesData.offerRAOInquiry.route,
    element: <EditOffer />,
    permission: routesData.offerRAOInquiry.permission,
  },
  {
    path: routesData.offerRAOHistory.route,
    element: <OfferHistory />,
    permission: routesData.offerRAOHistory.permission,
  },
  {
    path: routesData.offerRIONew.route,
    element: <EditOffer />,
    permission: routesData.offerRIONew.permission,
  },
  {
    path: routesData.offerRIOEdit.route,
    element: <EditOffer />,
    permission: routesData.offerRIOEdit.permission,
  },
  {
    path: routesData.offerRIODetail.route,
    element: <EditOffer />,
    permission: routesData.offerRIODetail.permission,
  },
  {
    path: routesData.offerRIOInquiry.route,
    element: <EditOffer />,
    permission: routesData.offerRIOInquiry.permission,
  },
  {
    path: routesData.offerRIOHistory.route,
    element: <OfferHistory />,
    permission: routesData.offerRIOHistory.permission,
  },
  {
    path: routesData.offerRDRONew.route,
    element: <EditOffer />,
    permission: routesData.offerRDRONew.permission,
  },
  {
    path: routesData.offerRDROEdit.route,
    element: <EditOffer />,
    permission: routesData.offerRDROEdit.permission,
  },
  {
    path: routesData.offerRDRODetail.route,
    element: <EditOffer />,
    permission: routesData.offerRDRODetail.permission,
  },
  {
    path: routesData.offerRDROInquiry.route,
    element: <EditOffer />,
    permission: routesData.offerRDROInquiry.permission,
  },
  {
    path: routesData.offerRDROHistory.route,
    element: <OfferHistory />,
    permission: routesData.offerRDROHistory.permission,
  },
  {
    path: routesData.offerRISONew.route,
    element: <EditOffer />,
    permission: routesData.offerRISONew.permission,
  },
  {
    path: routesData.offerRISOEdit.route,
    element: <EditOffer />,
    permission: routesData.offerRISOEdit.permission,
  },
  {
    path: routesData.offerRISODetail.route,
    element: <EditOffer />,
    permission: routesData.offerRISODetail.permission,
  },
  {
    path: routesData.offerRISOInquiry.route,
    element: <EditOffer />,
    permission: routesData.offerRISOInquiry.permission,
  },
  {
    path: routesData.offerRISOHistory.route,
    element: <OfferHistory />,
    permission: routesData.offerRISOHistory.permission,
  },
  {
    path: routesData.offerReviewEdit.route,
    element: <EditOfferReview />,
    permission: routesData.offerReviewEdit.permission,
  },
  {
    path: routesData.disagreementNew.route,
    element: <CreateDisagreement />,
    permission: routesData.disagreementNew.permission,
  },
  {
    path: routesData.disagreementEdit.route,
    element: <DetailDisagreement />,
    permission: routesData.disagreementEdit.permission,
  },
  {
    path: routesData.contractUsageNew.route,
    element: <EditContract />,
    permission: routesData.contractUsageNew.permission,
  },
  {
    path: routesData.contractUsageEdit.route,
    element: <EditContract />,
    permission: routesData.contractUsageEdit.permission,
  },
  {
    path: routesData.contractUsageHistory.route,
    element: <EditContract />,
    permission: routesData.contractUsageHistory.permission,
  },
  {
    path: routesData.contractConnectNew.route,
    element: <EditContract />,
    permission: routesData.contractConnectNew.permission,
  },
  {
    path: routesData.contractConnectEdit.route,
    element: <EditContract />,
    permission: routesData.contractConnectEdit.permission,
  },
  {
    path: routesData.contractConnectHistory.route,
    element: <EditContract />,
    permission: routesData.contractConnectHistory.permission,
  },
  {
    path: routesData.contractNetworkNew.route,
    element: <EditContract />,
    permission: routesData.contractNetworkNew.permission,
  },
  {
    path: routesData.contractNetworkEdit.route,
    element: <EditContract />,
    permission: routesData.contractNetworkEdit.permission,
  },
  {
    path: routesData.contractNetworkHistory.route,
    element: <EditContract />,
    permission: routesData.contractNetworkHistory.permission,
  },
  {
    path: routesData.contractInfraNew.route,
    element: <EditContract />,
    permission: routesData.contractInfraNew.permission,
  },
  {
    path: routesData.contractInfraEdit.route,
    element: <EditContract />,
    permission: routesData.contractInfraEdit.permission,
  },
  {
    path: routesData.contractInfraHistory.route,
    element: <EditContract />,
    permission: routesData.contractInfraHistory.permission,
  },
  {
    path: routesData.contractReviewEdit.route,
    element: <EditReviewContract />,
    permission: routesData.contractReviewEdit.permission,
  },
  {
    path: routesData.contractOfficerNew.route,
    element: <EditOfficerContract />,
    permission: routesData.contractOfficerNew.permission,
  },
  {
    path: routesData.contractOfficerEdit.route,
    element: <EditOfficerContract />,
    permission: routesData.contractOfficerEdit.permission,
  },
  {
    path: routesData.dataConOperatorNew.route,
    element: <CreateImportDataDocuments />,
    permission: routesData.dataConOperatorNew.permission,
  },
  {
    path: routesData.dataConOperatorEdit.route,
    element: <DetailImportDataDocuments />,
    permission: routesData.dataConOperatorEdit.permission,
  },
  {
    path: routesData.dataConReviewNew.route,
    element: <CreateImportDataLikeOperator />,
    permission: routesData.dataConReviewNew.permission,
  },
  {
    path: routesData.dataConReviewDetail.route,
    element: <DetailImportDataDocumentsOfficer />,
    permission: routesData.dataConReviewDetail.permission,
  },
  {
    path: routesData.dataConReviewEdit.route,
    element: <DetailImportDataEdit />,
    permission: routesData.dataConReviewEdit.permission,
  },
  {
    path: routesData.inquiryContractDetail.route,
    element: <DetailInquiryContract />,
    permission: routesData.inquiryContractDetail.permission,
  },
];
