import {
  createOfferSummaryReports,
  downloadOfferSummaryReportByJobId,
  getOfferSummaryReportByJobId,
  listOfferSummaryReports,
} from 'api/report';
import GenericReport from 'components/GenericReport';

import { REPORT_001_NAME } from 'features/Offer/constant/reportsName';

const OfferSummaryReport = () => {
  return (
    <>
      <GenericReport
        pageTitle={REPORT_001_NAME}
        createReportApi={createOfferSummaryReports}
        listReportApi={listOfferSummaryReports}
        getReportByJobIdApi={getOfferSummaryReportByJobId}
        downloadReportByJobIdApi={downloadOfferSummaryReportByJobId}
        isRequiredStartDate={true}
        isRequiredEndDate={true}
      />
    </>
  );
};

export default OfferSummaryReport;
