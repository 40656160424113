/* eslint-disable */

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { getUsageForm, updateUsageFormStatusForOfficer } from 'api/usageForm';
import { GetUsageFormResponse, RawReportAttachFile, UsageForm } from 'api/usageForm/interface';
import { Col, Form, Row, Select } from 'antd';
import { ButtonComponent, HeaderTitleComponent } from 'components';
import FormFilesUpload from '../components/Form-Files-Upload';
import { parseInt } from 'lodash';
import { useForm } from 'antd/es/form/Form';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import FormFileUploadWithOutDropDown from 'features/DataAndConnection/Officer/ImportDocument/components/FormFileUploadWithOutDropDown';
import { mainRoutePaths } from 'constants/routes';

const DetailImportData = (): JSX.Element => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { makeRequest: getUsageFormRequest } = useApi();
  const { makeRequest: updateStatusRequest } = useApi();
  const [usageForm, setUsageForm] = useState<UsageForm>();
  const [rawReportAttachFile, setRawReportAttachFile] = useState<RawReportAttachFile[]>([]);
  const [disable, setDisable] = useState(true);
  const [form] = useForm();
  const { id } = useParams();
  const navigate = useNavigate();

  const onUpdateStatus = () => {
    if (id) {
      updateStatusRequest(async () => {
        await updateUsageFormStatusForOfficer({
          id: parseInt(id),
          status: form.getFieldValue('result'),
          usageAttachFileRequests: [],
        });
        const res: any = await getUsageForm(parseInt(id));
        const result: GetUsageFormResponse = res.data;
        setUsageForm(result.data);
        return res;
      });
    }
  };

  const onDropDownChange = () => {
    setDisable(false);
  };

  useEffect(() => {
    setHeaderTitle('เพิ่ม/แก้ไข ข้อมูลนำส่งรายไตรมาศ');
    if (id) {
      getUsageFormRequest(async () => {
        const res: any = await getUsageForm(parseInt(id));
        const result: GetUsageFormResponse = res.data;
        setUsageForm(result.data);
        setRawReportAttachFile(result.data.rawReportAttachFiles);
        form.setFields([
          {
            name: 'result',
            value: result.data.considerResult,
          },
        ]);
        return res;
      });
    }
  }, [id]);
  return (
    <div>
      <HeaderTitleComponent>รายละเอียด</HeaderTitleComponent>
      <div style={{ padding: 24 }}>
        <Row>
          <Col span={6}>ปี พ.ศ. : {usageForm?.year}</Col>
          <Col span={6}>ไตรมาสที่ : {usageForm?.quarter}</Col>
          <Col>สถานะการนำส่งข้อมูล: {usageForm ? usageForm.formStatusName : ''}</Col>
        </Row>

        <Row
          style={{
            marginTop: 64,
          }}
        >
          <Col span={24}>
            <FormFilesUpload
              id={parseInt(id || '1', 0)}
              status={usageForm?.formStatus}
            ></FormFilesUpload>
          </Col>
          <Col span={24}>
            {usageForm && (
              <FormFileUploadWithOutDropDown
                files={rawReportAttachFile}
                id={parseInt(id || '1', 0)}
                status={usageForm.formStatus}
              />
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div>ผลการพิจารณา</div>
            <Form form={form}>
              <Form.Item name="result">
                <Select
                  placeholder="เลือกผลการพิจารณา"
                  disabled={usageForm?.formStatus === 4 || usageForm?.formStatus === 3}
                  style={{ width: 240, marginTop: 16 }}
                  onChange={onDropDownChange}
                  options={[
                    { value: 'ผ่าน', label: 'ผ่าน' },
                    { value: 'ไม่ผ่าน', label: 'ไม่ผ่าน' },
                    { value: 'รอพิจารณา', label: 'รอพิจารณา' },
                  ]}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <Row justify={'end'} gutter={[16, 16]}>
          <Col flex={'80px'}>
            <ButtonComponent
              htmlType="button"
              key={'navigate-back'}
              onClick={() => {
                navigate(mainRoutePaths.dataConnectionReview);
              }}
            >
              <RollbackOutlined />
              ย้อนกลับ
            </ButtonComponent>
          </Col>
          <Col flex={'80px'}>
            <ButtonComponent
              htmlType="button"
              key={'save'}
              disabled={usageForm?.formStatus === 4 || disable}
              onClick={onUpdateStatus}
            >
              <SaveOutlined />
              บันทึก
            </ButtonComponent>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default DetailImportData;
