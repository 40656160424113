import { Table } from 'antd';

import { IOfferPublishUrl } from '../../interfaces';

type TableResultListProps = {
  data: IOfferPublishUrl[];
  loading: boolean;
  paginationSetting: any;
  columns: any;
  rowKey: string;
};

const TableResultList = ({
  data,
  loading,
  paginationSetting,
  columns,
  rowKey,
}: TableResultListProps) => {
  return (
    <div className="content-box">
      <Table
        size="middle"
        rowKey={rowKey}
        style={{ whiteSpace: 'pre' }}
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={paginationSetting}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default TableResultList;
