import { programCode } from './programCode';
import { mainRoutePaths, subRoutePaths } from './routes';

// MAY'S NOTE: The reason I create this file is to have a single source of truth for mapping of routes and permissions
export const routesData = {
  // NOTE: Main Routes
  dashboardGraph: {
    route: mainRoutePaths.dashboardGraph,
    permission: programCode.A000001,
  },
  dashboardOperator: {
    route: mainRoutePaths.dashboardOperator,
    permission: programCode.A000002,
  },
  offerRAO: {
    route: mainRoutePaths.offerRAO,
    permission: programCode.A000003,
  },
  offerRIO: {
    route: mainRoutePaths.offerRIO,
    permission: programCode.A000009,
  },
  offerRDRO: {
    route: mainRoutePaths.offerRDRO,
    permission: programCode.A000015,
  },
  offerRISO: {
    route: mainRoutePaths.offerRISO,
    permission: programCode.A000021,
  },
  offerReview: {
    route: mainRoutePaths.offerReview,
    permission: programCode.A000027,
  },
  offerOfficer: {
    route: mainRoutePaths.offerOfficer,
    permission: programCode.A000029,
  },
  offerAssignment: {
    route: mainRoutePaths.offerAssignment,
    permission: programCode.A000030,
  },
  offerPublishUrl: {
    route: mainRoutePaths.offerPublishUrl,
    permission: programCode.A000031,
  },
  offerOperatorLMS: {
    route: mainRoutePaths.offerOperatorLMS,
    permission: programCode.A000032,
  },
  disagreementList: {
    route: mainRoutePaths.disagreementList,
    permission: programCode.A000033,
  },
  contractUsage: {
    route: mainRoutePaths.contractUsage,
    permission: programCode.A000036,
  },
  contractConnect: {
    route: mainRoutePaths.contractConnect,
    permission: programCode.A000040,
  },
  contractNetwork: {
    route: mainRoutePaths.contractNetwork,
    permission: programCode.A000044,
  },
  contractInfra: {
    route: mainRoutePaths.contractInfra,
    permission: programCode.A000048,
  },
  contractReview: {
    route: mainRoutePaths.contractReview,
    permission: programCode.A000052,
  },
  contractOfficer: {
    route: mainRoutePaths.contractOfficer,
    permission: programCode.A000054,
  },
  contractPublishUrl: {
    route: mainRoutePaths.contractPublishUrl,
    permission: programCode.A000057,
  },
  contractAssignment: {
    route: mainRoutePaths.contractAssignment,
    permission: programCode.A000058,
  },
  reportDisputeOverview: {
    route: mainRoutePaths.reportDisputeOverview,
    permission: programCode.A000059,
  },
  reportDisputeTracking: {
    route: mainRoutePaths.reportDisputeTracking,
    permission: programCode.A000060,
  },
  reportContractReviewStatus: {
    route: mainRoutePaths.reportContractReviewStatus,
    permission: programCode.A000061,
  },
  reportApprovedContract: {
    route: mainRoutePaths.reportApprovedContract,
    permission: programCode.A000062,
  },
  reportOfferReviewStatus: {
    route: mainRoutePaths.reportOfferReviewStatus,
    permission: programCode.A000063,
  },
  reportApprovedOffer: {
    route: mainRoutePaths.reportApprovedOffer,
    permission: programCode.A000064,
  },
  reportOfferAuditResults: {
    route: mainRoutePaths.reportOfferAuditResults,
    permission: programCode.A000065,
  },
  reportDataConnection: {
    route: mainRoutePaths.reportDataConnection,
    permission: programCode.A000066,
  },
  manageUser: {
    route: mainRoutePaths.manageUser,
    permission: programCode.A000067,
  },
  dataConnectionOperator: {
    route: mainRoutePaths.dataConnectionOperator,
    permission: programCode.A000068,
  },
  dataConnectionReview: {
    route: mainRoutePaths.dataConnectionReview,
    permission: programCode.A000071,
  },
  dataConnectionOfficer: {
    route: mainRoutePaths.dataConnectionOfficer,
    permission: programCode.A000075,
  },
  downloadForm: {
    route: mainRoutePaths.downloadForm,
    permission: programCode.A000076,
  },
  auditLogLoginHistory: {
    route: mainRoutePaths.auditLogLoginHistory,
    permission: programCode.A000077,
  },
  masterDataCurrencies: {
    route: mainRoutePaths.masterDataCurrencies,
    permission: programCode.A000078,
  },
  masterDataUnits: {
    route: mainRoutePaths.masterDataUnits,
    permission: programCode.A000079,
  },
  masterDataFacilities: {
    route: mainRoutePaths.masterDataFacilities,
    permission: programCode.A000080,
  },
  masterDataServiceTypes: {
    route: mainRoutePaths.masterDataServiceTypes,
    permission: programCode.A000081,
  },
  masterDataServices: {
    route: mainRoutePaths.masterDataServices,
    permission: programCode.A000082,
  },
  inquiryOffer: {
    route: mainRoutePaths.inquiryOffer,
    permission: programCode.A000083,
  },
  inquiryContract: {
    route: mainRoutePaths.inquiryContract,
    permission: programCode.A000084,
  },
  inquiryDisagreement: {
    route: mainRoutePaths.inquiryDisagreement,
    permission: programCode.A000086,
  },

  // NOTE: Sub Routes
  offerRAONew: {
    route: subRoutePaths.offerRAONew,
    permission: programCode.A000004,
  },
  offerRAOEdit: {
    route: subRoutePaths.offerRAOEdit(':offerId'),
    permission: programCode.A000005,
  },
  offerRAODetail: {
    route: subRoutePaths.offerRAODetail(':offerId'),
    permission: programCode.A000006,
  },
  offerRAOInquiry: {
    route: subRoutePaths.offerRAOInquiry(':offerId'),
    permission: programCode.A000007,
  },
  offerRAOHistory: {
    route: subRoutePaths.offerRAOHistory(':offerId'),
    permission: programCode.A000008,
  },
  offerRIONew: {
    route: subRoutePaths.offerRIONew,
    permission: programCode.A000010,
  },
  offerRIOEdit: {
    route: subRoutePaths.offerRIOEdit(':offerId'),
    permission: programCode.A000011,
  },
  offerRIODetail: {
    route: subRoutePaths.offerRIODetail(':offerId'),
    permission: programCode.A000012,
  },
  offerRIOInquiry: {
    route: subRoutePaths.offerRIOInquiry(':offerId'),
    permission: programCode.A000013,
  },
  offerRIOHistory: {
    route: subRoutePaths.offerRIOHistory(':offerId'),
    permission: programCode.A000014,
  },
  offerRDRONew: {
    route: subRoutePaths.offerRDRONew,
    permission: programCode.A000016,
  },
  offerRDROEdit: {
    route: subRoutePaths.offerRDROEdit(':offerId'),
    permission: programCode.A000017,
  },
  offerRDRODetail: {
    route: subRoutePaths.offerRDRODetail(':offerId'),
    permission: programCode.A000018,
  },
  offerRDROInquiry: {
    route: subRoutePaths.offerRDROInquiry(':offerId'),
    permission: programCode.A000019,
  },
  offerRDROHistory: {
    route: subRoutePaths.offerRDROHistory(':offerId'),
    permission: programCode.A000020,
  },
  offerRISONew: {
    route: subRoutePaths.offerRISONew,
    permission: programCode.A000022,
  },
  offerRISOEdit: {
    route: subRoutePaths.offerRISOEdit(':offerId'),
    permission: programCode.A000023,
  },
  offerRISODetail: {
    route: subRoutePaths.offerRISODetail(':offerId'),
    permission: programCode.A000024,
  },
  offerRISOInquiry: {
    route: subRoutePaths.offerRISOInquiry(':offerId'),
    permission: programCode.A000025,
  },
  offerRISOHistory: {
    route: subRoutePaths.offerRISOHistory(':offerId'),
    permission: programCode.A000026,
  },
  offerReviewEdit: {
    route: subRoutePaths.offerReviewEdit(':offerId'),
    permission: programCode.A000028,
  },
  disagreementNew: {
    route: subRoutePaths.disagreementNew,
    permission: programCode.A000034,
  },
  disagreementEdit: {
    route: subRoutePaths.disagreementEdit(':id'),
    permission: programCode.A000035,
  },
  contractUsageNew: {
    route: subRoutePaths.contractUsageNew,
    permission: programCode.A000037,
  },
  contractUsageEdit: {
    route: subRoutePaths.contractUsageEdit(':conFormId'),
    permission: programCode.A000038,
  },
  contractUsageHistory: {
    route: subRoutePaths.contractUsageHistory(':conFormId'),
    permission: programCode.A000039,
  },
  contractConnectNew: {
    route: subRoutePaths.contractConnectNew,
    permission: programCode.A000041,
  },
  contractConnectEdit: {
    route: subRoutePaths.contractConnectEdit(':conFormId'),
    permission: programCode.A000042,
  },
  contractConnectHistory: {
    route: subRoutePaths.contractConnectHistory(':conFormId'),
    permission: programCode.A000043,
  },
  contractNetworkNew: {
    route: subRoutePaths.contractNetworkNew,
    permission: programCode.A000045,
  },
  contractNetworkEdit: {
    route: subRoutePaths.contractNetworkEdit(':conFormId'),
    permission: programCode.A000046,
  },
  contractNetworkHistory: {
    route: subRoutePaths.contractNetworkHistory(':conFormId'),
    permission: programCode.A000047,
  },
  contractInfraNew: {
    route: subRoutePaths.contractInfraNew,
    permission: programCode.A000049,
  },
  contractInfraEdit: {
    route: subRoutePaths.contractInfraEdit(':conFormId'),
    permission: programCode.A000050,
  },
  contractInfraHistory: {
    route: subRoutePaths.contractInfraHistory(':conFormId'),
    permission: programCode.A000051,
  },
  contractReviewEdit: {
    route: subRoutePaths.contractReviewEdit(':conFormId'),
    permission: programCode.A000053,
  },
  contractOfficerNew: {
    route: subRoutePaths.contractOfficerNew(':offerFormType'),
    permission: programCode.A000055,
  },
  contractOfficerEdit: {
    route: subRoutePaths.contractOfficerEdit(':conFormId'),
    permission: programCode.A000056,
  },
  dataConOperatorNew: {
    route: subRoutePaths.dataConOperatorNew,
    permission: programCode.A000069,
  },
  dataConOperatorEdit: {
    route: subRoutePaths.dataConOperatorEdit(':id'),
    permission: programCode.A000070,
  },
  dataConReviewNew: {
    route: subRoutePaths.dataConReviewNew,
    permission: programCode.A000072,
  },
  dataConReviewDetail: {
    route: subRoutePaths.dataConReviewDetail(':id'),
    permission: programCode.A000073,
  },
  dataConReviewEdit: {
    route: subRoutePaths.dataConReviewEdit(':id'),
    permission: programCode.A000074,
  },
  inquiryContractDetail: {
    route: subRoutePaths.inquiryContractDetail(':conFormId'),
    permission: programCode.A000085,
  },
};
