import { useState } from 'react';
import { IOfficerUser } from '../interfaces';
import { FormInstance } from 'antd';

const useModal = (formUser: FormInstance<any>) => {
  const [recordData, setRecordData] = useState<IOfficerUser | undefined>();
  const [isOpenModalUser, setIsOpenModalUser] = useState<boolean>(false);

  const onOpenModalUser = () => {
    setIsOpenModalUser(true);
  };
  const onCancelModalUser = () => {
    setRecordData(undefined);
    formUser.resetFields();
    setIsOpenModalUser(false);
  };
  const onUserRecord = (record: IOfficerUser) => {
    setRecordData({ ...record });
    onOpenModalUser();
  };
  return { onOpenModalUser, onCancelModalUser, isOpenModalUser, onUserRecord, recordData };
};

export default useModal;
