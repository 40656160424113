import { NavigateFunction } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { AuditOutlined, FileOutlined } from '@ant-design/icons';

import { getTableIndex } from 'utils/table';

import { DataType } from '.';
import { dayJsformatDateToBCYear } from 'utils/date';
import { subRoutePaths } from 'constants/routes';

type TableColumnsProps = {
  pathname: string;
  navigate: NavigateFunction;
  page: number;
  pageSize: number;
  onModalOpenHistory: Function;
};

export const tableColumns = ({
  pathname,
  navigate,
  page,
  pageSize,
  onModalOpenHistory,
}: TableColumnsProps) => {
  const columns: ColumnsType<DataType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      render: (_, __, index) => <>{getTableIndex(page, pageSize, index)}</>,
    },
    {
      title: 'ประเภทข้อเสนอ',
      dataIndex: 'offerFormTypeName',
      key: 'offerFormTypeName',
      align: 'center',
    },
    {
      title: 'กลุ่มข้อเสนอ',
      dataIndex: 'offerFormGroupName',
      key: 'offerFormGroupName',
      align: 'center',
    },
    {
      title: 'ผู้ประกอบการ',
      dataIndex: 'operName',
      key: 'operName',
    },
    {
      title: 'เลขที่/วันที่หนังสือผู้ประกอบการ',
      dataIndex: 'offerFormNo',
      key: 'offerFormNo',
      align: 'center',
      render: (value, record) => {
        if (record.offerFormDate) {
          return `${value} ${dayJsformatDateToBCYear(record.offerFormDate)}`;
        }
        return value;
      },
    },
    {
      title: 'สถานะการจัดทำข้อเสนอ',
      dataIndex: 'offerFormStatusName',
      key: 'offerFormStatusName',
    },
    {
      title: 'ผู้ได้รับมอบหมาย',
      dataIndex: 'offerResponsibilityUser',
      key: 'offerResponsibilityUser',
    },
    {
      title: 'จำนวนครั้งที่แก้ไข',
      dataIndex: 'numOfEdit',
      key: 'numOfEdit',
      align: 'center',
    },
    {
      title: 'ประวัติรายการ',
      dataIndex: 'fixme3',
      key: 'fixme3',
      align: 'center',
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>
          <FileOutlined
            className="file-outlined-css"
            onClick={() => onModalOpenHistory(record.offerFormId)}
          />
        </div>
      ),
    },
    {
      title: 'พิจารณา/รายละเอียด',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div
          onClick={() => {
            navigate(subRoutePaths.offerReviewEdit(record.offerFormId), {
              state: { from: pathname },
            });
          }}
        >
          <AuditOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
        </div>
      ),
    },
  ];
  return columns;
};
