/* eslint-disable */

import { Checkbox, Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { useApi } from 'hooks/useApi';
import { Disagreement } from 'api/disagreement/interface';
import { useForm } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormFilesUpload from './components/Form-Files-Upload';
import { UpdateDisagreementState, updateSaveForm } from 'api/disagreement';
import { convertDataFormDatePickerToString, convertDateFromApiToDatePickerWithTimeZoneAsian } from 'utils/date';

interface Form16Prop {
  isCompleted: boolean;
  disagreement?: Disagreement,
  completeDate?: string,
  stepState: number,
  onActionSuccess: () => void
}

const Form1 = ({ isCompleted, stepState, disagreement, completeDate, onActionSuccess }: Form16Prop): JSX.Element => {
  const [form] = useForm();
  const [disableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { makeRequest: UpdateSaveRequest } = useApi();
  const { makeRequest: UpdateStateFormRequest } = useApi();

  useEffect(() => {
    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: new Date(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: new Date(),
        },
      ]);
    }

  }, [disagreement, completeDate]);

  useEffect(() => {
    if (disagreement) {
      form.setFields([
        {
          name: 'receiveDocumentCompleteDate',
          value: disagreement.receiveDocumentCompleteDate ? convertDateFromApiToDatePickerWithTimeZoneAsian(disagreement.receiveDocumentCompleteDate) : '',
        },

      ]);
    }
  }, [disagreement]);

  const [checkbox, setCheckBox] = useState(false);

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (disagreement) {
      await UpdateSaveRequest(async () => {
        return await updateSaveForm({
          formId: disagreement.formId,
          stateId: stepState,
          completeDate: convertDataFormDatePickerToString(form.getFieldValue('completeDate')),
          receiveDocumentCompleteDate: convertDataFormDatePickerToString(form.getFieldValue('receiveDocumentCompleteDate')),
        }).catch(() => setLoading(false));
      });
      if (checkbox) {
        setLoading(true);
        await UpdateStateFormRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId).catch(() => {
            setLoading(false);
          });
        });
      }
    }
    setLoading(false);
    onActionSuccess();
  };
  return <>
    <Form form={form}>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item labelCol={{
            span: 5,
          }} labelAlign={'left'} label='วันที่ได้รับคำร้องและเอกสารหลักฐาน' name='receiveDocumentCompleteDate'>
            <ThaiDatepickerComponent
              style={{
                width: '300px',
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <div style={{
      marginTop: '24px',
    }}>
      <FormFilesUpload stepState={stepState} disagreementFormId={disagreement?.formId || 0} state={1} />
    </div>
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox disabled={isCompleted} checked={checkbox}
                    onChange={onCheckBoxChange}>ยืนยันการพิจารณา</Checkbox>
        </Col>
        <Col span={8}>
          <Form.Item labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }
      }>
        <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onSubmit}>
          บันทึกรายการ
        </ButtonComponent>
      </div>
    </Form>
  </>;
};

export default Form1;
