import { Upload, UploadProps } from 'antd';

import { isLtXMb } from 'utils/file';
// import { allowedFileTypes } from 'constants/file';

import useDialogAlert from 'hooks/useDialogAlert';

type UploadButtonProps = {
  handleUpload: Function;
  setCurrentUploadFile: Function;
  children?: React.ReactNode;
  isOpenFileDialog: boolean;
  isDisabled: boolean;
};

const UploadButton = ({
  handleUpload,
  setCurrentUploadFile,
  children,
  isOpenFileDialog,
  isDisabled,
}: UploadButtonProps) => {
  const { error } = useDialogAlert();
  const props: UploadProps = {
    name: 'file',
    openFileDialogOnClick: isOpenFileDialog,
    customRequest: () => handleUpload(),
    maxCount: 1,
    beforeUpload: async (file) => {
      try {
        // NOTE: Check file size
        if (!isLtXMb(file.size, 25)) {
          console.error('File must smaller than 20MB!');
          error({
            header: 'ไฟล์ขนาดใหญ่เกิน 20MB',
            sub: 'กรุณาลองใหม่อีกครั้ง',
          });
          return false;
        }

        // NOTE: Check file type
        // if (!allowedFileTypes.includes(file.type)) {
        //   console.error('File type not supported');
        //   error({
        //     header: 'ไม่รองรับประเภทไฟล์ที่อัพโหลด',
        //     sub: 'กรุณาลองใหม่อีกครั้ง',
        //   });
        //   return false;
        // }

        setCurrentUploadFile(file);
        return true;
      } catch (errorInfo) {
        return false;
      }
    },
    showUploadList: false,
    disabled: isDisabled,
  };

  return (
    <>
      <Upload {...props}>{children}</Upload>
    </>
  );
};

export default UploadButton;
