/* eslint-disable */
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { getContractChart, getOfferChart, getOperatorChart } from 'api/dashboard';
import useAuth from 'hooks/useAuth';
import { dayJsformatDateToBCYear } from 'utils/date';

interface OfferData {
  key: number;
  offerFormType: string;
  offerFormNo?: string;
  offerFormDate?: string;
  offerFormStatusName: string;
}

const columnsOffer: ColumnsType<OfferData> = [
  {
    title: 'ประเภทข้อเสนอ',
    dataIndex: 'offerFormType',
    key: 'offerFormType',
    render: (text) => <div>{text}</div>,
  },
  {
    title: 'เลขที่/วันที่หนังสือผู้ประกอบการ',
    dataIndex: 'value',
    key: 'value',
    render: (value, record, index) => {
      return (
        <div>
          {record.offerFormDate
            ? record.offerFormNo +
              '/' +
              dayJsformatDateToBCYear(new Date(record.offerFormDate).toString())
            : ''}
        </div>
      );
    },
  },

  {
    title: 'สถานะ',
    dataIndex: 'offerFormStatusName',
    key: 'offerFormStatusName',
  },
];

interface ContractData {
  key: number;
  contractName: string;
  formNo: string;
  formDate: string;
  formStatusName: string;
}

const columnsContractData: ColumnsType<ContractData> = [
  {
    title: 'ประเภทข้อเสนอ',
    dataIndex: 'contractName',
    key: 'contractName',
  },
  {
    title: 'เลขที่/วันที่หนังสือผู้ประกอบการ',
    dataIndex: 'formNo',
    key: 'formNo',
    render: (value, record, index) => {
      return (
        <div>
          {record.formNo} / {dayJsformatDateToBCYear(new Date(record.formDate).toString())}
        </div>
      );
    },
  },
  {
    title: 'สถานะ',
    dataIndex: 'formStatusName',
    key: 'formStatusName',
  },
];

interface OperatorData {
  key: number;
  operatorName: string;
  year: number;
  quarter: number;
  formStatusName: string;
}

const columnsOperatorData: ColumnsType<OperatorData> = [
  {
    title: 'ผู้ประกอบการ',
    dataIndex: 'operatorName',
    key: 'operatorName',
  },
  {
    title: 'ปี',
    dataIndex: 'year',
    key: 'year',
  },
  {
    title: 'ไตรมาส',
    dataIndex: 'quarter',
    key: 'quarter',
  },
  {
    title: 'สถานะ',
    dataIndex: 'formStatusName',
    key: 'formStatusName',
  },
];

const OperatorDashboardComponent = (): JSX.Element => {
  const auth = useAuth();
  const { makeRequest: GetOperatorDataRequest, loading: operatorLoading } = useApi();
  const { makeRequest: GetContractDataRequest, loading: contractLoading } = useApi();
  const { makeRequest: GetOfferDataRequest, loading: offerLoading } = useApi();

  const [operatorData, setOperatorData] = useState<OperatorData[]>();
  const [contractData, setContractData] = useState<ContractData[]>();
  const [offerData, setOfferData] = useState<OfferData[]>();

  useEffect(() => {
    if (auth && auth.user?.referenceNo) {
      GetOperatorDataRequest(async () => {
        // @ts-ignore
        const res = await getOperatorChart(auth.user.referenceNo);
        setOperatorData(res.data.data.map((i, index) => ({ ...i, key: index })));
        return res;
      });
      GetContractDataRequest(async () => {
        // @ts-ignore
        const res = await getContractChart(auth.user.referenceNo);
        setContractData(res.data.data.map((i, index) => ({ ...i, key: index })));
        return res;
      });

      GetOfferDataRequest(async () => {
        // @ts-ignore
        const res = await getOfferChart(auth.user.referenceNo);
        setOfferData(res.data.data.map((i, index) => ({ ...i, key: index })));
        return res;
      });
    }
  }, []);
  return (
    <div>
      <h2 style={{ marginTop: '24px' }}>ข้อเสนอ</h2>
      <Table
        style={{ marginTop: '24px' }}
        rowKey={'key'}
        pagination={{ pageSize: 10, showTotal: (value) => <div>ทั้งหมด {value} รายการ</div> }}
        loading={offerLoading}
        columns={columnsOffer}
        dataSource={offerData}
      ></Table>
      <h2 style={{ marginTop: '24px' }}>สัญญา</h2>

      <Table
        style={{ marginTop: '24px' }}
        rowKey={'key'}
        pagination={{ pageSize: 10, showTotal: (value) => <div>ทั้งหมด {value} รายการ</div> }}
        loading={contractLoading}
        columns={columnsContractData}
        dataSource={contractData}
      ></Table>
      <h2 style={{ marginTop: '24px' }}>นำส่งข้อมูล</h2>
      <Table
        style={{ marginTop: '24px' }}
        rowKey={'key'}
        pagination={{ pageSize: 10, showTotal: (value) => <div>ทั้งหมด {value} รายการ</div> }}
        loading={operatorLoading}
        columns={columnsOperatorData}
        dataSource={operatorData}
      ></Table>
    </div>
  );
};
export default OperatorDashboardComponent;
