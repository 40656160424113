import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form } from 'antd';

import usePagination from 'hooks/usePagination';
import { useApi, TApiResponse } from 'hooks/useApi';
import useAuth from 'hooks/useAuth';

import { getContractListForOfficer } from 'api/contract';

import WithHistoryModal from 'features/Contract/components/WithHistoryModal';
import SearchContract from 'features/Contract/components/SearchContract';
import ContractOfficerListTable from 'features/Contract/components/ContractOfficerListTable';
import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import { subRoutePaths } from 'constants/routes';

type contractListQuery = {
  pageSize: number;
  currentPageNumber: number;
  offerFormType: string;
  contractNo?: string;
  contractStartDate?: string;
  contractEndDate?: string;
};

const ListOfficerContract = () => {
  const { user } = useAuth();
  const [searchContractFormRef] = Form.useForm();
  const { setHeaderTitle, setIsClearHeader }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const {
    makeRequest,
    data: contractListData = [],
    loading: isLoadingContractList,
    pageable,
  }: TApiResponse = useApi();
  const [queryParam, setQueryParam] = useState<contractListQuery>({} as contractListQuery);

  const requestContractList = useCallback(
    (params: contractListQuery) => {
      makeRequest(() =>
        getContractListForOfficer({
          ...params,
          referenceNo: user?.referenceNo,
        })
      );
    },
    [makeRequest, user]
  );

  useEffect(() => {
    // NOTE: Fetch when change page size or current page number
    requestContractList({ ...queryParam, pageSize, currentPageNumber });
  }, [pageSize, currentPageNumber, queryParam, requestContractList]);

  useEffect(() => {
    setIsClearHeader(false);
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle, setIsClearHeader]);

  return (
    <div>
      <HeaderTitle>ค้นหาโดย</HeaderTitle>
      <SearchContract
        isShowOfferFormType={true}
        formRef={searchContractFormRef}
        onSearchContract={(_values: any) => {
          setQueryParam(_values);
          setCurrentPageNumber(1);
        }}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <WithHistoryModal>
        {(props: any) => {
          return (
            <ContractOfficerListTable
              data={contractListData}
              loading={isLoadingContractList}
              pageSize={pageSize}
              currentPageNumber={currentPageNumber}
              paginationSetting={paginationSetting(pageable?.totalItems)}
              getEditPath={subRoutePaths.contractOfficerEdit}
              {...props}
            />
          );
        }}
      </WithHistoryModal>
    </div>
  );
};

export default ListOfficerContract;
