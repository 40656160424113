/* eslint-disable */

import { Alert, Button, Col, Form, Input, Modal, Radio, Result, Row, Skeleton, Table } from 'antd';
import { CloseOutlined, DownloadOutlined, ExceptionOutlined, HourglassOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { ColumnsType } from 'antd/es/table';
import {
  downloadAttachFileById,
  getErrorFromUsageAttachFile,
  getUsageForm,
  updateUsageAttachFile,
} from 'api/usageForm';
import { GetErrorFromUsageAttachFileResponse, GetUsageFormResponse } from 'api/usageForm/interface';
import { ButtonComponent } from 'components';
import TextArea from 'antd/es/input/TextArea';

interface DataType {
  key: React.Key;
  id: number;
  type: string;
  formStatus?: number;
  considerResult: string;
  considerDetail: string;
  status: string;
  attachFile: string;
  onLoadErrorClick: () => void;
  onDownloadClick: () => void;
  onEditClick: (id: number) => void;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ดาวน์โหลดเอกสาร',
    dataIndex: 'id',
    width: '180px',
    align: 'center',
    render: (_, record) => <div>
      <Button disabled={record.status !== 'COMPLETED'} onClick={record.onDownloadClick}>
        {record.status !== 'COMPLETED' && record.status !== 'FAILED' && <HourglassOutlined />}
        {record.status == 'COMPLETED' && <DownloadOutlined />}
        {record.status == 'FAILED' && <CloseOutlined />}
      </Button>
    </div>,
  },
  {
    title: 'ลำดับที่',
    dataIndex: 'id',
    align: 'center',
    width: '100px',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'กลุ่มเอกสาร',
    dataIndex: 'type',
  },
  {
    title: 'สถานะ',
    dataIndex: 'status',
  },
  {
    title: 'ข้อผิดพลาด',
    dataIndex: 'loadError',
    render: (i, record) => {
      return <Button onClick={record.onLoadErrorClick}>
        <ExceptionOutlined />
      </Button>;
    },
  },
  {
    title: 'ผลการพิจารณา',
    key: 'result',
    width: '200px',
    render: (_, record) => {
      return <div>
        {record.considerDetail && <span style={{ color: 'red' }}>*</span>} {record.considerResult || '-'}
      </div>;
    },
  },
  {
    title: '',
    key: 'delete',
    width: '100px',
    render: (_, record) => <Button

      onClick={() => record.onEditClick(record.id)}>รายละเอียด/แก้ไข</Button>,
  },
];

interface FormFileUploadProp {
  id: number;
  status?: number;
}

const FormFilesUpload = ({ id, status }: FormFileUploadProp): JSX.Element => {
  const [form] = useForm();
  const [filesForm] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [activeCellId, setActiveCellId] = useState<number>();

  const { makeRequest: GetAttachFileList } = useApi();
  const { makeRequest: GetErrorDocRequest } = useApi();
  const { makeRequest: DownloadAttachFileList } = useApi();
  const { makeRequest: UpdateAttachFileRequest, loading: updateAttachFilesLoading } = useApi();
  const [docErrors, setDocErrors] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState<DataType[]>([]);

  const onLoadErrorClick = (id: number) => {
    setIsModalOpen(true);
    setIsFetching(true);
    GetErrorDocRequest(async () => {
      const res: any = await getErrorFromUsageAttachFile(id);
      const data: GetErrorFromUsageAttachFileResponse = res.data;
      setDocErrors(data.data);
      setIsFetching(false);
      return res;
    });
  };

  const getAttachFileList = async (): Promise<void> => {
    GetAttachFileList(async () => {
      const res: any = await getUsageForm(id);
      const list: GetUsageFormResponse = res.data;
      const data = list.data.usageAttachFiles.sort((a, b) => a.formId - b.formId);
      setData(data.map(i => {
        return {
          key: i.formId,
          id: i.formId,
          status: i.status,
          formStatus: status,
          considerResult: i.considerResult,
          considerDetail: i.considerDetail,
          type: i.usageTypeName,
          attachFile: i.attachFileName,
          onLoadErrorClick: () => {
            onLoadErrorClick(i.formId);
          },
          onEditClick: (id: number) => {
            onEditCell(id);
          },
          onDownloadClick: async () => {
            DownloadAttachFileList(async () => {
              const res = await downloadAttachFileById(i.formId);
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', i.attachFileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              return res;
            });
          },
        };
      }));
      const temp = data.map(i => {
        return {
          id: i.formId,
          status: i.considerResult,
          detail: i.considerDetail,
        };
      });

      filesForm.setFields([{
        name: 'files',
        value: temp,
      }]);
      return res;
    });
  };

  const onEditCancel = () => {
    setIsModalEditOpen(false);
  };

  const onEditCell = (id: number) => {
    setActiveCellId(id);
    setIsModalEditOpen(true);
  };

  const onEditCellSubmit = async () => {
    UpdateAttachFileRequest(async () => {
      const { detail, status } = filesForm.getFieldValue('files').find((i: any) => i.id === activeCellId);
      const res = await updateUsageAttachFile(activeCellId!, status, detail);
      getAttachFileList();
      setIsModalEditOpen(false);
      return res;
    });
  };

  const handleCancel = () => {
    setDocErrors([]);
    setIsModalOpen(false);
  };

  useEffect(() => {
    getAttachFileList();
  }, [status, id]);

  return <div style={{
    marginBottom: 24,
  }}>
    <Modal width={1000} title='ข้อผิดพลาด' open={isModalOpen} onCancel={handleCancel} footer={[]}>
      {isFetching && <Skeleton active />}
      {docErrors.length > 0 && !isFetching && docErrors.map((i, index) => <div style={{
        marginBottom: 24,
      }} key={index}>
        <Alert
          description={i}
          type='error'
        />
      </div>)}
      {
        docErrors.length === 0 && !isFetching && <div>
          <Result
            status='success'
            title='ไม่พบข้อผิดพลาด'
            extra={[]}
          />

        </div>
      }
    </Modal>
    <Form form={filesForm}>
      {
        data.map((i, index) => {
          return <Modal
            key={'edit' + i.id}
            title='แก้ไขผลการพิจารณา'
            open={isModalEditOpen && activeCellId == i.id}
            onCancel={onEditCancel}
            footer={[
              <ButtonComponent disabled={i.formStatus === 3 || i.formStatus === 4 || i.formStatus === 1}
                               loading={updateAttachFilesLoading}
                               key='submit'
                               onClick={onEditCellSubmit}>
                บันทึก
              </ButtonComponent>,
            ]}
          >
            <div>
              <div style={{
                marginBottom: '16px',
              }}>
                <Form.Item style={{
                  visibility: 'hidden',
                  height: 0,
                }} initialValue={i.id} name={['files', index, 'id']}>
                  <Input></Input>
                </Form.Item>
                <h4>ผลการพิจารณา</h4>
                <Form.Item initialValue={i.considerResult} name={['files', index, 'status']}>
                  <Radio.Group disabled={i.formStatus === 3 || i.formStatus === 4 || i.formStatus === 1}>
                    <Radio value={'ผ่าน'}>ผ่าน</Radio>
                    <Radio value={'ไม่ผ่าน'}>ไม่ผ่าน</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div>
                <h4>รายละเอียด</h4>
                <Form.Item initialValue={i.considerDetail} name={['files', index, 'detail']}>
                  <TextArea disabled={i.formStatus === 3 || i.formStatus === 4 || i.formStatus === 1}
                            autoSize></TextArea>
                </Form.Item>
              </div>
            </div>
          </Modal>;
        })
      }
    </Form>
    <Form form={form}>
      <Row gutter={[16, 16]}>

        <Col span={24}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </Col>
      </Row>
    </Form>
  </div>;
};
export default FormFilesUpload;
