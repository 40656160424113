import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getOperator } from 'api/operator';

import useAuth from 'hooks/useAuth';
import useDialogAlert from 'hooks/useDialogAlert';
import { TApiResponse, useApi } from 'hooks/useApi';

import { IOfferData } from '../interfaces';
import { getOfferTypeFromPath } from '../utils';

type UseNewOfferPageProps = {
  isNewPage: boolean;
  setOfferData: React.Dispatch<React.SetStateAction<IOfferData>>;
};

const useNewOfferPage = ({ isNewPage, setOfferData }: UseNewOfferPageProps) => {
  const { makeRequest, loading, data }: TApiResponse = useApi();
  const { user } = useAuth();
  const { error } = useDialogAlert();
  const { pathname } = useLocation();
  const offerFormType = getOfferTypeFromPath(pathname);

  const NEW_OFFER_DATA = useMemo(
    () => ({
      offerFormId: 0,
      offerFormNo: null,
      offerFormDate: null,
      offerFormType: offerFormType,
      offerFormTypeName: offerFormType,
      offerFormPublicUrl: '',
      operators: {},
      offerServes: [],
      offerFacilities: [],
    }),
    [offerFormType]
  );

  const onGetOperatorInfo = useCallback(() => {
    if (user?.referenceNo) {
      makeRequest(() => getOperator({ refNo: user?.referenceNo }));
    } else {
      return error({
        header: 'ไม่พบข้อมูลผู้ใช้งาน',
        sub: 'กรุณาลองใหม่อีกครั้งหรือเข้าสู่ระบบใหม่',
      });
    }
  }, [makeRequest, error, user?.referenceNo]);

  useEffect(() => {
    const newOffer = { ...NEW_OFFER_DATA, operators: data };
    setOfferData(newOffer);
  }, [data, NEW_OFFER_DATA, setOfferData]);

  useEffect(() => {
    if (isNewPage) {
      onGetOperatorInfo();
    }
  }, [isNewPage, onGetOperatorInfo]);

  return { isLoadingOperatorInfo: loading };
};

export default useNewOfferPage;
