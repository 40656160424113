import { Col, Form, FormInstance, Input, Row, Select } from 'antd';
import { ThaiDatepickerComponent } from 'components';

type DetailFormProps = {
  form: FormInstance<any>;
  isDisable: boolean;
  isShowActionDropdown?: boolean;
  isHideApproveForm?: boolean;
  dateLabel?: string;
  // setSelectedActionCode: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const DetailForm = ({
  form,
  isDisable,
  dateLabel,
  // setSelectedActionCode,
  isShowActionDropdown = false,
  isHideApproveForm = false,
}: DetailFormProps) => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form.Item colon label="สถานะข้อเสนอ" name="stateName">
          <Input size="large" disabled />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          colon
          label={dateLabel || 'วันที่รับเรื่อง'}
          name="actionDate"
          rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
        >
          <ThaiDatepickerComponent disabled={isDisable} />
        </Form.Item>
      </Col>
      {isShowActionDropdown && (
        <Col span={24}>
          <Form.Item
            colon
            label="สถานะข้อเสนอ"
            name="actionCode"
            rules={[{ required: true, message: 'กรุณาเลือกสถานะข้อเสนอ' }]}
          >
            <Select
              size="large"
              placeholder="-- กรุณาระบุ --"
              onChange={(_, option) => {
                form.setFieldValue('actionName', option);
                // setSelectedActionCode(value);
              }}
              disabled={isDisable}
              options={[
                {
                  value: '8',
                  label: '8-อยู่ระหว่างจัดทำวาระเสนอที่ประชุม กสทช.',
                },
                {
                  value: '9',
                  label: '9-อยู่ระหว่างจัดทำหนังสือให้ปรับปรุงข้อเสนอฯ',
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="actionName" hidden>
            <Input />
          </Form.Item>
        </Col>
      )}
      {!isHideApproveForm && (
        <>
          <Col span={12}>
            <Form.Item
              colon
              label="มติครั้งที่"
              name="approvedNo"
              rules={[
                { required: true, message: 'กรุณาใส่มติครั้งที่' },
                { pattern: /^[0-9]+$/, message: 'กรุณากรอกตัวเลขเท่านั้น' },
              ]}
            >
              <Input disabled={isDisable} size="large" placeholder="กรอกตัวเลข" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              label="วันที่ลงมติ"
              name="approvedDate"
              rules={[{ required: true, message: 'กรุณาเลือกวันที่ลงมติ' }]}
            >
              <ThaiDatepickerComponent disabled={isDisable} />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
};

export default DetailForm;
