/* eslint-disable */
import SearchReportForm
  from 'features/ReportDataAndConnection/SearchReport/components/SearchReportForm/SearchReportForm';
import ReportTable, {
  DataSource,
} from 'features/ReportDataAndConnection/SearchReport/components/ReportTable/ReportTable';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ButtonComponent, HeaderTitleComponent } from 'components';
import { useApi } from 'hooks/useApi';
import { useForm } from 'antd/es/form/Form';
import useLoading from 'hooks/useLoading';
import { SearchReportUsageFormResponse } from 'api/usageForm/interface';
import {
  createReportUsageForm,
  deleteUsageFormReport,
  downloadReportUsageForm,
  searchReportUsageForm,
} from 'api/usageForm';
import CreateReport from './components/CreateReport/CreateReport';
import useDialogAlert from 'hooks/useDialogAlert';
import { Modal } from 'antd';

const defaultDatasource: DataSource = {
  data: [],
  pagination: undefined,
};

const SearchReport = (): JSX.Element => {
  const [searchForm] = useForm();
  const [createForm] = useForm();
  const [dataSource, setDataSource] = useState(defaultDatasource);
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [idSelected, setIdSelected] = useState<number>();
  const [deleting, setDeleting] = useState(false);
  const { makeRequest: searchReportUsageFormRequest } = useApi();
  const { makeRequest: downloadAttachFileList } = useApi();
  const { makeRequest: createReportUsageFormRequest } = useApi();
  const { makeRequest: deleteReportUsageFormRequest, error: deleteUsageFormReportError } = useApi();
  const showConfirmDelete = (id: number) => {
    setIdSelected(id);
    setIsModalOpen(true);
  };

  const deleteUsageForm = () => {
    setDeleting(true);
    deleteReportUsageFormRequest(async () => {
      if (idSelected) {
        await deleteUsageFormReport(idSelected);
        setDeleting(false);
        setIsModalOpen(false);
        await onSearch();
      } else {
        setDeleting(false);
      }
    }, () => {
    });
  };
  const onCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    if (deleteUsageFormReportError) {
      setIsModalOpen(false);
    }
  }, [deleteUsageFormReportError]);
  const { error } = useDialogAlert();
  const { setLoad } = useLoading();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const onCreateReport = async () => {
    setLoading(true);
    await createReportUsageFormRequest(async () => {
      const formValues = createForm.getFieldsValue();
      return await createReportUsageForm({
        year: formValues.year,
        quarter: formValues.quarter,
        usageTypeId: formValues.usageType,
        netWorkTypeCode: formValues.networkType,
      }).catch(() => {
        error({
          header: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', sub: '',
        });
        setLoading(false);
      });
    });
    setLoading(false);
    setOpen(false);
  };

  const onCreateReportClick = () => {
    setOpen(true);
  };

  const onSearch = async (pageSize?: number, currentPage?: number, reset?: boolean) => {
    setLoad(true);
    const formData = searchForm.getFieldsValue();

    if (reset) {
      setCurrentPage(1);
    }

    try {
      await searchReportUsageFormRequest(async () => {
        const result = await searchReportUsageForm({
          currentPageNumber: currentPage || 0,
          pageSize: pageSize || 5,
          year: formData.year,
          quarter: formData.quarter,
        });
        const newData = mapResponseToDataSource(result);
        setDataSource({
          data: [...newData.data],
          pagination: newData.pagination,
        });

        setLoad(false);
        return result;
      });

    } catch (e) {
      error({
        header: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', sub: '',
      });
      setLoad(false);
    }
  };

  const mapResponseToDataSource = (data: SearchReportUsageFormResponse): DataSource => {
    return {
      data: data.data.data.map(i => ({
        key: i.rawReportId,
        rawReportId: i.rawReportId,
        year: i.year,
        quarter: i.quarter,
        networkType: i.networkTypeName,
        usageType: i.usageTypeName,
        batchStatus: i.batchStatus,
        onDownload: async () => {
          downloadAttachFileList(async () => {
            const res: any = await downloadReportUsageForm(i.rawReportId);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;

            let name = res.headers['content-disposition'].split('filename=')[1].replace('"', '');
            name = name.replace('"', '');
            link.setAttribute('download', name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return res;
          });
        },
        onDelete: async () => {
          showConfirmDelete(i.rawReportId);
        },
      })),
      pagination: data.data.pageable,
    };
  };

  useEffect(() => {
    setHeaderTitle('รายงานปริมาณข้อมูล');

    onSearch();
  }, []);
  return <>
    <Modal title='ยืนยันการลบรายงาน' open={isModalOpen} okButtonProps={{ loading: deleting }} okText={'ลบ'}
           cancelText={'ยกเลิก'} okType={'danger'}
           onOk={deleteUsageForm} onCancel={onCancel}>
      <p>ต้องการลบรายการที่เลือกใช่หรือไม่ ?</p>

    </Modal>
    <CreateReport form={createForm} key={'CreateReport'} open={open} loading={loading} onOK={onCreateReport}
                  onClose={() => setOpen(false)} />
    <HeaderTitleComponent>ค้นหาโดย</HeaderTitleComponent>
    <div className='padding-conten-main'>
      <div style={{
        padding: '0 20px',
      }}>
        <div>
          <SearchReportForm form={searchForm} onSearch={() => {
            onSearch(undefined, undefined, true);
          }} />
        </div>
      </div>
    </div>
    <HeaderTitleComponent>ผลการค้นหา</HeaderTitleComponent>
    <div style={{
      padding: '0 20px',
      marginTop: '24px',
    }}>
      <div>
        <ButtonComponent onClick={onCreateReportClick}>จัดทำรายงาน</ButtonComponent>
      </div>
      <div style={{
        marginTop: '24px',
      }}>
        <ReportTable currentPage={currentPage} setCurrentPage={setCurrentPage} dataSource={dataSource}
                     onSearch={onSearch} />
      </div>
    </div>
  </>;
};
export default SearchReport;
