import { useMemo } from 'react';

import { CONFIRM_DOC_STATE } from 'features/Contract/constant';
import configReviewPage from 'features/Contract/config/configReviewPage';

type UseConditionConfigProps = {
  stateCode: number | undefined;
  isReviewPage: boolean;
};

const useConditionConfig = ({ stateCode, isReviewPage }: UseConditionConfigProps) => {
  const isNotConfirmDocState = useMemo(() => {
    if (isReviewPage) return false;
    if (stateCode) return !CONFIRM_DOC_STATE.includes(stateCode);
    else return true;
  }, [stateCode, isReviewPage]);

  const isShowConfirmDocButton = useMemo(() => {
    if (!stateCode) return false;
    if (isReviewPage) return configReviewPage.isShowConfirmDocButton(stateCode);
    return true;
  }, [stateCode, isReviewPage]);

  return { isNotConfirmDocState, isShowConfirmDocButton };
};

export default useConditionConfig;
