import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractType } from 'api/contract';

type ContractTypeDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  disabled?: boolean;
  isRequired?: boolean;
};

const ContractTypeDropdown = ({
  label,
  labelCol,
  disabled = false,
  isRequired = true,
}: ContractTypeDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listContractType());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name="offerFormType"
        label={label ? label : 'รูปแบบสัญญา'}
        labelCol={labelCol}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'รูปแบบสัญญา'}` }]
            : []
        }
      >
        <Select
          allowClear={isRequired ? false : true}
          loading={loading}
          disabled={disabled}
          placeholder="-- กรุณาระบุ --"
          options={
            data
              ? data.map((item: { code: string; name: string }) => ({
                  value: item.code,
                  label: item.name,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ContractTypeDropdown;
