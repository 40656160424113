import axiosInstance from 'utils/axios';
import { DeleteOfficerByOfficerIdQuery, DeleteOfficerQuery, ListOfficerQuery } from './interface';
import { IOfficerUser, IOfficerUserAdd } from 'features/ManageUser/interfaces';

export const getOfficerList = (query: ListOfficerQuery) => {
  return axiosInstance.get(`/officer`, {
    params: query,
  });
};
export const OfficerUpdate = (data: IOfficerUser) => {
  return axiosInstance.put(`/officer`, data);
};
export const createOfficer = (data: IOfficerUserAdd) => {
  return axiosInstance.post(`/officer`, data);
};
export const deleteOfficerByUserLogin = (query: DeleteOfficerQuery) => {
  return axiosInstance.delete(`/officer`, {
    params: query,
  });
};
export const deleteOfficerByUserOfficerId = (query: DeleteOfficerByOfficerIdQuery) => {
  return axiosInstance.delete(`/officer/${query.officerId}`);
};

export const getMappingGroup = () => {
  return axiosInstance.get(`/master/active-mapping-group`);
};
export const getOfficerLeader = () => {
  return axiosInstance.get(`/master/list-officer-leader`);
};
