import { downloadOfferHistoryAttachmentFile, getOfferHistoryById } from 'api/offer/history';

import EditOfferWrapper from '../../components/EditOfferWrapper';
import { OfferDataProvider } from '../../contexts/OfferDataContext';
import { OfferServicesProvider } from 'features/Offer/contexts/OfferServicesContext';
import { OfferFacilititesProvider } from 'features/Offer/contexts/OfferFacilitiesContext';

const OfferHistory = () => {
  return (
    <OfferDataProvider
      getOfferDataAPI={getOfferHistoryById}
      downloadOfferFileAPI={downloadOfferHistoryAttachmentFile}
      isHistoryPage={true}
    >
      <OfferServicesProvider>
        <OfferFacilititesProvider>
          <EditOfferWrapper />
        </OfferFacilititesProvider>
      </OfferServicesProvider>
    </OfferDataProvider>
  );
};

export default OfferHistory;
