import {
  CONTRACT_CONNECT,
  CONTRACT_INFRASTRUCTURE,
  CONTRACT_NETWORK,
  CONTRACT_USAGE,
} from 'features/Contract/constant';
export const contractTypeTh = (text: string) => {
  switch (text) {
    case CONTRACT_CONNECT:
      return 'สัญญาการเชื่อมต่อฯ';
    case CONTRACT_INFRASTRUCTURE:
      return 'สัญญาการใช้โครงสร้างพื้นฐาน';
    case CONTRACT_NETWORK:
      return 'สัญญาการใช้บริการข้ามโครงข่ายฯ';
    case CONTRACT_USAGE:
      return 'สัญญาการใช้ฯ';

    default:
      return '';
  }
};
