import { FunctionComponent, createContext, ReactNode, useState, useCallback } from 'react';
import { Button, Modal } from 'antd';

import ButtonChildren from 'components/ButtonComponent/ButtonComponent';
import PrimaryButton from 'components/PrimaryButton';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

// NOTE: Type
type WarningPopupProps = {
  header: string;
  sub: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

// NOTE: Context
interface DialogAlertContextType {
  alert: boolean;
  status: string;
  text: string;
  setAlert: (is: boolean) => void;
  setStatus: (is: string) => void;
  setText: (is: string) => void;
  success: (text?: { header: string; sub: string }, onOk?: Function) => void;
  error: (text?: { header: string; sub: string }, onOk?: Function) => void;
  warning: (props: WarningPopupProps) => void;
}
const DialogAlerContext = createContext<DialogAlertContextType | null>(null);

// NOTE: Provider
interface DialogAlertProviderProps {
  children: ReactNode;
}

const DialogAlerProvider: FunctionComponent<DialogAlertProviderProps> = (props) => {
  const [alert, setAlert] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [text, setText] = useState<string>('');

  const error = useCallback((text?: { header: string; sub: string }, onOk?: Function) => {
    let secondsToGo = 1000;

    const modal = Modal.error({
      zIndex: 100000,
      centered: true,
      className: 'modal-alert',
      content: (
        <div className="modal-alert-text">
          <div className="modal-alert-text-header-error">{text?.header}</div>
          <div className="modal-alert-text-sub-error">{text?.sub}</div>
        </div>
      ),
      closable: false,
      footer: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <ButtonChildren
            onClick={() => {
              if (onOk) onOk();
              Modal.destroyAll();
            }}
            htmlType="button"
          >
            ตกลง
          </ButtonChildren>
        </div>
      ),
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: (
          <div className="modal-alert-text">
            <div className="modal-alert-text-header-error">{text?.header}</div>
            <div className="modal-alert-text-sub-error">{text?.sub}</div>
          </div>
        ),
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      if (onOk) onOk();
      Modal.destroyAll();
    }, secondsToGo * 1000);
  }, []);

  const warning = useCallback(({ header, sub, onConfirm, onCancel }: WarningPopupProps) => {
    Modal.warning({
      zIndex: 100000,
      centered: true,
      className: 'modal-alert',
      content: (
        <div className="modal-alert-text">
          <div className="modal-alert-text-header-error">{header}</div>
          <div className="modal-alert-text-sub-error">{sub}</div>
        </div>
      ),
      closable: false,
      footer: (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
          <PrimaryButton
            size="large"
            icon={<SaveOutlined />}
            onClick={() => {
              if (onConfirm) onConfirm();
              Modal.destroyAll();
            }}
          >
            ยืนยัน
          </PrimaryButton>
          <Button
            size="large"
            shape="round"
            type="default"
            icon={<CloseOutlined />}
            onClick={() => {
              if (onCancel) onCancel();
              Modal.destroyAll();
            }}
          >
            ยกเลิก
          </Button>
        </div>
      ),
    });
  }, []);

  const success = useCallback((text?: { header: string; sub: string }, onOk?: Function) => {
    let secondsToGo = 3;

    const modal = Modal.success({
      centered: true,
      className: 'modal-alert',
      content: (
        <div className="modal-alert-text">
          <div className="modal-alert-text-header-success">{text?.header}</div>
          <div className="modal-alert-text-sub-success">{text?.sub}</div>
        </div>
      ),
      closable: false,
      footer: (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            className="button-login-submit"
            onClick={() => {
              if (onOk) onOk();
              Modal.destroyAll();
            }}
          >
            ตกลง
          </Button>
        </div>
      ),
    });

    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: (
          <div className="modal-alert-text">
            <div className="modal-alert-text-header-success">{text?.header}</div>
            <div className="modal-alert-text-sub-success">{text?.sub}</div>
            <div>{` ${secondsToGo} second.`}</div>
          </div>
        ),
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
      if (onOk) onOk();
      Modal.destroyAll();
    }, secondsToGo * 1000);
  }, []);

  return (
    <DialogAlerContext.Provider
      value={{
        alert,
        setAlert,
        status,
        setStatus,
        text,
        setText,
        success,
        error,
        warning,
      }}
    >
      {props.children}
    </DialogAlerContext.Provider>
  );
};

export { DialogAlerProvider, DialogAlerContext };
