import { useCallback, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import usePagination from 'hooks/usePagination';
import useModal from 'hooks/useModal';
import useCreateReport from './hooks/useCreateReport';
import useFetchReport from './hooks/useFetchReport';
import useDownloadReport from './hooks/useDownloadReport';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import PrimaryButton from 'components/PrimaryButton';
import ReportListTable from './components/ReportListTable';
import ReportForm from './components/ReportForm';
import CreateReportModal from './components/CreateReportModal';
import { dayJsformatDateObjToString } from 'utils/date';

type GenericReportProps = {
  pageTitle: string;
  createReportApi: Function;
  listReportApi: Function;
  getReportByJobIdApi: Function;
  downloadReportByJobIdApi: Function;
  isContractReport?: boolean;
  isRequiredOfferType?: boolean;
  isRequiredStartDate?: boolean;
  isRequiredEndDate?: boolean;
  dateLabel?: string;
  TypeDropdown?: any;
};

const GenericReport = ({
  pageTitle,
  createReportApi,
  listReportApi,
  getReportByJobIdApi,
  downloadReportByJobIdApi,
  dateLabel,
  TypeDropdown,
  isContractReport = false,
  isRequiredOfferType = false,
  isRequiredStartDate = false,
  isRequiredEndDate = false,
}: GenericReportProps) => {
  const [searchFormRef] = Form.useForm();
  const [createFormRef] = Form.useForm();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const { onCreateReport, isCreatingReport } = useCreateReport({
    formRef: searchFormRef,
    createReportApi,
  });
  const { onDownloadReport } = useDownloadReport({ downloadReportByJobIdApi });
  const {
    reportList,
    isFetching,
    pageable,
    onRefreshReportStatus,
    contextHolder,
    isFetchingReportStatus,
    setQueryParams,
  } = useFetchReport({
    currentPageNumber,
    pageSize,
    listReportApi,
    getReportByJobIdApi,
  });

  const onDoneCreateReport = useCallback(() => {
    createFormRef.resetFields();
    handleCancel();
    setQueryParams({});
  }, [createFormRef, handleCancel, setQueryParams]);

  const onSearchReport = useCallback(
    (values: any) => {
      setCurrentPageNumber(1);
      let _values = {
        reportStartDate: values.startDate
          ? dayJsformatDateObjToString(values.startDate)
          : undefined,
        reportEndDate: values.endDate ? dayJsformatDateObjToString(values.endDate) : undefined,
        offerFormType: values.offerFormType,
      };
      setQueryParams(_values);
    },
    [setCurrentPageNumber, setQueryParams]
  );

  useEffect(() => {
    setHeaderTitle(pageTitle);
  }, [setHeaderTitle, pageTitle]);

  return (
    <div>
      {/* NOTE: contextHolder => messageApi from antd */}
      {contextHolder}
      <HeaderTitle>ค้นหาโดย</HeaderTitle>
      <ReportForm
        submitButtonLabel="ค้นหารายงาน"
        dateLabel={dateLabel}
        formRef={searchFormRef}
        onSubmit={onSearchReport}
        TypeDropdown={
          TypeDropdown ? <TypeDropdown label="ประเภทสัญญา" isRequired={false} /> : undefined
        }
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <div className="content-box">
        <PrimaryButton icon={<PlusOutlined />} onClick={showModal}>
          จัดทำรายงาน
        </PrimaryButton>
      </div>
      <ReportListTable
        data={reportList}
        loading={isFetching || isCreatingReport || isFetchingReportStatus}
        paginationSetting={paginationSetting(pageable?.totalItems)}
        onRefreshReportStatus={onRefreshReportStatus}
        onDownloadReport={onDownloadReport}
        dateLabel={dateLabel}
        isContractReport={isContractReport}
      />
      <CreateReportModal
        modalTitle="จัดทำรายงาน"
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        formRef={createFormRef}
        isLoading={isCreatingReport}
        isRequiredOfferType={isRequiredOfferType}
        isRequiredStartDate={isRequiredStartDate}
        isRequiredEndDate={isRequiredEndDate}
        TypeDropdown={TypeDropdown}
        dateLabel={dateLabel}
        onSubmit={(values: any) => onCreateReport({ values, callback: onDoneCreateReport })}
      />
    </div>
  );
};

export default GenericReport;
