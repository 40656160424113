import { Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';
import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';

import PrimaryButton from 'components/PrimaryButton';

import { OFFER_TYPE_RDRO } from 'constants/offerFormType';

const ServiceActionButton = () => {
  // NOTE: RISO, RDRO
  const { isDisabled, offerType } = useOfferDataContext();
  const { currentOfferFacilities } = useOfferFacilititesContext();
  const { onAddNewService, onDeleteServices, onDeleteRIOServices } = useOfferServicesContext();

  return (
    <div>
      <Space>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<PlusOutlined />}
          onClick={onAddNewService}
        >
          เพิ่มบริการ
        </PrimaryButton>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<DeleteOutlined />}
          onClick={() => {
            if (offerType === OFFER_TYPE_RDRO) onDeleteRIOServices(currentOfferFacilities);
            else onDeleteServices();
          }}
        >
          ลบบริการ
        </PrimaryButton>
      </Space>
    </div>
  );
};

export default ServiceActionButton;
