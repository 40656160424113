import { useMemo } from 'react';
import { Col, Form, Input, Row, Select, Tooltip } from 'antd';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';
import useValidation from 'features/Offer/hooks/useValidation';

import { IOfferServes, IServicesData } from 'features/Offer/interfaces';
import { OTHER_OPTION } from 'features/Offer/constant';
import { OFFER_TYPE_RDRO } from 'constants/offerFormType';

type ServiceDropdownProps = {
  serviceId: string;
  record: IOfferServes;
  serviceOptions: IOfferServes[];
  isLoadingServices: boolean;
};

const ServiceDropdown = ({
  record,
  serviceId,
  serviceOptions,
  isLoadingServices,
}: ServiceDropdownProps) => {
  const { offerType } = useOfferDataContext();
  const { onCheckExistingServiceInFacilites } = useValidation();
  const { currentOfferFacilities } = useOfferFacilititesContext();
  const { serviceTableFormRef, onChangeOtherInput, onChangeService, onChangeRDROService } =
    useOfferServicesContext();
  const { isDisabled } = useOfferDataContext();

  const onCheckServiceOption = ({
    selectedOption,
    currentRecord,
  }: {
    selectedOption: IServicesData;
    currentRecord: IOfferServes;
  }) => {
    if (offerType === OFFER_TYPE_RDRO)
      onChangeRDROService({
        selectedOption,
        currentRecord,
        currentOfferFacilities: currentOfferFacilities,
      });
    else onChangeService({ selectedOption: selectedOption, currentRecord: currentRecord });
  };

  const isServiceInFacilities = useMemo(() => {
    return onCheckExistingServiceInFacilites({
      service: record,
      currentOfferFacilities,
      isThrowError: false,
    });
  }, [currentOfferFacilities, record, onCheckExistingServiceInFacilites]);

  const isShowOtherOptionInput = record.raoServName === OTHER_OPTION;

  return (
    <div style={{ textAlign: 'left' }}>
      <Row gutter={[16, 16]}>
        <Col span={isShowOtherOptionInput ? 6 : 24}>
          <Form.Item
            validateTrigger={['onChange', 'onSubmit']}
            rules={[
              {
                required: true,
                message: 'กรุณาเลือกบริการ',
              },
            ]}
            wrapperCol={{ span: 24 }}
          >
            <Select
              placeholder="บริการ"
              style={{ width: '100%' }}
              defaultValue={serviceId}
              value={serviceId}
              onChange={(_, option) => {
                onCheckServiceOption({
                  selectedOption: option,
                  currentRecord: record,
                });
              }}
              loading={isLoadingServices}
              options={
                serviceOptions
                  ? serviceOptions.map((item: any) => ({
                      value: item.raoServId,
                      label: item.raoServName,
                      ...item,
                    }))
                  : []
              }
            />
          </Form.Item>
        </Col>
        {isShowOtherOptionInput && (
          <Col span={18}>
            <Tooltip
              placement="topLeft"
              title="กรุณายกเลิกบริการที่บันทึกไว้กับสิ่งอำนวยความสะดวกก่อนแก้ไข"
              zIndex={isServiceInFacilities ? 999 : -999}
              color="#bb2124"
            >
              <Form.Item
                style={{ width: '100%' }}
                name={`otherOption-${record.key}`}
                validateTrigger={['onChange', 'onSubmit']}
                rules={[
                  {
                    required: true,
                    message: 'กรุณาระบุชื่อบริการ',
                  },
                ]}
                wrapperCol={{ span: 24 }}
                initialValue={record.raoServiceDesc}
              >
                <Input
                  disabled={isServiceInFacilities || isDisabled}
                  onChange={() => {
                    const _inputValue = serviceTableFormRef.getFieldValue(
                      `otherOption-${record.key}`
                    );
                    onChangeOtherInput({
                      inputValue: _inputValue,
                      currentRecord: record,
                    });
                  }}
                  onBlur={async () => {
                    await serviceTableFormRef.validateFields([`otherOption-${record.key}`]);
                  }}
                  value={record.raoServiceDesc}
                  style={{ width: '100%' }}
                  placeholder="ระบุชื่อบริการ"
                />
              </Form.Item>
            </Tooltip>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ServiceDropdown;
