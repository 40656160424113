import { Col, Form, Row, Spin } from 'antd';
import { FormInstance } from 'antd/lib';

import DocumentTypeDropdown from '../../DocumentTypeDropdown';
import UploadButton from './UploadButton';
import UploadTable from './UploadTable';

import { StepsDataType } from 'features/OfferConsideration/interfaces';
import { formLayout } from 'constants/form';

type UploadEmailFileProps = {
  form: FormInstance<any>;
  isDisable: boolean;
  stepData: StepsDataType | undefined;
  isOpenFileDialog: boolean;
  isUploadingFile: boolean;
  isDeletingFile: boolean;
  isLoadingFileList: boolean;
  uploadFormRef: FormInstance<any>;
  fileList: any[];
  onUploadFile: (file: any) => void;
  setCurrentUploadFile: (file: any) => void;
  onValidateDocType: () => void;
  onChangeDocType: (value: any, option: any) => void;
  onRemoveRecordFile: (file: any) => void;
  onDownloadRecordFile: (file: any) => void;
};

const UploadEmailFile = ({
  form,
  isDisable,
  stepData,
  isOpenFileDialog,
  isUploadingFile,
  isDeletingFile,
  isLoadingFileList,
  uploadFormRef,
  fileList,
  onUploadFile,
  setCurrentUploadFile,
  onValidateDocType,
  onChangeDocType,
  onRemoveRecordFile,
  onDownloadRecordFile,
}: UploadEmailFileProps) => {
  return (
    <Form {...formLayout} labelWrap labelAlign="left" form={uploadFormRef} name="stepModalForm">
      <Spin tip="กำลังอัพโหลดไฟล์..." spinning={isUploadingFile || isDeletingFile}>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <p style={{ margin: '-10px 0 -5px 0', color: 'red', fontSize: '16px' }}>
              รายละเอียดการแนบเอกสาร: สามารถแนบไฟล์ (pdf, word, excel) ครั้งละ 1 ไฟล์ ไฟล์ละไม่เกิน
              20 MB
            </p>
          </Col>
          <Col span={15}>
            <DocumentTypeDropdown
              form={form}
              isDisable={isDisable}
              state={stepData?.stateCode}
              onChangeDocType={onChangeDocType}
            />
          </Col>
          <Col span={7}>
            <UploadButton
              isDisabled={isDisable}
              isOpenFileDialog={isOpenFileDialog}
              onUploadFile={onUploadFile}
              setCurrentUploadFile={setCurrentUploadFile}
              onValidateDocType={onValidateDocType}
            />
          </Col>
          <Col span={24}>
            <UploadTable
              fileList={fileList}
              isLoadingFileList={isUploadingFile || isDeletingFile || isLoadingFileList}
              isDisable={isDisable}
              onRemoveFile={onRemoveRecordFile}
              downloadFile={onDownloadRecordFile}
            />
          </Col>
        </Row>
      </Spin>
    </Form>
  );
};

export default UploadEmailFile;
