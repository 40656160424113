import { ButtonComponent } from 'components';
import { Col, Form, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { getUsageType } from 'api/usageForm';
import { validateFormNotEmpty } from 'utils/form';
import { FormInstance } from 'antd/lib';

interface OptionInterface {
  value: number;
  label: number | string;
}

interface CreateReportInput {
  form: FormInstance;
  open: boolean;
  loading: boolean;
  onOK: () => void;
  onClose: () => void;
}

const validateRules = {
  usageType: true,
};

const CreateReport = ({ open, onOK, onClose, loading, form }: CreateReportInput): JSX.Element => {
  const [years, setYears] = useState<any[]>([]);
  const [usageTypes, setUsageTypes] = useState<OptionInterface[]>([]);
  const { makeRequest: GetAllUsageTypeRequest } = useApi();
  const [disabled, setDisabled] = useState(true);

  const filterOption = (input: string, option?: { label: string; value: number }) => {
    return option?.value.toString().includes(input) || false;
  };

  const validateForm = (): void => {
    const result = Object.entries(validateRules).every(([key, value]) => {
      if (!value) {
        return true;
      } else {
        return validateFormNotEmpty(form.getFieldValue(key));
      }
    });
    setDisabled(!result);
  };


  useEffect(() => {
    form.resetFields(['year', 'quarter', 'usageType', 'networkType']);
  }, [open, form]);

  useEffect(() => {
    const listYear: any[] = [];
    for (let i = 2559; i <= new Date().getFullYear() + 544; i++) {
      listYear.push({
        value: i,
        label: i,
      });
    }
    setYears(listYear);
    GetAllUsageTypeRequest(async () => {
      const result = await getUsageType();
      const options: OptionInterface[] = result.data.data.filter(item => item.usageTypeId !== 1).map(i => {
        return {
          label: i.description,
          value: i.usageTypeId,
        };
      });
      setUsageTypes(options);
      return result;
    });
    /* eslint-disable */
  }, []);

  return <div key='CreateReport'>
    <Modal title='จัดทำรายงาน' open={open} onOk={onOK} onCancel={onClose} width={1400}
           footer={[
             <ButtonComponent disabled={disabled} key='submit' type='primary' loading={loading} onClick={onOK}>
               บันทึกรายการ
             </ButtonComponent>,
           ]}>
      <Form form={form} onValuesChange={() => {
        validateForm();
      }
      }>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label='ปี พ.ศ.' name='year'>
              <Select showSearch filterOption={filterOption} allowClear={true} options={years}>
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label='ไตรมาสที่' name='quarter'>
              <Select allowClear={true} options={
                [
                  { label: '1', value: 1 },
                  { label: '2', value: 2 },
                  { label: '3', value: 3 },
                  { label: '4', value: 4 },
                ]
              }></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={
              <div>
                <span>
                  ประเภทข้อมูล
                </span>
                <span style={{ color: 'red', paddingLeft: '2px' }}>*</span>
              </div>
            } name='usageType'>
              <Select options={usageTypes}></Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label='ภายในประเทศ/ต่างประเทศ' name='networkType'>
              <Select allowClear={true} options={
                [
                  { value: 'D', label: 'ภายในประเทศ' },
                  { value: 'I', label: 'ต่างประเทศ, ไม่ระบุ' },
                ]
              }>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form></Modal>
  </div>;
};
export default CreateReport;
