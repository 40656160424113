import { SaveOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { getTableIndex } from 'utils/table';

import {
  getFormattedApproveDate,
  getFormattedString,
} from 'features/OfficerPublishUrl/utils/string';
import { IContractPublishUrl } from '../interfaces';

type ColumnsProps = {
  page: number;
  pageSize: number;
  onSelectRecord: Function;
};

export const columns = ({ page, pageSize, onSelectRecord }: ColumnsProps) => {
  const columns: ColumnsType<IContractPublishUrl> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      render: (_, __, index) => <>{getTableIndex(page, pageSize, index)}</>,
    },
    {
      title: 'ชื่อผู้ประกอบการ',
      dataIndex: 'owner',
      key: 'owner',
      render: (value) => value?.operName || '-',
    },
    {
      title: 'เลขที่/วันที่หนังสือ ผู้ประกอบการ',
      dataIndex: 'contractNo',
      key: 'contractNo',
      align: 'center',
      render: (_, record) => {
        return getFormattedString({ formNo: record?.contractNo, date: record?.formDate });
      },
    },
    {
      title: 'มติเห็นชอบ',
      dataIndex: 'nbtcApprovedNo',
      key: 'nbtcApprovedNo',
      align: 'center',
      render: (_, record) => {
        return getFormattedApproveDate({
          formNo: record?.nbtcApprovedNo,
          date: record?.nbtcApprovedDate,
        });
      },
    },
    {
      title: 'Link Web เปิดเผยสัญญา',
      dataIndex: 'publicUrl',
      key: 'publicUrl',
    },
    {
      title: 'สถานะลิงก์เว็บ',
      dataIndex: 'isActivePublicUrl',
      key: 'isActivePublicUrl',
      align: 'center',
      fixed: 'right',
      render: (value) => (value ? 'ใช้งาน' : 'ไม่ใช้งาน'),
    },
    {
      title: 'บันทึก',
      key: 'action',
      align: 'center',
      fixed: 'right',
      render: (_, record) => (
        <div
          onClick={() => {
            onSelectRecord(record);
          }}
        >
          <SaveOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
        </div>
      ),
    },
  ];
  return columns;
};
