import React, { useCallback, useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { Button, Input } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { columnsFunction } from './columns';
import buttonCSS from 'styles/components/button.module.css';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { HeaderTitleComponent } from 'components';
import { listRao } from 'api/accessOffer/Rao';
import useLoading from 'hooks/useLoading';
import ListManageRaoTable from '../components/TableRAO/ListManageRaoTable/ListManageRaoTable';
import useDialogAlert from 'hooks/useDialogAlert';
import { ILestRoaData } from 'contexts/DataAllProjectContext';
import useAuth from 'hooks/useAuth';
import ModalHistory from 'components/ModalHistory/ModalHistory';
import { TApiResponse, useApi } from 'hooks/useApi';
import { listAttachHistory } from 'api/attachHistory';
import { OfferLinkUpdate } from 'api/offer';
import { mainRoutePaths, subRoutePaths } from 'constants/routes';

type Props = {};

const ListManageOfferRISO = (props: Props) => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { error, success } = useDialogAlert();
  const [form] = Form.useForm();
  const [modalOpenHistory, setModalOpenHistory] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { makeRequest: API }: TApiResponse = useApi();
  // const {
  //   getAPIData: getListROA,
  //   dataAll: lestRoa,
  //   loading: isLoadingDocumentType,
  //   pageable,
  //   setDataAll,
  // } = useRequest();
  // const { lestRoa, setLestRoa } = useDataAllProject();
  const [listRAO, setListRAO] = useState<ILestRoaData>();
  const { setLoad } = useLoading();
  const { makeRequest: getListAttachHistoryRequest, data: attachHistory }: TApiResponse = useApi();
  let navigate = useNavigate();

  useEffect(() => {
    setHeaderTitle('ค้นหาข้อเสนอ RISO');
    // eslint-disable-next-line
  }, []);
  const getData = useCallback(
    async (
      pageSize: number,
      currentPageNumber: number,
      taxId: string,

      setLoad: (is: boolean) => void
    ) => {
      setLoad(true);
      const res = await API(() =>
        listRao({ pageSize, currentPageNumber, taxId, offerType: 'RISO' })
      ).finally(() => {
        setLoad(false);
      });

      setListRAO(res.data);
    },
    [API]
  );
  const getListAttachHistory = useCallback(
    (id: number | string) => {
      getListAttachHistoryRequest(() => listAttachHistory(id));
    },
    [getListAttachHistoryRequest]
  );
  useEffect(() => {
    if (attachHistory) {
      if (attachHistory.length === 0) {
        error({ header: 'ไม่มีประวัติไฟล์ข้อเสนอ', sub: '' });
      } else {
        setModalOpenHistory(true);
      }
    }
  }, [attachHistory, error]);
  // const getData = useCallback(
  //   async (
  //     pageSize: number,
  //     currentPageNumber: number,
  //     taxId: number,
  //     setLoad: (is: boolean) => void,
  //     getListROA: any
  //   ) => {
  //     setLoad(true);
  //     await getListROA(
  //       listRao(pageSize, currentPageNumber, taxId, 'RAO'),
  //       {},
  //       'http://143.198.197.17/'
  //       // pageSize, currentPageNumber, taxId
  //     ).finally(() => {
  //       setLoad(false);
  //     });
  //   },
  //   []
  // );

  useEffect(() => {
    if (!listRAO && user && pathname === mainRoutePaths.offerRISO)
      getData(5, 0, user.referenceNo, setLoad);
  }, [getData, setLoad, listRAO, pathname, user]);

  useEffect(() => {
    if (form.getFieldValue('password') && form.getFieldValue('password1')) {
      if (
        form.getFieldValue('password1').format('DD MM YYYY') <
        form.getFieldValue('password').format('DD MM YYYY')
      ) {
        form.setFieldsValue({
          password1: form.getFieldValue('password'),
        });
        error({
          header: 'วันที่ไม่ถูกต้อง',
          sub: '',
        });
      }
    }
  }, [error, form]);
  const onModalOpenHistory = (id: number | string) => {
    getListAttachHistory(id);
    // setModalOpenHistory(true);
  };
  const onModalHistoryCancel = () => {
    setModalOpenHistory(false);
  };
  async function modalFile(rowData: any, rowDataId: any) {
    const modal = Modal.info({
      width: 550,
      icon: undefined,
      className: 'modal-details',
      centered: true,
      footer: <></>,
      content: (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Link Web เปิดเผยข้อเสนอ</div>

            <CloseOutlined
              style={{ color: '#777777', width: '24px', height: '24px' }}
              onClick={() => modal.destroy()}
            />
          </div>
        </div>
      ),
      onOk: undefined,
    });
  }
  const onModalWeb = (text: any, value: string) => {
    const modal = Modal.info({
      width: 550,
      icon: undefined,
      className: 'modal-details',
      centered: true,
      footer: <></>,
      content: (
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Link Web เปิดเผยข้อเสนอ</div>

            <CloseOutlined
              style={{ color: '#777777', width: '24px', height: '24px' }}
              onClick={() => modal.destroy()}
            />
          </div>{' '}
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={async (e) => {
              const { status } = await OfferLinkUpdate({
                offerFormId: text.offerFormId,
                offerFormPublicUrl: e.ling,
              });
              if (status === 200 && user) {
                success({ header: 'บันทืกสำเร็จ', sub: '' });
                getData(5, 0, user.referenceNo, setLoad);
              } else error({ header: 'บันทึกไม่สำเร็จ', sub: '' });
              modal.destroy();
            }}
            autoComplete="off"
          >
            <div style={{ display: 'flex' }}>
              <Form.Item
                initialValue={value}
                style={{ flex: '1' }}
                name="ling"
                rules={[{ required: true, message: 'จำเป็น' }]}
              >
                <Input
                  style={{
                    borderRadius: '6px 0px 0px 6px',
                    height: '40px',
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button"
                  style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      ),
      onOk: undefined,
    });
  };

  return (
    <div className="k2d">
      {/* <HeaderTitleComponent>ค้นหาโดย</HeaderTitleComponent>
      <div>
        {ListManageRaoForm(onFinish, onFinishFailed, Form, form, setValueDate, setValueDate2)}
      </div> */}
      <HeaderTitleComponent>ผลการค้นหา</HeaderTitleComponent>

      <div className="padding-conten-main">
        <div className="padding-conten">
          <Button
            className={buttonCSS.primary}
            type="primary"
            shape="round"
            icon={<PlusOutlined />}
            size="large"
            onClick={() => navigate(subRoutePaths.offerRISONew, { state: { from: pathname } })}
          >
            เพื่อจัดทำข้อเสนอใหม่
          </Button>
          {/* <ButtonComponent htmlType="button">
            <EditOutlined />
            เพื่อปรับปรุงข้อเสนอเดิม
          </ButtonComponent> */}
        </div>
        {ListManageRaoTable(
          columnsFunction,
          onModalWeb,
          modalFile,
          navigate,
          listRAO,
          setLoad,
          getData,
          listRao,
          'RISO',
          onModalOpenHistory
        )}
        <ModalHistory
          modalOpenOffer={modalOpenHistory}
          onModalCancel={onModalHistoryCancel}
          title="ไฟล์จัดทำข้อเสนอ"
          width={700}
          attachHistory={attachHistory}
        />
      </div>
    </div>
  );
};

export default ListManageOfferRISO;
