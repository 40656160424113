import { useEffect } from 'react';

// API
import { useApi, TApiResponse } from 'hooks/useApi';
import { getOperator } from 'api/operator';

// Utils
import { getAddress } from '../utils/string';

const useOperatorAddress = (refNo: string | undefined | null) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    if (refNo) {
      makeRequest(() => getOperator({ refNo }), {
        isThrowError: false,
        dataTransformer: getAddress,
        isSilentError: true,
      }); // #NEED
    }
  }, [makeRequest, refNo]);

  return { operatorAddress: data, isLoadingOperatorAddress: loading };
};

export default useOperatorAddress;
