import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Pageable } from 'api/disagreement/interface';


export interface DataSource {
  data: ListDisagreementTableDataType[];
  pagination?: Pageable;
}

interface ListDisAgreementTableInput {
  dataSource: DataSource;
  onSearch: (pageSize?: number, currentPage?: number) => void;
}

export interface ListDisagreementTableDataType {
  key: React.Key;
  formId: number;
  petitioner: string;
  accused: string;
  formStatus?: string;
  onDetailClick: () => void;
  disagreeNo: string;
  formDate: string;
  topic: string;
}

const columns: ColumnsType<ListDisagreementTableDataType> = [
  {
    title: 'ลำดับที่',
    dataIndex: 'no',
    align: 'center',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'วันที่หนังสือนำส่ง',
    width: '150px',
    dataIndex: 'formDate',
  },
  {
    title: 'ข้อพิพาทที่',
    width: '150px',
    dataIndex: 'disagreeNo',
  },
  {
    title: 'ผู้ร้อง',
    dataIndex: 'petitioner',
  },
  {
    title: 'ผู้ถูกร้อง',
    dataIndex: 'accused',
  },
  {
    title: 'เรื่อง',
    dataIndex: 'topic',
    render: (_, record) => {
      return <div style={{
        wordBreak: 'break-all',
      }}>
        {record.topic}
      </div>;
    },
  },
  {
    title: 'สถานะ',
    dataIndex: 'formStatus',
  },
];


const ListDisagreementTable = (props: ListDisAgreementTableInput) => {
  return (
    <Table
      columns={columns}
      dataSource={props.dataSource.data}
      size='small'
      pagination={{
        onChange: async (currentPage, size) => {
          props.onSearch(size, currentPage - 1);
        },
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: props.dataSource.pagination?.totalItems,
        defaultPageSize: 5,
        defaultCurrent: 1,
        pageSizeOptions: [5, 10, 20],
      }}
    />
  );
};
export default ListDisagreementTable;
