import { useCallback } from 'react';
import { FormInstance } from 'antd';

import { IOfferFacility, IOfferServes } from '../interfaces';
import { OTHER_OPTION } from '../constant';
import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RISO,
} from 'constants/offerFormType';

interface IValidateRISOFormProps {
  editOfferFormRef: FormInstance<any>;
  currentOfferServices: IOfferServes[];
}

interface ValidateOfferByOfferTypeProps extends IValidateRISOFormProps {
  offerType: string;
}

const useValidation = () => {
  const onCheckExistingServiceInFacilites = useCallback(
    ({
      service,
      currentOfferFacilities,
      isThrowError = true,
    }: {
      service: IOfferServes;
      currentOfferFacilities: IOfferFacility[];
      isThrowError?: boolean;
    }) => {
      const isExisting = currentOfferFacilities.some((facility: IOfferFacility) => {
        if (facility.offerFacilitiesServes) {
          const isExistingService = facility.offerFacilitiesServes.some((_service: any) => {
            if (_service.raoServName === OTHER_OPTION)
              return _service.raoServiceDesc === service.raoServiceDesc;
            return _service.raoServId === service.raoServId;
          });
          if (isExistingService) return true;
        }
        return false;
      });

      if (!isThrowError) return isExisting;
      if (isExisting) {
        throw new Error('ไม่สามารถทำรายการได้');
      }
    },
    []
  );

  const validateIsServicesAtLeastOne = useCallback((currentOfferServices: IOfferServes[]) => {
    if (currentOfferServices.length <= 0) throw new Error('ต้องมีบริการอย่างน้อย 1 บริการ');
  }, []);

  const validateAreAllServicesSelected = useCallback((currentOfferServices: IOfferServes[]) => {
    const isAllHasServiceSelected = currentOfferServices.every((service) => {
      if (service?.raoServName === OTHER_OPTION) return service?.raoServiceDesc;
      return service?.raoServId;
    });
    if (!isAllHasServiceSelected) throw new Error('กรุณาเลือกบริการให้ครบก่อนดำเนินการต่อ');
  }, []);

  const validateAllServicesAtleastOneServePay = useCallback(
    (currentOfferServices: IOfferServes[]) => {
      const isAllHasServePay = currentOfferServices.every((service) => {
        if (service?.offerServePays) return service?.offerServePays?.length > 0;
        return false;
      });
      if (!isAllHasServePay) throw new Error('ต้องมีการระบุอัตราตอบแทนอย่างน้อย 1 รายการ');
    },
    []
  );

  const validateContactForm = useCallback(async (editOfferFormRef: FormInstance<any>) => {
    try {
      await editOfferFormRef.validateFields();
    } catch (e) {
      throw new Error('กรุณากรอกฟอร์มให้ครบ');
    }
  }, []);

  const validateRISOForm = useCallback(
    async ({ editOfferFormRef, currentOfferServices }: IValidateRISOFormProps) => {
      await validateContactForm(editOfferFormRef);
      validateIsServicesAtLeastOne(currentOfferServices);
      validateAreAllServicesSelected(currentOfferServices);
    },
    [validateContactForm, validateIsServicesAtLeastOne, validateAreAllServicesSelected]
  );

  const validateRIOForm = useCallback(
    async ({ editOfferFormRef, currentOfferServices }: any) => {
      await validateContactForm(editOfferFormRef);
      validateIsServicesAtLeastOne(currentOfferServices);
      validateAllServicesAtleastOneServePay(currentOfferServices);
    },
    [validateContactForm, validateAllServicesAtleastOneServePay, validateIsServicesAtLeastOne]
  );

  const validateOfferByOfferType = useCallback(
    async ({
      offerType,
      editOfferFormRef,
      currentOfferServices,
    }: ValidateOfferByOfferTypeProps) => {
      switch (offerType) {
        case OFFER_TYPE_RISO:
        case OFFER_TYPE_RDRO:
          await validateRISOForm({ editOfferFormRef, currentOfferServices });
          break;
        case OFFER_TYPE_RIO:
        case OFFER_TYPE_RAO:
          await validateRIOForm({ editOfferFormRef, currentOfferServices });
          break;
        default:
          break;
      }
    },
    [validateRISOForm, validateRIOForm]
  );

  return {
    validateIsServicesAtLeastOne,
    validateContactForm,
    validateOfferByOfferType,
    onCheckExistingServiceInFacilites,
  };
};

export default useValidation;
