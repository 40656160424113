import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import usePagination from 'hooks/usePagination';
import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractInquiry } from 'api/contract/inquiry';
import { ListContractQuery } from 'api/contract/interface';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import SearchInquiryForm from 'features/Contract/components/SearchInquiryForm';
import ContractReviewListTable from 'features/Contract/components/ContractReviewListTable';

import WithHistoryModal from '../../components/WithHistoryModal';
import { subRoutePaths } from 'constants/routes';

const ListInquiryContract = () => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  const [queryParam, setQueryParam] = useState<ListContractQuery | undefined>();

  const onListContractInquiry = useCallback(
    (params: ListContractQuery) => {
      makeRequest(() =>
        listContractInquiry({
          ...params,
        })
      );
    },
    [makeRequest]
  );

  useEffect(() => {
    if (queryParam) {
      onListContractInquiry({ ...queryParam, pageSize, currentPageNumber });
    } else {
      onListContractInquiry({ pageSize, currentPageNumber });
    }
  }, [pageSize, currentPageNumber, queryParam, onListContractInquiry]);

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle]);

  return (
    <div>
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <SearchInquiryForm
        onSearchInquiryForm={(_values: any) => {
          setQueryParam(_values);
          setCurrentPageNumber(1);
          onListContractInquiry({ ..._values, pageSize, currentPageNumber });
        }}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <WithHistoryModal>
        {(props: any) => {
          return (
            <ContractReviewListTable
              data={data}
              loading={loading}
              pageSize={pageSize}
              currentPageNumber={currentPageNumber}
              paginationSetting={paginationSetting(pageable?.totalItems)}
              getEditPath={subRoutePaths.inquiryContractDetail}
              {...props}
            />
          );
        }}
      </WithHistoryModal>
    </div>
  );
};

export default ListInquiryContract;
