/* eslint-disable */

import { Checkbox, Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { useApi } from 'hooks/useApi';
import { Disagreement, GetDisAgreeStateVerifyPetitionResponse } from 'api/disagreement/interface';
import { useForm } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormFilesUpload from './components/Form-Files-Upload';
import InputText from 'components/InputTextComponent';
import { validateFormNotEmpty } from 'utils/form';
import {
  getDisAgreeStateVerifyPetition,
  UpdateDisagreementState,
  updateDisAgreeStateVerifyPetition,
} from 'api/disagreement';
import {
  convertDataFormDatePickerToString,
  convertDateFromApiToDatePickerWithTimeZoneAsian,
  formatDateToOnlyBCYear,
} from 'utils/date';
import TextArea from 'antd/es/input/TextArea';

interface Form4Prop {
  isCompleted: boolean;
  disagreement?: Disagreement,
  completeDate?: string,
  stepState: number,
  onActionSuccess: () => void
}

interface Options {
  value: number;
  label: number;
}

const defaultOptions = (): Options[] => {
  const startAt = 2547;
  const options: Options[] = [];
  for (let i = startAt; i < parseInt(formatDateToOnlyBCYear(new Date().toString())) + 2; i++) {
    options.push({
      value: i,
      label: i,
    });
  }
  return options;
};

const Form4 = ({ isCompleted, stepState, disagreement, completeDate, onActionSuccess }: Form4Prop): JSX.Element => {
  const [form] = useForm();
  const [yearOptions] = useState(defaultOptions);
  const [select, setSelect] = useState<string | undefined>('Y');
  const [disableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableIcbs, setDisableIcbs] = useState(true);
  const [disableNextState, setDisableNextState] = useState(true);
  const [resultApprove, setResultApprove] = useState<boolean | null>();
  const [validateRules, setValidateRules] = useState({
    officerApprove: true,
    nbtcApprove: false,
    disagreeDate: true,
    disagreeYear: true,
    disagreeNo: true,
    remark: false,
  });
  const { makeRequest: UpdateDisagreementStateRequest } = useApi();
  const { makeRequest: UpdateDisagreementFormRequest } = useApi();
  const { makeRequest: GetDisagreementForm4Request } = useApi();

  const validateForm = (): void => {
    const result = Object.entries(validateRules).every(([key, value]) => {
      if (!value) {
        return true;
      } else {
        return validateFormNotEmpty(form.getFieldValue(key));
      }
    });
    setDisableNextState(!result);
  };

  useEffect(() => {
    validateForm();
  }, [validateRules]);

  useEffect(() => {
    const state = disagreement?.disAgreementStates.find(i => i.stateCode === 4);
    if (state) {
      setSelect(state.actionCode || 'Y');
    } else {
      setSelect('Y');
    }

    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: new Date(),
        },
      ]);
    }
  }, [disagreement, completeDate]);

  useEffect(() => {
    if (disagreement) {
      GetDisagreementForm4Request(async () => {
        const res: any = await getDisAgreeStateVerifyPetition(disagreement?.formId, stepState);
        const result: GetDisAgreeStateVerifyPetitionResponse = res.data;
        if (result.data) {
          form.setFields([
            {
              name: 'disagreeDate',
              value: convertDateFromApiToDatePickerWithTimeZoneAsian(result.data.disAgreeDate),
            },
            {
              name: 'nbtcApprove',
              value: result.data.nbtcApprove,
            },
            {
              name: 'officerApprove',
              value: result.data.officerApprove,
            },
            {
              name: 'disagreeYear',
              value: result.data.yearly,
            },
            {
              name: 'disagreeNo',
              value: result.data.disAgreeNo,
            },
            {
              name: 'remark',
              value: result.data.remark,
            },
          ]);
        }
        updateApproveResult();
        validateForm();
        return res;
      });
    }
  }, [disagreement]);

  const [checkbox, setCheckBox] = useState(false);

  const onSelectChange = (value: string) => {
    setSelect(value);
  };

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  const updateApproveResult = () => {
    if (form.getFieldValue('officerApprove') === true) {
      setResultApprove(true);
    } else if (form.getFieldValue('nbtcApprove') === true) {
      setResultApprove(true);
    } else if (form.getFieldValue('nbtcApprove') === false) {
      setResultApprove(false);
    } else {
      setResultApprove(null);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    if (disagreement) {
      await UpdateDisagreementFormRequest(async () => {
        return await updateDisAgreeStateVerifyPetition({
          formId: disagreement.formId,
          stateId: stepState,
          completeDate: convertDataFormDatePickerToString(form.getFieldValue('completeDate')),
          disAgreeDate: convertDataFormDatePickerToString(form.getFieldValue('disagreeDate')),
          disAgreeNo: form.getFieldValue('disagreeNo'),
          nbtcApprove: form.getFieldValue('nbtcApprove'),
          officerApprove: form.getFieldValue('officerApprove'),
          remark: form.getFieldValue('remark'),
          yearly: form.getFieldValue('disagreeYear'),
        }).catch(() => setLoading(false));
      });
      if (checkbox) {
        setLoading(true);
        await UpdateDisagreementStateRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId, resultApprove ? 'Y' : 'N').catch(() => {
            setLoading(false);
          });
        });
      }
    }
    setLoading(false);
    onActionSuccess();
  };
  return <>
    <Form form={form} onValuesChange={() => {
      updateApproveResult();
      if (form.getFieldValue('officerApprove') === false) {
        setValidateRules({
          officerApprove: true,
          nbtcApprove: true,
          disagreeDate: true,
          disagreeYear: true,
          disagreeNo: true,
          remark: false,
        });
      } else {
        setValidateRules({
          officerApprove: true,
          nbtcApprove: false,
          disagreeDate: true,
          disagreeYear: true,
          disagreeNo: true,
          remark: false,
        });
      }
      validateForm();
    }}>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='สำนักงาน' name='officerApprove'>
            <Select
              disabled={isCompleted}
              style={{ width: 240 }}
              onChange={(value) => {
                if (value === false) {
                  setDisableIcbs(false);
                } else {
                  setDisableIcbs(true);
                  form.resetFields(['nbtcApprove']);
                }
              }
              }
              placeholder='กรุณาระบุ'
              options={[
                { value: true, label: 'รับคำร้อง' },
                { value: false, label: 'ไม่รับคำร้อง' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='กสทช' name='nbtcApprove'>
            <Select
              disabled={isCompleted || disableIcbs}
              style={{ width: 240 }}
              onChange={onSelectChange}
              placeholder='กรุณาระบุ'
              options={[
                { value: true, label: 'รับคำร้อง' },
                { value: false, label: 'ไม่รับคำร้อง' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='ข้อพิพาทที่' name='disagreeNo'>
            <InputText
              style={{ width: '240px' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='ปีข้อพิพาทที่' name='disagreeYear'>
            <Select placeholder='ปีข้อพิพาทที่' style={{ width: '240px' }}
                    options={yearOptions} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item labelCol={{
            span: 4,
          }} labelAlign={'right'} label='วันที่รับคำร้อง' name='disagreeDate'>
            <ThaiDatepickerComponent
              style={{
                width: '240px',
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item labelCol={{
            span: 4,
          }} labelAlign={'right'} label='รายละเอียดคำร้อง' name='remark'>
            <TextArea />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <div style={{
      marginTop: '24px',
    }}>
      <FormFilesUpload stepState={stepState} disagreementFormId={disagreement?.formId || 0} state={4} />
    </div>
    <Form form={form} onValuesChange={() => {
      updateApproveResult();
      if (form.getFieldValue('officerApprove') === false) {
        setValidateRules({
          officerApprove: true,
          nbtcApprove: true,
          disagreeDate: true,
          disagreeYear: true,
          disagreeNo: true,
          remark: false,
        });
      } else {
        setValidateRules({
          officerApprove: true,
          nbtcApprove: false,
          disagreeDate: true,
          disagreeYear: true,
          disagreeNo: true,
          remark: false,
        });
      }
      validateForm();
    }}>
      <span>ผลการดำเนินการ :  </span>
      <span>{typeof resultApprove === 'boolean' ? resultApprove ? 'รับคำร้อง' : 'ไม่รับคำร้อง' : '-'}</span>
      <br />
      <br />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox disabled={isCompleted || disableNextState} checked={checkbox}
                    onChange={onCheckBoxChange}>ยืนยันการพิจารณา</Checkbox>
        </Col>
        <Col span={8}>
          <Form.Item labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }
      }>
        <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onSubmit}>
          บันทึกรายการ
        </ButtonComponent>
      </div>
    </Form>
  </>;
};

export default Form4;
