import { Modal, Spin } from 'antd';
import ReportForm from '../ReportForm';

type CreateReportModalProps = {
  modalTitle: string;
  isModalOpen: boolean;
  handleCancel: Function;
  formRef: any;
  onSubmit: Function;
  isLoading: boolean;
  dateLabel?: string;
  isRequiredOfferType?: boolean;
  isRequiredStartDate?: boolean;
  isRequiredEndDate?: boolean;
  TypeDropdown?: any;
};

const CreateReportModal = ({
  modalTitle,
  isModalOpen,
  handleCancel,
  formRef,
  onSubmit,
  isLoading,
  dateLabel,
  TypeDropdown,
  isRequiredOfferType = false,
  isRequiredStartDate = false,
  isRequiredEndDate = false,
}: CreateReportModalProps) => {
  const onHandleCancel = () => {
    // NOTE: Reset form to initial state
    formRef.resetFields();
    handleCancel();
  };

  return (
    <>
      <Modal
        width="70vw"
        title={modalTitle}
        open={isModalOpen}
        onCancel={() => onHandleCancel()}
        footer={false}
      >
        <Spin spinning={isLoading}>
          <ReportForm
            submitButtonLabel="จัดทำรายงาน"
            dateLabel={dateLabel}
            formRef={formRef}
            onSubmit={onSubmit}
            isRequiredOfferType={isRequiredOfferType}
            isRequiredStartDate={isRequiredStartDate}
            isRequiredEndDate={isRequiredEndDate}
            TypeDropdown={
              TypeDropdown ? (
                <TypeDropdown label="ประเภทสัญญา" isRequired={isRequiredOfferType} />
              ) : undefined
            }
          />
        </Spin>
      </Modal>
    </>
  );
};

export default CreateReportModal;
