import { useEffect } from 'react';
import { Col, Form, FormInstance, Input, Modal, Radio, Row, Space, Spin } from 'antd';

import { formLayout } from 'constants/form';

import OfferTypeDropdown from 'features/OfferConsideration/components/OfferTypeDropdown';
import Footer from '../components/Footer';

type EditModalProps = {
  isModalOpen: boolean;
  onCloseModal: Function;
  titleName: string;
  selectedRecord: any;
  onSaveRecord: Function;
  isUpdating: boolean;
  fieldName: string;
  fieldTitle: string;
  formRef: FormInstance<any>;
};

const EditModal = ({
  isModalOpen,
  onCloseModal,
  titleName,
  selectedRecord,
  onSaveRecord,
  isUpdating,
  fieldName,
  fieldTitle,
  formRef,
}: EditModalProps) => {
  const onSubmitForm = (values: any) => {
    let data = { ...values };
    if (selectedRecord) {
      data = { ...selectedRecord, ...values };
    }
    onSaveRecord(data);
  };

  useEffect(() => {
    if (selectedRecord) {
      formRef.setFieldsValue({
        [fieldName]: selectedRecord[fieldName],
        offerType: selectedRecord.offerType,
        isDeleted: selectedRecord.isDeleted,
      });
    }
  }, [formRef, selectedRecord, fieldName]);

  return (
    <div>
      <Modal
        title={<h3>{titleName}</h3>}
        open={isModalOpen}
        width={'80vh'}
        onCancel={() => onCloseModal()}
        footer={
          <Footer
            onCloseModal={onCloseModal}
            onSubmitForm={() => {
              formRef.submit();
            }}
          />
        }
      >
        <Spin spinning={isUpdating}>
          <Form
            {...formLayout}
            labelWrap
            colon
            labelAlign="right"
            size="large"
            name="editMasterDataModal"
            form={formRef}
            onFinish={onSubmitForm}
          >
            <Row gutter={[16, 16]}>
              <Col span={16}>
                <Form.Item
                  label={fieldTitle}
                  name={fieldName}
                  rules={[{ required: true, message: `กรุณากรอก${fieldTitle}` }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={16}>
                <OfferTypeDropdown fieldName="offerType" />
              </Col>
              <Col span={16}>
                <Form.Item
                  label="สถานะ"
                  name="isDeleted"
                  initialValue={false}
                  rules={[{ required: true, message: 'กรุณาเลือกสถานะ' }]}
                >
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio value={false}>ใช้งาน</Radio>
                      <Radio value={true}>ระงับการใช้งาน</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default EditModal;
