import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listTypeService } from 'api/master';
import { onFilterOption } from 'utils/select';

type OfferServiceTypeDropdownProps = {
  offerType: string;
  onChangeOption: (option: any) => void;
  label?: string;
  isRequired?: boolean;
};

const OfferServiceTypeDropdown = ({
  offerType,
  onChangeOption,
  label,
  isRequired = true,
}: OfferServiceTypeDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listTypeService(offerType));
  }, [makeRequest, offerType]);

  return (
    <>
      <Form.Item
        colon
        name="servTypeId"
        label={label ? label : 'ประเภทบริการ'}
        wrapperCol={{ span: 24 }}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'ประเภทบริการ'}` }]
            : []
        }
      >
        <Select
          labelInValue
          showSearch
          filterOption={onFilterOption}
          allowClear={isRequired ? false : true}
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          onChange={(_, option) => onChangeOption(option)}
          options={
            data
              ? data.map((item: any) => ({
                  value: item.servTypeId,
                  label: item.servTypeName,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OfferServiceTypeDropdown;
