import { Layout as LayoutAntd } from 'antd';
import style from './style.module.css';

const { Footer } = LayoutAntd;

const AppFooter = () => {
  return (
    <Footer className={style.appFooter}>
      <div className={style.leftFooter}>
        <p>มีข้อสงสัยหรือข้อเสนอแนะเกี่ยวกับการใช้งาน</p>
        <div className={style.addressFooter}>
          ติดต่อ สำนักงาน กสทช. สำนักโครงข่ายพื้นฐาน การใช้และเชื่อมต่อโครงข่าย
          <div>โทร : 02-670-8888</div>
          <div>E-mail : ic@nbtc.go.th</div>
        </div>
      </div>
      <div className={style.rightFooter}>
        สงวนลิขสิทธิ์ © 2023 สำนักงานคณะกรรมการกิจการกระจายเสียง กิจการโทรทัศน์
        และกิจการโทรคมนาคมแห่งชาติ
      </div>
    </Footer>
  );
};

export default AppFooter;
