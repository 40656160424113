import { DETAIL_PAGE, EDIT_PAGE, INQUIRY_PAGE, NEW_PAGE } from 'constants/page';
import { Tag } from 'antd';

import { OTHER_OPTION } from '../constant';
import { IOfferServes } from '../interfaces';

import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RISO,
} from 'constants/offerFormType';
import { subRoutePaths } from 'constants/routes';

export const getOfferEditPathFromOfferType = (offerType: string, offerId: any): string => {
  switch (offerType) {
    case OFFER_TYPE_RISO:
      return subRoutePaths.offerRISOEdit(offerId);
    case OFFER_TYPE_RDRO:
      return subRoutePaths.offerRDROEdit(offerId);
    case OFFER_TYPE_RIO:
      return subRoutePaths.offerRIOEdit(offerId);
    case OFFER_TYPE_RAO:
      return subRoutePaths.offerRAOEdit(offerId);
    default:
      return `/`;
  }
};

export const getOfferTypeFromPath = (pathname: string): string => {
  const paths = pathname.split('/')?.filter(Boolean);
  return (paths[1] || '').toUpperCase();
};

export const getPageFunction = (pathname: string) => {
  const paths = pathname.split('/');
  if (paths.includes('inquiry')) return INQUIRY_PAGE;
  if (paths.includes('edit')) return EDIT_PAGE;
  if (paths.includes('new')) return NEW_PAGE;
  if (paths.includes('detail')) return DETAIL_PAGE;
  return undefined;
};

type GetInquiryPathFromOfferTypeProps = {
  offerType: string;
  offerId: any;
};
export const getInquiryPathFromOfferType = ({
  offerType,
  offerId,
}: GetInquiryPathFromOfferTypeProps): string => {
  switch (offerType) {
    case OFFER_TYPE_RISO:
      return subRoutePaths.offerRISOInquiry(offerId);
    case OFFER_TYPE_RDRO:
      return subRoutePaths.offerRDROInquiry(offerId);
    case OFFER_TYPE_RIO:
      return subRoutePaths.offerRIOInquiry(offerId);
    case OFFER_TYPE_RAO:
      return subRoutePaths.offerRAOInquiry(offerId);
    default:
      return `/`;
  }
};

export const getLabelValue = (item: IOfferServes) => {
  if (item.raoServName === OTHER_OPTION) return item.raoServiceDesc;
  return item.raoServName;
};

export const getServiceDropdownValueByName = (item: IOfferServes) => {
  if (item.servTypeName) {
    if (item.raoServName === OTHER_OPTION) return `[${item.servTypeName}]${item.raoServiceDesc}`;
    return `[${item.servTypeName}]${item.raoServName}`;
  }
  if (item.raoServName === OTHER_OPTION) return item.raoServiceDesc;
  return item.raoServName;
};

export const getServiceLabelTextDropdown = (item: IOfferServes) => {
  return item.servTypeName ? (
    <>
      <Tag style={{ fontSize: 16 }}>{item.servTypeName}</Tag>
      {getLabelValue(item)}
    </>
  ) : (
    getLabelValue(item)
  );
};

export const setTitleData = (item: IOfferServes) => ({
  desc: item.raoServiceDesc,
  label: getLabelValue(item),
  value: item.raoServId,
  servTypeName: item.servTypeName,
});
