import { HeaderTitleComponent } from 'components';
import React from 'react';
import SearchTable from './SearchTable/SearchTable';

type Props = {
  // listOffer: ILestOffer | undefined;
  data: any;
  loading: boolean;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  currentPageNumber: number;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageable: any;
  onModalOpenHistory: (id: number | string) => void;
  user: string[] | undefined;
};

const SearchResults = (props: Props) => {
  const {
    data,
    loading,
    pageSize,
    setPageSize,
    currentPageNumber,
    setCurrentPageNumber,
    pageable,
    onModalOpenHistory,
    user,
  } = props;
  return (
    <div>
      <HeaderTitleComponent>ผลการค้นหา</HeaderTitleComponent>

      <div className="padding-conten-main">
        <SearchTable
          data={data}
          loading={loading}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
          pageable={pageable}
          onModalOpenHistory={onModalOpenHistory}
          user={user}
        />
      </div>
    </div>
  );
};

export default SearchResults;
