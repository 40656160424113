import axiosInstance from 'utils/axios';

export const uploadOfferAttachmentFile = (offerId: number, formData: FormData) => {
  return axiosInstance.post(`offer/${offerId}/attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadOfferAttachmentFile = (attachFileId: string | number) => {
  return axiosInstance.get(`/offer/attachment/${attachFileId}/download`, {
    responseType: 'blob', // This tells Axios to return the response as a blob object
  });
};

export const deleteUploadedOfferFile = (attachFileId: string | number) => {
  return axiosInstance.delete(`/offer/attachment/${attachFileId}/delete`);
};
