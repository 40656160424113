import { Form, Row, Col, Input } from 'antd';
import { useEffect } from 'react';

import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';
import { formLayout } from 'constants/form';
import { IStepModalForm } from './interfaces';
import { ThaiDatepickerComponent } from 'components';

interface IStepFormWithDate extends IStepModalForm {
  label?: string;
}

const StepFormWithDate = ({
  form,
  stepData,
  isDisable,
  onUpdateOfferState,
  isHideDateForm,
  label,
}: IStepFormWithDate) => {
  const onFinish = (values: any) => {
    let data: any = {
      // formStateId: stepData?.formStateId,
      // stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: '',
      // actionName: '',
      approvedNo: '',
      approvedDate: null,
      isCompleteState: false,
      isCurrentState: true,
    };
    if (!isHideDateForm) {
      const date = new Date(values.actionDate);
      data.actionDate = dayJsformatDateObjToString(date);
    }
    onUpdateOfferState(data);
  };

  useEffect(() => {
    if (stepData) {
      let _stepData: any = { ...stepData };
      _stepData.actionDate = stepData.actionDate
        ? getJSDateObj(stepData.actionDate)
        : new Date();
      form.setFieldsValue({
        ..._stepData,
      });
    }
  }, [form, stepData]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      name="stepModalForm"
      form={form}
      onFinish={onFinish}
      size="large"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะสัญญา" name="stateName">
            <Input disabled />
          </Form.Item>
        </Col>
        {!isHideDateForm && (
          <Col span={24}>
            <Form.Item
              colon
              label={label ? label : 'วันที่บันทึก'}
              name="actionDate"
              rules={[{ required: true, message: `กรุณาเลือก${label ?? 'วันที่บันทึก'}` }]}
            >
              <ThaiDatepickerComponent disabled={isDisable} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default StepFormWithDate;
