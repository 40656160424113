import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Divider } from 'antd';

import usePagination from 'hooks/usePagination';
import { useApi, TApiResponse } from 'hooks/useApi';
import { listChildContractInquiry } from 'api/contract/inquiry';

import WithHistoryModal from '../WithHistoryModal';
import ContractReviewListTable from '../ContractReviewListTable';
import { subRoutePaths } from 'constants/routes';

const ChildContractTable = () => {
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  const { currentPageNumber, pageSize, paginationSetting } = usePagination();
  const { conFormId } = useParams<{ conFormId: string }>();

  useEffect(() => {
    if (conFormId) makeRequest(() => listChildContractInquiry(conFormId));
  }, [makeRequest, conFormId]);

  return (
    <div>
      <h2>ตารางสัญญาลูก</h2>
      <WithHistoryModal>
        {(props: any) => {
          return (
            <ContractReviewListTable
              data={data}
              loading={loading}
              pageSize={pageSize}
              currentPageNumber={currentPageNumber}
              paginationSetting={paginationSetting(pageable?.totalItems)}
              getEditPath={subRoutePaths.inquiryContractDetail}
              {...props}
            />
          );
        }}
      </WithHistoryModal>
      <Divider />
    </div>
  );
};

export default ChildContractTable;
