import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { HOST } from '../constants/apis';
import transform from './transform';

import CookiesService from 'service/CookiesService';

const requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const referenceNo = CookiesService.get('REFERENCE_NO');
  const configure: AxiosRequestConfig = {
    ...config,
    //@ts-ignore
    headers: {
      ...config.headers,
      'Reference-No': referenceNo,
    },
  };

  return configure;
};

const responseInterceptor = (response: AxiosResponse<IResponse>): IResponse => {
  const successResponse = transform.response(response);
  return successResponse;
};

const handleRequestError = (error: any) => {
  return Promise.reject(error);
};

const handleResponseError = (error: AxiosError<IResponse>): Promise<IResponse> => {
  const errorResponse = transform.response(error);
  return Promise.reject(errorResponse);
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: HOST.url_ICDS_APP + HOST.api_context,
  timeout: 10000,
  responseType: 'json',
  withCredentials: window.location.protocol === 'https',
});

const LogoutInstance: AxiosInstance = axios.create({
  baseURL: HOST.url_ICDS_APP,
  timeout: 10000,
  responseType: 'json',
  withCredentials: window.location.protocol === 'https',
});

axiosInstance.defaults.headers.common['Accept'] = 'application/json';
axiosInstance.interceptors.request.use(requestInterceptor, handleRequestError);
axiosInstance.interceptors.response.use(responseInterceptor, handleResponseError);
console.info('Configurated service.');

LogoutInstance.defaults.headers.common['Accept'] = 'application/json';
export { LogoutInstance };
export default axiosInstance;
