import { useEffect } from 'react';
import { Form, Row, Col, Input } from 'antd';

import { getJSDateObj } from 'utils/date';
import { IOfferDetail } from './interfaces';
import { ThaiDatepickerComponent } from 'components';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const OfferDetail = ({ reviewData }: IOfferDetail) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (reviewData) {
      form.setFieldsValue({
        responsiblePerson: reviewData.offerResponsibilityUser,
        operName: reviewData.operName,
        offerFormTypeName: reviewData.offerFormTypeName,
        offerFormGroupName: reviewData.offerFormGroupName,
        offerFormNo: reviewData.offerFormNo,
        offerFormDate: reviewData.offerFormDate
          ? getJSDateObj(reviewData.offerFormDate)
          : undefined,
      });
    }
  }, [form, reviewData]);

  return (
    <div className="content-box">
      <Form
        {...layout}
        labelWrap
        form={form}
        name="EditOfferForm"
        initialValues={{ remember: true }}
      >
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Form.Item colon label="ผู้ได้รับมอบหมาย" name="responsiblePerson" labelAlign="left">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item colon label="บริษัท" name="operName" labelAlign="left">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item colon label="ประเภทข้อเสนอ" name="offerFormTypeName" labelAlign="left">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item colon label="กลุ่มข้อเสนอ" name="offerFormGroupName" labelAlign="left">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item colon label="เลขที่หนังสือผู้ประกอบการ" name="offerFormNo" labelAlign="left">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              colon
              label="วันที่หนังสือผู้ประกอบการ"
              name="offerFormDate"
              labelAlign="left"
            >
              <ThaiDatepickerComponent disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OfferDetail;
