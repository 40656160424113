import { Button, Table, TableColumnsType, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { OTHER_OPTION } from 'features/Offer/constant';
import { getPayPeriodUnitName } from 'utils/payPeriodUnitName';
import { IContServePay, IContServesData } from 'features/Contract/interfaces';

const getServNameWithServType = (record: any) => {
  let servName = record.servName;
  if (record.servName === OTHER_OPTION) {
    servName = record.serviceDesc;
  }

  return (
    <>
      {record.servTypeName && <Tag style={{ fontSize: 16 }}>{record.servTypeName}</Tag>}
      {`${servName}`}
    </>
  );
};

type ColumnsProps = {
  onEditContServes: (props: { contServes: IContServesData }) => void;
  isDisabled: boolean;
};

export const getColumns = ({ onEditContServes, isDisabled }: ColumnsProps) => {
  const columns: ColumnsType<IContServesData> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '100px',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'บริการ',
      dataIndex: 'servName',
      key: 'servName',
      align: 'center',
      render: (_, record) => (
        <div style={{ textAlign: 'left' }}>{getServNameWithServType(record)}</div>
      ),
    },
    {
      title: 'อัตราค่าตอบแทน',
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      width: '100px',
      render: (_, record) => {
        return (
          <div>
            <Button
              size="middle"
              disabled={isDisabled}
              icon={<EditOutlined />}
              onClick={() => {
                onEditContServes({
                  contServes: record,
                });
              }}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};

export const expandedRowRenderer = ({ record }: any) => {
  const columns: TableColumnsType<IContServePay> = [
    {
      title: '',
      dataIndex: 'emptyCell',
      key: 'emptyCell',
      align: 'center',
      width: '48px',
    },
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '100px',
      render: (_, __, index) => <>{index + 1}</>,
    },
    { title: 'เงื่อนไขราคา', dataIndex: 'payCondition', key: 'name', align: 'center' },
    { title: 'อัตราตอบแทน', dataIndex: 'payCompensation', key: 'payCompensation', align: 'center' },
    {
      title: 'สกุลเงิน',
      dataIndex: 'currencyName',
      key: 'currencyName',
      align: 'center',
      render: (_, record) => {
        if (record.currencyName === OTHER_OPTION) return <>{record.currencyDesc}</>;
        return <>{record.currencyName}</>;
      },
    },
    {
      title: 'หน่วย(Unit)',
      dataIndex: 'unitName',
      key: 'unitName',
      align: 'center',
      render: (_, record) => {
        if (record.unitName === OTHER_OPTION) return <>{record.unitDesc}</>;
        return <>{record.unitName}</>;
      },
    },
    {
      title: 'ระยะเวลา',
      dataIndex: 'payPeriod',
      key: 'payPeriod',
      align: 'center',
      render: (value, record) => {
        return (
          <>
            {value} {getPayPeriodUnitName(record.payPeriodUnit)}
          </>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={record.contServePays ? record.contServePays : []}
      rowKey={(value) => {
        return `${value.currencyId}-${value.unitId}-${value.payCompensation}-${value.payPeriodUnit}`;
      }}
      pagination={false}
      className="sub-table"
    />
  );
};
