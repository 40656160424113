import axiosInstance from 'utils/axios';

import { FunctionComponent, createContext, ReactNode, useState, useCallback } from 'react';
export interface TApiResponse {
  getAPIData: Function;
  putAPIData: Function;
  deleteAPIData: Function;
  status: Number;
  statusText: String;
  data: any;
  pageable: any;
  error: any;
  loading: boolean;
  dataAll: any;
  setDataAll: React.Dispatch<any>;
}

const ApiContext = createContext<TApiResponse | null>(null);

interface TApiResponseProviderProps {
  children: ReactNode;
}


// NOTE: deprecated ??
const ApiProvider: FunctionComponent<TApiResponseProviderProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<Number>(0);
  const [statusText, setStatusText] = useState<String>('');
  const [data, setData] = useState<any>();
  const [dataAll, setDataAll] = useState<any>();
  const [pageable, setPageable] = useState<any>();
  const [error, setError] = useState<any>();

  const getAPIData = useCallback(async (url: string, config?: {}, baseURL?: string) => {
    axiosInstance.defaults.baseURL = baseURL;
    setLoading(true);
    try {
      const apiResponse = await axiosInstance.get(url, config);
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(apiResponse.data.data);
      setDataAll(apiResponse.data);
      setPageable(apiResponse.data?.pageable ? apiResponse.data?.pageable : undefined);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, []);

  const putAPIData = useCallback(
    async (url: string, requestBody: {}, config?: {}, baseURL?: string) => {
      axiosInstance.defaults.baseURL = baseURL;
      setLoading(true);
      try {
        const apiResponse = await axiosInstance.put(url, requestBody, config);
        setStatus(apiResponse.status);
        setStatusText(apiResponse.statusText);
        setData(apiResponse.data?.data);
      } catch (error) {
        setError(error);
      }
      setLoading(false);
    },
    []
  );

  const deleteAPIData = useCallback(async (url: string, config?: {}, baseURL?: string) => {
    axiosInstance.defaults.baseURL = baseURL;
    setLoading(true);
    try {
      const apiResponse = await axiosInstance.delete(url, config);
      setStatus(apiResponse.status);
      setStatusText(apiResponse.statusText);
      setData(apiResponse.data?.data);
    } catch (error) {
      setError(error);
      setLoading(false);
      throw error;
    }
    setLoading(false);
  }, []);
  return (
    <ApiContext.Provider
      value={{
        putAPIData,
        getAPIData,
        status,
        statusText,
        data,
        error,
        loading,
        pageable,
        deleteAPIData,
        dataAll,
        setDataAll,
      }}
    >
      {props.children}
    </ApiContext.Provider>
  );
};

export { ApiProvider, ApiContext };

// export type TApiResponse = {
//   getAPIData: Function;
//   putAPIData: Function;
//   status: Number;
//   statusText: String;
//   data: any;
//   error: any;
//   loading: boolean;
// };

// export const useApi = (): TApiResponse => {
//   const [status, setStatus] = useState<Number>(0);
//   const [statusText, setStatusText] = useState<String>('');
//   const [data, setData] = useState<any>();
//   const [error, setError] = useState<any>();
//   const [loading, setLoading] = useState<boolean>(false);

//   const getAPIData = async (url: string, config?: {}, baseURL?: string) => {
//     axiosInstance.defaults.baseURL = baseURL;
//     setLoading(true);
//     try {
//       const apiResponse = await axiosInstance.get(url, config);
//       setStatus(apiResponse.status);
//       setStatusText(apiResponse.statusText);
//       setData(apiResponse.data?.data);
//     } catch (error) {
//       setError(error);
//     }
//     setLoading(false);
//   };

//   const putAPIData = async (url: string, requestBody: {}, config?: {}, baseURL?: string) => {
//     axiosInstance.defaults.baseURL = baseURL;
//     setLoading(true);
//     try {
//       const apiResponse = await axiosInstance.put(url, requestBody, config);
//       setStatus(apiResponse.status);
//       setStatusText(apiResponse.statusText);
//       setData(apiResponse.data?.data);
//     } catch (error) {
//       setError(error);
//     }
//     setLoading(false);
//   };

//   return { putAPIData, getAPIData, status, statusText, data, error, loading };
// };
