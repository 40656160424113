import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';

import useAuth from 'hooks/useAuth';

import ProfileSwitchingButton from 'components/ProfileSwitchingButton';
import { headerText } from './headerText';
import { getDropDownItems } from './menuItems';

import IconPage from 'assets/img/iconPage.png';
import styles from './Sider.module.css';
import { getFilterdMenuList } from './utils';
import useSideMenu from './hooks/useSideMenu';
import AppFooter from 'components/AppFooter';

const { Content, Sider } = Layout;

type SiderComponentProps = {
  children: ReactNode;
  headerTitle?: string;
};

export const SiderComponent = ({ children, headerTitle }: SiderComponentProps) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { pathname } = useLocation();
  const { selectedMenu, openedSubMenu, onOpenChange } = useSideMenu();

  return (
    <>
      <Layout className={styles.layout}>
        <Sider
          width={window.innerWidth <= 1440 ? 320 : 320}
          style={{ backgroundColor: '#56282d' }}
          breakpoint="xl"
          collapsedWidth="0"
        >
          <div className={styles.header}>
            <div className={styles.divHeader}>
              <img src={IconPage} alt="login" width="90px" height="120px" />
              <div className={styles.divHeaderText}>
                ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม
              </div>
            </div>
            <Menu
              mode="inline"
              className={styles.menu}
              selectedKeys={[selectedMenu]}
              openKeys={openedSubMenu}
              onOpenChange={onOpenChange}
              onClick={({ key }) => navigate(key, { state: { from: pathname } })}
              items={getFilterdMenuList(user?.permissions || [])}
            />
            <div className={styles.logoutDiv}>
              <Button className={styles.buttonLogout} onClick={logout}>
                ออกจากระบบ
              </Button>
            </div>
          </div>
        </Sider>
        <Layout
          style={{
            backgroundColor: 'transparent',
          }}
        >
          <Content>
            <div>
              <div className={styles.contentSite}>
                <div className={styles.headerText}>
                  {headerTitle ? headerTitle : headerText(pathname)}
                </div>
                <div className={styles.divUser}>
                  <ProfileSwitchingButton />
                  <div>
                    <Dropdown
                      menu={{ items: getDropDownItems({ onLogout: logout }) }}
                      placement="bottomLeft"
                    >
                      <div style={{ cursor: 'pointer', marginLeft: 10 }}>
                        <MenuOutlined />
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div
                style={{
                  padding: '20px',
                }}
              >
                <div className={styles.divChildren}>{children}</div>
              </div>
            </div>
          </Content>
          <AppFooter />
        </Layout>
      </Layout>
    </>
  );
};
