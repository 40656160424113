/* eslint-disable */

import { Alert, Checkbox, Col, Form, Row } from 'antd';
import { useEffect, useState } from 'react';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { useApi } from 'hooks/useApi';
import { Disagreement } from 'api/disagreement/interface';
import { useForm } from 'antd/es/form/Form';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormFilesUpload from './components/Form-Files-Upload';
import { validateFormNotEmpty } from 'utils/form';
import { getSummarize, UpdateDisagreementState, updateSummarize } from 'api/disagreement';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { differenceInCalendarDays } from 'date-fns';
import { convertDataFormDatePickerToString, convertDateFromApiToDatePickerWithTimeZoneAsian } from 'utils/date';

interface Form12Prop {
  isCompleted: boolean;
  disagreement?: Disagreement,
  completeDate?: string,
  stepState: number,
  onActionSuccess: () => void
}

const Form12 = ({ isCompleted, stepState, disagreement, completeDate, onActionSuccess }: Form12Prop): JSX.Element => {
  const [form] = useForm();
  const [disableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableNextState, setDisableNextState] = useState(true);
  const [dateCount, setDateCount] = useState<number>();
  const [validateRules] = useState({
    summarizeDate: true,
  });
  const { makeRequest: UpdateSummarizeFormRequest } = useApi();
  const { makeRequest: UpdateStateFormRequest } = useApi();
  const { makeRequest: GetDisagreementForm12Request } = useApi();

  const validateForm = (): void => {
    const result = Object.entries(validateRules).every(([key, value]) => {
      if (!value) {
        return true;
      } else {
        return validateFormNotEmpty(form.getFieldValue(key));
      }
    });
    setDisableNextState(!result);
  };

  useEffect(() => {
    validateForm();
  }, [validateRules]);

  useEffect(() => {
    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: new Date(),
        },
      ]);
    }

  }, [disagreement, completeDate]);

  useEffect(() => {
    if (disagreement) {
      GetDisagreementForm12Request(async () => {
        const res = await getSummarize(disagreement.formId, stepState);
        const result = res.data.data;
        if (result) {
          form.setFields([
            {
              name: 'summarizeDate',
              value: convertDateFromApiToDatePickerWithTimeZoneAsian(result.summarizeDate),
            },
            {
              name: 'remark',
              value: result.remark,
            },
          ]);
        }
        validateForm();
        return res;
      });

      const days = differenceInCalendarDays(dayjs(disagreement.receiveDiscussAccusedFormDate).toDate(), new Date());
      setDateCount(Math.abs(days));
    }
  }, [disagreement]);

  const [checkbox, setCheckBox] = useState(false);

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  const onSubmit = async () => {
    setLoading(true);
    if (disagreement) {
      await UpdateSummarizeFormRequest(async () => {
        return await updateSummarize({
          formId: disagreement.formId,
          stateId: stepState,
          completeDate: convertDataFormDatePickerToString(form.getFieldValue('completeDate')),
          summarizeDate: convertDataFormDatePickerToString(form.getFieldValue('summarizeDate')),
          remark: form.getFieldValue('remark'),
        }).catch(() => setLoading(false));
      });
      if (checkbox) {
        setLoading(true);
        await UpdateStateFormRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId).catch(() => {
            setLoading(false);
          });
        });
      }
    }
    setLoading(false);
    onActionSuccess();
  };
  return <>
    <Form form={form} onValuesChange={() => {
      validateForm();
    }}>
      <Row justify={'space-between'} gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item labelCol={{
            span: 8,
          }} labelAlign={'right'} label='วันสิ้นสุดการหาข้อเท็จจริง' name='summarizeDate'>
            <ThaiDatepickerComponent
              style={{
                width: '300px',
              }}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item labelCol={{
            span: 4,
          }} labelAlign={'right'} label='รายละเอียดเอกสาร' name='remark'>
            <TextArea style={{ width: '300px' }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <Alert message={
      typeof dateCount === 'number' ? 'รวมจำนวนวันที่ดำเนินการ: ' + dateCount + ' วัน' : ''
    } style={{
      marginTop: '24px',
      textAlign: 'center',
    }} type='warning' />
    <div style={{
      marginTop: '24px',
    }}>
      <FormFilesUpload stepState={stepState} disagreementFormId={disagreement?.formId || 0} state={12} />
    </div>
    <Form form={form} onValuesChange={() => {
      validateForm();
    }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox disabled={isCompleted || disableNextState} checked={checkbox}
                    onChange={onCheckBoxChange}>ยืนยันการพิจารณา</Checkbox>
        </Col>
        <Col span={8}>
          <Form.Item labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }
      }>
        <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onSubmit}>
          บันทึกรายการ
        </ButtonComponent>
      </div>
    </Form>
  </>;
};

export default Form12;
