import { Key, useEffect, useState } from 'react';
import { Form } from 'antd';

import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';
import useServiceFacilityValidation from './useServiceFacilityValidation';

import { DropdownType, IContServePay, IContServesData, IFacilitiesType } from '../interfaces';
import { OTHER_OPTION } from 'features/Offer/constant';

import { extractServId, getDataWithKey, getValueWithIdAndLabel } from '../utils';
import { getOfferServeLabelTextDropdown, setOfferServeTitleData } from '../utils/serviceDropdown';

type useManageContractServicesProps = {
  contServes: IContServesData[];
};

const useManageContractServices = ({ contServes }: useManageContractServicesProps) => {
  const [contServeModalFormRef] = Form.useForm();
  const [contServePayModalFormRef] = Form.useForm();
  const { error, warning } = useDialogAlert();
  const { onCheckExistingServiceInFacilites } = useServiceFacilityValidation();

  const [currentContServes, setCurrentContServes] = useState<IContServesData[]>([]);
  const [currentContServePays, setCurrentContServePays] = useState<IContServePay[]>([]);
  const [selectedContServe, setSelectedContServe] = useState<IContServesData | undefined>(
    undefined
  );
  const [selectedContServePay, setSelectedContServePay] = useState<IContServePay | undefined>(
    undefined
  );
  const [selectedServiceOption, setSelectedServiceOption] = useState<DropdownType | undefined>(
    undefined
  );
  const [selectedMultipleServices, setSelectedMultipleServices] = useState<Key[]>([]);
  const [selectedMultipleRecords, setSelectedMultipleRecords] = useState<IContServesData[]>([]);

  const {
    isModalOpen: isContServeModalOpen,
    showModal: showContServeModal,
    handleCancel: handleCancelContServeModal,
  } = useModal();
  const {
    isModalOpen: isContServePayModalOpen,
    showModal: showContServePayModal,
    handleCancel: handleCancelContServePayModal,
  } = useModal();

  const onCheckServePayAtLeastOne = () => {
    if (currentContServePays.length > 0) return;
    throw new Error('กรุณาเพิ่มอัตราค่าตอบแทนอย่างน้อย 1 รายการ');
  };

  const onCheckDuplicateService = (option: DropdownType) => {
    if (option.label === OTHER_OPTION) return;
    const isDuplicate = currentContServes.find((item: IContServesData) => {
      const _servId = getValueWithIdAndLabel({
        id: item.servId,
        label: item.servName,
        desc: item.serviceDesc,
      });
      if (_servId === option.value) {
        return true;
      }
      return false;
    });
    if (isDuplicate) {
      throw new Error('ไม่สามารถเพิ่มบริการซ้ำได้');
    }
  };

  const onResetCurrentContServes = () => {
    setCurrentContServes([]);
  };

  /** -------------------------------------------------------------------
   * NOTE: Service Table
   * -------------------------------------------------------------------
   */
  const onSelectMultipleServices = (selectedRowKeys: Key[], selectedRows: any[]) => {
    setSelectedMultipleServices(selectedRowKeys);
    setSelectedMultipleRecords(selectedRows);
  };

  const onEditContServes = ({ contServes }: { contServes: IContServesData }) => {
    let _contServes = {
      ...contServes,
      servId: getValueWithIdAndLabel({
        id: contServes.servId,
        label: contServes.servName,
        desc: contServes.serviceDesc,
      }),
    };
    _contServes.contServePays = getDataWithKey(_contServes.contServePays);
    setSelectedContServe(_contServes);
    setCurrentContServePays(
      _contServes.contServePays ? getDataWithKey(_contServes.contServePays) : []
    );
    showContServeModal();

    if (_contServes) {
      setSelectedServiceOption({
        value: _contServes.servId,
        label: _contServes.servName,
      });
      contServeModalFormRef?.setFieldsValue({
        ..._contServes,
        servId: {
          value: _contServes.servId,
          label:
            _contServes.servName === OTHER_OPTION
              ? OTHER_OPTION
              : getOfferServeLabelTextDropdown(_contServes),
          title: setOfferServeTitleData(_contServes),
        },
      });
    }
  };

  const onAddNewContServes = () => {
    const newKey = currentContServes ? currentContServes.length + 1 : 1;
    const newService: IContServesData = {
      key: newKey,
      serviceId: 0,
      servId: undefined,
      servName: undefined,
      serviceDesc: undefined,
      contServePays: undefined,
      servTypeId: undefined,
      servTypeDesc: undefined,
      servTypeName: undefined,
    };
    setSelectedContServe(newService);
    showContServeModal();
  };

  const onDeleteContServes = (currentContFacilities: IFacilitiesType[]) => {
    const allContServesValid = selectedMultipleRecords.every((service) => {
      try {
        onCheckExistingServiceInFacilites({ contServes: service, currentContFacilities });
        return true; // No error occurred, continue iterating
      } catch (e: any) {
        error({
          header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณายกเลิกบริการที่บันทึกไว้กับสิ่งอำนวยความสะดวกก่อน',
        });
        return false; // Stop iterating due to error
      }
    });

    if (!allContServesValid) {
      return; // Stop the entire process if an error occurred
    }

    const newContServes = currentContServes.filter(
      (item) => !selectedMultipleServices.includes(item.key)
    );
    setCurrentContServes(newContServes);
    setSelectedMultipleServices([]);
  };

  // -------------------------------------------------------------------

  /** -------------------------------------------------------------------
   * NOTE: ContServe Modal
   * -------------------------------------------------------------------
   */

  const onCheckHasExistingServePays = () => {
    if (currentContServePays.length === 0) return false;
    return currentContServePays.findIndex((item: IContServePay) => !item.servicePayId) !== -1;
  };

  const onChangeOfferServe = (option: DropdownType) => {
    try {
      setSelectedServiceOption(option);
      setCurrentContServePays(
        option.title?.servePays ? getDataWithKey(option.title?.servePays) : []
      );
      onCheckDuplicateService(option);
    } catch (e: any) {
      setSelectedServiceOption(undefined);
      setCurrentContServePays([]);
      contServeModalFormRef.resetFields(['servId', 'serviceDesc']);
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  const onCancelChangeOfferServe = () => {
    if (selectedContServe) {
      contServeModalFormRef?.setFieldsValue({
        ...selectedContServe,
        servId: {
          value: selectedContServe?.servId,
          label:
            selectedContServe?.servName === OTHER_OPTION
              ? OTHER_OPTION
              : getOfferServeLabelTextDropdown(selectedContServe),
          title: setOfferServeTitleData(selectedContServe),
        },
      });
    }
  };

  const onConfirmChangeOfferServe = (option: DropdownType) => {
    const hasExistingServePays = onCheckHasExistingServePays();
    if (hasExistingServePays) {
      warning({
        header: 'คำเตือน',
        sub: 'หากท่านต้องการเปลี่ยนบริการ อัตราค่าตอบแทนที่กรอกไว้จะหายไป',
        onConfirm: () => {
          onChangeOfferServe(option);
        },
        onCancel: () => {
          onCancelChangeOfferServe();
        },
      });
    } else {
      onChangeOfferServe(option);
    }
  };

  const onAddNewContServePay = () => {
    showContServePayModal();
  };

  const onCloseContServesModal = () => {
    setSelectedContServe(undefined);
    setSelectedServiceOption(undefined);
    setCurrentContServePays([]);
    contServeModalFormRef.resetFields();
    handleCancelContServeModal();
  };

  const onSubmitContServesModalForm = () => {
    try {
      onCheckServePayAtLeastOne();
      const formValue = contServeModalFormRef.getFieldsValue();
      if (selectedContServe) {
        const newSelectedContServe = {
          ...selectedContServe,
          servId: extractServId(formValue?.servId?.value),
          servName: formValue?.servId?.title?.label,
          serviceDesc: formValue?.serviceDesc,
          servTypeName: formValue?.servId?.title?.servTypeName,
          contServePays: currentContServePays,
        };

        const editedIndex = currentContServes.findIndex(
          (item: IContServesData) => item.key === newSelectedContServe.key
        );

        let _currentContServes = [];
        if (editedIndex !== -1) {
          _currentContServes = [...currentContServes];
          _currentContServes[editedIndex] = newSelectedContServe;
        } else {
          _currentContServes = [...currentContServes, newSelectedContServe];
        }

        setCurrentContServes(_currentContServes);
        onCloseContServesModal();
      }
    } catch (e: any) {
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: '',
      });
    }
  };
  // -------------------------------------------------------------------

  /** -------------------------------------------------------------------
   * NOTE: ContServePays Modal
   * -------------------------------------------------------------------
   */
  const onEditContServePay = (servePay: IContServePay) => {
    setSelectedContServePay(servePay);
    contServePayModalFormRef.setFieldsValue({
      ...servePay,
      unitId: { value: servePay.unitId, label: servePay.unitName },
      currencyId: { value: servePay.currencyId, label: servePay.currencyName },
    });
    showContServePayModal();
  };

  const onDeleteContServePay = (servePay: IContServePay) => {
    const _servePays = currentContServePays.filter(
      (item: IContServePay) => item.key !== servePay.key
    );

    if (selectedContServe) {
      const newSelectedContServe = {
        ...selectedContServe,
        contServePays: _servePays,
      };
      setSelectedContServe(newSelectedContServe);
    }
    setCurrentContServePays(_servePays);
  };

  const onCloseContServePayModal = () => {
    handleCancelContServePayModal();
    setSelectedContServePay(undefined);
    contServePayModalFormRef.resetFields();
  };

  const onEditExistingOrDefaultServePay = ({ formValues }: { formValues: any }) => {
    const newContServePay: IContServePay[] = currentContServePays.map((item: IContServePay) => {
      if (item.key === selectedContServePay?.key) {
        return {
          ...formValues,
          unitId: formValues?.unitId?.value,
          unitName: formValues?.unitId?.label,
          currencyId: formValues?.currencyId?.value,
          currencyName: formValues?.currencyId?.label,
          key: item.key,
        };
      }
      return item;
    });

    if (selectedContServe) {
      const newSelectedContServe = {
        ...selectedContServe,
        contServePays: newContServePay,
      };
      setSelectedContServe(newSelectedContServe);
    }

    setCurrentContServePays(newContServePay);
    onCloseContServePayModal();
  };

  const onAddNewServePay = ({ formValues }: { formValues: any }) => {
    const newKey = currentContServePays.length + 1;
    const newContServePay: IContServePay = {
      ...formValues,
      servicePayId: 0,
      unitId: formValues?.unitId?.value,
      unitName: formValues?.unitId?.label,
      currencyId: formValues?.currencyId?.value,
      currencyName: formValues?.currencyId?.label,
      key: newKey,
    };

    if (selectedContServe) {
      const newSelectedContServe = {
        ...selectedContServe,
        contServePays: [...currentContServePays, newContServePay],
      };
      setSelectedContServe(newSelectedContServe);
    }

    setCurrentContServePays([...currentContServePays, newContServePay]);
    onCloseContServePayModal();
  };

  const onSubmitContServePayModalForm = () => {
    const formValues = contServePayModalFormRef.getFieldsValue();
    if (selectedContServePay) {
      // NOTE: Edit Case
      onEditExistingOrDefaultServePay({ formValues });
    } else {
      // NOTE: Add Case
      onAddNewServePay({ formValues });
    }
  };
  // -------------------------------------------------------------------

  useEffect(() => {
    setCurrentContServes(getDataWithKey(contServes));
  }, [contServes]);

  return {
    isContServeModalOpen,
    isContServePayModalOpen,
    contServeModalFormRef,
    contServePayModalFormRef,
    currentContServes,
    currentContServePays,
    selectedMultipleServices,
    selectedContServe,
    selectedServiceOption,
    selectedContServePay,
    onEditContServes,
    onSelectMultipleServices,
    onAddNewContServes,
    onDeleteContServes,
    onCloseContServesModal,
    onSubmitContServesModalForm,
    onConfirmChangeOfferServe,
    onAddNewContServePay,
    onEditContServePay,
    onDeleteContServePay,
    onCloseContServePayModal,
    onSubmitContServePayModalForm,
    onResetCurrentContServes,
  };
};

export default useManageContractServices;
