import { useCallback, useEffect } from 'react';

import { TApiResponse, useApi } from 'hooks/useApi';
import { listDistrictsByProvinceId } from 'api/master';

type useDistrictProps = {
  provinceId: string | number;
};

const useDistrict = ({ provinceId }: useDistrictProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  const onListDistrictByProvinceId = useCallback(
    (provinceId: string | number) => {
      makeRequest(() => listDistrictsByProvinceId(provinceId));
    },
    [makeRequest]
  );

  useEffect(() => {
    if (provinceId) onListDistrictByProvinceId(provinceId);
  }, [onListDistrictByProvinceId, provinceId]);

  return { onListDistrictByProvinceId, districts: data, isLoadingDistricts: loading };
};

export default useDistrict;
