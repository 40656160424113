import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listOfferFormType } from 'api/master';
import { onFilterOption } from 'utils/select';

type OfferTypeDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  disabled?: boolean;
  fieldName?: string;
  isRequired?: boolean;
};

const OfferTypeDropdown = ({
  label,
  labelCol,
  fieldName,
  disabled = false,
  isRequired = false,
}: OfferTypeDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listOfferFormType());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name={fieldName ? fieldName : 'offerFormType'}
        label={label ? label : 'ประเภทข้อเสนอ'}
        labelCol={labelCol}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'ประเภทข้อเสนอ'}` }]
            : undefined
        }
      >
        <Select
          showSearch
          allowClear
          loading={loading}
          disabled={disabled}
          placeholder="-- กรุณาระบุ --"
          filterOption={onFilterOption}
          options={
            data
              ? data.map((item: { code: string; name: string }) => ({
                  value: item.code,
                  label: item.name,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OfferTypeDropdown;
