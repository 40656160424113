import {
  GetContactChartResponse,
  GetContractChartResponse,
  GetDisagreementAllStatusNameResponse,
  GetDisagreementChartResponse,
  GetDisagreementTotalResponse,
  GetOfferChartResponse,
  GetOfferChartTotalResponse,
  GetOperatorChartResponse,
  GetUsageFormChartResponse,
} from 'api/dashboard/interface';
import axiosInstance from 'utils/axios';

export const getContact = (year: number): Promise<GetContactChartResponse> => {
  return axiosInstance.post(`contract/chart-total`, {
    year,
  });
};

export const getUsageFormChart = (year: number, quarter: number): Promise<GetUsageFormChartResponse> => {
  return axiosInstance.post(`operators/usage-form/chart`, {
    year,
    quarter,
  });
};


export const getOfferChartTotal = (year: number): Promise<GetOfferChartTotalResponse> => {
  return axiosInstance.post(`offer/chart-total`, {
    year,
  });
};

export const getDisagreementAllStatusName = (): Promise<GetDisagreementAllStatusNameResponse> => {
  return axiosInstance.get(`disagreements/chart/status-group-name`);
};

export const getDisagreementChart = (): Promise<GetDisagreementChartResponse> => {
  return axiosInstance.get(`disagreements/chart`);
};

export const getDisagreementTotal = (): Promise<GetDisagreementTotalResponse> => {
  return axiosInstance.get(`disagreements/chart/total`);
};

export const getOfferChart = (taxId: number): Promise<GetOfferChartResponse> => {
  return axiosInstance.post(`offer/chart`, {
    taxId,
  });
};

export const getContractChart = (taxId: number): Promise<GetContractChartResponse> => {
  return axiosInstance.post(`contract/chart`, {
    taxId,
  });
};
export const getOperatorChart = (taxId: string): Promise<GetOperatorChartResponse> => {
  return axiosInstance.post(`operators/chart`, {
    taxId,
  });
};
