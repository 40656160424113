import { useCallback, useMemo, useState } from 'react';
import { Form } from 'antd';

import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';
import { TApiResponse, useApi } from 'hooks/useApi';

import { getServiceAnnounceRate } from 'api/offer';

import useValidation from './useValidation';

import { OFFER_TYPE_RAO, OFFER_TYPE_RIO } from 'constants/offerFormType';
import { DropdownType, IOfferFacility, IOfferServePay, IOfferServes } from '../interfaces';
import { OTHER_OPTION } from '../constant';

type UseManageServicesRIOProps = {
  offerType: string;
  currentOfferServices: IOfferServes[];
  selectedMultipleRecords: IOfferServes[];
  setCurrentOfferServices: React.Dispatch<React.SetStateAction<IOfferServes[]>>;
  onDeleteServices: () => void;
};

const useManageServiceRIO = ({
  offerType,
  currentOfferServices,
  selectedMultipleRecords,
  setCurrentOfferServices,
  onDeleteServices,
}: UseManageServicesRIOProps) => {
  const { error } = useDialogAlert();
  const { onCheckExistingServiceInFacilites } = useValidation();
  const { makeRequest, loading: isLoadingServiceAnnounceRate }: TApiResponse = useApi();
  const [RIOserviceModalFormRef] = Form.useForm();
  const [RIOservePayModalFormRef] = Form.useForm();
  const [selectedService, setSelectedService] = useState<IOfferServes | undefined>(undefined);
  const [selectedServePay, setSelectedServePay] = useState<IOfferServePay | undefined>(undefined);
  const [selectedServType, setSelectedServType] = useState<DropdownType | undefined>(undefined);
  const [selectedServiceOption, setSelectedServiceOption] = useState<DropdownType | undefined>(
    undefined
  );

  const {
    isModalOpen: isRIOServePayModalOpen,
    showModal: showRIOServePayModal,
    handleCancel: handleCancelRIOServePayModal,
  } = useModal();
  const {
    isModalOpen: isRIOServiceModalOpen,
    showModal: showRIOServiceModal,
    handleCancel: handleCancelRIOServiceModal,
  } = useModal();

  const currentOfferServPayWithKey = useCallback((servePay: IOfferServePay[] | undefined) => {
    if (!servePay) return [];
    return servePay?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      };
    });
  }, []);

  /** -------------------------------------------------------------------
   * NOTE: RIO Service Announce Rate
   * -------------------------------------------------------------------
   */
  const isDisableServiceAnnounceRate = useMemo(() => {
    if (currentOfferServices) {
      if (currentOfferServices.length > 0) return true;
    }
    return false;
  }, [currentOfferServices]);

  const onAddServiceAnnounceRate = async () => {
    const response = await makeRequest(() => getServiceAnnounceRate());
    const data = response?.data?.data;
    if (data) {
      const newOfferServices = data.map((item: any, index: any) => {
        return {
          ...item,
          key: index + 1,
        };
      });
      setCurrentOfferServices(newOfferServices);
    } else {
      error({
        header: 'มีข้อผิดพลาดเกิดขึ้น',
        sub: 'กรุณาลองใหม่อีกครั้ง (error: #0724)',
      });
    }
  };
  // -------------------------------------------------------------------

  /** -------------------------------------------------------------------
   * NOTE: RIO Service Modal
   * -------------------------------------------------------------------
   */
  const onAddNewRIOService = () => {
    const newKey = currentOfferServices ? currentOfferServices.length + 1 : 1;
    const newService: IOfferServes = {
      key: newKey,
      serviceId: 0,
      raoServId: undefined,
      raoServName: undefined,
      raoServiceDesc: undefined,
      servTypeId: undefined,
      servTypeName: undefined,
      servTypeDesc: undefined,
      offerServePays: undefined,
    };
    setSelectedService(newService);
    showRIOServiceModal();
  };

  const onEditRIOService = ({
    service,
    currentOfferFacilities, // FIXME:M Remove this
  }: {
    service: IOfferServes;
    currentOfferFacilities: IOfferFacility[];
  }) => {
    try {
      // onCheckExistingServiceInFacilites({ service, currentOfferFacilities });

      let _service = { ...service };
      _service.offerServePays = currentOfferServPayWithKey(_service.offerServePays);
      setSelectedService(_service);
      showRIOServiceModal();
      if (_service) {
        setSelectedServType({ value: _service.servTypeId, label: _service.servTypeName });
        setSelectedServiceOption({
          value: _service.raoServId,
          label: _service.raoServName,
        });
        RIOserviceModalFormRef?.setFieldsValue({
          ..._service,
          raoServId: { value: _service.raoServId, label: _service.raoServName },
          servTypeId: { value: _service.servTypeId, label: _service.servTypeName },
        });
      }
    } catch (e: any) {
      // FIXME:M Remove this
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: 'กรุณายกเลิกบริการที่บันทึกไว้กับสิ่งอำนวยความสะดวกก่อน',
      });
    }
  };

  const onCloseRIOServiceModal = () => {
    setSelectedService(undefined);
    setSelectedServType(undefined);
    setSelectedServiceOption(undefined);
    RIOserviceModalFormRef.resetFields();
    handleCancelRIOServiceModal();
  };

  const isSelectedOtherRIOService = useMemo(() => {
    if (selectedServiceOption) {
      return selectedServiceOption.label === OTHER_OPTION;
    }
    return false;
  }, [selectedServiceOption]);

  const onChangeRIOServType = useCallback(
    (option: any) => {
      setSelectedServType(option);
      setSelectedServiceOption(undefined);
      RIOserviceModalFormRef.resetFields(['raoServId', 'raoServiceDesc']);
    },
    [setSelectedServType, RIOserviceModalFormRef]
  );

  const onCheckRIODuplicateService = useCallback(
    (option: DropdownType) => {
      return currentOfferServices.find((item: IOfferServes) => {
        if (item.raoServId === option.value) {
          if (item.servTypeId === selectedServType?.value) {
            return true;
          }
        }
        return false;
      });
    },
    [currentOfferServices, selectedServType]
  );

  const onCheckRAODuplicateService = useCallback(
    (option: DropdownType) => {
      return currentOfferServices.find((item: IOfferServes) => {
        if (item.raoServId === option.value) {
          return true;
        }
        return false;
      });
    },
    [currentOfferServices]
  );

  const onCheckDuplicateService = useCallback(
    (option: DropdownType) => {
      // NOTE #8596: [4 Aug 2023] จาก Data ของ offer ตอนนี้จะยัง check duplicate ไม่ถูก
      // เพราะ servTypeId return มาเป็น 0 หมด มีปัญหาเรื่อง data
      // [16 Aug 2023] ตอนนี้สามารถสร้างใหม่แล้วเทสได้แล้ว แต่ตัวเก่าๆ Data เดิมจะมีปัญหาเหมือนเดิม ตัวใหม่ที่สร้างเท่านั้นที่จะเช็คถูก

      if (option.label === OTHER_OPTION) return;
      if (currentOfferServices && option.value) {
        let isDuplicate;
        switch (offerType) {
          case OFFER_TYPE_RIO:
            isDuplicate = onCheckRIODuplicateService(option);
            break;
          case OFFER_TYPE_RAO:
          default:
            isDuplicate = onCheckRAODuplicateService(option);
            break;
        }
        if (isDuplicate) {
          throw new Error('ไม่สามารถเพิ่มบริการซ้ำได้');
        }
      }
    },
    [currentOfferServices, onCheckRIODuplicateService, onCheckRAODuplicateService, offerType]
  );

  const onChangeRIOService = useCallback(
    (option: DropdownType) => {
      try {
        setSelectedServiceOption(option);
        onCheckDuplicateService(option);
      } catch (e: any) {
        setSelectedServiceOption(undefined);
        RIOserviceModalFormRef.resetFields(['raoServId', 'raoServiceDesc']);
        error({
          header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [setSelectedServiceOption, onCheckDuplicateService, error, RIOserviceModalFormRef]
  );

  const onCheckServePayAtLeastOne = () => {
    if (selectedService) {
      if (selectedService.offerServePays) {
        if (selectedService.offerServePays.length > 0) return;
      }
    }
    throw new Error('กรุณาเพิ่มอัตราค่าตอบแทนอย่างน้อย 1 รายการ');
  };

  const onSubmitServiceModalForm = () => {
    try {
      onCheckServePayAtLeastOne();
      const formValue = RIOserviceModalFormRef.getFieldsValue();
      if (selectedService) {
        const newSelectedService = {
          ...selectedService,
          raoServId: formValue?.raoServId?.value,
          raoServName: formValue?.raoServId?.label,
          raoServiceDesc: formValue?.raoServiceDesc,
          servTypeId: formValue?.servTypeId?.value,
          servTypeName: formValue?.servTypeId?.label,
        };

        const editIndex = currentOfferServices.findIndex((item: IOfferServes) => {
          return item.key === newSelectedService?.key;
        });

        let _currentOfferServices = [];
        if (editIndex !== -1) {
          _currentOfferServices = [...currentOfferServices];
          _currentOfferServices[editIndex] = newSelectedService;
        } else {
          _currentOfferServices = [...currentOfferServices, newSelectedService];
        }

        setCurrentOfferServices(_currentOfferServices);
        onCloseRIOServiceModal();
      }
    } catch (e: any) {
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: '',
      });
    }
  };
  // -------------------------------------------------------------------

  /** -------------------------------------------------------------------
   * NOTE: RIO Serve Pay Modal
   * -------------------------------------------------------------------
   */
  const onAddNewRIOServePay = () => {
    showRIOServePayModal();
  };

  const onEditRIOServePay = (servePay: IOfferServePay) => {
    setSelectedServePay(servePay);
    RIOservePayModalFormRef?.setFieldsValue({
      ...servePay,
      unitId: { value: servePay.unitId, label: servePay.unitName },
      currencyId: { value: servePay.currencyId, label: servePay.currencyName },
    });
    showRIOServePayModal();
  };

  const onCloseRIOServePayModal = () => {
    handleCancelRIOServePayModal();
    setSelectedServePay(undefined);
    RIOservePayModalFormRef.resetFields();
  };

  const onDeleteServePay = (servePay: IOfferServePay) => {
    if (selectedService) {
      const currentServePays = selectedService.offerServePays;
      if (currentServePays) {
        const _servePay = currentServePays.filter((item: IOfferServePay) => {
          return item.key !== servePay.key;
        });
        const newSelectedService = {
          ...selectedService,
          offerServePays: _servePay,
        };
        setSelectedService(newSelectedService);
      } else {
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง (error: #3245)',
        });
      }
    } else {
      error({
        header: 'ไม่พบบริการที่ต้องการลบอัตราค่าตอบแทน',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  const onSubmitServePayModalForm = () => {
    const value = RIOservePayModalFormRef.getFieldsValue();
    if (selectedServePay) {
      // NOTE: Edit Case
      const currentServePays = selectedService?.offerServePays;
      if (currentServePays) {
        const _servePay: IOfferServePay[] = currentServePays.map((item: IOfferServePay) => {
          if (item.key === selectedServePay.key) {
            return {
              ...value,
              unitId: value?.unitId?.value,
              unitName: value?.unitId?.label,
              currencyId: value?.currencyId?.value,
              currencyName: value?.currencyId?.label,
              key: item.key,
            };
          }
          return item;
        });
        const newSelectedService = {
          ...selectedService,
          offerServePays: _servePay,
        };
        setSelectedService(newSelectedService);
        onCloseRIOServePayModal();
      } else {
        error({
          header: 'ไม่พบอัตราค่าตอบแทนที่ต้องการแก้ไข',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    } else {
      // NOTE: Add Case
      if (selectedService) {
        const currentServePays = selectedService.offerServePays;
        const newKey = currentServePays ? currentServePays.length + 1 : 1;
        const newServePay: IOfferServePay = {
          ...value,
          servicePayId: 0,
          unitId: value?.unitId?.value,
          unitName: value?.unitId?.label,
          currencyId: value?.currencyId?.value,
          currencyName: value?.currencyId?.label,
          key: newKey,
        };

        let newSelectedService: IOfferServes = {
          ...selectedService,
          offerServePays: [newServePay],
        };
        if (currentServePays) {
          newSelectedService = {
            ...selectedService,
            offerServePays: [...currentServePays, newServePay],
          };
        }
        setSelectedService(newSelectedService);
        onCloseRIOServePayModal();
      } else {
        error({
          header: 'ไม่พบบริการที่ต้องการเพิ่มอัตราค่าตอบแทน',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    }
  };
  // -------------------------------------------------------------------

  /** -------------------------------------------------------------------
   * NOTE: RIO Service Table
   * -------------------------------------------------------------------
   */
  const onDeleteRIOServices = (currentOfferFacilities: IOfferFacility[]) => {
    const allServicesValid = selectedMultipleRecords.every((service) => {
      try {
        onCheckExistingServiceInFacilites({ service, currentOfferFacilities });
        return true; // No error occurred, continue iterating
      } catch (e: any) {
        error({
          header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณายกเลิกบริการที่บันทึกไว้กับสิ่งอำนวยความสะดวกก่อน',
        });
        return false; // Stop iterating due to error
      }
    });

    if (!allServicesValid) {
      return; // Stop the entire process if an error occurred
    }

    onDeleteServices();
  };
  // -------------------------------------------------------------------

  return {
    isDisableServiceAnnounceRate,
    isRIOServePayModalOpen,
    isRIOServiceModalOpen,
    isSelectedOtherRIOService,
    isLoadingServiceAnnounceRate,
    selectedService,
    selectedServePay,
    selectedServType,
    selectedServiceOption,
    RIOserviceModalFormRef,
    RIOservePayModalFormRef,
    onAddNewRIOService,
    onAddServiceAnnounceRate,
    onCloseRIOServiceModal,
    onEditRIOService,
    onCloseRIOServePayModal,
    onAddNewRIOServePay,
    onEditRIOServePay,
    onChangeRIOServType,
    onChangeRIOService,
    onSubmitServePayModalForm,
    onDeleteServePay,
    onSubmitServiceModalForm,
    onDeleteRIOServices,
  };
};

export default useManageServiceRIO;
