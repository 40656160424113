import { useEffect } from 'react';
import { Form, Space } from 'antd';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import { formLayout } from 'constants/form';

import { HeaderTitleComponent } from 'components';

import OperatorInfoForm from '../OperatorInfoForm';
import AddressInfoForm from '../AddressInfoForm';
import AddressDropdownForm from '../AddressDropdownForm';

const ContactInfoForm = () => {
  const { editOfferFormRef, offerData, isDisabled } = useOfferDataContext();
  useEffect(() => {
    if (offerData) {
      const operatorInfo = offerData.operators;
      editOfferFormRef.setFieldsValue({
        operName: operatorInfo?.operName,
        conName: operatorInfo?.conName,
        conTel: operatorInfo?.conTel,
        conFax: operatorInfo?.conFax,
        conMail: operatorInfo?.conMail,
        conNo: operatorInfo?.conNo,
        conMoo: operatorInfo?.conMoo,
        conSoi: operatorInfo?.conSoi,
        conRoad: operatorInfo?.conRoad,
        conProvinceId: operatorInfo?.conProvinceId,
        conDistrictId: operatorInfo?.conDistrictId,
        conSubDistrictId: operatorInfo?.conSubDistrictId,
        conPost: operatorInfo?.conPost,
      });
    }
  }, [editOfferFormRef, offerData]);

  return (
    <Space direction="vertical" size="middle" style={{ marginTop: 15 }}>
      <HeaderTitleComponent>ข้อมูลผู้ติดต่อ</HeaderTitleComponent>
      <Form
        {...formLayout}
        labelWrap
        size="large"
        name="editOfferForm"
        form={editOfferFormRef}
        disabled={isDisabled}
        validateTrigger="onSubmit" // Set the validation trigger to onSubmit
      >
        <Space direction="vertical" size="middle">
          <OperatorInfoForm />
          <AddressInfoForm />
          <AddressDropdownForm />
        </Space>
      </Form>
    </Space>
  );
};

export default ContactInfoForm;
