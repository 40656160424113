import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Form, Spin, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { useApi, TApiResponse } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';

import { getContractById, updateContract, createContract } from 'api/contract';
import { EDIT_PAGE, NEW_PAGE } from 'constants/page';

import { composeSaveData, composeCreateData } from 'features/Contract/utils/composeData';
import { getContractType, getPageFunction } from 'features/Contract/utils/offerFormType';
import { ATTACHMENT_TAB, CONTRACT_TAB } from 'features/Contract/constant';

import useValidation from 'features/Contract/hooks/useValidation';
import CreateContractTab from 'features/Contract/components/CreateContractTab';
import ButtonGroupContractTab from 'features/Contract/components/ButtonGroupContractTab';
import AttachmentTab from 'features/Contract/components/AttachmentTab';
import ChildContractTable from 'features/Contract/components/ChildContractTable';
import { IContServesData, IFacilitiesType } from 'features/Contract/interfaces';
import { ContractServicesProvider } from 'features/Contract/context/ContractServicesContext';
import { ContractFacilitiesProvider } from 'features/Contract/context/ContractFacilitiesContext';
import { subRoutePaths } from 'constants/routes';

const DetailInquiryContract = () => {
  const navigate = useNavigate();
  let query = useQuery();
  const [contractFormRef] = Form.useForm();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { conFormId } = useParams<{ conFormId: string }>();
  const { user } = useAuth();
  const { pathname, state } = useLocation();
  const from = state ? state.from : '/';
  const { error, success } = useDialogAlert();

  const {
    makeRequest: _getContractById,
    data: contractData,
    loading: isLoadingContract,
    setData: setContractData,
  }: TApiResponse = useApi();
  const { makeRequest, loading: isSaving }: TApiResponse = useApi();

  const [activeTab, setActiveTab] = useState(CONTRACT_TAB);
  const [isUpdatingState, setIsUpdatingState] = useState(false);

  const pageFunction = useMemo(() => getPageFunction(pathname), [pathname]);
  const isNewPage = useMemo(() => pageFunction === NEW_PAGE, [pageFunction]);
  const isDisabled = true;
  const offerFormType = useMemo(() => {
    if (!contractData) return '';
    return contractData.offerFormType;
  }, [contractData]);
  const contractType = useMemo(() => {
    return getContractType(offerFormType);
  }, [offerFormType]);

  const { validateContractPage } = useValidation({
    formRef: contractFormRef,
    offerFormType,
  });

  const onSaveContract = useCallback(
    async (data: any) => {
      try {
        const saveResponse = await makeRequest(() => updateContract(data.conFormId, data));
        setContractData(saveResponse.data?.data);
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
      } catch (errorInfo) {
        console.error(errorInfo);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [error, makeRequest, setContractData, success]
  );

  const onCreateContract = useCallback(
    async (data: any) => {
      try {
        const saveResponse = await makeRequest(() => createContract(data));
        setContractData(saveResponse.data?.data);
        const editContractPath = subRoutePaths.contractOfficerEdit;
        const path = editContractPath(saveResponse?.data?.data?.conFormId);
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' }, () =>
          navigate(path, { state: { from } })
        );
      } catch (errorInfo) {
        console.error(errorInfo);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [error, makeRequest, navigate, setContractData, success, from]
  );

  const createContractRequest = useCallback(
    async ({
      currentContServes,
      currentContFacilities,
    }: {
      currentContServes: IContServesData[];
      currentContFacilities: IFacilitiesType[];
    }) => {
      if (!user) {
        error({
          header: 'ไม่พบข้อมูลผู้ใช้งาน',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
        return;
      }
      switch (pageFunction) {
        case EDIT_PAGE:
          const editedData = composeSaveData({
            formData: contractFormRef.getFieldsValue(true),
            currentContServes,
            currentContFacilities,
          });
          await onSaveContract(editedData);
          break;
        case NEW_PAGE:
          const newData = composeCreateData({
            formData: contractFormRef.getFieldsValue(true),
            ownerId: query.get('ownerId'),
            offerFormType,
            currentContServes,
            currentContFacilities,
          });
          await onCreateContract(newData);
          break;
        default:
          break;
      }
    },
    [
      contractFormRef,
      error,
      onCreateContract,
      onSaveContract,
      offerFormType,
      pageFunction,
      user,
      query,
    ]
  );

  const onGetContractById = useCallback(() => {
    if (conFormId) {
      _getContractById(() => getContractById(conFormId));
    }
  }, [_getContractById, conFormId]);

  const onSubmitForm = useCallback(
    async ({
      currentContServes,
      currentContFacilities,
    }: {
      currentContServes: IContServesData[];
      currentContFacilities: IFacilitiesType[];
    }) => {
      try {
        await validateContractPage({ currentContServes });
        await createContractRequest({
          currentContServes,
          currentContFacilities,
        });
      } catch (errorInfo: any) {
        error({
          header: errorInfo ? errorInfo.message : 'กรุณากรอกฟอร์มให้ครบถ้วน',
          sub: '',
        });
      }
    },
    [createContractRequest, error, validateContractPage]
  );

  const tabItems: TabsProps['items'] = [
    {
      key: CONTRACT_TAB,
      label: `จัดทำสัญญา`,
      children: (
        <>
          <CreateContractTab
            contractFormRef={contractFormRef}
            contractData={contractData}
            offerFormType={offerFormType}
            isDisabled={isDisabled}
            isChortorEdit={true}
            contractType={contractType}
          />
          <ButtonGroupContractTab
            isDisabled={isDisabled}
            isNewPage={isNewPage}
            offerFormType={offerFormType}
            conFormId={conFormId}
            stateCode={contractData?.formStatus}
            isActiveContract={contractData?.isActiveContract}
            onSubmitForm={onSubmitForm}
            setActiveTab={setActiveTab}
            onGetContractById={onGetContractById}
            setIsUpdatingState={setIsUpdatingState}
            contAttachFiles={contractData?.contAttachFiles}
            setContractData={setContractData}
            isInquiryPage={true}
          />
        </>
      ),
    },
    {
      key: ATTACHMENT_TAB,
      label: `แนบเอกสาร`,
      disabled: isNewPage,
      children: (
        <AttachmentTab
          contractFormRef={contractFormRef}
          contractData={contractData}
          offerFormType={offerFormType}
          isDisabled={isDisabled}
          onGetContractById={onGetContractById}
          setIsUpdatingState={setIsUpdatingState}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetContractById();
  }, [onGetContractById]);

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle]);

  return (
    <ContractServicesProvider contractData={contractData} isDisabled={isDisabled}>
      <ContractFacilitiesProvider contractData={contractData} isDisabled={isDisabled}>
        <div className="content-box">
          <Spin tip="กำลังดำเนินการ..." spinning={isLoadingContract || isSaving || isUpdatingState}>
            <Content>
              {/* <ProcessStep /> */}
              <div>
                {contractData?.isParentContract && <ChildContractTable />}
                <Tabs
                  defaultActiveKey={CONTRACT_TAB}
                  onChange={(activeKey) => {
                    setActiveTab(activeKey);
                  }}
                  items={tabItems}
                  activeKey={activeTab}
                />
              </div>
            </Content>
          </Spin>
        </div>
      </ContractFacilitiesProvider>
    </ContractServicesProvider>
  );
};

export default DetailInquiryContract;
