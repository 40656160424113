import { useState } from 'react';
import { DEFAULT_PAGESIZE } from 'constants/pagination';

const usePagination = (customPageSize?: number) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(customPageSize ? customPageSize : DEFAULT_PAGESIZE);
  const paginationSetting = (totalItems: number) => {
    return {
      current: currentPageNumber,
      onChange: (page: number, pageSize: number) => {
        setPageSize(pageSize);
        setCurrentPageNumber(page);
      },
      onShowSizeChange: (_: any, pageSize: number) => {
        setPageSize(pageSize);
      },
      showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
      total: totalItems,
      defaultPageSize: pageSize,
      showSizeChanger: true,
    };
  };

  return { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber };
};

export default usePagination;
