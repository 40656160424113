export const programCode = {
  A000001: 'A000001', // ข้อมูลสรุป
  A000002: 'A000002', // ข้อมูลสรุปผู้ประกอบการ
  A000003: 'A000003', // การจัดทำข้อเสนอ RAO
  A000004: 'A000004', // RAO Add New
  A000005: 'A000005', // RAO Edit
  A000006: 'A000006', // RAO Detail
  A000007: 'A000007', // RAO Inquiry
  A000008: 'A000008', // RAO History
  A000009: 'A000009', // การจัดทำข้อเสนอ RIO
  A000010: 'A000010', // RIO Add New
  A000011: 'A000011', // RIO Edit
  A000012: 'A000012', // RIO Detail
  A000013: 'A000013', // RIO Inquiry
  A000014: 'A000014', // RIO History
  A000015: 'A000015', // การจัดทำข้อเสนอ RDRO
  A000016: 'A000016', // RDRO Add New
  A000017: 'A000017', // RDRO Edit
  A000018: 'A000018', // RDRO Detail
  A000019: 'A000019', // RDRO Inquiry
  A000020: 'A000020', // RDRO History
  A000021: 'A000021', // การจัดทำข้อเสนอ RISO
  A000022: 'A000022', // RISO Add New
  A000023: 'A000023', // RISO Edit
  A000024: 'A000024', // RISO Detail
  A000025: 'A000025', // RISO Inquiry
  A000026: 'A000026', // RISO History
  A000027: 'A000027', // การพิจารณาข้อเสนอ
  A000028: 'A000028', // Review Edit
  A000029: 'A000029', // การจัดทำข้อเสนอโดย ชท
  A000030: 'A000030', // การมอบหมายงานข้อเสนอ
  A000031: 'A000031', // ลิงก์ข้อเสนอ โดย ชท
  A000032: 'A000032', // ข้อมูลผู้ประกอบการ  (LMS)
  A000033: 'A000033', // กระบวนการระงับข้อพิพาท
  A000034: 'A000034', // เพิ่มกระบวนการระงับข้อพิพาท
  A000035: 'A000035', // แก้ไขกระบวนการระงับข้อพิพาท
  A000036: 'A000036', // สัญญาการใช้ฯ
  A000037: 'A000037', // สัญญาการใช้ฯ Add New
  A000038: 'A000038', // สัญญาการใช้ฯ Edit
  A000039: 'A000039', // สัญญาการใช้ฯ History
  A000040: 'A000040', // สัญญาการเชื่อมต่อฯ
  A000041: 'A000041', // สัญญาการเชื่อมต่อฯ Add New
  A000042: 'A000042', // สัญญาการเชื่อมต่อฯ Edit
  A000043: 'A000043', // สัญญาการเชื่อมต่อฯ History
  A000044: 'A000044', // สัญญาการใช้บริการข้ามโครงข่ายฯ
  A000045: 'A000045', // สัญญาการใช้บริการข้ามโครงข่ายฯ Add New
  A000046: 'A000046', // สัญญาการใช้บริการข้ามโครงข่ายฯ Edit
  A000047: 'A000047', // สัญญาการใช้บริการข้ามโครงข่ายฯ History
  A000048: 'A000048', // สัญญาการใช้โครงสร้างพื้นฐาน
  A000049: 'A000049', // สัญญาการใช้โครงสร้างพื้นฐาน Add New
  A000050: 'A000050', // สัญญาการใช้โครงสร้างพื้นฐาน Edit
  A000051: 'A000051', // สัญญาการใช้โครงสร้างพื้นฐาน History
  A000052: 'A000052', // การพิจารณาสัญญา
  A000053: 'A000053', // การพิจารณาสัญญา Edit
  A000054: 'A000054', // จัดทำสัญญาโดย ชท.
  A000055: 'A000055', // จัดทำสัญญาโดย ชท. Add New
  A000056: 'A000056', // จัดทำสัญญาโดย ชท. Edit
  A000057: 'A000057', // ลิงก์สัญญา โดย ชท
  A000058: 'A000058', // การมอบหมายงานสัญญา
  A000059: 'A000059', // รายงานภาพรวม
  A000060: 'A000060', // รายงานการติดตาม
  A000061: 'A000061', // รายงานสถานะการพิจารณาสัญญา
  A000062: 'A000062', // รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว
  A000063: 'A000063', // รายงานสถานะการพิจารณาข้อเสนอ
  A000064: 'A000064', // รายงานข้อมูลข้อเสนอที่ได้รับความเห็นชอบแล้ว
  A000065: 'A000065', // รายงานผลงานส่วนงานข้อเสนอ
  A000066: 'A000066', // รายงานปริมาณข้อมูล
  A000067: 'A000067', // การจัดการข้อมูลผู้ใช้
  A000068: 'A000068', // นำส่งข้อมูล
  A000069: 'A000069', // นำส่งข้อมูล Add New
  A000070: 'A000070', // นำส่งข้อมูล Edit
  A000071: 'A000071', // การพิจารณาข้อมูล
  A000072: 'A000072', // การพิจารณาข้อมูล Add New
  A000073: 'A000073', // การพิจารณาข้อมูล Detail
  A000074: 'A000074', // การพิจารณาข้อมูล Edit
  A000075: 'A000075', // นำส่งข้อมูล โดย ชท.
  A000076: 'A000076', // ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม
  A000077: 'A000077', // ตรวจสอบประวัติการเข้าใช้งานระบบ
  A000078: 'A000078', // ข้อมูลสกุลเงิน
  A000079: 'A000079', // ข้อมูลหน่วยนับ
  A000080: 'A000080', // ข้อมูลสิ่งอำนวยความสะดวก
  A000081: 'A000081', // ข้อมูลประเภทบริการ
  A000082: 'A000082', // ข้อมูลบริการ
  A000083: 'A000083', // สอบถามข้อมูลข้อเสนอ
  A000084: 'A000084', // สอบถามข้อมูลสัญญา
  A000085: 'A000085', // สอบถามข้อมูลสัญญา Detail
  A000086: 'A000086', // สอบถามข้อมูลข้อพิพาท
};
