import { Col, Form, Row, Table } from 'antd';

import useFetchServices from 'features/Offer/hooks/useFetchServices';
import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';

import { formLayout } from 'constants/form';

import { getColumns } from './columns';

const ServiceTable = () => {
  // NOTE: RISO, RDRO
  const { isDisabled, offerType } = useOfferDataContext();
  const {
    currentOfferServices,
    serviceTableFormRef,
    selectedMultipleServices,
    onSelectMultipleServices,
  } = useOfferServicesContext();
  const { servicesData, isLoadingServices } = useFetchServices({ offerType });

  const rowSelection = {
    onChange: onSelectMultipleServices,
    selectedRowKeys: selectedMultipleServices,
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form
          {...formLayout}
          labelWrap
          size="large"
          name="serviceTableForm"
          form={serviceTableFormRef}
          disabled={isDisabled}
        >
          <Table
            bordered
            size="small"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={getColumns({
              servicesData: servicesData ? servicesData : [],
              isLoadingServices,
            })}
            dataSource={currentOfferServices ? currentOfferServices : []}
            rowKey={(value) => value.key}
            pagination={false}
            scroll={{ x: true }}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default ServiceTable;
