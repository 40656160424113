import { OTHER_OPTION } from 'features/Offer/constant';

type getValueWithIdAndLabelProps = {
  id: number | string | undefined;
  label: string | undefined;
  desc: string | undefined;
};

export const getDataWithKey = (data: any) => {
  if (!data) return [];
  return data.map((item: any, index: number) => {
    return {
      ...item,
      key: index + 1,
    };
  });
};

export const getUndefinedIfZero = (value: any) => {
  if (value === 0) return undefined;
  return value;
};

export const getValueWithIdAndLabel = ({ id, label, desc }: getValueWithIdAndLabelProps) => {
  const _label = label === OTHER_OPTION ? desc : label;
  return `${id}<>${_label}`;
};

export const extractServId = (value: string | undefined) => {
  if (!value) return undefined;
  return value.split('<>')[0];
};
