import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import usePagination from 'hooks/usePagination';
import { useApi, TApiResponse } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import useAttachHistoryOffer from '../hook/useAttachHistoryOffer';

import { listReviewOffer } from 'api/review';
import { ListOfferReviewQuery } from 'api/review/interfaces';
import { listAttachHistory } from 'api/attachHistory';

import ModalHistory from 'components/ModalHistory/ModalHistory';
import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import SearchOffer from '../components/SearchOffer';
import OfferListTable from '../components/OfferListTable';

const ListOfferReview = () => {
  const { user } = useAuth();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const [queryParam, setQueryParam] = useState<ListOfferReviewQuery>({} as ListOfferReviewQuery);
  const { makeRequest: getListAttachHistoryRequest, data: attachHistory }: TApiResponse = useApi();

  const { error } = useDialogAlert();
  const { modalOpenHistory, onModalHistoryCancel } = useAttachHistoryOffer(attachHistory, error);
  const getReviewOfferList = useCallback(
    (params: ListOfferReviewQuery) => {
      makeRequest(() => listReviewOffer({ ...params }));
    },
    [makeRequest]
  );

  const getListAttachHistory = useCallback(
    (id: number | string) => {
      getListAttachHistoryRequest(() => listAttachHistory(id));
    },
    [getListAttachHistoryRequest]
  );
  const onModalOpenHistory = (id: number | string) => {
    getListAttachHistory(id);
  };

  useEffect(() => {
    if (user) {
      getReviewOfferList({
        ...queryParam,
        referenceNo: user?.referenceNo,
        pageSize,
        currentPageNumber,
      });
    }
  }, [pageSize, currentPageNumber, queryParam, getReviewOfferList, user]);

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle]);
  return (
    <div>
      <HeaderTitle>ค้นหาโดย</HeaderTitle>
      <SearchOffer
        onSearchOffer={(_values: any) => {
          setQueryParam(_values);
          setCurrentPageNumber(1);
        }}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <OfferListTable
        data={data}
        loading={loading}
        pageSize={pageSize}
        currentPageNumber={currentPageNumber}
        paginationSetting={paginationSetting(pageable?.totalItems)}
        onModalOpenHistory={onModalOpenHistory}
      />
      <ModalHistory
        modalOpenOffer={modalOpenHistory}
        onModalCancel={onModalHistoryCancel}
        title="ไฟล์จัดทำข้อเสนอ"
        width={700}
        attachHistory={attachHistory}
      />
    </div>
  );
};

export default ListOfferReview;
