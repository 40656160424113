import { getOfferById } from 'api/offer';
import { downloadOfferAttachmentFile } from 'api/offer/attachFiles';

import EditOfferWrapper from '../components/EditOfferWrapper';
import { OfferDataProvider } from '../contexts/OfferDataContext';
import { OfferServicesProvider } from '../contexts/OfferServicesContext';
import { OfferFacilititesProvider } from '../contexts/OfferFacilitiesContext';

const EditOffer = () => {
  return (
    <OfferDataProvider
      getOfferDataAPI={getOfferById}
      downloadOfferFileAPI={downloadOfferAttachmentFile}
    >
      <OfferServicesProvider>
        <OfferFacilititesProvider>
          <EditOfferWrapper />
        </OfferFacilititesProvider>
      </OfferServicesProvider>
    </OfferDataProvider>
  );
};

export default EditOffer;
