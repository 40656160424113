import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getOfferServes } from 'api/contract';

import { onFilterOption } from 'utils/select';
import { IContServesData } from 'features/Contract/interfaces';
import {
  getOfferServeLabelTextDropdown,
  setOfferServeTitleData,
} from 'features/Contract/utils/serviceDropdown';
import { getValueWithIdAndLabel } from 'features/Contract/utils';

type ContractOfferServesDropdownProps = {
  offerId: string | number | undefined;
  onChangeOption: (option: any) => void;
  label?: string;
  isRequired?: boolean;
};

const ContractOfferServesDropdown = ({
  offerId,
  onChangeOption,
  label,
  isRequired = true,
}: ContractOfferServesDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    if (offerId) makeRequest(() => getOfferServes(offerId));
  }, [makeRequest, offerId]);

  return (
    <>
      <Form.Item
        colon
        name="servId"
        label={label ? label : 'บริการ'}
        wrapperCol={{ span: 24 }}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'ประเภทบริการ'}` }]
            : []
        }
      >
        <Select
          labelInValue
          showSearch
          filterOption={onFilterOption}
          allowClear={isRequired ? false : true}
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          onChange={(_, options) => onChangeOption(options)}
          options={
            data
              ? data.map((item: IContServesData) => ({
                  value: getValueWithIdAndLabel({
                    id: item.servId,
                    label: item.servName,
                    desc: item.serviceDesc,
                  }),
                  label: getOfferServeLabelTextDropdown(item),
                  title: setOfferServeTitleData(item),
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ContractOfferServesDropdown;
