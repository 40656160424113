import { useEffect, useState } from 'react';
import { FormInstance } from 'antd';

import { OTHER_OPTION } from 'features/Offer/constant';
import { DropdownType } from 'features/Offer/interfaces';

type UseContServePayFormProps = {
  record: any;
  formRef: FormInstance<any>;
};

const useContServePayForm = ({ record, formRef }: UseContServePayFormProps) => {
  const [selectedUnit, setSelectedUnit] = useState<DropdownType | undefined>(undefined);
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownType | undefined>(undefined);
  const isDisabledOtherUnitDesc = selectedUnit?.label !== OTHER_OPTION;
  const isDisabledOtherCurrencyDesc = selectedCurrency?.label !== OTHER_OPTION;

  const onChangeCurrency = (option: DropdownType) => {
    setSelectedCurrency(option);
    if (option.label !== OTHER_OPTION) {
      formRef.resetFields(['currencyDesc']);
    }
  };

  const onChangeUnit = (option: DropdownType) => {
    setSelectedUnit(option);
    if (option.label !== OTHER_OPTION) {
      formRef.resetFields(['unitDesc']);
    }
  };

  useEffect(() => {
    if (record) {
      setSelectedUnit({ value: record.unitId, label: record.unitName });
      setSelectedCurrency({
        value: record.currencyId,
        label: record.currencyName,
      });
    }
  }, [record]);
  return { isDisabledOtherUnitDesc, isDisabledOtherCurrencyDesc, onChangeCurrency, onChangeUnit };
};

export default useContServePayForm;
