import { mainRoutePaths } from 'constants/routes';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import CookiesService from 'service/CookiesService';

export default function GuestGuard() {
  const { pathname } = useLocation();
  const rootPath = CookiesService.get('ROOT_PATH');
  let mode = '';
  try {
    mode = process.env.VITE_ELECTRON_MODE || '';
  } catch (error) {
    mode = '';
  }

  if (pathname === '/') {
    if (rootPath && rootPath !== '/') {
      return <Navigate to={rootPath} />;
    }
    return <Navigate to={mainRoutePaths.offerRAO} />;
  } else if (mode === 'kiosk') {
    return <Navigate to="/visitor-register" />;
  } else if (mode === 'information') {
    return <Navigate to="/visitor-information" />;
  } else {
    return <Outlet />;
  }
}
