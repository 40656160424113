import { FunctionComponent, createContext, ReactNode, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useDialogAlert from 'hooks/useDialogAlert';
import { useApi } from 'hooks/useApi';

import { getUserInfo, officerLogout } from 'api/authen';
import { UserInfoQuery } from 'api/authen/interfaces';

import { AuthContextType } from 'types/AuthContext';
import { IUserInfo } from 'types/Authorization';
import { LOCAL_ICBS_KEY } from 'constants/apis';
import { USER_ROLE_OPERATOR } from 'constants/roles';

import { makePersistable, removestore } from 'utils/localstore';
import { removeCookieCredentials } from 'utils/logout';

import CookiesService from 'service/CookiesService';
import { getUserLandingPage, getUserPerms } from 'utils/userPerms';

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FunctionComponent<AuthProviderProps> = (props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { error: errorModal } = useDialogAlert();
  const { makeRequest } = useApi();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [user, setUser] = useState<IUserInfo | null>(null);

  const saveAuth = (userInfo: IUserInfo) => {
    const userPerms = getUserPerms(userInfo?.roles);
    const newUserInfo = { ...userInfo, permissions: userPerms };
    makePersistable(LOCAL_ICBS_KEY, JSON.stringify(newUserInfo));
    setUser(newUserInfo);
    setIsAuthenticated(true);
    setIsAuthenticating(false);
    const landingPage = getUserLandingPage(userInfo?.roles);
    CookiesService.set('ROOT_PATH', landingPage);
    CookiesService.set('CURRENT_USER', userInfo?.userName);
    CookiesService.set('REFERENCE_NO', userInfo?.referenceNo);
    CookiesService.set('USER_ROLE', userInfo?.userRole);
  };

  const getAuth = useCallback(async () => {
    const userInfoQuery: UserInfoQuery = {};
    const userRole = CookiesService.get('USER_ROLE');
    if (userRole) {
      if (userRole === USER_ROLE_OPERATOR) {
        userInfoQuery.referenceNo = CookiesService.get('REFERENCE_NO');
      } else {
        userInfoQuery.userLogin = CookiesService.get('CURRENT_USER');
      }
      const userInfo = await makeRequest(() => getUserInfo(userInfoQuery));
      if (userInfo?.data) {
        const _userInfo = userInfo?.data?.data;
        const userPerms = getUserPerms(_userInfo?.roles);
        setUser({ ..._userInfo, permissions: userPerms });
        setIsAuthenticated(true);
        setIsAuthenticating(false);
        // [31 May 2023] May's NOTE:
        // Comment out navigate to prevent redirect infinite loop when no path or perms is allowed
        // navigate({
        //   pathname: location.pathname,
        //   search: location.search,
        // });
      } else {
        setIsAuthenticated(false);
        setIsAuthenticating(false);
        removestore(LOCAL_ICBS_KEY);
      }
    } else {
      setIsAuthenticated(false);
      setIsAuthenticating(false);
      removestore(LOCAL_ICBS_KEY);
    }
  }, [makeRequest]);

  const logout = useCallback(
    async (params?: { isThrowError: false }) => {
      try {
        await makeRequest(() => officerLogout(), { isThrowError: true });
        setIsAuthenticated(false);
        removestore(LOCAL_ICBS_KEY);
        removeCookieCredentials();
        navigate('/login', {
          state: { from: pathname },
        });
      } catch (e) {
        console.error(e);
        if (params?.isThrowError) throw e;
        errorModal({
          header: 'ไม่สามารถออกจากระบบได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [navigate, pathname, errorModal, makeRequest]
  );

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        setIsAuthenticating,
        isAuthenticated,
        isAuthenticating,
        user: user || null,
        logout,
        saveAuth,
        getAuth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
