import { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form, Button, Spin } from 'antd';
import { SaveOutlined } from '@ant-design/icons';

import { StepsDataType } from 'features/OfferConsideration/interfaces';
import { stateCode } from 'constants/stateCode';

import StepFormWithDate from '../StepsForm/StepFormWithDate';
import StepFormWithStatus from '../StepsForm/StepFormWithStatus';
import StepFormWithApproveFile from '../StepsForm/StepFormWithApproveFile';
import StepFormWithLink from '../StepsForm/StepFormWithLink';
import StepFormWithEmailFile from '../StepsForm/StepFormWithEmailFile';

import buttonCSS from 'styles/components/button.module.css';

type StepsModalProps = {
  offerData: any;
  isModalOpen: boolean;
  isLoadingUpdateState: boolean;
  stepModalIndex: number | undefined;
  stepData: StepsDataType | undefined;
  onUpdateOfferState: (data: any, api?: any) => void;
  handleCancel: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  onRefetchOfferData: () => void;
};

const DISABLE_SUBMIT_BUTTON = [
  stateCode.step1,
  stateCode.step2,
  // stateCode.step6,
  // stateCode.step12,
  stateCode.step18,
];

const StepsModal = ({
  isModalOpen,
  isLoadingUpdateState,
  handleCancel,
  stepModalIndex,
  stepData,
  onUpdateOfferState,
  offerData,
  onRefetchOfferData,
}: StepsModalProps) => {
  const [form] = Form.useForm();
  const [refTrigger, setRefTrigger] = useState(0);

  const isDisable = useMemo(() => {
    if (!(stepData?.isCompleteState === false && stepData?.isCurrentState === true)) return true;
    else return false;
  }, [stepData]);

  const isHideSubmitButton = useMemo(() => {
    if (stepModalIndex !== undefined) {
      if (isDisable || DISABLE_SUBMIT_BUTTON.includes(stepModalIndex)) return true;
    }
    return false;
  }, [isDisable, stepModalIndex]);

  const getStepForm = useCallback(() => {
    switch (stepModalIndex) {
      case stateCode.step3:
      case stateCode.step7:
      case stateCode.step10:
        return (
          <StepFormWithDate
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step17:
        return (
          <StepFormWithDate
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            dateLabel="วันที่ตรวจสอบ"
          />
        );
      case stateCode.step1:
      case stateCode.step2:
      case stateCode.step18:
        return (
          <StepFormWithDate
            form={form}
            stepData={stepData}
            isDisable={true}
            isHideDateForm={true}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step4:
        return (
          <StepFormWithStatus
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step5:
      case stateCode.step11:
      case stateCode.step15:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            isHideApproveForm={true}
            dateLabel="วันที่หนังสือ"
          />
        );
      case stateCode.step8:
      case stateCode.step13:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            isHideApproveForm={true}
            dateLabel="วันที่บันทึก"
          />
        );
      case stateCode.step9:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            isShowActionDropdown={true}
          />
        );
      case stateCode.step14:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step16:
        return (
          <StepFormWithLink
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            offerData={offerData}
            onRefetchOfferData={onRefetchOfferData}
          />
        );
      case stateCode.step6:
      case stateCode.step12:
        return (
          <StepFormWithEmailFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            refTrigger={refTrigger}
          />
        );
      default:
        break;
    }
  }, [
    stepModalIndex,
    form,
    stepData,
    isDisable,
    onUpdateOfferState,
    offerData,
    onRefetchOfferData,
    refTrigger,
  ]);

  useEffect(() => {
    if (isModalOpen) setRefTrigger((prev) => prev + 1);
  }, [isModalOpen]);

  return (
    <div>
      <Modal
        title="การพิจารณาข้อเสนอ"
        open={isModalOpen}
        onCancel={handleCancel}
        width={'100vh'}
        footer={
          !isHideSubmitButton && (
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SaveOutlined />}
              size="large"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              ยืนยันการพิจารณา
            </Button>
          )
        }
      >
        <Spin tip="กำลังดำเนินการ..." spinning={isLoadingUpdateState}>
          {getStepForm()}
        </Spin>
      </Modal>
    </div>
  );
};

export default StepsModal;
