import axiosInstance from 'utils/axios';
import { FormState } from 'types/state';
import { ListReviewContractQuery } from './interface';

// NOTE: LIST
export const getContractReviewList = (query: ListReviewContractQuery) => {
  return axiosInstance.get(`/contract/review`, {
    params: query,
  });
};

export const listContractReviewAction = (stateCode: number) => {
  return axiosInstance.get(`/contract/review/action-state/${stateCode}`);
};

export const listStateFileByFormStateId = (stateId: number) => {
  return axiosInstance.get(`/contract/review/state/attachment/${stateId}`);
};

// NOTE: PUT
export const updateContractState = (id: number | string, data: FormState) => {
  return axiosInstance.put(`/contract/review/${id}/state`, data);
};

// NOTE: File
export const uploadStateFile = (stateId: number, formData: any) => {
  return axiosInstance.post(`/contract/review/state/${stateId}/attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadStateFile = (attachFileId: string | number) => {
  return axiosInstance.get(`/contract/review/state/attachment/${attachFileId}/download`, {
    responseType: 'blob', // This tells Axios to return the response as a blob object
  });
};

export const deleteStateFile = (attachFileId: string | number) => {
  return axiosInstance.delete(`/contract/review/state/attachment/${attachFileId}/delete`);
};
