import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DownloadOutlined } from '@ant-design/icons';

import { FileListDataType } from 'features/Contract/interfaces';

type FileTableColumnProps = {
  onDownloadRecordFile: Function;
  isCurrentContract: boolean;
};

export const getFileTableColumn = ({
  onDownloadRecordFile,
  isCurrentContract,
}: FileTableColumnProps) => {
  const columns: ColumnsType<FileListDataType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'กลุ่มเอกสาร',
      dataIndex: 'docName',
      key: 'docName',
      width: 350,
      render: (value, record) => (
        <>
          <span style={{ color: 'red' }}>{record.required ? '*' : ''}</span>
          {value}
        </>
      ),
    },
    {
      title: 'เลขที่/วันที่สัญญา',
      dataIndex: 'attachFileName',
      key: 'attachFileName',
    },
    {
      title: 'ดาวน์โหลดเอกสาร',
      key: 'download',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Button
          disabled={!record.attachFileId}
          icon={<DownloadOutlined />}
          onClick={() => {
            onDownloadRecordFile(record, isCurrentContract);
          }}
        />
      ),
    },
  ];
  return columns;
};
