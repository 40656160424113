import { useEffect } from 'react';
import { Col, Divider, Form, FormInstance, Input, Row } from 'antd';

import useUploadEmailFile from './hooks/useUploadEmailFile';
import useDialogAlert from 'hooks/useDialogAlert';

import UploadEmailFile from './components/UploadEmailFile';

import { formLayout } from 'constants/form';
import { IStateData } from 'features/Contract/interfaces';
import { dayJsformatDateObjToString } from 'utils/date';

type StepFormWithEmailFileProps = {
  form: FormInstance<any>;
  stepData: IStateData | undefined;
  isDisable: boolean;
  refTrigger: number;
  onUpdateOfferState: Function;
};

const StepFormWithEmailFile = ({
  form,
  stepData,
  isDisable,
  refTrigger,
  onUpdateOfferState,
}: StepFormWithEmailFileProps) => {
  const { error: errorModal } = useDialogAlert();
  const {
    uploadFormRef,
    onChangeDocType,
    isOpenFileDialog,
    onUploadFile,
    setCurrentUploadFile,
    onValidateDocType,
    fileList,
    isUploadingFile,
    isDeletingFile,
    isLoadingFileList,
    onRemoveRecordFile,
    onDownloadRecordFile,
    onValidateAtleastOneFile,
  } = useUploadEmailFile({
    stateId: stepData?.formStateId,
    refTrigger,
  });
  const onFinish = async () => {
    try {
      await onValidateAtleastOneFile();

      let _actionDate = new Date();

      let data: any = {
        formStateId: stepData?.formStateId,
        stateName: stepData?.stateName,
        stateCode: stepData?.stateCode,
        actionCode: '',
        actionName: '',
        actionDate: dayJsformatDateObjToString(_actionDate),
        isCompleteState: false,
        isCurrentState: true,
      };

      onUpdateOfferState(data);
    } catch (e: any) {
      errorModal({
        header: e.message || 'เกิดข้อผิดพลาดขึ้น (error: #8243)',
        sub: e.message ? '' : 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  useEffect(() => {
    if (stepData) {
      form.setFieldsValue({
        stateName: stepData.stateName,
      });
    }
  }, [form, stepData]);

  return (
    <>
      <Form
        {...formLayout}
        labelWrap
        labelAlign="left"
        form={form}
        name="stepModalForm"
        onFinish={onFinish}
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item colon label="สถานะสัญญา" name="stateName">
              <Input size="large" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider orientation="left" orientationMargin="0">
        อัพโหลดไฟล์
      </Divider>
      <UploadEmailFile
        uploadFormRef={uploadFormRef}
        isDisable={isDisable}
        form={form}
        onChangeDocType={onChangeDocType}
        isOpenFileDialog={isOpenFileDialog}
        onUploadFile={onUploadFile}
        setCurrentUploadFile={setCurrentUploadFile}
        onValidateDocType={onValidateDocType}
        fileList={fileList}
        isUploadingFile={isUploadingFile}
        isDeletingFile={isDeletingFile}
        isLoadingFileList={isLoadingFileList}
        onRemoveRecordFile={onRemoveRecordFile}
        onDownloadRecordFile={onDownloadRecordFile}
      />
    </>
  );
};

export default StepFormWithEmailFile;
