import { Button, UploadFile } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons';

import UploadButton from 'features/Contract/components/UploadButton';
import { FileListDataType } from 'features/Contract/interfaces';

type FileTableColumnProps = {
  isDisabled: boolean;
  onRemoveRecordFile: Function;
  onDownloadRecordFile: (record: any) => void;
  onTableUploadFile: Function;
  offerId: number | string | undefined;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any> | undefined>>;
};

export const getFileTableColumn = ({
  isDisabled,
  onRemoveRecordFile,
  onDownloadRecordFile,
  onTableUploadFile,
  offerId,
  setCurrentUploadFile,
}: FileTableColumnProps) => {
  const columns: ColumnsType<FileListDataType> = [
    {
      title: 'ดาวน์โหลดเอกสาร',
      key: 'download',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Button
          disabled={!record.attachFileId}
          icon={<DownloadOutlined />}
          onClick={() => {
            onDownloadRecordFile(record);
          }}
        />
      ),
    },
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'กลุ่มเอกสาร',
      dataIndex: 'docName',
      key: 'docName',
      width: 350,
      render: (value, record) => (
        <>
          <span style={{ color: 'red' }}>{record.required ? '*' : ''}</span>
          {value}
        </>
      ),
    },
    {
      title: 'รายการเอกสาร',
      dataIndex: 'attachFileName',
      key: 'attachFileName',
    },

    {
      title: 'อัพโหลด/ลบ',
      key: 'delete',
      align: 'center',
      width: 50,
      render: (_, record) => {
        if (!record.attachFileId)
          return (
            <UploadButton
              handleUpload={() => onTableUploadFile(record, offerId)}
              setCurrentUploadFile={setCurrentUploadFile}
              isOpenFileDialog={true}
              isDisabled={isDisabled}
            >
              <Button icon={<UploadOutlined />} disabled={isDisabled} />
            </UploadButton>
          );
        else
          return (
            <>
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                disabled={isDisabled}
                onClick={() => {
                  onRemoveRecordFile(record);
                }}
              />
            </>
          );
      },
    },
  ];
  return columns;
};
