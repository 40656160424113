import axiosInstance from 'utils/axios';

type DownloadOfferHistoryAttachmentFileProps = {
  offerId: string | number;
  offerType: string;
  attachFileId: string | number;
};

export const getOfferHistoryById = (id: string | number) => {
  return axiosInstance.get(`hist/previous-offer/${id}`);
};

export const downloadOfferHistoryAttachmentFile = ({
  offerId,
  offerType,
  attachFileId,
}: DownloadOfferHistoryAttachmentFileProps) => {
  return axiosInstance.get(
    `hist/previous-offer/${offerId}/attachFile/${attachFileId}/docType/${offerType}/download`,
    {
      responseType: 'blob',
    }
  );
};
