import { Space } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';

interface IStepWithDate extends IStepDescription {
  label?: string;
}

const StepWithDate = ({ stepData, label }: IStepWithDate) => {
  return (
    <div>
      <Space>
        <label>{label ? label : 'วันที่บันทึก'} :</label>
        <span>
          {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
        </span>
      </Space>
    </div>
  );
};

export default StepWithDate;
