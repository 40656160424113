import { Key, useEffect, useMemo, useState } from 'react';

import { IOfferServes } from '../interfaces';

type useManageServicesProps = {
  offerServes: IOfferServes[];
};

const useManageServices = ({ offerServes }: useManageServicesProps) => {
  const [currentOfferServices, setCurrentOfferServices] = useState<IOfferServes[]>([]);
  const [selectedMultipleServices, setSelectedMultipleServices] = useState<Key[]>([]);
  const [selectedMultipleRecords, setSelectedMultipleRecords] = useState<IOfferServes[]>([]);

  const currentOfferServicesWithKey = useMemo(() => {
    if (!offerServes) return [];
    return offerServes?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      };
    });
  }, [offerServes]);

  const onDeleteServices = () => {
    const newOfferServices = currentOfferServices.filter(
      (item) => !selectedMultipleServices.includes(item.key)
    );
    setCurrentOfferServices(newOfferServices);
    setSelectedMultipleServices([]);
  };

  const onSelectMultipleServices = (selectedRowKeys: Key[], selectedRows: any[]) => {
    setSelectedMultipleServices(selectedRowKeys);
    setSelectedMultipleRecords(selectedRows);
  };

  useEffect(() => {
    setCurrentOfferServices(currentOfferServicesWithKey);
  }, [currentOfferServicesWithKey]);

  return {
    currentOfferServices,
    selectedMultipleServices,
    selectedMultipleRecords,
    setCurrentOfferServices,
    onSelectMultipleServices,
    onDeleteServices,
  };
};

export default useManageServices;
