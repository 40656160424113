import useDialogAlert from 'hooks/useDialogAlert';

import { OTHER_OPTION } from '../constant';
import { IOfferServes, IServicesData } from '../interfaces';
import { Form } from 'antd';

type UseManageServicesRISOProps = {
  currentOfferServices: IOfferServes[];
  setCurrentOfferServices: React.Dispatch<React.SetStateAction<IOfferServes[]>>;
};

export type OnChangeServiceParams = {
  selectedOption: IServicesData;
  currentRecord: IOfferServes;
};

export type OnChangeOtherInputParams = {
  inputValue: string;
  currentRecord: IOfferServes;
};

type OnCheckDuplicateServiceParams = {
  raoServId: number;
  raoServName: string;
};

const useManageServicesRISO = ({
  currentOfferServices,
  setCurrentOfferServices,
}: UseManageServicesRISOProps) => {
  const { error } = useDialogAlert();
  const [serviceTableFormRef] = Form.useForm();

  const onCheckDuplicateService = ({ raoServId, raoServName }: OnCheckDuplicateServiceParams) => {
    if (raoServName === OTHER_OPTION) return;
    const isDuplicate = currentOfferServices.some(
      (item) => String(item.raoServId) === String(raoServId)
    );
    return isDuplicate;
  };

  const onResetValue = ({ currentRecord }: { currentRecord: IOfferServes }) => {
    let newCurrentOfferServices = [...currentOfferServices];
    newCurrentOfferServices = newCurrentOfferServices.map((item) => {
      if (item.key === currentRecord.key) {
        if (item.raoServName !== OTHER_OPTION)
          return {
            ...item,
            raoServId: null,
            raoServName: '',
            raoServiceDesc: '',
          };
      }
      return item;
    });

    setCurrentOfferServices(newCurrentOfferServices);
  };

  const onChangeService = ({ selectedOption, currentRecord }: OnChangeServiceParams) => {
    try {
      const isDuplicate = onCheckDuplicateService({
        raoServId: selectedOption.raoServId,
        raoServName: selectedOption.raoServName,
      });

      if (isDuplicate) {
        throw new Error('ไม่สามารถเลือกบริการซ้ำกันได้');
      }
    } catch (e: any) {
      return error(
        {
          header: e.message || 'ไม่สามารถเลือกบริการซ้ำกันได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        },
        () => onResetValue({ currentRecord })
      );
    }

    const newOfferServices = currentOfferServices.map((item) => {
      if (item.key === currentRecord.key) {
        return {
          key: item.key,
          serviceId: item.serviceId,
          raoServId: selectedOption.raoServId,
          raoServName: selectedOption.raoServName,
          raoServiceDesc: selectedOption.raoServDesc,
        };
      }
      return item;
    });
    setCurrentOfferServices(newOfferServices);
  };

  const onChangeOtherInput = ({ inputValue, currentRecord }: OnChangeOtherInputParams) => {
    const newOfferServices = currentOfferServices.map((item) => {
      if (item.key === currentRecord.key) {
        return {
          ...item,
          raoServiceDesc: inputValue,
        };
      }
      return item;
    });
    setCurrentOfferServices(newOfferServices);
  };

  const onAddNewService = () => {
    const newOfferServices = [...currentOfferServices];
    newOfferServices.push({
      key: currentOfferServices.length + 1,
      serviceId: 0,
      raoServId: null,
      raoServName: '',
      raoServiceDesc: '',
    });
    setCurrentOfferServices(newOfferServices);
  };

  return {
    serviceTableFormRef,
    onChangeService,
    onChangeOtherInput,
    onAddNewService,
    onResetValue,
  };
};

export default useManageServicesRISO;
