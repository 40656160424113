import useDialogAlert from 'hooks/useDialogAlert';
import useValidation from './useValidation';

import { OnChangeServiceParams } from './useManageServicesRISO';

import { IOfferFacility, IOfferServes, IServicesData } from '../interfaces';

type UseManageServicesRDROProps = {
  onChangeService: ({ selectedOption, currentRecord }: OnChangeServiceParams) => void;
};

const useManageServicesRDRO = ({ onChangeService }: UseManageServicesRDROProps) => {
  const { error } = useDialogAlert();
  const { onCheckExistingServiceInFacilites } = useValidation();

  const onChangeRDROService = ({
    selectedOption,
    currentRecord,
    currentOfferFacilities,
  }: {
    selectedOption: IServicesData;
    currentRecord: IOfferServes;
    currentOfferFacilities: IOfferFacility[];
  }) => {
    try {
      onCheckExistingServiceInFacilites({ service: currentRecord, currentOfferFacilities });
      onChangeService({ selectedOption, currentRecord });
    } catch (e: any) {
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: 'กรุณายกเลิกบริการที่บันทึกไว้กับสิ่งอำนวยความสะดวกก่อน',
      });
    }
  };

  return { onChangeRDROService };
};

export default useManageServicesRDRO;
