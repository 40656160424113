import { useCallback } from 'react';
import { Table, UploadFile } from 'antd';

import usePagination from 'hooks/usePagination';
import { uploadStateFile, downloadStateFile, deleteStateFile } from 'api/contract/state';

import { FileListDataType } from 'features/Contract/interfaces';
import { getFileTableColumn } from './columns';

type UploadedFileListTableProps = {
  isLoading: boolean;
  isDisabled: boolean;
  onRemoveFile: Function;
  downloadFile: Function;
  fileList: FileListDataType[];
  totalItems: number;
  handleUpload: Function;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any> | undefined>>;
  stateId: number | undefined;
};

const UploadedFileListTable = ({
  isLoading,
  isDisabled,
  onRemoveFile,
  downloadFile,
  fileList,
  totalItems,
  handleUpload,
  setCurrentUploadFile,
  stateId,
}: UploadedFileListTableProps) => {
  const { currentPageNumber, pageSize, paginationSetting } = usePagination();

  const onUploadFile = useCallback(
    async (record: any) => {
      await handleUpload({
        docId: record.docId,
        docName: record.docName,
        required: true,
        api: uploadStateFile,
        id: stateId,
      });
    },
    [stateId, handleUpload]
  );

  const onRemoveRecordFile = useCallback(
    (record: any) => {
      onRemoveFile({ fileId: record.attachFileId, api: deleteStateFile });
    },
    [onRemoveFile]
  );

  const onDownloadRecordFile = useCallback(
    (record: any) => {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: downloadStateFile,
      });
    },
    [downloadFile]
  );

  return (
    <>
      <Table
        bordered
        size="small"
        loading={isLoading}
        columns={getFileTableColumn({
          isDisabled,
          onRemoveRecordFile,
          onDownloadRecordFile,
          currentPageNumber,
          pageSize,
          onUploadFile,
          setCurrentUploadFile,
        })}
        dataSource={fileList}
        rowKey={'index'}
        pagination={paginationSetting(totalItems)}
      />
    </>
  );
};

export default UploadedFileListTable;
