import React, { createContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, FormInstance } from 'antd';

import useNewOfferPage from '../hooks/useNewOfferPage';
import usePageHeaderText from '../hooks/usePageHeaderText';
import useRequestOffer, { OnConfirmOfferParams, OnSaveOfferParams } from '../hooks/useRequestOffer';

import useConditionConfig from '../hooks/useConditionConfig';

import { OFFER_EDIT_TAB, OFFER_REVIEW_TAB } from '../constant';
import { IOfferData } from '../interfaces';

type OfferDataContextType = {
  isNewPage: boolean;
  isDisabledAttachmentTab: boolean;
  isLoadingOfferData: boolean;
  isDisabled: boolean;
  isShowImproveOfferButton: boolean;
  isHistoryPage: boolean;
  isLoadingOperatorInfo: boolean;
  isDisabledUploadFile: boolean;
  isHideSaveOfferButton: boolean;
  isHideConfirmOfferButton: boolean;
  isHideConfirmDocButton: boolean;
  isConfirmingOffer: boolean;
  offerType: string;
  editOfferFormRef: FormInstance<any>;
  offerData: IOfferData;
  activeTab: string;
  offerId: string | undefined;
  offerNoFormRef: FormInstance<any>;
  attachmentOperFormRef: FormInstance<any>;
  downloadOfferFileAPI: Function;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  onSaveOffer: (data: OnSaveOfferParams) => void;
  onConfirmOffer: (data: OnConfirmOfferParams) => void;
  onGetOfferById: (offerId: string | number | undefined) => void;
  setOfferData: React.Dispatch<React.SetStateAction<IOfferData>>;
  onImproveOfferFromExistingOne: () => void;
};

const OfferDataContext = createContext<OfferDataContextType>({} as OfferDataContextType);

type OfferDataProviderProps = {
  children: React.ReactNode;
  getOfferDataAPI: (offerId: string | number) => Promise<any>;
  downloadOfferFileAPI: Function;
  isHistoryPage?: boolean;
  isReviewPage?: boolean;
};

const OfferDataProvider = ({
  getOfferDataAPI,
  downloadOfferFileAPI,
  children,
  isHistoryPage = false,
  isReviewPage = false,
}: OfferDataProviderProps) => {
  const { offerId } = useParams<{ offerId: string }>();
  const [activeTab, setActiveTab] = useState<string>(
    isReviewPage ? OFFER_REVIEW_TAB : OFFER_EDIT_TAB
  );
  const [offerNoFormRef] = Form.useForm();
  const [editOfferFormRef] = Form.useForm();
  const [attachmentOperFormRef] = Form.useForm();
  const {
    offerData,
    isLoadingOfferData,
    isConfirmingOffer,
    onSaveOffer,
    onConfirmOffer,
    onImproveOfferFromExistingOne,
    onGetOfferById,
    setOfferData,
  } = useRequestOffer({
    offerId,
    getOfferDataAPI,
  });
  const {
    isDisabled,
    isShowImproveOfferButton,
    isNewPage,
    isDisabledAttachmentTab,
    isDisabledUploadFile,
    isHideSaveOfferButton,
    isHideConfirmOfferButton,
    isHideConfirmDocButton,
  } = useConditionConfig({
    offerFormStatus: String(offerData?.offerFormStatus),
    isHistoryPage,
    isReviewPage,
  });
  const { isLoadingOperatorInfo } = useNewOfferPage({
    isNewPage,
    setOfferData,
  });
  usePageHeaderText({ offerType: offerData?.offerFormType, isNewPage });

  return (
    <OfferDataContext.Provider
      value={{
        offerType: offerData?.offerFormType,
        isNewPage,
        isDisabledAttachmentTab,
        editOfferFormRef,
        offerData,
        isLoadingOfferData,
        isConfirmingOffer,
        activeTab,
        setActiveTab,
        isDisabled,
        offerId,
        onSaveOffer,
        offerNoFormRef,
        isShowImproveOfferButton,
        onConfirmOffer,
        onImproveOfferFromExistingOne,
        attachmentOperFormRef,
        onGetOfferById,
        isDisabledUploadFile,
        setOfferData,
        isLoadingOperatorInfo,
        downloadOfferFileAPI,
        isHistoryPage,
        isHideSaveOfferButton,
        isHideConfirmOfferButton,
        isHideConfirmDocButton,
      }}
    >
      {children}
    </OfferDataContext.Provider>
  );
};

export { OfferDataProvider, OfferDataContext };
