import { FunctionComponent, createContext, ReactNode, useState } from 'react';
interface DataAllProjectContextType {
  lestRoa: ILestRoaData | undefined;
  setLestRoa: (is: ILestRoaData) => void;
  operators: IOperators | undefined;
  setOperators: (is: IOperators | undefined) => void;
}
export interface IOperators {
  data: IOperatorsData;
  message: string;
  success: boolean;
}
export interface IOperatorsData {
  operatorsId: number;
  operName: string;
  taxId: string;
  contactType: string;
  conName: string;
  conTel: string;
  conFax: string;
  conMail: string;
  conNo: string;
  conMoo: string;
  conSoi: string;
  conRoad: string;
  conProvinceId: number;
  conProvinceName: string;
  conDistrictId: number;
  conDistrictName: string;
  conSubDistrictId: number;
  conSubDistrictName: string;
  conPost: string;
}
export interface ILestRoa {
  offerFormId: number;
  offerFormNo: string;
  offerFormDate: string;
  offerFormGroup: string;
  offerFormPublicUrl: string;
  offerFormStatusName: string;
  numOfEdit: number;
}
export interface ILestRoaPageable {
  offerFormId: number;
  offerFormNo: string;
  offerFormDate: string;
  offerFormGroup: string;
  offerFormPublicUrl: string;
  offerFormStatusName: string;
  numOfEdit: number;
  offerFormStatus?: string;
}
export interface IRAOPageable {
  currentPageNumber: number;
  numberOfItems: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}
export interface ILestRoaData {
  data: ILestRoa[];
  message: string;
  pageable: IRAOPageable;
  success: boolean;
}
const DataAllProjectContext = createContext<DataAllProjectContextType | null>(null);

interface DataAllProjectProviderProps {
  children: ReactNode;
}

const DataAllProjectProvider: FunctionComponent<DataAllProjectProviderProps> = (props) => {
  const [lestRoa, setLestRoa] = useState<ILestRoaData | undefined>();
  const [operators, setOperators] = useState<IOperators | undefined>();
  return (
    <DataAllProjectContext.Provider
      value={{
        lestRoa,
        setLestRoa,
        operators,
        setOperators,
      }}
    >
      {props.children}
    </DataAllProjectContext.Provider>
  );
};

export { DataAllProjectProvider, DataAllProjectContext };
