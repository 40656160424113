import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractOfficer } from 'api/contract';
import { getName } from 'utils/string';

const ContractOfficerDropdown = () => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listContractOfficer());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        label="ผู้ได้รับมอบหมาย"
        name="respUserId"
        // rules={[{ required: true, message: 'กรุณาเลือกผู้ได้รับมอบหมาย' }]}
      >
        <Select
          allowClear
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          options={
            data
              ? data.map((item: any) => ({
                  value: item.officerId,
                  label: getName(item),
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ContractOfficerDropdown;
