import React from 'react';

import useReport from './hooks/useReport';
import { FormInstance } from 'antd';

export interface SearchReportProps {
  onSearchReport: Function;
  onReset: Function;
  formReport: FormInstance<any>;
  reportType: string;
}

const SearchReport = ({ formReport, onReset, onSearchReport, reportType }: SearchReportProps) => {
  const { report } = useReport({ formReport, onReset, onSearchReport, reportType });

  return <div className='content-box'>{report()}</div>;
};

export default SearchReport;
