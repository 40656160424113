import { NEW_PAGE, EDIT_PAGE, DETAIL_PAGE, HISTORY_PAGE, INQUIRY_PAGE } from 'constants/page';
import { mainRoutePaths, subRoutePaths } from 'constants/routes';
import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RISO,
} from 'constants/offerFormType';
import {
  CONTRACT_USAGE,
  CONTRACT_CONNECT,
  CONTRACT_NETWORK,
  CONTRACT_INFRASTRUCTURE,
} from '../constant';

export const getOfferFormType = (contractType: string) => {
  switch (contractType) {
    case CONTRACT_USAGE:
      return OFFER_TYPE_RAO;
    case CONTRACT_CONNECT:
      return OFFER_TYPE_RIO;
    case CONTRACT_NETWORK:
      return OFFER_TYPE_RDRO;
    case CONTRACT_INFRASTRUCTURE:
      return OFFER_TYPE_RISO;
    default:
      return '';
  }
};

export const getContractType = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RAO:
      return CONTRACT_USAGE;
    case OFFER_TYPE_RIO:
      return CONTRACT_CONNECT;
    case OFFER_TYPE_RDRO:
      return CONTRACT_NETWORK;
    case OFFER_TYPE_RISO:
      return CONTRACT_INFRASTRUCTURE;
    default:
      return '';
  }
};

export const getEditContractPath = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RAO:
      return subRoutePaths.contractUsageEdit;
    case OFFER_TYPE_RIO:
      return subRoutePaths.contractConnectEdit;
    case OFFER_TYPE_RDRO:
      return subRoutePaths.contractNetworkEdit;
    case OFFER_TYPE_RISO:
      return subRoutePaths.contractInfraEdit;
    default:
      return () => '';
  }
};

export const getHistoryContractPath = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RAO:
      return subRoutePaths.contractUsageHistory;
    case OFFER_TYPE_RIO:
      return subRoutePaths.contractConnectHistory;
    case OFFER_TYPE_RDRO:
      return subRoutePaths.contractNetworkHistory;
    case OFFER_TYPE_RISO:
      return subRoutePaths.contractInfraHistory;
    default:
      return () => '';
  }
};

export const getNewContractPath = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RAO:
      return subRoutePaths.contractUsageNew;
    case OFFER_TYPE_RIO:
      return subRoutePaths.contractConnectNew;
    case OFFER_TYPE_RDRO:
      return subRoutePaths.contractNetworkNew;
    case OFFER_TYPE_RISO:
      return subRoutePaths.contractInfraNew;
    default:
      return '';
  }
};

export const getIndexContractPath = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RAO:
      return mainRoutePaths.contractUsage;
    case OFFER_TYPE_RIO:
      return mainRoutePaths.contractConnect;
    case OFFER_TYPE_RDRO:
      return mainRoutePaths.contractNetwork;
    case OFFER_TYPE_RISO:
      return mainRoutePaths.contractInfra;
    default:
      return '';
  }
};

export const getPageFunction = (pathname: string) => {
  const paths = pathname.split('/');
  if (paths.includes('edit')) return EDIT_PAGE;
  if (paths.includes('new')) return NEW_PAGE;
  if (paths.includes('detail')) return DETAIL_PAGE;
  if (paths.includes('history')) return HISTORY_PAGE;
  if (paths.includes('inquiry')) return INQUIRY_PAGE;
  return undefined;
};

export const getContractNameFromOfferFormType = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RAO:
      return 'สัญญาการใช้ฯ';
    case OFFER_TYPE_RIO:
      return 'สัญญาการเชื่อมต่อฯ';
    case OFFER_TYPE_RDRO:
      return 'สัญญาการใช้บริการข้ามโครงข่ายฯ';
    case OFFER_TYPE_RISO:
      return 'สัญญาการใช้โครงสร้างพื้นฐาน';
    default:
      return '';
  }
};
