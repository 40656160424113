import { AuditOutlined, FileOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { getInquiryPathFromOfferType } from 'features/Offer/utils';
import { NavigateFunction } from 'react-router-dom';
import { dayJsformatDateToBCYear } from 'utils/date';

export const tableColumns = (
  navigator: NavigateFunction,
  page: number,
  pageSize: number,
  onModalOpenHistory: (id: number | string) => void,
  user: string[] | undefined,
  pathname: string
) => {
  const columns: ColumnsType<ILestOfferItem> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'ประเภทข้อเสนอ',
      dataIndex: 'offerFormType',
      key: 'offerFormType',
      align: 'center',
      width: 100,
    },
    {
      title: 'กลุ่มข้อเสนอ',
      dataIndex: 'offerFormGroup',
      key: 'offerFormGroupName',
      align: 'center',
      width: 100,
    },
    {
      title: 'ผู้ประกอบการ',
      dataIndex: 'operName',
      key: 'operName',
      align: 'center',
      width: 100,
    },
    {
      title: 'เลขที่/วันที่หนังสือผู้ประกอบการ',
      key: 'offerFormNo',
      align: 'center',
      width: 100,
      render: (_, record) => {
        const offerFormNo = record.offerFormNo;
        const offerFormDate = dayJsformatDateToBCYear(record.offerFormDate);
        return (
          <>
            {offerFormNo} {offerFormDate ? '/' + offerFormDate : ''}
          </>
        );
      },
    },
    {
      title: 'สถานะการจัดทำข้อเสนอ',
      dataIndex: 'offerFormStatusName',
      key: 'offerFormStatusName',
      align: 'center',
      width: 100,
    },

    {
      title: 'จำนวนครั้งที่แก้ไข',
      dataIndex: 'numOfImprove',
      key: 'numOfImprove',
      align: 'center',
      width: 100,
    },
    {
      title: 'ประวัติรายการ',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>
          <FileOutlined
            className="file-outlined-css"
            onClick={() => onModalOpenHistory(record.offerFormId)}
          />
        </div>
      ),
    },

    {
      title: 'แก้ไข/รายละเอียด',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return (
          <div
            onClick={() => {
              navigator(
                getInquiryPathFromOfferType({
                  offerType: record.offerFormType,
                  offerId: record.offerFormId,
                }),
                { state: { from: pathname } }
              );
            }}
          >
            <AuditOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
          </div>
        );
      },
    },
  ];
  return columns;
};
