import { Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';
import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';
import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

const ServiceActionButtons = () => {
  const { onAddNewContServes, onDeleteContServes, isDisabled } = useContractServicesContext();
  const { currentContFacilities } = useContractFacilitiesContext();

  return (
    <div>
      <Space>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<PlusOutlined />}
          onClick={onAddNewContServes}
        >
          เพิ่มบริการ
        </PrimaryButton>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<DeleteOutlined />}
          onClick={() => onDeleteContServes(currentContFacilities)}
        >
          ลบบริการ
        </PrimaryButton>
      </Space>
    </div>
  );
};

export default ServiceActionButtons;
