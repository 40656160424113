import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Select } from 'antd';
import React from 'react';
import buttonCSS from 'styles/components/button.module.css';
import { SearchOperatorsProps } from 'features/OperatorsLMS/interfaces';

const SearchOperatorsForm = ({
                               formSearch,
                               onReset,
                               onSearchOperators,
                               operatorsList,
                             }: SearchOperatorsProps) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Form
        labelWrap
        form={formSearch}
        requiredMark={false}
        name='searchOfferForm'
        onFinish={(val: any) => onSearchOperators(val)}
        style={{ width: '70vw' }}
      >
        <Row gutter={[24, 24]}>
          {' '}
          <Col span={14}>
            <Form.Item colon labelAlign='right' label='ผู้ประกอบการ :' name='id'>
              <Select
                placeholder='-- เลือกผู้ประกอบการ --'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                options={
                  operatorsList &&
                  operatorsList.map((val) => {
                    return { value: val.id, label: val.operName };
                  })
                }
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item colon labelAlign='right' label='สถานะ :' name='status'>
              <Select
                placeholder='-- ระบุสถานะผู้ประกอบการ --'
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                options={
                  [{
                    label: 'ปกติ',
                    value: true,
                  },
                    {
                      label: 'สิ้นสุด',
                      value: false,
                    },
                  ]
                }
                showSearch
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
            }}>
              <Button
                className={buttonCSS.primary}
                type='primary'
                shape='round'
                icon={<SearchOutlined />}
                size='large'
                htmlType='submit'
              >
                ค้นหาข้อมูล
              </Button>
              <Button
                className={buttonCSS.primary}
                type='primary'
                shape='round'
                icon={<ClearOutlined />}
                size='large'
                onClick={() => onReset()}
              >
                ล้างเงื่อนไข
              </Button>
            </div>
          </Col>

        </Row>
      </Form>
    </div>
  );
};

export default SearchOperatorsForm;
