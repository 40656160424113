import { useCallback } from 'react';

import { getUserInfo } from 'api/authen';

import { useApi } from './useApi';
import useAuth from './useAuth';
import useDialogAlert from './useDialogAlert';

import CookiesService from 'service/CookiesService';
import { USER_ROLE_OPERATOR } from 'constants/roles';

type OnSelectProfileProps = {
  refNo: string;
  errorRedirectPath?: string;
};

const useProfile = () => {
  const { makeRequest } = useApi();
  const { saveAuth } = useAuth();
  const { error } = useDialogAlert();

  const onSelectProfile = useCallback(
    async ({ refNo, errorRedirectPath = '/login' }: OnSelectProfileProps) => {
      try {
        // NOTE: [For Operator] Get user info from selected profile (refNo props)
        const userInfo = await makeRequest(() => {
          return getUserInfo({ referenceNo: refNo });
        });

        /**
         * NOTE: field "userName" need to save refNo instead of the real userName
         * Because when we get userInfo for operator, we use refNo as a userLogin
         * But for the officer we use the real userName for userLogin
         */
        saveAuth({ ...userInfo?.data?.data, userRole: USER_ROLE_OPERATOR });
        const rootPath = CookiesService.get('ROOT_PATH');

        // NOTE: Redirect to default path of the role
        window.location.replace(rootPath);
      } catch (errorInfo) {
        console.error('error', errorInfo);
        error(
          {
            header: 'มีข้อผิดพลาดเกิดขึ้น',
            sub: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อเจ้าหน้าที่',
          },
          () => {
            window.location.replace(errorRedirectPath);
          }
        );
      }
    },
    [makeRequest, saveAuth, error]
  );

  return { onSelectProfile };
};

export default useProfile;
