/* eslint-disable */

import { Col, Form, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { ButtonComponent } from 'components';
import { useOutletContext } from 'react-router-dom';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { useApi } from 'hooks/useApi';
import { getAllOperators } from 'api/operator';
import { Operator } from 'api/operator/interface';


interface ImportInput {
  form: FormInstance;
  onSubmit: () => void;
}

interface OperatorOption {
  value: number;
  label: string;
}

const ImportDataForm = ({ form, onSubmit }: ImportInput): JSX.Element => {
  const [years, setYears] = useState<any[]>();
  const [quarter, setQuarter] = useState<any[]>();
  const [operatorIds, setOperatorIds] = useState<OperatorOption[]>([]);

  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { makeRequest: getOperatorsRequest } = useApi();

  const filterOption = (input: string, option?: { label: string; value: number }) => {
    return option?.value.toString().includes(input) || false;
  };

  useEffect(() => {
    getOperatorsRequest(async () => {
      const res: any = await getAllOperators();
      const operators: Operator[] = res.data.data;
      setOperatorIds(
        operators.map((i) => {
          return {
            label: i.operName,
            value: i.id,
          };
        }),
      );
      return res;
    });
  }, []);

  useEffect(() => {
    setHeaderTitle('เพิ่ม/แก้ไข ข้อมูลนำส่งรายไตรมาศ');
    const listYear: any[] = [];
    for (let i = 2539; i <= new Date().getFullYear() + 544; i++) {
      listYear.push({
        value: i,
        label: i,
      });
    }
    setQuarter([
      {
        value: 1,
        label: 1,
      },
      {
        value: 2,
        label: 2,
      }, {
        value: 3,
        label: 3,
      }, {
        value: 4,
        label: 4,
      },


    ]);
    setYears(listYear);
  }, [setHeaderTitle]);

  return <div className='padding-conten-main'>
    <div className='padding-content'>
      <Form form={form} style={{ width: '100%' }}>
        <Row justify='center' gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item name='operatorId' label='ผู้ประกอบการ :'
                       rules={[{ required: true, message: 'กรุณาระบุผู้ประกอบการ' }]

                       }>
              <Select
                placeholder='ระบุผู้ประกอบการ'
                onChange={() => {
                }}
                allowClear
                showSearch
                filterOption={(input, option) => {
                  return (option?.label.includes(input)) || false;
                }}
                options={operatorIds}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name='year' label='ปี พ.ศ.' rules={[{ required: true, message: 'กรุณาระบุปี พ.ศ.' }]

            }>
              <Select
                showSearch
                placeholder='ระบุปี พ.ศ.'
                onChange={() => {
                }}
                allowClear
                options={years}
                filterOption={filterOption}
              >
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name='quarter' label='ไตรมาสที่ :' rules={[{ required: true, message: 'กรุณาระบุไตรมาส' }]

            }>
              <Select
                placeholder='ระบุไตรมาส'
                onChange={() => {
                }}
                allowClear
                options={quarter}
              >
              </Select>
            </Form.Item>
          </Col>

        </Row>
        <Row justify='end'>
          <Col span={6} style={{ textAlign: 'right' }}>
            <ButtonComponent style={{
              width: '200px',
              marginTop: '16px',
            }} htmlType='submit' onClick={onSubmit}>
              <SaveOutlined />
              บันทึกรายการ
            </ButtonComponent>
          </Col>
        </Row>
      </Form>
    </div>
  </div>;
};
export default ImportDataForm;
