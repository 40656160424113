import { useCallback } from 'react';

import { SearchReportProps } from 'features/ReportOffer/interfaces';
import SearchOverviewForm from '../components/SearchOverviewForm';
import SearchTrackingForm from '../components/SearchTrackingForm';

const useReport = ({ formReport, onReset, onSearchReport, reportType }: SearchReportProps) => {
  const report = useCallback(() => {
    switch (reportType) {
      case 'overview':
        return (
          <SearchOverviewForm
            formReport={formReport}
            onReset={onReset}
            onSearchReport={onSearchReport}
            reportType={reportType}
          />
        );
      case 'tracking':
        return (
          <SearchTrackingForm
            formReport={formReport}
            onReset={onReset}
            onSearchReport={onSearchReport}
            reportType={reportType}
          />
        );

      default:
        <></>;
    }
  }, [reportType, formReport, onReset, onSearchReport]);
  return { report };
};
export default useReport;
