import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import { dayJsformatDateToBCYear } from 'utils/date';
import { FileListDataType } from '../../StepFormWithApproveFile';

type FileTableColumnProps = {
  isDisable: boolean;
  handleRemoveFile: Function;
  downloadFile: Function;
};

export const getColumns = ({ isDisable, handleRemoveFile, downloadFile }: FileTableColumnProps) => {
  const columns: ColumnsType<FileListDataType> = [
    {
      title: 'ดาวน์โหลดเอกสาร',
      key: 'download',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => {
            downloadFile(record);
          }}
        />
      ),
    },
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'กลุ่มเอกสาร',
      dataIndex: 'docName',
      key: 'docName',
      width: 150,
    },
    {
      title: 'รายการเอกสาร',
      dataIndex: 'attachFileName',
      key: 'attachFileName',
      render: (value) => <div style={{ wordBreak: 'break-word' }}>{value}</div>,
    },
    {
      title: 'ครั้งที่',
      align: 'center',
      dataIndex: 'offerStateNotifyEmail',
      key: 'offerStateNotifyEmail',
      render: (value) => <>{value.numberOfSent}</>,
    },
    {
      title: 'วันที่',
      align: 'center',
      dataIndex: 'offerStateNotifyEmail',
      key: 'offerStateNotifyEmail',
      render: (value) => (
        <>{value?.updatedDate ? dayJsformatDateToBCYear(value?.updatedDate) : ''}</>
      ),
    },
    {
      title: 'สถานะการส่ง',
      align: 'center',
      dataIndex: 'offerStateNotifyEmail',
      key: 'offerStateNotifyEmail',
      render: (value) => <>{value.notifyFlag ? 'ส่งแล้ว' : ''}</>,
    },
    {
      title: 'ลบ',
      key: 'delete',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          disabled={isDisable || !!record?.offerStateNotifyEmail?.numberOfSent}
          // disabled={isDisable}
          onClick={() => handleRemoveFile(record)}
        />
      ),
    },
  ];
  return columns;
};
