import { useState } from 'react';
import { Col, Form, Input, Modal, Row, Spin } from 'antd';

import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';

import ModalFooter from 'components/ModalFooter';
import CurrencyDropdown from 'features/Offer/components/CurrencyDropdown';
import ServiceUnitDropdown from 'features/Offer/components/ServiceUnitDropdown';
import PayPeriodUnitDropdown from 'components/PayPeriodUnitDropdown';

import { formLayout } from 'constants/form';
import { OTHER_OPTION } from 'features/Offer/constant';
import { DropdownType } from 'features/Offer/interfaces';

const RIOServePayModal = () => {
  const {
    isRIOServePayModalOpen,
    onCloseRIOServePayModal,
    RIOservePayModalFormRef,
    selectedServiceOption,
    onSubmitServePayModalForm,
  } = useOfferServicesContext();
  const [selectedUnit, setSelectedUnit] = useState<DropdownType | undefined>(undefined);
  const [selectedCurrency, setSelectedCurrency] = useState<DropdownType | undefined>(undefined);
  const isDisabledOtherUnitDesc = selectedUnit?.label !== OTHER_OPTION;
  const isDisabledOtherCurrencyDesc = selectedCurrency?.label !== OTHER_OPTION;

  const onChangeCurrency = (option: DropdownType) => {
    setSelectedCurrency(option);
  };

  const onChangeUnit = (option: DropdownType) => {
    setSelectedUnit(option);
  };

  return (
    <Modal
      title="อัตราค่าตอบแทน"
      width="75%"
      open={isRIOServePayModalOpen}
      onCancel={onCloseRIOServePayModal}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, .8)' }}
      footer={
        <ModalFooter
          confirmLabel="บันทึกรายการ"
          handleCancel={onCloseRIOServePayModal}
          onSubmitForm={() => RIOservePayModalFormRef.submit()}
        />
      }
    >
      <Spin spinning={false}>
        <div style={{ margin: '30px 0 40px 0' }}>
          <Form
            {...formLayout}
            labelWrap
            labelAlign="left"
            form={RIOservePayModalFormRef}
            name="RIOserviceModalForm"
            size="large"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            onFinish={onSubmitServePayModalForm}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="เงื่อนไขราคา"
                  name="payCondition"
                  rules={[{ required: true, message: 'กรุณากรอกเงื่อนไขราคา' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="อัตราค่าตอบแทน"
                  name="payCompensation"
                  rules={[{ required: true, message: 'กรุณากรอกอัตราค่าตอบแทน' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CurrencyDropdown onChangeOption={onChangeCurrency} />
              </Col>
              <Col span={12}>
                <Form.Item
                  label="สกุลเงิน กรณีระบุอื่นๆ"
                  name="currencyDesc"
                  labelCol={{ span: 10 }}
                  rules={
                    isDisabledOtherCurrencyDesc
                      ? undefined
                      : [{ required: true, message: 'กรุณาระบุอื่นๆ' }]
                  }
                >
                  <Input disabled={isDisabledOtherCurrencyDesc} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <ServiceUnitDropdown
                  onChangeOption={onChangeUnit}
                  raoServId={selectedServiceOption?.value}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label="หน่วย (Unit) กรณีระบุอื่นๆ"
                  name="unitDesc"
                  labelCol={{ span: 10 }}
                  rules={
                    isDisabledOtherUnitDesc
                      ? undefined
                      : [{ required: true, message: 'กรุณาระบุอื่นๆ' }]
                  }
                >
                  <Input disabled={isDisabledOtherUnitDesc} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="ระยะเวลา"
                      labelCol={{ span: 10 }}
                      name="payPeriod"
                      rules={[
                        { required: true, message: 'กรุณาระบุระยะเวลา' },
                        { pattern: /^[0-9]+$/, message: 'กรุณากรอกตัวเลขเท่านั้น' },
                      ]}
                    >
                      <Input className="no-right-radius" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="payPeriodUnit"
                      className="no-left-radius"
                      style={{ borderLeft: 'none' }}
                      rules={[{ required: true, message: 'กรุณาเลือกหน่วยระยะเวลา' }]}
                      initialValue={1}
                    >
                      <PayPeriodUnitDropdown className="select-no-left-border" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default RIOServePayModal;
