import { useCallback, useEffect, useState } from 'react';
import Search from './components/Search/Search';
import { useOutletContext } from 'react-router-dom';
import { Form } from 'antd';
import SearchResults from './components/SearchResults/SearchResults';
import useAuth from 'hooks/useAuth';

import { TApiResponse, useApi } from 'hooks/useApi';
// import { listReviewOffer } from 'api/review';
import { getOperatorAll } from 'api/operator';
// import { listOfferFormStates, listOfficerOffer, listReviewOfferOfficer } from 'api/officerCT';
import ModalHistory from 'components/ModalHistory/ModalHistory';
import { getInquiryStatus, listAttachHistory } from 'api/attachHistory';
import useDialogAlert from 'hooks/useDialogAlert';
import { listInformationInquire } from 'api/informationInquire';
import { dayJsformatDateObjToString12PM } from 'utils/date';

type Props = {};

const ListAssignment = (props: Props) => {
  const [form] = Form.useForm();
  const [formOffer] = Form.useForm();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { user } = useAuth();
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  // let navigator = useNavigate();
  const [modalOpenHistory, setModalOpenHistory] = useState<boolean>(false);
  const [filterOffer, setFilterOffer] = useState<{
    offerFormStartDate: string | null;
    offerFormEndDate: string | null;
    offerFormTypeCode: string | null;
    taxId: string | null;
    offerInquiryStatus: string | null;
  }>({
    offerFormStartDate: null,
    offerFormEndDate: null,
    offerFormTypeCode: null,
    taxId: null,
    offerInquiryStatus: null,
  });
  const {
    makeRequest: getOperatorListRequest,
    data: operator,
  }: // loading: operatorLoading,
  TApiResponse = useApi();

  const { makeRequest: getListAttachHistoryRequest, data: attachHistory }: TApiResponse = useApi();
  const { makeRequest: getInquiryStatusRequest, data: inquiryStatus }: TApiResponse = useApi();
  // const {
  //   makeRequest: updateAssigneesRequest,
  //   data: updateAssigneesData,
  //   loading: updateAssigneesLoading,
  //   status: status,
  //   statusText: statusText,
  // }: TApiResponse = useApi();
  const [operatorList, setOperatorList] = useState<
    {
      value: any;
      label: any;
    }[]
  >();

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const { error } = useDialogAlert();
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    setHeaderTitle('สอบถามข้อมูล');
    // eslint-disable-next-line
  }, []);

  // const getReviewOfferList = useCallback(() => {
  //   makeRequest(() => listOfficerOffer(pageSize, currentPageNumber));
  // }, [makeRequest, pageSize, currentPageNumber]);

  const getReviewOfferList = useCallback(() => {
    makeRequest(() => listInformationInquire(pageSize, currentPageNumber, filterOffer));
  }, [makeRequest, pageSize, currentPageNumber, filterOffer]);
  const getOperatorList = useCallback(() => {
    getOperatorListRequest(() => getOperatorAll());
  }, [getOperatorListRequest]);
  const getListInquiryStatus = useCallback(() => {
    getInquiryStatusRequest(() => getInquiryStatus());
  }, [getInquiryStatusRequest]);

  const getListAttachHistory = useCallback(
    (id: number | string) => {
      getListAttachHistoryRequest(() => listAttachHistory(id));
    },
    [getListAttachHistoryRequest]
  );
  // const updateAssignees = useCallback(
  //   async (id: number | string, data: { officerIds: number[] }) => {
  //     await updateAssigneesRequest(() => updateListOfferAssignees(id, data));
  //   },
  //   [updateAssigneesRequest]
  // );

  useEffect(() => {
    if (user) {
      getReviewOfferList();
    }
    getOperatorList();
    getListInquiryStatus();
  }, [getReviewOfferList, getOperatorList, user, getListInquiryStatus]);
  useEffect(() => {
    if (operator) {
      let arrayOperator = [];
      for (let i = 0; i < operator.length; i++) {
        const element = operator[i];
        arrayOperator.push({
          value: element.taxId,
          label: element.operName,
        });
      }

      setOperatorList(arrayOperator);
    }
    if (operator) {
      let arrayOperator = [];
      for (let i = 0; i < operator.length; i++) {
        const element = operator[i];
        arrayOperator.push({
          value: element.taxId,
          label: element.operName,
        });
      }

      setOperatorList(arrayOperator);
    }
  }, [operator]);
  useEffect(() => {
    if (attachHistory) {
      if (attachHistory.length === 0) {
        error({ header: 'ไม่มีประวัติไฟล์ข้อเสนอ', sub: '' });
      } else {
        setModalOpenHistory(true);
      }
    }
  }, [attachHistory, error]);
  // useEffect(() => {
  //   if (status === 200) {
  //     getReviewOfferList();
  //   }
  // }, [status, getReviewOfferList, statusText]);

  const onFinishSearch = (values: any) => {
    setFilterOffer({
      offerFormTypeCode: values.offerType === undefined ? null : values.offerType,
      taxId: values.client === undefined ? null : values.client,

      offerFormStartDate:
        values.offerFormStartDate === undefined
          ? null
          : dayJsformatDateObjToString12PM(values.offerFormStartDate),
      offerFormEndDate:
        values.offerFormEndDate === undefined
          ? null
          : dayJsformatDateObjToString12PM(values.offerFormEndDate),
      offerInquiryStatus: values.offerInquiryStatus,
    });
  };

  const onFinishFailedSearch = (errorInfo: any) => {
    formOffer.resetFields();
  };

  const onModalOpenHistory = (id: number | string) => {
    getListAttachHistory(id);
    // setModalOpenHistory(true);
  };
  const onModalHistoryCancel = () => {
    setModalOpenHistory(false);
  };
  return (
    <div className="k2d">
      <Search
        form={form}
        onFinishSearch={onFinishSearch}
        onFinishFailedSearch={onFinishFailedSearch}
        operatorList={operatorList}
        inquiryStatus={inquiryStatus}
      />
      <SearchResults
        data={data}
        loading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        pageable={pageable}
        onModalOpenHistory={onModalOpenHistory}
        user={user?.roles}
      />

      <ModalHistory
        modalOpenOffer={modalOpenHistory}
        onModalCancel={onModalHistoryCancel}
        title="ไฟล์จัดทำข้อเสนอ"
        width={700}
        attachHistory={attachHistory}
      />
    </div>
  );
};

export default ListAssignment;
