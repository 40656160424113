import { FormInstance } from 'antd';

const useSearch = (formReport: FormInstance<any>) => {
  const onSearchReport = (values: any) => {
  };
  const onReset = () => {
    formReport.resetFields();
  };

  return { onSearchReport, onReset };
};

export default useSearch;
