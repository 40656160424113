import { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Table } from 'antd';

import useModal from 'hooks/useModal';
import { useApi, TApiResponse } from 'hooks/useApi';
import { FacilityData } from 'api/master/interface';
import { listAllFacilities, createNewFacility, updateFacility } from 'api/master/adminData';

import PrimaryButton from 'components/PrimaryButton';
import { HeaderTitleComponent } from 'components';

import EditModal from '../components/EditModal';
import { columns } from '../components/columns';

const ListFacilities = () => {
  const [formRef] = Form.useForm();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const {
    makeRequest: _getListFacilities,
    data: facilities = [],
    loading: isLoadingFacilitiesList,
  }: TApiResponse = useApi();

  const { makeRequest: _updateFacility, loading: isUpdating }: TApiResponse = useApi();

  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);

  const onOpenEditModal = (record: any) => {
    setSelectedRecord(record);
    showModal();
  };

  const onCloseModal = () => {
    setSelectedRecord(undefined);
    formRef.resetFields();
    handleCancel();
  };

  const onFetchList = useCallback(() => {
    _getListFacilities(() => listAllFacilities());
  }, [_getListFacilities]);

  const onSaveFacility = useCallback(
    async (values: FacilityData) => {
      if (values.facId) {
        await _updateFacility(() => updateFacility(values.facId, values));
      } else {
        await _updateFacility(() => createNewFacility(values));
      }
      setSelectedRecord(undefined);
      formRef.resetFields();
      handleCancel();
      onFetchList();
    },
    [handleCancel, _updateFacility, onFetchList, formRef]
  );

  useEffect(() => {
    onFetchList();
  }, [onFetchList]);

  console.log(selectedRecord);

  return (
    <div>
      <EditModal
        isModalOpen={isModalOpen}
        isUpdating={isUpdating}
        selectedRecord={selectedRecord}
        onCloseModal={onCloseModal}
        onSaveRecord={onSaveFacility}
        titleName={'แก้ไขข้อมูลสิ่งอำนวยความสะดวก'}
        fieldName={'facName'}
        fieldTitle={'ชื่อสิ่งอำนวยความสะดวก'}
        formRef={formRef}
      />
      <HeaderTitleComponent>จัดการข้อมูลสิ่งอำนวยความสะดวก</HeaderTitleComponent>
      <div className="content-box"  style={{ paddingLeft: 0 }}>
        <PrimaryButton icon={<PlusOutlined />} onClick={showModal}>
          เพิ่มสิ่งอำนวยความสะดวก
        </PrimaryButton>
      </div>
      <Table
        columns={columns({
          onOpenEditModal,
          titleName: 'สิ่งอำนวยความสะดวก',
          valueName: 'facName',
        })}
        dataSource={facilities}
        loading={isLoadingFacilitiesList}
        rowKey={'facId'}
        size="middle"
        pagination={false}
      />
    </div>
  );
};

export default ListFacilities;
