import type { ColumnsType } from 'antd/es/table';
import { Dropdown, MenuProps } from 'antd';
import {
  CheckCircleTwoTone,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { BiDetail } from 'react-icons/bi';

import { UploadIcon } from 'components/UploadComponent/UploadComponent';
import { ILestRoaPageable } from 'contexts/DataAllProjectContext';
import { dayJsformatDateToBCYear } from 'utils/date';
import { getOfferDetailPath, getOfferEditPath } from 'utils/getOfferUrl';

export function columnsFunction(
  showWeb: any,
  modalFile: any,
  keyType: 'RIO' | 'RAO',
  pathName: string,
  navigator?: any,
  onModalOpenHistory?: (id: number | string) => void
) {
  const columns: ColumnsType<ILestRoaPageable> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      align: 'center',
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'กลุ่มข้อเสนอ',
      dataIndex: 'offerFormGroupName',
      key: 'offerFormGroupName',
      align: 'center',
      width: 100,
    },
    {
      title: 'เลขที่หนังสือผู้ประกอบการ',
      dataIndex: 'offerFormNo',
      key: 'offerFormNo',
      align: 'center',
      width: 100,
    },
    {
      title: 'วันที่หนังสือผู้ประกอบการ',
      dataIndex: 'offerFormDate',
      key: 'offerFormDate',
      width: 100,
      align: 'center',
      render: (_, record) => <div>{dayJsformatDateToBCYear(record.offerFormDate)}</div>,
    },
    {
      title: 'สถานะการจัดทำข้อเสนอ',
      dataIndex: 'offerFormStatusName',
      key: 'offerFormStatusName',
      align: 'center',
      width: 200,
      render: (_, record) => <div style={{ textAlign: 'start' }}>{record.offerFormStatusName}</div>,
    },
    {
      title: 'Link Web เผยแพร่',
      dataIndex: 'offerFormPublicUrl',
      key: 'offerFormPublicUrl',
      align: 'center',
      render: (_, record) => (
        <div className="link-a" onClick={() => showWeb(record, record.offerFormPublicUrl)}>
          {record.offerFormPublicUrl.length === 0 ? <>คลิกเพื่อระบุ</> : <>คลิกเพื่อแสดง</>}
        </div>
      ),
      width: 100,
    },
    {
      title: 'จำนวนครั้งที่แก้ไข',
      dataIndex: 'numOfEdit',
      key: 'numOfEdit',
      align: 'center',
      width: 100,
    },

    {
      title: 'ประวัติรายการ',
      key: 'file',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>
          <FileOutlined
            className="file-outlined-css"
            onClick={() => onModalOpenHistory && onModalOpenHistory(record.offerFormId)}
          />
        </div>
      ),
    },
    {
      title: 'แก้ไข/รายละเอียด',
      key: 'action',
      width: 100,
      align: 'center',
      render: (_, record) => {
        const items: MenuProps['items'] =
          record.offerFormStatus &&
          ['3', '4', '5', '7', '8', '9', '10', '11', '13', '14', '15', '16', '17'].includes(
            record.offerFormStatus
          )
            ? [
                {
                  key: '2',
                  label: (
                    <div
                      onClick={() =>
                        // navigator(`/search-${keyType}/${record.offerFormId}/details`, {
                        //   state: { from: pathName },
                        // })
                        navigator(
                          getOfferDetailPath({ offerType: keyType, offerId: record.offerFormId }),
                          {
                            state: { from: pathName },
                          }
                        )
                      }
                      style={{ display: 'flex', gap: '10px', alignItems: 'center' }}
                    >
                      <BiDetail />
                      รายละเอียด
                    </div>
                  ),
                },
              ]
            : [
                {
                  key: '1',
                  label: (
                    <div
                      style={{ display: 'flex', gap: '10px' }}
                      onClick={() =>
                        // navigator(`/search-${keyType}/${record.offerFormId}/edit`, {
                        //   state: { from: pathName },
                        // })
                        navigator(
                          getOfferEditPath({ offerType: keyType, offerId: record.offerFormId }),
                          {
                            state: { from: pathName },
                          }
                        )
                      }
                    >
                      <EditOutlined />
                      <div>แก้ไข</div>
                    </div>
                  ),
                },
              ];

        return (
          <Dropdown menu={{ items }} placement="bottomRight" arrow>
            <EllipsisOutlined className="ellipsis-outlined-css" />
          </Dropdown>
        );
      },
    },
  ];
  return columns;
}

// rowSelectionFacilities
export function columnsFacilitiesFunction() {
  const columns: ColumnsType<DataFacilitiesType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 20,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'ประเภทสิ่งอำนวยความสะดวก',
      dataIndex: 'facilitiesType',
      key: 'facilitiesType',
      align: 'center',
      width: 100,
    },

    {
      title: 'บริการ',
      key: 'facilities',
      dataIndex: 'facilities',
      width: 50,
      align: 'center',
    },
  ];
  return columns;
}
export function columnsServeFunction(handleChange: any, showManageModal: any, error: any) {
  const columns: ColumnsType<DataServeType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 20,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'บริการ',
      dataIndex: 'serve',
      key: 'serve',
      align: 'center',
      width: 100,
      render: (_, record) => <>{record.serve.label}</>,
    },

    {
      title: 'อัตราค่าตอบแทน',
      key: 'action',
      width: 50,
      align: 'center',
      render: (_, record) =>
        record.remuneration ? (
          <>
            <PlusCircleOutlined
              className="plusCircle-outlined-css"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (record.serve.value) {
                  showManageModal(record);
                } else {
                  error({ header: 'กรุณาเลือกบริการ', sub: '' });
                }

                // showManageModal(record)
              }}
            />
          </>
        ) : (
          <>
            <CheckCircleTwoTone className="plusCircle-outlined-css" twoToneColor="#52c41a" />
          </>
        ),
    },
  ];
  return columns;
}
export const columnsDetail: ColumnsType<IRaoOfferServes> = [
  {
    title: 'ลำดับที่',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: 100,
    render: (item, record, index) => <>{index + 1}</>,
  },
  {
    title: 'บริการ',
    dataIndex: 'serve',
    key: 'serve',
    align: 'center',
    render: (_, record) => <div style={{ textAlign: 'start' }}>{record.raoServName}</div>,
  },
  {
    title: 'อัตราค่าตอบแทน',
    key: 'action',
    width: 50,
    align: 'center',
    render: (_, record) => (
      <CheckCircleTwoTone className="plusCircle-outlined-css" twoToneColor="#52c41a" />
    ),
  },
];
export function columnsServeFunctionChange(showManageModal: any) {
  const columnsDetail: ColumnsType<IRaoOfferServes> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'บริการ',
      dataIndex: 'serve',
      key: 'serve',
      align: 'center',
      render: (_, record) => <div style={{ textAlign: 'start' }}>{record.raoServName}</div>,
    },
    {
      title: 'อัตราค่าตอบแทน',
      key: 'action',
      width: 50,
      align: 'center',
      render: (_, record) => (
        <CheckCircleTwoTone
          className="plusCircle-outlined-css"
          twoToneColor="#52c41a"
          onClick={() => showManageModal(record)}
        />
      ),
    },
  ];
  return columnsDetail;
}
export function columnsDetailFacilitiesFunction(facilitiesModal: any) {
  const columnsDetailFacilities: ColumnsType<IRoaOfferFacilities> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'ประเภทสิ่งอำนวยความสะดวก',
      dataIndex: 'facName',
      key: 'facName',
      align: 'center',
      width: 300,
      render: (item, record) => <div style={{ textAlign: 'start' }}>{record.facName}</div>,
    },

    {
      title: 'บริการ',
      key: 'offerFacilitiesServes',
      dataIndex: 'offerFacilitiesServes',
      align: 'center',
      render: (item, record) => (
        <>
          {record.offerFacilitiesServes.map((val, inx) => (
            <div style={{ textAlign: 'start' }}>
              {inx === 1 && ' , '}
              {val.raoServName}
            </div>
          ))}
        </>
      ),
    },
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (_, record) => (
        <EditOutlined
          className="plusCircle-outlined-css"
          style={{ cursor: 'pointer' }}
          onClick={() => facilitiesModal(record)}
        />
        // <CheckCircleTwoTone
        //   className="plusCircle-outlined-css"
        //   twoToneColor="#52c41a"
        // />
      ),
    },
  ];
  return columnsDetailFacilities;
}
export const columnsDetailFacilities: ColumnsType<IRoaOfferFacilities> = [
  {
    title: 'ลำดับที่',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: 100,
    render: (item, record, index) => <>{index + 1}</>,
  },
  {
    title: 'ประเภทสิ่งอำนวยความสะดวก',
    dataIndex: 'facName',
    key: 'facName',
    align: 'center',
    width: 300,
    render: (item, record) => <div style={{ textAlign: 'start' }}>{record.facName}</div>,
  },

  {
    title: 'บริการ',
    key: 'offerFacilitiesServes',
    dataIndex: 'offerFacilitiesServes',
    align: 'center',
    render: (item, record) => (
      <>
        {record.offerFacilitiesServes.map((val, inx) => (
          <div style={{ textAlign: 'start' }}>
            {inx === 1 && ' , '}
            {val.raoServName}
          </div>
        ))}
      </>
    ),
  },
  {
    title: '',
    key: 'action',
    width: 50,
    align: 'center',
    render: (_, record) => (
      <EditOutlined className="plusCircle-outlined-css" style={{ cursor: 'pointer' }} />
      // <CheckCircleTwoTone
      //   className="plusCircle-outlined-css"
      //   twoToneColor="#52c41a"
      // />
    ),
  },
];

export function columnsDetailFileFunction(
  handledownloadFileRao: any,
  handleChangeUpload: any,
  beforeUploadM2: any,

  fileList2:
    | {
        meta: {
          attachFileId: number | null;
          attachFileName: String;
          docId: number;
          docName: String;
          docType: String;
          required: Boolean;
        };
        file: File;
      }[]
    | undefined,
  handleChangeDeleteFile: any,

  rao: IRoa | undefined,
  error: (
    text?:
      | {
          header: string;
          sub: string;
        }
      | undefined
  ) => void,
  isDisabled?: boolean
) {
  const columnsDetailFile: ColumnsType<IRoaOfferAttachFiles> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'กลุ่มเอกสาร',
      dataIndex: 'documentgroup',
      key: 'documentgroup',
      align: 'center',
      width: 300,
      render: (item, record) => (
        <div style={{ textAlign: 'start' }}>
          <div style={{ display: 'flex', gap: '5px' }}>
            {record.required && <div style={{ color: 'red' }}>*</div>}
            {record.docName}
          </div>
        </div>
      ),
    },

    {
      title: 'รายการเอกสาร',
      key: 'documentlist',
      dataIndex: 'documentlist',
      align: 'center',
      render: (item, record) => {
        let _filter = rao?.offerAttachFiles.filter(
          (val) =>
            val.docId === record.docId &&
            val.attachFileId !== null &&
            val.docName === record.docName
        );

        if (_filter && _filter?.length !== 0) {
          record = _filter[0];
        }
        // console.log('raoraoraorao', rao, record);

        return (
          <div style={{ textAlign: 'start' }}>
            <div>
              {/* {!uploadTable(fileList2, record).is ? (
                <></>
              ) : (
                <>{uploadTable(fileList2, record).file}</>
              )} */}

              <div className="link-a" onClick={() => handledownloadFileRao(record)}>
                {record.attachFileName}
              </div>
            </div>
            {/* {record.attachFileId ? (
              <div>
                <DeleteOutlined />
              </div>
            ) : (
              <div style={{ cursor: 'pointer' }}>
                <UploadOutlined onClick={() => handleChange(record)} />
              </div>
            )} */}
          </div>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (_, record) => {
        let _filter = rao?.offerAttachFiles.filter(
          (val) =>
            val.docId === record.docId &&
            val.attachFileId !== null &&
            val.docName === record.docName
        );
        if (_filter && _filter?.length !== 0) {
          record = _filter[0];
        }
        return (
          <div>
            {record.attachFileName.length !== 0 && (
              <div>
                <DeleteOutlined
                  className={
                    isDisabled ? 'plusCircle-outlined-disabled' : 'plusCircle-outlined-css'
                  }
                  onClick={() => {
                    if (isDisabled) {
                      return;
                    }

                    handleChangeDeleteFile(record);
                  }}
                  disabled={isDisabled}
                />
              </div>
            )}
            {record.attachFileName.length === 0 && (
              <div style={{ cursor: 'pointer' }}>
                <UploadIcon data={record} onClick={handleChangeUpload} disabled={isDisabled} />
              </div>
            )}
          </div>
        );
      },
    },
  ];
  return columnsDetailFile;
}
export const columnsDetailFile: ColumnsType<IRoaOfferAttachFiles> = [
  {
    title: 'ลำดับที่',
    dataIndex: 'no',
    key: 'no',
    align: 'center',
    width: 100,
    render: (item, record, index) => <>{index + 1}</>,
  },
  {
    title: 'กลุ่มเอกสาร',
    dataIndex: 'documentgroup',
    key: 'documentgroup',
    align: 'center',
    width: 300,
    render: (item, record) => <div style={{ textAlign: 'start' }}>{record.docName}</div>,
  },

  {
    title: 'รายการเอกสาร',
    key: 'documentlist',
    dataIndex: 'documentlist',
    align: 'center',
    render: (item, record) => {
      return (
        <div style={{ textAlign: 'start' }}>
          <div>{record.attachFileName}</div>
          {record.attachFileId ? (
            <div>
              <DeleteOutlined />
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    },
  },
  {
    title: '',
    key: 'action',
    width: 50,
    align: 'center',
    render: (_, record) => (
      <DownloadOutlined className="plusCircle-outlined-css" style={{ cursor: 'pointer' }} />
      // <PlusCircleOutlined
      //   className="plusCircle-outlined-css"
      //   style={{ cursor: 'pointer' }}
      //   onClick={() => {
      //     console.log('record', record);
      //     // if (record.serve.value) {
      //     //   showManageModal(record);
      //     // } else {
      //     //   error({ header: 'กรุณาเลือกบริการ', sub: '' });
      //     // }

      //     // showManageModal(record)
      //   }}
      // />
    ),
  },
];
