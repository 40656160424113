import {
  CONTRACT_TYPE_RAO_LABEL,
  CONTRACT_TYPE_RDRO_LABEL,
  CONTRACT_TYPE_RIO_LABEL,
  CONTRACT_TYPE_RISO_LABEL,
} from 'constants/contractType';
import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RAO_LABEL,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RDRO_LABEL,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RIO_LABEL,
  OFFER_TYPE_RISO,
  OFFER_TYPE_RISO_LABEL,
} from 'constants/offerFormType';

export const getContractTypeLabel = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RISO:
      return CONTRACT_TYPE_RISO_LABEL;
    case OFFER_TYPE_RAO:
      return CONTRACT_TYPE_RAO_LABEL;
    case OFFER_TYPE_RIO:
      return CONTRACT_TYPE_RIO_LABEL;
    case OFFER_TYPE_RDRO:
      return CONTRACT_TYPE_RDRO_LABEL;
    default:
      return offerFormType;
  }
};

export const getOfferTypeLabel = (offerFormType: string) => {
  switch (offerFormType) {
    case OFFER_TYPE_RISO:
      return OFFER_TYPE_RISO_LABEL;
    case OFFER_TYPE_RAO:
      return OFFER_TYPE_RAO_LABEL;
    case OFFER_TYPE_RIO:
      return OFFER_TYPE_RIO_LABEL;
    case OFFER_TYPE_RDRO:
      return OFFER_TYPE_RDRO_LABEL;
    default:
      return offerFormType;
  }
};

export const getFileName = (record: any) => {
  const fileName = `รายงาน ${record.offerFormType} วันที่ ${record.startDate} ถึง ${record.endDate}.xlsx`;
  return fileName;
};
