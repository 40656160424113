/* eslint-disable */

import { Button, Col, Form, Row, Select, Table, Upload, UploadProps } from 'antd';
import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import buttonCSS from 'styles/components/button.module.css';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import {
  createAttachFile,
  deleteAttachFile,
  downloadAttachFileById,
  getAllAttachFile,
  getDisAgreementDoc,
} from 'api/disagreement';
import { DisagreementDoc, GetAttachFileResponse } from 'api/disagreement/interface';
import { ColumnsType } from 'antd/es/table';
import { UploadFile } from 'antd/es/upload';

interface DataType {
  key: React.Key;
  id: string;
  type: string;
  attachFile: string;
  onDownloadClick: () => void;
  onDeleteClick: () => void;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ดาวน์โหลดเอกสาร',
    dataIndex: 'id',
    width: '180px',
    align: 'center',
    render: (_, record) => <div>
      <Button onClick={record.onDownloadClick}>
        <UploadOutlined />
      </Button>
    </div>,
  },
  {
    title: 'ลำดับที่',
    dataIndex: 'id',
    align: 'center',
    width: '100px',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'กลุ่มเอกสาร',
    dataIndex: 'type',
  },
  {
    title: 'รายการเอกสาร',
    dataIndex: 'attachFile',
  },
  {
    title: 'ลบ',
    key: 'delete',
    width: '100px',
    render: (_, record) => <div onClick={record.onDeleteClick}>
      <DeleteFilled style={{
        fontSize: 24,
        color: '#ff802b',
        cursor: 'pointer',
      }
      } />
    </div>,
  },
];

interface FormFileUploadProp {
  state: number;
  stepState: number;
  disagreementFormId: number;
}

const FormFilesUpload = ({ state, stepState, disagreementFormId }: FormFileUploadProp): JSX.Element => {
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { makeRequest: GetDoc } = useApi();
  const { makeRequest: UploadFile } = useApi();
  const { makeRequest: GetAttachFileList } = useApi();
  const { makeRequest: DownloadAttachFileList } = useApi();
  const { makeRequest: DeleteAttachFileList } = useApi();
  const [disableUpload, setDisableUpload] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState<{
    label: string,
    value: number
  }[]>();

  const onSelectChange = () => {
    setDisableUpload(false);
  };


  const uploadFile = async (file: any) => {
    UploadFile(async () => {
      setUploading(true);
      const formData = new FormData();
      formData.append('attachment', file);
      const res = await createAttachFile(form.getFieldValue('docId'), stepState, formData).catch(() => setUploading(false));
      await updateAttachFileList();
      setUploading(false);
      return res;
    });
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    onChange: (file) => {
      uploadFile(file.file);
    },
    showUploadList: false,
    fileList,
  };

  const updateAttachFileList = async () => {
    GetAttachFileList(async () => {
      const res: any = await getAllAttachFile(stepState);
      const list: GetAttachFileResponse = res.data;
      setData(list.data.map(i => {
        return {
          key: i.attachFileId,
          id: i.attachFilePath,
          type: i.docName,
          attachFile: i.attachFileName,
          onDeleteClick: async () => {
            DeleteAttachFileList(async () => {
              const res = await deleteAttachFile(i.attachFileId);
              await updateAttachFileList();
              return res;
            });
          },
          onDownloadClick: async () => {
            DownloadAttachFileList(async () => {
              const res = await downloadAttachFileById(i.attachFileId);
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', i.attachFileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              return res;
            });
          },
        };
      }));
      return res;
    });
  };

  useEffect(() => {
    GetDoc(async () => {
      const res: any = await getDisAgreementDoc(state);
      const docs: DisagreementDoc[] = res.data.data;
      setOptions(docs.map(i => {
        return {
          label: i.docName,
          value: i.docId,
        };
      }));
      return res;
    });
    updateAttachFileList();
  }, [state, stepState, disagreementFormId]);
  return <div style={{
    marginBottom: 24,
  }}>
    <div><h2>อัพโหลดไฟล์</h2></div>
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <Col span={4}><span style={{
          color: 'red',
        }}>*</span> เลือกกลุ่มเอกสาร:</Col>
        <Col span={14}>
          <Form.Item name='docId'>
            <Select
              placeholder='กรุณาเลือกกลุ่มเอกสาร'
              onSelect={onSelectChange}
              style={{ width: '100%' }}
              options={options}
            />
          </Form.Item>
        </Col>
        <Col offset={1} span={3}>
          <Upload {...props} maxCount={1} disabled={disableUpload}>
            <Button
              disabled={disableUpload}
              type='primary'
              className={buttonCSS.primary}
              icon={<UploadOutlined />}
              size='large'
              shape='round'
              loading={uploading}
            >
              อัพโหลดเอกสาร
            </Button>
          </Upload>
        </Col>
        <Col span={24}>
          <Table columns={columns} dataSource={data} pagination={false} scroll={{
            y: 200,
          }
          } />
        </Col>
      </Row>
    </Form>
  </div>;
};
export default FormFilesUpload;
