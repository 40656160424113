import useAuth from 'hooks/useAuth';
import OfferChart from 'features/Dashboard/GraphDashboard/components/offer-chart';
import DeliveryChart from 'features/Dashboard/GraphDashboard/components/delivery-data-chart';
import ContactChart from 'features/Dashboard/GraphDashboard/components/contact-chart';
import DisagreementDashboard from 'features/Dashboard/GraphDashboard/components/disagreement-dashboard';
import { useCallback } from 'react';

const CONFIG_DASHBOARD: Record<string, string[]> = {
  deliveryData: ['OFF_SEN_LDR', 'OFF_SEN', 'OFF_DIR'],
  offer: ['OFF_OFFER_LDR', 'OFF_OFFER', 'OFF_DIR'],
  contract: ['OFF_CONTC', 'OFF_CONTC_LDR', 'OFF_DIR'],
  disagreement: ['OFF_DIS_LDR', 'OFF_DIS', 'OFF_DIR'],
};

const GraphDashboard = () => {
  const { user } = useAuth();

  const hasPermission = useCallback((name: string) => {
    if (user?.roles) {
      const result = user.roles.some(i => CONFIG_DASHBOARD[name].some(k => k === i));
      return result;
    }
    return false;
  }, [user]);
  return (
    <div>
      <h1>Dashboard</h1>
   
      {hasPermission('deliveryData') && <div style={{ marginTop: '24px' }}>
        <DeliveryChart />
      </div>}
      {
        hasPermission('offer') && <div style={{ marginTop: '24px' }}>
          <OfferChart />
        </div>
      }
      {
        hasPermission('contract') && <div style={{ marginTop: '24px' }}>
          <ContactChart />
        </div>
      }

      {
        hasPermission('disagreement') && <div style={{ marginTop: '24px' }}>
          <DisagreementDashboard />
        </div>
      }


    </div>
  );
};

export default GraphDashboard;
