/* eslint-disable */
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd';
import { Pageable } from 'api/operator/interface';
import { ButtonComponent } from 'components';
import React from 'react';

export interface DataSource {
  data: TableDataType[];
  pagination?: Pageable;
}

interface TableDataInput {
  dataSource: DataSource;
  onSearch: (pageSize?: number, currentPage?: number) => void;
  setCurrentPage: (value: number) => void;
  currentPage: number;
}

export interface TableDataType {
  key: React.Key;
  id: number;
  year: number;
  quarter: number;
  status: string;
  onDetailClick: () => void;
}

const columns: ColumnsType<TableDataType> = [
  {
    title: 'ลำดับที่',
    dataIndex: 'no',
    align: 'center',
    width: '90px',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'ปี',
    dataIndex: 'year',
    align: 'center',
    width: '120px',
  },
  {
    title: 'ไตรมาส',
    dataIndex: 'quarter',
    align: 'center',
    width: '120px',
  },
  {
    title: 'สถานะการนำส่งข้อมูล',
    dataIndex: 'status',
    align: 'center',
  },
  {
    title: 'แก้ไข/รายละเอียด',
    align: 'center',
    width: '240px',
    render: (_, record) => {
      return (
        <ButtonComponent htmlType='button' onClick={record.onDetailClick}>
          รายละเอียด
        </ButtonComponent>
      );
    },
  },
];

const TableData = (props: TableDataInput): JSX.Element => {
  return (
    <div className='tabs-scroll'>
      {' '}
      <Table
        columns={columns}
        dataSource={props.dataSource.data}
        size='small'
        pagination={{
          onChange: async (currentPage, size) => {
            if (currentPage !== props.currentPage) {
              props.setCurrentPage(currentPage);
            }
            props.onSearch(size, currentPage - 1);
          },
          current: props.currentPage || 1,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          total: props.dataSource.pagination?.totalItems,
          defaultPageSize: 5,
          defaultCurrent: 1,
          pageSizeOptions: [5, 10, 20, 50, 100],
        }}
      />
    </div>
  );
};
export default TableData;
