import { useCallback, useEffect } from 'react';
import { Form, FormInstance, Modal, Row, Spin } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractAssignees } from 'api/contract/assignees';
import { formLayout } from 'constants/form';
import { getJSDateObj } from 'utils/date';

import AssigneesDropdown from '../AssigneesDropdown';
import Footer from './Footer';

type AssignmentModalProps = {
  isModalOpen: boolean;
  handleCancel: Function;
  selectedConFormId: any;
  onUpdateAssignees: Function;
  assigneesFormRef: FormInstance<any>;
  isSavingContractAssignesss: boolean;
};

const AssignmentModal = ({
  isModalOpen,
  handleCancel,
  selectedConFormId,
  onUpdateAssignees,
  assigneesFormRef,
  isSavingContractAssignesss,
}: AssignmentModalProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  const onSetValuesToAssignees = useCallback(() => {
    if (data) {
      assigneesFormRef.setFieldsValue({
        assignees: data.map((item: any) => item.officer.officerId),
        assignDate: data[0]?.assignDate ? getJSDateObj(data[0].assignDate) : null,
      });
    }
  }, [assigneesFormRef, data]);

  const onHandleCancel = () => {
    onSetValuesToAssignees(); // NOTE: Reset form to initial state
    handleCancel();
  };

  useEffect(() => {
    if (selectedConFormId) {
      makeRequest(() => listContractAssignees(selectedConFormId));
    }
  }, [makeRequest, selectedConFormId]);

  useEffect(() => {
    onSetValuesToAssignees();
  }, [onSetValuesToAssignees]);

  return (
    <>
      <Modal
        title="ข้อมูลผู้ได้รับมอบหมาย"
        open={isModalOpen}
        maskClosable={false}
        width={'80vh'}
        onCancel={() => onHandleCancel()}
        footer={
          <Footer
            handleCancel={onHandleCancel}
            onSubmitForm={() => {
              assigneesFormRef.submit();
            }}
          />
        }
      >
        <Spin spinning={loading || isSavingContractAssignesss}>
          <Form
            {...formLayout}
            labelWrap
            form={assigneesFormRef}
            name="assigneesForm"
            size="large"
            onFinish={() => onUpdateAssignees(onSetValuesToAssignees)}
          >
            <Row gutter={[16, 16]}>
              <AssigneesDropdown />
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default AssignmentModal;
