import { FormInstance } from 'antd';
import { mainRoutePaths } from 'constants/routes';
import { useState } from 'react';

const useHeaderTitle = (setHeaderTitle: Function, formReport: FormInstance<any>) => {
  const [reportType, setReportType] = useState<string>('');
  const setHeaderText = (pathname: string) => {
    formReport.resetFields();
    switch (pathname) {
      case mainRoutePaths.reportDisputeOverview:
        return setTitle('รายงานภาพรวมกระบวนการระงับข้อพิพาท', 'overview');
      case mainRoutePaths.reportDisputeTracking:
        return setTitle('รายงานการติดตามกระบวนการระงับข้อพิพาท', 'tracking');

      default:
        return setTitle('', '');
    }
  };
  const setTitle = (headerTitle: string, reportType: string) => {
    setReportType(reportType);
    setHeaderTitle(headerTitle);
  };
  return { setHeaderText, reportType };
};
export default useHeaderTitle;
