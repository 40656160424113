import { createContext, useContext } from 'react';
import { Form, FormInstance } from 'antd';
import useManageFacility from '../hooks/useManageFacility';
import useOfferDataContext from '../hooks/useOfferDataContext';

import { OfferServicesContext } from './OfferServicesContext';
import { IOfferFacility } from '../interfaces';

type OfferFacilitiesContextType = {
  isFacilityModalOpen: boolean;
  currentOfferFacilities: IOfferFacility[];
  facilityFormRef: FormInstance<any>;
  facilityTableFormRef: FormInstance<any>;
  selectedFacilities: React.Key[];
  editingFacility: IOfferFacility | undefined;
  onAddNewFacility: () => void;
  onSelectMultipleFacilities: (selectedRowKeys: React.Key[], _: any[]) => void;
  onDeleteFacilities: () => void;
  onCloseFacilityModal: () => void;
  onSubmitNewFacility: () => void;
  onCheckDuplicateFacility: (facilityName: string) => void;
  onEditFacility: (facilityName: string) => void;
};

const OfferFacilitiesContext = createContext<OfferFacilitiesContextType>(
  {} as OfferFacilitiesContextType
);

type OfferFacilititesProviderProps = {
  children: React.ReactNode;
};

const OfferFacilititesProvider = ({ children }: OfferFacilititesProviderProps) => {
  const { offerData } = useOfferDataContext();
  const { currentOfferServices } = useContext(OfferServicesContext);
  const [facilityTableFormRef] = Form.useForm();
  const {
    currentOfferFacilities,
    isFacilityModalOpen,
    facilityFormRef,
    selectedFacilities,
    editingFacility,
    onAddNewFacility,
    onSelectMultipleFacilities,
    onDeleteFacilities,
    onCloseFacilityModal,
    onSubmitNewFacility,
    onCheckDuplicateFacility,
    onEditFacility,
  } = useManageFacility({
    offerFacilities: offerData?.offerFacilities,
    currentOfferServices,
  });
  return (
    <OfferFacilitiesContext.Provider
      value={{
        isFacilityModalOpen,
        currentOfferFacilities,
        facilityFormRef,
        facilityTableFormRef,
        selectedFacilities,
        editingFacility,
        onAddNewFacility,
        onSelectMultipleFacilities,
        onDeleteFacilities,
        onCloseFacilityModal,
        onSubmitNewFacility,
        onCheckDuplicateFacility,
        onEditFacility,
      }}
    >
      {children}
    </OfferFacilitiesContext.Provider>
  );
};

export { OfferFacilitiesContext, OfferFacilititesProvider };
