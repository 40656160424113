import RDROFacility from '../RDROServiceFacility/components/RDROFacility';
import RIOService from './components/RIOService';

const RIOServiceFacility = () => {
  return (
    <>
      <RIOService />
      <RDROFacility />
    </>
  );
};

export default RIOServiceFacility;
