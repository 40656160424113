import { Col, Form, Select } from 'antd';

import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';
import {
  getServiceDropdownValueByName,
  getServiceLabelTextDropdown,
  setTitleData,
} from 'features/Offer/utils';

const ServicesDropdown = () => {
  const { currentOfferServices } = useOfferServicesContext();

  return (
    <>
      <Col span={24}>
        <Form.Item
          colon
          labelAlign="right"
          label="บริการ"
          name="raoServId"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[{ required: true, message: 'กรุณาเลือกบริการ' }]}
        >
          <Select
            allowClear
            labelInValue
            mode="multiple"
            placeholder="-- กรุณาเลือกบริการ --"
            options={
              currentOfferServices
                ? currentOfferServices.map((item) => {
                    return {
                      value: getServiceDropdownValueByName(item),
                      label: getServiceLabelTextDropdown(item),
                      title: setTitleData(item),
                    };
                  })
                : []
            }
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default ServicesDropdown;
