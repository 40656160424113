import React, { ReactNode } from 'react';

interface Children {
  children: ReactNode;
}

const LayoutComponent = (props: Children) => {
  return <div>{props.children}</div>;
};

export default LayoutComponent;
