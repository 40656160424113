import { useCallback, useEffect, useState } from 'react';

import { Col, Form, Row } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { formLayout } from 'constants/form';
import { IContAttachFiles } from 'features/Contract/interfaces';
import useFileManagement from 'features/Contract/hooks/useFileManagement';

import { useApi, TApiResponse } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import { listCONTDocType } from 'api/contract';
import { uploadAttachmentFile } from 'api/contract';

import useContractDataContext from 'features/Contract/hooks/useContractDataContext';
import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';

import PrimaryButton from 'components/PrimaryButton';
import DocTypeDropdown from '../DocTypeDropdown';
import UploadButton from '../UploadButton';
import ButtonGroupAttachmentTab from '../ButtonGroupAttachmentTab';
import UploadedFilesListTable from '../UploadedFilesListTable';

import { CONTRACT_TAB } from 'features/Contract/constant';

type UploadAttachmentProps = {
  isDisabled: boolean;
  conFormId: number;
  contAttachFiles: IContAttachFiles[];
  onGetContractById: Function;
  stateCode: number;
  setIsUpdatingState: React.Dispatch<React.SetStateAction<boolean>>;
};

const UploadAttachment = ({
  isDisabled,
  conFormId,
  contAttachFiles,
  onGetContractById,
  stateCode,
  setIsUpdatingState,
}: UploadAttachmentProps) => {
  const { validateContractPage, setActiveTab } = useContractDataContext();
  const { currentContServes } = useContractServicesContext();
  const [uploadFormRef] = Form.useForm();
  const { error } = useDialogAlert();
  const [isOpenFileDialog, setIsOpenFileDialog] = useState(false);
  const [docId, setDocId] = useState<number | undefined>(undefined);
  const [docName, setDocName] = useState<string>('');
  const [selectedDocTypeOption, setSelectedDocTypeOption] = useState<any>(undefined);
  const {
    fileList,
    onRemoveFile,
    setFileList,
    handleUpload,
    isUploadingFile,
    setCurrentUploadFile,
    downloadFile,
    isDeletingFile,
    sortRequiredDoc,
    validateRequiredFiles,
  } = useFileManagement();

  const {
    makeRequest: _listContractFiles,
    data: uploadedFileList,
    loading: isLoadingFileList,
  }: TApiResponse = useApi();

  const onValidateDocType = useCallback(() => {
    try {
      uploadFormRef.validateFields(['docId']);
    } catch (error) {
      console.error(error);
    }
  }, [uploadFormRef]);

  const onUploadFile = useCallback(async () => {
    if (docId) {
      await handleUpload({
        docId,
        docName,
        required: selectedDocTypeOption.required,
        id: conFormId,
        api: uploadAttachmentFile,
      });
      uploadFormRef.setFieldValue('docId', undefined);
      setDocId(undefined);
      setSelectedDocTypeOption(undefined);
    }
  }, [docId, docName, handleUpload, uploadFormRef, conFormId, selectedDocTypeOption]);

  const onConfirmDoc = async (callback: Function) => {
    try {
      await validateContractPage({ currentContServes });
      const isDocValid = validateRequiredFiles();
      if (isDocValid !== -1)
        error({
          header: 'กรุณาแนบไฟล์ที่จำเป็นให้ครบ',
          sub: '',
        });
      else {
        callback();
      }
    } catch (errorInfo: any) {
      setActiveTab(CONTRACT_TAB);
      return error({
        header: errorInfo ? errorInfo.message : 'กรุณากรอกฟอร์มให้ครบถ้วน',
        sub: '',
      });
    }
  };

  useEffect(() => {
    // NOTE: กรณีที่เคยอัพโหลด doc เสร็จแล้วต้อง check doctype ใหม่
    if (docId === undefined) {
      setIsOpenFileDialog(false);
    }
  }, [docId, setIsOpenFileDialog]);

  useEffect(() => {
    if (uploadedFileList) {
      const sortedData = sortRequiredDoc(uploadedFileList);
      setFileList(sortedData);
    }
  }, [setFileList, uploadedFileList, sortRequiredDoc]);

  useEffect(() => {
    if (contAttachFiles && contAttachFiles.length > 0) {
      const sortedData = sortRequiredDoc(contAttachFiles);
      setFileList(sortedData);
    } else {
      /**
       * NOTE:
       * ถ้าไม่มีข้อมูลใน contAttachFiles ให้ดึงข้อมูลจาก API มาแสดง
       * ทีแรก API ไม่ return required file มาให้เลยต้อง fetch required file เอง
       * ถ้าต้องแก้ ต้องเช็ค API เส้น listCONTDocType อีกที
       */
      _listContractFiles(() => listCONTDocType());
    }
  }, [_listContractFiles, contAttachFiles, setFileList, sortRequiredDoc]);

  return (
    <div style={{ marginTop: 15 }}>
      <Form
        {...formLayout}
        labelWrap
        labelAlign="left"
        name="uploadAttachmentForm"
        form={uploadFormRef}
      >
        <Row gutter={[16, 16]}>
          <Col span={15}>
            <DocTypeDropdown
              isDisabled={isDisabled}
              setDocId={setDocId}
              setDocName={setDocName}
              setIsOpenFileDialog={setIsOpenFileDialog}
              api={listCONTDocType}
              setSelectedDocTypeOption={setSelectedDocTypeOption}
            />
          </Col>
          <Col span={7}>
            <UploadButton
              handleUpload={onUploadFile}
              setCurrentUploadFile={setCurrentUploadFile}
              isOpenFileDialog={isOpenFileDialog}
              isDisabled={isDisabled}
            >
              <PrimaryButton
                icon={<UploadOutlined />}
                disabled={isDisabled}
                onClick={() => {
                  onValidateDocType();
                }}
              >
                อัพโหลดเอกสาร
              </PrimaryButton>
            </UploadButton>
          </Col>
          <Col span={24}>
            <UploadedFilesListTable
              isLoading={isLoadingFileList || isUploadingFile || isDeletingFile}
              isDisabled={isDisabled}
              onRemoveFile={onRemoveFile}
              downloadFile={downloadFile}
              fileList={fileList}
              handleUpload={handleUpload}
              setCurrentUploadFile={setCurrentUploadFile}
              conFormId={conFormId}
            />
          </Col>
        </Row>
      </Form>
      <ButtonGroupAttachmentTab
        isDisabled={isDisabled}
        conFormId={conFormId}
        stateCode={stateCode}
        onGetContractById={onGetContractById}
        onConfirmDoc={onConfirmDoc}
        setIsUpdatingState={setIsUpdatingState}
      />
    </div>
  );
};

export default UploadAttachment;
