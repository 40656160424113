import { useEffect } from 'react';
import { Button, Col, Form, FormInstance, Input, Modal, Row, Spin } from 'antd';

import { formLayout } from 'constants/form';

type PublishLinkModalProps = {
  isModalOpen: boolean;
  handleCancel: Function;
  modalFormRef: FormInstance<any>;
  isUpdating: boolean;
  onUpdateLink: Function;
  modalTitle: string;
  linkFieldName: string;
  selectedRecord: any;
  setValueFieldName?: string;
};

const PublishLinkModal = ({
  modalTitle,
  isModalOpen,
  handleCancel,
  modalFormRef,
  isUpdating,
  onUpdateLink,
  linkFieldName,
  selectedRecord,
  setValueFieldName,
}: PublishLinkModalProps) => {
  const onHandleCancel = () => {
    modalFormRef.resetFields(); // NOTE: Reset form to initial state
    handleCancel();
  };

  useEffect(() => {
    if (selectedRecord) {
      modalFormRef.setFieldsValue({
        [linkFieldName]: selectedRecord[setValueFieldName ? setValueFieldName : linkFieldName],
      });
    }
  }, [selectedRecord, modalFormRef, linkFieldName, setValueFieldName]);

  return (
    <Modal
      destroyOnClose
      width="80vh"
      footer={false}
      title={modalTitle}
      open={isModalOpen}
      onCancel={() => onHandleCancel()}
    >
      <Spin spinning={isUpdating}>
        <Form
          labelWrap
          name="publishLinkModal"
          size="large"
          form={modalFormRef}
          onFinish={(values: any) => onUpdateLink(values)}
          {...formLayout}
        >
          <Row style={{ marginTop: 15 }}>
            <Col span={20}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                name={linkFieldName}
                rules={[{ required: true, message: 'กรุณากรอก Link Web' }]}
              >
                <Input className="no-right-radius" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button type="primary" htmlType="submit" className="button no-left-radius">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default PublishLinkModal;
