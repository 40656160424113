import { Outlet } from 'react-router-dom';
// import { Desktop } from '../utils/displayResponsive';
import { SiderComponent } from 'components/SiderComponent/SiderComponent';
import Layout from 'components/LayoutComponent/LayoutComponent';
import PortalGuard from './Guards/PortalGuard';

const PortalLoginLayout = () => {
  return (
    <PortalGuard>
      <div className="background-log">
        {/* <Desktop> */}
          <SiderComponent>
            <Layout>
              <Outlet />
            </Layout>
          </SiderComponent>
        {/* </Desktop> */}
      </div>
    </PortalGuard>
  );
};

export default PortalLoginLayout;
