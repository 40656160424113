import { Form, Row, Col, Input } from 'antd';
import { useEffect } from 'react';

import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';
import { formLayout } from 'constants/form';
import { IStepModalForm } from './interfaces';
import { ThaiDatepickerComponent } from 'components';

interface IStepFormWithDate extends IStepModalForm {
  dateLabel?: string;
}

const StepFormWithDate = ({
  form,
  stepData,
  isDisable,
  onUpdateOfferState,
  isHideDateForm,
  dateLabel,
}: IStepFormWithDate) => {
  const onFinish = (values: any) => {
    let data: any = {
      formStateId: stepData?.formStateId,
      stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: '',
      actionName: '',
      approvedNo: '',
      approvedDate: null,
      isCompleteState: false,
      isCurrentState: true,
    };
    if (!isHideDateForm) {
      let date = new Date(values.actionDate);
      data.actionDate = dayJsformatDateObjToString(date);
    }
    onUpdateOfferState(data);
  };

  useEffect(() => {
    if (stepData) {
      form.setFieldsValue({
        stateName: stepData.stateName,
        actionDate: stepData.actionDate ? getJSDateObj(stepData.actionDate) : undefined,
      });
    }
  }, [form, stepData]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      form={form}
      name="stepModalForm"
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะข้อเสนอ" name="stateName">
            <Input size="large" disabled />
          </Form.Item>
        </Col>
        {!isHideDateForm && (
          <Col span={24}>
            <Form.Item
              colon
              label={dateLabel || 'วันที่รับเรื่อง'}
              name="actionDate"
              rules={[{ required: true, message: `กรุณาเลือกวันที่` }]}
            >
              <ThaiDatepickerComponent disabled={isDisable} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default StepFormWithDate;
