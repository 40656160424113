import { useApi } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';

type useCreateReportProps = {
  formRef: any;
  createReportApi: Function;
};

const useCreateReport = ({ formRef, createReportApi }: useCreateReportProps) => {
  const { error, success } = useDialogAlert();
  const { makeRequest, loading: isCreatingReport } = useApi();

  const onCreateReport = async ({ values, callback }: { values: any; callback: Function }) => {
    try {
      await makeRequest(() => createReportApi(values));
      if (callback) callback();
      success({ header: 'จัดทำรายงานสำเร็จ', sub: '' });
      formRef.resetFields();
    } catch (e) {
      console.error('e', e);
      error({
        header: 'ไม่สามารถจัดทำรายงานได้',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };
  return { onCreateReport, isCreatingReport };
};

export default useCreateReport;
