import { EditOutlined, FileOutlined, RollbackOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { NavigateFunction } from 'react-router-dom';
import { dayJsformatDateToBCYear } from 'utils/date';

const IMPROVEABLE_STATUS = '18';

export const tableColumns = (
  navigator: NavigateFunction,
  page: number,
  pageSize: number,
  onModalOpenOffer: (val: any, isImproveAndAssign?: boolean) => void,
  onModalOpenHistory: (id: number | string) => void
) => {
  const columns: ColumnsType<ILestOfferItem> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'ประเภทข้อเสนอ',
      dataIndex: 'offerFormTypeName',
      key: 'offerFormTypeName',
      align: 'center',
      width: 100,
    },
    {
      title: 'กลุ่มข้อเสนอ',
      dataIndex: 'offerFormGroupName',
      key: 'offerFormGroupName',
      align: 'center',
      width: 100,
    },
    {
      title: 'ผู้ประกอบการ',
      dataIndex: 'operName',
      key: 'operName',
      align: 'center',
      width: 100,
    },
    {
      title: 'เลขที่/วันที่หนังสือผู้ประกอบการ',
      key: 'offerFormNo',
      align: 'center',
      width: 100,
      render: (_, record) => {
        const offerFormNo = record.offerFormNo;
        const offerFormDate = dayJsformatDateToBCYear(record.offerFormDate);
        return (
          <>
            {offerFormNo} {offerFormDate ? '/' + offerFormDate : ''}
          </>
        );
      },
    },
    {
      title: 'สถานะการจัดทำข้อเสนอ',
      dataIndex: 'offerFormStatusName',
      key: 'offerFormStatusName',
      align: 'center',
      width: 100,
    },
    {
      title: 'ผู้ได้รับมอบหมาย',
      dataIndex: 'offerResponsibilityUser',
      key: 'offerResponsibilityUser',
      align: 'center',
      width: 100,
    },
    {
      title: 'จำนวนครั้งที่แก้ไข',
      dataIndex: 'numOfImprove',
      key: 'numOfImprove',
      align: 'center',
      width: 100,
      render: (_, record) => {
        return <>{record.numOfImprove ? record.numOfImprove : 0}</>;
      },
    },
    {
      title: 'ประวัติรายการ',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>
          <FileOutlined
            className="file-outlined-css"
            onClick={() => onModalOpenHistory(record.offerFormId)}
          />
        </div>
      ),
    },
    {
      title: 'การมอบหมาย',
      key: 'no',
      align: 'center',
      width: 100,
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }}>
          <EditOutlined className="file-outlined-css" onClick={() => onModalOpenOffer(record)} />
        </div>
      ),
    },
    {
      title: 'ปรับปรุงข้อเสนอและมอบหมายงาน',
      key: 'no',
      align: 'center',
      width: 100,
      render: (_, record) => {
        if (record?.offerFormStatus === IMPROVEABLE_STATUS)
          return (
            <div style={{ cursor: 'pointer' }}>
              <RollbackOutlined
                className="file-outlined-css"
                onClick={() => onModalOpenOffer(record, true)}
              />
            </div>
          );
      },
    },
  ];
  return columns;
};
