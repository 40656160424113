import axiosInstance from 'utils/axios';
import {
  ImproveAndAssignContractData,
  ListContractAssignQuery,
  SaveContractAssigneesData,
} from './interface';

// NOTE: LIST
export const listContractAssign = (query: ListContractAssignQuery) => {
  return axiosInstance.get(`/contract/assign`, {
    params: query,
  });
};

export const listContractAssignees = (conFormId: string | number) => {
  return axiosInstance.get(`/contract/assign/${conFormId}`);
};

// NOTE: POST
export const saveContractAssignees = (data: SaveContractAssigneesData) => {
  return axiosInstance.post(`/contract/assign/upsert`, data);
};

export const improveAndAssignContract = (data: ImproveAndAssignContractData) => {
  return axiosInstance.post(`/contract/improveAndAssign`, data);
};
