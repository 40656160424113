import { useEffect, useState, useCallback } from 'react';
import { Form, Row, Col, Button, Upload, Divider, Table, Spin, Modal } from 'antd';
import type { UploadProps } from 'antd';
import { UploadOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/es/upload';

import { useApi, TApiResponse } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import useFileManagement from 'features/Contract/hooks/useFileManagement';

import { listStateFile, uploadStateFile, deleteStateFile } from 'api/review';

import { formLayout } from 'constants/form';
import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';

import buttonCSS from 'styles/components/button.module.css';

import DocumentTypeDropdown from '../../DocumentTypeDropdown';
import { IStepModalForm } from '../interfaces';
import { getFileTableColumn } from './columns';
import DetailForm from './DetailForm';

const { confirm } = Modal;

export interface FileListDataType {
  attachFileId: number;
  docId: number;
  docName: string;
  docType: string;
  attachFileName: string;
  required: boolean;
  offerStateNotifyEmail: {
    numberOfSent: number;
    updatedDate: string;
    notifyFlag: boolean;
  };
}

interface IStepFormWithApproveFile extends IStepModalForm {
  dateLabel?: string;
}

const StepFormWithApproveFile = ({
  form,
  stepData,
  isDisable,
  onUpdateOfferState,
  isHideApproveForm,
  dateLabel,
  isShowActionDropdown = false,
}: IStepFormWithApproveFile) => {
  const [uploadForm] = Form.useForm();
  const { error, success } = useDialogAlert();
  const { downloadFile } = useFileManagement();

  const {
    makeRequest: _listStateFile,
    data: uploadedFileList,
    loading: isLoadingFileList,
  }: TApiResponse = useApi();
  const { makeRequest: _deleteStateFile, loading: isDeletingFile }: TApiResponse = useApi();
  const { makeRequest }: TApiResponse = useApi();

  const [currentUploadFile, setCurrentUploadFile] = useState<UploadFile>();
  const [isUploadingFile, setUploadingFile] = useState(false);
  const [fileList, setFileList] = useState<FileListDataType[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  // const [selectedActionCode, setSelectedActionCode] = useState<string | undefined>();

  const handleUpload = async () => {
    const formData = new FormData();

    let docName = await uploadForm.getFieldValue('docName');
    let docId = await uploadForm.getFieldValue('docId');

    const metaData = new Blob(
      [JSON.stringify({ docId, docName: docName.children, required: true })],
      {
        type: 'application/json',
      }
    );

    formData.append('attachment', currentUploadFile as RcFile);
    formData.append('metaData', metaData);
    setUploadingFile(true);

    try {
      if (stepData) {
        const response = await makeRequest(() => uploadStateFile(stepData.formStateId, formData));
        setFileList([...fileList, response.data]);
        await uploadForm.setFieldValue('docId', undefined);
        setUploadingFile(false);
        success({ header: 'อัพโหลดสำเร็จ', sub: '' });
      }
    } catch (errorInfo) {
      setUploadingFile(false);
      error({
        header: 'อัพโหลดไฟล์ไม่สำเร็จ',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  const handleRemoveFile = useCallback(
    async (attachFileId: number | string) => {
      try {
        await _deleteStateFile(() => deleteStateFile(attachFileId));
        let newFileList = fileList.filter((file) => {
          return file.attachFileId !== attachFileId;
        });
        setFileList(newFileList);
        success({ header: 'ลบไฟล์สำเร็จ', sub: '' });
      } catch (errorInfo) {
        error({
          header: 'ลบไฟล์ไม่สำเร็จ',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [fileList, _deleteStateFile, error, success]
  );

  const onRemoveFile = useCallback(
    (attachFileId: number | string) => {
      confirm({
        title: 'ต้องการลบไฟล์หรือไม่ ?',
        icon: <ExclamationCircleFilled />,
        content: 'Some descriptions',
        okText: 'ลบ',
        okType: 'danger',
        cancelText: 'ยกเลิก',
        onOk() {
          handleRemoveFile(attachFileId);
        },
        onCancel() {},
      });
    },
    [handleRemoveFile]
  );

  const onFinish = (values: any) => {
    let _actionDate = new Date(values.actionDate);

    let data: any = {
      formStateId: stepData?.formStateId,
      stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: '',
      actionName: '',
      actionDate: dayJsformatDateObjToString(_actionDate),
      isCompleteState: false,
      isCurrentState: true,
    };

    if (!isHideApproveForm) {
      let _approvedDate = new Date(values.approvedDate);
      data.approvedDate = dayJsformatDateObjToString(_approvedDate);
      data.approvedNo = values.approvedNo;
    }

    if (isShowActionDropdown) {
      data.actionCode = values.actionCode;
      data.actionName = values.actionName?.label;
    }
    onUpdateOfferState(data);
  };

  const uploadProps: UploadProps = {
    name: 'file',
    customRequest: () => handleUpload(),
    beforeUpload: async (file) => {
      try {
        await uploadForm.validateFields(['docId']);
        setCurrentUploadFile(file);
        return true;
      } catch (errorInfo) {
        return false;
      }
    },
    showUploadList: false,
  };

  useEffect(() => {
    if (stepData) {
      _listStateFile(() => listStateFile(stepData.formStateId));
    }
  }, [stepData, _listStateFile]);

  useEffect(() => {
    if (stepData) {
      form.setFieldsValue({
        stateName: stepData?.stateName,
        actionDate: stepData.actionDate ? getJSDateObj(stepData.actionDate) : undefined,
        approvedNo: stepData.approvedNo,
        approvedDate: stepData.approvedDate ? getJSDateObj(stepData.approvedDate) : undefined,
      });

      if (
        stepData.actionCode === '' ||
        stepData.actionCode === undefined ||
        stepData.actionCode === null
      ) {
        form.setFieldsValue({
          actionCode: undefined,
        });
        // setSelectedActionCode(undefined);
      } else {
        form.setFieldsValue({
          actionCode: stepData.actionCode,
        });
        // setSelectedActionCode(stepData.actionCode);
      }
    }
  }, [form, stepData]);

  useEffect(() => {
    setFileList(uploadedFileList);
  }, [uploadedFileList]);

  return (
    <>
      <Form
        {...formLayout}
        labelWrap
        labelAlign="left"
        form={form}
        name="stepModalForm"
        onFinish={onFinish}
      >
        <DetailForm
          isDisable={isDisable}
          isHideApproveForm={isHideApproveForm}
          isShowActionDropdown={isShowActionDropdown}
          form={form}
          dateLabel={dateLabel}
          // setSelectedActionCode={setSelectedActionCode}
        />
      </Form>
      <Divider orientation="left" orientationMargin="0">
        อัพโหลดไฟล์
      </Divider>
      <Form {...formLayout} labelWrap labelAlign="left" form={uploadForm} name="stepModalForm">
        <Spin tip="กำลังอัพโหลดไฟล์..." spinning={isUploadingFile || isDeletingFile}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <p style={{ margin: '-10px 0 -5px 0', color: 'red', fontSize: '16px' }}>
                รายละเอียดการแนบเอกสาร: สามารถแนบไฟล์ (pdf, word, excel) ครั้งละ 1 ไฟล์
                ไฟล์ละไม่เกิน 20 MB
              </p>
            </Col>
            <Col span={15}>
              <DocumentTypeDropdown
                form={uploadForm}
                isDisable={isDisable}
                state={stepData?.stateCode}
              />
            </Col>
            <Col span={7}>
              <Upload {...uploadProps}>
                <Button
                  type="primary"
                  className={buttonCSS.primary}
                  icon={<UploadOutlined />}
                  size="large"
                  shape="round"
                  disabled={isDisable}
                >
                  อัพโหลดเอกสาร
                </Button>
              </Upload>
            </Col>
            <Col span={24}>
              <Table
                bordered
                size="small"
                loading={isLoadingFileList}
                columns={getFileTableColumn({
                  isDisable,
                  handleRemoveFile: onRemoveFile,
                  downloadFile,
                  currentPageNumber,
                  pageSize,
                })}
                dataSource={fileList}
                rowKey={'attachFileId'}
                pagination={{
                  onChange: (page, pageSize) => {
                    setPageSize(pageSize);
                    setCurrentPageNumber(page - 1);
                  },
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  showSizeChanger: true,
                  defaultPageSize: pageSize,
                }}
              />
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  );
};

export default StepFormWithApproveFile;
