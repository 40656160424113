/* eslint-disable */
import { ButtonComponent, HeaderTitleComponent } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Table, Upload, UploadProps } from 'antd';
import { DeleteOutlined, DownloadOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import useAuth from 'hooks/useAuth';
import { useForm } from 'antd/es/form/Form';
import { RcFile, UploadFile } from 'antd/es/upload';
import { useApi } from 'hooks/useApi';
import { deleteFile, downloadFileById, editUploadFile, getFileList, uploadFile } from 'api/formDocument';
import useDialogAlert from 'hooks/useDialogAlert';

interface FileData {
  fileName: string;
  attachFileName: string;
  isAllowOperator: boolean;
  download: (id: number, name: string) => void;
  edit: (id: number, name: string, isAllowOperator: boolean) => void;
  delete: (id: number) => void;
  id: number;
}

const columns: ColumnsType<FileData> = [
  {
    title: 'ชื่อเอกสาร',
    dataIndex: 'fileName',
    key: 'fileName',
  },
  {
    title: 'ดาวน์โหลด',
    dataIndex: 'download',
    key: 'download',
    width: '140px',
    align: 'center',
    render: (value, record, index) => <div>
      <Button icon={<DownloadOutlined />} onClick={() => record.download(record.id, record.attachFileName)}>

      </Button>
    </div>,
  },
];

const columnsForAdmin: ColumnsType<FileData> = [
  {
    title: 'ชื่อเอกสาร',
    dataIndex: 'fileName',
    key: 'fileName',
  },
  {
    title: 'ดาวน์โหลด',
    dataIndex: 'download',
    key: 'download',
    width: '100px',
    align: 'center',
    render: (value, record, index) => <div>
      <Button icon={<DownloadOutlined />} onClick={() => record.download(record.id, record.attachFileName)}>

      </Button>
    </div>,
  },
  {
    title: 'แก้ไข',
    dataIndex: 'edit',
    key: 'edit',
    width: '100px',
    align: 'center',
    render: (value, record, index) => <div>
      <Button icon={<EditOutlined />} onClick={() => record.edit(record.id, record.fileName, record.isAllowOperator)}>

      </Button>
    </div>,
  },
  {
    title: 'ลบ',
    dataIndex: 'delete',
    key: 'delete',
    width: '100px',
    align: 'center',
    render: (value, record, index) => <div>
      <Button icon={<DeleteOutlined />} onClick={() => record.delete(record.id)}>

      </Button>
    </div>,
  },
];

const CONFIG_FORM: Record<string, string[]> = {
  create: ['ADMIN'],
  view: ['OPERATOR', 'OFF_OFFER_LDR', 'OFF_OFFER', 'OFF_DIS_LDR', 'OFF_DIS', 'OFF_SEN_LDR', 'OFF_SEN', 'OFF_CONTC_LDR', 'OFF_CONTC', 'OFF_NBTC', 'OFF_DIR'],
};

const FormDownload = (): JSX.Element => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [data, setData] = useState<FileData[]>([]);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const { makeRequest: uploadRequest, error: uploadError } = useApi();
  const { makeRequest: getFileListRequest } = useApi();
  const { makeRequest: updateFileRequest, error: updateError } = useApi();
  const { makeRequest: downloadFileRequest } = useApi();
  const { makeRequest: deleteRequest } = useApi();
  const { user } = useAuth();
  const [form] = useForm();
  const { error } = useDialogAlert();
  const [editState, setEditState] = useState<{ id: number, fileName: string }>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (uploadError) {
      if (uploadError?.errors[0] == 'Error occur when file name already exist') {
        setUploading(false);
        error({ header: 'ชื่อเอกสารนี้ถูกใช้งานแล้ว', sub: 'กรุณาเปลี่ยนชื่อเอกสารแล้วลองใหม่อีกครั้ง' });
      }
    }
  }, [uploadError]);

  useEffect(() => {
    if (updateError) {
      if (updateError?.errors[0] == 'Error occur when file name already exist') {
        setUploading(false);
        error({ header: 'ชื่อเอกสารนี้ถูกใช้งานแล้ว', sub: 'กรุณาเปลี่ยนชื่อเอกสารแล้วลองใหม่อีกครั้ง' });
      }
    }
  }, [updateError]);

  const validateForm = () => {
    setDisable(!form.getFieldValue('name'));
  };

  const onDownload = async (id: number, attachFileName: string) => {
    await downloadFileRequest(async () => {
      const res = await downloadFileById(id);
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return res;
    });

  };

  const onEdit = (id: number, fileName: string, isAllowOperator: boolean) => {
    setEditState({ id, fileName });
    setDisable(false);
    form.setFields([{
      name: 'name',
      value: fileName,
    }, {
      name: 'isAllowOperator',
      value: isAllowOperator,
    }]);
    setFileList([]);
    setOpenEdit(true);
  };

  const onDelete = (id: number) => {
    deleteRequest(async () => {
      const res = await deleteFile(id);
      await updateFileList();
      return res;
    });
  };

  const showModal = () => {
    form.setFields([{
      name: 'name',
      value: '',
    }, {
      name: 'isAllowOperator',
      value: false,
    }]);
    setDisable(true);
    setFileList([]);
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleEditCancel = () => {
    setOpenEdit(false);
  };

  const updateFileList = async () => {
    getFileListRequest(async () => {
      const res = await getFileList();
      setData(res.data.data.map(i => {
        return {
          fileName: i.fileName,
          attachFileName: i.attachFileName,
          isAllowOperator: i.isAllowOperator,
          download: (id: number, name: string) => {
            onDownload(id, name);
          },
          edit: (id: number, name: string, isAllowOperator: boolean) => {
            onEdit(id, name, isAllowOperator);
          },
          delete: (id: number) => {
            onDelete(id);
          },
          id: i.sampleDataFileId,
        };
      }));
    });
  };

  useEffect(() => {
    updateFileList();
  }, []);

  const hasPermission = useCallback((name: string) => {
    if (user?.roles) {
      const result = user.roles.some(i => CONFIG_FORM[name].some(k => k === i));
      return result;
    }
    return false;
  }, [user]);

  const handleUpload = () => {
    setUploading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });
    try {
      uploadRequest(async () => {
        const res = await uploadFile(fileList[0], form.getFieldValue('name'), form.getFieldValue('isAllowOperator'));
        setUploading(false);
        setOpen(false);
        updateFileList();
        return res;
      });
    } catch (e) {
      error({ header: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', sub: '' });
      setUploading(false);
    }
  };

  const handleEditUpload = () => {
    setUploading(true);
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });
    try {
      if (editState) {
        updateFileRequest(async () => {
          const res = await editUploadFile(editState.id, fileList[0], form.getFieldValue('name'), form.getFieldValue('isAllowOperator'));
          setUploading(false);
          setOpenEdit(false);
          updateFileList();
          return res;
        });
      }
    } catch (e) {
      error({ header: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง', sub: '' });
      setUploading(false);
    }
  };

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  useEffect(() => {
    setHeaderTitle('แบบฟอร์ม');
  }, [user]);
  return <div>
    {hasPermission('view') && <div>
      <HeaderTitleComponent>ดาวน์โหลดแบบฟอร์ม</HeaderTitleComponent>

      <h2 style={{ marginTop: '24px' }}>
        รายการเอกสาร
      </h2>
      <br />
      <Table rowKey={'id'} columns={columns} dataSource={data} pagination={false} />
    </div>}


    {hasPermission('create') && <div>
      <HeaderTitleComponent>จัดการแบบฟอร์ม</HeaderTitleComponent>
      <ButtonComponent style={{ marginTop: '24px' }} onClick={showModal}>เพิ่มรายการเอกสาร</ButtonComponent>
      <Table style={{ marginTop: '24px' }} rowKey={'id'} columns={columnsForAdmin} dataSource={data}
             pagination={false} />

    </div>}

    <Modal
      open={open}
      title='เพิ่มรายการเอกสาร'
      onCancel={handleCancel}
      footer={[
        <ButtonComponent type={'default'} key='back' onClick={handleCancel}>
          ยกเลิก
        </ButtonComponent>,
        <ButtonComponent onClick={handleUpload}
                         disabled={fileList.length === 0 || disable}
                         loading={uploading} key='submit' type='primary'>
          บันทึก
        </ButtonComponent>,
      ]}
    >
      <Form form={form} onValuesChange={() => {
        validateForm();
      }
      }>
        <Row gutter={[16, 16]}>
          <Col>
            <Form.Item name={'name'} label={'ชื่อรายการเอกสาร'}>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='isAllowOperator' valuePropName='checked'>
              <Checkbox>เอกสารสำหรับผู้ประกอบการ</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ marginTop: '24px' }}>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </div>
      </Form>
    </Modal>

    <Modal
      open={openEdit}
      title='แก้ไขรายการเอกสาร'
      onCancel={handleCancel}
      closable={false}
      footer={[
        <ButtonComponent type={'default'} key='back' onClick={handleEditCancel}>
          ยกเลิก
        </ButtonComponent>,
        <ButtonComponent onClick={handleEditUpload}
                         disabled={disable}
                         loading={uploading} key='submit' type='primary'>
          บันทึก
        </ButtonComponent>,
      ]}
    >
      <Form form={form}>
        <Row gutter={[16, 16]}>
          <Col>
            <Form.Item name={'name'} label={'ชื่อรายการเอกสาร'}>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name='isAllowOperator' valuePropName='checked'>
              <Checkbox>เอกสารสำหรับผู้ประกอบการ</Checkbox>
            </Form.Item>
          </Col>
        </Row>

        <div style={{ marginTop: '24px' }}>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>เลือกไฟล์</Button>
          </Upload>
        </div>
      </Form>
    </Modal>
  </div>;
};
export default FormDownload;
