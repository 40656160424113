/* eslint-disable */
import { Button, Col, Form, Row, Select, Table, Upload, UploadProps } from 'antd';
import { CloseOutlined, DeleteFilled, DownloadOutlined, HourglassOutlined, UploadOutlined } from '@ant-design/icons';
import buttonCSS from 'styles/components/button.module.css';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { ColumnsType } from 'antd/es/table';
import { UploadFile } from 'antd/es/upload';
import {
  deleteAttachFileOfficer,
  downloadAttachFileByIdOfficer,
  getUsageFormOfficer,
  getUsageTypeOfficer,
  uploadAttachFileOfficer,
} from 'api/usageForm';
import { GetUsageFormResponse, UsageFormType } from 'api/usageForm/interface';

interface DataType {
  key: React.Key;
  id: number;
  type: string;
  formStatus: number;
  status: string;
  attachFile: string;
  considerDetail: string;
  considerResult: string;
  onDownloadClick: () => void;
  onDeleteClick: () => void;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ดาวน์โหลดเอกสาร',
    dataIndex: 'id',
    width: '180px',
    align: 'center',
    render: (_, record) => <div>
      <Button disabled={record.status !== 'COMPLETED'} onClick={record.onDownloadClick}>
        {record.status !== 'COMPLETED' && record.status !== 'FAILED' && <HourglassOutlined />}
        {record.status == 'COMPLETED' && <DownloadOutlined />}
        {record.status == 'FAILED' && <CloseOutlined />}
      </Button>
    </div>,
  },
  {
    title: 'ลำดับที่',
    dataIndex: 'id',
    align: 'center',
    width: '100px',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'กลุ่มเอกสาร',
    dataIndex: 'type',
  },
  {
    title: 'ผลการพิจารณา',
    dataIndex: 'type',
    width: '150px',
    align: 'center',
    render: (item, record, index) => {
      return <div style={{
        wordBreak: 'break-all',
      }}>{record.considerResult}</div>;
    },
  },
  {
    title: 'รายละเอียดผลการพิจารณา',
    dataIndex: 'type',
    render: (item, record, index) => {
      return <div style={{
        wordBreak: 'break-all',
      }}>{record.considerDetail}</div>;
    },
  },
  {
    title: 'รายการเอกสาร',
    dataIndex: 'attachFile',
    width: '200px',
  },
  {
    title: 'ลบ',
    key: 'delete',
    width: '100px',
    render: (_, record) => {
      return <Button
        disabled={record.formStatus == 2 || record.formStatus == 4 || record.considerResult === 'ผ่าน'}
        onClick={record.onDeleteClick}>
        <DeleteFilled disabled={record.formStatus == 2 || record.formStatus == 4 || record.considerResult === 'ผ่าน'} />
      </Button>;
    },
  },
];

interface FormFileUploadProp {
  id: number;
  status: number;
  disabledUsageTypes: number[];
  onRefresh: () => void;
}

const FormFilesUpload = ({ id, status, disabledUsageTypes, onRefresh }: FormFileUploadProp): JSX.Element => {
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { makeRequest: GetDoc } = useApi();
  const { makeRequest: UploadFile } = useApi();
  const { makeRequest: GetAttachFileList } = useApi();
  const { makeRequest: DownloadAttachFileList } = useApi();
  const { makeRequest: DeleteAttachFileList } = useApi();
  const [disableUpload, setDisableUpload] = useState(true);
  const [data, setData] = useState<DataType[]>([]);
  const [uploading, setUploading] = useState(false);
  const [options, setOptions] = useState<{
    label: string,
    value: number,
    disabled: boolean
  }[]>();

  const onSelectChange = () => {
    setDisableUpload(false);
  };


  const uploadFile = async (file: any) => {
    UploadFile(async () => {
      setUploading(true);
      const formData = new FormData();
      formData.append('attachment', file);
      const res = await uploadAttachFileOfficer(id, form.getFieldValue('usageTypeId'), formData).catch(() => setUploading(false));
      await updateAttachFileList();
      onRefresh();
      setUploading(false);
      return res;
    });
  };

  const props: UploadProps = {
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    onChange: (file) => {
      uploadFile(file.file);
    },
    showUploadList: false,
    fileList,
  };

  const updateAttachFileList = async () => {
    GetAttachFileList(async () => {
      const res: any = await getUsageFormOfficer(id);
      const list: GetUsageFormResponse = res.data;
      setData(list.data.usageAttachFiles.map(i => {
        return {
          key: i.formId,
          id: i.formId,
          status: i.status,
          formStatus: status,
          considerResult: i.considerResult,
          considerDetail: i.considerDetail,
          type: i.usageTypeName,
          attachFile: i.attachFileName,
          onDeleteClick: async () => {
            DeleteAttachFileList(async () => {
              const res = await deleteAttachFileOfficer(i.formId);
              await updateAttachFileList();
              onRefresh();
              return res;
            });
          },
          onDownloadClick: async () => {
            DownloadAttachFileList(async () => {
              const res = await downloadAttachFileByIdOfficer(i.formId);
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', i.attachFileName);
              document.body.appendChild(link);
              link.click();
              link.remove();
              return res;
            });
          },
        };
      }));
      return res;
    });
  };

  useEffect(() => {
    updateAttachFileList();
  }, [status]);

  useEffect(() => {
    GetDoc(async () => {
      const res: any = await getUsageTypeOfficer();
      const docs: UsageFormType[] = res.data.data;
      setOptions(docs.map(i => {
        return {
          label: i.description,
          value: i.usageTypeId,
          disabled: disabledUsageTypes.includes(i.usageTypeId),
        };
      }));
      return res;
    });
  }, [id, status, disabledUsageTypes]);
  return <div style={{
    marginBottom: 24,
  }}>
    <div><h2>อัพโหลดไฟล์</h2></div>
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <Col span={4}><span style={{
          color: 'red',
        }}>*</span> เลือกกลุ่มเอกสาร:</Col>
        <Col span={14}>
          <Form.Item name='usageTypeId'>
            <Select
              disabled={status == 2 || status == 4}
              placeholder='กรุณาเลือกกลุ่มเอกสาร'
              onSelect={onSelectChange}
              style={{ width: '100%' }}
              options={options}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Upload {...props} maxCount={1} disabled={disableUpload}>
            <Button
              disabled={disableUpload || status === 2 || status === 4}
              type='primary'
              className={buttonCSS.primary}
              icon={<UploadOutlined />}
              size='large'
              shape='round'
              loading={uploading}
            >
              อัพโหลดเอกสาร
            </Button>
          </Upload>
        </Col>
        <Col span={24}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </Col>
      </Row>
    </Form>
  </div>;
};
export default FormFilesUpload;
