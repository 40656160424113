import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import configReviewPage from 'features/Offer/config/configReviewPage';

import { INQUIRY_PAGE, NEW_PAGE } from 'constants/page';

import {
  ALLOW_IMPROVE_OFFER_STATE,
  ATTACHMENT_STATE,
  OFFER_EDITABLE_STATE,
  PROHIBIT_ATTACHMENT_TAB_STATE,
} from '../constant';
import { getPageFunction } from '../utils';

type UseConditionConfigProps = {
  offerFormStatus?: string;
  isHistoryPage?: boolean;
  isReviewPage?: boolean;
};

const useConditionConfig = ({
  offerFormStatus = '',
  isHistoryPage,
  isReviewPage,
}: UseConditionConfigProps) => {
  const { pathname } = useLocation();

  const pageFunction = getPageFunction(pathname);
  const isNewPage = pageFunction === NEW_PAGE;
  const isInquiryPage = pageFunction === INQUIRY_PAGE;

  const isShowImproveOfferButton = useMemo(() => {
    if (isInquiryPage) return false;
    if (isReviewPage) return configReviewPage.isShowImproveOfferButton;
    return ALLOW_IMPROVE_OFFER_STATE.includes(offerFormStatus);
  }, [offerFormStatus, isInquiryPage, isReviewPage]);

  const isHideSaveOfferButton = useMemo(() => {
    if (isReviewPage) return configReviewPage.isHideSaveOfferButton(offerFormStatus);
    return false;
  }, [offerFormStatus, isReviewPage]);

  const isHideConfirmOfferButton = useMemo(() => {
    if (isReviewPage) return configReviewPage.isHideConfirmOfferButton(offerFormStatus);
    return false;
  }, [offerFormStatus, isReviewPage]);

  const isHideConfirmDocButton = useMemo(() => {
    if (isReviewPage) return configReviewPage.isHideConfirmDocButton(offerFormStatus);
    return false;
  }, [offerFormStatus, isReviewPage]);

  const isDisabledUploadFile = useMemo(() => {
    if (isReviewPage) return configReviewPage.isDisabledUploadFile(offerFormStatus);
    return !ATTACHMENT_STATE.includes(offerFormStatus);
  }, [offerFormStatus, isReviewPage]);

  const isDisabledAttachmentTab = useMemo(() => {
    if (isReviewPage) return configReviewPage.isDisabledAttachmentTab;
    return PROHIBIT_ATTACHMENT_TAB_STATE.includes(offerFormStatus);
  }, [offerFormStatus, isReviewPage]);

  const isDisabled = useMemo(() => {
    if (isReviewPage) return configReviewPage.isDisabled(offerFormStatus);
    if (isInquiryPage || isHistoryPage) return true;
    if (isNewPage) return false;
    return !OFFER_EDITABLE_STATE.includes(offerFormStatus);
  }, [isNewPage, offerFormStatus, isInquiryPage, isHistoryPage, isReviewPage]);

  return {
    isDisabled,
    isShowImproveOfferButton,
    isNewPage,
    isDisabledAttachmentTab,
    isDisabledUploadFile,
    isHideSaveOfferButton,
    isHideConfirmOfferButton,
    isHideConfirmDocButton,
  };
};

export default useConditionConfig;
