/* eslint-disable */

import { useEffect } from 'react';
import ImportDataForm from '../components/ImportDataForm';
import { useApi } from 'hooks/useApi';
import useAuth from 'hooks/useAuth';
import { createUsageFormOfficer } from 'api/usageForm';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import useDialogAlert from 'hooks/useDialogAlert';
import { subRoutePaths } from 'constants/routes';

const CreateImportDataLikeOperator = (): JSX.Element => {
  const { user } = useAuth();
  const [form] = useForm();
  const navigate = useNavigate();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();

  const { makeRequest: createUsageFormRequest, error: createError } = useApi();
  const { error } = useDialogAlert();

  const onSubmit = () => {
    createUsageFormRequest(async () => {
      const formData = form.getFieldsValue();
      const res: any = await createUsageFormOfficer(
        formData.operatorId,
        formData.year,
        formData.quarter
      );
      navigate(subRoutePaths.dataConReviewEdit(res.data.data.usageFormId));
      return res;
    });
  };

  useEffect(() => {
    if (
      createError?.errors[0] == 'Error occur when usage form already exist with year and quarter'
    ) {
      error({
        header: 'รายงานที่ต้องการสร้างมีอยู่แล้วในระบบ',
        sub: 'กรุณาตรวจสอบข้อมูลอีกครั้ง',
      });
    }
  }, [createError]);

  useEffect(() => {
    setHeaderTitle('เพิ่ม/แก้ไข ข้อมูลนำส่งรายไตรมาศ');
  }, []);

  return <ImportDataForm form={form} onSubmit={onSubmit} />;
};
export default CreateImportDataLikeOperator;
