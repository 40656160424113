import { useContext } from 'react';
import { OfferServicesContext } from '../contexts/OfferServicesContext';

const useOfferServicesContext = () => {
  const context = useContext(OfferServicesContext);
  if (!context) throw new Error('OfferServicesContext must be use inside OfferServicesProvider');
  return context;
};

export default useOfferServicesContext;
