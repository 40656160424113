export const routeGroup = {
  dashboard: '/dashboard',
  offer: '/offer',
  disagreement: '/disagreement',
  contract: '/contract',
  report: '/report',
  dataConnection: '/data-connection',
  download: '/download',
  auditLog: '/audit-log',
  masterData: '/master-data',
  inquiry: '/inquiry',
};

export const mainRoutePaths = {
  dashboardGraph: `${routeGroup.dashboard}/graph`,
  dashboardOperator: `${routeGroup.dashboard}/operator`,
  offerRAO: `${routeGroup.offer}/rao`,
  offerRIO: `${routeGroup.offer}/rio`,
  offerRDRO: `${routeGroup.offer}/rdro`,
  offerRISO: `${routeGroup.offer}/riso`,
  offerReview: `${routeGroup.offer}/review`,
  offerOfficer: `${routeGroup.offer}/officer`,
  offerAssignment: `${routeGroup.offer}/assignment`,
  offerPublishUrl: `${routeGroup.offer}/publish-url`,
  offerOperatorLMS: `${routeGroup.offer}/operator-lms`,
  disagreementList: `${routeGroup.disagreement}/list`,
  contractUsage: `${routeGroup.contract}/usage`,
  contractConnect: `${routeGroup.contract}/connect`,
  contractNetwork: `${routeGroup.contract}/network`,
  contractInfra: `${routeGroup.contract}/infrastructure`,
  contractReview: `${routeGroup.contract}/review`,
  contractOfficer: `${routeGroup.contract}/officer`,
  contractPublishUrl: `${routeGroup.contract}/publish-url`,
  contractAssignment: `${routeGroup.contract}/assignment`,
  reportDisputeOverview: `${routeGroup.report}/dispute-overview`,
  reportDisputeTracking: `${routeGroup.report}/dispute-tracking`,
  reportContractReviewStatus: `${routeGroup.report}/contract-review-status`,
  reportApprovedContract: `${routeGroup.report}/approved-contract`,
  reportOfferReviewStatus: `${routeGroup.report}/offer-review-status`,
  reportApprovedOffer: `${routeGroup.report}/approved-offer`,
  reportOfferAuditResults: `${routeGroup.report}/offer-audit-results`,
  reportDataConnection: `${routeGroup.report}/data-connection`,
  manageUser: '/manage-user',
  dataConnectionOperator: `${routeGroup.dataConnection}/operator`,
  dataConnectionReview: `${routeGroup.dataConnection}/review`,
  dataConnectionOfficer: `${routeGroup.dataConnection}/officer`,
  downloadForm: `${routeGroup.download}/form`,
  auditLogLoginHistory: `${routeGroup.auditLog}/login-history`,
  masterDataCurrencies: `${routeGroup.masterData}/currencies`,
  masterDataUnits: `${routeGroup.masterData}/units`,
  masterDataFacilities: `${routeGroup.masterData}/facilities`,
  masterDataServiceTypes: `${routeGroup.masterData}/service-types`,
  masterDataServices: `${routeGroup.masterData}/services`,
  inquiryOffer: `${routeGroup.inquiry}/offer`,
  inquiryContract: `${routeGroup.inquiry}/contract`,
  inquiryDisagreement: `${routeGroup.inquiry}/disagreement`,
};

export const subRoutePaths = {
  offerRAONew: `${mainRoutePaths.offerRAO}/new`,
  offerRAOEdit: (offerId) => `${mainRoutePaths.offerRAO}/${offerId}/edit`,
  offerRAODetail: (offerId) => `${mainRoutePaths.offerRAO}/${offerId}/detail`,
  offerRAOInquiry: (offerId) => `${mainRoutePaths.offerRAO}/${offerId}/inquiry`,
  offerRAOHistory: (offerId) => `${mainRoutePaths.offerRAO}/${offerId}/history`,
  offerRIONew: `${mainRoutePaths.offerRIO}/new`,
  offerRIOEdit: (offerId) => `${mainRoutePaths.offerRIO}/${offerId}/edit`,
  offerRIODetail: (offerId) => `${mainRoutePaths.offerRIO}/${offerId}/detail`,
  offerRIOInquiry: (offerId) => `${mainRoutePaths.offerRIO}/${offerId}/inquiry`,
  offerRIOHistory: (offerId) => `${mainRoutePaths.offerRIO}/${offerId}/history`,
  offerRDRONew: `${mainRoutePaths.offerRDRO}/new`,
  offerRDROEdit: (offerId) => `${mainRoutePaths.offerRDRO}/${offerId}/edit`,
  offerRDRODetail: (offerId) => `${mainRoutePaths.offerRDRO}/${offerId}/detail`,
  offerRDROInquiry: (offerId) => `${mainRoutePaths.offerRDRO}/${offerId}/inquiry`,
  offerRDROHistory: (offerId) => `${mainRoutePaths.offerRDRO}/${offerId}/history`,
  offerRISONew: `${mainRoutePaths.offerRISO}/new`,
  offerRISOEdit: (offerId) => `${mainRoutePaths.offerRISO}/${offerId}/edit`,
  offerRISODetail: (offerId) => `${mainRoutePaths.offerRISO}/${offerId}/detail`,
  offerRISOInquiry: (offerId) => `${mainRoutePaths.offerRISO}/${offerId}/inquiry`,
  offerRISOHistory: (offerId) => `${mainRoutePaths.offerRISO}/${offerId}/history`,
  offerReviewEdit: (offerId) => `${mainRoutePaths.offerReview}/${offerId}/edit`,
  disagreementNew: `${routeGroup.disagreement}/new`,
  disagreementEdit: (disagreementId) => `${routeGroup.disagreement}/${disagreementId}/edit`,
  contractUsageNew: `${mainRoutePaths.contractUsage}/new`,
  contractUsageEdit: (contractId) => `${mainRoutePaths.contractUsage}/${contractId}/edit`,
  contractUsageHistory: (contractId) => `${mainRoutePaths.contractUsage}/${contractId}/history`,
  contractConnectNew: `${mainRoutePaths.contractConnect}/new`,
  contractConnectEdit: (contractId) => `${mainRoutePaths.contractConnect}/${contractId}/edit`,
  contractConnectHistory: (contractId) => `${mainRoutePaths.contractConnect}/${contractId}/history`,
  contractNetworkNew: `${mainRoutePaths.contractNetwork}/new`,
  contractNetworkEdit: (contractId) => `${mainRoutePaths.contractNetwork}/${contractId}/edit`,
  contractNetworkHistory: (contractId) => `${mainRoutePaths.contractNetwork}/${contractId}/history`,
  contractInfraNew: `${mainRoutePaths.contractInfra}/new`,
  contractInfraEdit: (contractId) => `${mainRoutePaths.contractInfra}/${contractId}/edit`,
  contractInfraHistory: (contractId) => `${mainRoutePaths.contractInfra}/${contractId}/history`,
  contractReviewEdit: (contractId) => `${mainRoutePaths.contractReview}/${contractId}/edit`,
  contractOfficerNew: (offerType) => `${mainRoutePaths.contractOfficer}/${offerType}/new`,
  contractOfficerEdit: (contractId) => `${mainRoutePaths.contractOfficer}/${contractId}/edit`,
  dataConOperatorNew: `${mainRoutePaths.dataConnectionOperator}/new`,
  dataConOperatorEdit: (dataConId) => `${mainRoutePaths.dataConnectionOperator}/${dataConId}/edit`,
  dataConReviewNew: `${mainRoutePaths.dataConnectionReview}/new`,
  dataConReviewDetail: (dataConId) => `${mainRoutePaths.dataConnectionReview}/${dataConId}/detail`,
  dataConReviewEdit: (dataConId) => `${mainRoutePaths.dataConnectionReview}/${dataConId}/edit`,
  inquiryContractDetail: (contractId) => `${mainRoutePaths.inquiryContract}/${contractId}/detail`,
};
