import { Form, Input } from 'antd';

import useCancelContractModalContext from 'features/Contract/hooks/useCancelContractModalContext';

import { formLayout } from 'constants/form';

const { TextArea } = Input;

const CancelContractForm = () => {
  const { contractTypeName, formRef, onSubmitCancelContract } = useCancelContractModalContext();
  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      size="large"
      name="cancelContractModal"
      form={formRef}
      onFinish={(values: any) => {
        onSubmitCancelContract(values);
      }}
    >
      <Form.Item
        label={`รายละเอียดการยกเลิก${contractTypeName}`}
        name="cancelContract"
        rules={[{ required: true, message: 'กรุณากรอกรายละเอียดการยกเลิก' }]}
      >
        <TextArea rows={4} />
      </Form.Item>
    </Form>
  );
};

export default CancelContractForm;
