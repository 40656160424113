import axiosInstance from 'utils/axios';

export const listAttachHistory = (id: number | string) => {
  return axiosInstance.get(`/hist/offer/${id}`);
};
export const listReviewOfferOfficer = (
  pageSize: number | string,
  currentPageNumber: number | string
) => {
  return axiosInstance.get(`/review-offer/readAll`, {
    params: {
      pageSize,
      currentPageNumber,
    },
  });
};
export const listOfferFormStates = () => {
  return axiosInstance.get(`/offer/officer/offer-form-states`);
};

export const getInquiryStatus = () => {
  return axiosInstance.get(`/offer/inquiry-status`);
};
