import { ColumnsType } from 'antd/es/table';
import { Button, Table } from 'antd';
import { CloseOutlined, DeleteFilled, DownloadOutlined, HourglassOutlined } from '@ant-design/icons';
import { Pageable } from 'api/usageForm/interface';

export interface DataSource {
  data: ReportTableDataType[];
  pagination?: Pageable;
}

interface ReportTableInput {
  dataSource: DataSource;
  onSearch: (pageSize?: number, currentPage?: number) => void;
  setCurrentPage: (value: number) => void;
  currentPage: number;
}

export interface ReportTableDataType {
  key: React.Key;
  rawReportId: number;
  usageType: string;
  year: number;
  quarter: number;
  onDownload: () => void;
  onDelete: () => void;
  networkType: string;
  batchStatus: string;
}

const MESSAGE: Record<string, string> = {
  COMPLETED: 'เสร็จสิ้น',
  FAILED: 'เกิดข้อผิดพลาด',
  NOT_FOUND: 'เกิดข้อผิดพลาด',
  PENDING: 'อยู่ระหว่างประมวนผล',
};

const columns: ColumnsType<ReportTableDataType> = [
  {
    title: 'ลำดับที่',
    dataIndex: 'no',
    align: 'center',
    render: (item, record, index) => {
      return <>{index + 1}</>;
    },
  },
  {
    title: 'ประเภทข้อมูล',
    width: '150px',
    dataIndex: 'usageType',
  },
  {
    title: 'ปี',
    dataIndex: 'year',
  },
  {
    title: 'ไตรมาส',
    dataIndex: 'quarter',
  },
  {
    title: 'ภายในประเทศ/ต่างประเทศ',
    dataIndex: 'networkType',
  },
  {
    title: 'สถานะ',
    dataIndex: 'batchStatus',
    render: (_, record) => {

      return <div>
        {
          MESSAGE[record.batchStatus]
        }
      </div>;
    },
  },
  {
    title: 'ดาวน์โหลด',
    align: 'center',
    render: (_, record) => {
      return <div>
        <Button disabled={record.batchStatus !== 'COMPLETED'} onClick={() => {
          record.onDownload();
        }
        }>
          {record.batchStatus !== 'COMPLETED' && record.batchStatus !== 'FAILED' && record.batchStatus !== 'NOT_FOUND' &&
            <HourglassOutlined />}
          {record.batchStatus === 'COMPLETED' && <DownloadOutlined />}
          {(record.batchStatus === 'FAILED' || record.batchStatus === 'PENDING') && <CloseOutlined />}
        </Button>
      </div>;
    },
  },
  {
    title: '',
    align: 'center',
    render: (_, record) => {
      return <div>
        <Button disabled={record.batchStatus !== 'COMPLETED'} onClick={() => {
          record.onDelete();
        }
        }>
          <DeleteFilled />
        </Button>
      </div>;
    },
  },
];


const ReportTable = (props: ReportTableInput): JSX.Element => {
  return (
    <Table
      columns={columns}
      dataSource={props.dataSource.data}
      size='small'
      pagination={{
        onChange: async (currentPage, size) => {
          if (currentPage !== props.currentPage) {
            props.setCurrentPage(currentPage);
          }
          props.onSearch(size, currentPage - 1);
        },
        current: props.currentPage || 1,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: props.dataSource.pagination?.totalItems,
        defaultPageSize: 5,
        defaultCurrent: 1,
        pageSizeOptions: [5, 10, 20],
      }}
    />
  );
};
export default ReportTable;
