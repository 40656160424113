import axiosInstance from 'utils/axios';
import { GetOperatorLMSResponse, Operator, SearchOperatorList, SearchOperatorListResponse } from './interface';
import { clean } from 'utils/object';

export const getAllOperators = (): Promise<Operator[]> => {
  return axiosInstance.get(`/operators`);
};

export const getOperator = ({ refNo }: { refNo: string }) => {
  return axiosInstance.get(`/operators/${refNo}`);
};

export const getOperatorAll = () => {
  return axiosInstance.get(`/operators`);
};


export const getOperatorList = (payload: SearchOperatorList): Promise<SearchOperatorListResponse> => {
  return axiosInstance.get('/operators/lms/search', {
    params: {
      ...clean({
        ...payload,
      }),
    },
  });
};

export const getOperatorLMS = (id: number): Promise<GetOperatorLMSResponse> => {
  return axiosInstance.get(`operators/lms/${id}`);
};
