import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getAllOperators } from 'api/operator';

type OperatorDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  disabled?: boolean;
};

const OperatorDropdown = ({ label, labelCol, disabled = false }: OperatorDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => getAllOperators());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name="taxId"
        label={label ? label : 'ผู้ประกอบการ'}
        labelCol={labelCol}
        // rules={[{ required: true, message: `กรุณาเลือก${label ? label : 'ผู้ประกอบการ'}` }]}
      >
        <Select
          loading={loading}
          disabled={disabled}
          placeholder="-- กรุณาระบุ --"
          showSearch
          filterOption={(input, option) => (option?.label?.toString() ?? '').includes(input)}
          options={
            data
              ? data.map((item: { taxId: string; operName: string }) => ({
                  value: item.taxId,
                  label: item.operName,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OperatorDropdown;
