import { useEffect, useMemo, useState } from 'react';
import { FormInstance } from 'antd';
import { useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import useQuery from 'hooks/useQuery';
import useDialogAlert from 'hooks/useDialogAlert';
import useOperatorAddress from 'features/Contract/hooks/useOperatorAddress';
import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';
import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

import { calculateDuration, convertMonthsToYearMonths, getJSDateObj } from 'utils/date';
import { getPageFunction } from 'features/Offer/utils';

import { IContractData } from 'features/Contract/interfaces';
import { EDIT_PAGE } from 'constants/page';

type UseContractFormProps = {
  isChortorEdit: boolean;
  contractData: IContractData;
  contractFormRef: FormInstance<any>;
  onChangeOfferFormId: (value: number) => void;
  selectedOfferFormId?: number;
};

const useContractForm = ({
  isChortorEdit,
  contractData,
  contractFormRef,
  onChangeOfferFormId,
  selectedOfferFormId,
}: UseContractFormProps) => {
  let query = useQuery();
  const [taxId, setTaxId] = useState<string | undefined | null>(undefined);

  const { user } = useAuth();
  const { pathname } = useLocation();
  const { warning } = useDialogAlert();
  const { operatorAddress } = useOperatorAddress(taxId);
  const { currentContServes, onResetCurrentContServes } = useContractServicesContext();
  const { currentContFacilities, onResetCurrentContFacilities } = useContractFacilitiesContext();

  const pageFunction = useMemo(() => getPageFunction(pathname), [pathname]);

  const onConfirmChangeOfferId = (value: number) => {
    if (currentContServes.length > 0 || currentContFacilities.length > 0) {
      warning({
        header: 'คำเตือน',
        sub: 'หากท่านต้องการเปลี่ยนข้อเสนอเพื่ออ้างอิงทำสัญญาใหม่ ข้อมูลที่กรอกไว้จะหายไป',
        onConfirm: () => {
          onChangeOfferFormId(value);
          onResetCurrentContServes();
          onResetCurrentContFacilities();
        },
        onCancel: () => {
          contractFormRef.setFieldsValue({
            offerFormId: selectedOfferFormId,
          });
        },
      });
    } else {
      onChangeOfferFormId(value);
    }
  };

  const onChangeOwner = (value: string) => {
    setTaxId(value);
    contractFormRef.setFieldsValue({ selectedRenter: undefined, address: undefined });
  };

  const onCalculateContractDuration = (startDate: Date, endDate: Date) => {
    if (startDate && endDate) {
      const duration = calculateDuration(new Date(startDate), new Date(endDate));
      contractFormRef.setFieldsValue({
        periodYears: duration.years,
        periodMonths: duration.months,
      });
    }
  };

  const onChangeContractStartDate = (startDate: any) => {
    const endDate = contractFormRef.getFieldValue('contractEndDate');
    onCalculateContractDuration(startDate, endDate);
  };

  const onChangeContractEndDate = (endDate: any) => {
    const startDate = contractFormRef.getFieldValue('contractStartDate');
    onCalculateContractDuration(startDate, endDate);
  };

  useEffect(() => {
    if (!isChortorEdit && user) {
      // NOTE: ผู้ประกอบการจัดทำสัญญา
      setTaxId(user.referenceNo);
    } else {
      // NOTE: ช.ท จัดทำสัญญา
      if (pageFunction === EDIT_PAGE) {
        setTaxId(contractData?.ownerId);
      } else {
        setTaxId(query.get('ownerId'));
      }
    }
  }, [isChortorEdit, user, pageFunction, query, contractData]);

  useEffect(() => {
    if (operatorAddress) {
      contractFormRef.setFieldsValue({
        address: operatorAddress,
      });
    }

    if (contractData) {
      let formData: any = { ...contractData };
      const { years, months } = convertMonthsToYearMonths(contractData.contractPeriod);
      formData.periodYears = years;
      formData.periodMonths = months;
      formData.formDate = getJSDateObj(formData.formDate);
      formData.contractDate = getJSDateObj(formData.contractDate);
      formData.contractStartDate = getJSDateObj(formData.contractStartDate);
      formData.contractEndDate = getJSDateObj(formData.contractEndDate);
      formData.signedDate = getJSDateObj(formData.signedDate);
      formData.selectedRenter = contractData.renter?.taxId
        ? { value: contractData.renter?.taxId }
        : undefined;
      formData.address = contractData.address || operatorAddress;

      contractFormRef.setFieldsValue({
        ...formData,
      });
    }
  }, [contractFormRef, contractData, operatorAddress]);

  return {
    taxId,
    onConfirmChangeOfferId,
    onChangeOwner,
    onChangeContractEndDate,
    onChangeContractStartDate,
  };
};

export default useContractForm;
