import { Form, Row, Col, Input } from 'antd';
import { useEffect } from 'react';

import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';
import { formLayout } from 'constants/form';

import { IStepModalForm } from './interfaces';

interface IStepFormWithDate extends IStepModalForm {
  conFormPublicUrl: string | undefined;
}

const StepFormWithLink = ({
  form,
  stepData,
  isDisable,
  onUpdateOfferState,
  conFormPublicUrl,
}: IStepFormWithDate) => {
  const onFinish = (values: any) => {
    let date = new Date(values.actionDate);
    let data = {
      // formStateId: stepData?.formStateId,
      // stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: '',
      // actionName: '',
      actionDate: dayJsformatDateObjToString(date),
      approvedNo: '',
      approvedDate: null,
      isCompleteState: false,
      isCurrentState: true,
    };
    onUpdateOfferState(data);
  };

  useEffect(() => {
    if (stepData) {
      let _stepData: any = { ...stepData };
      _stepData.actionDate = stepData.actionDate ? getJSDateObj(stepData.actionDate) : new Date();
      _stepData.conFormPublicUrl = conFormPublicUrl;
      form.setFieldsValue({
        ..._stepData,
      });
    }
  }, [form, stepData, conFormPublicUrl]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      size="large"
      name="stepModalForm"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะสัญญา" name="stateName">
            <Input disabled />
          </Form.Item>
        </Col>
        {/* <Col span={24}>
          <Form.Item
            colon
            label="วันที่ทำรายการ"
            name="actionDate"
            rules={[{ required: true, message: 'กรุณาเลือกวันที่ทำรายการ' }]}
          >
            <ThaiDatepickerComponent disabled={isDisable} />
          </Form.Item>
        </Col> */}
        <Col span={24}>
          <Form.Item
            colon
            label="Link Web เผยแพร่สัญญา"
            name="conFormPublicUrl"
            rules={[{ required: true, message: 'กรุณากรอก Link Web เผยแพร่สัญญา' }]}
          >
            <Input disabled={isDisable} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default StepFormWithLink;
