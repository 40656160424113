import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form } from 'antd';
import SearchResult from './components/SearchResult';
import useModal from './hooks/useModal';
import SearchOperators from './components/SearchOperators';
import { TApiResponse, useApi } from 'hooks/useApi';
import { getAllOperators, getOperatorList, getOperatorLMS } from 'api/operator';
import { Operator, OperatorData, OperatorLMS, SearchOperatorListResponse } from 'api/operator/interface';
import ModalOperators from 'features/OperatorsLMS/components/ModalOperators';

const ListOperatorsLMS = () => {
  const { makeRequest: searchOperatorListRequest } = useApi();
  const { makeRequest: getOperatorLMSRequest } = useApi();
  const [pageSize, setPageSize] = useState(5);
  const [dataSource, setDataSource] = useState<OperatorData[]>([]);
  const [isOpenModal, setOpenModal] = useState(false);
  const [operatorLMS, setOperatorLMS] = useState<OperatorLMS>();
  const [totalItems, setTotalItems] = useState<number>(0);
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [formSearch] = Form.useForm();
  const { isOpenModalOperators, onCancelModalOperators, recordOperators } =
    useModal(formSearch);

  const { makeRequest: operatorsRequest, data: operatorsList }: TApiResponse<Operator[]> = useApi();
  const getOfficerLeaderData = useCallback(async () => {
    await operatorsRequest(() => getAllOperators());
  }, [operatorsRequest]);

  const onOpenModalOperators = async (id: number) => {
    await getOperatorLMSRequest(async () => {
      const res = await getOperatorLMS(id);
      setOperatorLMS(res.data.data);
      setOpenModal(true);
      return res;
    });
  };

  const onReset = () => {
    formSearch.resetFields();
  };

  const onSearchOperators = async (pageSize: number, currentPageNumber: number) => {
    const operId = formSearch.getFieldValue('id');
    let operator: Operator | undefined;
    if (operId) {
      operator = operatorsList.find(i => i.id === operId);
    }
    setPageSize(pageSize);
    const res: SearchOperatorListResponse = await searchOperatorListRequest(async () => {
      return getOperatorList({
        pageSize: pageSize || 1,
        currentPageNumber: currentPageNumber || 1,
        operName: operator?.operName,
        status: formSearch.getFieldValue('status'),
      });
    });
    setDataSource(res.data.data);
    setTotalItems(res.data.pageable.totalItems);
  };

  useEffect(() => {
    onSearchOperators(pageSize, 1);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOfficerLeaderData();
  }, [getOfficerLeaderData]);

  useEffect(() => {
    setHeaderTitle('ข้อมูลผู้ประกอบการ (LMS)');
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <HeaderTitle>ค้นหาโดย</HeaderTitle>
      <SearchOperators
        formSearch={formSearch}
        onReset={onReset}
        onSearchOperators={() => {
          onSearchOperators(pageSize, 1);
        }}
        operatorsList={operatorsList}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <SearchResult
        onPageSizeChange={onSearchOperators}
        onOpenModalOperators={onOpenModalOperators}
        isOpenModalOperators={isOpenModalOperators}
        onCancelModalOperators={onCancelModalOperators}
        recordOperators={recordOperators}
        operatorsList={dataSource}
        total={totalItems}
      />
      <ModalOperators isOpen={isOpenModal} setModal={setOpenModal} operatorLMS={operatorLMS} />
    </div>
  );
};

export default ListOperatorsLMS;
