import { useCallback, useEffect, useState } from 'react';
import { Form, UploadFile } from 'antd';
import { FormInstance } from 'antd/lib';

import { deleteUploadedOfferFile, uploadOfferAttachmentFile } from 'api/offer/attachFiles';
import useDialogAlert from 'hooks/useDialogAlert';

import useFileManagement, {
  downloadFileProps,
  handleUploadProps,
  onRemoveFileProps,
} from 'features/Contract/hooks/useFileManagement';
import { FileListDataType } from 'features/Contract/interfaces';
import { IOfferAttachFile } from '../interfaces';

type UseUploadButtonValue = {
  isOpenFileDialog: boolean;
  fileList: FileListDataType[];
  isUploadingFile: boolean;
  isDeletingFile: boolean;
  uploadFormRef: FormInstance<any>;
  setIsOpenFileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  onUploadFile: (offerId: number | string | undefined) => Promise<void>;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any> | undefined>>;
  onValidateDocType: () => void;
  onRemoveFile: ({ fileId, api }: onRemoveFileProps) => void;
  downloadFile: ({ fileId, fileName, api }: downloadFileProps) => void;
  handleUpload: ({
    docId,
    docName,
    required,
    id,
    api,
    getData = (res: any) => res.data?.data,
  }: handleUploadProps) => void;
  onRemoveRecordFile: (record: any) => void;
  onDownloadRecordFile: (record: any) => void;
  onTableUploadFile: (record: any, offerId: number | string | undefined) => void;
  validateRequiredFiles: () => number;
  setSelectedDocTypeOption: React.Dispatch<React.SetStateAction<any>>;
};

type UseUploadButtonProps = {
  offerAttachFiles: IOfferAttachFile[] | undefined;
  downloadOfferFileAPI: Function;
  isHistoryPage: boolean;
  offerId: string | number | undefined;
  offerType: string;
};

export type OnDownloadRecordFileProps = {
  record: any;
  offerId: string | number | undefined;
  offerType: string;
};

const useUploadButton = ({
  offerAttachFiles,
  downloadOfferFileAPI,
  isHistoryPage,
  offerId,
  offerType,
}: UseUploadButtonProps): UseUploadButtonValue => {
  const { error } = useDialogAlert();
  const [uploadFormRef] = Form.useForm();
  const [isOpenFileDialog, setIsOpenFileDialog] = useState<boolean>(false);
  const [selectedDocTypeOption, setSelectedDocTypeOption] = useState<any>(undefined);
  const {
    fileList,
    onRemoveFile,
    setFileList,
    handleUpload,
    isUploadingFile,
    setCurrentUploadFile,
    downloadFile,
    isDeletingFile,
    sortRequiredDoc,
    validateRequiredFiles,
  } = useFileManagement();

  const onUploadFile = useCallback(
    async (offerId: number | string | undefined) => {
      if (offerId) {
        await handleUpload({
          docId: selectedDocTypeOption.value,
          docName: selectedDocTypeOption.label,
          required: selectedDocTypeOption.required,
          id: Number(offerId),
          api: uploadOfferAttachmentFile,
          getData: (response: any) => response.data,
        });
        uploadFormRef.setFieldValue('docId', undefined);
        setSelectedDocTypeOption(undefined);
      } else {
        error({
          header: 'ไม่พบ docId หรือ offerId',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [handleUpload, uploadFormRef, error, selectedDocTypeOption]
  );

  const onTableUploadFile = useCallback(
    async (record: any, offerId: number | string | undefined) => {
      if (!offerId)
        return error({
          header: 'ไม่พบ offerId',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      await handleUpload({
        docId: record.docId,
        docName: record.docName,
        required: true,
        api: uploadOfferAttachmentFile,
        id: Number(offerId),
        getData: (response: any) => response.data,
      });
    },
    [handleUpload, error]
  );

  const onRemoveRecordFile = useCallback(
    (record: any) => {
      onRemoveFile({
        fileId: record.attachFileId,
        api: deleteUploadedOfferFile,
        getData: (response: any) => response.data,
      });
    },
    [onRemoveFile]
  );

  const onDownloadRecordFile = useCallback(
    (record: any) => {
      if (isHistoryPage) {
        downloadFile({
          params: {
            offerId: offerId,
            offerType: offerType,
            attachFileId: record.attachFileId,
          },
          fileId: record.attachFileId,
          fileName: record.attachFileName,
          api: downloadOfferFileAPI,
        });
      } else {
        downloadFile({
          fileId: record.attachFileId,
          fileName: record.attachFileName,
          api: downloadOfferFileAPI,
        });
      }
    },
    [downloadFile, downloadOfferFileAPI, isHistoryPage, offerId, offerType]
  );

  const onValidateDocType = useCallback(() => {
    try {
      uploadFormRef.validateFields(['docId']);
    } catch (error) {
      console.error(error);
    }
  }, [uploadFormRef]);

  useEffect(() => {
    if (offerAttachFiles && offerAttachFiles.length > 0) {
      const sortedData = sortRequiredDoc(offerAttachFiles);
      setFileList(sortedData);
    }
  }, [offerAttachFiles, setFileList, sortRequiredDoc]);

  return {
    isOpenFileDialog,
    setIsOpenFileDialog,
    setSelectedDocTypeOption,
    uploadFormRef,
    onUploadFile,
    setCurrentUploadFile,
    onValidateDocType,
    fileList,
    isUploadingFile,
    isDeletingFile,
    onRemoveFile,
    downloadFile,
    handleUpload,
    onRemoveRecordFile,
    onDownloadRecordFile,
    onTableUploadFile,
    validateRequiredFiles,
  };
};

export default useUploadButton;
