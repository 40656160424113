import { Spin } from 'antd';
import useLoading from '../../hooks/useLoading';

const LoadComponent = () => {
  const { isLoad } = useLoading();

  if (!isLoad) {
    return <></>;
  }

  return (
    <div className="loading">
      <Spin />
    </div>
  );
};

export default LoadComponent;
