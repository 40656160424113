import { DownloadOutlined, RedoOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { dayJsformatDateToBCYear } from 'utils/date';
import { getContractTypeLabel, getOfferTypeLabel } from 'components/GenericReport/utils';

import { IOfferSummaryReportData } from '../../interfaces';

type ColumnsProps = {
  onRefreshReportStatus: (jobId: string) => void;
  onDownloadReport: (record: any) => void;
  dateLabel?: string;
  isContractReport?: boolean;
};

export const columns = ({
  onRefreshReportStatus,
  onDownloadReport,
  dateLabel,
  isContractReport,
}: ColumnsProps) => {
  const columns: ColumnsType<IOfferSummaryReportData> = [
    {
      title: 'Job ID',
      dataIndex: 'jobId',
      key: 'jobId',
      align: 'center',
    },
    {
      title: isContractReport ? 'ประเภทสัญญา' : 'ประเภทข้อเสนอ',
      dataIndex: 'offerFormType',
      key: 'offerFormType',
      align: 'center',
      render: (value) => {
        const label = isContractReport ? getContractTypeLabel(value) : getOfferTypeLabel(value);
        if (label && label !== 'null') {
          return label;
        }
        return isContractReport ? 'ทุกประเภทสัญญา' : 'ทุกประเภทข้อเสนอ';
      },
    },
    {
      title: dateLabel ? dateLabel : 'มติ กสทช. วันที่',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : '-'),
    },
    {
      title: 'ถึงวันที่',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : '-'),
    },
    {
      title: 'ผู้สร้างข้อมูล',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
    },
    {
      title: 'วันที่สร้างข้อมูล',
      dataIndex: 'executedDate',
      key: 'executedDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : ''),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        if (record.status === 'COMPLETED')
          return (
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                onDownloadReport(record);
              }}
            >
              ดาวน์โหลด
            </Button>
          );
        return (
          <Button icon={<RedoOutlined />} onClick={() => onRefreshReportStatus(record.jobId)}>
            รีเฟรช
          </Button>
        );
      },
    },
  ];
  return columns;
};
