import Table, { ColumnsType } from 'antd/es/table';
import React from 'react';

import { ModalOperatorsProps } from 'features/OperatorsLMS/interfaces';
import { AuditOutlined } from '@ant-design/icons';
import { OperatorData } from 'api/operator/interface';

const SearchResult = ({ onOpenModalOperators, operatorsList, onPageSizeChange, total }: ModalOperatorsProps) => {
  const columns: ColumnsType<OperatorData> = [
    {
      title: 'ลำดับที่',
      key: 'key',
      align: 'center',
      width: 100,
      render: (_, __, index) => <>{index + 1}</>,
    },

    {
      title: <div style={{ textAlign: 'center' }}>รายชื่อผู้ประกอบการ</div>,
      key: 'operName',

      render: (_, record) => <>{record.operName}</>,
    },
    {
      title: 'สถานะ',
      key: 'status',
      align: 'center',
      width: 100,
      render: (_, record) => <>{record.status ? 'ปกติ' : 'สิ้นสุด'}</>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>รายละเอียด</div>,
      key: 'save',
      width: 100,
      align: 'center',
      render: (_, record) => (
        <>
          {' '}
          <AuditOutlined
            className='file-outlined-css'
            style={{ cursor: 'pointer' }}
            onClick={() => onOpenModalOperators(record.operatorsId)}
          />
        </>
      ),
    },
  ];


  return (
    <div className='padding-conten-main'>
      <Table
        columns={columns}
        dataSource={operatorsList}
        rowKey={'operatorsId'}
        size='middle'
        pagination={{
          onChange: async (currentPage, size) => {
            onPageSizeChange(size, currentPage);
          },
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          total: total,
          defaultPageSize: 5,
          defaultCurrent: 1,
          pageSizeOptions: [5, 10, 20],
        }}
      />
    </div>
  );
};

export default SearchResult;
