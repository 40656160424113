import { createReport004, downloadReport004, getReport004, listReport004 } from 'api/report';
import GenericReport from 'components/GenericReport';
import ContractTypeDropdown from 'features/Contract/components/ContractTypeDropdown';

const Report004 = () => {
  return (
    <>
      <GenericReport
        isRequiredOfferType={false}
        isRequiredStartDate={false}
        isRequiredEndDate={false}
        isContractReport={true}
        pageTitle="รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว"
        dateLabel="มติ กสทช. วันที่"
        createReportApi={createReport004}
        listReportApi={listReport004}
        getReportByJobIdApi={getReport004}
        downloadReportByJobIdApi={downloadReport004}
        TypeDropdown={ContractTypeDropdown}
      />
    </>
  );
};

export default Report004;
