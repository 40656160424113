import { useLocation, useNavigate } from 'react-router-dom';
import { Row, Space } from 'antd';
import { PrinterOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';
import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';

const ActionButtonGroup = () => {
  const navigate = useNavigate();
  const {
    editOfferFormRef,
    isDisabled,
    isShowImproveOfferButton,
    isNewPage,
    isHideSaveOfferButton,
    isHideConfirmOfferButton,
    onSaveOffer,
    onConfirmOffer,
    onImproveOfferFromExistingOne,
  } = useOfferDataContext();
  const { currentOfferServices } = useOfferServicesContext();
  const { currentOfferFacilities } = useOfferFacilititesContext();
  const { state } = useLocation();
  const from = state ? state.from : '/';

  return (
    <Row justify={'center'} style={{ marginTop: 30 }}>
      <Space>
        {isShowImproveOfferButton && (
          <PrimaryButton
            icon={<RollbackOutlined />}
            onClick={() => {
              onImproveOfferFromExistingOne();
            }}
          >
            ปรับปรุงข้อเสนอเดิม
          </PrimaryButton>
        )}
        {!isHideSaveOfferButton && (
          <PrimaryButton
            disabled={isDisabled}
            icon={<SaveOutlined />}
            onClick={() =>
              onSaveOffer({
                editOfferFormRef,
                currentOfferServices,
                currentOfferFacilities,
              })
            }
          >
            บันทึกรายการ
          </PrimaryButton>
        )}
        {!isHideConfirmOfferButton && (
          <PrimaryButton
            disabled={isDisabled || isNewPage}
            icon={<PrinterOutlined />}
            onClick={() =>
              onConfirmOffer({
                editOfferFormRef,
                currentOfferServices,
              })
            }
          >
            ยืนยันการบันทึก
          </PrimaryButton>
        )}
        <PrimaryButton icon={<RollbackOutlined />} onClick={() => navigate(from)}>
          ย้อนกลับหน้าค้นหา
        </PrimaryButton>
      </Space>
    </Row>
  );
};

export default ActionButtonGroup;
