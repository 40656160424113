import { useCallback } from 'react';
import { Col, Form, Input, Row } from 'antd';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';

import useProvince from './hooks/useProvince';
import useDistrict from './hooks/useDistrict';
import useSubDistrict from './hooks/useSubDistrict';

import ProvincesDropdown from './ProvincesDropdown';
import DistrictDropdown from './DistrictsDropdown';
import SubDistrictDropdown from './SubDistrictsDropdown';

const AddressDropdownForm = () => {
  const { editOfferFormRef, offerData, isDisabled } = useOfferDataContext();
  const operatorInfo = offerData?.operators;

  const { provinces, isLoadingProvinces } = useProvince();
  const { onListDistrictByProvinceId, districts, isLoadingDistricts } = useDistrict({
    provinceId: operatorInfo?.conProvinceId,
  });
  const { onListSubDistrictByDistrictId, subDistricts, isLoadingSubDistricts } = useSubDistrict({
    districtId: operatorInfo?.conDistrictId,
  });

  const onChangeProvince = useCallback(
    (value: any) => {
      onListDistrictByProvinceId(value);
      editOfferFormRef.resetFields(['conDistrictId', 'conSubDistrictId']);
    },
    [onListDistrictByProvinceId, editOfferFormRef]
  );

  const onChangeDistrict = useCallback(
    (value: any) => {
      onListSubDistrictByDistrictId(value);
      editOfferFormRef.resetFields(['conSubDistrictId']);
    },
    [onListSubDistrictByDistrictId, editOfferFormRef]
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <ProvincesDropdown
          onChangeProvince={onChangeProvince}
          provinces={provinces}
          isLoadingProvinces={isLoadingProvinces}
        />
      </Col>
      <Col span={12}>
        <DistrictDropdown
          isDisabled={isDisabled}
          onChangeDistrict={onChangeDistrict}
          districts={districts}
          isLoadingDistricts={isLoadingDistricts}
        />
      </Col>
      <Col span={12}>
        <SubDistrictDropdown
          isDisabled={isDisabled}
          subDistricts={subDistricts}
          isLoadingSubDistricts={isLoadingSubDistricts}
        />
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="รหัสไปรษณีย์"
          name="conPost"
          rules={[{ required: true, message: 'กรุณากรอกรหัสไปรษณีย์' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AddressDropdownForm;
