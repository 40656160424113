import { Col, Form, Row, Select } from 'antd';
import { ButtonComponent } from 'components';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd/lib';

const { Option } = Select;

interface SearchReportFormProps {
  form: FormInstance;
  onSearch: () => void;
}

const SearchReportForm = ({ form, onSearch }: SearchReportFormProps): JSX.Element => {
  const [years, setYears] = useState<any[]>([]);
  const filterOption = (input: string, option?: { label: string; value: number }) => {
    return option?.value.toString().includes(input) || false;
  };
  useEffect(() => {
    const listYear: any[] = [];
    for (let i = 2559; i <= new Date().getFullYear() + 544; i++) {
      listYear.push({
        value: i,
        label: i,
      });
    }
    setYears(listYear);


  }, []);

  return <>
    <Form form={form} style={{ width: '100%' }} onFinish={() => onSearch()}>
      <Row>
        <Col span={12}>
          <Form.Item name='year' label='ปี พ.ศ.'>
            <Select
              showSearch
              placeholder='ระบุปี พ.ศ.'
              allowClear
              options={years}
              filterOption={filterOption}
              style={{
                width: '250px',
              }}></Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name='quarter' label='ไตรมาสที่'>
            <Select
              placeholder='ระบุไตรมาส'
              style={{
                width: '250px',
              }}>
              <Option value='1'>1</Option>
              <Option value='2'>2</Option>
              <Option value='3'>3</Option>
              <Option value='4'>4</Option>
            </Select>
          </Form.Item>
        </Col>

      </Row>
      <Row style={{
        marginTop: '24px',
      }} justify={'center'} gutter={[16, 16]}>
        <Col>
          <ButtonComponent htmlType='submit'>
            <SearchOutlined />
            ค้นหาข้อมูล
          </ButtonComponent>
        </Col>
        <Col>
          <ButtonComponent htmlType='reset' onClick={() => {
            form.resetFields(['year', 'quarter']);
          }}>
            <ClearOutlined />
            ล้างเงื่อนไข
          </ButtonComponent>
        </Col>
      </Row>
    </Form>
  </>;
};

export default SearchReportForm;
