import { Col, Form, Row, Table } from 'antd';

import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

import { formLayout } from 'constants/form';

import { getColumns } from './columns';

const ContractFacilitiesTable = () => {
  const {
    facilityTableFormRef,
    selectedFacilities,
    currentContFacilities,
    onSelectMultipleFacilities,
    onEditContractFacility,
    isDisabled,
  } = useContractFacilitiesContext();

  const rowSelection = {
    onChange: onSelectMultipleFacilities,
    selectedRowKeys: selectedFacilities,
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form
          {...formLayout}
          labelWrap
          size="large"
          name="facilityTableForm"
          form={facilityTableFormRef}
          // disabled={isDisabled}
        >
          <Table
            bordered
            size="small"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={getColumns({ onEditContractFacility, isDisabled })}
            dataSource={currentContFacilities ? currentContFacilities : []}
            rowKey={(value) => value.key}
            pagination={false}
            scroll={{ x: true }}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default ContractFacilitiesTable;
