import { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Table } from 'antd';

import useModal from 'hooks/useModal';
import { useApi, TApiResponse } from 'hooks/useApi';
import { CurrencyData } from 'api/master/interface';
import { createNewCurrency, listAllCurrencies, updateCurrency } from 'api/master/adminData';

import PrimaryButton from 'components/PrimaryButton';
import { HeaderTitleComponent } from 'components';

import EditModal from '../components/EditModal';
import { columns } from '../components/columns';

const ListCurrencies = () => {
  const [formRef] = Form.useForm();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const {
    makeRequest: _getListCurrencies,
    data: currencies = [],
    loading: isLoadingCurrencyList,
  }: TApiResponse = useApi();

  const { makeRequest: _updateCurrency, loading: isUpdatingCurrency }: TApiResponse = useApi();

  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);

  const onOpenEditModal = (record: any) => {
    setSelectedRecord(record);
    showModal();
  };

  const onCloseModal = () => {
    setSelectedRecord(undefined);
    formRef.resetFields();
    handleCancel();
  };

  const onFetchCurrencyList = useCallback(() => {
    _getListCurrencies(() => listAllCurrencies());
  }, [_getListCurrencies]);

  const onSaveCurrency = useCallback(
    async (values: CurrencyData) => {
      if (values.currencyId) {
        await _updateCurrency(() => updateCurrency(values.currencyId, values));
      } else {
        await _updateCurrency(() => createNewCurrency(values));
      }
      setSelectedRecord(undefined);
      handleCancel();
      formRef.resetFields();
      onFetchCurrencyList();
    },
    [handleCancel, _updateCurrency, onFetchCurrencyList, formRef]
  );

  useEffect(() => {
    onFetchCurrencyList();
  }, [onFetchCurrencyList]);

  return (
    <div>
      <EditModal
        isModalOpen={isModalOpen}
        isUpdating={isUpdatingCurrency}
        selectedRecord={selectedRecord}
        onCloseModal={onCloseModal}
        onSaveRecord={onSaveCurrency}
        titleName={'แก้ไขข้อมูลสกุลเงิน'}
        fieldName={'currencyName'}
        fieldTitle={'ชื่อสกุลเงิน'}
        formRef={formRef}
      />
      <HeaderTitleComponent>จัดการข้อมูลสกุลเงิน</HeaderTitleComponent>
      <div className="content-box" style={{ paddingLeft: 0 }}>
        <PrimaryButton icon={<PlusOutlined />} onClick={showModal}>
          เพิ่มสกุลเงิน
        </PrimaryButton>
      </div>
      <Table
        columns={columns({ onOpenEditModal, titleName: 'สกุลเงิน', valueName: 'currencyName' })}
        dataSource={currencies}
        loading={isLoadingCurrencyList}
        rowKey={'currencyId'}
        size="middle"
        pagination={false}
      />
    </div>
  );
};

export default ListCurrencies;
