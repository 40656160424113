import { Col, Form, Modal, Row, Spin } from 'antd';

import useDialogAlert from 'hooks/useDialogAlert';
import { useApi, TApiResponse } from 'hooks/useApi';
import { createAndAssignContract } from 'api/contract';
import { formLayout } from 'constants/form';
import { dayJsformatDateObjToString } from 'utils/date';

import ContractTypeDropdown from '../ContractTypeDropdown';
import RenterDropdown from '../RenterDropdown';
import AssigneesDropdown from '../AssigneesDropdown';
import Footer from './Footer';
import { ICreateAndAssingContract } from 'api/contract/interface';

type ReviewNewContractModalProps = {
  isModalOpen: boolean;
  handleCancel: Function;
};

const ReviewNewContractModal = ({ isModalOpen, handleCancel }: ReviewNewContractModalProps) => {
  const [contractFormRef] = Form.useForm();
  const { error, success } = useDialogAlert();
  const { makeRequest, loading }: TApiResponse = useApi();

  const onCreateNewContract = async () => {
    const values = contractFormRef.getFieldsValue();
    const data: ICreateAndAssingContract = {
      offerFormType: values?.offerFormType,
      ownerId: values?.ownerId,
      officerIds: values?.assignees,
      assignDate: dayJsformatDateObjToString(values?.assignDate),
    };
    try {
      await makeRequest(() => createAndAssignContract(data));
      success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
    } catch (errorInfo: any) {
      console.log(error);
      error({
        header: 'มีข้อผิดพลาดเกิดขึ้น',
        sub: errorInfo?.errors[0],
      });
    }
    contractFormRef.resetFields();
    handleCancel();
  };

  return (
    <Modal
      title="เลือกประเภทข้อเสนอ"
      open={isModalOpen}
      width={1000}
      onCancel={() => handleCancel()}
      footer={<Footer handleCancel={handleCancel} onConfirm={() => contractFormRef.submit()} />}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          labelWrap
          form={contractFormRef}
          name="assigneesForm"
          size="large"
          labelAlign="left"
          onFinish={onCreateNewContract}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <ContractTypeDropdown label="รูปแบบสัญญา" labelCol={{ span: 8 }} />
            </Col>
            <Col span={12}>
              <RenterDropdown
                label="ผู้ประกอบการ"
                name="ownerId"
                rules={[{ required: true, message: `กรุณาเลือกผู้ประกอบการ` }]}
                isLabelInValue={false}
                labelCol={{ span: 6 }}
              />
            </Col>
            <AssigneesDropdown isRequiredAssignees={true} />
          </Row>
        </Form>
      </Spin>
    </Modal>
  );
};

export default ReviewNewContractModal;
