import React from 'react';
import SearchOperatorsForm from './components/SearchOperatorsForm';
import { SearchOperatorsProps } from 'features/OperatorsLMS/interfaces';

const SearchOperators = ({
  formSearch,
  onReset,
  onSearchOperators,
  operatorsList,
}: SearchOperatorsProps) => {
  return (
    <div className="content-box">
      <SearchOperatorsForm
        formSearch={formSearch}
        onReset={onReset}
        onSearchOperators={onSearchOperators}
        operatorsList={operatorsList}
      />
    </div>
  );
};

export default SearchOperators;
