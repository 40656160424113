import { Table } from 'antd';

import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';
import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';

import { getColumns, expandedRowRenderer } from './columns';

const RIOServiceTable = () => {
  // NOTE: RIO, RAO
  const { isDisabled } = useOfferDataContext();
  const { currentOfferFacilities } = useOfferFacilititesContext();
  const {
    isLoadingServiceAnnounceRate,
    selectedMultipleServices,
    currentOfferServices,
    onSelectMultipleServices,
    onEditRIOService,
  } = useOfferServicesContext();

  const rowSelection = {
    onChange: onSelectMultipleServices,
    selectedRowKeys: selectedMultipleServices,
  };

  return (
    <div>
      <Table
        loading={isLoadingServiceAnnounceRate}
        bordered
        size="middle"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={getColumns({ onEditRIOService, isDisabled, currentOfferFacilities })}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) =>
            expandedRowRenderer({ record, index, indent, expanded }),
          defaultExpandedRowKeys: ['0'],
        }}
        dataSource={currentOfferServices ? currentOfferServices : []}
        rowKey={(value) => value.key}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default RIOServiceTable;
