import { CloseOutlined } from '@ant-design/icons';

import useModal from 'hooks/useModal';
import { CancelContractModalProvider } from 'features/Contract/context/CancelContractModalContext';

import { IContAttachFiles } from 'features/Contract/interfaces';

import PrimaryButton from 'components/PrimaryButton';
import CancelContractModal from '../CancelContractModal';

type CancelContractButtonProps = {
  offerFormType: string;
  conFormId: string | undefined;
  contAttachFiles: IContAttachFiles[];
  setContractData: React.Dispatch<any>;
  isActiveContract: boolean;
  isInquiryPage?: boolean;
};

const CancelContractButton = ({
  offerFormType,
  conFormId,
  contAttachFiles,
  setContractData,
  isActiveContract,
  isInquiryPage = false,
}: CancelContractButtonProps) => {
  const { isModalOpen, showModal, handleCancel } = useModal();
  return (
    <>
      <CancelContractModalProvider
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        offerFormType={offerFormType}
        conFormId={conFormId}
        contAttachFiles={contAttachFiles}
        setContractData={setContractData}
      >
        <CancelContractModal />
      </CancelContractModalProvider>
      <PrimaryButton
        disabled={!isActiveContract || isInquiryPage}
        icon={<CloseOutlined />}
        onClick={() => showModal()}
      >
        ยกเลิกสัญญา
      </PrimaryButton>
    </>
  );
};

export default CancelContractButton;
