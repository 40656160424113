import { ClearOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import buttonCSS from 'styles/components/button.module.css';
import ExcelJS, { Border } from 'exceljs';
import { useApi } from 'hooks/useApi';
import { getAllFormStatus, getOverviewReport } from 'api/disagreement';
import { Operator } from 'api/operator/interface';
import { getAllOperators } from 'api/operator';
import { formatDateToOnlyBCYear } from 'utils/date';
import { GetOverviewReportResponse } from 'api/disagreement/interface';

interface OptionFormStatus {
  value: string | number;
  label: string;
}

interface Options {
  value: number;
  label: number;
}

export interface SearchReportProps {
  onSearchReport: Function;
  onReset: Function;
  formReport: FormInstance<any>;
}

const defaultOptions = (): Options[] => {
  const startAt = 2563;
  const options: Options[] = [];

  for (let i = startAt; i < parseInt(formatDateToOnlyBCYear(new Date().toString())) + 2; i++) {
    options.push({
      value: i,
      label: i,
    });
  }
  return options;
};

const SearchOverviewForm = ({ formReport, onReset, onSearchReport }: SearchReportProps) => {
  const { makeRequest: getAllStatusRequest } = useApi();
  const { makeRequest: getOperatorsRequest } = useApi();
  const { makeRequest: getOverviewRequest } = useApi();
  const [yearOptions] = useState(defaultOptions);
  const [operators, setOperators] = useState<OptionFormStatus[]>([]);
  const [options, setOptions] = useState<OptionFormStatus[]>([]);

  const getOperators = useCallback(async () => {
    return await getOperatorsRequest(() => {
      return getAllOperators();
    });
  }, [getOperatorsRequest]);

  useEffect(() => {
    getOperators().then(res => {
      const operators: Operator[] = res.data.data;
      setOperators(operators.map(i => ({
        value: i.id,
        label: i.operName,
      })));
    });
  }, [getOperators]);

  useEffect(() => {
    getAllStatusRequest(async () => {
      const res = await getAllFormStatus();
      const newOptions: OptionFormStatus[] = [];
      res.data.data.forEach(i => {
        newOptions.push({
          value: i.formStatus,
          label: i.formStatusName,
        });
      });
      setOptions(newOptions);
      return res;
    });
  }, [getAllStatusRequest]);


  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  const exportExcel = async (payload: GetOverviewReportResponse, startYear: number, endYear: number) => {
    const header = [
      'ลำดับที่',
      'ปี พ.ศ.',
      'ข้อพิพาทที่',
      'ผู้ร้อง',
      'ผู้ถูกร้อง',
      'เรื่อง',
      'สถานะข้อพิพาท',
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    const headerRow = worksheet.addRow(['รายงานภาพรวมกระบวนการระงับข้อพิพาท']);
    let subHeaderRow;

    if (startYear && endYear) {
      subHeaderRow = worksheet.addRow([`ปี พ.ศ. ตั้งแต่ ${startYear} ถึง ${endYear}`]);
    } else if (startYear) {
      subHeaderRow = worksheet.addRow([`ปี พ.ศ. ตั้งแต่ ${startYear}`]);
    } else if (endYear) {
      subHeaderRow = worksheet.addRow([`ปี พ.ศ. ถึง ${endYear}`]);
    } else {
      subHeaderRow = worksheet.addRow(['']);
    }

    worksheet.mergeCells(1, 1, 1, header.length);
    worksheet.mergeCells(2, 1, 2, header.length);
    const border: Border = {
      style: 'thin',
      color: {
        argb: '000000',
      },
    };
    [headerRow, subHeaderRow].forEach((i, index) => {
      if (index === 0) {
        i.getCell(1).border = {
          top: border,
          left: border,
          right: border,
        };
        i.getCell(1).font = { bold: true, size: 16 };
      } else {
        i.getCell(1).border = {
          left: border,
          bottom: border,
          right: border,
        };
        i.getCell(1).font = { bold: true, size: 14 };
      }

      i.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
      i.getCell(1).alignment = { horizontal: 'center' };
    });


    // Add header columns
    const headerRef = worksheet.addRow(header);
    headerRef.alignment = {
      wrapText: true,
      vertical: 'top',
    };

    // Add data rows
    payload.data.data.overviewReports.forEach((row, index) => {
      const disNo = row.disagreeNo && row.yearly ? `${row.disagreeNo}/${row.yearly}` : '';
      worksheet.addRow([index + 1, row.yearly, disNo, row.petitionerName, row.accusedName, row.topic, row.formStatusName]);
    });

    // Auto-fit column widths
    worksheet.columns.forEach((column) => {
      column.width = 15;
    });
    worksheet.addRow(['', '', '', '', '', '']);

    payload.data.data.statusDetail.forEach(status => {
      worksheet.addRow(['', '', '', '', status.status + ': ', '  ' + status.total + ' เรื่อง']);
    });

    // Generate buffer from workbook
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a blob and download the file
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = 'overview_report.xlsx';
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const onExport = async () => {
    const { petitionerId, accusedId, startYear, endYear, status } = formReport.getFieldsValue();
    await getOverviewRequest(async () => {
      const res = await getOverviewReport({
        petitionerId,
        startYear,
        endYear,
        accusedId,
        formStatus: status,
      });
      await exportExcel(res, startYear, endYear);
      return res;
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Form
        {...layout}
        labelWrap
        form={formReport}
        requiredMark={false}
        name='searchOfferForm'
        onFinish={(val: any) => onSearchReport(val)}
        style={{ width: '70vw' }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign='right'
              label='ผู้ร้อง'
              name='petitionerId'
            >
              <Select
                showSearch
                filterOption={(input, option) => {
                  return (option?.label.indexOf(input) || -1) >= 0;
                }}
                placeholder='ผู้ร้อง'
                options={operators}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign='right'
              label='ผู้ถูกร้อง'
              name='accusedId'
            >
              <Select
                showSearch
                filterOption={(input, option) => {
                  return (option?.label.indexOf(input) || -1) >= 0;
                }}
                placeholder='ผู้ร้อง'
                options={operators}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign='right'
              label='สถานะข้อพิพาท'
              name='status'
            >
              <Select placeholder='สถานะ' options={options} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              colon
              labelAlign='right'
              label='ปี พ.ศ. ตั้งแต่'
              name='startYear'
            >
              <Select placeholder='ระบุปี พ.ศ.'
                      options={yearOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign='right'
              label='ปี พ.ศ. ถึง'
              name='endYear'
            >
              <Select placeholder='ระบุปี พ.ศ.'
                      options={yearOptions} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify='center' style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type='primary'
              shape='round'
              icon={<FileDoneOutlined />}
              onClick={onExport}
              size='large'
              htmlType='submit'
            >
              พิมพ์รายงาน
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type='primary'
              shape='round'
              icon={<ClearOutlined />}
              size='large'
              onClick={() => onReset()}
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchOverviewForm;
