import { DownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';

export function columnsDetailFileFunction(handledownloadFileRao: (record: any) => void) {
  const columnsDetailFile: ColumnsType<any> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 100,
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: 'กลุ่มเอกสาร',
      key: 'docName',
      align: 'center',
      width: 300,
      render: (_, record) => <div style={{ textAlign: 'left' }}>{record.docName}</div>,
    },

    {
      title: 'รายการเอกสาร',
      key: 'attachFileName',
      dataIndex: 'attachFileName',
      align: 'center',
    },
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      render: (_, record) => {
        return (
          <DownloadOutlined
            className="plusCircle-outlined-css"
            style={{ cursor: 'pointer' }}
            onClick={() => handledownloadFileRao(record)}
          />
        );
      },
    },
  ];
  return columnsDetailFile;
}
