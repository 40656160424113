import axiosInstance from 'utils/axios';
import { ListContractQuery } from './interface';

export const listContractInquiry = (query: ListContractQuery) => {
  return axiosInstance.get(`/contract/inquiry`, {
    params: query,
  });
};

export const listChildContractInquiry = (parentConId: string | number) => {
  return axiosInstance.get(`/contract/inquiry/${parentConId}`);
};
