import { Col, Form, Row } from 'antd';
import { ClearOutlined, PrinterOutlined } from '@ant-design/icons';

import useAuditResultsReport from './hooks/useAuditResultsReport';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import PrimaryButton from 'components/PrimaryButton';
import { ThaiDatepickerComponent } from 'components';

import { formLayout } from 'constants/form';
import { useOutletContext } from 'react-router-dom';
import { useEffect } from 'react';

const OfferAuditResultsReport = () => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { formRef, onDownloadReport, onReset } = useAuditResultsReport();

  useEffect(() => {
    setHeaderTitle('รายงานผลงานส่วนงานข้อเสนอ');
  }, [setHeaderTitle]);

  return (
    <div>
      <HeaderTitle>จัดทำรายงาน</HeaderTitle>
      <div className="content-box">
        <Form labelWrap name="report004" size="large" form={formRef} {...formLayout}>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="วันที่ผู้ประกอบการนำส่ง"
                name="startDate"
                labelCol={{ span: 10 }}
                rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
              >
                <ThaiDatepickerComponent />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="ถึงวันที่"
                name="endDate"
                labelCol={{ span: 6 }}
                rules={[{ required: true, message: 'กรุณาเลือกวันที่' }]}
              >
                <ThaiDatepickerComponent />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
            <Col>
              <PrimaryButton icon={<PrinterOutlined />} onClick={() => onDownloadReport()}>
                จัดทำรายงาน
              </PrimaryButton>
            </Col>
            <Col>
              <PrimaryButton icon={<ClearOutlined />} onClick={() => onReset()}>
                ล้างเงื่อนไข
              </PrimaryButton>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default OfferAuditResultsReport;
