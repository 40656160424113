import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Row, Col, Select, Form, Input, Radio, Space } from 'antd';
import { ButtonComponent } from 'components';
import React, { useEffect, useState } from 'react';

import { formLayout } from 'constants/form';
import styleds from './Index.module.css';
import styled from 'styled-components';
import { IUserFormForm } from 'features/ManageUser/interfaces';
import InputText from 'components/InputTextComponent';
const Div = styled.div`
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;
const { Option } = Select;
const ModalUserForm = ({
  formUser,
  record,
  roles,
  onSubmitForm,
  onCancelModalUser,
  officerLeaderData,
}: IUserFormForm) => {
  const [idDisabled, setIdDisabled] = useState<boolean>(false);
  useEffect(() => {
    if (record && Object.keys(record).length !== 0) {
      setIdDisabled(true);
      const rolesArray = [];
      for (let i = 0; i < record.roles.length; i++) {
        rolesArray.push(record.roles[i].groupCode);
      }
      formUser.setFieldsValue({
        userLogin: record.userLogin,
        referenceNo: record.referenceNo,
        name: { title: record.titleName, titleName: record.firstName },
        lastName: record.lastName,
        positionName: record.positionName,
        departmentName: record.departmentName,
        email: record.email,
        phoneNumber: record.phoneNumber,
        leaderIdNo: record.leaderIdNo,
        roles: rolesArray,
        status: record.status ? 1 : 2,
      });
    } else {
      setIdDisabled(false);
    }
  }, [formUser, record]);
  return (
    <>
      <Form
        {...formLayout}
        labelWrap
        onFinish={(val) => {
          onSubmitForm();
        }}
        requiredMark={false}
        form={formUser}
        name="contractForm"
        size="large"
        // disabled={isDisabled}
      >
        <Div>
          <Row gutter={[16, 16]}>
            <Col span={16}></Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="รหัสผู้ใช้งาน"
                name="userLogin"
                rules={[{ required: true, message: 'กรุณากรอกรหัสผู้ใช้งาน' }]}
              >
                <InputText disabled={idDisabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="รหัสพนักงาน"
                name="referenceNo"
                rules={[{ required: true, message: 'กรุณากรอกรหัสพนักงาน' }]}
              >
                <InputText disabled={idDisabled} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ชื่อ">
                <Input.Group compact style={{ display: 'flex' }}>
                  <Form.Item
                    initialValue={'นาย'}
                    name={['name', 'title']}
                    rules={[{ required: true, message: '*เลือกคำนำหน้าชื่อ' }]}
                    noStyle
                  >
                    <Select size="large" style={{ width: '100px' }}>
                      <Option value="นาย">นาย</Option>
                      <Option value="นาง">นาง</Option>
                      <Option value="นางสาว">นางสาว</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={['name', 'titleName']}
                    rules={[
                      {
                        required: true,
                        message: 'กรุณากรอกชื่อ',
                      },
                    ]}
                    noStyle
                  >
                    <InputText />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="นามสกุล"
                name="lastName"
                rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
              >
                <InputText />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="ตำแหน่ง"
                name="positionName"
                rules={[{ required: true, message: 'กรุณาเลือกตำแหน่ง' }]}
              >
                <InputText />
                {/* <Select
                  style={{ width: '100%' }}
                  size="large"
                  options={
                    [
                      {
                        value: 'ข้อเสนอ',
                        label: 'ข้อเสนอ',
                      },
                      {
                        value: 'กลุ่มข้อพิพาก',
                        label: 'กลุ่มข้อพิพาก',
                      },
                      {
                        value: 'สัญญา',
                        label: 'สัญญา',
                      },
                    ]
                  
                  }
                
                  allowClear
                /> */}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="หน่วยงาน"
                name="departmentName"
                rules={[{ required: true, message: 'กรุณาเลือกหน่วยงาน' }]}
              >
                <InputText />
                {/* <Select
                  style={{ width: '100%' }}
                  size="large"
                  options={[
                    {
                      value: 'ข้อเสนอ',
                      label: 'ข้อเสนอ',
                    },
                    {
                      value: 'กลุ่มข้อพิพาก',
                      label: 'กลุ่มข้อพิพาก',
                    },
                    {
                      value: 'สัญญา',
                      label: 'สัญญา',
                    },
                  ]}
                  // onChange={(val) => {
                  //   if (contServesData) {
                  //     const [newServe] = facilitiesList.filter((item) => item.facId === val);
                  //     onChangeSelectedFacilitie(val, newServe, record);
                  //   }
                  // }}
                  allowClear
                /> */}
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="อีเมลล์"
                name="email"
                rules={[{ required: false, message: 'กรุณากรอกอีเมลล์' }]}
              >
                <InputText />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="เบอร์ติดต่อ"
                name="phoneNumber"
                rules={[{ required: false, message: 'กรุณากรอกเบอร์ติดต่อ' }]}
              >
                <InputText />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Form.Item
                initialValue={null}
                colon
                labelAlign="right"
                label="หัวหน้าทีม"
                name="leaderIdNo"
                rules={[{ required: true, message: 'กรุณาเลือกหัวหน้าทีม' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  options={
                    officerLeaderData
                      ? officerLeaderData.map((item) => ({
                          value: item.referenceNo,
                          label: item.firstName + ' ' + item.lastName,
                        }))
                      : []
                  }
                  allowClear
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}></Col> */}
            <Col span={12}>
              <Form.Item
                colon
                labelAlign="right"
                label="กลุ่มผู้ใช้งาน"
                name="roles"
                rules={[{ required: true, message: 'กรุณาเลือกกลุ่มผู้ใข้งาน' }]}
              >
                <Select
                  style={{ width: '100%' }}
                  size="large"
                  mode="multiple"
                  options={roles.map((val) => ({ value: val.groupCode, label: val.groupName }))}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item colon labelAlign="right" label="สถานะ" name="status" initialValue={1}>
                <Radio.Group>
                  <Space direction="horizontal">
                    <Radio value={1}>ใช้งาน</Radio>
                    <Radio value={2}>ไม่ใช้งาน</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <div className={styleds.divButtonGroup}>
            <ButtonComponent
              htmlType="button"
              onClick={() => {
                onCancelModalUser();
              }}
            >
              <CloseOutlined />
              ยกเลิกรายการ
            </ButtonComponent>
            <ButtonComponent htmlType="submit">
              <SaveOutlined />
              บันทึกรายการ
            </ButtonComponent>
          </div>
        </Div>
      </Form>
    </>
  );
};

export default ModalUserForm;
