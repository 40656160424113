import { Button, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { OTHER_OPTION } from 'features/Offer/constant';
import { IOfferFacilitiesServes, IOfferFacility } from 'features/Offer/interfaces';

type GetColumnsProps = {
  onEditFacility: (record: any) => void;
};

const getServiceWithServType = (item: IOfferFacilitiesServes, index: number) => {
  let serviceName = item.raoServName;
  if (item.raoServName === OTHER_OPTION) serviceName = item.raoServiceDesc;
  return (
    <div key={index}>
      - {item.servTypeName && <Tag style={{ fontSize: 16 }}>{item.servTypeName}</Tag>} {serviceName}
    </div>
  );
};

export const getColumns = ({ onEditFacility }: GetColumnsProps) => {
  const columns: ColumnsType<IOfferFacility> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '100px',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'ประเภทสิ่งอำนวยความสะดวก',
      dataIndex: 'facName',
      key: 'facName',
      width: '50%',
      render: (value, record) => {
        if (value === OTHER_OPTION) return <>{record.facDesc}</>;
        return <>{value}</>;
      },
    },
    {
      title: 'บริการ',
      dataIndex: 'offerFacilitiesServes',
      key: 'offerFacilitiesServes',
      render: (value) => {
        return value.map((item: IOfferFacilitiesServes, index: any) => {
          return getServiceWithServType(item, index);
        });
      },
    },
    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      width: '40px',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Button
              size="middle"
              icon={<EditOutlined />}
              onClick={() => {
                onEditFacility(record);
              }}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};
