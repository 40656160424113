import { useCallback, useEffect, useState } from 'react';
import { message } from 'antd';

import { useApi } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';

type useReportTableProps = {
  pageSize: number;
  currentPageNumber: number;
  listReportApi: Function;
  getReportByJobIdApi: Function;
};

const useReportTable = ({
  currentPageNumber,
  pageSize,
  listReportApi,
  getReportByJobIdApi,
}: useReportTableProps) => {
  const { error } = useDialogAlert();
  const [queryParams, setQueryParams] = useState({} as any);
  const [messageApi, contextHolder] = message.useMessage();
  const { makeRequest, data: reportList, loading: isFetching, pageable, setData } = useApi();
  const { makeRequest: _onGetReportStatus, loading: isFetchingReportStatus } = useApi();

  const onFetchReportList = useCallback(
    ({ currentPageNumber, pageSize, ...queryParams }: any) => {
      try {
        makeRequest(() => listReportApi({ currentPageNumber, pageSize, ...queryParams }));
      } catch (e) {
        console.error(e);
        error({
          header: 'ไม่สามารถดึงข้อมูลรายงานได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [error, makeRequest, listReportApi]
  );

  const onGetReportStatus = useCallback(
    async (jobId: string) => {
      try {
        return await _onGetReportStatus(() => getReportByJobIdApi(jobId));
      } catch (e) {
        console.error(e);
        error({
          header: 'ไม่สามารถรีเฟรชสถานะรายงานได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [_onGetReportStatus, error, getReportByJobIdApi]
  );

  const onRefreshReportStatus = useCallback(
    async (jobId: string) => {
      const report = await onGetReportStatus(jobId);
      messageApi.open({
        type: 'success',
        content: 'รีเฟรชสถานะรายงานสำเร็จ',
      });
      let newReportList = reportList.map((item: any) => {
        if (item.jobId === jobId) {
          return report?.data?.data;
        } else {
          return item;
        }
      });
      setData(newReportList);
    },
    [onGetReportStatus, reportList, setData, messageApi]
  );

  useEffect(() => {
    onFetchReportList({ currentPageNumber, pageSize, ...queryParams });
  }, [onFetchReportList, currentPageNumber, pageSize, queryParams]);

  return {
    reportList,
    isFetching,
    isFetchingReportStatus,
    pageable,
    onRefreshReportStatus,
    contextHolder,
    setQueryParams,
  };
};

export default useReportTable;
