import { useContext } from 'react';
import { OfferDataContext } from '../contexts/OfferDataContext';

const useOfferDataContext = () => {
  const context = useContext(OfferDataContext);
  if (!context) throw new Error('OfferDataContext must be use inside OfferDataProvider');
  return context;
};

export default useOfferDataContext;
