import axiosInstance, { LogoutInstance } from 'utils/axios';
import { UserInfoQuery } from './interfaces';

export const getUserInfo = (query: UserInfoQuery) => {
  return axiosInstance.get(`userInfo`, {
    params: query,
  });
};

export const officerLogin = ({ username, password }: { username: string; password: string }) => {
  return axiosInstance.post(`login`, {
    username,
    password,
  });
};

export const officerLogout = () => {
  return LogoutInstance.post(`logout`);
};

export const getAvailableProfile = () => {
  return axiosInstance.get(`userinfo/operators`);
};
