import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import GuestGuard from './Guards/GuestGuard';
import AuthenticatedLayout from './Authenticated.layout';
import ForbiddenLayout from './ForbiddenLayout.layout';
import LoadComponent from 'components/LoadingComponent/LoadingComponent';
import PortalLoginLayout from './PortalLogin.layout';
import { mainRoutes, subRoutes } from './routesMapping';

const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<LoadComponent />}>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import('./Login/Login')));
const Forbidden403 = Loadable(lazy(() => import('components/Forbidden403')));
const Page404 = Loadable(lazy(() => import('./Page404/Page404')));
const Profile = Loadable(lazy(() => import('features/Profile')));
const Logout = Loadable(lazy(() => import('features/Logout')));

export default function Router() {
  return (
    <Routes>
      <Route element={<GuestGuard />}>
        <Route path="login" element={<Login />} />
        <Route path="logout" element={<Logout />} />
      </Route>
      <Route element={<PortalLoginLayout />}>
        <Route path="profile" element={<Profile />} />
        <Route path="/" element={<p>กรุณาเลือกเมนู...</p>} />
      </Route>
      <Route element={<ForbiddenLayout />}>
        <Route path="forbidden" element={<Forbidden403 />} />
      </Route>
      {mainRoutes.map((route) => {
        return (
          <Route key={route.path} element={<AuthenticatedLayout permission={route.permission} />}>
            <Route {...route} />
          </Route>
        );
      })}
      {subRoutes.map((route) => {
        return (
          <Route key={route.path} element={<AuthenticatedLayout permission={route.permission} />}>
            <Route {...route} />
          </Route>
        );
      })}
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
