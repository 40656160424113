import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { stateCode } from 'constants/stateCode';
import { IStateData } from 'features/Contract/interfaces';

import buttonCSS from 'styles/components/button.module.css';
import StepFormWithDate from '../StepsForm/StepFormWithDate';
import StepFormWithStatus from '../StepsForm/StepFormWithStatus';
import StepFormWithApproveFile from '../StepsForm/StepFormWithApproveFile';
import StepFormWithLink from '../StepsForm/StepFormWithLink';
import StepFormWithApprovedDate from '../StepsForm/StepFormWithApprovedDate';
import StepFormWithEmailFile from '../StepsForm/StepFormWithEmailFile';

const DISABLE_SUBMIT_BUTTON = [
  stateCode.step1,
  stateCode.step2,
  stateCode.step3,
  // stateCode.step6,
  // stateCode.step12,
  stateCode.step17,
];

const DISABLE_FORM_STATE = [stateCode.step3, stateCode.step17];

type StepsModalProps = {
  isModalOpen: boolean;
  handleCancel: ((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
  stepModalIndex: number | undefined;
  stepData: IStateData | undefined;
  isLoadingUpdateState: boolean;
  onUpdateOfferState: Function;
  conFormPublicUrl: string | undefined;
};

const StepsModal = ({
  isModalOpen,
  handleCancel,
  stepModalIndex,
  stepData,
  isLoadingUpdateState,
  onUpdateOfferState,
  conFormPublicUrl,
}: StepsModalProps) => {
  const [form] = Form.useForm();
  const [refTrigger, setRefTrigger] = useState(0);

  /**
   * NOTE: This is the logic to disable the submit button
   * -------------------------------------------------------------
   * Fields           | status  |	Can Open Modal ? | Modal Status
   * -------------------------------------------------------------
   * isCurrentState   | TRUE    | YES              |  ENABLE
   * isCompletedState | TRUE    | YES              |  ENABLE
   * -------------------------------------------------------------
   * isCurrentState   | TRUE    | YES              |  ENABLE
   * isCompletedState | FALSE   | YES              |  ENABLE
   * -------------------------------------------------------------
   * isCurrentState   | FALSE   | YES              |  DISABLE
   * isCompletedState | TRUE    | YES              |  DISABLE
   * -------------------------------------------------------------
   * isCurrentState   | FALSE   | NO               |  (Cannot Open Modal)
   * isCompletedState | FALSE   | NO               |  (Cannot Open Modal)
   * -------------------------------------------------------------
   */
  const isDisable = useMemo(() => {
    if (stepModalIndex !== undefined) {
      if (DISABLE_FORM_STATE.includes(stepModalIndex)) return true;
    }
    if (stepData?.isCurrentState === false) return true;
    else return false;
  }, [stepData, stepModalIndex]);

  const isHideSubmitButton = useMemo(() => {
    if (stepModalIndex !== undefined) {
      // NOTE: Don't refactor this stepModalIndex, it might be 0 !
      if (isDisable || DISABLE_SUBMIT_BUTTON.includes(stepModalIndex)) return true;
    }
    return false;
  }, [isDisable, stepModalIndex]);

  const getStepForm = useCallback(() => {
    switch (stepModalIndex) {
      case stateCode.step3:
        return (
          <StepFormWithApprovedDate
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step8:
      case stateCode.step13:
        return (
          <StepFormWithDate
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step1:
      case stateCode.step2:
        return (
          <StepFormWithDate
            form={form}
            stepData={stepData}
            isDisable={true}
            isHideDateForm={true}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step6:
      case stateCode.step12:
        return (
          <StepFormWithEmailFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            refTrigger={refTrigger}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step4:
        return (
          <StepFormWithStatus
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
          />
        );
      case stateCode.step5:
      case stateCode.step7:
      case stateCode.step9:
      case stateCode.step11:
      case stateCode.step14:
      case stateCode.step16:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            isHideApproveForm={true}
            dateLabel="วันที่ส่งออก"
          />
        );
      case stateCode.step10:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            isShowActionStateOption={true}
            isHideApproveForm={false}
          />
        );
      case stateCode.step15:
        return (
          <StepFormWithApproveFile
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            isHideApproveForm={false}
            dateLabel="วันที่บันทึก"
          />
        );
      case stateCode.step17:
        return (
          <StepFormWithLink
            form={form}
            stepData={stepData}
            isDisable={isDisable}
            onUpdateOfferState={onUpdateOfferState}
            conFormPublicUrl={conFormPublicUrl}
          />
        );
      default:
        break;
    }
  }, [stepModalIndex, form, stepData, isDisable, onUpdateOfferState, conFormPublicUrl, refTrigger]);

  useEffect(() => {
    if (isModalOpen) setRefTrigger((prev) => prev + 1);
  }, [isModalOpen]);

  return (
    <div>
      <Modal
        title="การพิจารณาสัญญา"
        open={isModalOpen}
        onCancel={handleCancel}
        width={'80vh'}
        footer={
          !isHideSubmitButton && (
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SaveOutlined />}
              size="large"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              ยืนยันการพิจารณา
            </Button>
          )
        }
      >
        <Spin tip="กำลังดำเนินการ..." spinning={isLoadingUpdateState}>
          {getStepForm()}
        </Spin>
      </Modal>
    </div>
  );
};

export default StepsModal;
