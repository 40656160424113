import { Col, Modal, Row, Table } from 'antd';
import React from 'react';
import { OperatorLMS } from 'api/operator/interface';
import { ColumnsType } from 'antd/es/table';

interface ModalData {
  isOpen: boolean;
  setModal: Function;
  operatorLMS?: OperatorLMS & {
    status: boolean;
  };
}

export interface Data {
  operatorName: string;
  taxId: string;
  registerAddress: string;
  licenseNo: string;
  licenseGroupType: string;
  licenseType: string;
  effectiveYear: string;
  issueDate: string;
  expireDate: string;
  serviceId: string;
  serviceName: string;
  status: boolean;
  statusDescription: string;
}


const columns: ColumnsType<Data> = [
  {
    title: 'เลขที่ใบอนุญาต',
    dataIndex: 'licenseNo',
    key: 'licenseNo',
  },
  {
    title: 'ประเภทใบอนุญาต',
    dataIndex: 'licenseType',
    key: 'licenseType',
    render: (value, record, index) => <div>{record.licenseGroupType + ' ' + record.licenseType}</div>,
  },
  {
    title: 'บริการ',
    dataIndex: 'serviceName',
    key: 'serviceName'
  },
  {
    title: 'วันที่เริ่มต้น - สิ้นสุด',
    dataIndex: 'issueDate',
    key: 'issueDate',
    render: (value, record, index) => <div>{record.issueDate + (record.expireDate==null?'': ('-' +record.expireDate))}</div>,
  },
  {
    title: 'สถานะ',
    dataIndex: 'statusDescription',
    key: 'statusDescription'
  },
];

const ModalOperators = ({
                          isOpen,
                          operatorLMS,
                          setModal,
                        }: ModalData) => {
  return (
    <Modal
      width={'90%'}
      title='รายละเอียดผู้ประกอบการ'
      open={isOpen}
      onCancel={() => {
        setModal(false);
      }}
      footer={[]}
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          เลขประจำตัวผู้เสียภาษี: {operatorLMS?.taxId}
        </Col>
        <Col span={12}>
          รายชื่อผู้ประกอบการ: {operatorLMS?.operName}
        </Col>
        <Col span={12}>
          สถานะ: {operatorLMS?.status ? 'ปกติ' : 'สิ้นสุด'}
        </Col>
        <Col span={12}>
          ที่ตั้ง: {operatorLMS?.registerAddress}
        </Col>
        <Col span={24}>
          <Table dataSource={operatorLMS?.licenses} rowKey={'licenseNo'} columns={columns} pagination={false} />
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalOperators;
