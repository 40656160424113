import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonComponent } from 'components';
import { IOfficerUser, SearchResultProps } from 'features/ManageUser/interfaces';
import React from 'react';

const SearchResult = ({
  onOpenModalUser,
  officerData,
  currentPageNumber,
  loading,
  pageSize,
  pageable,
  setCurrentPageNumber,
  setPageSize,
  onUserRecord,
  onDeleteOfficer,
}: SearchResultProps) => {
  const columns: ColumnsType<IOfficerUser> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      width: 100,
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>รหัสผู้ใช้งาน</div>,
      dataIndex: 'userLogin',
      key: 'userLogin',
      width: 100,
    },
    {
      title: <div style={{ textAlign: 'center' }}>ชื่อผู้ใช้งาน</div>,
      key: 'name',
      width: 100,
      render: (_, record, index) => (
        <>
          {record.titleName}
          {record.firstName} {record.lastName}
        </>
      ),
    },
    {
      title: <div style={{ textAlign: 'center' }}>กลุ่มผู้ใช้งาน</div>,
      key: 'roles',
      width: 100,
      render: (_, record) => <>{record.roles.map((val) => val.groupName)}</>,
    },
    {
      title: 'สถานะ',
      key: 'status',
      align: 'center',
      width: 100,
      render: (_, record) => <>{record.status ? 'ใช้งาน' : 'ไม่ใช้งาน'}</>,
    },
    {
      title: 'แก้ไข',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      width: 100,
      render: (_, record, index) => (
        <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
          <div>
            {' '}
            <EditOutlined
              className="file-outlined-css"
              style={{ cursor: 'pointer' }}
              onClick={() => onUserRecord(record)}
            />
          </div>
          {/* <div>
            {' '}
            <DeleteOutlined
              className="file-outlined-css"
              onClick={() => onDeleteOfficer(record)}
              style={{ color: 'rgb(220, 53, 69)', cursor: 'pointer' }}
            />
          </div> */}
        </div>
      ),
    },
  ];
  return (
    <div className="padding-conten-main">
      <div className="padding-conten">
        <ButtonComponent htmlType="button" onClick={() => onOpenModalUser()}>
          <PlusOutlined />
          เพิ่มผู้ใช้งาน
        </ButtonComponent>
      </div>
      <Table
        columns={columns}
        dataSource={officerData}
        rowKey={'no'}
        size="middle"
        pagination={{
          onChange: (page, pageSize) => {
            setPageSize(pageSize);
            setCurrentPageNumber(page - 1);
          },
          onShowSizeChange: (_, pageSize) => {
            setPageSize(pageSize);
          },
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          total: pageable?.totalItems,
          showSizeChanger: true,
          defaultPageSize: pageSize,
        }}
      />
    </div>
  );
};

export default SearchResult;
