import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import React, { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import SearchUser from './components/SearchUser';
import SearchResult from './components/SearchResult';
import useSearch from './hooks/useSearch';
import useModal from './hooks/useModal';
import ModalUser from './components/ModalUser';
import { Form } from 'antd';
import { TApiResponse, useApi } from 'hooks/useApi';
import {
  OfficerUpdate,
  createOfficer,
  deleteOfficerByUserOfficerId,
  getMappingGroup,
  getOfficerLeader,
  getOfficerList,
} from 'api/officerList';

import { composeCreateDate, composeSaveData } from './utils/composeData';
import useDialogAlert from 'hooks/useDialogAlert';

const ListUser = () => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [formSearch] = Form.useForm();
  const [formUser] = Form.useForm();
  const {
    makeRequest: OfficerListRequest,
    data: officerData,
    pageable,
    loading,
    error,
    setError,
  }: TApiResponse = useApi();
  const { makeRequest: officerUpdateRequest }: TApiResponse = useApi();
  const { makeRequest: createOfficerRequest }: TApiResponse = useApi();
  const { makeRequest: deleteOfficerRequest }: TApiResponse = useApi();
  const { makeRequest: mappingGroupRequest, data: mappingGroupData }: TApiResponse = useApi();
  const { makeRequest: officerLeaderRequest, data: officerLeaderData }: TApiResponse = useApi();
  const { onSearchUser, groupCode, userLogin, onReset } = useSearch(formSearch, setError);
  const { isOpenModalUser, onCancelModalUser, onOpenModalUser, onUserRecord, recordData } =
    useModal(formUser);
  const { error: errorAlert, success } = useDialogAlert();
  const [pageSize, setPageSize] = useState(15);
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const getOfficer = useCallback(() => {
    OfficerListRequest(() =>
      getOfficerList({ userLogin, groupCode }).catch((e) =>
        errorAlert({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: e.error + ' ' + e.status,
        })
      )
    );
  }, [OfficerListRequest, userLogin, groupCode, errorAlert]);
  const getMappingGroupData = useCallback(async () => {
    await mappingGroupRequest(() => getMappingGroup());
  }, [mappingGroupRequest]);
  const getOfficerLeaderData = useCallback(async () => {
    await officerLeaderRequest(() => getOfficerLeader());
  }, [officerLeaderRequest]);
  const onSaveContract = useCallback(
    async (data: any) => {
      try {
        await officerUpdateRequest(() => OfficerUpdate(data));
        getOfficer();
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
        onCancelModalUser();
      } catch (errorInfo) {
        console.error(errorInfo);
        errorAlert({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [onCancelModalUser, errorAlert, getOfficer, officerUpdateRequest, success]
  );

  const onCreateOfficer = useCallback(
    async (data: any) => {
      try {
        await createOfficerRequest(() => createOfficer(data));
        getOfficer();

        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
        onCancelModalUser();
      } catch (errorInfo) {
        console.error(errorInfo);
        errorAlert({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [errorAlert, success, createOfficerRequest, getOfficer, onCancelModalUser]
  );
  const onDeleteOfficer = async (data: any) => {
    try {
      await deleteOfficerRequest(() => {
        return deleteOfficerByUserOfficerId({ officerId: data.officerId });
      });
      getOfficer();
      success({ header: 'ลบรายการสำเร็จ', sub: '' });
    } catch (e) {
      error({
        header: 'Unable to Delete Disagreement !',
        sub: 'please try this again',
      });
    }
  };

  const createOfficer_ = useCallback(async () => {
    if (recordData) {
      const editedData = composeSaveData({
        formData: formUser.getFieldsValue(true),
        recordData: recordData,
        roles: mappingGroupData,
      });

      await onSaveContract(editedData);
    } else {
      const newData = composeCreateDate({
        formData: formUser.getFieldsValue(true),
        roles: mappingGroupData,
      });

      await onCreateOfficer(newData);
    }
  }, [recordData, formUser, onSaveContract, mappingGroupData, onCreateOfficer]);
  const onSubmitForm = useCallback(async () => {
    try {
      await createOfficer_();
    } catch (errorInfo: any) {
      error({
        header: errorInfo ? errorInfo.message : 'กรุณากรอกฟอร์มให้ครบถ้วน',
        sub: '',
      });
    }
  }, [createOfficer_, error]);

  useEffect(() => {
    setHeaderTitle('จัดการข้อมูลผู้ใช้');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getOfficer();
  }, [getOfficer]);
  useEffect(() => {
    getMappingGroupData();
  }, [getMappingGroupData]);
  useEffect(() => {
    getOfficerLeaderData();
  }, [getOfficerLeaderData]);

  return (
    <div>
      <HeaderTitle>ค้นหาข้อมูลผู้ใช้งาน</HeaderTitle>
      <SearchUser
        onSearchUser={onSearchUser}
        formSearch={formSearch}
        onReset={onReset}
        mappingGroupData={mappingGroupData}
      />
      <HeaderTitle>รายการผู้ใช้งาน</HeaderTitle>
      <SearchResult
        onOpenModalUser={onOpenModalUser}
        officerData={error ? (error.response.status >= 400 ? [] : officerData) : officerData}
        loading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        pageable={pageable}
        onUserRecord={onUserRecord}
        onDeleteOfficer={onDeleteOfficer}
      />
      <ModalUser
        isOpenModalUser={isOpenModalUser}
        onCancelModalUser={onCancelModalUser}
        formUser={formUser}
        record={recordData}
        roles={mappingGroupData}
        onSubmitForm={onSubmitForm}
        officerLeaderData={officerLeaderData}
      />
    </div>
  );
};

export default ListUser;
