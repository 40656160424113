import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getOfferContractRefer } from 'api/offer';
import { onFilterOption } from 'utils/select';

type ContractOfferDropdownProps = {
  offerFormType: string;
  onChangeOfferFormId: Function;
  taxId: string | undefined | null;
};

const ContractOfferDropdown = ({
  offerFormType,
  onChangeOfferFormId,
  taxId,
}: ContractOfferDropdownProps) => {
  const {
    makeRequest: _getOfferContract,
    data: offerData,
    loading: isLoadingOffer,
  }: TApiResponse = useApi();

  useEffect(() => {
    if (taxId && offerFormType) {
      _getOfferContract(() => getOfferContractRefer({ taxId, offerFormType }));
    }
  }, [_getOfferContract, offerFormType, taxId]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        label="ระบุข้อเสนอเพื่ออ้างอิงทำสัญญา"
        name="offerFormId"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        rules={[{ required: true, message: 'กรุณาเลือกระบุข้อเสนอเพื่ออ้างอิงทำสัญญา' }]}
      >
        <Select
          showSearch
          loading={isLoadingOffer}
          placeholder="-- กรุณาระบุ --"
          optionFilterProp="children"
          filterOption={(inputValue, option) => onFilterOption(inputValue, option)}
          onChange={(value) => {
            onChangeOfferFormId(value);
          }}
          options={
            offerData
              ? offerData.map((item: any) => ({
                  value: item.offerFormId,
                  label: item.offerFormStatusName, // FIXME:M which property is label ?
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ContractOfferDropdown;
