import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import React, { useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import useSearch from './hooks/useSearch';
import { Form } from 'antd';
import useHeaderTitle from './hooks/useHeaderTitle';
import SearchReport from './components/SearchReport';

const ListReport = () => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [formReport] = Form.useForm();
  const { onReset, onSearchReport } = useSearch(formReport);
  const { pathname } = useLocation();
  const { setHeaderText, reportType } = useHeaderTitle(setHeaderTitle, formReport);

  useEffect(() => {
    setHeaderText(pathname);
  }, [pathname, setHeaderText]);

  return (
    <div>
      <HeaderTitle>ค้นหาโดย</HeaderTitle>
      <SearchReport
        formReport={formReport}
        onReset={onReset}
        onSearchReport={onSearchReport}
        reportType={reportType}
      />
    </div>
  );
};

export default ListReport;
