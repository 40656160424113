import { Key, createContext } from 'react';
import { FormInstance } from 'antd';

import useManageServices from '../hooks/useManageServices';
import useOfferDataContext from '../hooks/useOfferDataContext';
import useManageServiceRIO from '../hooks/useManageServiceRIO';
import useManageServicesRDRO from '../hooks/useManageServicesRDRO';
import useManageServicesRISO, {
  OnChangeOtherInputParams,
  OnChangeServiceParams,
} from '../hooks/useManageServicesRISO';

import {
  DropdownType,
  IOfferFacility,
  IOfferServePay,
  IOfferServes,
  IServicesData,
} from '../interfaces';

type OfferServicesContextType = {
  isDisableServiceAnnounceRate: boolean;
  isRIOServePayModalOpen: boolean;
  isRIOServiceModalOpen: boolean;
  isSelectedOtherRIOService: boolean;
  isLoadingServiceAnnounceRate: boolean;
  selectedServePay: IOfferServePay | undefined;
  selectedService: IOfferServes | undefined;
  selectedServType: DropdownType | undefined;
  selectedServiceOption: any;
  selectedMultipleServices: Key[];
  currentOfferServices: IOfferServes[];
  serviceTableFormRef: FormInstance<any>;
  RIOserviceModalFormRef: FormInstance<any>;
  RIOservePayModalFormRef: FormInstance<any>;
  onChangeService: ({ selectedOption, currentRecord }: OnChangeServiceParams) => void;
  onChangeOtherInput: ({ inputValue, currentRecord }: OnChangeOtherInputParams) => void;
  onAddNewService: () => void;
  onSelectMultipleServices: (selectedRowKeys: React.Key[], _: any[]) => void;
  onDeleteServices: () => void;
  onAddNewRIOService: () => void;
  onAddServiceAnnounceRate: () => void;
  onCloseRIOServiceModal: () => void;
  onEditRIOService: (value: {
    service: IOfferServes;
    currentOfferFacilities: IOfferFacility[];
  }) => void;
  onCloseRIOServePayModal: () => void;
  onAddNewRIOServePay: () => void;
  onEditRIOServePay: (servePay: IOfferServePay) => void;
  onChangeRIOService: (option: DropdownType) => void;
  onChangeRIOServType: (option: DropdownType) => void;
  onSubmitServePayModalForm: () => void;
  onDeleteServePay: (servePay: IOfferServePay) => void;
  onSubmitServiceModalForm: () => void;
  onDeleteRIOServices: (currentOfferFacilities: IOfferFacility[]) => void;
  onChangeRDROService: (value: {
    selectedOption: IServicesData;
    currentRecord: IOfferServes;
    currentOfferFacilities: IOfferFacility[];
  }) => void;
};

type OfferServicesProviderProps = {
  children: React.ReactNode;
};

const OfferServicesContext = createContext<OfferServicesContextType>(
  {} as OfferServicesContextType
);

const OfferServicesProvider = ({ children }: OfferServicesProviderProps) => {
  const { offerData, offerType } = useOfferDataContext();
  const {
    currentOfferServices,
    selectedMultipleServices,
    selectedMultipleRecords,
    setCurrentOfferServices,
    onSelectMultipleServices,
    onDeleteServices,
  } = useManageServices({
    offerServes: offerData?.offerServes,
  });
  const { serviceTableFormRef, onChangeService, onChangeOtherInput, onAddNewService } =
    useManageServicesRISO({
      currentOfferServices,
      setCurrentOfferServices,
    });
  const { onChangeRDROService } = useManageServicesRDRO({
    onChangeService,
  });
  const {
    isDisableServiceAnnounceRate,
    isRIOServePayModalOpen,
    isRIOServiceModalOpen,
    isSelectedOtherRIOService,
    isLoadingServiceAnnounceRate,
    selectedServePay,
    selectedServType,
    selectedService,
    selectedServiceOption,
    RIOserviceModalFormRef,
    RIOservePayModalFormRef,
    onAddNewRIOService,
    onAddServiceAnnounceRate,
    onCloseRIOServiceModal,
    onEditRIOService,
    onCloseRIOServePayModal,
    onAddNewRIOServePay,
    onEditRIOServePay,
    onChangeRIOService,
    onChangeRIOServType,
    onSubmitServePayModalForm,
    onDeleteServePay,
    onSubmitServiceModalForm,
    onDeleteRIOServices,
  } = useManageServiceRIO({
    offerType,
    currentOfferServices,
    selectedMultipleRecords,
    setCurrentOfferServices,
    onDeleteServices,
  });
  return (
    <OfferServicesContext.Provider
      value={{
        selectedService,
        selectedServePay,
        selectedServType,
        selectedServiceOption,
        selectedMultipleServices,
        currentOfferServices,
        isDisableServiceAnnounceRate,
        isRIOServePayModalOpen,
        isRIOServiceModalOpen,
        isSelectedOtherRIOService,
        isLoadingServiceAnnounceRate,
        serviceTableFormRef,
        RIOserviceModalFormRef,
        RIOservePayModalFormRef,
        onChangeService,
        onChangeOtherInput,
        onAddNewService,
        onSelectMultipleServices,
        onDeleteServices,
        onAddNewRIOService,
        onAddServiceAnnounceRate,
        onCloseRIOServiceModal,
        onEditRIOService,
        onCloseRIOServePayModal,
        onAddNewRIOServePay,
        onEditRIOServePay,
        onChangeRIOService,
        onChangeRIOServType,
        onSubmitServePayModalForm,
        onDeleteServePay,
        onSubmitServiceModalForm,
        onDeleteRIOServices,
        onChangeRDROService,
      }}
    >
      {children}
    </OfferServicesContext.Provider>
  );
};

export { OfferServicesContext, OfferServicesProvider };
