import { useEffect } from 'react';
import { Form } from 'antd';
import { downloadAuditResultsReport } from 'api/report';

import useFileManagement from 'features/Contract/hooks/useFileManagement';
import useDialogAlert from 'hooks/useDialogAlert';

import { dayJsformatDateObjToString } from 'utils/date';

const useAuditResultsReport = () => {
  const [formRef] = Form.useForm();
  const { downloadFile } = useFileManagement();
  const { error } = useDialogAlert();

  const onValidateForm = async () => {
    try {
      await formRef.validateFields();
    } catch (e) {
      throw new Error('กรุณากรอกข้อมูลให้ครบถ้วน');
    }
  };

  const onDownloadReport = async () => {
    try {
      await onValidateForm();

      const values = formRef.getFieldsValue();
      let _values = { ...values };
      _values.startDate = _values.startDate ? dayJsformatDateObjToString(_values.startDate) : null;
      _values.endDate = _values.endDate ? dayJsformatDateObjToString(_values.endDate) : null;
      await downloadFile({
        api: () => downloadAuditResultsReport(_values),
        isThrowError: true,
      });
    } catch (e: any) {
      error({
        header: e.message || 'ไม่สามารถดึงข้อมูลรายงานได้',
        sub: e.message ? '' : 'กรุณาลองเปลี่ยนวันที่ใหม่อีกครั้ง',
      });
    }
  };

  const onReset = () => {
    formRef.resetFields();
  };

  useEffect(() => {
    formRef.setFieldsValue({
      startDate: new Date(),
      endDate: new Date(),
    });
  }, [formRef]);

  return { formRef, onDownloadReport, onReset };
};

export default useAuditResultsReport;
