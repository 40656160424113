import { Modal } from 'antd';
import ModalHistoryContent from './ModalHistoryContent';

type Props = {
  modalOpenOffer: boolean;
  onModalCancel: () => void;
  width: string | number;
  title: string;
  attachHistory: any;
};

const ModalHistory = (props: Props) => {
  const { modalOpenOffer, onModalCancel, title, width, attachHistory } = props;

  return (
    <Modal
      width={width}
      title={title ? title : 'Basic Modal'}
      open={modalOpenOffer}
      onOk={undefined}
      onCancel={onModalCancel}
      footer={<></>}
      centered
    >
      <ModalHistoryContent attachHistory={attachHistory} />
    </Modal>
  );
};

export default ModalHistory;
