import { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import { HeaderTitleComponent, ThaiDatepickerComponent } from 'components';

import { formLayout } from 'constants/form';
import { getJSDateObj } from 'utils/date';

const OfferNOForm = () => {
  const { offerNoFormRef, offerData } = useOfferDataContext();

  useEffect(() => {
    if (offerData) {
      offerNoFormRef.setFieldsValue({
        offerFormNo: offerData.offerFormNo,
        offerFormDate: getJSDateObj(offerData.offerFormDate),
      });
    }
  }, [offerNoFormRef, offerData]);

  return (
    <div>
      <HeaderTitleComponent style={{ marginBottom: 15 }}>ข้อมูลผู้ประกอบการ</HeaderTitleComponent>
      <Form
        {...formLayout}
        colon
        labelWrap
        size="large"
        name="offerNoForm"
        form={offerNoFormRef}
        disabled={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="เลขที่หนังสือผู้ประกอบการ" name="offerFormNo">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="วันที่หนังสือผู้ประกอบการ" name="offerFormDate">
              <ThaiDatepickerComponent disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default OfferNOForm;
