import { AxiosError, AxiosResponse } from 'axios';
import { camelCase, snakeCase, keys, isEmpty, get, includes } from 'lodash';

const isArray = (array: any) => Array.isArray(array);

const isObject = (object: any) =>
  object === Object(object) && !isArray(object) && typeof object !== 'function';

const snakeToCamelcaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any };
    const newObject: { [name: string]: any } = {};
    keys(objectData).forEach((key) => {
      newObject[camelCase(key)] = snakeToCamelcaseTransform(objectData[key]);
    });
    return newObject;
  } else if (isArray(data)) {
    const arrayData = data as [];
    const newArray = arrayData.map((i) => snakeToCamelcaseTransform(i));
    return newArray;
  }
  return data;
};

const camelToSnakecaseTransform = (data: any): { [name: string]: any } | [] => {
  if (isObject(data)) {
    const objectData = data as { [name: string]: any };
    const newObject: { [name: string]: any } = {};
    keys(objectData).forEach((key) => {
      const ignoreCase = includes(['tel1', 'tel2', 'tel3'], key);
      if (ignoreCase) {
        newObject[key] = camelToSnakecaseTransform(objectData[key]);
      } else {
        newObject[snakeCase(key)] = camelToSnakecaseTransform(objectData[key]);
      }
    });
    return newObject;
  } else if (isArray(data)) {
    const arrayData = data as [];
    const newArray = arrayData.map((i) => camelToSnakecaseTransform(i));
    return newArray;
  }
  return data;
};
const response = (response: AxiosError<IResponse> | AxiosResponse<IResponse>): IResponse => {
  if (isEmpty(get(response, 'response', {}))) {
    // Succes case
    const axiosResponse = response as AxiosResponse<IResponse>;
    const message: string = get(axiosResponse, 'data.message', '');
    const status: number = get(axiosResponse, 'status', 0);
    const data: any = get(axiosResponse, 'data', {});
    const config: any = get(axiosResponse, 'config', {});
    const headers: any = get(axiosResponse, 'headers', {});
    const statusText: any = get(axiosResponse, 'statusText', {});
    const request: any = get(axiosResponse, 'request', {});
    return {
      message,
      status,
      data,
      config,
      headers,
      statusText,
      request,
    };
  } else {
    // Error case
    const axiosResponse = response as AxiosError<IResponse>;
    const otherResponse: any = get(axiosResponse, 'response.data', {});
    const status: number = get(axiosResponse, 'response.status', 0);
    const data: any = get(axiosResponse, 'response.data.data', '');
    const message: string = get(axiosResponse, 'response.data.message', '');
    return {
      ...otherResponse,
      status,
      data,
      message,
    };
  }
};

const transformer = {
  snakeToCamelcaseTransform,
  camelToSnakecaseTransform,
  response,
};
export default transformer;
