import { useEffect, useMemo } from 'react';
import { Modal, Spin } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractHistory } from 'api/contract/history';

import ContractHistoryTable from '../ContractHistoryTable';
import { getHistoryContractPath } from 'features/Contract/utils/offerFormType';

type ContractHistoryModalProps = {
  isModalOpen: boolean;
  handleCancel: Function;
  conFormId: number | undefined;
  offerFormType: string;
};

const ContractHistoryModal = ({
  isModalOpen,
  handleCancel,
  conFormId,
  offerFormType,
}: ContractHistoryModalProps) => {
  const {
    makeRequest,
    data: contractHistoryData,
    loading: isLoadingContractHistory,
  }: TApiResponse = useApi();

  const getHistoryPath = useMemo(() => {
    if (offerFormType) return getHistoryContractPath(offerFormType);
    else return (_: any) => '';
  }, [offerFormType]);

  useEffect(() => {
    if (conFormId) makeRequest(() => listContractHistory(conFormId));
  }, [conFormId, makeRequest]);

  return (
    <div>
      <Modal
        title={<h4>ไฟล์การจัดทำสัญญา</h4>}
        open={isModalOpen}
        width={'80vh'}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
          <Spin spinning={isLoadingContractHistory}>
            <ContractHistoryTable
              contractHistoryData={contractHistoryData}
              isLoadingContractHistory={isLoadingContractHistory}
              getHistoryPath={getHistoryPath}
            />
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default ContractHistoryModal;
