import React, { ComponentType, useCallback } from 'react';

import useModal from 'hooks/useModal';
import { IContractData } from 'features/Contract/interfaces';

import ContractHistoryModal from '../ContractHistoryModal';

type WithHistoryModalProps = {
  children: ComponentType<any>;
};

const WithHistoryModal = ({ children: ContractTable }: WithHistoryModalProps) => {
  const { isModalOpen, showModal, handleCancel } = useModal();
  const [selectedContract, setSelectedContract] = React.useState<any>(undefined);

  const onOpenHistoryModal = useCallback(
    (record: IContractData) => {
      setSelectedContract(record);
      showModal();
    },
    [showModal]
  );

  return (
    <>
      <ContractHistoryModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        conFormId={selectedContract?.conFormId}
        offerFormType={selectedContract?.offerFormType}
      />
      <ContractTable onOpenHistoryModal={onOpenHistoryModal} />
    </>
  );
};

export default WithHistoryModal;
