import axiosInstance from 'utils/axios';
import { ListDocumentRAONotiQuery } from './interface';

export const listDocumentRAONoti = (query: ListDocumentRAONotiQuery) => {
  return axiosInstance.get(`/master/doc/doc-type/RAO-NOTI`, {
    params: query,
  });
};
export const listProvince = () => {
  // FIXME:M Unused?, Removed?
  return `/api/master/provinces`;
};
export const listDistrict = (id: number | string) => {
  // FIXME:M Unused?, Removed?
  return `/api/master/provinces/${id}/districts`;
};
export const listSubdistrict = (id: number | string) => {
  // FIXME:M Unused?, Removed?
  return `/api/master/districts/${id}/subdistricts`;
};
export const listCurrency = () => {
  return `/api/master/currencies`;
};
// export const listFacilities = () => {
//   return `/api/master/facilities`;
// };
export const getFacilities = () => {
  return axiosInstance.get(`/master/facilities`);
};

export const listRaoService = (offerType: string, servType?: number | string) => {
  if (servType)
    return axiosInstance.get(`/master/offer-service?offerType=${offerType}&servType=${servType}`);
  return axiosInstance.get(`/master/offer-service?offerType=${offerType}`);
};
export const listTypeService = (offerType: string) => {
  return axiosInstance.get(`/master/offer-service-type?offerType=${offerType}`);
};
export const listRaoUnit = (id: number | string) => {
  return `/api/master/rao-service/${id}/unit`;
};
export const getUnit = (id?: number | string) => {
  console.log('id', id);

  return axiosInstance.get(`/master/offer-unit?raoServId=${id}`);
};
export const listDocument = () => {
  return `/api/master/doc/doc-type/RAO`;
};
export const offerServiceList = (offerType: string, servType?: number | string) => {
  if (servType)
    return axiosInstance.get(`/master/offer-service?offerType=${offerType}&servType=${servType}`);
  return axiosInstance.get(`/master/offer-service?offerType=${offerType}`);
};
export const listOfferFormType = () => {
  return axiosInstance.get(`/master/offer-form-types`);
};
export const getOperators = (id?: number | string) => {
  return axiosInstance.get(`/operators/${id}`);
};
export const getMasterUnit = () => {
  return axiosInstance.get(`/master/unit`);
};

// -------------------------------------------------------------------
export const listProvinces = () => {
  return axiosInstance.get(`/master/provinces`);
};
export const listDistrictsByProvinceId = (provinceId: string | number) => {
  return axiosInstance.get(`/master/provinces/${provinceId}/districts`);
};
export const listSubDistrictsByDistrictId = (districtId: string | number) => {
  return axiosInstance.get(`/master/districts/${districtId}/subdistricts`);
};
export const listOfferDocType = (offerType: string) => {
  return axiosInstance.get(`/master/doc/doc-type/${offerType}`);
};
export const getCurrency = () => {
  return axiosInstance.get(`/master/currencies`);
};
export const listUnitByService = (raoServId: string | number) => {
  return axiosInstance.get(`/master/offer-unit`, {
    params: { raoServId },
  });
};
