import { useCallback, useEffect } from 'react';

import { useApi } from 'hooks/useApi';
import { getFacilities } from 'api/master';
import { IOfferFacility } from '../interfaces';

type OfferFacilityData = {
  facilitiesData: IOfferFacility[];
  isLoadingFacilities: boolean;
};

const useFetchFacilities = (): OfferFacilityData => {
  const { makeRequest, loading, data } = useApi();

  const onListFacilities = useCallback(async () => {
    makeRequest(() => getFacilities());
  }, [makeRequest]);

  useEffect(() => {
    onListFacilities();
  }, [onListFacilities]);

  return { facilitiesData: data, isLoadingFacilities: loading };
};

export default useFetchFacilities;
