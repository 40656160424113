import { useEffect } from 'react';
import { Form, FormInstance, Input, Select } from 'antd';

import { listDocumentRAONoti } from 'api/master';
import { useApi, TApiResponse } from 'hooks/useApi';

type TdocumentTypeList = {
  createdDate?: string | any;
  docId: number;
  docName: string;
  docType: string;
  required: boolean;
};

type DocumentTypeDropdownProps = {
  form: FormInstance<any>;
  isDisable: boolean;
  state: string | undefined;
  onChangeDocType?: (value: any, option: any) => void;
};

const DocumentTypeDropdown = ({
  form,
  isDisable,
  state,
  onChangeDocType,
}: DocumentTypeDropdownProps) => {
  const { Option } = Select;
  const {
    makeRequest: _listDocumentRAONoti,
    data: documentTypeList,
    loading: isLoadingDocumentType,
  }: TApiResponse = useApi();

  useEffect(() => {
    if (state) {
      _listDocumentRAONoti(() => listDocumentRAONoti({ state }));
    }
  }, [_listDocumentRAONoti, state]);

  return (
    <>
      <Form.Item
        colon
        label="เลือกกลุ่มเอกสาร"
        name="docId"
        rules={[{ required: true, message: 'กรุณาเลือกกลุ่มเอกสาร' }]}
      >
        <Select
          loading={isLoadingDocumentType}
          placeholder="-- กรุณาระบุ --"
          size="large"
          disabled={isDisable}
          onChange={(value, option) => {
            form.setFieldValue('docName', option);
            if (onChangeDocType) onChangeDocType(value, option);
          }}
        >
          {documentTypeList &&
            documentTypeList.map((item: TdocumentTypeList) => {
              return (
                <Option key={item.docId} value={item.docId}>
                  {item.docName}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item name="docName" hidden>
        <Input />
      </Form.Item>
    </>
  );
};

export default DocumentTypeDropdown;
