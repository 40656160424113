import ContractFacilities from '../ContractFacilities';
import ContractServices from '../ContractServices';

type ContractServicesFacilitiesProps = {
  selectedOfferFormId: number | string | undefined;
};

const ContractServicesFacilities = ({ selectedOfferFormId }: ContractServicesFacilitiesProps) => {
  return (
    <div>
      <ContractServices selectedOfferFormId={selectedOfferFormId} />
      <ContractFacilities />
    </div>
  );
};

export default ContractServicesFacilities;
