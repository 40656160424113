import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractStatus } from 'api/contract';
import { useEffect } from 'react';

const ContractStatusDropdown = () => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listContractStatus());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        label="สถานะของสัญญา"
        name="isActiveContract"
        // rules={[{ required: true, message: 'กรุณาเลือกสถานะของสัญญา' }]}
      >
        <Select
          allowClear
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          options={
            data
              ? data.map((item: { status: string; statusName: string }) => ({
                  value: item.status,
                  label: item.statusName,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ContractStatusDropdown;
