import RISOService from '../RISOServiceFacility';
import RDROFacility from './components/RDROFacility';

const RDROServiceFacility = () => {
  return (
    <div>
      <RISOService />
      <RDROFacility />
    </div>
  );
};

export default RDROServiceFacility;
