import { useCallback, useEffect } from 'react';

import { useApi } from 'hooks/useApi';

import { listServicesByOfferType } from 'api/offer/services';
import { ListServicesQuery } from 'api/offer/interface';

type useFetchServicesProps = {
  offerType: string;
  servType?: string;
};

const useFetchServices = ({ offerType, servType }: useFetchServicesProps) => {
  const { makeRequest, loading, data } = useApi();

  const onListServicesByOfferType = useCallback(
    async (query: ListServicesQuery) => {
      if (query.offerType) {
        makeRequest(() => listServicesByOfferType(query));
      }
    },
    [makeRequest]
  );

  useEffect(() => {
    onListServicesByOfferType({ offerType, servType });
  }, [offerType, servType, onListServicesByOfferType]);

  return { servicesData: data, isLoadingServices: loading, onListServicesByOfferType };
};

export default useFetchServices;
