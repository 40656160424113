import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';
import { Rule } from 'antd/es/form';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getAllOperators } from 'api/operator';
import { onFilterOption } from 'utils/select';

type OperatorsDropdownProps = {
  name: string;
  isLabelInValue?: boolean;
  label?: string;
  labelCol?: ColProps | undefined;
  rules?: Rule[] | undefined;
  onChange?: (values: any) => void;
  disabled?: boolean;
  isIdValue?: boolean;
};

const OperatorsDropdown = ({
  label,
  name,
  isLabelInValue = false,
  disabled = false,
  labelCol = undefined,
  rules = undefined,
  onChange = undefined,
  isIdValue = false,
}: OperatorsDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => getAllOperators());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        name={name}
        label={label ? label : 'ผู้ประกอบการ'}
        labelCol={labelCol}
        rules={rules}
        // rules={[{ required: true, message: `กรุณาเลือก${label ? label : 'ทำสัญญาร่วมกับ'}` }]}
      >
        <Select
          showSearch
          allowClear
          placeholder="-- กรุณาระบุ --"
          labelInValue={isLabelInValue}
          loading={loading}
          onChange={onChange}
          disabled={disabled}
          filterOption={(inputValue, option) => onFilterOption(inputValue, option)}
          options={
            data
              ? data.map((item: { id: number; taxId: string; operName: string }) => ({
                  value: item[isIdValue ? 'id' : 'taxId'],
                  label: item.operName,
                  key: item.id,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OperatorsDropdown;
