import axiosInstance from '../../utils/axios';
import { clean } from '../../utils/object';
import {
  CreateReportUsageFormInput,
  GetDetailDataResponse,
  GetErrorFromUsageAttachFileResponse,
  GetUsageFormResponse,
  GetUsageFormTypeResponse,
  SearchReportUsageForm,
  SearchReportUsageFormResponse,
  SearchUsageForm,
  SearchUsageFormForOfficerInput,
  SearchUsageFormForOfficerResponse,
  SearchUsageFormResponse,
  UpdateOtherFormInput,
  UpdateTelecomFormInput,
  UpdateUsageFormStatusForOfficerInput,
} from './interface';


export const createUsageForm = (operatorsId: number, year: number, quarter: number): Promise<GetUsageFormResponse> => {
  return axiosInstance.post(`/operators/usage-form`, {
    operatorsId,
    quarter,
    year,
  });
};

export const createUsageFormOfficer = (operatorsId: number, year: number, quarter: number): Promise<GetUsageFormResponse> => {
  return axiosInstance.post(`/officer/usage-form`, {
    operatorsId,
    quarter,
    year,
  });
};

export const updateTelecomForm = (input: UpdateTelecomFormInput): Promise<void> => {
  return axiosInstance.post(`operators/usage-form/${input.usageFormId}/telecom-form`, {
    reviewerName: input.reviewerName,
    reviewerRole: input.reviewerRole,
    frequencyOfTechnicalProblem: input.frequencyOfTechnicalProblem,
    frequencyOfServiceQualityProblem: input.frequencyOfServiceQualityProblem,
  });
};

export const updateTelecomFormOfficer = (input: UpdateTelecomFormInput): Promise<void> => {
  return axiosInstance.post(`officer/usage-form/${input.usageFormId}/telecom-form`, {
    reviewerName: input.reviewerName,
    reviewerRole: input.reviewerRole,
    frequencyOfTechnicalProblem: input.frequencyOfTechnicalProblem,
    frequencyOfServiceQualityProblem: input.frequencyOfServiceQualityProblem,
  });
};

export const updateConnectForm = (input: UpdateOtherFormInput): Promise<void> => {
  return axiosInstance.post(`operators/usage-form/${input.usageFormId}/other-form`, {
    reviewerName: input.reviewerName,
    reviewerRole: input.reviewerRole,
    frequencyOfTechnicalProblem: input.frequencyOfTechnicalProblem,
    frequencyOfServiceQualityProblem: input.frequencyOfServiceQualityProblem,
  });
};

export const updateConnectFormOfficer = (input: UpdateOtherFormInput): Promise<void> => {
  return axiosInstance.post(`officer/usage-form/${input.usageFormId}/other-form`, {
    reviewerName: input.reviewerName,
    reviewerRole: input.reviewerRole,
    frequencyOfTechnicalProblem: input.frequencyOfTechnicalProblem,
    frequencyOfServiceQualityProblem: input.frequencyOfServiceQualityProblem,
  });
};

export const uploadAttachFile = (id: number, usageTypeId: number, formData: FormData): Promise<void> => {
  return axiosInstance.post(`operators/usage-form/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'usageTypeId': usageTypeId,
    },
  });
};

export const uploadAttachFileOfficer = (id: number, usageTypeId: number, formData: FormData): Promise<void> => {
  return axiosInstance.post(`officer/usage-form/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'usageTypeId': usageTypeId,
    },
  });
};

export const getUsageForm = (id: number): Promise<GetUsageFormResponse> => {
  return axiosInstance.get(`operators/usage-form/${id}`);
};

export const getUsageFormOfficer = (id: number): Promise<GetUsageFormResponse> => {
  return axiosInstance.get(`officer/usage-form/${id}`);
};

export const updateUsageFormState = (id: string): Promise<void> => {
  return axiosInstance.post(`operators/usage-form/${id}/status`);
};

export const updateUsageFormStateOfficer = (id: string): Promise<void> => {
  return axiosInstance.post(`officer/usage-form/${id}/status-officer`);
};

export const getUsageType = (): Promise<GetUsageFormTypeResponse> => {
  return axiosInstance.get(`operators/usage-type`);
};

export const getUsageTypeOfficer = (): Promise<GetUsageFormTypeResponse> => {
  return axiosInstance.get(`officer/usage-type`);
};


export const downloadAttachFileById = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`operators/usage-attach-file/${id}/download`, {
    responseType: 'blob',
  });
};

export const downloadAttachFileByIdOfficer = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`officer/usage-attach-file/${id}/download`, {
    responseType: 'blob',
  });
};

export const deleteAttachFile = (id: number): Promise<void> => {
  return axiosInstance.delete(`operators/usage-attach-file/${id}`);
};

export const deleteAttachFileOfficer = (id: number): Promise<void> => {
  return axiosInstance.delete(`officer/usage-attach-file/${id}`);
};

export const searchUsageForm = (
  payload: SearchUsageForm,
): Promise<SearchUsageFormResponse> => {
  return axiosInstance.post(`operators/usage-form/search`,
    clean({
      year: payload.year,
      quarter: payload.quarter,
    }),
    {
      params: {
        currentPageNumber: payload.currentPageNumber,
        pageSize: payload.pageSize,
      },
    },
  );
};


export const searchUsageFormForOfficer = (payload: SearchUsageFormForOfficerInput): Promise<SearchUsageFormForOfficerResponse> => {
  return axiosInstance.post(`officer/usage-form/search`,
    clean({
      year: payload.year,
      quarter: payload.quarter,
      operatorsId: payload.operatorsId,
    }),
    {
      params: {
        currentPageNumber: payload.currentPageNumber,
        pageSize: payload.pageSize,
      },
    });
};

export const searchUsageFormForOfficerWithAllStatus = (payload: SearchUsageFormForOfficerInput): Promise<SearchUsageFormForOfficerResponse> => {
  return axiosInstance.post(`officer/usage-form/search-officer`,
    clean({
      year: payload.year,
      quarter: payload.quarter,
      operatorsId: payload.operatorsId,
    }),
    {
      params: {
        currentPageNumber: payload.currentPageNumber,
        pageSize: payload.pageSize,
      },
    });
};

export const getErrorFromUsageAttachFile = (id: number): Promise<GetErrorFromUsageAttachFileResponse> => {
  return axiosInstance.get(`officer/usage-attach-file/${id}/error`);
};


export const updateUsageFormStatusForOfficer = (payload: UpdateUsageFormStatusForOfficerInput): Promise<void> => {
  return axiosInstance.post(`officer/usage-form/${payload.id}/status`, {
    status: payload.status,
    usageAttachFileRequests: payload.usageAttachFileRequests,
  });
};

export const searchReportUsageForm = (
  payload: SearchReportUsageForm,
): Promise<SearchReportUsageFormResponse> => {
  return axiosInstance.post(`operators/usage-raw-report/search`,
    clean({
      year: payload.year,
      quarter: payload.quarter,
    }),
    {
      params: {
        currentPageNumber: payload.currentPageNumber,
        pageSize: payload.pageSize,
      },
    },
  );
};

export const searchReportUsageFormOfficer = (
  payload: SearchReportUsageForm,
): Promise<SearchReportUsageFormResponse> => {
  return axiosInstance.post(`officer/usage-raw-report/search`,
    clean({
      year: payload.year,
      quarter: payload.quarter,
    }),
    {
      params: {
        currentPageNumber: payload.currentPageNumber,
        pageSize: payload.pageSize,
      },
    },
  );
};

export const createReportUsageForm = (payload: CreateReportUsageFormInput): Promise<SearchReportUsageFormResponse> => {
  return axiosInstance.post('operators/usage-raw-report', {
    year: payload.year,
    quarter: payload.quarter,
    usageTypeId: payload.usageTypeId,
    netWorkTypeCode: payload.netWorkTypeCode,
  });
};

export const createReportUsageFormOfficer = (payload: CreateReportUsageFormInput): Promise<SearchReportUsageFormResponse> => {
  return axiosInstance.post('officer/usage-raw-report', {
    year: payload.year,
    quarter: payload.quarter,
    usageTypeId: payload.usageTypeId,
    netWorkTypeCode: payload.netWorkTypeCode,
  });
};

export const downloadReportUsageForm = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`operators/usage-raw-report/${id}/download`, {
    responseType: 'blob',
  });
};

export const downloadReportUsageFormOfficer = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`officer/usage-raw-report/${id}/download`, {
    responseType: 'blob',
  });
};

export const deleteUsageFormReport = (id: number): Promise<void> => {
  return axiosInstance.delete(`operators/usage-raw-report/${id}`);
};

export const deleteUsageFormReportOfficer = (id: number): Promise<void> => {
  return axiosInstance.delete(`officer/usage-raw-report/${id}`);
};
export const updateUsageAttachFile = (id: number, considerResult: string, considerDetail: string): Promise<void> => {
  return axiosInstance.post(`officer/usage-attach-file/${id}/form`, {
    considerResult,
    considerDetail,
  });
};


export const getDetailData = (id: number): Promise<GetDetailDataResponse> => {
  return axiosInstance.get(`operators/usage-form/${id}/form-detail`);
};

export const getDetailDataOfficer = (id: number): Promise<GetDetailDataResponse> => {
  return axiosInstance.get(`officer/usage-form/${id}/form-detail`);
};

export const uploadRawReportFile = (id: number, usageTypeId: number, formData: FormData): Promise<void> => {
  return axiosInstance.post(`/operators/usage-form/${id}/report-type/${usageTypeId}/raw-report-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'usageTypeId': usageTypeId,
    },
  });
};

export const uploadRawReportFileOfficer = (id: number, usageTypeId: number, formData: FormData): Promise<void> => {
  return axiosInstance.post(`/officer/usage-form/${id}/report-type/${usageTypeId}/raw-report-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'usageTypeId': usageTypeId,
    },
  });
};

export const downloadRawReportFile = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`operators/raw-report-file/${id}/download`, {
    responseType: 'blob',
  });
};

export const downloadRawReportFileOfficer = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`officer/raw-report-file/${id}/download`, {
    responseType: 'blob',
  });
};

export const deleteRawReportFile = (id: number): Promise<void> => {
  return axiosInstance.delete(`operators/raw-report-file/${id}`);
};

export const deleteRawReportFileOfficer = (id: number): Promise<void> => {
  return axiosInstance.delete(`officer/raw-report-file/${id}`);
};

