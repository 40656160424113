import { Space, Row, Col, Button } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';

const StepWithFile = ({ stepData, isDisable }: IStepDescription) => {
  return (
    <div>
      <Row>
        <Col>
          <Space>
            <label>วันที่ส่งออก :</label>
            <span>
              {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
            </span>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col>
          <Space>
            <label>ไฟล์แนบ :</label>
            <Button size="small" disabled={isDisable}>
              -- กรุณาคลิกเพื่อดูไฟล์แนบ --
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithFile;
