import {
  createApprovedContractStatusReport,
  listApprovedContractStatusReport,
  getApprovedContractStatusReportByJobId,
  downloadApprovedContractStatusReportByJobId,
} from 'api/report';
import GenericReport from 'components/GenericReport';
import ContractTypeDropdown from 'features/Contract/components/ContractTypeDropdown';

const ApprovedContractReport = () => {
  return (
    <>
      <GenericReport
        isRequiredOfferType={false}
        isContractReport={true}
        pageTitle="รายงานสถานะการพิจารณาสัญญา"
        dateLabel="วันที่หนังสือนำส่งสัญญาตั้งแต่"
        createReportApi={createApprovedContractStatusReport}
        listReportApi={listApprovedContractStatusReport}
        getReportByJobIdApi={getApprovedContractStatusReportByJobId}
        downloadReportByJobIdApi={downloadApprovedContractStatusReportByJobId}
        TypeDropdown={ContractTypeDropdown}
      />
    </>
  );
};

export default ApprovedContractReport;
