import React, { ReactNode } from 'react';
import { Button, ButtonProps } from 'antd';
import buttonCSS from 'styles/components/button.module.css';
interface Children {
  children?: ReactNode;
}

const ButtonComponent = (props: ButtonProps & Children) => {
  return (
    <Button className={buttonCSS.primary} style={{ height: '40px', ...props }} {...props}>
      {props.children}
    </Button>
  );
};

export default ButtonComponent;
