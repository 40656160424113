import { useContext } from 'react';
import { DialogAlerContext } from '../contexts/DialogAlertContext';

const useDialogAlert = () => {
  const context = useContext(DialogAlerContext);
  if (!context)
    throw new Error('Authentication Context must be use inside AuthProvider');
  return context;
};

export default useDialogAlert;
