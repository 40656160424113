import { Table } from 'antd';

import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';

import { getColumns } from './columns';

const ContServePaysTable = () => {
  const { currentContServePays, onEditContServePay, onDeleteContServePay } =
    useContractServicesContext();

  return (
    <>
      <Table
        bordered
        size="small"
        columns={getColumns({
          onEditContServePay,
          onDeleteContServePay,
        })}
        dataSource={currentContServePays ? currentContServePays : []}
        rowKey={(value) => value.key}
        pagination={false}
        scroll={{ x: true }}
      />
    </>
  );
};

export default ContServePaysTable;
