import { useCallback, useEffect } from 'react';

import { TApiResponse, useApi } from 'hooks/useApi';
import { listSubDistrictsByDistrictId } from 'api/master';

type useSubDistrictProps = {
  districtId: string | number;
};

const useSubDistrict = ({ districtId }: useSubDistrictProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  const onListSubDistrictByDistrictId = useCallback(
    (DistrictId: string | number) => {
      makeRequest(() => listSubDistrictsByDistrictId(DistrictId));
    },
    [makeRequest]
  );

  useEffect(() => {
    if (districtId) onListSubDistrictByDistrictId(districtId);
  }, [onListSubDistrictByDistrictId, districtId]);

  return { onListSubDistrictByDistrictId, subDistricts: data, isLoadingSubDistricts: loading };
};

export default useSubDistrict;
