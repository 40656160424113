/* eslint-disable */
import { useCallback, useEffect, useState } from 'react';
import { EChartsOption } from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { ColumnsType } from 'antd/es/table';
import { Form, Select, Table } from 'antd';
import { useApi } from 'hooks/useApi';
import { getContact } from 'api/dashboard';
import { formatDateToOnlyBCYear } from 'utils/date';


interface DataType {
  key: number;
  status: string;
  value: number;
}

interface Options {
  value: number;
  label: number;
}

const defaultOptions = (): Options[] => {
  const startAt = 2563;
  const options: Options[] = [];
  for (let i = startAt; i < parseInt(formatDateToOnlyBCYear(new Date().toString())) + 2; i++) {
    options.push({
      value: i,
      label: i,
    });
  }
  return options;
};

const columns: ColumnsType<DataType> = [
  {
    title: 'สถานะ',
    dataIndex: 'status',
    key: 'status',
    render: (text) => <div>{text}</div>,
  },
  {
    title: 'จำนวน',
    align: 'center',
    dataIndex: 'value',
    key: 'value',
  },
];
const ContactChart = (): JSX.Element => {
  const { makeRequest: GetContactTotalRequest } = useApi();
  const [year, setYear] = useState<number>(parseInt(formatDateToOnlyBCYear(new Date().toString())));
  const [chartData, setChartData] = useState([
    {
      key: 1,
      status: 'อยู่ระหว่างจัดทำเอกสาร',
      value: 0,
    },
    {
      key: 2,
      status: 'รอแนบเอกสาร',
      value: 0,
    },
    {
      key: 3,
      status: 'อยู่ระหว่างพิจารณาโดย ชท.',
      value: 0,
    },
    {
      key: 4,
      status: 'พิจารณาโดย คณะอนุกลั่นกรองฯ',
      value: 0,
    },
    {
      key: 5,
      status: 'อยู่ระหว่างผู้ประกอบการแก้ไข',
      value: 0,
    },
    {
      key: 6,
      status: 'พิจารณาโดย กสทช.',
      value: 0,
    },
    {
      key: 7,
      status: 'กสทช. พิจารณาแล้วเสร็จ',
      value: 0,
    },
    {
      key: 8,
      status: 'รวม',
      value: 0,
    },
  ]);
  const [options] = useState(defaultOptions);
  const [loading, setLoading] = useState(true);

  const chartOption = useCallback(() => {
    let option: EChartsOption;
    option = {
      title: {
        text: 'การพิจารณาสัญญา',
        left: 'center',
      },
      tooltip: {},
      legend: {
        bottom: 'bottom',
      },
      grid: {
        height: '300px',
        left: '3%',
        right: '4%',
        bottom: '22%',
        containLabel: false,
      },
      xAxis: {}
      ,
      yAxis: {
        type: 'category',
        show: false,
      },
      series: chartData.filter(i => i.status !== 'รวม').map(i => {
        return {
          type: 'bar',
          name: i.status,
          data: [i.value],
        };
      }),
    };
    return option;
  }, [chartData]);

  const updateChart = async (year: number) => {
    await GetContactTotalRequest(async () => {
      const res = await getContact(year);
      setChartData(res.data.data.map((item, index) => {
        return {
          key: index,
          status: item.status,
          value: item.total,
        };
      }));
      setLoading(false);
      return res;
    });
  };
  useEffect(() => {
    updateChart(year);
  }, [year]);


  return <div>
    {!loading && <ReactEcharts style={{ height: '500px' }} option={chartOption()} />}
    <div style={{ padding: '0 3%', marginTop: '32px' }}>
      <div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ marginBottom: '12px', float: 'left' }}>
          <div>การพิจารณาสัญญา ปี {year}</div>
        </div>
        <div style={{ marginBottom: '12px', float: 'right' }}>
          <Form>
            <Form.Item label={'ปี'}>
              <Select style={{ width: '100px' }} onSelect={(value) => {
                setYear(value);
              }
              } options={options} defaultValue={year}>
              </Select>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Table columns={columns} pagination={false} dataSource={chartData} />
    </div>
  </div>
    ;
};
export default ContactChart;
