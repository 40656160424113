import { Table } from 'antd';
import { getColumns } from './columns';

type UploadTableProps = {
  fileList: any[];
  isLoadingFileList: boolean;
  isDisable: boolean;
  onRemoveFile: (id: any) => void;
  downloadFile: (id: any) => void;
};

const UploadTable = ({
  fileList,
  isLoadingFileList,
  isDisable,
  onRemoveFile,
  downloadFile,
}: UploadTableProps) => {
  return (
    <Table
      bordered
      size="small"
      loading={isLoadingFileList}
      columns={getColumns({
        isDisable,
        handleRemoveFile: onRemoveFile,
        downloadFile,
      })}
      dataSource={fileList}
      rowKey={'attachFileId'}
      pagination={false}
      scroll={{ x: true }}
    />
  );
};

export default UploadTable;
