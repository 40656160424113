import { useCallback, useEffect, useState } from 'react';
import { Form, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useApi, TApiResponse } from 'hooks/useApi';
import useModal from 'hooks/useModal';
import { ServiceData } from 'api/master/interface';
import { listAllServices, createNewService, updateService } from 'api/master/adminData';

import { HeaderTitleComponent } from 'components';
import PrimaryButton from 'components/PrimaryButton';
import EditModal from './EditModal';
import { columns } from './columns';

const ListServices = () => {
  const [formRef] = Form.useForm();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const {
    makeRequest: _getServiceList,
    data: services = [],
    loading: isLoadingServicesList,
  }: TApiResponse = useApi();
  const { makeRequest: _updateServices, loading: isUpdating }: TApiResponse = useApi();
  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);

  const onOpenEditModal = (record: any) => {
    setSelectedRecord(record);
    showModal();
  };

  const onCloseModal = () => {
    setSelectedRecord(undefined);
    formRef.resetFields();
    handleCancel();
  };

  const onFetchList = useCallback(() => {
    _getServiceList(() => listAllServices());
  }, [_getServiceList]);

  const onSaveService = useCallback(
    async (values: ServiceData) => {
      if (values.raoServId) {
        await _updateServices(() => updateService(values.raoServId, values));
      } else {
        await _updateServices(() => createNewService(values));
      }
      setSelectedRecord(undefined);
      handleCancel();
      formRef.resetFields();
      onFetchList();
    },
    [handleCancel, _updateServices, onFetchList, formRef]
  );

  useEffect(() => {
    onFetchList();
  }, [onFetchList]);

  return (
    <div>
      <EditModal
        isModalOpen={isModalOpen}
        isUpdating={isUpdating}
        selectedRecord={selectedRecord}
        onCloseModal={onCloseModal}
        onSaveRecord={onSaveService}
        titleName={'แก้ไขข้อมูลบริการ'}
        fieldName={'raoServName'}
        fieldTitle={'ชื่อบริการ'}
        formRef={formRef}
      />
      <HeaderTitleComponent>จัดการข้อมูลบริการ</HeaderTitleComponent>
      <div className="content-box" style={{ paddingLeft: 0 }}>
        <PrimaryButton icon={<PlusOutlined />} onClick={showModal}>
          เพิ่มบริการ
        </PrimaryButton>
      </div>
      <Table
        size="middle"
        columns={columns({
          onOpenEditModal,
          titleName: 'บริการ',
          valueName: 'raoServName',
        })}
        dataSource={services}
        loading={isLoadingServicesList}
        rowKey={'raoServId'}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default ListServices;
