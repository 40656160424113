import { Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { IDistricts } from 'types/Authorization';
import { onFilterOption } from 'utils/select';

type DistrictDropdownProps = {
  onChangeDistrict: (value: any, option: DefaultOptionType | DefaultOptionType[]) => void;
  isLoadingDistricts: boolean;
  districts: IDistricts[];
  isDisabled: boolean;
};

const DistrictDropdown = ({
  onChangeDistrict,
  isLoadingDistricts,
  districts,
  isDisabled,
}: DistrictDropdownProps) => {
  return (
    <Form.Item
      colon
      label="อำเภอ/เขต"
      name="conDistrictId"
      rules={[{ required: true, message: 'กรุณาเลือกอำเภอ/เขต' }]}
    >
      <Select
        showSearch
        disabled={!districts || isDisabled}
        loading={isLoadingDistricts}
        placeholder="-- กรุณาระบุ --"
        optionFilterProp="children"
        filterOption={onFilterOption}
        onChange={onChangeDistrict}
        options={
          districts
            ? districts.map((item: any) => ({
                value: item.districtId,
                label: item.districtName,
              }))
            : []
        }
      />
    </Form.Item>
  );
};

export default DistrictDropdown;
