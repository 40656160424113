import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import InputText from 'components/InputTextComponent';
// import useDialogAlert from 'hooks/useDialogAlert';
import React from 'react';
import buttonCSS from 'styles/components/button.module.css';
// import { offerType } from './options';
import OfferTypeDropdown from 'features/OfferConsideration/components/OfferTypeDropdown';
type Props = {
  form: FormInstance<any>;
  onFinishSearch: (values: any) => void;
  onFinishFailedSearch: (errorInfo: any) => void;
  operatorList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  offerFormStatesList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
};

const FormSearch = (props: Props) => {
  const { form, onFinishFailedSearch, onFinishSearch, operatorList, offerFormStatesList } = props;
  // const [valueDate, setValueDate] = useState<any>();
  // const [valueDate2, setValueDate2] = useState<any>();
  // const { error } = useDialogAlert();
  const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 16 },
  };

  return (
    <Form
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinishSearch}
      onFinishFailed={onFinishFailedSearch}
      autoComplete="off"
      requiredMark={false}
      {...layout}
      labelWrap
      size="large"
    >
      <div className="content-box">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {/* <Form.Item
              colon
              labelAlign="right"
              label="ประเภทข้อเสนอ"
              name="offerType"
              rules={[{ required: false, message: 'กรุณาเลือกประเภทข้อเสนอ' }]}
            >
              <Select
                placeholder="-- กรุณาระบุ --"
                showSearch
                allowClear
                filterOption={(input, option) =>
                  (option?.label?.toString().toLowerCase() ?? '').includes(input)
                }
                options={offerType}
              />
            </Form.Item> */}
            <OfferTypeDropdown fieldName="offerType" />
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="ผู้ประกอบการ"
              name="client"
              rules={[{ required: false, message: 'กรุณาเลือกผู้ประกอบการ' }]}
            >
              <Select
                placeholder="-- กรุณาระบุ --"
                filterOption={(input, option) =>
                  (option?.label?.toString().toLowerCase() ?? '').includes(input)
                }
                showSearch
                allowClear
                options={operatorList}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="เลขที่หนังสือผู้ประกอบการ"
              name="clientNo"
              rules={[{ required: false, message: 'กรุณากรอกเลขที่หนังสือผู้ประกอบการ' }]}
            >
              <InputText
                onChange={(e) =>
                  form.setFieldsValue({
                    clientNo: e.target.value.length === 0 ? undefined : e.target.value,
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          {/* <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่หนังสือผู้ประกอบการตั้งแต่"
              name="startDate"
              rules={[{ required: true, message: 'กรุณากรอกวันที่หนังสือผู้ประกอบการตั้งแต่' }]}
            >
              <ThaiDatepickerComponent
                onChange={(e: any) => {
                  setValueDate(e);
                  form.setFieldsValue({ endDate: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่หนังสือผู้ประกอบการถึง"
              name="endDate"
              rules={[{ required: true, message: 'กรุณากรอกวันที่หนังสือผู้ประกอบการถึง' }]}
            >
              <ThaiDatepickerComponent
                onChange={(e: any) => {
                  setValueDate2(e);
                }}
              />
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="สถานะข้อเสนอ"
              name="offerStatus"
              rules={[{ required: false, message: 'กรุณาเลือกสถานะข้อเสนอ' }]}
            >
              <Select
                placeholder="-- กรุณาระบุ --"
                showSearch
                allowClear
                options={offerFormStatesList}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              htmlType="reset"
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FormSearch;
