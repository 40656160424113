import { ReactNode, useEffect } from 'react';
import { Navigate, useLocation, useSearchParams } from 'react-router-dom';
import { Spin } from 'antd';

import useAuth from 'hooks/useAuth';
import useDialogAlert from 'hooks/useDialogAlert';
import { useApi } from 'hooks/useApi';
import useProfile from 'hooks/useProfile';

import { getAvailableProfile } from 'api/authen';

type PortalGuardProps = {
  children: ReactNode;
};

export default function PortalGuard({ children }: PortalGuardProps) {
  const location = useLocation();
  const { error } = useDialogAlert();
  const [searchParams] = useSearchParams();
  const { makeRequest } = useApi();
  const { onSelectProfile } = useProfile();
  const { getAuth, isAuthenticated, isAuthenticating, setIsAuthenticated } = useAuth();
  const userLogin = searchParams.get('userName');
  const errorCode = searchParams.get('errorCode');
  const errorDesc = searchParams.get('errorDesc');

  const saveUserPortalLogin = async () => {
    try {
      // NOTE: [For Operator] Get all available profiles
      const availableProfiles = await makeRequest(
        () => {
          return getAvailableProfile();
        },
        { isThrowError: true }
      ); // #NEED
      const defaultProfileRefNo = availableProfiles?.data?.data[0]?.referenceNo;

      onSelectProfile({
        refNo: defaultProfileRefNo,
        errorRedirectPath: process.env.REACT_APP_PORTAL_LOGIN_URL,
      });
    } catch (errorInfo) {
      console.error('error', errorInfo);
      error(
        {
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'ไม่พบ Profiles ที่ใช้งานได้ กรุณาติดต่อเจ้าหน้าที่',
        },
        () => {
          window.location.replace(process.env.REACT_APP_PORTAL_LOGIN_URL || '/login');
        }
      );
    }
  };

  useEffect(() => {
    if (errorCode) {
      error(
        {
          header: errorDesc || 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'ไม่สามารถเข้าสู่ระบบได้ กรุณาติดต่อเจ้าหน้าที่',
        },
        () => {
          window.location.replace(process.env.REACT_APP_PORTAL_LOGIN_URL || '/login');
        }
      );
      setIsAuthenticated(false);
      return;
    }

    if (userLogin) {
      // NOTE: Portal login
      saveUserPortalLogin();
    } else {
      // NOTE: Officer login
      getAuth();
    }
    // eslint-disable-next-line
  }, []);

  if (isAuthenticating) {
    return (
      <div className="center">
        <Spin tip="Loading" size="large" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
