import { Space, Row, Col, Button } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';

interface IStepWithEmailFileProps extends IStepDescription {
  label?: string;
}

const StepWithEmailFile = ({ isDisable, label, stepData }: IStepWithEmailFileProps) => {
  return (
    <div>
      <Row>
        <Col>
          <Space>
            <label>{label ?? 'วันที่หนังสือ'} :</label>
            <span>
              {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
            </span>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Space>
            <label>ไฟล์แนบ :</label>
            <Button size="small" disabled={isDisable}>
              -- กรุณาคลิกเพื่อดูไฟล์แนบ --
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithEmailFile;
