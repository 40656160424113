import axiosInstance from 'utils/axios';
import { IRisoData, ListofferContractRefer } from './interface';

type UpdateOfferParams = {
  offerId: string | number;
  data: IRisoData;
};

type ConfirmOfferDocParams = {
  offerId: string | number;
  data: {
    offerFormNo: string;
    offerFormDate: string;
    offerFormPublicUrl?: string;
  };
};

export const getOfferById = (id: string | number) => {
  return axiosInstance.get(`offer/${id}`);
};

export const getOfferContractRefer = (query: ListofferContractRefer) => {
  return axiosInstance.get('offer/contract-refer', {
    params: query,
  });
};

export const OfferLinkUpdate = (data: { offerFormId: number; offerFormPublicUrl: string }) => {
  return axiosInstance.put(`access-offer/updateLink`, data);
};

export const createOffer = (data: IRisoData) => {
  return axiosInstance.post('offer/create', data);
};

export const updateOffer = ({ offerId, data }: UpdateOfferParams) => {
  return axiosInstance.put(`offer/${offerId}/update`, data);
};

export const confirmOffer = (offerId: string | number) => {
  return axiosInstance.patch(`offer/${offerId}/changeOfferFormStatusToWaitFile`);
};

export const improveOffer = (offerId: string | number) => {
  return axiosInstance.put(`offer/${offerId}/improve`);
};

export const confirmOfferDoc = ({ offerId, data }: ConfirmOfferDocParams) => {
  return axiosInstance.put(`offer/${offerId}/operator/confirm`, data);
};

export const getServiceAnnounceRate = () => {
  return axiosInstance.get('/offer/RIO/serviceAnnounceRate');
};
