import { ButtonComponent, HeaderTitleComponent } from '../../../components';
import { useEffect, useState } from 'react';
import DisagreementForm, {
  DisagreementFormData,
} from '../components/DisagreementForm/DisagreementForm';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import style from './CreateDisagreement.module.css';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useApi } from 'hooks/useApi';
import { getStringDateFromDayJs } from 'utils/date';
import { CreateDisagreementInput, CreateDisagreementResponse } from 'api/disagreement/interface';
import { createDisagreement } from 'api/disagreement';
import { Dayjs } from 'dayjs';
import { mainRoutePaths, subRoutePaths } from 'constants/routes';

const CreateDisagreement = (): JSX.Element => {
  let navigate = useNavigate();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { makeRequest: createDisagreementRequest } = useApi();
  const [data, setData] = useState<DisagreementFormData>();

  const onCreate = async (): Promise<void> => {
    const disagreementForm = data;
    if (disagreementForm) {
      const payload: CreateDisagreementInput = {
        formNo: disagreementForm.formNo,
        formDate: disagreementForm.formDate
          ? getStringDateFromDayJs(disagreementForm.formDate as Dayjs)
          : undefined,
        petitioner: disagreementForm.petitionerId,
        accused: disagreementForm.accusedId,
        topic: disagreementForm.topic,
      };

      const res = await createDisagreementRequest(async () => {
        return await createDisagreement(payload);
      });
      const disagreement: CreateDisagreementResponse = res.data;
      navigate(subRoutePaths.disagreementEdit(disagreement.data.formId));
    }
  };

  const onFormValueChange = (data: DisagreementFormData) => {
    setData(data);
  };

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  });
  return (
    <>
      <HeaderTitleComponent>รายละเอียดตรวจสอบรับคำร้อง</HeaderTitleComponent>
      <DisagreementForm onFormDataChange={onFormValueChange} />
      <div className={style.divButtonGroup}>
        <ButtonComponent htmlType="submit" onClick={() => onCreate()}>
          <SaveOutlined />
          บันทึกรายการ
        </ButtonComponent>
        <ButtonComponent htmlType="reset" onClick={() => navigate(mainRoutePaths.disagreementList)}>
          <CloseOutlined />
          ย้อนกลับ
        </ButtonComponent>
      </div>
    </>
  );
};
export default CreateDisagreement;
