import { useEffect } from 'react';
import { Form, Row, Col, Input } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { OfferLinkUpdate } from 'api/offer';

import { ThaiDatepickerComponent } from 'components';

import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';
import { formLayout } from 'constants/form';

import { IStepModalForm } from './interfaces';

interface IStepFormWithLink extends IStepModalForm {
  offerData: any;
  onRefetchOfferData: () => void;
}

const StepFormWithLink = ({
  form,
  stepData,
  isDisable,
  onUpdateOfferState,
  offerData,
  onRefetchOfferData,
}: IStepFormWithLink) => {
  const { makeRequest }: TApiResponse = useApi();

  const onFinish = async (values: any) => {
    if (offerData) {
      await makeRequest(() =>
        OfferLinkUpdate({
          offerFormId: offerData.offerFormId,
          offerFormPublicUrl: values.offerFormPublicUrl,
        })
      );
      onRefetchOfferData();
    } else {
      console.error('offerData is undefined');
    }

    let date = new Date(values.actionDate);
    let data = {
      formStateId: stepData?.formStateId,
      stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: '',
      actionName: '',
      actionDate: dayJsformatDateObjToString(date),
      approvedNo: '',
      approvedDate: null,
      isCompleteState: false,
      isCurrentState: true,
    };
    onUpdateOfferState(data);
  };

  useEffect(() => {
    if (stepData) {
      form.setFieldsValue({
        stateName: stepData.stateName,
        actionDate: stepData.actionDate ? getJSDateObj(stepData.actionDate) : undefined,
      });
    }

    if (offerData) {
      form.setFieldsValue({
        offerFormPublicUrl: offerData.offerFormPublicUrl,
      });
    }
  }, [form, stepData, offerData]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      form={form}
      name="stepModalForm"
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะข้อเสนอ" name="stateName">
            <Input size="large" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label="วันที่ได้รับ Link เปิดเผยข้อเสนอฯ"
            name="actionDate"
            rules={[{ required: true, message: 'กรุณาเลือกวันที่ได้รับ Link เปิดเผยข้อเสนอฯ' }]}
          >
            <ThaiDatepickerComponent disabled={isDisable} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label="Link เปิดเผยข้อเสนอฯ"
            name="offerFormPublicUrl"
            rules={[{ required: true, message: 'กรุณากรอก Link เปิดเผยข้อเสนอฯ' }]}
          >
            <Input size="large" disabled={isDisable} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default StepFormWithLink;
