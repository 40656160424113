import { ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import RoleBasedGuard from './RoleBasedGuard';
import useAuth from 'hooks/useAuth';

type AuthGuardProps = {
  children: ReactNode;
  permission?: string;
};

export default function AuthGuard({ children, permission }: AuthGuardProps) {
  const { isAuthenticated, isAuthenticating, getAuth } = useAuth();
  const location = useLocation();

  useEffect(() => {
    getAuth();
  }, [getAuth]);

  if (isAuthenticating) {
    return <>Loading...</>;
  }
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <RoleBasedGuard permission={permission}>{children}</RoleBasedGuard>;
}
