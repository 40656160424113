import { Col, Form, FormInstance, Input, Modal, Row, Spin } from 'antd';

import { formLayout } from 'constants/form';
import ModalFooter from 'components/ModalFooter';
import { ThaiDatepickerComponent } from 'components';

type ConfirmDocModalProps = {
  isModalOpen: boolean;
  confirmDocFormRef: FormInstance<any>;
  isConfirmingDoc: boolean;
  isShowPublicUrlInput: boolean;
  onHandleCancel: () => void;
  onSubmitConfirmDoc: () => void;
};

const ConfirmDocModal = ({
  isModalOpen,
  onHandleCancel,
  confirmDocFormRef,
  isConfirmingDoc,
  onSubmitConfirmDoc,
  isShowPublicUrlInput,
}: ConfirmDocModalProps) => {
  return (
    <>
      <Modal
        title="การจัดส่งเอกสาร"
        open={isModalOpen}
        maskClosable={false}
        width="80vh"
        onCancel={() => onHandleCancel()}
        footer={
          <ModalFooter
            handleCancel={onHandleCancel}
            onSubmitForm={() => {
              confirmDocFormRef.submit();
            }}
          />
        }
      >
        <Spin spinning={isConfirmingDoc}>
          <Form
            {...formLayout}
            labelWrap
            form={confirmDocFormRef}
            name="confirmDocForm"
            size="large"
            onFinish={() => onSubmitConfirmDoc()}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="เลขที่หนังสือผู้ประกอบการ"
                  name="offerFormNo"
                  rules={[{ required: true, message: 'กรุณากรอกเลขที่หนังสือผู้ประกอบการ' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="วันที่หนังสือผู้ประกอบการ"
                  name="offerFormDate"
                  rules={[{ required: true, message: 'กรุณากรอกวันที่หนังสือผู้ประกอบการ' }]}
                >
                  <ThaiDatepickerComponent />
                </Form.Item>
              </Col>
              {isShowPublicUrlInput && (
                <Col span={24}>
                  <Form.Item
                    label="Link Web เผยแพร่"
                    name="offerFormPublicUrl"
                    rules={[{ required: true, message: 'กรุณากรอก Link Web เผยแพร่' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default ConfirmDocModal;
