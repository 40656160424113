import { Table } from 'antd';
import React from 'react';
import { tableColumns } from './columnsTable';
import { useNavigate } from 'react-router-dom';

type Props = {
  data: any;
  loading: boolean;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  currentPageNumber: number;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageable: any;
  onModalOpenOffer: (val: any) => void;
  onModalOpenHistory: (id: number | string) => void;
};

const SearchTable = (props: Props) => {
  const {
    data,
    currentPageNumber,
    loading,
    pageSize,
    pageable,
    setCurrentPageNumber,
    setPageSize,
    onModalOpenOffer,
    onModalOpenHistory,
  } = props;
  let navigator = useNavigate();
  return (
    <Table
      columns={tableColumns(
        navigator,
        currentPageNumber,
        pageSize,
        onModalOpenOffer,
        onModalOpenHistory
      )}
      dataSource={data}
      loading={loading}
      rowKey={'offerFormId'}
      size="middle"
      pagination={{
        onChange: (page, pageSize) => {
          setPageSize(pageSize);
          setCurrentPageNumber(page - 1);
        },
        onShowSizeChange: (_, pageSize) => {
          setPageSize(pageSize);
        },
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: pageable?.totalItems,
        showSizeChanger: true,
        defaultPageSize: pageSize,
      }}
    />
  );
};

export default SearchTable;
