import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listAssignTasksOfferFormStates } from 'api/assignTasks';

type OfferStatusDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  disabled?: boolean;
};

const OfferStatusDropdown = ({ label, labelCol, disabled = false }: OfferStatusDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listAssignTasksOfferFormStates());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name="offerFormStatus"
        label={label ? label : 'สถานะข้อเสนอ'}
        labelCol={labelCol}
        // rules={[{ required: true, message: `กรุณาเลือก${label ? label : 'สถานะข้อเสนอ'}` }]}
      >
        <Select
          loading={loading}
          disabled={disabled}
          placeholder="-- กรุณาระบุ --"
          options={
            data
              ? data.map((item: { code: string; name: string }) => ({
                  value: item.code,
                  label: item.name,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OfferStatusDropdown;
