/* eslint-disable */
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { TApiResponse, useApi } from 'hooks/useApi';
import DisagreementForm, {
  DisagreementFormData,
} from '../components/DisagreementForm/DisagreementForm';
import {
  Disagreement,
  DisAgreementState,
  GetDisagreementResponse,
  UpdateDisagreementInput,
} from 'api/disagreement/interface';
import {
  convertDataFormDatePickerToString,
  convertDateFromApiToDatePickerWithTimeZoneAsian,
} from 'utils/date';
import { getDisagreement, updateDisagreement } from 'api/disagreement';
import React, { useCallback, useEffect, useState } from 'react';
import { ButtonComponent, HeaderTitleComponent } from 'components';
import style from './DetailDisagreement.module.css';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import DisagreementSteps from './components/DisagreementSteps/DisagreementSteps';
import { Col, Row } from 'antd';
import { mainRoutePaths } from 'constants/routes';

const DetailDisagreement = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { makeRequest: updateDisagreementRequest } = useApi();
  const { makeRequest: getDisagreementRequest } = useApi();
  const [detail, setDetail] = useState<DisagreementFormData>();
  const [data, setData] = useState<DisagreementFormData>();
  const [state, setState] = useState<DisAgreementState[]>([]);
  const [currentState, setCurrentState] = useState(1);
  const [disagreement, setDisagreement] = useState<Disagreement>();

  const fetchData = useCallback(async () => {
    const result: TApiResponse<GetDisagreementResponse> = await getDisagreementRequest(() => {
      if (id) {
        return getDisagreement(id);
      }
      throw new Error('Empty Disagreement ID');
    });

    return result.data.data;
  }, [id]);

  const onNextStateCompleted = () => {
    fetchData().then((disagreement) => {
      setDisagreement(disagreement);
      setDetail({
        id: disagreement.formId,
        formNo: disagreement.formNo,
        formDate: convertDateFromApiToDatePickerWithTimeZoneAsian(disagreement.formDate),
        petitionerId: disagreement.petitionerId,
        accusedId: disagreement.accusedId,
        topic: disagreement.topic,
      });
      setState(disagreement.disAgreementStates.sort((a, b) => a.stateCode - b.stateCode));
      const currenState = disagreement.disAgreementStates.find((i) => i.isCurrentState);
      if (currenState) {
        setCurrentState(currenState.stateCode);
      } else {
        const currenState = disagreement.formStatus ? parseInt(disagreement.formStatus) : 1;
        setCurrentState(currenState);
      }
    });
  };

  useEffect(() => {
    fetchData().then((disagreement) => {
      setDisagreement(disagreement);
      setDetail({
        id: disagreement.formId,
        formNo: disagreement.formNo,
        formDate: convertDateFromApiToDatePickerWithTimeZoneAsian(disagreement.formDate),
        petitionerId: disagreement.petitionerId,
        accusedId: disagreement.accusedId,
        topic: disagreement.topic,
      });
      setState(disagreement.disAgreementStates.sort((a, b) => a.stateCode - b.stateCode));
      const currenState = disagreement.disAgreementStates.find((i) => i.isCurrentState);
      if (currenState) {
        setCurrentState(currenState.stateCode);
      } else {
        const currenState = disagreement.formStatus ? parseInt(disagreement.formStatus) : 1;
        setCurrentState(currenState);
      }
    });
  }, [id]);

  const onUpdate = async (): Promise<void> => {
    const disagreementForm = data;
    if (disagreementForm) {
      const payload: UpdateDisagreementInput = {
        id: detail!.id,
        formNo: disagreementForm.formNo,
        formDate: disagreementForm.formDate
          ? convertDataFormDatePickerToString(disagreementForm.formDate)
          : undefined,
        petitioner: detail!.petitionerId,
        accused: detail!.accusedId,
        topic: disagreementForm.topic,
      };

      await updateDisagreementRequest(() => {
        return updateDisagreement(payload);
      });
      navigate(mainRoutePaths.disagreementList);
    }
  };

  const onFormValueChange = (data: DisagreementFormData) => {
    setData(data);
  };

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, []);

  return (
    <>
      <HeaderTitleComponent>รายละเอียดตรวจสอบรับคำร้อง</HeaderTitleComponent>
      <DisagreementForm data={detail} onFormDataChange={onFormValueChange} />

      <div className={style.divButtonGroup}>
        <ButtonComponent htmlType="submit" onClick={() => onUpdate()}>
          <SaveOutlined />
          บันทึกรายการ
        </ButtonComponent>
        <ButtonComponent htmlType="reset" onClick={() => navigate(mainRoutePaths.disagreementList)}>
          <CloseOutlined />
          ย้อนกลับ
        </ButtonComponent>
      </div>

      <div className="content-box" style={{ marginLeft: 40 }}>
        <Row>
          <Col>
            <DisagreementSteps
              disagreement={disagreement}
              steps={state}
              currentState={currentState}
              id={detail?.id || 0}
              onNextStateCompleted={onNextStateCompleted}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
export default DetailDisagreement;
