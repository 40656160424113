import { createContext } from 'react';
import { FormInstance } from 'antd';

import { IContAttachFiles } from '../interfaces';
import useCancelContractModal from '../hooks/useCancelContractModal';

type TCancelContractModalContext = {
  loading: boolean;
  isOpenFileDialog: boolean;
  isUploadingFile: boolean;
  isDeletingFile: boolean;
  isModalOpen: boolean;
  formRef: FormInstance<any>;
  uploadFormRef: FormInstance<any>;
  contractTypeName: string;
  conFormId: string | undefined;
  contAttachFiles: IContAttachFiles[];
  fileList: any[];
  onSubmitCancelContract: (values: any) => Promise<void>;
  onUploadFile: () => Promise<void>;
  onValidateDocType: () => void;
  onRemoveFile: (file: any) => void;
  downloadFile: (file: any) => void;
  handleUpload: (file: any) => Promise<void>;
  handleCancel: () => void;
  setDocId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDocName: React.Dispatch<React.SetStateAction<string>>;
  setIsOpenFileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDocTypeOption: React.Dispatch<React.SetStateAction<any>>;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<any>>;
};

const CancelContractModalContext = createContext<TCancelContractModalContext>(
  {} as TCancelContractModalContext
);

type CancelContractModalProviderProps = {
  children: React.ReactNode;
  isModalOpen: boolean;
  offerFormType: string;
  conFormId: string | undefined;
  contAttachFiles: IContAttachFiles[];
  handleCancel: () => void;
  setContractData: React.Dispatch<any>;
};

const CancelContractModalProvider = ({
  children,
  isModalOpen,
  offerFormType,
  conFormId,
  contAttachFiles,
  handleCancel,
  setContractData,
}: CancelContractModalProviderProps) => {
  const {
    loading,
    isOpenFileDialog,
    isUploadingFile,
    isDeletingFile,
    formRef,
    uploadFormRef,
    contractTypeName,
    fileList,
    onSubmitCancelContract,
    onUploadFile,
    onValidateDocType,
    onRemoveFile,
    downloadFile,
    handleUpload,
    setDocId,
    setDocName,
    setIsOpenFileDialog,
    setSelectedDocTypeOption,
    setCurrentUploadFile,
  } = useCancelContractModal({
    handleCancel,
    offerFormType,
    conFormId,
    contAttachFiles,
    setContractData,
  });
  return (
    <CancelContractModalContext.Provider
      value={{
        loading,
        isOpenFileDialog,
        isUploadingFile,
        isDeletingFile,
        isModalOpen,
        formRef,
        uploadFormRef,
        contractTypeName,
        fileList,
        conFormId,
        contAttachFiles,
        onSubmitCancelContract,
        onUploadFile,
        onValidateDocType,
        onRemoveFile,
        downloadFile,
        handleUpload,
        handleCancel,
        setDocId,
        setDocName,
        setIsOpenFileDialog,
        setSelectedDocTypeOption,
        setCurrentUploadFile,
      }}
    >
      {children}
    </CancelContractModalContext.Provider>
  );
};

export { CancelContractModalContext, CancelContractModalProvider };
