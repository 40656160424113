import { Form, FormInstance, Input, Select } from 'antd';
import { listContNotiDocType } from 'api/contract';
import { TApiResponse, useApi } from 'hooks/useApi';
import { useEffect } from 'react';

type DocTypeDropdownProps = {
  isDisabled: boolean;
  state: string | undefined;
  onChangeDocType?: (value: any, option: any) => void;
  form: FormInstance<any>;
};

const DocTypeDropdown = ({ isDisabled, form, state, onChangeDocType }: DocTypeDropdownProps) => {
  const { makeRequest, data: docTypeList, loading: isLoadingDocType }: TApiResponse = useApi();

  useEffect(() => {
    if (state) {
      makeRequest(() => listContNotiDocType(state));
    }
  }, [makeRequest, state]);

  return (
    <>
      <Form.Item
        colon
        label="เลือกกลุ่มเอกสาร"
        name="docId"
        rules={[{ required: true, message: 'กรุณาเลือกกลุ่มเอกสาร' }]}
      >
        <Select
          loading={isLoadingDocType}
          placeholder="-- กรุณาระบุ --"
          size="large"
          disabled={isDisabled}
          onChange={(value, option: any) => {
            form.setFieldValue('docName', option);
            if (onChangeDocType) onChangeDocType(value, option);
          }}
          options={
            docTypeList
              ? docTypeList.map((item: any) => ({
                  value: item.docId,
                  label: item.docName,
                  required: item.required,
                }))
              : []
          }
        />
      </Form.Item>
      <Form.Item name="docName" hidden>
        <Input />
      </Form.Item>
    </>
  );
};

export default DocTypeDropdown;
