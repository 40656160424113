import { Table } from 'antd';

import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';

import { getColumns } from './columns';

const ServPayTable = () => {
  const { selectedService, onEditRIOServePay, onDeleteServePay } = useOfferServicesContext();
  return (
    <>
      <Table
        bordered
        size="small"
        columns={getColumns({
          onEditRIOServePay,
          onDeleteServePay,
        })}
        dataSource={selectedService?.offerServePays ? selectedService?.offerServePays : []}
        rowKey={(value) => value.key}
        pagination={false}
        scroll={{ x: true }}
      />
    </>
  );
};

export default ServPayTable;
