import { useLocation, useNavigate } from 'react-router-dom';

import { Row, Space } from 'antd';
import { RollbackOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';

import { ATTACHMENT_TAB } from 'features/Contract/constant';
import { IContAttachFiles } from 'features/Contract/interfaces';

import PrimaryButton from 'components/PrimaryButton';
import ConfirmSaveButton from '../ConfirmSaveButton';
import UpdateNewContractButton from '../UpdateNewContractButton';
import CancelContractButton from '../CancelContractButton';
import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';
import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';
import useContractDataContext from 'features/Contract/hooks/useContractDataContext';

import useConditionConfig from './hooks/useConditionConfig';

type ButtonGroupContractTabProps = {
  onSubmitForm: Function;
  offerFormType: string;
  isDisabled: boolean;
  isNewPage: boolean;
  onGetContractById: Function;
  conFormId: string | undefined;
  stateCode: number | undefined;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setIsUpdatingState: React.Dispatch<React.SetStateAction<boolean>>;
  contAttachFiles: IContAttachFiles[];
  setContractData: React.Dispatch<any>;
  isActiveContract: boolean;
  isNotAllowedGoToAttachmentTab?: boolean;
  isInquiryPage?: boolean;
};

const ButtonGroupContractTab = ({
  onSubmitForm,
  offerFormType,
  isDisabled,
  isNewPage,
  onGetContractById,
  conFormId,
  stateCode,
  setActiveTab,
  setIsUpdatingState,
  contAttachFiles,
  setContractData,
  isActiveContract,
  isNotAllowedGoToAttachmentTab = false,
  isInquiryPage = false,
}: ButtonGroupContractTabProps) => {
  const { isReviewPage } = useContractDataContext();
  const { currentContServes } = useContractServicesContext();
  const { currentContFacilities } = useContractFacilitiesContext();

  const { isShowEditContract, isShowSaveContractButton, isShowConfirmContractButton } =
    useConditionConfig({
      isReviewPage,
      stateCode,
      isNewPage,
    });

  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state ? state.from : '/';

  return (
    <Row justify={'center'} style={{ marginTop: 15 }}>
      <Space>
        {isShowEditContract ? (
          <>
            <CancelContractButton
              offerFormType={offerFormType}
              conFormId={conFormId}
              contAttachFiles={contAttachFiles}
              setContractData={setContractData}
              isActiveContract={isActiveContract}
              isInquiryPage={isInquiryPage}
            />
            <UpdateNewContractButton
              conFormId={conFormId}
              offerFormType={offerFormType}
              setIsUpdatingState={setIsUpdatingState}
              isActiveContract={isActiveContract}
              isInquiryPage={isInquiryPage}
            />
          </>
        ) : (
          <>
            {isShowSaveContractButton && (
              <PrimaryButton
                disabled={isDisabled}
                icon={<SaveOutlined />}
                onClick={() => onSubmitForm({ currentContServes, currentContFacilities })}
              >
                บันทึกรายการ
              </PrimaryButton>
            )}
            {isShowConfirmContractButton && (
              <ConfirmSaveButton
                isReviewPage={isReviewPage}
                isDisabled={isDisabled}
                isNewPage={isNewPage}
                conFormId={conFormId}
                stateCode={stateCode}
                onGetContractById={onGetContractById}
                setIsUpdatingState={setIsUpdatingState}
              />
            )}
            {isShowSaveContractButton && (
              <PrimaryButton
                disabled={isDisabled || isNewPage || isNotAllowedGoToAttachmentTab}
                icon={<UploadOutlined />}
                onClick={() => {
                  setActiveTab(ATTACHMENT_TAB);
                }}
              >
                แนบเอกสาร
              </PrimaryButton>
            )}
          </>
        )}
        <PrimaryButton icon={<RollbackOutlined />} onClick={() => navigate(from)}>
          ย้อนกลับหน้าค้นหา
        </PrimaryButton>
      </Space>
    </Row>
  );
};

export default ButtonGroupContractTab;
