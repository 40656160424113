/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col, Form, Input, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ButtonComponent, HeaderTitleComponent, ThaiDatepickerComponent } from 'components';
import { useApi } from 'hooks/useApi';
import { getAuditLog } from 'api/auditLog';
import { useForm } from 'antd/es/form/Form';
import { dayJsformatDateToBCYear, getStringDateFromDayJs } from 'utils/date';
import { Dayjs } from 'dayjs';
import { uniqueId } from 'lodash';

// interface DataSource {
//   data: AuditLogData[];
//   pageable: Pageable;
// }

interface AuditLogData {
  key: string;
  userLogin: string;
  officerName: string;
  date: string;
  accessUrl: string;
}

const columns: ColumnsType<AuditLogData> = [
  {
    title: 'ลำดับ',
    dataIndex: 'key',
    key: 'key',
    render: (value, record, index) => <div>{index + 1}</div>,
  },
  {
    title: 'วัน-เวลา',
    dataIndex: 'date',
    key: 'date',
  },
  {
    title: 'รหัสผู้ใช้',
    dataIndex: 'userLogin',
    key: 'userLogin',
  },
  {
    title: 'ชื่อผู้ปฏิบัติงาน',
    dataIndex: 'officerName',
    key: 'officerName',
  },
  {
    title: 'URL ที่เข้าใช้งาน',
    dataIndex: 'accessUrl',
    key: 'accessUrl',
  },
];


const AuditLog = (): JSX.Element => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [form] = useForm();
  const [dataSource, setDataSource] = useState<AuditLogData[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [current, setCurrent] = useState(1);
  const { makeRequest: getAuditLogRequest } = useApi();

  const onSearch = (size: number, currentPage: number) => {
    getAuditLogRequest(async () => {
      const res = await getAuditLog({
        pageSize: size,
        currentPageNumber: currentPage,
        startDate: form.getFieldValue('startDate') ? getStringDateFromDayJs(form.getFieldValue('startDate') as Dayjs) : undefined,
        endDate: form.getFieldValue('endDate') ? getStringDateFromDayJs(form.getFieldValue('endDate') as Dayjs) : undefined,
        userLogin: form.getFieldValue('userLogin') ? form.getFieldValue('userLogin') : undefined,
      });
      const logMapped: AuditLogData[] = res.data.data.map((value, index) => {
        return {
          key: uniqueId(),
          userLogin: value.userLogin,
          officerName: value.officerName,
          date: dayJsformatDateToBCYear(value.date),
          accessUrl: value.accessUrl,
        };
      });
      setDataSource(logMapped);
      setTotal(res.data.pageable.totalItems);
    });
  };

  useEffect(() => {
    getAuditLogRequest(async () => {
      const res = await getAuditLog({
        pageSize: 10,
        currentPageNumber: 1,
      });
      const logMapped: AuditLogData[] = res.data.data.map((value, index) => {
        return {
          key: uniqueId(),
          userLogin: value.userLogin,
          officerName: value.officerName,
          date: dayJsformatDateToBCYear(value.date),
          accessUrl: value.accessUrl,
        };
      });
      setDataSource(logMapped);
      setTotal(res.data.pageable.totalItems);
    });
  }, []);

  useEffect(() => {
    setHeaderTitle('ตรวจสอบประวัติการเข้าใช้งานระบบ');
  }, []);
  return <div>
    <HeaderTitleComponent>ประวัติการเข้าใช้งานระบบ</HeaderTitleComponent>
    <Form form={form} style={{ marginTop: '24px' }}>
      <Row gutter={[16, 16]} justify={'center'}>
        <Col>
          <Form.Item name='startDate' label={'วันที่เริ่มต้น'}>
            <ThaiDatepickerComponent></ThaiDatepickerComponent>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name='endDate' label={'วันที่สิ้นสุด'}>
            <ThaiDatepickerComponent></ThaiDatepickerComponent>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name='userLogin' label={'รหัสผู้ใช้'}>
            <Input></Input>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify={'center'} style={{ marginTop: '24px' }}>
        <Col>
          <ButtonComponent onClick={() => {
            setPageSize(10);
            setCurrent(1);
            onSearch(10, 0);
          }
          }>ค้นหา</ButtonComponent>
        </Col>
        <Col>
          <ButtonComponent onClick={() => {
            form.resetFields(['startDate', 'endDate', 'userLogin']);
          }
          }>ล้างข้อมูล</ButtonComponent>
        </Col>
      </Row>
    </Form>
    <div style={{ marginTop: '24px' }}>
      <Table rowKey={'key'} onChange={(page) => {
        if (page.current !== current) {
          setCurrent(page.current || 1);
        }
      }}
             columns={columns} dataSource={dataSource} pagination={{
        onChange: (currentPage, size) => {
          onSearch(size, currentPage - 1);
        },
        current,
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: total,
        defaultPageSize: pageSize,
        pageSizeOptions: [10, 20, 50],
      }} />
    </div>
  </div>;
};
export default AuditLog;
