import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { offerType } from '../../Search/options';

import buttonCSS from 'styles/components/button.module.css';
type Props = {
  form: FormInstance<any>;
  onFinishOfferContent: (values: any) => void;
  onFinishFailedOfferContent: (errorInfo: any) => void;
  onModalCancel: () => void;
  operatorList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  listAssignees:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
};

const ModalOfferContent = (props: Props) => {
  const {
    form,
    onFinishOfferContent,
    onFinishFailedOfferContent,
    onModalCancel,
    operatorList,
    listAssignees,
  } = props;
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 16 },
  };
  return (
    <Content>
      {' '}
      <Form
        name="basic"
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinishOfferContent}
        onFinishFailed={onFinishFailedOfferContent}
        autoComplete="off"
        requiredMark={false}
        {...layout}
        labelWrap
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="ระบุการจัดทำข้อเสนอ :"
              name="offerType"
              rules={[{ required: true, message: 'กรุณาเลือกประเภทข้อเสนอ' }]}
            >
              <Select placeholder="-- กรุณาระบุ --" showSearch allowClear options={offerType} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="ผู้ประกอบการ :"
              name="operators"
              rules={[{ required: true, message: 'กรุณาเลือกผู้ประกอบการ' }]}
            >
              <Select placeholder="-- กรุณาระบุ --" showSearch allowClear options={operatorList} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="ผู้ได้รับมอบหมาย :"
              name="officerIds"
              rules={[{ required: true, message: 'กรุณาเลือกผู้ได้รับมอบหมาย' }]}
            >
              <Select
                placeholder="-- กรุณาระบุ --"
                showSearch
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                mode="multiple"
                allowClear
                options={listAssignees}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 20 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              //   icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ยืนยัน
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              //   icon={<ClearOutlined />}
              onClick={onModalCancel}
              size="large"
              htmlType="reset"
            >
              ยกเลิก
            </Button>
          </Col>
        </Row>
      </Form>
    </Content>
  );
};

export default ModalOfferContent;
