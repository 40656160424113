import { Button, Col, Form, FormInstance, Row } from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

import { formLayout } from 'constants/form';

import buttonCSS from 'styles/components/button.module.css';
import OperatorsDropdown from '../OperatorsDropdown';
import PublishLinkStatusDropdown from '../PublishLinkStatusDropdown';

type SearchFormProps = {
  formRef: FormInstance<any>;
  onSearch: (values: any) => void;
};

const SearchForm = ({ formRef, onSearch }: SearchFormProps) => {
  const onReset = (): void => {
    formRef.resetFields();
  };

  return (
    <div className="plr-15">
      <Form
        labelWrap
        name="publishUrlSearchForm"
        size="large"
        labelAlign="left"
        form={formRef}
        onFinish={onSearch}
        {...formLayout}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <OperatorsDropdown name="taxId" labelCol={{ span: 5 }} />
          </Col>
          <Col span={12}>
            <PublishLinkStatusDropdown name="isActivePublicUrl" labelCol={{ span: 5 }} />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              onClick={onReset}
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchForm;
