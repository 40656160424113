import { Col, Form, Row, Select } from 'antd';
import InputText from '../../../../components/InputTextComponent';
import { ButtonComponent, ThaiDatepickerComponent } from '../../../../components';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import style from './SearchDisagreementForm.module.css';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { useApi } from 'hooks/useApi';
import { useEffect, useState } from 'react';
import { getAllFormStatus } from 'api/disagreement';


export interface SearchDisagreementFormInstance {
  status: string;
  bookNo: string;
  startDate: Date;
  endDate: Date;
  petitioner: number;
  accused: number;
}

export interface Option {
  value: number;
  label: string;
  disabled: boolean;
}

interface OptionFormStatus {
  value: string;
  label: string;
}

interface SearchDisagreementFormProps {
  onFinish: ((values: any) => void) | undefined;
  onFinishFailed: (errorInfo: any) => void;
  onSearchFormValueChange: (value: any) => void;
  onReset: () => void;
  form: FormInstance;
  onSearch: () => void;
  listAccused: Option[];
  listPetitioner: Option[];
}


const SearchDisagreementForm = (
  props: SearchDisagreementFormProps,
): JSX.Element => {
  const { makeRequest: getAllStatusRequest } = useApi();
  const [options, setOptions] = useState<OptionFormStatus[]>([]);

  const onResetSearchForm = () => {
    props.onReset();
  };

  useEffect(() => {
    getAllStatusRequest(async () => {
      const res = await getAllFormStatus();
      const newOptions: OptionFormStatus[] = [];
      res.data.data.forEach(i => {
        newOptions.push({
          value: i.formStatus,
          label: i.formStatusName,
        });
      });
      setOptions(newOptions);
      return res;
    });
  }, [getAllStatusRequest]);

  return (
    <>
      <Form
        name='basic'
        form={props.form}
        initialValues={{ remember: true }}
        onFinish={props.onFinish}
        onFinishFailed={props.onFinishFailed}
        autoComplete='off'
        requiredMark={false}
        onValuesChange={props.onSearchFormValueChange}
      >
        <div className='form-manage-rao-row-col'>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6} className={style.col}>
              <div>เลขที่หนังสือนำส่ง :</div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div>
                <Form.Item
                  name='bookNo'

                >
                  <InputText />
                </Form.Item>
              </div>
            </Col>
            <Col className={style.col} span={6}>
              <div>สถานะข้อพิพาท :</div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div>
                <Form.Item name='status'>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='เลือกสถานะข้อพิพาท'
                    onChange={() => {
                    }}
                    options={options}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6} className={style.col}>
              <div>วันที่หนังสือนำส่งตั้งแต่ :</div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div>
                <Form.Item name='startDate'>
                  <ThaiDatepickerComponent
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className={style.col} span={6}>
              <div> วันที่หนังสือผู้ประกอบการถึง :</div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div>
                <Form.Item name='endDate'>
                  <ThaiDatepickerComponent
                    disabled={!props.form.getFieldValue('startDate')}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={6} className={style.col}>
              <div>ผู้ร้อง :</div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div>
                <Form.Item name='petitioner'>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.label.indexOf(input) || -1) >= 0;
                    }}
                    placeholder='เลือกผู้ร้อง'
                    onChange={() => {
                    }}
                    options={props.listPetitioner}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col className={style.col} span={6}>
              <div> ผู้ถูกร้อง :</div>
            </Col>
            <Col className='gutter-row' span={6}>
              <div>
                <Form.Item name='accused'>
                  <Select
                    style={{ width: '100%' }}
                    placeholder='เลือกผู้ถูกร้อง'
                    showSearch
                    filterOption={(input, option) => {
                      return (option?.label.indexOf(input) || -1) >= 0;
                    }}
                    onChange={() => {
                    }}
                    options={props.listAccused}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <div className={style.divButtonGroup}>
            <ButtonComponent htmlType='submit'>
              <SearchOutlined />
              ค้นหาข้อมูล
            </ButtonComponent>
            <ButtonComponent htmlType='reset' onClick={onResetSearchForm}>
              <ClearOutlined />
              ล้างเงื่อนไข
            </ButtonComponent>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default SearchDisagreementForm;
