import axiosInstance from 'utils/axios';
import { ListOfferReviewQuery } from './interfaces';

export const listReviewOffer = (query: ListOfferReviewQuery) => {
  return axiosInstance.get(`/review-offer/readAll`, {
    params: query,
  });
};

export const getReviewOfferState = (offerId: number | string | undefined) => {
  return axiosInstance.get(`/review-offer/offer/${offerId}/state`);
};

export const updateReviewOfferState = (offerId: string | number, data: any) => {
  return axiosInstance.put(`/review-offer/offer/${offerId}/state`, { ...data });
};

export const listStateFile = (formStateId: string | number) => {
  return axiosInstance.get(`/review-offer/state/${formStateId}/readAll`);
};

export const uploadStateFile = (formStateId: string | number, formData: any) => {
  return axiosInstance.post(`/review-offer/state/${formStateId}/attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteStateFile = (attachFileId: string | number) => {
  return axiosInstance.delete(`/review-offer/state/attachment/${attachFileId}/delete`);
};

export const downloadStateFile = (attachFileId: string | number) => {
  return axiosInstance.get(`/review-offer/state/attachment/${attachFileId}/download`, {
    responseType: 'blob', // This tells Axios to return the response as a blob object
  });
};

export const getReviewOfferById = (offerId: string | number) => {
  return axiosInstance.get(`/review-offer/${offerId}`);
};

export const updateStateNotifyEmail = (formStateId: string | number, data: any) => {
  return axiosInstance.post(`offer/${formStateId}/state-notify-email`, { ...data });
};
