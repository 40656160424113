import { Space, Row, Col } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';

const StepWithStatus = ({ stepData }: IStepDescription) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Space>
            <label>วันที่รับเรื่อง :</label>
            <span>
              {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
            </span>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            <label>สถานะข้อเสนอ :</label>
            <span>{stepData?.actionName ? stepData.actionName : '-'}</span>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithStatus;
