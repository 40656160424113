import { Table } from 'antd';
import { columns } from './column';

type ReportListTableProps = {
  data: any[];
  loading: boolean;
  paginationSetting: any;
  onRefreshReportStatus: (jobId: string) => void;
  onDownloadReport: (record: any) => void;
  dateLabel?: string;
  isContractReport?: boolean;
};

const ReportListTable = ({
  data,
  loading,
  paginationSetting,
  onRefreshReportStatus,
  onDownloadReport,
  dateLabel,
  isContractReport,
}: ReportListTableProps) => {
  return (
    <div className="content-box ">
      <Table
        columns={columns({ onRefreshReportStatus, onDownloadReport, dateLabel, isContractReport })}
        dataSource={data}
        loading={loading}
        rowKey={(value) => {
          return value.jobId;
        }}
        size="middle"
        pagination={paginationSetting}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default ReportListTable;
