import { Col, Form, Input, Modal, Row, Spin } from 'antd';

import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';
import useContServePayForm from 'features/Contract/hooks/useContServePayForm';

import ModalFooter from 'components/ModalFooter';
import CurrencyDropdown from 'features/Offer/components/CurrencyDropdown';
import ServiceUnitDropdown from 'features/Offer/components/ServiceUnitDropdown';
import PayPeriodUnitDropdown from 'components/PayPeriodUnitDropdown';

import { formLayout } from 'constants/form';

const ContServePayModal = () => {
  const {
    isContServePayModalOpen,
    isDisabled,
    contServePayModalFormRef,
    selectedServiceOption,
    selectedContServePay,
    onCloseContServePayModal,
    onSubmitContServePayModalForm,
  } = useContractServicesContext();
  const { isDisabledOtherUnitDesc, isDisabledOtherCurrencyDesc, onChangeCurrency, onChangeUnit } =
    useContServePayForm({ record: selectedContServePay, formRef: contServePayModalFormRef });

  return (
    <Modal
      title="อัตราค่าตอบแทน"
      width="75%"
      open={isContServePayModalOpen}
      onCancel={onCloseContServePayModal}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, .8)' }}
      footer={
        <ModalFooter
          confirmLabel="บันทึกรายการ"
          handleCancel={onCloseContServePayModal}
          onSubmitForm={() => contServePayModalFormRef.submit()}
        />
      }
    >
      <Spin spinning={false}>
        <div style={{ margin: '30px 0 40px 0' }}>
          <Form
            {...formLayout}
            labelWrap
            labelAlign="left"
            form={contServePayModalFormRef}
            name="RIOserviceModalForm"
            size="large"
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            onFinish={onSubmitContServePayModalForm}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Form.Item
                  label="เงื่อนไขราคา"
                  name="payCondition"
                  rules={[{ required: true, message: 'กรุณากรอกเงื่อนไขราคา' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="อัตราค่าตอบแทน"
                  name="payCompensation"
                  rules={[{ required: true, message: 'กรุณากรอกอัตราค่าตอบแทน' }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <CurrencyDropdown
                  onChangeOption={onChangeCurrency}
                  isRequired={true}
                  disabled={isDisabled}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label="สกุลเงิน กรณีระบุอื่นๆ"
                  name="currencyDesc"
                  labelCol={{ span: 10 }}
                  rules={
                    isDisabledOtherCurrencyDesc
                      ? undefined
                      : [{ required: true, message: 'กรุณาระบุอื่นๆ' }]
                  }
                >
                  <Input disabled={isDisabledOtherCurrencyDesc || isDisabled} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <ServiceUnitDropdown
                  onChangeOption={onChangeUnit}
                  raoServId={selectedServiceOption?.title?.value}
                  disabled={isDisabled}
                />
              </Col>
              <Col span={12}>
                <Form.Item
                  label="หน่วย (Unit) กรณีระบุอื่นๆ"
                  name="unitDesc"
                  labelCol={{ span: 10 }}
                  rules={
                    isDisabledOtherUnitDesc
                      ? undefined
                      : [{ required: true, message: 'กรุณาระบุอื่นๆ' }]
                  }
                >
                  <Input disabled={isDisabledOtherUnitDesc || isDisabled} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="ระยะเวลา"
                      labelCol={{ span: 10 }}
                      name="payPeriod"
                      rules={[
                        { required: true, message: 'กรุณาระบุระยะเวลา' },
                        { pattern: /^[0-9]+$/, message: 'กรุณากรอกตัวเลขเท่านั้น' },
                      ]}
                    >
                      <Input className="no-right-radius" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="payPeriodUnit"
                      className="no-left-radius"
                      style={{ borderLeft: 'none' }}
                      rules={[{ required: true, message: 'กรุณาเลือกหน่วยระยะเวลา' }]}
                      initialValue={1}
                    >
                      <PayPeriodUnitDropdown className="select-no-left-border" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    </Modal>
  );
};

export default ContServePayModal;
