import { NavigateFunction } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { AuditOutlined, FileOutlined } from '@ant-design/icons';

import { IContractData } from 'features/Contract/interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';
import { getTableIndex } from 'utils/table';

type ColumnsProps = {
  navigator: NavigateFunction;
  page: number;
  pageSize: number;
  getEditPath: Function;
  onOpenHistoryModal: Function;
  pathname: string;
};

export const columns = ({
  navigator,
  page,
  pageSize,
  getEditPath,
  onOpenHistoryModal,
  pathname,
}: ColumnsProps) => {
  const columns: ColumnsType<IContractData> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      render: (_, __, index) => <>{getTableIndex(page, pageSize, index)}</>,
    },
    {
      title: 'วันที่จัดทำสัญญา',
      dataIndex: 'contractDate',
      key: 'contractDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : ''),
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      key: 'contractNo',
      align: 'center',
    },
    {
      title: 'เลขที่/วันที่หนังสือนำส่งสัญญา',
      dataIndex: 'formDate',
      key: 'formDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : ''),
    },
    {
      title: 'ทำสัญญาร่วมกับบริษัท',
      dataIndex: 'renter',
      key: 'operName',
      align: 'center',
      render: (value) => value?.operName,
    },
    {
      title: 'รูปแบบสัญญา',
      dataIndex: 'contractGroupName',
      key: 'contractGroupName',
      align: 'center',
    },
    {
      title: 'สถานะของสัญญา',
      dataIndex: 'contStatusName',
      key: 'contStatusName',
      align: 'center',
    },
    {
      title: 'สถานะการจัดทำสัญญา',
      dataIndex: 'formStatusName',
      key: 'formStatusName',
      align: 'center',
    },
    {
      title: 'จำนวนครั้งที่แก้ไข',
      dataIndex: 'numOfImprove',
      key: 'numOfImprove',
      align: 'center',
    },
    {
      title: 'ประวัติรายการ',
      dataIndex: 'history',
      key: 'history',
      align: 'center',
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }} onClick={() => onOpenHistoryModal(record)}>
          <FileOutlined className="file-outlined-css" />
        </div>
      ),
    },
    {
      title: 'แก้ไข/รายละเอียด',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div
          onClick={() => {
            navigator(getEditPath(record.conFormId), { state: { from: pathname } });
          }}
        >
          <AuditOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
        </div>
      ),
    },
  ];
  return columns;
};
