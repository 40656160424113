/* eslint-disable */

import { Disagreement } from 'api/disagreement/interface';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { Checkbox, Col, Form, Row, Select } from 'antd';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import TextArea from 'antd/es/input/TextArea';
import { useApi } from 'hooks/useApi';
import { updateDisagreementFormEight, UpdateDisagreementState } from 'api/disagreement';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import FormFilesUpload from './components/Form-Files-Upload';
import { convertDataFormDatePickerToString, convertDateFromApiToDatePickerWithTimeZoneAsian } from 'utils/date';


const Form8 = ({ disagreement, stepState, isCompleted, completeDate, onActionSuccess }: {
  completeDate?: string,
  disagreement?: Disagreement,
  isCompleted: boolean,
  stepState: number,
  onActionSuccess: () => void
}): JSX.Element => {
  const [form] = useForm();
  const { makeRequest: UpdateDisagreementFormEight } = useApi();
  const { makeRequest: UpdateDisagreementStateRequest } = useApi();

  const [disable, setDisable] = useState<boolean>(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckBox] = useState(false);

  const onFormValueChange = () => {
    if (typeof (form.getFieldValue('negotiated')) !== 'boolean') {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const formEight = form.getFieldsValue();
    if (disagreement) {
      await UpdateDisagreementFormEight(async () => {
        return await updateDisagreementFormEight({
          disagreementId: disagreement.formId,
          negotiated: formEight.negotiated,
          negotiatedDate: convertDataFormDatePickerToString(formEight.negotiatedDate),
          negotiatedRemark: formEight.negotiatedRemark,
          completeDate: convertDataFormDatePickerToString(formEight.completeDate),
        }).catch(() => {
          setLoading(true);
        });
      });
      if (checkbox && !isCompleted) {
        await UpdateDisagreementStateRequest(async () => {
          return await UpdateDisagreementState(disagreement.formId, formEight.negotiated ? 'Y' : 'N').catch(() => {
            setLoading(false);
          });
        });
      }
      setLoading(false);
      onActionSuccess();
    }

  };

  const onCheckBoxChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };
  useEffect(() => {

    if (disagreement) {
      setDisableSubmit(disagreement.negotiated === null || disagreement.negotiated === undefined);
      form.setFields([
        {
          name: 'negotiated',
          value: disagreement.negotiated,
        },
        {
          name: 'negotiatedDate',
          value: disagreement.negotiatedDate ? convertDateFromApiToDatePickerWithTimeZoneAsian(disagreement.negotiatedDate) : new Date(),
        },
        {
          name: 'negotiatedRemark',
          value: disagreement.negotiatedRemark,
        },
      ]);
      const eightState = disagreement.disAgreementStates.find(i => i.stateCode === 8);
      if (eightState?.isCompleteState) {
        setDisable(true);
      }
      onFormValueChange();
    }


    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: new Date(),
        },
      ]);
    }
  }, [disagreement, completeDate]);
  return <>
    <Form form={form} onValuesChange={onFormValueChange} style={{
      marginBottom: 24,
      marginTop: 24,
    }}>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item label='การตกลงของคู่กรณี' name='negotiated' labelCol={{ span: 12 }} wrapperCol={{ span: 14 }}>
            <Select
              disabled={disable}
              style={{ width: '100%' }}
              placeholder='---กรุณาระบุ---'
              options={[
                { value: true, label: 'ตกลงยุติข้อพิพาท' },
                { value: false, label: 'ไม่สามารถตกลงยุติข้อพิพาท' },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item labelAlign={'left'} label='วันที่พิจารณา' name='negotiatedDate' labelCol={{ span: 8 }}
                     wrapperCol={{ span: 14 }}>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <div style={
            {
              textAlign: 'center',
              marginBottom: '16px',
            }
          }>
            รายละเอียดบันทึกความตกลง (ในกรณีตกลง) / สรุปข้อเท็จจริง และพยานหลักฐาน (กรณีไม่ตกลง)
          </div>
          <Form.Item labelAlign={'left'} name='negotiatedRemark'>
            <TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
    <div>
      <FormFilesUpload stepState={stepState} disagreementFormId={disagreement?.formId || 0} state={8} />
    </div>
    <Form form={form} onValuesChange={onFormValueChange} style={{
      marginTop: 24,
    }}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Checkbox disabled={isCompleted} checked={checkbox} onChange={onCheckBoxChange}>ยืนยันการพิจารณา</Checkbox>
        </Col>
        <Col span={24}>
          <Form.Item labelCol={{ span: 4 }} wrapperCol={{
            span: 6,
          }
          } labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Col>
      </Row>
      <div style={
        {
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }
      }>
        <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onSubmit}>
          บันทึกรายการ
        </ButtonComponent>
      </div>
    </Form>
  </>;
};

export default Form8;
