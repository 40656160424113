import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider, Table } from 'antd';

import { downloadContractHistoryFile } from 'api/contract/history';
import { downloadAttachmentFile } from 'api/contract';
import { downloadStateFile } from 'api/contract/state';

import useFileManagement from 'features/Contract/hooks/useFileManagement';

import { dayJsformatDateToBCYear } from 'utils/date';
import { getFileTableColumn } from './columns';
import css from './Style.module.css';

type ContractHistoryTableProps = {
  contractHistoryData: any;
  isLoadingContractHistory: boolean;
  getHistoryPath: (id: any) => string;
};

const ContractHistoryTable = ({
  contractHistoryData,
  isLoadingContractHistory,
  getHistoryPath,
}: ContractHistoryTableProps) => {
  const { downloadFile } = useFileManagement();
  const { pathname } = useLocation();

  const isCurrentContract = (index: number) => index === 0;

  const onDownloadContractFile = useCallback(
    (record: any, isCurrentContract: boolean) => {
      if (isCurrentContract) {
        downloadFile({
          fileId: record.attachFileId,
          fileName: record.attachFileName,
          api: downloadAttachmentFile,
        });
      } else {
        downloadFile({
          fileId: record.attachFileId,
          fileName: record.attachFileName,
          api: () =>
            downloadContractHistoryFile({
              historyConAttachId: record.attachFileId,
              historyConStateAttachId: null,
            }),
        });
      }
    },
    [downloadFile]
  );

  const onDownloadStateFile = useCallback(
    (record: any, isCurrentContract: boolean) => {
      if (isCurrentContract) {
        downloadFile({
          fileId: record.attachFileId,
          fileName: record.attachFileName,
          api: downloadStateFile,
        });
      } else {
        downloadFile({
          fileId: record.attachFileId,
          fileName: record.attachFileName,
          api: () =>
            downloadContractHistoryFile({
              historyConAttachId: null,
              historyConStateAttachId: record.attachFileId,
            }),
        });
      }
    },
    [downloadFile]
  );

  const getHeaderText = (item: any) => {
    const date = item?.contractDate ? dayJsformatDateToBCYear(item.contractDate) : '-';
    return `สัญญาเลขที่ ${item?.contractNo} (${date})`;
  };

  const getLinkItem = (item: any, index: number) => {
    if (index === 0)
      return (
        <p>
          {`${index + 1}. `}
          {getHeaderText(item)}
        </p>
      );
    else {
      return (
        <Link to={getHistoryPath(item.conFormId)} state={{ from: pathname }}>
          {`${index + 1}. `}
          {getHeaderText(item)}
        </Link>
      );
    }
  };

  if (isLoadingContractHistory) return <div>กำลังโหลดข้อมูล...</div>;
  if (!contractHistoryData) return <div>มีข้อผิดพลาดเกิดขึ้น, กรุณาลองใหม่อีกครั้ง</div>;
  if (contractHistoryData.length === 0) return <div>ไม่พบข้อมูล</div>;
  return (
    <div>
      {contractHistoryData.map((item: any, index: any) => {
        return (
          <div key={item.conFormId} style={{ marginBottom: 25 }}>
            <div>{getLinkItem(item, index)}</div>
            <div style={{ marginTop: 10 }}>
              <p className={css.tableHeader}>ไฟล์สัญญา</p>
              <Table
                bordered
                size="small"
                rowKey={(record) => record.attachFileId}
                loading={isLoadingContractHistory}
                columns={getFileTableColumn({
                  onDownloadRecordFile: onDownloadContractFile,
                  isCurrentContract: isCurrentContract(index),
                })}
                dataSource={item.contAttachFiles}
                pagination={false}
              />
            </div>
            <div style={{ marginTop: 15 }}>
              <p className={css.tableHeader}>ไฟล์สถานะของสัญญา</p>
              <Table
                bordered
                size="small"
                rowKey={(record) => record.attachFileId}
                loading={isLoadingContractHistory}
                columns={getFileTableColumn({
                  onDownloadRecordFile: onDownloadStateFile,
                  isCurrentContract: isCurrentContract(index),
                })}
                dataSource={item.stateAttachFiles}
                pagination={false}
              />
            </div>
            <Divider />
          </div>
        );
      })}
    </div>
  );
};

export default ContractHistoryTable;
