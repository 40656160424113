import { ReactNode } from 'react';

interface HeaderTitleProp extends React.ComponentProps<'div'> {
  children: ReactNode;
  className?: string;
}

const HeaderTitle = ({ children, className, ...rest }: HeaderTitleProp) => {
  return (
    <div className={`headerTitle-layout ${className}`} {...rest}>
      <div>{children}</div>
    </div>
  );
};

export default HeaderTitle;
