import { useCallback, useEffect, useState } from 'react';
import Search from './components/Search/Search';
import { useOutletContext } from 'react-router-dom';
import { Form, message } from 'antd';
import SearchResults from './components/SearchResults/SearchResults';
import useAuth from 'hooks/useAuth';

import ModalAssignment from './components/Modal/ModalAssignment/ModalAssignment';
import { TApiResponse, useApi } from 'hooks/useApi';
// import { listReviewOffer } from 'api/review';
import { getOperatorAll } from 'api/operator';
// import { listOfferFormStates, listOfficerOffer, listReviewOfferOfficer } from 'api/officerCT';
import {
  listAssignTasks,
  listAssignTasksOfferFormStates,
  getListOfferAssignees,
  updateListOfferAssignees,
  improveAndAssignOffer,
} from 'api/assignTasks';
import ModalHistory from 'components/ModalHistory/ModalHistory';
import { listAttachHistory } from 'api/attachHistory';
import useDialogAlert from 'hooks/useDialogAlert';
import ModalOffer from './components/Modal/ModalOffer/ModalOffer';
import { getOperators } from 'api/master';
import { createOfferofficerCT } from 'api/officerCT';

type Props = {};

const ListAssignment = (props: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [formOffer] = Form.useForm();
  const [formOffer_] = Form.useForm();

  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { user } = useAuth();
  const [isImproveAndAssign, setIsAssignAndImprove] = useState<boolean>(false);
  const [modalOpenOffer, setModalOpenOffer] = useState<boolean>(false);
  const [modalOpenOffer_, setModalOpenOffer_] = useState<boolean>(false);
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  // let navigator = useNavigate();
  const [modalOpenHistory, setModalOpenHistory] = useState<boolean>(false);
  const { makeRequest: createOfferofficerCTequest }: TApiResponse = useApi();
  const { error: errorAlert, success } = useDialogAlert();
  const [filterOffer, setFilterOffer] = useState<{
    offerFormNo: string | null;
    offerFormStateCode: string | null;
    offerFormTypeCode: string | null;
    taxId: string | null;
    respUserId: string | null;
  }>({
    offerFormNo: null,
    offerFormStateCode: null,
    offerFormTypeCode: null,
    taxId: null,
    respUserId: null,
  });
  const {
    makeRequest: getOperatorListRequest,
    data: operator,
  }: // loading: operatorLoading,
  TApiResponse = useApi();
  const { makeRequest: updateResponseUserRequest }: TApiResponse = useApi();
  const {
    makeRequest: getListOfferFormStatesRequest,
    data: offerFormStates,
  }: // loading: OfferFormStatesLoading,
  TApiResponse = useApi();
  const {
    makeRequest: getListAssigneesRequest,
    data: assigneesList,
  }: // loading: assigneesListLoading,
  TApiResponse = useApi();
  const { makeRequest: getListAttachHistoryRequest, data: attachHistory }: TApiResponse = useApi();
  // const {
  //   makeRequest: updateAssigneesRequest,
  //   data: updateAssigneesData,
  //   loading: updateAssigneesLoading,
  //   status: status,
  //   statusText: statusText,
  // }: TApiResponse = useApi();
  const [operatorList, setOperatorList] = useState<
    {
      value: any;
      label: any;
    }[]
  >();
  const [offerFormStatesList, setOfferFormStatesList] = useState<
    {
      value: any;
      label: any;
    }[]
  >();
  const [listAssignees, setListAssignees] = useState<
    {
      value: any;
      label: any;
    }[]
  >();
  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const { error } = useDialogAlert();
  const [dataEdit, setDataEdit] = useState<any>();
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    setHeaderTitle('การมอบหมายงาน');
    // eslint-disable-next-line
  }, []);

  // const getReviewOfferList = useCallback(() => {
  //   makeRequest(() => listOfficerOffer(pageSize, currentPageNumber));
  // }, [makeRequest, pageSize, currentPageNumber]);

  const getReviewOfferList = useCallback(() => {
    makeRequest(() => listAssignTasks(pageSize, currentPageNumber, filterOffer));
  }, [makeRequest, pageSize, currentPageNumber, filterOffer]);
  const getOperatorList = useCallback(() => {
    getOperatorListRequest(() => getOperatorAll());
  }, [getOperatorListRequest]);
  const getListOfferFormStates = useCallback(() => {
    getListOfferFormStatesRequest(() => listAssignTasksOfferFormStates());
  }, [getListOfferFormStatesRequest]);
  const getListAssignees = useCallback(() => {
    getListAssigneesRequest(() => getListOfferAssignees());
  }, [getListAssigneesRequest]);
  const getListAttachHistory = useCallback(
    (id: number | string) => {
      getListAttachHistoryRequest(() => listAttachHistory(id));
    },
    [getListAttachHistoryRequest]
  );

  // const updateAssignees = useCallback(
  //   async (id: number | string, data: { officerIds: number[] }) => {
  //     await updateAssigneesRequest(() => updateListOfferAssignees(id, data));
  //   },
  //   [updateAssigneesRequest]
  // );

  useEffect(() => {
    if (user) {
      getReviewOfferList();
    }
    getOperatorList();
    getListOfferFormStates();
    getListAssignees();
  }, [getReviewOfferList, getOperatorList, getListOfferFormStates, getListAssignees, user]);
  useEffect(() => {
    if (offerFormStates) {
      let arrayOfferFormStates = [];

      for (let i = 0; i < offerFormStates.length; i++) {
        const element = offerFormStates[i];
        arrayOfferFormStates.push({
          value: element.code,
          label: element.name,
        });
      }
      setOfferFormStatesList(arrayOfferFormStates);
    }
    if (operator) {
      let arrayOperator = [];
      for (let i = 0; i < operator.length; i++) {
        const element = operator[i];
        arrayOperator.push({
          value: element.taxId,
          label: element.operName,
        });
      }

      setOperatorList(arrayOperator);
    }
    if (operator) {
      let arrayOperator = [];
      for (let i = 0; i < operator.length; i++) {
        const element = operator[i];
        arrayOperator.push({
          value: element.taxId,
          label: element.operName,
        });
      }

      setOperatorList(arrayOperator);
    }
    if (assigneesList) {
      let arrayAssignees = [];
      for (let i = 0; i < assigneesList.length; i++) {
        const element = assigneesList[i];
        arrayAssignees.push({
          value: element.id,
          label: element.name,
        });
      }

      setListAssignees(arrayAssignees);
    }
  }, [operator, offerFormStates, assigneesList]);
  useEffect(() => {
    if (attachHistory) {
      if (attachHistory.length === 0) {
        error({ header: 'ไม่มีประวัติไฟล์ข้อเสนอ', sub: '' });
      } else {
        setModalOpenHistory(true);
      }
    }
  }, [attachHistory, error]);
  // useEffect(() => {
  //   if (status === 200) {
  //     getReviewOfferList();
  //   }
  // }, [status, getReviewOfferList, statusText]);

  const onFinishSearch = (values: any) => {
    setFilterOffer({
      offerFormNo: values.clientNo === undefined ? null : values.clientNo,
      offerFormStateCode: values.offerStatus === undefined ? null : values.offerStatus,
      offerFormTypeCode: values.offerType === undefined ? null : values.offerType,
      taxId: values.client === undefined ? null : values.client,
      respUserId: values.respUserId === undefined ? null : values.respUserId,
    });
  };
  const onModalCancel = () => {
    setModalOpenOffer(false);
    setDataEdit(undefined);
    formOffer.resetFields();
  };
  const onFinishFailedSearch = (errorInfo: any) => {
    formOffer.resetFields();
  };
  const onFinishOfferContent = async (values: any) => {
    if (dataEdit) {
      let res: any = {};
      if (isImproveAndAssign) {
        const data = {
          offerFormId: dataEdit.offerFormId,
          officerIds: values.officerIds,
        };
        res = await updateResponseUserRequest(() => improveAndAssignOffer(data));
      } else {
        res = await updateResponseUserRequest(() =>
          updateListOfferAssignees(dataEdit.offerFormId, values)
        );
      }
      if (res.status === 200) {
        messageApi.open({
          type: 'success',
          content: 'บันทึกสำเร็จ',
        });
        getReviewOfferList();
        onModalCancel();
      }
    }
    // navigator(`/search-${values.offerType}/${values.operators}/new`, { replace: true });
  };

  const onFinishFailedOfferContent = (errorInfo: any) => {
    formOffer.resetFields();
  };

  const onModalOpenOffer = (val: any, isImproveAndAssign?: boolean) => {
    const _offerResponsibilityUser = val.offerResponsibilityUser.split(',');
    let array = [];
    for (let i = 0; i < _offerResponsibilityUser.length; i++) {
      const element = _offerResponsibilityUser[i];
      const filter = listAssignees?.filter((val) => val.label === element);
      if (filter && filter.length !== 0) {
        array.push(filter[0].value);
      }
    }
    formOffer.setFieldsValue({ officerIds: [...array] });
    setDataEdit(val);
    setModalOpenOffer(true);
    setIsAssignAndImprove(isImproveAndAssign ? true : false);
  };
  const onModalOpenHistory = (id: number | string) => {
    getListAttachHistory(id);
    // setModalOpenHistory(true);
  };
  const onModalHistoryCancel = () => {
    setModalOpenHistory(false);
  };
  const onModalOpenOffer_ = () => {
    setModalOpenOffer_(true);
  };

  const onModalCancel__ = () => {
    formOffer_.resetFields();
    setModalOpenOffer_(false);
  };
  const onFinishFailedOfferContent_ = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const onFinishOfferContent_ = async (values: any) => {
    if (operator) {
      const { data } = await getOperators(values.operators);

      const obj = {
        operators: { ...data.data },
        offerFormType: values.offerType,
        officerIds: [...values.officerIds],
      };
      await onCreateOfficer(obj);
      onModalCancel__();
    }
  };
  const onCreateOfficer = useCallback(
    async (data: any) => {
      try {
        await createOfferofficerCTequest(() => createOfferofficerCT(data));
        getReviewOfferList();
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
      } catch (errorInfo) {
        errorAlert({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [errorAlert, success, createOfferofficerCTequest, getReviewOfferList]
  );
  return (
    <div className="tabs-scroll">
      {contextHolder}
      <Search
        form={form}
        onFinishSearch={onFinishSearch}
        onFinishFailedSearch={onFinishFailedSearch}
        operatorList={operatorList}
        offerFormStatesList={offerFormStatesList}
        listAssignees={listAssignees}
      />
      <SearchResults
        onModalOpenOffer={onModalOpenOffer}
        onModalOpenOffer_={onModalOpenOffer_}
        data={data}
        loading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        pageable={pageable}
        onModalOpenHistory={onModalOpenHistory}
      />
      <ModalAssignment
        modalOpenOffer={modalOpenOffer}
        onModalCancel={onModalCancel}
        title="ข้อมูลผู้ได้รับมอบหมาย"
        width={600}
        form={formOffer}
        onFinishFailedOfferContent={onFinishFailedOfferContent}
        onFinishOfferContent={onFinishOfferContent}
        operatorList={operatorList}
        listAssignees={listAssignees}
      />
      <ModalOffer
        modalOpenOffer={modalOpenOffer_}
        onModalCancel={onModalCancel__}
        title="เลือกประเภทข้อเสนอ"
        width={1000}
        form={formOffer_}
        onFinishFailedOfferContent={onFinishFailedOfferContent_}
        onFinishOfferContent={onFinishOfferContent_}
        operatorList={operatorList}
        listAssignees={listAssignees}
      />
      <ModalHistory
        modalOpenOffer={modalOpenHistory}
        onModalCancel={onModalHistoryCancel}
        title="ไฟล์จัดทำข้อเสนอ"
        width={700}
        attachHistory={attachHistory}
      />
    </div>
  );
};

export default ListAssignment;
