import { useLocation, useNavigate } from 'react-router-dom';
import { Table } from 'antd';

import { tableColumns } from './tableColumns';

export interface DataType {
  offerFormId: number;
  offerFormNo: string;
  offerFormDate: string | null;
  offerFormGroup: string;
  offerFormPublicUrl: string;
  offerFormStatusName: string;
  offerFormGroupName: string;
  offerFormStatus: string;
  offerFormType: string;
  offerFormTypeName: string;
  numOfEdit: number;
}

type OfferListTableProps = {
  data: DataType[];
  loading: boolean;
  pageSize: number;
  currentPageNumber: number;
  paginationSetting: any;
  onModalOpenHistory: Function;
};

const OfferListTable = ({
  data,
  loading,
  pageSize,
  currentPageNumber,
  paginationSetting,
  onModalOpenHistory,
}: OfferListTableProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <div className="content-box tabs-scroll">
      <Table
        columns={tableColumns({
          pathname,
          navigate,
          page: currentPageNumber,
          pageSize,
          onModalOpenHistory,
        })}
        dataSource={data}
        loading={loading}
        rowKey="offerFormId"
        size="middle"
        pagination={paginationSetting}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default OfferListTable;
