import { Select } from 'antd';
import {
  PAY_PERIOD_UNIT_MONTH,
  PAY_PERIOD_UNIT_USAGE,
  PAY_PERIOD_UNIT_YEAR,
} from 'constants/payPeriodUnitName';
import { onFilterOption } from 'utils/select';

const PayPeriodUnitDropdown = (prop: any) => {
  return (
    <Select
      showSearch
      filterOption={onFilterOption}
      placeholder="--กรุณาเลือกหน่วยระยะเวลา--"
      options={[
        {
          value: 1,
          label: PAY_PERIOD_UNIT_MONTH,
        },
        {
          value: 2,
          label: PAY_PERIOD_UNIT_YEAR,
        },
        {
          value: 3,
          label: PAY_PERIOD_UNIT_USAGE,
        },
      ]}
      {...prop}
    />
  );
};

export default PayPeriodUnitDropdown;
