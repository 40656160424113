import { Col, Form, Input, Row } from 'antd';

const AddressInfoForm = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Form.Item
          colon
          label="เลขที่"
          name="conNo"
          rules={[{ required: true, message: 'กรุณากรอกเลขที่' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="หมู่"
          name="conMoo"
          rules={[{ required: true, message: 'กรุณากรอกหมู่' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="ซอย"
          name="conSoi"
          rules={[{ required: true, message: 'กรุณากรอกซอย' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          colon
          label="ถนน"
          name="conRoad"
          rules={[{ required: true, message: 'กรุณากรอกถนน' }]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AddressInfoForm;
