import { useEffect, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Input, Row, Tag } from 'antd';

import PrimaryButton from 'components/PrimaryButton';
import ContractOfferServesDropdown from 'features/Contract/components/ContractOfferServesDropdown';
import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';

import { OTHER_OPTION } from 'features/Offer/constant';

type ServiceFormProps = {
  selectedOfferFormId: number | string | undefined;
  isDisabledServiceForm: boolean;
};

const ServiceForm = ({ selectedOfferFormId, isDisabledServiceForm }: ServiceFormProps) => {
  const {
    selectedContServe,
    selectedServiceOption,
    onConfirmChangeOfferServe,
    onAddNewContServePay,
  } = useContractServicesContext();
  const [contServeOtherInputValue, setContServeOtherInputValue] = useState<string | undefined>(
    undefined
  );

  const isDisabledOtherInput = useMemo(() => {
    if (selectedServiceOption?.label === OTHER_OPTION) {
      if (isDisabledServiceForm) return false;
      return !selectedServiceOption || !contServeOtherInputValue;
    } else return !selectedServiceOption;
  }, [selectedServiceOption, contServeOtherInputValue, isDisabledServiceForm]);

  useEffect(() => {
    if (selectedContServe) setContServeOtherInputValue(selectedContServe.serviceDesc);
  }, [selectedContServe]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <ContractOfferServesDropdown
          offerId={selectedOfferFormId}
          onChangeOption={onConfirmChangeOfferServe}
        />
      </Col>
      {selectedServiceOption?.label === OTHER_OPTION && (
        <Col span={24}>
          <Form.Item
            label="บริการ กรณีอื่นๆระบุ"
            name="serviceDesc"
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'กรุณาระบุอื่นๆ' }]}
          >
            <Input
              onChange={(value: any) => {
                setContServeOtherInputValue(value?.target?.value);
              }}
            />
          </Form.Item>
        </Col>
      )}
      {isDisabledServiceForm && (
        <Col span={19} offset={5}>
          <Tag color="gold">
            {`*กรุณาลบอัตราค่าตอบแทน หรือ บริการในสิ่งอำนวยความสะดวกก่อนแก้ไข`}
          </Tag>
        </Col>
      )}
      <Divider style={{ margin: '5px 0' }} />
      <Col span={24}>
        <PrimaryButton
          disabled={isDisabledOtherInput}
          size="middle"
          icon={<PlusOutlined />}
          onClick={onAddNewContServePay}
        >
          เพิ่มอัตราค่าตอบแทน
        </PrimaryButton>
      </Col>
    </Row>
  );
};

export default ServiceForm;
