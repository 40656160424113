import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { useApi, TApiResponse } from 'hooks/useApi';
import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';
import { listContractReviewState } from 'api/contract';
import { updateContractState } from 'api/contract/state';
import { FormState } from 'types/state';

import EditContract from './EditContract';
import { Spin } from 'antd';
// import { StepsData } from './mockData';

const EditReviewContract = () => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { conFormId } = useParams<{ conFormId: string }>();
  const { error: errorModal } = useDialogAlert();
  const { isModalOpen, showModal, handleOk, handleCancel } = useModal();
  const {
    makeRequest: _getContractReviewState,
    data: contractStateData,
    loading: isLoadingContractStateData,
  }: TApiResponse = useApi();

  const {
    makeRequest: _updateContractReviewState,
    data: updatedContractStateData,
    loading: isUpdatingState,
  }: TApiResponse = useApi();

  const [stepsData, setStepsData] = useState<any>([]);
  const [stepModalIndex, setStepModalIndex] = useState<number | undefined>(undefined);
  const [currentStepData, setCurrentStepData] = useState(undefined);

  const onOpenModalForm = useCallback(
    (stepNumber: number) => {
      setStepModalIndex(stepNumber);
      setCurrentStepData(stepsData[stepNumber]);
      showModal();
    },
    [showModal, stepsData]
  );

  const onUpdateOfferState = useCallback(
    async (data: FormState) => {
      if (conFormId) {
        await _updateContractReviewState(() => updateContractState(conFormId, data));
        handleOk();
      } else {
        errorModal({
          header: 'เกิดข้อผิดพลาดขึ้น: ไม่พบ OfferId',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [handleOk, conFormId, _updateContractReviewState, errorModal]
  );

  useEffect(() => {
    if (conFormId) {
      _getContractReviewState(() => listContractReviewState(conFormId));
    }
  }, [conFormId, _getContractReviewState]);

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle]);

  useEffect(() => {
    setStepsData(contractStateData);
    // setStepsData(StepsData);
  }, [contractStateData]);

  useEffect(() => {
    if (updatedContractStateData) {
      setStepsData(updatedContractStateData);
    }
  }, [updatedContractStateData]);

  return (
    <div>
      <Spin spinning={isLoadingContractStateData || isUpdatingState}>
        {/* <HeaderTitle>รายละเอียดสัญญา</HeaderTitle> */}
        <EditContract
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          stepModalIndex={stepModalIndex}
          currentStepData={currentStepData}
          onUpdateOfferState={onUpdateOfferState}
          stepsData={stepsData}
          onOpenModalForm={onOpenModalForm}
        />
      </Spin>
    </div>
  );
};

export default EditReviewContract;
