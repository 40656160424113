import { Col, Form, FormInstance, Input, Row, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractReviewAction } from 'api/contract/state';

import { ThaiDatepickerComponent } from 'components';

import { formLayout } from 'constants/form';
import { useEffect } from 'react';
import { IStateData } from 'features/Contract/interfaces';
import { getJSDateObj } from 'utils/date';

const REVIEW_ACTION_STATE = 10;

type StateInputFormProps = {
  form: FormInstance<any>;
  onFinish: ((values: any) => void) | undefined;
  isHideApproveForm?: boolean;
  isDisable: boolean;
  isShowActionStateOption?: boolean;
  stepData: IStateData | undefined;
  setSelectedActionCode?: React.Dispatch<React.SetStateAction<string>>;
  dateLabel?: string;
};

const StateInputForm = ({
  form,
  onFinish,
  isDisable,
  stepData,
  setSelectedActionCode,
  dateLabel,
  isHideApproveForm = false,
  isShowActionStateOption = false,
}: StateInputFormProps) => {
  const { makeRequest, data: actionOptionsList, loading }: TApiResponse = useApi();

  useEffect(() => {
    if (stepData) {
      let _stepData: any = { ...stepData };
      _stepData.actionDate = stepData.actionDate ? getJSDateObj(stepData.actionDate) : null;
      _stepData.approvedDate = stepData.approvedDate ? getJSDateObj(stepData.approvedDate) : null;
      form.setFieldsValue({
        ..._stepData,
      });
    }
  }, [form, stepData]);

  useEffect(() => {
    makeRequest(() => listContractReviewAction(REVIEW_ACTION_STATE));
  }, [makeRequest]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      form={form}
      name="stepModalForm"
      onFinish={onFinish}
      size="large"
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะสัญญา" name="stateName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label={dateLabel ? dateLabel : 'วันที่บันทึก'}
            name="actionDate"
            rules={[{ required: true, message: `กรุณาเลือก${dateLabel ?? 'วันที่บันทึก'}` }]}
          >
            <ThaiDatepickerComponent disabled={isDisable} />
          </Form.Item>
        </Col>
        {isShowActionStateOption && (
          <Col span={24}>
            <Form.Item
              colon
              label="สถานะสัญญา"
              name="actionCode"
              rules={[{ required: true, message: 'กรุณาเลือกสถานะสัญญา' }]}
            >
              <Select
                loading={loading}
                placeholder="-- กรุณาระบุ --"
                disabled={isDisable}
                onChange={(value: any) => {
                  if (setSelectedActionCode) {
                    setSelectedActionCode(value);
                  }
                }}
                options={
                  actionOptionsList
                    ? actionOptionsList.map((item: { actionCode: string; actionName: string }) => ({
                        value: item.actionCode,
                        label: item.actionName,
                      }))
                    : []
                }
              />
            </Form.Item>
          </Col>
        )}
        {!isHideApproveForm && (
          <>
            <Col span={12}>
              <Form.Item
                colon
                label="มติครั้งที่"
                name="approvedNo"
                rules={[
                  { required: true, message: 'กรุณาใส่มติครั้งที่' },
                  { pattern: /^[0-9]+$/, message: 'กรุณากรอกตัวเลขเท่านั้น' },
                ]}
              >
                <Input disabled={isDisable} placeholder="กรอกตัวเลข" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                colon
                label="วันที่ลงมติ"
                name="approvedDate"
                rules={[{ required: true, message: 'กรุณาเลือกวันที่ลงมติ' }]}
              >
                <ThaiDatepickerComponent disabled={isDisable} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default StateInputForm;
