/* eslint-disable */
import { updateDisagreementForm, UpdateDisagreementState } from 'api/disagreement';
import { Disagreement } from 'api/disagreement/interface';
import { Alert, Checkbox, Form, Modal } from 'antd';
import { Dispatch, useEffect, useState } from 'react';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { ButtonComponent, ThaiDatepickerComponent } from 'components';
import { useForm } from 'antd/es/form/Form';
import { useApi } from 'hooks/useApi';
import Form7 from './Form7';
import Form8 from './Form8';
import Form4 from './Form4';
import Form2 from './Form2';
import dayjs from 'dayjs';
import Form3 from './Form3';
import FormFilesUpload from './components/Form-Files-Upload';
import Form10 from 'features/Disagreement/DetailDisagreement/components/DisagreementSteps/components/Form10';
import Form11 from 'features/Disagreement/DetailDisagreement/components/DisagreementSteps/components/Form11';
import Form14 from 'features/Disagreement/DetailDisagreement/components/DisagreementSteps/components/Form14';
import Form16 from 'features/Disagreement/DetailDisagreement/components/DisagreementSteps/components/Form16';
import { differenceInCalendarDays } from 'date-fns';
import Form12 from 'features/Disagreement/DetailDisagreement/components/DisagreementSteps/components/Form12';
import Form1 from 'features/Disagreement/DetailDisagreement/components/DisagreementSteps/components/Form1';
import { convertDataFormDatePickerToString, convertDateFromApiToDatePickerWithTimeZoneAsian } from 'utils/date';

interface ModalFormProp {
  title: string;
  onUpdateStateCompleted: () => void;
  open: boolean;
  setOpen: Dispatch<boolean>;
  stepState: number;
  currentState: number;
  isCompleted: boolean;
  state: number;
  id: number;
  completeDate?: string;
  disagreement?: Disagreement;
}


const ModalForm = ({
                     title,
                     open,
                     setOpen,
                     stepState,
                     id,
                     state,
                     onUpdateStateCompleted,
                     currentState,
                     isCompleted,
                     completeDate,
                     disagreement,
                   }: ModalFormProp): JSX.Element => {
  const [checkbox, setCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const { makeRequest: UpdateDisagreementStateRequest } = useApi();
  const { makeRequest: UpdateDisagreementFormRequest } = useApi();
  const [disableSubmit] = useState(false);
  const [dateCount, setDateCount] = useState<number>();
  const [form] = useForm();

  useEffect(() => {
    if (disagreement) {
      const days = differenceInCalendarDays(dayjs(disagreement.receiveDiscussAccusedFormDate).toDate(), new Date());
      setDateCount(Math.abs(days));
    }
  }, [disagreement]);


  useEffect(() => {
    resetData();
    if (completeDate) {
      form.setFields([
        {
          name: 'completeDate',
          value: convertDateFromApiToDatePickerWithTimeZoneAsian(completeDate),
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'completeDate',
          value: dayjs(new Date()).toDate(),
        },
      ]);
    }
  }, [open, currentState, completeDate]);

  const resetData = () => {
    setCheckBox(false);
  };

  const onChange = (e: CheckboxChangeEvent) => {
    setCheckBox(e.target.checked);
  };

  const onOK = async () => {
    if (disagreement) {
      const formValue = form.getFieldsValue();

      setLoading(true);
      if ([2, 3, 4, 7, 8].every(i => state != i)) {
        await UpdateDisagreementFormRequest(async () => {
          return await updateDisagreementForm(id, state, convertDataFormDatePickerToString(formValue.completeDate));
        });
        if (checkbox) {
          await UpdateDisagreementStateRequest(async () => {
            return await UpdateDisagreementState(id);
          });
        }
      }
      resetData();
      setOpen(false);
      setLoading(false);
      onUpdateStateCompleted();
    }
  };

  const onActionComplete = () => {
    setOpen(false);
    onUpdateStateCompleted();
  };

  const onClose = () => {
    resetData();
    setOpen(false);
    setCheckBox(false);
  };


  return <>
    <Modal title={title} open={open} onOk={onOK} onCancel={onClose} width={1400}
           footer={[
             state !== 1 && state !== 4 && state !== 7 && state !== 8 && state !== 2 && state !== 3 && state !== 10 && state !== 11 && state !== 14 && state !== 16 && state !== 12 &&
             <ButtonComponent disabled={disableSubmit} key='submit' type='primary' loading={loading} onClick={onOK}>
               บันทึกรายการ
             </ButtonComponent>,

           ]}>
      {[13].some(i => i === state) && <Alert message={
        typeof dateCount === 'number' ? 'รวมจำนวนวันที่ดำเนินการ: ' + dateCount + ' วัน' : ''
      } style={{
        marginTop: '24px',
        marginBottom: '24px',
        textAlign: 'center',
      }} type='warning' />}

      {
        [5, 6, 9, 13, 15].some(i => i === state) &&
        <FormFilesUpload state={state} stepState={stepState} disagreementFormId={id} />
      }
      {
        state === 1 &&
        <Form1 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
               onActionSuccess={onActionComplete} stepState={stepState} />
      }
      {
        state === 2 &&
        <Form2 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
               onActionSuccess={onActionComplete} />
      }
      {
        state === 3 &&
        <Form3 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
               onActionSuccess={onActionComplete} />
      }
      {
        state === 4 &&
        <Form4 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
               onActionSuccess={onActionComplete} stepState={stepState} />
      }
      {
        state === 7 &&
        <Form7 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
               onActionSuccess={onActionComplete} stepState={stepState} />
      }
      {
        state === 8 &&
        <Form8 isCompleted={isCompleted} disagreement={disagreement} stepState={stepState} completeDate={completeDate}
               onActionSuccess={onActionComplete} />
      }
      {
        state === 10 &&
        <Form10 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
                onActionSuccess={onActionComplete} stepState={stepState} />
      }
      {
        state === 11 &&
        <Form11 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
                onActionSuccess={onActionComplete} stepState={stepState} />
      }

      {
        state === 12 &&
        <Form12 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
                onActionSuccess={onActionComplete} stepState={stepState} />
      }
      {
        state === 14 &&
        <Form14 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
                onActionSuccess={onActionComplete} stepState={stepState} />
      }

      {
        state === 16 &&
        <Form16 isCompleted={isCompleted} disagreement={disagreement} completeDate={completeDate}
                onActionSuccess={onActionComplete} stepState={stepState} />
      }

      {
        state !== 1 && state !== 4 && state !== 7 && state !== 8 && state !== 2 && state !== 3 && state !== 10 && state !== 11 && state !== 14 && state !== 16 && state !== 12 &&
        <Checkbox disabled={isCompleted} checked={checkbox} onChange={onChange}>ยืนยันการพิจารณา</Checkbox>
      }
      <br />
      <br />
      {
        state !== 1 && state !== 4 && state !== 7 && state !== 8 && state !== 2 && state !== 3 && state !== 10 && state !== 11 && state !== 14 && state !== 16 && state !== 12 &&
        <Form
          name='basic'
          form={form}
          initialValues={{ remember: true }}
          autoComplete='off'
          requiredMark={false}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 6 }}
          labelAlign={'left'}
        >
          <Form.Item labelAlign={'left'} label='วันที่พิจารณา' name='completeDate'>
            <ThaiDatepickerComponent
            />
          </Form.Item>
        </Form>
      }


    </Modal></>;
};
export default ModalForm;
