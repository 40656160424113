/* eslint-disable */
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useApi } from 'hooks/useApi';
import { useEffect, useState } from 'react';
import { getDisagreementAllStatusName, getDisagreementChart, getDisagreementTotal } from 'api/dashboard';

interface DataType {
  key: string;
  disAgreementNo: string;
  statusGroup: number;
  statusGroupName: string;
}

interface DisagereementTotalData {
  key: string;
  yearly: string;
  total: number;
}

const DisagereementTotalColumns: ColumnsType<DisagereementTotalData> = [
  {
    title: 'ปี',
    key: 'yearly',
    dataIndex: 'yearly',
  },
  {
    title: 'จำนวน',
    key: 'total',
    dataIndex: 'total',
    align: 'right',
  },
];

const DisagreementDashboard = (): JSX.Element => {
  const { makeRequest: GetDisagreementAllStatusName, loading } = useApi();
  const { makeRequest: GetDisagreementAll } = useApi();
  const { makeRequest: GetDisagreementTotal } = useApi();
  const [columns, setColumns] = useState<ColumnsType<DataType>>();
  const [chartData, setChartData] = useState<DataType[]>([]);
  const [disagreementTotal, setDisagreementTotal] = useState<DisagereementTotalData[]>([]);

  useEffect(() => {
    GetDisagreementAllStatusName(async () => {
      const allStatus = await getDisagreementAllStatusName();
      const fixedColumns: ColumnsType<DataType> = [
        {
          title: 'ข้อพิพาทที่',
          dataIndex: 'disAgreementNo',
          key: 'disAgreementNo',
        },
      ];
      allStatus.data.data.forEach(i => {
        fixedColumns.push({
          title: i.statusGroupName,
          render: (value, record) => {
            return <div style={{
              display: 'flex',
              justifyContent: 'center',
            }}>{(record.statusGroup === i.statusGroup) || (record.statusGroup > i.statusGroup) ?
              <div style={{
                backgroundColor: '#5bd552',
                borderRadius: '4px',
                width: '30px',
                height: '12px',
              }}></div> : ''}</div>;
          },
          align: 'center',
          dataIndex: 'statusGroup',
          key: 'statusGroup',
        });
      });
      setColumns(fixedColumns);

      await GetDisagreementAll(async () => {
        const res = await getDisagreementChart();
        const data: DataType[] = res.data.data.map((i, index) => {
          return {
            key: i.disAgreementNo + index,
            statusGroupName: i.statusGroupName,
            statusGroup: i.statusGroup,
            disAgreementNo: i.disAgreementNo,
          };
        });
        setChartData(data);
      });

      return allStatus;
    });
    GetDisagreementTotal(async () => {
      const res = await getDisagreementTotal();
      const data: DisagereementTotalData[] = [];
      res.data.data.forEach((i, index) => {
        data.push({
          key: i.yearly,
          total: i.total,
          yearly: i.yearly,
        });
      });
      setDisagreementTotal(data);
    });
  }, []);
  return <div>
    <h2>การพิจารณาข้อพิพาท</h2>
    <br />
    <Table loading={loading} columns={columns} pagination={false} dataSource={chartData} scroll={{ y: '80vh' }} />

    <Table style={{ marginTop: '34px' }} pagination={false} columns={DisagereementTotalColumns}
           dataSource={disagreementTotal}></Table>
  </div>;
};
export default DisagreementDashboard;
