import axiosInstance from 'utils/axios';
import { GetFileListResponse } from 'api/formDocument/interface';

export const uploadFile = (file: any, fileName: string, isAllowOperator: string): Promise<void> => {
  const formData = new FormData();
  formData.append('attachment', file);
  const fileNameBlob = new Blob([fileName], { type: 'text/plain; charset=utf-8' });
  formData.append('fileName', fileNameBlob);
  formData.append('isAllowOperator', isAllowOperator);
  return axiosInstance.post(`operators/sample-data-file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const editUploadFile = (id: number, file: any, fileName: string, isAllowOperator: string): Promise<void> => {
  const formData = new FormData();
  formData.append('attachment', file);
  const fileNameBlob = new Blob([fileName], { type: 'text/plain; charset=utf-8' });
  formData.append('fileName', fileNameBlob);
  formData.append('isAllowOperator', isAllowOperator);
  return axiosInstance.put(`operators/sample-data-file/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getFileList = (): Promise<GetFileListResponse> => {
  return axiosInstance.get('operators/sample-data-file/all');
};

export const downloadFileById = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`operators/sample-data-file/${id}/download`, {
    responseType: 'blob',
  });
};

export const deleteFile = (id: number): Promise<void> => {
  return axiosInstance.delete(`operators/sample-data-file/${id}`);
};
