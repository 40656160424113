import { Modal, Spin } from 'antd';

import useCancelContractModalContext from 'features/Contract/hooks/useCancelContractModalContext';

import { HeaderTitleComponent } from 'components';
import CancelContractForm from '../CancelContractForm';
import UploadFilesSection from '../UploadFilesSection';
import Footer from './Footer';

const CancelContractModal = () => {
  const { contractTypeName, formRef, loading, isModalOpen, handleCancel } =
    useCancelContractModalContext();

  return (
    <Modal
      title={`การยกเลิก${contractTypeName}`}
      open={isModalOpen}
      onCancel={() => handleCancel()}
      width="100vh"
      footer={
        <Footer onCancelContract={() => formRef.submit()} handleCancel={() => handleCancel()} />
      }
    >
      <Spin tip="กำลังดำเนินการ..." spinning={loading}>
        <HeaderTitleComponent
          className="no-right-radius no-left-radius"
          style={{ margin: '15px -24px' }}
        >
          รายละเอียดการยกเลิก{contractTypeName}
        </HeaderTitleComponent>
        <CancelContractForm />
        <HeaderTitleComponent
          className="no-right-radius no-left-radius"
          style={{ margin: '15px -24px' }}
        >
          รายละเอียดการแนบเอกสาร : สามารถแนบไฟล์ (pdf, word, excel) ครั้งละ 1 ไฟล์ ไฟล์ละไม่เกิน 20
          MB
        </HeaderTitleComponent>
        <UploadFilesSection />
      </Spin>
    </Modal>
  );
};

export default CancelContractModal;
