import { useCallback, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

type usePageHeaderTextProps = {
  offerType: string;
  isNewPage: boolean;
};

const usePageHeaderText = ({ offerType, isNewPage }: usePageHeaderTextProps) => {
  const { setHeaderTitle, setIsClearHeader }: headerTitleContext = useOutletContext();
  
  const getHeaderText = useCallback(() => {
    if (!offerType) return '';
    if (isNewPage) return `เพิ่มข้อเสนอ ${offerType}`;
    return `แก้ไขข้อเสนอ ${offerType}`;
  }, [isNewPage, offerType]);

  useEffect(() => {
    setIsClearHeader(false);
    setHeaderTitle(getHeaderText());
  }, [setHeaderTitle, setIsClearHeader, getHeaderText]);
};

export default usePageHeaderText;
