import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useDialogAlert from './useDialogAlert';
import { getErrorMessage } from 'utils/string';

export type TApiResponse<T = any> = {
  makeRequest: Function;
  status: Number | undefined;
  statusText: String;
  data: T;
  setData: React.Dispatch<any>;
  pageable: any;
  error: any;
  loading: boolean;
  setError: any;
};

type MakeRequestParams = {
  isThrowError?: boolean;
  dataTransformer?: Function;
  isSilentError?: boolean;
};

export const useApi = (): TApiResponse => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { error: errorModal } = useDialogAlert();
  const [status, setStatus] = useState<Number | undefined>(undefined);
  const [statusText, setStatusText] = useState<String>('');
  const [data, setData] = useState<any>();
  const [pageable, setPageable] = useState<any>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleUnauthorized401 = useCallback(() => {
    errorModal(
      {
        header: 'Session หมดอายุ',
        sub: 'กรุณาเข้าสู่ระบบใหม่อีกครั้ง',
      },
      () => {
        navigate('/logout', { state: { from: pathname } });
      }
    );
  }, [navigate, pathname, errorModal]);

  const handleForbidden403 = useCallback(() => {
    errorModal(
      {
        header: 'ไม่มีสิทธิ์เข้าถึง',
        sub: 'กรุณาติดต่อผู้ดูแลระบบ',
      },
      () => {
        navigate('/forbidden', { state: { from: pathname } });
      }
    );
  }, [navigate, pathname, errorModal]);

  const handleNotFound404 = useCallback(
    (error: any) => {
      const errMsg = getErrorMessage(error);
      errorModal({
        header: errMsg || 'ไม่พบข้อมูล',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    },
    [errorModal]
  );

  const handleUnknownError = useCallback(
    (error: any) => {
      const errMsg = getErrorMessage(error);
      errorModal({
        header: errMsg || 'เกิดข้อผิดพลาด',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    },
    [errorModal]
  );

  const makeRequest = useCallback(
    async (api: Function, params?: MakeRequestParams) => {
      setLoading(true);
      try {
        const apiResponse = await api();
        if (apiResponse) {
          setStatus(apiResponse.status);
          setStatusText(apiResponse.statusText);

          // NOTE: dataTransformer is a function that transform data from api response
          if (params?.dataTransformer) {
            setData(params?.dataTransformer(apiResponse.data?.data));
          } else {
            setData(apiResponse.data?.data ? apiResponse.data?.data : apiResponse.data);
          }

          setPageable(apiResponse.data?.pageable ? apiResponse.data?.pageable : undefined);
          setLoading(false);
        }
        return apiResponse;
      } catch (error: any) {
        console.error(error);
        setStatus(error?.status);
        setStatusText(getErrorMessage(error));
        setError(error);
        setLoading(false);

        if (error.status === 401) {
          return handleUnauthorized401();
        }
        if (error.status === 403) {
          return handleForbidden403();
        }

        if (params?.isSilentError) {
          return error;
        }

        // NOTE: If want to custom handle error, set isThrowError to true
        if (params?.isThrowError) {
          throw error;
        }
        if (error.status === 404) {
          handleNotFound404(error);
        }
        handleUnknownError(error);
        return error;
      }
    },
    [handleUnauthorized401, handleForbidden403, handleNotFound404, handleUnknownError]
  );

  return {
    status,
    statusText,
    data,
    error,
    loading,
    pageable,
    makeRequest,
    setData,
    setError,
  };
};
