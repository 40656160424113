import { Col, Form, Input, Row } from 'antd';

import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';
import useContServePayForm from 'features/Contract/hooks/useContServePayForm';

import PayPeriodUnitDropdown from 'components/PayPeriodUnitDropdown';
import CurrencyDropdown from 'features/Offer/components/CurrencyDropdown';
import AllUnitsDropdown from '../AllUnitsDropdown';

const ContServePayForm = () => {
  const { facilityModalFormRef, editingFacility, isDisabled } = useContractFacilitiesContext();
  const { isDisabledOtherUnitDesc, isDisabledOtherCurrencyDesc, onChangeCurrency, onChangeUnit } =
    useContServePayForm({ record: editingFacility, formRef: facilityModalFormRef });

  return (
    <>
      <Col span={24}>
        <Form.Item
          label="เงื่อนไขราคา"
          name="payCondition"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[{ required: true, message: 'กรุณากรอกเงื่อนไขราคา' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="อัตราค่าตอบแทน"
          name="payCompensation"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[{ required: true, message: 'กรุณากรอกอัตราค่าตอบแทน' }]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <CurrencyDropdown
          onChangeOption={onChangeCurrency}
          labelCol={{ span: 12 }}
          isRequired={true}
          disabled={isDisabled}
        />
      </Col>
      <Col span={12}>
        <Form.Item
          label="สกุลเงิน กรณีระบุอื่นๆ"
          name="currencyDesc"
          labelCol={{ span: 10 }}
          rules={
            isDisabledOtherCurrencyDesc
              ? undefined
              : [{ required: true, message: 'กรุณาระบุอื่นๆ' }]
          }
        >
          <Input disabled={isDisabledOtherCurrencyDesc || isDisabled} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <AllUnitsDropdown onChangeOption={onChangeUnit} isRequired={true} disabled={isDisabled} />
      </Col>
      <Col span={12}>
        <Form.Item
          label="หน่วย (Unit) กรณีระบุอื่นๆ"
          name="unitDesc"
          labelCol={{ span: 10 }}
          rules={
            isDisabledOtherUnitDesc ? undefined : [{ required: true, message: 'กรุณาระบุอื่นๆ' }]
          }
        >
          <Input disabled={isDisabledOtherUnitDesc || isDisabled} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Form.Item
              label="ระยะเวลา"
              labelCol={{ span: 12 }}
              name="payPeriod"
              rules={[
                { required: true, message: 'กรุณาระบุระยะเวลา' },
                { pattern: /^[0-9]+$/, message: 'กรุณากรอกตัวเลขเท่านั้น' },
              ]}
            >
              <Input className="no-right-radius" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="payPeriodUnit"
              className="no-left-radius"
              style={{ borderLeft: 'none' }}
              rules={[{ required: true, message: 'กรุณาเลือกหน่วยระยะเวลา' }]}
              initialValue={1}
              wrapperCol={{ span: 24 }}
            >
              <PayPeriodUnitDropdown className="select-no-left-border" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default ContServePayForm;
