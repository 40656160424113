import { Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';

type UploadButtonProps = {
  isDisabled: boolean;
  isOpenFileDialog: boolean;
  onUploadFile: Function;
  setCurrentUploadFile: (file: any) => void;
  onValidateDocType: () => void;
};

const UploadButton = ({
  isDisabled,
  isOpenFileDialog,
  onUploadFile,
  setCurrentUploadFile,
  onValidateDocType,
}: UploadButtonProps) => {
  const uploadProps: UploadProps = {
    name: 'file',
    openFileDialogOnClick: isOpenFileDialog,
    customRequest: () => onUploadFile(),
    maxCount: 1,
    beforeUpload: async (file: any) => {
      try {
        setCurrentUploadFile(file);
        return true;
      } catch (errorInfo) {
        return false;
      }
    },
    showUploadList: false,
    disabled: isDisabled,
  };

  return (
    <Upload {...uploadProps}>
      <PrimaryButton
        icon={<UploadOutlined />}
        disabled={isDisabled}
        onClick={() => {
          onValidateDocType();
        }}
      >
        อัพโหลดเอกสาร
      </PrimaryButton>
    </Upload>
  );
};

export default UploadButton;
