import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Form, Spin, Tabs, TabsProps } from 'antd';
import { Content } from 'antd/es/layout/layout';

import { ContractServicesProvider } from '../context/ContractServicesContext';
import { ContractFacilitiesProvider } from '../context/ContractFacilitiesContext';
import { ContractDataProvider } from '../context/ContractDataContext';

import { useApi, TApiResponse } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import useAuth from 'hooks/useAuth';
import useValidation from '../hooks/useValidation';
import useContractData from '../hooks/useContractData';

import { getContractById, updateContract, createContract } from 'api/contract';
import { EDIT_PAGE, HISTORY_PAGE, NEW_PAGE } from 'constants/page';
import { composeSaveData, composeCreateData } from 'features/Contract/utils/composeData';
import {
  CONTRACT_TAB,
  ATTACHMENT_TAB,
  EDITABLE_STATE,
  ALLOW_GO_TO_ATTACHMENT_TAB_STATE,
  PROHIBIT_ATTACHMENT_TAB_STATE,
} from '../constant';
import { getPageFunction, getOfferFormType, getEditContractPath } from '../utils/offerFormType';

import CreateContractTab from '../components/CreateContractTab';
import AttachmentTab from '../components/AttachmentTab';
import ButtonGroupContractTab from '../components/ButtonGroupContractTab';

import { IContServesData, IFacilitiesType } from '../interfaces';

const EditContract = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const { user } = useAuth();
  const { error, success } = useDialogAlert();
  const { conFormId } = useParams<{ conFormId: string }>();
  const { setHeaderTitle, setIsClearHeader }: headerTitleContext = useOutletContext();
  const [contractFormRef] = Form.useForm();
  const from = state ? state.from : '/';

  const {
    makeRequest: _getContractById,
    data: contractData,
    loading: isLoadingContract,
    setData: setContractData,
  }: TApiResponse = useApi();
  const { makeRequest, loading: isSaving } = useApi();

  const [activeTab, setActiveTab] = useState(CONTRACT_TAB);
  const [isUpdatingState, setIsUpdatingState] = useState(false);

  const pageFunction = useMemo(() => getPageFunction(pathname), [pathname]);
  const formStatus = useMemo(() => {
    if (contractData) {
      return contractData.formStatus;
    } else {
      return undefined;
    }
  }, [contractData]);
  const isNewPage = pageFunction === NEW_PAGE;

  const isDisabled = useMemo(() => {
    if (pageFunction === HISTORY_PAGE) return true;
    if (formStatus) {
      return !EDITABLE_STATE.includes(formStatus) || !contractData.isActiveContract;
    } else {
      return false;
    }
  }, [formStatus, contractData, pageFunction]);

  const offerFormType = useMemo(() => {
    if (!pageFunction) return '';
    switch (pageFunction) {
      case EDIT_PAGE:
        if (!contractData) return '';
        return contractData.offerFormType;
      case NEW_PAGE:
        if (!pathname) return '';
        return getOfferFormType(pathname.split('/')[2]);
      default:
        return '';
    }
  }, [contractData, pageFunction, pathname]);

  const contractType = useMemo(() => {
    return pathname.split('/')[2];
  }, [pathname]);

  const isDisabledAttachmentTab = useMemo(() => {
    if (contractData?.formStatus)
      return !ALLOW_GO_TO_ATTACHMENT_TAB_STATE.includes(contractData?.formStatus);
    else return true;
  }, [contractData]);

  const isNotAllowedGoToAttachmentTab = useMemo(() => {
    if (contractData?.formStatus)
      return PROHIBIT_ATTACHMENT_TAB_STATE.includes(contractData?.formStatus);
    else return false;
  }, [contractData]);

  const { validateContractPage } = useValidation({
    formRef: contractFormRef,
    offerFormType,
  });
  const { onPrepareContractData } = useContractData({
    currentContServes: contractData?.contServes || [],
    pageFunction,
    contractFormRef,
    currentContFacilities: contractData?.contFacilities || [],
    user,
    offerFormType,
  });

  const onSaveContract = useCallback(
    async (data: any) => {
      try {
        const saveResponse = await makeRequest(() => updateContract(data.conFormId, data));
        setContractData(saveResponse.data?.data);
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
      } catch (errorInfo) {
        console.error(errorInfo);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [error, makeRequest, setContractData, success]
  );

  const onCreateContract = useCallback(
    async (data: any) => {
      try {
        const saveResponse = await makeRequest(() => createContract(data));
        setContractData(saveResponse.data?.data);
        const editContractPath = getEditContractPath(offerFormType);
        const path = editContractPath(saveResponse.data?.data.conFormId);
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' }, () =>
          navigate(path, { state: { from } })
        );
      } catch (errorInfo) {
        console.error(errorInfo);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [error, makeRequest, navigate, offerFormType, setContractData, success, from]
  );

  const createContractRequest = useCallback(
    async ({
      currentContServes,
      currentContFacilities,
    }: {
      currentContServes: IContServesData[];
      currentContFacilities: IFacilitiesType[];
    }) => {
      if (!user) {
        error({
          header: 'ไม่พบข้อมูลผู้ใช้งาน',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
        return;
      }
      switch (pageFunction) {
        case EDIT_PAGE:
          const editedData = composeSaveData({
            formData: contractFormRef.getFieldsValue(true),
            currentContServes,
            currentContFacilities,
          });

          await onSaveContract(editedData);
          break;
        case NEW_PAGE:
          const newData = composeCreateData({
            formData: contractFormRef.getFieldsValue(true),
            ownerId: user?.referenceNo,
            offerFormType,
            currentContServes,
            currentContFacilities,
          });
          await onCreateContract(newData);
          break;
        default:
          break;
      }
    },
    [contractFormRef, error, onCreateContract, onSaveContract, offerFormType, pageFunction, user]
  );

  const onGetContractById = useCallback(() => {
    if (conFormId) {
      _getContractById(() => getContractById(conFormId));
    }
  }, [_getContractById, conFormId]);

  const onSubmitForm = useCallback(
    async ({
      currentContServes,
      currentContFacilities,
    }: {
      currentContServes: IContServesData[];
      currentContFacilities: IFacilitiesType[];
    }) => {
      try {
        // await validateContractPage({ currentContServes });
        await contractFormRef.validateFields([
          'offerFormId',
          'formNo',
          'formDate',
          'selectedRenter',
        ]);
        await createContractRequest({
          currentContServes,
          currentContFacilities,
        });
      } catch (errorInfo: any) {
        error({
          header: 'กรุณากรอกฟอร์มให้ครบถ้วน',
          sub: '',
        });
      }
    },
    [createContractRequest, error, contractFormRef]
  );

  const tabItems: TabsProps['items'] = [
    {
      key: CONTRACT_TAB,
      label: `จัดทำสัญญา`,
      children: (
        <>
          <CreateContractTab
            contractFormRef={contractFormRef}
            contractData={contractData}
            offerFormType={offerFormType}
            isDisabled={isDisabled}
            contractType={contractType}
          />
          <ButtonGroupContractTab
            isDisabled={isDisabled}
            isNewPage={isNewPage}
            offerFormType={offerFormType}
            conFormId={conFormId}
            stateCode={contractData?.formStatus}
            isActiveContract={contractData?.isActiveContract}
            onSubmitForm={onSubmitForm}
            setActiveTab={setActiveTab}
            onGetContractById={onGetContractById}
            setIsUpdatingState={setIsUpdatingState}
            contAttachFiles={contractData?.contAttachFiles}
            setContractData={setContractData}
            isNotAllowedGoToAttachmentTab={isDisabledAttachmentTab}
          />
        </>
      ),
    },
    {
      key: ATTACHMENT_TAB,
      label: `แนบเอกสาร`,
      disabled: isNewPage || isNotAllowedGoToAttachmentTab,
      children: (
        <AttachmentTab
          contractFormRef={contractFormRef}
          contractData={contractData}
          offerFormType={offerFormType}
          isDisabled={isDisabled}
          onGetContractById={onGetContractById}
          setIsUpdatingState={setIsUpdatingState}
        />
      ),
    },
  ];

  useEffect(() => {
    onGetContractById();
  }, [onGetContractById]);

  useEffect(() => {
    setIsClearHeader(false);
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle, setIsClearHeader]);

  return (
    <ContractDataProvider
      onPrepareContractData={onPrepareContractData}
      validateContractPage={validateContractPage}
      setActiveTab={setActiveTab}
    >
      <ContractServicesProvider contractData={contractData} isDisabled={isDisabled}>
        <ContractFacilitiesProvider contractData={contractData} isDisabled={isDisabled}>
          <div className="content-box">
            <Spin
              tip="กำลังดำเนินการ..."
              spinning={isLoadingContract || isSaving || isUpdatingState}
            >
              <Content>
                {/* <ProcessStep /> */}
                <div>
                  <Tabs
                    defaultActiveKey={CONTRACT_TAB}
                    onChange={(activeKey) => {
                      setActiveTab(activeKey);
                    }}
                    items={tabItems}
                    activeKey={activeTab}
                  />
                </div>
              </Content>
            </Spin>
          </div>
        </ContractFacilitiesProvider>
      </ContractServicesProvider>
    </ContractDataProvider>
  );
};

export default EditContract;
