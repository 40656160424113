import { useMemo } from 'react';
import { Col, Form, Modal, Row, Spin } from 'antd';

import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';
import useServiceFacilityValidation from 'features/Contract/hooks/useServiceFacilityValidation';
import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

import ModalFooter from 'components/ModalFooter';
import ServiceForm from '../ServiceForm';
import ContServePaysTable from '../ContServePaysTable';

import { formLayout } from 'constants/form';

type ServiceModalProps = {
  selectedOfferFormId: number | string | undefined;
};

const ServiceModal = ({ selectedOfferFormId }: ServiceModalProps) => {
  const {
    selectedContServe,
    isContServeModalOpen,
    contServeModalFormRef,
    onCloseContServesModal,
    onSubmitContServesModalForm,
  } = useContractServicesContext();
  const { currentContFacilities } = useContractFacilitiesContext();
  const { onCheckExistingServiceInFacilites } = useServiceFacilityValidation();

  const isDisabledServiceForm = useMemo(() => {
    if (!selectedContServe) return true;

    const isServiceExistInFacility = onCheckExistingServiceInFacilites({
      contServes: selectedContServe,
      currentContFacilities: currentContFacilities,
      isThrowError: false,
    });
    if (isServiceExistInFacility) return true;

    if (selectedContServe?.contServePays)
      if (selectedContServe.contServePays.length > 0) return true;

    return false;
  }, [selectedContServe, onCheckExistingServiceInFacilites, currentContFacilities]);

  return (
    <Modal
      title="บริการและอัตราค่าตอบแทน"
      width="75%"
      open={isContServeModalOpen}
      onCancel={onCloseContServesModal}
      footer={
        <ModalFooter
          confirmLabel="บันทึกรายการ"
          handleCancel={onCloseContServesModal}
          onSubmitForm={() => contServeModalFormRef.submit()}
        />
      }
    >
      <Spin spinning={false}>
        <Form
          {...formLayout}
          labelWrap
          size="large"
          labelAlign="left"
          name="RIOserviceModalForm"
          disabled={isDisabledServiceForm}
          form={contServeModalFormRef}
          labelCol={{ span: 5 }}
          onFinish={onSubmitContServesModalForm}
        >
          <ServiceForm
            selectedOfferFormId={selectedOfferFormId}
            isDisabledServiceForm={isDisabledServiceForm}
          />
        </Form>
        <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
          <Col span={24}>
            <ContServePaysTable />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default ServiceModal;
