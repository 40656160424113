import { Col, Form, Input, Row, InputNumber, Space, FormInstance, Checkbox } from 'antd';

import useContractForm from './hooks/useContractForm';

import { ThaiDatepickerComponent } from 'components';
import ContractOfferDropdown from '../ContractOfferDropdown';
import RenterDropdown from '../RenterDropdown';
import OfferTypeOwnerForm from '../OfferTypeOwnerForm';

import { IContractData } from 'features/Contract/interfaces';

import { formLayout } from 'constants/form';

const { TextArea } = Input;

export interface IContractForm {
  contractFormRef: FormInstance<any>;
  contractData: IContractData;
  offerFormType: string;
  onChangeOfferFormId: (value: number) => void;
  isDisabled: boolean;
  isChortorEdit?: boolean;
  selectedOfferFormId?: number;
}

const ContractForm = ({
  isDisabled,
  contractFormRef,
  contractData,
  offerFormType,
  onChangeOfferFormId,
  selectedOfferFormId,
  isChortorEdit = false,
}: IContractForm) => {
  const {
    taxId,
    onConfirmChangeOfferId,
    onChangeOwner,
    onChangeContractEndDate,
    onChangeContractStartDate,
  } = useContractForm({
    isChortorEdit,
    contractFormRef,
    contractData,
    onChangeOfferFormId,
    selectedOfferFormId,
  });

  return (
    <div className="content-box">
      <Form
        {...formLayout}
        labelWrap
        form={contractFormRef}
        name="contractForm"
        size="large"
        disabled={isDisabled}
        validateTrigger="onSubmit" // Set the validation trigger to onSubmit
      >
        <Row gutter={[16, 16]}>
          {isChortorEdit && (
            <OfferTypeOwnerForm contractFormRef={contractFormRef} onChangeOwner={onChangeOwner} />
          )}
          <Col span={24}>
            <ContractOfferDropdown
              offerFormType={offerFormType}
              onChangeOfferFormId={onConfirmChangeOfferId}
              taxId={taxId}
            />
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="เลขที่หนังสือนำส่งสัญญา"
              name="formNo"
              rules={[{ required: true, message: 'กรุณากรอกเลขที่หนังสือนำส่งสัญญา' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่หนังสือนำส่งสัญญา"
              name="formDate"
              rules={[{ required: true, message: 'กรุณากรอกวันที่หนังสือนำส่งสัญญา' }]}
            >
              <ThaiDatepickerComponent disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              colon
              labelAlign="right"
              label="เลขที่สัญญา"
              name="contractNo"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
              // rules={[{ required: true, message: 'กรุณากรอกเลขที่สัญญา' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่จัดทำสัญญา"
              name="contractDate"
              rules={[{ required: true, message: 'กรุณากรอกวันที่จัดทำสัญญา' }]}
            >
              <ThaiDatepickerComponent disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่ลงนามสัญญา"
              name="signedDate"
              rules={[{ required: true, message: 'กรุณากรอกวันที่ลงนามสัญญา' }]}
            >
              <ThaiDatepickerComponent disabled={isDisabled} />
            </Form.Item>
          </Col>
          <Col span={16}>
            <RenterDropdown
              label="ทำสัญญาร่วมกับ"
              name="selectedRenter"
              rules={[{ required: true, message: `กรุณาเลือกผู้ทำสัญญาร่วม` }]}
              labelCol={{ span: 6 }}
              isLabelInValue={true}
              taxId={taxId}
            />
          </Col>
          <Col span={16}>
            <Form.Item
              colon
              labelAlign="right"
              label="สำนักงานตั้งอยู่"
              name="address"
              labelCol={{ span: 6 }}
              rules={[{ required: true, message: 'กรุณากรอกสำนักงานตั้งอยู่' }]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="ตั้งแต่วันที่"
              name="contractStartDate"
              rules={[{ required: true, message: 'กรุณากรอกตั้งแต่วันที่' }]}
            >
              <ThaiDatepickerComponent disabled={isDisabled} onChange={onChangeContractStartDate} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              colon
              labelAlign="right"
              label="ถึงวันที่"
              name="contractEndDate"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              // rules={[{ required: true, message: 'กรุณากรอกถึงวันที่' }]}
            >
              <ThaiDatepickerComponent disabled={isDisabled} onChange={onChangeContractEndDate} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="extendContract" valuePropName="checked" wrapperCol={{ span: 24 }}>
              <Checkbox>มีเงื่อนไขต่ออายุอัตโนมัติ</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 8 }}
              label="ระยะเวลาสัญญา"
              style={{ marginBottom: 0 }}
            >
              <Space>
                <Form.Item
                  colon
                  labelAlign="right"
                  name="periodYears"
                  wrapperCol={{ span: 18 }}
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                >
                  <InputNumber
                    style={{ width: 120 }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <span>ปี</span>
                <Form.Item
                  wrapperCol={{ span: 5 }}
                  name="periodMonths"
                  style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
                >
                  <InputNumber style={{ width: 120 }} max={12} />
                </Form.Item>
                <span>เดือน</span>
              </Space>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item
              colon
              labelAlign="right"
              label="ลิงก์ที่นำมาใช้เผยแพร่ในเว็บไซต์"
              name="conFormPublicUrl"
              labelCol={{ span: 6 }}
              rules={[{ required: true, message: 'กรุณากรอกลิงก์ที่นำมาใช้เผยแพร่ในเว็บไซต์' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ContractForm;
