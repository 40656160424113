import { IAttachmentTab } from 'features/Contract/interfaces';

import { HeaderTitleComponent } from 'components';
import ContractForm from '../ContractForm';
import UploadAttachment from '../UploadAttachment';

const AttachmentTab = ({
  contractFormRef,
  contractData,
  offerFormType,
  isDisabled,
  onGetContractById,
  setIsUpdatingState,
  isChortorEdit,
}: IAttachmentTab) => {
  if (!contractData) return <div>กรุณาสร้างสัญญาก่อนดำเนินการต่อ</div>;
  return (
    <div>
      <ContractForm
        contractFormRef={contractFormRef}
        contractData={contractData}
        offerFormType={offerFormType}
        onChangeOfferFormId={() => {}}
        isChortorEdit={isChortorEdit}
        isDisabled={true}
      />
      <HeaderTitleComponent style={{ margin: '15px -20px' }}>
        รายละเอียดการแนบเอกสาร: สามารถแนบไฟล์ (pdf, word, excel) ครั้งละ 1 ไฟล์ ไฟล์ละไม่เกิน 20MB
      </HeaderTitleComponent>
      <UploadAttachment
        isDisabled={isDisabled}
        conFormId={contractData.conFormId}
        contAttachFiles={contractData.contAttachFiles}
        stateCode={contractData.formStatus}
        onGetContractById={onGetContractById}
        setIsUpdatingState={setIsUpdatingState}
      />
    </div>
  );
};

export default AttachmentTab;
