import { useEffect, useState } from 'react';
import { Col, Form, Input, Select } from 'antd';

import { OTHER_OPTION } from 'features/Offer/constant';

import useFetchFacilities from 'features/Offer/hooks/useFetchFacilities';
import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

const ContractFacilitiesDropdown = () => {
  const { onCheckDuplicateFacility, editingFacility, isFacilityModalOpen } =
    useContractFacilitiesContext();

  const { facilitiesData, isLoadingFacilities } = useFetchFacilities();
  const [isSelectOtherFacility, setIsSelectOtherFacility] = useState<boolean>(false);

  const onChangeFacility = (_: any, option: any) => {
    if (option.label === OTHER_OPTION) {
      setIsSelectOtherFacility(true);
    } else {
      // NOTE: Check duplicate facility
      onCheckDuplicateFacility(option);
      setIsSelectOtherFacility(false);
    }
  };

  useEffect(() => {
    if (editingFacility?.facName === OTHER_OPTION) setIsSelectOtherFacility(true);
    else setIsSelectOtherFacility(false);
  }, [editingFacility]);

  useEffect(() => {
    // NOTE: Reset isSelectOtherFacility when modal is closed
    if (!isFacilityModalOpen) setIsSelectOtherFacility(false);
  }, [isFacilityModalOpen]);

  return (
    <>
      <Col span={24}>
        <Form.Item
          colon
          label="ประเภทสิ่งอำนวยความสะดวก"
          name="facId"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          rules={[{ required: true, message: 'กรุณาเลือกประเภทสิ่งอำนวยความสะดวก' }]}
        >
          <Select
            allowClear
            labelInValue
            loading={isLoadingFacilities}
            placeholder="-- กรุณาเลือกประเภทสิ่งอำนวยความสะดวก --"
            onChange={onChangeFacility}
            options={
              facilitiesData
                ? facilitiesData.map((item: any) => ({
                    value: item.facId,
                    label: item.facName,
                  }))
                : []
            }
          />
        </Form.Item>
      </Col>
      {isSelectOtherFacility && (
        <Col span={24}>
          <Form.Item
            colon
            labelAlign="right"
            label="ชื่อประเภทสิ่งอำนวยความสะดวกอื่นๆ"
            name="facDesc"
            labelCol={{ span: 6 }}
            rules={[{ required: true, message: 'กรุณากรอกชื่อประเภทสิ่งอำนวยความสะดวกอื่นๆ' }]}
          >
            <Input placeholder="กรุณากรอกชื่อประเภทสิ่งอำนวยความสะดวกอื่นๆ" />
          </Form.Item>
        </Col>
      )}
    </>
  );
};

export default ContractFacilitiesDropdown;
