import { useCallback, useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';
import { Rule } from 'antd/es/form';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getAllOperators } from 'api/operator';

type RenterDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  rules?: Rule[] | undefined;
  onChange?: (values: any) => void;
  name: string;
  isLabelInValue: boolean;
  taxId?: string | undefined | null;
};

const RenterDropdown = ({
  label,
  name,
  labelCol = undefined,
  rules = undefined,
  onChange = undefined,
  isLabelInValue,
  taxId,
}: RenterDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  const dataTransformer = useCallback(
    (data: any) => {
      if (taxId) {
        return data.filter((item: any) => item.taxId !== taxId);
      } else {
        return data;
      }
    },
    [taxId]
  );

  useEffect(() => {
    makeRequest(() => getAllOperators(), { isThrowError: false, dataTransformer }); // #NEED
  }, [makeRequest, dataTransformer]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name={name}
        label={label ? label : 'ทำสัญญาร่วมกับ'}
        labelCol={labelCol}
        rules={rules}
        // rules={[{ required: true, message: `กรุณาเลือก${label ? label : 'ทำสัญญาร่วมกับ'}` }]}
      >
        <Select
          allowClear
          labelInValue={isLabelInValue}
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          onChange={onChange}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input)
          }
          options={
            data
              ? data.map((item: { id: number; taxId: string; operName: string }) => ({
                  value: item.taxId,
                  label: item.operName,
                  key: item.id,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default RenterDropdown;
