import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormInstance } from 'antd';

import { confirmOffer, createOffer, improveOffer, updateOffer } from 'api/offer';

import { TApiResponse, useApi } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import useValidation from './useValidation';
import useFileManagement from 'features/Contract/hooks/useFileManagement';

import { downloadOfferDoc } from 'api/report';
import { IRisoData } from 'api/offer/interface';

import { IOfferData, IOfferFacility, IOfferServes } from '../interfaces';
import { composeSaveOfferData } from '../utils/composeData';
import { getOfferEditPathFromOfferType } from '../utils';

type UseRequestOfferProps = {
  offerId: string | number | undefined;
  getOfferDataAPI: (offerId: string | number) => Promise<any>;
};

type UseRequestOfferReturnProps = {
  offerData: IOfferData;
  isLoadingOfferData: boolean;
  isConfirmingOffer: boolean;
  onGetOfferById: (offerId: string | number | undefined) => void;
  onSaveOffer: (data: OnSaveOfferParams) => void;
  onConfirmOffer: (data: OnConfirmOfferParams) => void;
  onImproveOfferFromExistingOne: () => void;
  setOfferData: React.Dispatch<React.SetStateAction<IOfferData>>;
};

export type OnSaveOfferParams = {
  editOfferFormRef: FormInstance<any>;
  currentOfferServices: IOfferServes[];
  currentOfferFacilities: IOfferFacility[];
};

export type OnConfirmOfferParams = {
  editOfferFormRef: FormInstance<any>;
  currentOfferServices: IOfferServes[];
};

const useRequestOffer = ({
  offerId,
  getOfferDataAPI,
}: UseRequestOfferProps): UseRequestOfferReturnProps => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { error, success } = useDialogAlert();
  const { validateOfferByOfferType } = useValidation();
  const { makeRequest, loading, data, setData }: TApiResponse = useApi();
  const { makeRequest: _onConfirmOffer, loading: isConfirmingOffer }: TApiResponse = useApi();
  const { downloadFile } = useFileManagement();

  const from = state ? state.from : '/';

  const onGetOfferById = useCallback(
    async (offerId: string | number | undefined) => {
      if (!offerId)
        return error({
          header: 'กรุณาระบุ id ของข้อเสนอ',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      makeRequest(() => getOfferDataAPI(offerId));
    },
    [makeRequest, error, getOfferDataAPI]
  );

  const onCreateNewOffer = useCallback(
    async (requestData: IRisoData) => {
      try {
        const response = await makeRequest(() => createOffer(requestData), true);
        const data = response?.data?.data;
        const newEditPath = getOfferEditPathFromOfferType(data?.offerFormType, data?.offerFormId);
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' }, () => {
          navigate(newEditPath, { state: { from } });
        });
      } catch (e) {
        console.error(e);
        return error({
          header: 'ไม่สามารถบันทึกรายการได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [makeRequest, navigate, success, error, from]
  );

  const onUpdateExistingOffer = useCallback(
    async (requestData: IRisoData) => {
      if (offerId) {
        await makeRequest(() => updateOffer({ offerId: offerId, data: requestData }));
        success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
      } else {
        return error({
          header: 'ไม่พบ offerId',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [makeRequest, offerId, error, success]
  );

  const onSaveOffer = useCallback(
    async ({
      editOfferFormRef,
      currentOfferServices,
      currentOfferFacilities,
    }: OnSaveOfferParams) => {
      // NOTE 1️⃣: Validate offer form
      // try {
      //   await validateOfferByOfferType({
      //     offerType: data?.offerFormType,
      //     editOfferFormRef,
      //     currentOfferServices,
      //   });
      // } catch (e: any) {
      //   return error({
      //     header: e.message || 'กรุณาตรวจสอบข้อมูลให้ครบถ้วน',
      //     sub: '',
      //   });
      // }

      // NOTE 2️⃣: Prepare data
      const requestData = composeSaveOfferData({
        offerData: data,
        editOfferFormRef,
        currentOfferServices,
        currentOfferFacilities,
      });

      // NOTE 3️⃣: Update/Create offer
      if (data?.offerFormId) {
        // NOTE: Update offer
        await onUpdateExistingOffer(requestData);
      } else {
        // NOTE: Create offer
        await onCreateNewOffer(requestData);
      }
    },
    [data, onUpdateExistingOffer, onCreateNewOffer]
  );

  const onConfirmOffer = useCallback(
    async ({ editOfferFormRef, currentOfferServices }: OnConfirmOfferParams) => {
      // NOTE 1️⃣: Validate offer form
      try {
        await validateOfferByOfferType({
          offerType: data?.offerFormType,
          editOfferFormRef,
          currentOfferServices,
        });
      } catch (e: any) {
        return error({
          header: e.message || 'กรุณาตรวจสอบข้อมูลให้ครบถ้วน',
          sub: '',
        });
      }

      try {
        if (offerId) {
          await _onConfirmOffer(() => confirmOffer(offerId));
          await downloadFile({
            fileId: Number(offerId),
            api: downloadOfferDoc,
          });
          onGetOfferById(offerId);
          success({ header: 'บันทึกรายการสำเร็จ', sub: '' });
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      _onConfirmOffer,
      onGetOfferById,
      success,
      offerId,
      downloadFile,
      error,
      validateOfferByOfferType,
      data,
    ]
  );

  const onImproveOfferFromExistingOne = useCallback(async () => {
    try {
      if (offerId) {
        const response = await makeRequest(() => improveOffer(offerId));
        const offerData = response?.data?.data;
        if (offerData?.offerFormId) {
          success({ header: 'บันทึกรายการสำเร็จ', sub: '' }, () => {
            const path = getOfferEditPathFromOfferType(
              offerData?.offerFormType,
              offerData?.offerFormId
            );
            navigate(path);
          });
        } else {
          error({
            header: 'ไม่สามารถดำเนินการได้',
            sub: 'กรุณาลองใหม่อีกครั้ง',
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [makeRequest, offerId, success, navigate, error]);

  useEffect(() => {
    if (offerId) onGetOfferById(offerId);
  }, [onGetOfferById, offerId]);

  return {
    offerData: data,
    isLoadingOfferData: loading,
    isConfirmingOffer,
    setOfferData: setData,
    onGetOfferById,
    onSaveOffer,
    onConfirmOffer,
    onImproveOfferFromExistingOne,
  };
};

export default useRequestOffer;
