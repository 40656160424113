import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';
import { Rule } from 'antd/es/form';

import { useApi, TApiResponse } from 'hooks/useApi';
import { getAllOperators } from 'api/operator';

type OwnerDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  rules?: Rule[] | undefined;
  onChange?: (values: any) => void;
  name: string;
  isLabelInValue: boolean;
  disabled?: boolean;
  isIdValue?: boolean;
};

const OwnerDropdown = ({
  label,
  name,
  isLabelInValue,
  disabled = false,
  labelCol = undefined,
  rules = undefined,
  onChange = undefined,
  isIdValue = false,
}: OwnerDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => getAllOperators());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name={name}
        label={label ? label : 'ทำสัญญาร่วมกับ'}
        labelCol={labelCol}
        rules={rules}
        // rules={[{ required: true, message: `กรุณาเลือก${label ? label : 'ทำสัญญาร่วมกับ'}` }]}
      >
        <Select
          labelInValue={isLabelInValue}
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          onChange={onChange}
          disabled={disabled}
          showSearch
          filterOption={(input, option) =>
            (option?.label ?? '').toString().toLowerCase().includes(input)
          }
          options={
            data
              ? data.map((item: { id: number; taxId: string; operName: string }) => ({
                  value: item[isIdValue ? 'id' : 'taxId'],
                  label: item.operName,
                  key: item.id,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default OwnerDropdown;
