import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col, Form, Row } from 'antd';

import { ContractPublicUrlData, ListContractPublishUrlQuery } from 'api/contract/interface';
import { listContractPublishUrl, updateContractPublicUrl } from 'api/contract/publishUrl';

import useModal from 'hooks/useModal';
import usePagination from 'hooks/usePagination';
import useDialogAlert from 'hooks/useDialogAlert';
import { useApi, TApiResponse } from 'hooks/useApi';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import SearchForm from '../components/SearchForm';
import TableResultList from '../components/TableResultList';
import PublishLinkModal from '../components/PublishLinkModal';
import { IContractPublishUrl } from '../interfaces';
import { columns } from './columns';

const ContractPublishUrl = () => {
  const [formRef] = Form.useForm();
  const { error } = useDialogAlert();
  const [modalFormRef] = Form.useForm();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();

  const [queryParam, setQueryParam] = useState<any>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<IContractPublishUrl | undefined>(undefined);

  const { makeRequest, data, loading, pageable }: TApiResponse = useApi();
  const { makeRequest: _updateContractPublicUrl, loading: isUpdating }: TApiResponse = useApi();

  const onSelectRecord = useCallback(
    (record: IContractPublishUrl) => {
      setSelectedRecord(record);
      showModal();
    },
    [setSelectedRecord, showModal]
  );

  const onCloseModal = useCallback(() => {
    handleCancel();
    setSelectedRecord(undefined);
    modalFormRef.resetFields();
  }, [handleCancel, modalFormRef]);

  const onListContactPublicUrl = useCallback(
    (param: ListContractPublishUrlQuery): void => {
      try {
        makeRequest(() =>
          listContractPublishUrl({
            ...param,
          })
        );
      } catch (e) {
        console.error(e);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [makeRequest, error]
  );

  const onRequestUpdateContractPublicUrl = useCallback(
    async (data: ContractPublicUrlData): Promise<void> => {
      try {
        await _updateContractPublicUrl(() => updateContractPublicUrl(data));
      } catch (e) {
        error({
          header: 'ไม่สามารถอัพเดทได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [_updateContractPublicUrl, error]
  );

  const onUpdateLinks = useCallback(
    async (values: any): Promise<void> => {
      if (selectedRecord?.conFormId && values.conFormPublicUrl) {
        const data: ContractPublicUrlData = {
          conFormId: selectedRecord?.conFormId,
          conFormPublicUrl: values.conFormPublicUrl,
        };
        await onRequestUpdateContractPublicUrl(data);
        if (queryParam) {
          onListContactPublicUrl({ ...queryParam, pageSize, currentPageNumber });
        } else {
          onListContactPublicUrl({ pageSize, currentPageNumber });
        }
        onCloseModal();
      } else {
        error({
          header: 'ไม่พบข้อมูลสัญญา',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [
      currentPageNumber,
      error,
      onRequestUpdateContractPublicUrl,
      onListContactPublicUrl,
      pageSize,
      queryParam,
      selectedRecord,
      onCloseModal,
    ]
  );

  useEffect(() => {
    if (queryParam) {
      onListContactPublicUrl({ ...queryParam, pageSize, currentPageNumber });
    } else {
      onListContactPublicUrl({ pageSize, currentPageNumber });
    }
  }, [onListContactPublicUrl, pageSize, currentPageNumber, queryParam]);

  useEffect(() => {
    setHeaderTitle('ลิงก์เปิดเผยสัญญา');
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <PublishLinkModal
        modalTitle="Link Web เปิดเผยสัญญา"
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        modalFormRef={modalFormRef}
        isUpdating={isUpdating}
        onUpdateLink={onUpdateLinks}
        linkFieldName="conFormPublicUrl"
        selectedRecord={selectedRecord}
        setValueFieldName="publicUrl"
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HeaderTitle>ค้นหาโดย</HeaderTitle>
        </Col>
        <Col span={24}>
          <SearchForm
            formRef={formRef}
            onSearch={(values: any) => {
              setQueryParam(values);
              setCurrentPageNumber(1);
              onListContactPublicUrl({ ...values, pageSize, currentPageNumber: 1 });
            }}
          />
        </Col>
        <Col span={24}>
          <HeaderTitle>ผลการค้นหา</HeaderTitle>
        </Col>
        <Col span={24}>
          <TableResultList
            rowKey="conFormId"
            data={data}
            loading={loading}
            paginationSetting={paginationSetting(pageable?.totalItems)}
            columns={columns({
              page: currentPageNumber,
              pageSize,
              onSelectRecord,
            })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ContractPublishUrl;
