import { FormInstance, Modal } from 'antd';
import React from 'react';
import ModalAssignmentContent from './ModalAssignmentContent';

type Props = {
  modalOpenOffer: boolean;
  onModalCancel: () => void;
  width: string | number;
  title: string;
  form: FormInstance<any>;
  onFinishOfferContent: (values: any) => void;
  onFinishFailedOfferContent: (errorInfo: any) => void;
  operatorList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  listAssignees:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
};

const ModalAssignment = (props: Props) => {
  const {
    modalOpenOffer,
    onModalCancel,
    title,
    width,
    form,
    onFinishFailedOfferContent,
    onFinishOfferContent,
    operatorList,
    listAssignees,
  } = props;
  return (
    <Modal
      width={width}
      title={title ? title : 'Basic Modal'}
      open={modalOpenOffer}
      onOk={undefined}
      onCancel={onModalCancel}
      footer={<></>}
      centered
    >
      <ModalAssignmentContent
        form={form}
        onFinishFailedOfferContent={onFinishFailedOfferContent}
        onFinishOfferContent={onFinishOfferContent}
        onModalCancel={onModalCancel}
        operatorList={operatorList}
        listAssignees={listAssignees}
      />
    </Modal>
  );
};

export default ModalAssignment;
