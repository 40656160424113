import { Form, Select } from 'antd';

import { ISubDistricts } from 'types/Authorization';
import { onFilterOption } from 'utils/select';

type SubDistrictDropdownProps = {
  isLoadingSubDistricts: boolean;
  subDistricts: ISubDistricts[];
  isDisabled: boolean;
};

const SubDistrictDropdown = ({
  isLoadingSubDistricts,
  subDistricts,
  isDisabled,
}: SubDistrictDropdownProps) => {
  return (
    <Form.Item
      colon
      label="ตำบล/แขวง"
      name="conSubDistrictId"
      rules={[{ required: true, message: 'กรุณาเลือกตำบล/แขวง' }]}
    >
      <Select
        showSearch
        disabled={!subDistricts || isDisabled}
        loading={isLoadingSubDistricts}
        placeholder="-- กรุณาระบุ --"
        optionFilterProp="children"
        filterOption={onFilterOption}
        options={
          subDistricts
            ? subDistricts.map((item: any) => ({
                value: item.subDistrictId,
                label: item.subDistrictName,
              }))
            : []
        }
      />
    </Form.Item>
  );
};

export default SubDistrictDropdown;
