import { createContext } from 'react';
import { ValidateContractPageProps } from '../hooks/useValidation';

type TContractDataContext = {
  isReviewPage: boolean;
  onPrepareContractData: () => {} | undefined;
  validateContractPage: ({ currentContServes }: ValidateContractPageProps) => Promise<void>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
};
const ContractDataContext = createContext<TContractDataContext>({} as TContractDataContext);

type TContractDataProvider = {
  isReviewPage?: boolean;
  children: React.ReactNode;
  onPrepareContractData: () => {} | undefined;
  validateContractPage: ({ currentContServes }: ValidateContractPageProps) => Promise<void>;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
};
const ContractDataProvider = ({
  children,
  onPrepareContractData,
  validateContractPage,
  setActiveTab,
  isReviewPage = false,
}: TContractDataProvider) => {
  return (
    <ContractDataContext.Provider
      value={{ onPrepareContractData, isReviewPage, validateContractPage, setActiveTab }}
    >
      {children}
    </ContractDataContext.Provider>
  );
};

export { ContractDataContext, ContractDataProvider };
