import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Space } from 'antd';
import { CheckOutlined, RollbackOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';

type ActionButtonAttachmentGroupProps = {
  onConfirmDoc: Function;
  isDisabled: boolean;
  isHideConfirmDocButton?: boolean;
};

const ActionButtonAttachmentGroup = ({
  onConfirmDoc,
  isDisabled,
  isHideConfirmDocButton = false,
}: ActionButtonAttachmentGroupProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state ? state.from : '/';

  return (
    <Col span={24}>
      <Space align="center" style={{ display: 'flex', justifyContent: 'center' }}>
        {!isHideConfirmDocButton && (
          <PrimaryButton
            disabled={isDisabled}
            icon={<CheckOutlined />}
            onClick={() => onConfirmDoc()}
          >
            ยืนยันการจัดส่งเอกสาร
          </PrimaryButton>
        )}
        <PrimaryButton icon={<RollbackOutlined />} onClick={() => navigate(from)}>
          ย้อนกลับหน้าค้นหา
        </PrimaryButton>
      </Space>
    </Col>
  );
};

export default ActionButtonAttachmentGroup;
