import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import useDialogAlert from 'hooks/useDialogAlert';
import { useApi, TApiResponse } from 'hooks/useApi';
import { updateNewContract } from 'api/contract';

import PrimaryButton from 'components/PrimaryButton';
import { getEditContractPath } from 'features/Contract/utils/offerFormType';

type UpdateNewContractButtonProps = {
  conFormId: string | undefined;
  offerFormType: string;
  setIsUpdatingState: React.Dispatch<React.SetStateAction<boolean>>;
  isActiveContract: boolean;
  isInquiryPage?: boolean;
};

const UpdateNewContractButton = ({
  conFormId,
  offerFormType,
  setIsUpdatingState,
  isActiveContract,
  isInquiryPage = false,
}: UpdateNewContractButtonProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state ? state.from : '/';

  const { makeRequest, loading }: TApiResponse = useApi();
  const { error } = useDialogAlert();

  const editContractPath = useMemo(() => {
    return getEditContractPath(offerFormType);
  }, [offerFormType]);

  const onUpdateNewContract = async () => {
    if (conFormId) {
      try {
        const res = await makeRequest(() => updateNewContract(conFormId));
        navigate(editContractPath(res?.data?.data?.conFormId), { state: { from } });
      } catch (e) {
        error({
          header: 'ไม่สามารถแก้ไขสัญญาได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    }
  };

  useEffect(() => {
    setIsUpdatingState(loading);
  }, [loading, setIsUpdatingState]);

  return (
    <PrimaryButton
      disabled={!isActiveContract || isInquiryPage}
      icon={<EditOutlined />}
      onClick={onUpdateNewContract}
    >
      แก้ไขสัญญา
    </PrimaryButton>
  );
};

export default UpdateNewContractButton;
