import { FormInstance } from 'antd';
import { HeaderTitleComponent } from 'components';
import React from 'react';
import FormSearch from './FormSearch';

type Props = {
  form: FormInstance<any>;
  onFinishSearch: (values: any) => void;
  onFinishFailedSearch: (errorInfo: any) => void;
  operatorList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  offerFormStatesList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  listAssignees:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
};

const Search = (props: Props) => {
  const {
    form,
    onFinishFailedSearch,
    onFinishSearch,
    operatorList,
    offerFormStatesList,
    listAssignees,
  } = props;
  return (
    <>
      <HeaderTitleComponent>ค้นหาโดย</HeaderTitleComponent>
      <FormSearch
        form={form}
        onFinishFailedSearch={onFinishFailedSearch}
        onFinishSearch={onFinishSearch}
        operatorList={operatorList}
        offerFormStatesList={offerFormStatesList}
        listAssignees={listAssignees}
      />
    </>
  );
};

export default Search;
