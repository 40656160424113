import { ClearOutlined, FileDoneOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select } from 'antd';

import { useCallback, useEffect, useState } from 'react';
import buttonCSS from 'styles/components/button.module.css';
import ThaiDatepickerComponent from 'components/ThaiDatepickerComponent';
import { GetTrackingReportResponse } from 'api/disagreement/interface';
import ExcelJS, { Border } from 'exceljs';
import { getTrackingReport } from 'api/disagreement';
import { dayJsformatDateToBCYear, formatDateToBCYearFull, getStringDateFromDayJs } from 'utils/date';
import { useApi } from 'hooks/useApi';
import { getAllOperators } from 'api/operator';
import { Operator } from 'api/operator/interface';

interface OptionFormStatus {
  value: string | number;
  label: string;
}

export interface SearchReportProps {
  onSearchReport: Function;
  onReset: Function;
  formReport: FormInstance<any>;
  reportType: string;
}

const SearchTrackingForm = ({ formReport, onReset, onSearchReport }: SearchReportProps) => {
  // #NEED
  const { makeRequest: getOperatorsRequest } = useApi();
  const { makeRequest: getTrackingReportRequest } = useApi();
  const [operators, setOperators] = useState<OptionFormStatus[]>([]);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getOperators = useCallback(async () => {
    return await getOperatorsRequest(() => {
      return getAllOperators();
    });
  }, [getOperatorsRequest]);

  useEffect(() => {
    getOperators().then((res) => {
      const operators: Operator[] = res.data.data;
      setOperators(
        operators.map((i) => ({
          value: i.id,
          label: i.operName,
        })),
      );
    });
  }, [getOperators]);

  const getThaiDate = (date: string): string | null => {
    if (date) {
      return dayJsformatDateToBCYear(date);
    }
    return null;
  };

  const exportExcel = async (
    payload: GetTrackingReportResponse,
    startDate: string,
    endDate: string,
  ) => {
    const header = [
      'ลำดับที่',
      'ผู้ร้อง',
      'ผู้ถูกร้อง',
      'ข้อพิพาทที่',
      'วันที่หนังสือนำส่งตั้งแต่',
      'วันที่ได้รับคำร้องและเอกสารหลักฐาน',
      'วันที่รับคำร้องเข้าสู่กระบวนการระงับข้อพิพาท',
      'วันที่ได้รับคำร้อง คำโต้แย้ง และเอกสารหลักฐานที่ถูกต้องครบถ้วน',
      'วันสิ้นสุดการแสวงหาข้อเท็จจริง',
      'บันทึกความเห็นของคณะอนุกรรมการ ลงวันที่',
      'รวมระยะเวลาดำเนินการ',
      'คำวินิจฉัยข้อพิพาทของ กสทช. ลงวันที่',
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');

    // Add header row with aligned cells
    let subHeaderRow;
    const headerRow = worksheet.addRow(['รายงานการติดตามกระบวนการระงับข้อพิพาท']);
    if (startDate && endDate) {
      subHeaderRow = worksheet.addRow([
        `วันที่รับคำร้อง ${formatDateToBCYearFull(startDate)} ถึง ${formatDateToBCYearFull(
          endDate,
        )}`,
      ]);
    } else if (startDate) {
      subHeaderRow = worksheet.addRow([
        `วันที่รับคำร้อง ${formatDateToBCYearFull(startDate)} เป็นต้นไป`,
      ]);
    } else if (endDate) {
      subHeaderRow = worksheet.addRow([`วันที่รับคำร้อง  ถึง ${formatDateToBCYearFull(endDate)}`]);
    } else {
      subHeaderRow = worksheet.addRow(['']);
    }

    worksheet.mergeCells(1, 1, 1, header.length);
    worksheet.mergeCells(2, 1, 2, header.length);
    const border: Border = {
      style: 'thin',
      color: {
        argb: '000000',
      },
    };
    [headerRow, subHeaderRow].forEach((i, index) => {
      if (i) {
        if (index === 0) {
          i.getCell(1).border = {
            top: border,
            left: border,
            right: border,
          };
          i.getCell(1).font = { bold: true, size: 16 };
        } else {
          i.getCell(1).border = {
            left: border,
            bottom: border,
            right: border,
          };
          i.getCell(1).font = { bold: true, size: 14 };
        }

        i.getCell(1).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } };
        i.getCell(1).alignment = { horizontal: 'center' };
      }
    });

    // Add header columns
    const headerRef = worksheet.addRow(header);
    headerRef.alignment = {
      wrapText: true,
      vertical: 'top',
    };


    // Add data rows
    payload.data.data.followReports.forEach((row, index) => {
      const disNo = row.disagreeNo && row.yearly ? row.disagreeNo + '/' + row.yearly : '';
      worksheet.addRow([
        index + 1,
        row.petitionerName,
        row.accusedName,
        disNo,
        getThaiDate(row.formDate),
        getThaiDate(row.receiveDocumentCompleteDate),
        getThaiDate(row.disAgreeDate),
        getThaiDate(row.receiveDiscussAccusedFormDate),
        getThaiDate(row.summarizeDate),
        getThaiDate(row.concludeFormDate),
        row.processDateDays,
        getThaiDate(row.approveDate),
      ]);
    });

    // Auto-fit column widths
    worksheet.columns.forEach((column) => {
      // column.alignment = { wrapText: true };
      column.width = 15;
    });

    // Generate buffer from workbook
    const buffer = await workbook.xlsx.writeBuffer();

    // Create a blob and download the file
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const fileName = 'tracking_report.xlsx';
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const onExport = async () => {
    const { petitionerId, accusedId, startDate, endDate } = formReport.getFieldsValue();
    await getTrackingReportRequest(async () => {
      const res = await getTrackingReport({
        petitionerId,
        accusedId,
        formStartDate: getStringDateFromDayJs(startDate),
        formEndDate: getStringDateFromDayJs(endDate),
      });
      await exportExcel(res, startDate, endDate);
      return res;
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Form
        {...layout}
        labelWrap
        form={formReport}
        requiredMark={false}
        name='searchOfferForm'
        onFinish={(val: any) => onSearchReport(val)}
        style={{ width: '70vw' }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item colon labelAlign='right' label='ผู้ร้อง' name='petitionerId'>
              <Select showSearch
                      filterOption={(input, option) => {
                        return (option?.label.indexOf(input) || -1) >= 0;
                      }} placeholder='-- กรุณาระบุ --' options={operators} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon labelAlign='right' label='ผู้ถูกร้อง' name='accusedId'>
              <Select showSearch
                      filterOption={(input, option) => {
                        return (option?.label.indexOf(input) || -1) >= 0;
                      }} placeholder='-- กรุณาระบุ --' options={operators} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item colon labelAlign='right' label='วันที่รับคำร้องตั้งแต่' name='startDate'>
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item colon labelAlign='right' label='วันที่รับคำร้องถึง' name='endDate'>
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify='center' style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type='primary'
              shape='round'
              icon={<FileDoneOutlined />}
              size='large'
              htmlType='submit'
              onClick={onExport}
            >
              พิมพ์รายงาน
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type='primary'
              shape='round'
              icon={<ClearOutlined />}
              size='large'
              onClick={() => onReset()}
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchTrackingForm;
