import { useMemo } from 'react';
import { Col, Form, Modal, Row, Spin } from 'antd';

import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';
import useValidation from 'features/Offer/hooks/useValidation';

import ModalFooter from 'components/ModalFooter';
import ServiceForm from '../ServiceForm';
import ServPayTable from '../ServPayTable';

import { formLayout } from 'constants/form';

const RIOServiceModal = () => {
  const {
    selectedService,
    isRIOServiceModalOpen,
    onCloseRIOServiceModal,
    RIOserviceModalFormRef,
    onSubmitServiceModalForm,
  } = useOfferServicesContext();
  const { currentOfferFacilities } = useOfferFacilititesContext();
  const { onCheckExistingServiceInFacilites } = useValidation();

  const isDisabledServiceForm = useMemo(() => {
    if (!selectedService) {
      return true;
    }
    const isServiceExistInFacility = onCheckExistingServiceInFacilites({
      service: selectedService,
      currentOfferFacilities,
      isThrowError: false,
    });
    if (isServiceExistInFacility) {
      return true;
    }
    if (selectedService?.offerServePays)
      if (selectedService.offerServePays.length > 0) {
        return true;
      }
    return false;
  }, [selectedService, currentOfferFacilities, onCheckExistingServiceInFacilites]);

  return (
    <Modal
      title="บริการและอัตราค่าตอบแทน"
      width="75%"
      open={isRIOServiceModalOpen}
      onCancel={onCloseRIOServiceModal}
      footer={
        <ModalFooter
          confirmLabel="บันทึกรายการ"
          handleCancel={onCloseRIOServiceModal}
          onSubmitForm={() => RIOserviceModalFormRef.submit()}
        />
      }
    >
      <Spin spinning={false}>
        <Form
          {...formLayout}
          labelWrap
          size="large"
          labelAlign="left"
          name="RIOserviceModalForm"
          disabled={isDisabledServiceForm}
          form={RIOserviceModalFormRef}
          labelCol={{ span: 5 }}
          onFinish={onSubmitServiceModalForm}
        >
          <ServiceForm isDisabled={isDisabledServiceForm} />
        </Form>
        <Row gutter={[16, 16]} style={{ marginTop: 15 }}>
          <Col span={24}>
            <ServPayTable />
          </Col>
        </Row>
      </Spin>
    </Modal>
  );
};

export default RIOServiceModal;
