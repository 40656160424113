/* eslint-disable */
import { HeaderTitleComponent } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import SearchDisagreementForm, {
  Option,
  SearchDisagreementFormInstance,
} from './SearchDisagreementForm/SearchDisagreementForm';
import { Form } from 'antd';
import ListDisagreementTable, { DataSource } from './ListDisagreementTable/ListDisagreementTable';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { TApiResponse, useApi } from 'hooks/useApi';
import { searchDisagreement } from 'api/disagreement';
import { SearchDisagreementResponse } from 'api/disagreement/interface';
import useLoading from 'hooks/useLoading';
import { getAllOperators } from 'api/operator';
import { Operator } from 'api/operator/interface';
import { hasValue } from 'utils/string';
import { dayJsformatDateObjToString, dayJsformatDateToBCYear } from 'utils/date';
import { subRoutePaths } from 'constants/routes';

const defaultDatasource: DataSource = {
  data: [],
  pagination: undefined,
};

const ListDisagreementViewOnly = (): JSX.Element => {
  const navigate = useNavigate();
  const { setLoad } = useLoading();
  const { makeRequest: searchDisagreementRequest } = useApi();
  const { makeRequest: getOperatorsRequest } = useApi();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const [searchForm] = Form.useForm<SearchDisagreementFormInstance>();
  const [searchFormValueChange, setFormChange] = useState<SearchDisagreementFormInstance>();
  const [dataSource, setDataSource] = useState<DataSource>(defaultDatasource);
  const [listAccused, setListAccused] = useState<Option[]>([]);
  const [listPetitioner, setListPetitioner] = useState<Option[]>([]);
  const [operators, setOperators] = useState<Operator[]>([]);

  const getOperators = useCallback(async () => {
    return await getOperatorsRequest(() => {
      return getAllOperators();
    });
  }, [getOperatorsRequest]);

  useEffect(() => {
    getOperators().then((res) => {
      const operators: Operator[] = res.data.data;
      setOperators(operators);
    });
  }, [getOperators]);

  useEffect(() => {
    onSearch();
  }, []);

  const onFinish = async () => {
    await onSearch();
  };

  const onFinishFailed = () => {};

  const onResetSearchForm = () => {
    searchForm.resetFields();
    updateDropDownList();
  };

  const onSearchFormValueChange = (value: SearchDisagreementFormInstance) => {
    setFormChange(value);
  };

  const updateDropDownList = useCallback(() => {
    const formValue = searchForm.getFieldsValue();
    if (formValue.petitioner) {
      const newList = operators.map((i) => {
        return {
          value: i.id,
          label: i.operName,
          disabled: i.id === formValue.petitioner,
        };
      });
      setListAccused(newList);
    }
    if (formValue.accused) {
      const newList = operators.map((i) => {
        return {
          value: i.id,
          label: i.operName,
          disabled: i.id === formValue.accused,
        };
      });
      setListPetitioner(newList);
    }

    if (!formValue.petitioner && !formValue.accused) {
      const mapOperators: Option[] = operators?.map((i) => ({
        value: i.id,
        label: i.operName,
        disabled: false,
      }));

      setListPetitioner(mapOperators);
      setListAccused(mapOperators);
    }
  }, [searchForm, operators]);

  useEffect(() => {
    updateDropDownList();
  }, [searchFormValueChange, updateDropDownList]);

  const mapResponseToDataSource = (data: SearchDisagreementResponse): DataSource => {
    return {
      data: data.data.map((i) => ({
        key: i.formId,
        formId: i.formId,
        disagreeNo:
          hasValue(i.disAgreeNo) && hasValue(i.yearly) ? i.disAgreeNo + '/' + i.yearly : '',
        petitioner: i.petitionerOperatorName,
        accused: i.accusedOperatorName,
        formStatus: i.statusGroupName,
        formDate: dayJsformatDateToBCYear(i.formDate),
        topic: i.topic,
        onDetailClick: () => {
          navigate(subRoutePaths.disagreementEdit(i.formId));
        },
      })),
      pagination: data.pageable,
    };
  };

  const onSearch = async (pageSize?: number, currentPage?: number) => {
    setLoad(true);
    const formData = searchForm.getFieldsValue();
    try {
      const result: TApiResponse<SearchDisagreementResponse> = await searchDisagreementRequest(
        () => {
          return searchDisagreement({
            currentPageNumber: currentPage || 0,
            pageSize: pageSize || 5,
            formNo: formData.bookNo,
            petitionerId: formData.petitioner,
            accusedId: formData.accused,
            formStartDate: formData.startDate
              ? dayJsformatDateObjToString(formData.startDate)
              : null,
            formEndDate: formData.endDate ? dayJsformatDateObjToString(formData.endDate) : null,
            formStatus: formData.status,
          });
        }
      );
      const newData = mapResponseToDataSource(result.data);
      setDataSource({
        data: [...newData.data],
        pagination: newData.pagination,
      });
      setLoad(false);
    } catch (e) {
      setLoad(false);
    }
  };

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  });

  return (
    <>
      <HeaderTitleComponent>ค้นหาโดย</HeaderTitleComponent>
      {SearchDisagreementForm({
        form: searchForm,
        onFinish,
        onFinishFailed,
        listAccused,
        listPetitioner,
        onSearch,
        onSearchFormValueChange,
        onReset: onResetSearchForm,
      })}
      <HeaderTitleComponent>ผลการค้นหา</HeaderTitleComponent>
      <div className="padding-conten-main">
        {ListDisagreementTable({
          dataSource,
          onSearch,
        })}
      </div>
    </>
  );
};

export default ListDisagreementViewOnly;
