import axiosInstance from 'utils/axios';

export const listInformationInquire = (
  pageSize: number | string,
  currentPageNumber: number | string,
  filter: {
    offerFormStartDate: string | null;
    offerFormEndDate: string | null;
    offerFormTypeCode: string | null;
    taxId: string | null;
  }
) => {
  return axiosInstance.get(
    `/offer/inquiry?currentPageNumber=${currentPageNumber}&pageSize=${pageSize}`,
    { params: filter }
  );
};

// export const listAssignTasksOfferFormStates = () => {
//   return axiosInstance.get(`/offer/officer/assign-tasks/offer-form-states`);
// };
// export const getListOfferAssignees = () => {
//   return axiosInstance.get(`/offer/officer/assignees`);
// };
// export const updateListOfferAssignees = (id: string | number, data: { officerIds: number[] }) => {
//   return axiosInstance.put(`/offer/officer/assign-tasks/${id}/assignees`, data);
// };
