import axiosInstance from 'utils/axios';
import {
  CreateConsiderInput,
  CreateDisagreementInput,
  CreateDisagreementResponse,
  GetAccusedFormResponse,
  GetAllConsiderRequestResponse,
  GetAllFormStatusResponse,
  GetArticleTypeResponse,
  GetAttachFileResponse,
  GetDisAgreementDocResponse,
  GetDisagreementResponse,
  GetDisAgreeStateVerifyPetitionResponse,
  GetIcConcludeResponse,
  GetOverviewReportInput,
  GetOverviewReportResponse,
  GetPetitionResponse,
  GetSummarizeResponse,
  GetTrackingReportInput,
  GetTrackingReportResponse,
  SearchDisagreementInput,
  SearchDisagreementResponse,
  UpdateAccusedFormInput,
  UpdateConsiderInput,
  UpdateDisagreementFormEightInput,
  UpdateDisagreementFormSevenInput,
  UpdateDisagreementInput,
  updateDisAgreeStateVerifyPetitionInput,
  UpdateFinishDisagreementFormInput,
  UpdateIcConcludeInput,
  UpdatePetitionInput,
  UpdateSaveFormInput,
  UpdateSummarizeInput,
} from './interface';
import { clean } from 'utils/object';

export const createDisagreement = (payload: CreateDisagreementInput): Promise<CreateDisagreementResponse> => {
  return axiosInstance.post(`/disagreements`, {
    ...payload,
  });
};

export const getDisagreement = (id: string): Promise<GetDisagreementResponse> => {
  return axiosInstance.get(`/disagreements/${id}`);
};

export const deleteDisagreement = (ids: number[]): Promise<any[]> => {
  const removeDisagreementList = ids.map((id) => {
    return axiosInstance.delete(`/disagreements/${id}`);
  });
  return Promise.all(removeDisagreementList);
};

export const updateDisagreement = (payload: UpdateDisagreementInput): Promise<void> => {
  return axiosInstance.put(`/disagreements/${payload.id}`, {
    ...payload,
  });
};

export const searchDisagreement = (
  payload: SearchDisagreementInput,
): Promise<SearchDisagreementResponse> => {
  return axiosInstance.post(
    `/disagreements/search`,
    clean<Partial<SearchDisagreementInput>>({
      formNo: payload.formNo,
      petitionerId: payload.petitionerId,
      accusedId: payload.accusedId,
      formStartDate: payload.formStartDate,
      formEndDate: payload.formEndDate,
      formStatus: payload.formStatus,
    }),
    {
      params: {
        currentPageNumber: payload.currentPageNumber,
        pageSize: payload.pageSize,
      },
    },
  );
};

export const UpdateDisagreementState = (id: number, actionCode?: string): Promise<void> => {
  return axiosInstance.put(`/disagreements/${id}/state`, clean({
    actionCode,
  }));
};

export const updateDisagreementForm = (id: number, state: number, date: string | null): Promise<void> => {
  return axiosInstance.put(`/disagreements/${id}/form/${state}/default`, clean({
    completeDate: date,
  }));
};

export const updateDisagreementFormSeven = (payload: UpdateDisagreementFormSevenInput): Promise<void> => {
  return axiosInstance.put(`/disagreements/${payload.disagreementId}/form/discuss`, clean({
    petitionerAgreeDiscuss: payload.petitionerAgreeDiscuss,
    accusedAgreeDiscuss: payload.accusedAgreeDiscuss,
    agreeDiscuss: payload.agreeDiscuss,
    completeDate: payload.completeDate,
  }));
};

export const updateDisagreementFormEight = (payload: UpdateDisagreementFormEightInput): Promise<void> => {
  return axiosInstance.put(`/disagreements/${payload.disagreementId}/form/agreement`, clean({
    negotiated: payload.negotiated,
    negotiatedDate: payload.negotiatedDate,
    completeDate: payload.completeDate,
    negotiatedRemark: payload.negotiatedRemark,
  }));
};

export const getArticleType = (id: string): Promise<GetArticleTypeResponse> => {
  return axiosInstance.get(`/articles/${id}`);
};

export const createConsiderRequest = (payload: CreateConsiderInput): Promise<void> => {
  return axiosInstance.post(`/consider-requests`, clean({
    articleId: payload.articleId,
    formId: payload.formId,
    stateCode: payload.stateCode,
    reviewStatus: payload.reviewStatus,
    reviewDate: payload.reviewDate,
  }));
};

export const updateConsiderRequest = (payload: UpdateConsiderInput): Promise<void> => {
  return axiosInstance.put(`/consider-requests/${payload.reqId}`, {
    articleId: payload.articleId,
    reviewStatus: payload.reviewStatus,
    reviewDate: payload.reviewDate,
    remark: payload.remark,
  });
};

export const getAllConsiderRequest = (formId: number, stateCode: number): Promise<GetAllConsiderRequestResponse> => {
  return axiosInstance.get(`consider-requests/all/${formId}/state/${stateCode}`);
};

export const deleteConsiderRequest = (reqId: number): Promise<void> => {
  return axiosInstance.delete(`consider-requests/${reqId}`);
};

export const getDisAgreementDoc = (formState: number): Promise<GetDisAgreementDocResponse> => {
  return axiosInstance.get(`disagreements/doc/${formState}`);
};

export const createAttachFile = (docId: number, stepState: number, formData: FormData): Promise<void> => {
  return axiosInstance.post(`disagreement-attach-file/${docId}/state/${stepState}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadAttachFileById = (id: number): Promise<{
  data: Blob
}> => {
  return axiosInstance.get(`disagreement-attach-file/${id}/download`, {
    responseType: 'blob',
  });
};

export const getAllAttachFile = (stepState: number): Promise<GetAttachFileResponse> => {
  return axiosInstance.get(`disagreement-attach-file/state/${stepState}/all`);
};


export const deleteAttachFile = (id: number): Promise<void> => {
  return axiosInstance.delete(`disagreement-attach-file/${id}`);
};

//Form 4
export const updateDisAgreeStateVerifyPetition = (payload: updateDisAgreeStateVerifyPetitionInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/verify-petition`, {
    officerApprove: payload.officerApprove,
    nbtcApprove: payload.nbtcApprove,
    disAgreeDate: payload.disAgreeDate,
    disAgreeNo: payload.disAgreeNo,
    yearly: payload.yearly,
    remark: payload.remark,
    completeDate: payload.completeDate,
  });
};

export const getDisAgreeStateVerifyPetition = (formId: number, stateId: number): Promise<GetDisAgreeStateVerifyPetitionResponse> => {
  return axiosInstance.get(`disagreements/${formId}/state/${stateId}/verify-petition`);
};

//Form 10
export const updateAccusedForm = (payload: UpdateAccusedFormInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/accused-form`, {
    discussFormDate: payload.discussFormDate,
    discussFormNo: payload.discussFormNo,
    remark: payload.remark,
    completeDate: payload.completeDate,
    receiveDiscussAccusedFormDate: payload.receiveDiscussAccusedFormDate,
  });
};

export const getAccusedForm = (formId: number, stateId: number): Promise<GetAccusedFormResponse> => {
  return axiosInstance.get(`disagreements/${formId}/state/${stateId}/accused-form`);
};

//Form 14
export const updateIcConclude = (payload: UpdateIcConcludeInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/ic-conclude`, {
    concludeFormDate: payload.concludeFormDate,
    remark: payload.remark,
    completeDate: payload.completeDate,
  });
};

export const getIcConclude = (formId: number, stateId: number): Promise<GetIcConcludeResponse> => {
  return axiosInstance.get(`disagreements/${formId}/state/${stateId}/ic-conclude`);
};


//Form 11
export const updatePetition = (payload: UpdatePetitionInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/petition-form`, {
    petitionReceiveDate: payload.petitionReceiveDate,
    numberPetition: payload.numberPetition,
    remark: payload.remark,
    completeDate: payload.completeDate,
  });
};

export const getPetition = (formId: number, stateId: number): Promise<GetPetitionResponse> => {
  return axiosInstance.get(`disagreements/${formId}/state/${stateId}/petition-form`);
};

//Form 12
export const updateSummarize = (payload: UpdateSummarizeInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/summarize`, {
    summarizeDate: payload.summarizeDate,
    remark: payload.remark,
    completeDate: payload.completeDate,
  });
};

export const getSummarize = (formId: number, stateId: number): Promise<GetSummarizeResponse> => {
  return axiosInstance.get(`disagreements/${formId}/state/${stateId}/summarize`);
};

export const updateFinishDisagreementForm = (payload: UpdateFinishDisagreementFormInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/finish-disagreement-form`, {
    approvedDate: payload.approvedDate,
    publicUrl: payload.publicUrl,
    completeDate: payload.completeDate,
  });
};

export const getAllFormStatus = (): Promise<GetAllFormStatusResponse> => {
  return axiosInstance.get('disagreements/all-form-status');
};


export const getOverviewReport = (input?: GetOverviewReportInput): Promise<GetOverviewReportResponse> => {
  return axiosInstance.post('disagreements/overview-report', {
    ...input,
  });
};

export const getTrackingReport = (input?: GetTrackingReportInput): Promise<GetTrackingReportResponse> => {
  return axiosInstance.post('disagreements/follow-report', {
    ...input,
  });
};

export const updateSaveForm = (payload: UpdateSaveFormInput): Promise<void> => {
  return axiosInstance.put(`disagreements/${payload.formId}/state/${payload.stateId}/save-form`, {
    completeDate: payload.completeDate,
    receiveDocumentCompleteDate: payload.receiveDocumentCompleteDate,
  });
};

