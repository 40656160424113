import { Table } from 'antd';

import { downloadOfferAttachmentFile } from 'api/offer/attachFiles';
import { downloadOfferHistoryAttachmentFile } from 'api/offer/history';
import { downloadStateFile } from 'api/review';

import useFileManagement from 'features/Contract/hooks/useFileManagement';

import { columnsDetailFileFunction } from './columnsTable';

type Props = {
  data: any;
  isCurrentOffer: boolean;
};

const ModalTable = (props: Props) => {
  const { data, isCurrentOffer } = props;
  const { downloadFile } = useFileManagement();

  const onDownloadOfferFile = (record: any) => {
    if (isCurrentOffer) {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: downloadOfferAttachmentFile,
      });
    } else {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: () =>
          downloadOfferHistoryAttachmentFile({
            offerId: data.offerFormId,
            offerType: record.docId,
            attachFileId: record.attachFileId,
          }),
      });
    }
  };

  const onDownloadStateFile = (record: any) => {
    if (isCurrentOffer) {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: downloadStateFile,
      });
    } else {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: () =>
          downloadOfferHistoryAttachmentFile({
            offerId: data.offerFormId,
            offerType: record.docId,
            attachFileId: record.attachFileId,
          }),
      });
    }
  };

  return (
    <>
      <Table
        columns={columnsDetailFileFunction(onDownloadOfferFile)}
        dataSource={data.offerAttachFiles}
        rowKey="offerFormId"
        size="middle"
        pagination={false}
      />
      {data.stateAttachFiles.length !== 0 && (
        <Table
          columns={columnsDetailFileFunction(onDownloadStateFile)}
          dataSource={data.stateAttachFiles}
          size="middle"
          pagination={false}
          rowKey={(value) => value.attachFileId}
        />
      )}
    </>
  );
};

export default ModalTable;
