import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';

import usePagination from 'hooks/usePagination';
import useAuth from 'hooks/useAuth';
import { useApi, TApiResponse } from 'hooks/useApi';
import { getContractReviewList } from 'api/contract/state';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import SearchReviewForm from '../../components/SearchReviewForm';
import ContractReviewListTable from '../../components/ContractReviewListTable';

import { getContractReviewListQuery } from '../../interfaces';
import WithHistoryModal from '../../components/WithHistoryModal';
import { subRoutePaths } from 'constants/routes';

const ListReviewContract = () => {
  const { user } = useAuth();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const { makeRequest, data = [], loading, pageable }: TApiResponse = useApi();
  const [queryParam, setQueryParam] = useState<getContractReviewListQuery>(
    {} as getContractReviewListQuery
  );

  const onGetContractReviewList = useCallback(
    (params: getContractReviewListQuery) => {
      makeRequest(() =>
        getContractReviewList({
          ...params,
        })
      );
    },
    [makeRequest]
  );

  useEffect(() => {
    if (user) {
      onGetContractReviewList({
        ...queryParam,
        referenceNo: user?.referenceNo,
        pageSize,
        currentPageNumber,
      });
    }
  }, [pageSize, currentPageNumber, queryParam, onGetContractReviewList, user]);

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle]);

  return (
    <div>
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <SearchReviewForm
        isShowResponUserFilter={false}
        onSearchReviewForm={(_values: any) => {
          setQueryParam(_values);
          setCurrentPageNumber(1);
        }}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <WithHistoryModal>
        {(props: any) => {
          return (
            <ContractReviewListTable
              data={data}
              loading={loading}
              pageSize={pageSize}
              currentPageNumber={currentPageNumber}
              paginationSetting={paginationSetting(pageable?.totalItems)}
              getEditPath={subRoutePaths.contractReviewEdit}
              {...props}
            />
          );
        }}
      </WithHistoryModal>
    </div>
  );
};

export default ListReviewContract;
