import { Button, Tag } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { OTHER_OPTION } from 'features/Offer/constant';
import { IFacilitiesType, IOfferFacilities } from 'features/Contract/interfaces';

type GetColumnsProps = {
  onEditContractFacility: (record: IFacilitiesType) => void;
  isDisabled: boolean;
};

const getServiceWithServType = (item: IOfferFacilities, index: number) => {
  let serviceName = item.servName;
  if (item.servName === OTHER_OPTION) serviceName = item.serviceDesc;
  return (
    <div key={index}>
      - {item.servTypeName && <Tag style={{ fontSize: 16 }}>{item.servTypeName}</Tag>} {serviceName}
    </div>
  );
};

export const getColumns = ({ onEditContractFacility, isDisabled }: GetColumnsProps) => {
  const columns: ColumnsType<IFacilitiesType> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '100px',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'ประเภทสิ่งอำนวยความสะดวก',
      dataIndex: 'facName',
      key: 'facName',
      width: '50%',
      render: (value, record) => {
        if (value === OTHER_OPTION) return <>{record.facDesc}</>;
        return <>{value}</>;
      },
    },
    {
      title: 'บริการ',
      dataIndex: 'contFacilitiesServes',
      key: 'contFacilitiesServes',
      render: (value) => {
        return value?.map((item: IOfferFacilities, index: any) => {
          return getServiceWithServType(item, index);
        });
      },
    },
    {
      title: isDisabled ? 'ดูรายละเอียด' : 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      width: '40px',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Button
              size="middle"
              icon={isDisabled ? <EyeOutlined /> : <EditOutlined />}
              onClick={() => {
                onEditContractFacility(record);
              }}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};
