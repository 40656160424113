export const getStringOrEmpty = (value: any) => {
  if (value) return value;
  else return '';
};

export const hasValue = (value: any): boolean => {
  if (value === undefined || value === null || value === '') {
    return false;
  }
  return true;
};

export const getName = (item: any) => {
  if (item) {
    return `${item.titleName} ${item.firstName} ${item.lastName}`.trim();
  }
  return '';
};

export const getErrorMessage = (error: any) => {
  return error?.errors?.join(',');
};
