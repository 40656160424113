import { useContext } from 'react';
import { ConractFacilitiesContext } from '../context/ContractFacilitiesContext';

const useContractFacilitiesContext = () => {
  const context = useContext(ConractFacilitiesContext);
  if (!context) throw new Error('Error produced by initial ConractFacilitiesContext');
  return context;
};

export default useContractFacilitiesContext;
