import { EditOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

type Props = {
  onOpenEditModal: Function;
  titleName: string;
  valueName: string;
};

export const columns = ({ onOpenEditModal, titleName, valueName }: Props) => {
  const columns: ColumnsType<{}> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      render: (_, __, index) => <>{Number(index) + 1}</>,
    },
    {
      title: titleName,
      dataIndex: valueName,
      key: valueName,
      width: '60%',
    },
    {
      title: 'สถานะการใช้งาน',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      align: 'center',
      render: (isDeleted: boolean) => <>{isDeleted ? 'ไม่ใช้งาน' : 'ใช้งาน'}</>,
    },
    {
      title: 'แก้ไข',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div
          onClick={() => {
            onOpenEditModal(record);
          }}
        >
          <EditOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
        </div>
      ),
    },
  ];
  return columns;
};
