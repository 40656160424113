const thai = {
  name: 'thai',
  startYear: 1,
  yearLength: 365,
  epoch: 1523229,
  century: 25,
  weekStartDayIndex: 1,
  getMonthLengths(isLeap) {
    return [31, isLeap ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  },
  isLeap(year) {
    const thaiYear = year - 543;
    return (thaiYear % 4 === 0 && thaiYear % 100 !== 0) || thaiYear % 400 === 0;
  },
  getLeaps(currentYear) {
    if (currentYear === 0) return;

    let year = currentYear > 0 ? 1 : -1;

    let leaps = [],
      condition = () => (currentYear > 0 ? year <= currentYear : currentYear <= year),
      increase = () => (currentYear > 0 ? year++ : year--);

    while (condition()) {
      if (this.isLeap(year)) leaps.push(year);

      increase();
    }

    return leaps;
  },
  getDayOfYear({ year, month, day }) {
    let monthLengths = this.getMonthLengths(this.isLeap(year));

    for (let i = 0; i < month.index; i++) {
      day += monthLengths[i];
    }

    return day;
  },
  getAllDays(date) {
    const { year } = date;

    return this.yearLength * (year - 1) + this.leapsLength(year) + this.getDayOfYear(date);
  },
  leapsLength(year) {
    return (((year - 544) / 4) | 0) + (-((year - 544) / 100) | 0) + (((year - 544) / 400) | 0);
  },
  guessYear(days, currentYear) {
    let year = ~~(days / 365.24);

    return year + (currentYear > 0 ? 1 : -1);
  },
};

export default thai;
