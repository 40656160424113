import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Form, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useApi, TApiResponse } from 'hooks/useApi';
import usePagination from 'hooks/usePagination';
import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';
import {
  saveContractAssignees,
  listContractAssign,
  improveAndAssignContract,
} from 'api/contract/assignees';
import { ListContractAssignQuery } from 'api/contract/interface';
import { dayJsformatDateObjToString } from 'utils/date';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import ReviewNewContractModal from 'features/Contract/components/ReviewNewContractModal';
import PrimaryButton from 'components/PrimaryButton';
import SearchReviewForm from '../../components/SearchReviewForm';
import ContractAssignmentListTable from '../../components/ContractAssignmentListTable';
import AssignmentModal from '../../components/AssignmentModal';
import WithHistoryModal from '../../components/WithHistoryModal';
import { getErrorMessage } from 'utils/string';

const ListAssignmentContract = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const [assigneesFormRef] = Form.useForm();
  const { error } = useDialogAlert();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const {
    isModalOpen: isOpenAssignmentModal,
    showModal: showAssignmentModal,
    handleOk: handleOkAssignmentModal,
    handleCancel: handleCancelAssignmentModal,
  } = useModal();
  const {
    isModalOpen: isOpenNewContractModal,
    showModal: showNewContractModal,
    handleCancel: handleCancelNewContractModal,
  } = useModal();
  const { makeRequest, data: contractAssignList = [], loading, pageable }: TApiResponse = useApi();
  const { makeRequest: _saveContractAssignees, loading: isSavingContractAssignesss }: TApiResponse =
    useApi();

  const [selectedConFormId, setSelectedConFormId] = useState<number | undefined>(undefined);
  const [isImproveAndAssign, setIsImproveAndAssign] = useState<boolean>(false);
  const [queryParam, setQueryParam] = useState<ListContractAssignQuery>(
    {} as ListContractAssignQuery
  );

  const onGetContractAssignList = useCallback(
    (params: ListContractAssignQuery) => {
      makeRequest(() =>
        listContractAssign({
          ...params,
        })
      );
    },
    [makeRequest]
  );

  const onSelectConFormId = useCallback(
    (conFormId: number, isImproveAndAssign?: boolean) => {
      setIsImproveAndAssign(isImproveAndAssign || false);
      setSelectedConFormId(conFormId);
      showAssignmentModal();
    },
    [showAssignmentModal]
  );

  const onUpdateAssignees = useCallback(
    async (onResetFormCallback: Function) => {
      const assignees = assigneesFormRef.getFieldValue('assignees');
      const assignDate = assigneesFormRef.getFieldValue('assignDate');
      const assignDateString = assignDate ? dayJsformatDateObjToString(assignDate) : null;
      if (selectedConFormId && assignDateString) {
        const data = {
          conFormId: selectedConFormId,
          officerIdList: assignees,
          assignDate: assignDateString,
          officerIds: assignees,
        };
        try {
          if (isImproveAndAssign) {
            await _saveContractAssignees(() => improveAndAssignContract(data), {
              isThrowError: true,
            });
          } else {
            await _saveContractAssignees(() => saveContractAssignees(data), {
              isThrowError: true,
            });
          }
          messageApi.open({
            type: 'success',
            content: 'บันทึกสำเร็จ',
          });
          onGetContractAssignList({
            ...queryParam,
            currentPageNumber,
            pageSize,
          });
          handleOkAssignmentModal();
        } catch (errorInfo: any) {
          onResetFormCallback();
          error({
            header: 'มีข้อผิดพลาดเกิดขึ้น',
            sub: getErrorMessage(errorInfo),
          });
          handleOkAssignmentModal();
        }
      } else {
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาตรวจสอบวันที่มอบหมายงานหรือ conFormId แล้วลองใหม่อีกครั้ง',
        });
      }
    },
    [
      handleOkAssignmentModal,
      assigneesFormRef,
      selectedConFormId,
      _saveContractAssignees,
      error,
      onGetContractAssignList,
      queryParam,
      currentPageNumber,
      pageSize,
      isImproveAndAssign,
      messageApi,
    ]
  );

  useEffect(() => {
    onGetContractAssignList({
      ...queryParam,
      currentPageNumber,
      pageSize,
    });
  }, [currentPageNumber, pageSize, onGetContractAssignList, queryParam]);

  useEffect(() => {
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle]);

  return (
    <div>
      {contextHolder}
      <SearchReviewForm
        onSearchReviewForm={(_values: any) => {
          setQueryParam(_values);
          setCurrentPageNumber(1);
        }}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <div className="content-box">
        <PrimaryButton icon={<PlusOutlined />} onClick={() => showNewContractModal()}>
          จัดทำสัญญาใหม่
        </PrimaryButton>
      </div>
      <ReviewNewContractModal
        isModalOpen={isOpenNewContractModal}
        handleCancel={handleCancelNewContractModal}
      />
      <AssignmentModal
        isModalOpen={isOpenAssignmentModal}
        handleCancel={handleCancelAssignmentModal}
        selectedConFormId={selectedConFormId}
        onUpdateAssignees={onUpdateAssignees}
        assigneesFormRef={assigneesFormRef}
        isSavingContractAssignesss={isSavingContractAssignesss}
      />
      <WithHistoryModal>
        {(props: any) => {
          return (
            <ContractAssignmentListTable
              data={contractAssignList}
              loading={loading}
              pageSize={pageSize}
              currentPageNumber={currentPageNumber}
              paginationSetting={paginationSetting(pageable?.totalItems)}
              onSelectConFormId={onSelectConFormId}
              {...props}
            />
          );
        }}
      </WithHistoryModal>
    </div>
  );
};

export default ListAssignmentContract;
