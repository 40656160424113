import { useEffect } from 'react';
import { Form, Row, Col, Input, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractReviewAction } from 'api/contract/state';

import { formLayout } from 'constants/form';
import { dayJsformatDateObjToString, getJSDateObj } from 'utils/date';

import { ThaiDatepickerComponent } from 'components';

import { IStepModalForm } from './interfaces';

const REVIEW_ACTION_STATE = 4;

const StepFormWithStatus = ({ form, stepData, isDisable, onUpdateOfferState }: IStepModalForm) => {
  const { makeRequest, data: actionOptionsList, loading }: TApiResponse = useApi();

  const onFinish = (values: any) => {
    let date = new Date(values.actionDate);
    let data = {
      // formStateId: stepData?.formStateId,
      // stateName: stepData?.stateName,
      stateCode: stepData?.stateCode,
      actionCode: values.actionCode,
      // actionName: values.actionName.label ? values.actionName.label : values.actionName,
      actionDate: dayJsformatDateObjToString(date),
      approvedNo: '',
      approvedDate: null,
      isCompleteState: false,
      isCurrentState: true,
    };
    onUpdateOfferState(data);
  };

  useEffect(() => {
    makeRequest(() => listContractReviewAction(REVIEW_ACTION_STATE));
  }, [makeRequest]);

  useEffect(() => {
    if (stepData) {
      let _stepData: any = { ...stepData };
      _stepData.actionDate = stepData.actionDate ? getJSDateObj(stepData.actionDate) : new Date();

      form.setFieldsValue({
        ..._stepData,
      });
    }
  }, [form, stepData]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      size="large"
      name="stepModalForm"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะสัญญา" name="stateName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label="วันที่บันทึก"
            name="actionDate"
            rules={[{ required: true, message: 'กรุณาเลือกวันที่บันทึก' }]}
          >
            <ThaiDatepickerComponent disabled={isDisable} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label="สถานะสัญญา"
            name="actionCode"
            rules={[{ required: true, message: 'กรุณาเลือกสถานะสัญญา' }]}
          >
            <Select
              loading={loading}
              placeholder="-- กรุณาระบุ --"
              disabled={isDisable}
              options={
                actionOptionsList
                  ? actionOptionsList.map((item: { actionCode: string; actionName: string }) => ({
                      value: item.actionCode,
                      label: item.actionName,
                    }))
                  : []
              }
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default StepFormWithStatus;
