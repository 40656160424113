import { Key, useEffect, useMemo, useState } from 'react';
import { Form, FormInstance } from 'antd';

import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';

import { IOfferFacility, IOfferServes } from '../interfaces';
import useValidation from './useValidation';
import { OTHER_OPTION } from '../constant';
import { getServiceDropdownValueByName, getServiceLabelTextDropdown, setTitleData } from '../utils';

type OfferFacilityHook = {
  currentOfferFacilities: IOfferFacility[];
  isFacilityModalOpen: boolean;
  facilityFormRef: FormInstance<any>;
  selectedFacilities: Key[];
  editingFacility: IOfferFacility | undefined;
  onSelectMultipleFacilities: (selectedRowKeys: Key[], _: any[]) => void;
  onDeleteFacilities: () => void;
  onAddNewFacility: () => void;
  onCloseFacilityModal: () => void;
  onSubmitNewFacility: () => void;
  onCheckDuplicateFacility: (option: any) => void;
  onEditFacility: (record: any) => void;
};

type useManageFacilityProps = {
  offerFacilities: IOfferFacility[] | undefined;
  currentOfferServices: IOfferServes[] | undefined;
};

const useManageFacility = ({
  offerFacilities,
  currentOfferServices,
}: useManageFacilityProps): OfferFacilityHook => {
  const [facilityFormRef] = Form.useForm();
  const { error } = useDialogAlert();
  const { validateIsServicesAtLeastOne } = useValidation();
  const [currentOfferFacilities, setCurrentOfferFacilities] = useState<IOfferFacility[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Key[]>([]);
  const [editingFacility, setEditingFacility] = useState<IOfferFacility | undefined>(undefined); // TODO: Need to consider about the edit case [2021-09-23
  const {
    isModalOpen: isFacilityModalOpen,
    showModal: showFacilityModal,
    handleCancel,
  } = useModal();

  const currentOfferFacilitiesWithKey = useMemo(() => {
    if (!offerFacilities) return [];
    return offerFacilities?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      };
    });
  }, [offerFacilities]);

  const onSelectMultipleFacilities = (selectedRowKeys: Key[], _: any[]) => {
    setSelectedFacilities(selectedRowKeys);
  };

  const onDeleteFacilities = () => {
    const newOfferFacilities = currentOfferFacilities.filter(
      (item) => !selectedFacilities.includes(item.key)
    );
    setCurrentOfferFacilities(newOfferFacilities);
    setSelectedFacilities([]);
  };

  const onAddNewFacility = () => {
    try {
      if (!currentOfferServices) throw new Error('กรุณาเลือกบริการก่อนดำเนินการต่อ');
      validateIsServicesAtLeastOne(currentOfferServices);
      showFacilityModal();
    } catch (e: any) {
      error({
        header: e.message || 'กรุณากรอกฟอร์มให้ครบถ้วน',
        sub: '',
      });
    }
  };

  const onCloseFacilityModal = () => {
    facilityFormRef.resetFields();
    setEditingFacility(undefined);
    handleCancel();
  };

  const onCheckDuplicateFacility = (option: any) => {
    const isDuplicateFacility = currentOfferFacilities.some((item) => item.facId === option.value);
    if (isDuplicateFacility) {
      return error(
        {
          header: 'ไม่สามารถเลือกสิ่งอำนวยความสะดวกซ้ำกันได้',
          sub: '',
        },
        () => {
          facilityFormRef.setFieldsValue({ facId: undefined });
        }
      );
    }
  };

  const onEditFacility = (record: any) => {
    showFacilityModal();
    setEditingFacility(record);
    facilityFormRef.setFieldsValue({
      facId: {
        value: record.facId,
        label: record.facName,
      },
      facDesc: record.facDesc,
      raoServId: record.offerFacilitiesServes.map((item: any) => {
        return {
          value: getServiceDropdownValueByName(item),
          label: getServiceLabelTextDropdown(item),
          title: setTitleData(item),
        };
      }),
    });
  };

  const onSubmitNewFacility = async () => {
    try {
      // NOTE: Validate form
      await facilityFormRef.validateFields();

      // NOTE: Compose offer facility data
      let newOfferFacilities: IOfferFacility[];
      const newFacility = facilityFormRef.getFieldsValue();

      const formattedNewOfferFacilities = {
        facId: newFacility?.facId.value,
        facName: newFacility?.facId.label,
        facDesc: newFacility?.facDesc || '',
        offerFacilitiesServes: newFacility?.raoServId.map((item: any) => {
          return {
            raoServId: item?.title?.value,
            raoServName:
              item?.title?.label === item?.title?.desc ? OTHER_OPTION : item?.title?.label,
            raoServiceDesc: item?.title?.desc,
            servTypeName: item?.title?.servTypeName,
          };
        }),
      };

      // NOTE: Check case whether edit or add new
      if (editingFacility) {
        // NOTE: Edit
        newOfferFacilities = currentOfferFacilities.map((item) => {
          if (item.key === editingFacility.key) {
            return {
              key: item.key,
              ...formattedNewOfferFacilities,
            };
          }
          return item;
        });
      } else {
        // NOTE: Add new
        newOfferFacilities = [
          ...currentOfferFacilities,
          { key: currentOfferFacilities.length + 1, ...formattedNewOfferFacilities },
        ];
      }

      setCurrentOfferFacilities(newOfferFacilities);
      setEditingFacility(undefined);
      onCloseFacilityModal();
    } catch (e: any) {
      error({
        header: e.message || 'กรุณากรอกฟอร์มให้ครบถ้วน',
        sub: '',
      });
    }
  };

  useEffect(() => {
    setCurrentOfferFacilities(currentOfferFacilitiesWithKey);
  }, [currentOfferFacilitiesWithKey]);

  return {
    currentOfferFacilities,
    isFacilityModalOpen,
    facilityFormRef,
    selectedFacilities,
    editingFacility,
    onSelectMultipleFacilities,
    onDeleteFacilities,
    onAddNewFacility,
    onCloseFacilityModal,
    onSubmitNewFacility,
    onCheckDuplicateFacility,
    onEditFacility,
  };
};

export default useManageFacility;
