import { useCallback, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Col, Form, Row } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import usePagination from 'hooks/usePagination';
import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';

import { listOfferPublishUrl, updateOfferPublicUrl } from 'api/offer/publishUrl';
import { ListOfferPublishUrlQuery, OfferPublicUrlData } from 'api/offer/interface';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import SearchForm from '../components/SearchForm';
import TableResultList from '../components/TableResultList';
import PublishLinkModal from '../components/PublishLinkModal';
import { IOfferPublishUrl } from '../interfaces';
import { columns } from './columns';

const OfferPublishUrl = () => {
  const [formRef] = Form.useForm();
  const { error } = useDialogAlert();
  const [modalFormRef] = Form.useForm();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();

  const [queryParam, setQueryParam] = useState<any>(undefined);
  const [selectedRecord, setSelectedRecord] = useState<IOfferPublishUrl | undefined>(undefined);

  const { makeRequest, data, loading, pageable }: TApiResponse = useApi();
  const { makeRequest: _updateOfferPublicUrl, loading: isUpdating }: TApiResponse = useApi();

  const onSelectRecord = useCallback(
    (record: IOfferPublishUrl) => {
      setSelectedRecord(record);
      showModal();
    },
    [setSelectedRecord, showModal]
  );

  const onCloseModal = useCallback(() => {
    handleCancel();
    setSelectedRecord(undefined);
    modalFormRef.resetFields();
  }, [handleCancel, modalFormRef]);

  const onListOfferPublicUrl = useCallback(
    (param: ListOfferPublishUrlQuery): void => {
      try {
        makeRequest(() =>
          listOfferPublishUrl({
            ...param,
          })
        );
      } catch (e) {
        console.error(e);
        error({
          header: 'มีข้อผิดพลาดเกิดขึ้น',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [makeRequest, error]
  );

  const onRequestUpdateOfferPublicUrl = useCallback(
    async (data: OfferPublicUrlData): Promise<void> => {
      try {
        await _updateOfferPublicUrl(() => updateOfferPublicUrl(data));
      } catch (e) {
        error({
          header: 'ไม่สามารถอัพเดทได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [_updateOfferPublicUrl, error]
  );

  const onUpdateLinks = useCallback(
    async (values: any): Promise<void> => {
      if (selectedRecord?.offerFormId && values.offerFormPublicUrl) {
        const data: OfferPublicUrlData = {
          offerFormId: selectedRecord?.offerFormId,
          offerFormPublicUrl: values.offerFormPublicUrl,
        };
        await onRequestUpdateOfferPublicUrl(data);
        if (queryParam) {
          onListOfferPublicUrl({ ...queryParam, pageSize, currentPageNumber });
        } else {
          onListOfferPublicUrl({ pageSize, currentPageNumber });
        }
        onCloseModal();
      } else {
        error({
          header: 'ไม่พบข้อมูลข้อเสนอ',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [
      currentPageNumber,
      error,
      onRequestUpdateOfferPublicUrl,
      onListOfferPublicUrl,
      pageSize,
      queryParam,
      selectedRecord,
      onCloseModal,
    ]
  );

  useEffect(() => {
    if (queryParam) {
      onListOfferPublicUrl({ ...queryParam, pageSize, currentPageNumber });
    } else {
      onListOfferPublicUrl({ pageSize, currentPageNumber });
    }
  }, [onListOfferPublicUrl, pageSize, currentPageNumber, queryParam]);

  useEffect(() => {
    setHeaderTitle('ลิงก์เปิดเผยข้อเสนอ');
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <PublishLinkModal
        modalTitle="Link Web เปิดเผยข้อเสนอ"
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        modalFormRef={modalFormRef}
        isUpdating={isUpdating}
        onUpdateLink={onUpdateLinks}
        linkFieldName="offerFormPublicUrl"
        selectedRecord={selectedRecord}
      />
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HeaderTitle>ค้นหาโดย</HeaderTitle>
        </Col>
        <Col span={24}>
          <SearchForm
            formRef={formRef}
            onSearch={(values: any) => {
              setQueryParam(values);
              setCurrentPageNumber(1);
              onListOfferPublicUrl({ ...values, pageSize, currentPageNumber: 1 });
            }}
          />
        </Col>
        <Col span={24}>
          <HeaderTitle>ผลการค้นหา</HeaderTitle>
        </Col>
        <Col span={24}>
          <TableResultList
            rowKey="offerFormId"
            data={data}
            loading={loading}
            paginationSetting={paginationSetting(pageable?.totalItems)}
            columns={columns({
              page: currentPageNumber,
              pageSize,
              onSelectRecord,
            })}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OfferPublishUrl;
