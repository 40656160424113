import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { mainRoutePaths } from 'constants/routes';

const useSideMenu = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [selectedMenu, setSelectedMenu] = useState<string>('');
  const [openedSubMenu, setOpenedSubMenu] = useState<string[]>([]);

  const getParentRoutes = (path = '') => {
    const parts = path.split('/').filter(Boolean); // Split path and remove empty parts

    const routes = [];
    let currentRoute = '';

    for (let i = 0; i < parts.length; i++) {
      currentRoute += '/' + parts[i]; // Append the current part to the current route
      routes.push(currentRoute); // Add the current route to the list of routes
    }

    return routes;
  };

  const onOpenChange = useCallback((openKeys: string[]) => {
    setOpenedSubMenu(openKeys);
  }, []);

  const isMenuRoute = useMemo(() => {
    const routes = Object.values(mainRoutePaths);
    return routes.some((route) => pathname === route);
  }, [pathname]);

  const onSetOpenedMenuFromPath = useCallback(() => {
    if (isMenuRoute) {
      let keyRoutes = getParentRoutes(pathname);
      setOpenedSubMenu(keyRoutes);
    }
  }, [pathname, isMenuRoute]);

  const onSelectMenu = useCallback(
    ({ key }: { key: string }) => {
      navigate(key);
    },
    [navigate]
  );

  const onSetSelectedMenuFromPath = useCallback(() => {
    if (isMenuRoute) {
      setSelectedMenu(pathname);
    }
  }, [isMenuRoute, pathname]);

  useEffect(() => {
    onSetOpenedMenuFromPath();
    onSetSelectedMenuFromPath();
  }, [onSetOpenedMenuFromPath, onSetSelectedMenuFromPath]);

  return { selectedMenu, openedSubMenu, onSelectMenu, onOpenChange };
};

export default useSideMenu;
