import { useCallback } from 'react';
import { Table, UploadFile } from 'antd';

import { uploadAttachmentFile, deleteUploadedFile, downloadAttachmentFile } from 'api/contract';

import { FileListDataType } from 'features/Contract/interfaces';
import { getFileTableColumn } from './columns';

type UploadedFilesListTableProps = {
  isLoading: boolean;
  isDisabled: boolean;
  onRemoveFile: Function;
  downloadFile: Function;
  fileList: FileListDataType[];
  handleUpload: Function;
  setCurrentUploadFile: React.Dispatch<React.SetStateAction<UploadFile<any> | undefined>>;
  conFormId: number | string | undefined;
};

const UploadedFilesListTable = ({
  isLoading,
  isDisabled,
  onRemoveFile,
  downloadFile,
  fileList,
  handleUpload,
  setCurrentUploadFile,
  conFormId,
}: UploadedFilesListTableProps) => {
  const onUploadFile = useCallback(
    async (record: any) => {
      if (!conFormId) return;
      await handleUpload({
        docId: record.docId,
        docName: record.docName,
        required: true,
        api: uploadAttachmentFile,
        id: conFormId,
      });
    },
    [conFormId, handleUpload]
  );

  const onRemoveRecordFile = useCallback(
    (record: any) => {
      onRemoveFile({ fileId: record.attachFileId, api: deleteUploadedFile });
    },
    [onRemoveFile]
  );

  const onDownloadRecordFile = useCallback(
    (record: any) => {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: downloadAttachmentFile,
      });
    },
    [downloadFile]
  );

  return (
    <>
      <Table
        bordered
        size="small"
        loading={isLoading}
        columns={getFileTableColumn({
          isDisabled,
          onRemoveRecordFile,
          onDownloadRecordFile,
          onUploadFile,
          setCurrentUploadFile,
        })}
        dataSource={fileList}
        rowKey={(value) => {
          return value?.index || value.attachFileName;
        }}
        pagination={false}
      />
    </>
  );
};

export default UploadedFilesListTable;
