import { EditOutlined, FileOutlined, RollbackOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { IContractData } from 'features/Contract/interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';
import { getName } from 'utils/string';
import { getTableIndex } from 'utils/table';

const IMPROVEABLE_STATUS = '17';

type ColumnsProps = {
  page: number;
  pageSize: number;
  onSelectConFormId: Function;
  onOpenHistoryModal: Function;
};

export const columns = ({
  page,
  pageSize,
  onSelectConFormId,
  onOpenHistoryModal,
}: ColumnsProps) => {
  const columns: ColumnsType<IContractData> = [
    {
      title: 'ลำดับที่',
      dataIndex: 'orderNo',
      key: 'orderNo',
      align: 'center',
      render: (_, __, index) => <>{getTableIndex(page, pageSize, index)}</>,
    },
    {
      title: 'ประเภทสัญญา',
      dataIndex: 'offerFormTypeName',
      key: 'offerFormTypeName',
      align: 'center',
      // width: 150,
    },
    {
      title: 'รูปแบบสัญญา',
      dataIndex: 'contractGroupName',
      key: 'contractGroupName',
      align: 'center',
      // width: 100,
    },
    {
      title: 'สถานะของสัญญา',
      dataIndex: 'contStatusName',
      key: 'contStatusName',
    },
    {
      title: 'ผู้ประกอบการ',
      dataIndex: 'owner',
      key: 'owner',
      // width: 200,
      render: (value) => value?.operName,
    },
    {
      title: 'ทำสัญญาร่วมกับบริษัท',
      dataIndex: 'renter',
      key: 'operName',
      align: 'center',
      // width: 200,
      render: (value) => value?.operName,
    },
    {
      title: 'วันที่จัดทำสัญญา',
      dataIndex: 'contractDate',
      key: 'contractDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : ''),
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      key: 'contractNo',
      align: 'center',
    },
    {
      title: 'เลขที่/วันที่หนังสือนำส่งสัญญา',
      dataIndex: 'formDate',
      key: 'formDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : ''),
    },
    {
      title: 'สถานะการจัดทำสัญญา',
      dataIndex: 'formStatusName',
      key: 'formStatusName',
      align: 'center',
      width: 250,
    },
    {
      title: 'วันที่มอบหมายงาน',
      dataIndex: 'assignDate',
      key: 'assignDate',
      align: 'center',
      render: (value) => (value ? dayJsformatDateToBCYear(value) : ''),
    },
    {
      title: 'ผู้ได้รับมอบหมาย',
      dataIndex: 'contResponsibilityUsers',
      key: 'contResponsibilityUsers',
      // width: 300,
      render: (value) => {
        return value?.map((item: any) => getName(item?.officer)).join(', ');
      },
    },
    {
      title: 'จำนวนครั้งที่แก้ไข',
      dataIndex: 'numOfImprove',
      key: 'numOfImprove',
      align: 'center',
    },
    {
      title: 'ประวัติรายการ',
      dataIndex: 'history',
      key: 'history',
      align: 'center',
      render: (_, record) => (
        <div style={{ cursor: 'pointer' }} onClick={() => onOpenHistoryModal(record)}>
          <FileOutlined className="file-outlined-css" />
        </div>
      ),
    },
    {
      title: 'การมอบหมายงาน',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <div
          onClick={() => {
            onSelectConFormId(record.conFormId);
          }}
        >
          <EditOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
        </div>
      ),
    },
    {
      title: 'แก้ไขสัญญาและมอบหมายงาน',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        if (String(record?.formStatus) === IMPROVEABLE_STATUS)
          return (
            <div
              onClick={() => {
                onSelectConFormId(record.conFormId, true);
              }}
            >
              <RollbackOutlined style={{ fontSize: '25px', cursor: 'pointer' }} />
            </div>
          );
      },
    },
  ];
  return columns;
};
