/* eslint-disable */

import { Col, Form, Row, Select } from 'antd';
import { ButtonComponent } from 'components';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import style from './SearchForm.module.css';
import { useEffect, useState } from 'react';
import { FormInstance } from 'antd/es/form/hooks/useForm';

interface SearchFormProps {
  onSearch: () => void;
  form: FormInstance;
}

const { Option } = Select;
const SearchForm = ({ onSearch, form }: SearchFormProps): JSX.Element => {
  const [years, setYears] = useState<any[]>();

  const filterOption = (input: string, option?: { label: string; value: number }) => {
    return option?.value.toString().includes(input) || false;
  };

  useEffect(() => {
    const listYear: any[] = [];
    for (let i = 2539; i <= new Date().getFullYear() + 544; i++) {
      listYear.push({
        value: i,
        label: i,
      });
    }
    setYears(listYear);
  }, []);
  return (
    <div style={{ padding: '20px' }}>
      <div className='padding-content'>
        <Form form={form} style={{ width: '100%' }}>
          <Row gutter={[16, 16]}>
            <Col flex='auto'>
              <Form.Item name='year' label='ปี พ.ศ.'>
                <Select
                  showSearch
                  placeholder='ระบุปี พ.ศ.'
                  onChange={() => {
                  }}
                  allowClear
                  options={years}
                  filterOption={filterOption}
                ></Select>
              </Form.Item>
            </Col>

            <Col flex='auto'>
              <Form.Item name='quarter' label='ไตรมาสที่ :'>
                <Select placeholder='ระบุไตรมาส' onChange={() => {
                }} allowClear>
                  <Option value='1'>1</Option>
                  <Option value='2'>2</Option>
                  <Option value='3'>3</Option>
                  <Option value='4'>4</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col flex='300px'>
              <div className={style.divButtonGroup}>
                <ButtonComponent htmlType='submit' onClick={onSearch}>
                  <SearchOutlined />
                  ค้นหาข้อมูล
                </ButtonComponent>
                <ButtonComponent
                  htmlType='reset'
                  onClick={() => {
                    form.resetFields(['quarter', 'year']);
                  }}
                >
                  <ClearOutlined />
                  ล้างเงื่อนไข
                </ButtonComponent>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default SearchForm;
