import { useContext } from 'react';
import { OfferFacilitiesContext } from '../contexts/OfferFacilitiesContext';

const useOfferFacilititesContext = () => {
  const context = useContext(OfferFacilitiesContext);
  if (!context)
    throw new Error('OfferFacilitiesContext must be use inside OfferFacilitiesProvider');
  return context;
};

export default useOfferFacilititesContext;
