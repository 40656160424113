import { Col, Form, Row } from 'antd';
import { ClearOutlined, SaveOutlined } from '@ant-design/icons';

import { formLayout } from 'constants/form';

import { ThaiDatepickerComponent } from 'components';
import PrimaryButton from 'components/PrimaryButton';
import OfferTypeDropdown from 'features/OfferConsideration/components/OfferTypeDropdown';

import { dayJsformatDateObjToString12PM } from 'utils/date';
import useDialogAlert from 'hooks/useDialogAlert';

type ReportProps = {
  formRef: any;
  onSubmit: Function;
  submitButtonLabel: string;
  isRequiredOfferType?: boolean;
  isRequiredStartDate?: boolean;
  isRequiredEndDate?: boolean;
  dateLabel?: string;
  TypeDropdown?: any;
};

const ReportForm = ({
  formRef,
  onSubmit,
  submitButtonLabel,
  dateLabel,
  TypeDropdown,
  isRequiredOfferType = false,
  isRequiredStartDate = false,
  isRequiredEndDate = false,
}: ReportProps) => {
  const { error } = useDialogAlert();

  const onValidateForm = async () => {
    try {
      await formRef.validateFields();
    } catch (e) {
      throw new Error('กรุณากรอกข้อมูลให้ครบถ้วน');
    }
  };

  const onFinish = async () => {
    try {
      await onValidateForm();
      const values = formRef.getFieldsValue();
      let _values = { ...values };
      _values.startDate = _values.startDate
        ? dayJsformatDateObjToString12PM(_values.startDate)
        : null;
      _values.endDate = _values.endDate ? dayJsformatDateObjToString12PM(_values.endDate) : null;
      onSubmit(_values);
    } catch (e: any) {
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  const onReset = () => {
    formRef.resetFields();
  };

  return (
    <div className="content-box">
      <Form labelWrap name="summaryReportForm" size="large" form={formRef} {...formLayout}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {TypeDropdown ? TypeDropdown : <OfferTypeDropdown isRequired={isRequiredOfferType} />}
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label={dateLabel ? dateLabel : 'มติ กสทช. วันที่'}
              name="startDate"
              rules={
                isRequiredStartDate ? [{ required: true, message: 'กรุณาเลือกวันที่' }] : undefined
              }
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="ถึงวันที่"
              name="endDate"
              rules={
                isRequiredEndDate ? [{ required: true, message: 'กรุณาเลือกวันที่' }] : undefined
              }
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <PrimaryButton icon={<SaveOutlined />} onClick={() => onFinish()}>
              {submitButtonLabel}
            </PrimaryButton>
          </Col>
          <Col>
            <PrimaryButton icon={<ClearOutlined />} onClick={() => onReset()}>
              ล้างเงื่อนไข
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ReportForm;
