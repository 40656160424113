import {
  createOfferReviewStatusReport,
  downloadOfferReviewStatusReportByJobId,
  getOfferReviewStatusReportByJobId,
  listOfferReviewStatusReport,
} from 'api/report';
import GenericReport from 'components/GenericReport';

const OfferReviewStatusReport = () => {
  return (
    <>
      <GenericReport
        pageTitle="รายงานสถานะการพิจารณาข้อเสนอ"
        dateLabel="วันที่ผู้ประกอบการนำส่ง"
        createReportApi={createOfferReviewStatusReport}
        listReportApi={listOfferReviewStatusReport}
        getReportByJobIdApi={getOfferReviewStatusReportByJobId}
        downloadReportByJobIdApi={downloadOfferReviewStatusReportByJobId}
        isRequiredStartDate={true}
        isRequiredEndDate={true}
      />
    </>
  );
};

export default OfferReviewStatusReport;
