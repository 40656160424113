import { ReactNode } from 'react';

import { getOfferById } from 'api/offer';
import { downloadOfferAttachmentFile } from 'api/offer/attachFiles';

import { OfferDataProvider } from 'features/Offer/contexts/OfferDataContext';
import { OfferFacilititesProvider } from 'features/Offer/contexts/OfferFacilitiesContext';
import { OfferServicesProvider } from 'features/Offer/contexts/OfferServicesContext';

import EditOfferWrapper from 'features/Offer/components/EditOfferWrapper';

type EditOfferForReviewProps = {
  OfferReviewComponent: ReactNode;
};

const EditOfferForReview = ({ OfferReviewComponent }: EditOfferForReviewProps) => {
  return (
    <OfferDataProvider
      getOfferDataAPI={getOfferById}
      downloadOfferFileAPI={downloadOfferAttachmentFile}
      isReviewPage={true}
    >
      <OfferServicesProvider>
        <OfferFacilititesProvider>
          <EditOfferWrapper OfferReviewComponent={OfferReviewComponent} />
        </OfferFacilititesProvider>
      </OfferServicesProvider>
    </OfferDataProvider>
  );
};

export default EditOfferForReview;
