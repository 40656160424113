import { useEffect } from 'react';

import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listOfferDocType } from 'api/master';

type DocTypeDropdownProps = {
  isDisabled: boolean;
  offerType: string | undefined;
  setIsOpenFileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDocTypeOption: React.Dispatch<React.SetStateAction<any>>;
};

const DocTypeDropdown = ({
  isDisabled,
  offerType,
  setSelectedDocTypeOption,
  setIsOpenFileDialog,
}: DocTypeDropdownProps) => {
  const { makeRequest, data: docTypeList, loading: isLoadingDocType }: TApiResponse = useApi();

  useEffect(() => {
    if (offerType) makeRequest(() => listOfferDocType(offerType));
  }, [makeRequest, offerType]);

  return (
    <>
      <Form.Item
        colon
        label="เลือกกลุ่มเอกสาร"
        name="docId"
        rules={[{ required: true, message: 'กรุณาเลือกกลุ่มเอกสาร' }]}
      >
        <Select
          loading={isLoadingDocType}
          placeholder="-- กรุณาระบุ --"
          size="large"
          disabled={isDisabled}
          onChange={(_, option: any) => {
            setSelectedDocTypeOption(option);
            setIsOpenFileDialog(true);
          }}
          options={
            docTypeList
              ? docTypeList.map((item: any) => ({
                  value: item.docId,
                  label: item.docName,
                  required: item.required,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default DocTypeDropdown;
