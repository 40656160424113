import { Space, Row, Col, Button } from 'antd';

type StepWithEmailFileProps = {
  isDisable: boolean | undefined;
};

const StepWithEmailFile = ({ isDisable }: StepWithEmailFileProps) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Space>
            <label>ไฟล์แนบ :</label>
            <Button size="small" disabled={isDisable}>
              -- กรุณาคลิกเพื่อดูไฟล์แนบ --
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithEmailFile;
