import { Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';
import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';

const RDROActionButton = () => {
  const { isDisabled } = useOfferDataContext();
  const { onDeleteFacilities, onAddNewFacility } = useOfferFacilititesContext();

  return (
    <div>
      <Space>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<PlusOutlined />}
          onClick={onAddNewFacility}
        >
          เพิ่มสิ่งอำนวยความสะดวก
        </PrimaryButton>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<DeleteOutlined />}
          onClick={onDeleteFacilities}
        >
          ลบสิ่งอำนวยความสะดวก
        </PrimaryButton>
      </Space>
    </div>
  );
};

export default RDROActionButton;
