import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import { downloadStateFile } from 'api/review';

import { FileListDataType } from '.';

type FileTableColumnProps = {
  isDisable: boolean;
  handleRemoveFile: Function;
  downloadFile: Function;
  currentPageNumber: number;
  pageSize: number;
};

export const getFileTableColumn = ({
  isDisable,
  handleRemoveFile,
  downloadFile,
  currentPageNumber,
  pageSize,
}: FileTableColumnProps) => {
  const columns: ColumnsType<FileListDataType> = [
    {
      title: 'ดาวน์โหลดเอกสาร',
      key: 'download',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Button
          icon={<DownloadOutlined />}
          onClick={() => {
            downloadFile({
              fileId: record.attachFileId,
              fileName: record.attachFileName,
              api: downloadStateFile,
            });
          }}
        />
      ),
    },
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: 80,
      render: (_, __, index) => <>{pageSize * currentPageNumber + index + 1}</>,
    },
    {
      title: 'กลุ่มเอกสาร',
      dataIndex: 'docName',
      key: 'docName',
    },
    {
      title: 'รายการเอกสาร',
      dataIndex: 'attachFileName',
      key: 'attachFileName',
    },

    {
      title: 'ลบ',
      key: 'delete',
      align: 'center',
      width: 50,
      render: (_, record) => (
        <Button
          type="primary"
          danger
          icon={<DeleteOutlined />}
          disabled={isDisable}
          onClick={() => handleRemoveFile(record.attachFileId)}
        />
      ),
    },
  ];
  return columns;
};
