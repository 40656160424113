import { HeaderTitleComponent } from 'components';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';

import RISOService from '../RISOServiceFacility';
import RIOServiceFacility from '../RIOServiceFacility';
import RDROServiceFacility from '../RDROServiceFacility';

import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RISO,
} from 'constants/offerFormType';

const OfferServices = () => {
  const { offerType } = useOfferDataContext();

  const getOfferServiceByOfferType = () => {
    switch (offerType) {
      case OFFER_TYPE_RISO:
        return <RISOService />;
      case OFFER_TYPE_RDRO:
        return <RDROServiceFacility />;
      case OFFER_TYPE_RIO:
      case OFFER_TYPE_RAO:
        return <RIOServiceFacility />;
      default:
        return <div>ประเภทข้อเสนอไม่ถูกต้อง กรุณาตรวจสอบข้อมูล</div>;
    }
  };

  return (
    <div style={{ marginTop: 15 }}>
      <HeaderTitleComponent>รายละเอียดการจัดทำข้อเสนอ {offerType}</HeaderTitleComponent>
      {getOfferServiceByOfferType()}
    </div>
  );
};

export default OfferServices;
