import { Form } from 'antd';
import {
  deleteStateFile,
  downloadStateFile,
  listStateFileByFormStateId,
  uploadStateFile,
} from 'api/contract/state';
import useFileManagement from 'features/Contract/hooks/useFileManagement';
import { TApiResponse, useApi } from 'hooks/useApi';
import { useCallback, useEffect, useState } from 'react';

type UploadEmailFileProps = {
  stateId: number | undefined;
  refTrigger: number;
};

const useUploadEmailFile = ({ stateId, refTrigger }: UploadEmailFileProps) => {
  const [uploadFormRef] = Form.useForm();
  const {
    fileList,
    isUploadingFile,
    isDeletingFile,
    handleUpload,
    setCurrentUploadFile,
    onRemoveFile,
    downloadFile,
    setFileList,
  } = useFileManagement();
  const {
    makeRequest: _listStateFile,
    data: uploadedFileList,
    loading: isLoadingFileList,
  }: TApiResponse = useApi();

  const [docId, setDocId] = useState<number | undefined>(undefined);
  const [docName, setDocName] = useState<string>('');
  const [isOpenFileDialog, setIsOpenFileDialog] = useState(false);

  const onValidateAtleastOneFile = useCallback(async () => {
    const newFileIndex = fileList.findIndex((file) => {
      return !file?.contStateNotifyEmail?.numberOfSent;
    });
    if (newFileIndex === -1) throw new Error('กรุณาอัพโหลดไฟล์อย่างน้อย 1 ไฟล์');
    return;
  }, [fileList]);

  const onChangeDocType = (value: any, option: any) => {
    setDocId(value);
    setDocName(option?.label);
    setIsOpenFileDialog(true);
  };

  const onUploadFile = useCallback(async () => {
    if (docId && stateId) {
      await handleUpload({
        docId,
        docName,
        required: false,
        id: stateId,
        api: uploadStateFile,
      });
      uploadFormRef.setFieldValue('docId', undefined);
      setDocId(undefined);
    }
  }, [docId, docName, handleUpload, uploadFormRef, stateId]);

  const onValidateDocType = useCallback(() => {
    try {
      uploadFormRef.validateFields(['docId']);
    } catch (error) {
      console.error(error);
    }
  }, [uploadFormRef]);

  const onRemoveRecordFile = useCallback(
    (record: any) => {
      onRemoveFile({
        fileId: record.attachFileId,
        api: deleteStateFile,
        // getData: (_: any) => {
        //   return fileList.filter((file) => file.attachFileId !== record.attachFileId);
        // },
      });
    },
    [onRemoveFile]
  );

  const onDownloadRecordFile = useCallback(
    (record: any) => {
      downloadFile({
        fileId: record.attachFileId,
        fileName: record.attachFileName,
        api: downloadStateFile,
      });
    },
    [downloadFile]
  );

  useEffect(() => {
    if (stateId && refTrigger !== 0) {
      // NOTE refTrigger !== 0 is to prevent useEffect from running on initial render
      // refTrigger is used here because stateId is not gonna change and it won't fetch new data.
      _listStateFile(() => listStateFileByFormStateId(stateId));
    }
  }, [stateId, _listStateFile, refTrigger]);

  useEffect(() => {
    setFileList(uploadedFileList);
  }, [uploadedFileList, setFileList]);

  return {
    uploadFormRef,
    onChangeDocType,
    isOpenFileDialog,
    onUploadFile,
    setCurrentUploadFile,
    onValidateDocType,
    fileList,
    isUploadingFile,
    isDeletingFile,
    isLoadingFileList,
    onRemoveRecordFile,
    onDownloadRecordFile,
    onValidateAtleastOneFile,
  };
};

export default useUploadEmailFile;
