import { useEffect, useMemo, useState } from 'react';
import { Col, Divider, Form, Input, Row, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';

import PrimaryButton from 'components/PrimaryButton';
import OfferServiceDropdown from 'features/Offer/components/OfferServiceDropdown';
import OfferServiceTypeDropdown from 'features/Offer/components/OfferServiceTypeDropdown';

import { OFFER_TYPE_RIO } from 'constants/offerFormType';

type ServiceFormProps = {
  isDisabled: boolean;
};

const ServiceForm = ({ isDisabled }: ServiceFormProps) => {
  const { offerType } = useOfferDataContext();
  const {
    isSelectedOtherRIOService,
    selectedService,
    selectedServType,
    selectedServiceOption,
    onAddNewRIOServePay,
    onChangeRIOService,
    onChangeRIOServType,
  } = useOfferServicesContext();
  const [contServeOtherInputValue, setContServeOtherInputValue] = useState<string | undefined>(
    undefined
  );

  const isDisabledOtherInput = useMemo(() => {
    if (offerType === OFFER_TYPE_RIO) {
      // กรณีเป็น RIO
      if (isSelectedOtherRIOService) {
        // กรณีเลือกเป็น บริการอื่นๆ
        if (isDisabled) {
          // กรณีเป็น isDisabled คือ form อยู่ในสถานะ disabled แปลว่ากรอกครบแล้ว ดังนั้นต้องเพิ่มค่าตอบแทนได้ จึง return false
          return false;
        }
        return !selectedServiceOption || !contServeOtherInputValue || !selectedServType; // กรณีเป็น บริการอื่นๆ ใน form ต้องมีค่าครบ 3 input คือ  ประเภทบริการ, บริการ, บริการ กรณีอื่นๆระบุ
      }
      return !selectedServiceOption || !selectedServType; // กรณีเป็นไม่ใช่บริการอื่นๆ เช็คแค่ ประเภทบริการ กับ บริการ เท่านั้น
    } else {
      if (isSelectedOtherRIOService) {
        if (isDisabled) return false;
        return !selectedServiceOption || !contServeOtherInputValue;
      } else return !selectedServiceOption;
    }
  }, [
    offerType,
    selectedServiceOption,
    contServeOtherInputValue,
    selectedServType,
    isSelectedOtherRIOService,
    isDisabled,
  ]);

  useEffect(() => {
    if (selectedService?.raoServiceDesc) {
      setContServeOtherInputValue(selectedService.raoServiceDesc);
    }
  }, [selectedService]);

  return (
    <Row gutter={[16, 16]}>
      {offerType === OFFER_TYPE_RIO && (
        <Col span={24}>
          <OfferServiceTypeDropdown
            offerType={offerType}
            onChangeOption={(option: any) => {
              setContServeOtherInputValue(undefined);
              onChangeRIOServType(option);
            }}
          />
        </Col>
      )}
      <Col span={24}>
        <OfferServiceDropdown
          offerType={offerType}
          servType={selectedServType?.value}
          onChangeOption={onChangeRIOService}
        />
      </Col>
      {isSelectedOtherRIOService && (
        <Col span={24}>
          <Form.Item
            label="บริการ กรณีอื่นๆระบุ"
            name="raoServiceDesc"
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: 'กรุณาระบุอื่นๆ' }]}
          >
            <Input
              onChange={(value: any) => {
                setContServeOtherInputValue(value?.target?.value);
              }}
            />
          </Form.Item>
        </Col>
      )}
      {isDisabled && (
        <Col span={19} offset={5}>
          <Tag color="gold">
            {`*กรุณาลบอัตราค่าตอบแทน หรือ บริการในสิ่งอำนวยความสะดวกก่อนแก้ไขบริการ`}
          </Tag>
        </Col>
      )}
      <Divider style={{ margin: '5px 0' }} />
      <Col span={24}>
        <PrimaryButton
          disabled={isDisabledOtherInput}
          size="middle"
          icon={<PlusOutlined />}
          onClick={onAddNewRIOServePay}
        >
          เพิ่มอัตราค่าตอบแทน
        </PrimaryButton>
      </Col>
    </Row>
  );
};

export default ServiceForm;
