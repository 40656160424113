import DatePicker from 'react-multi-date-picker';

import css from './ThaiDatePicker.module.css';
import thai_th from './thai-th';
import thai from './thai';

const ThaiDatepickerComponent = (props: any) => {
  return (
    <DatePicker
      containerStyle={{ width: '100%' }}
      inputClass={`${css.custominput} thaiDatePicker`}
      placeholder="วว-ดด-ปปปป"
      calendar={thai}
      locale={thai_th}
      format="DD/MM/YYYY"
      {...props}
    />
  );
};

export default ThaiDatepickerComponent;
