import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

import Layout from 'components/LayoutComponent/LayoutComponent';
import { SiderComponent } from 'components/SiderComponent/SiderComponent';

// import { Desktop } from '../utils/displayResponsive';

const ForbiddenLayout = () => {
  const { getAuth } = useAuth();

  useEffect(() => {
    getAuth();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="background-log">
      {/* <Desktop> */}
        <SiderComponent>
          <Layout>
            <Outlet />
          </Layout>
        </SiderComponent>
      {/* </Desktop> */}
    </div>
  );
};

export default ForbiddenLayout;
