import type { MenuProps } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { mainRoutePaths, routeGroup } from 'constants/routes';
import { routesData } from 'constants/routesWithPerm';

export const menuListWithPerms = [
  {
    label: 'Dashboard',
    // icon: <UserOutlined />,
    key: routeGroup.dashboard,
    children: [
      {
        label: 'ข้อมูลสรุป',
        key: routesData.dashboardGraph.route,
        permission: routesData.dashboardGraph.permission,
      },
      {
        label: 'ข้อมูลสรุปผู้ประกอบการ',
        key: routesData.dashboardOperator.route,
        permission: routesData.dashboardOperator.permission,
      },
    ],
  },
  {
    label: 'การจัดทำข้อเสนอ',
    // icon: <UserOutlined />,
    key: routeGroup.offer,
    children: [
      {
        label: 'การจัดทำข้อเสนอ RAO',
        key: routesData.offerRAO.route,
        permission: routesData.offerRAO.permission,
      },
      {
        label: 'การจัดทำข้อเสนอ RIO',
        key: routesData.offerRIO.route,
        permission: routesData.offerRIO.permission,
      },
      {
        label: 'การจัดทำข้อเสนอ RDRO',
        key: routesData.offerRDRO.route,
        permission: routesData.offerRDRO.permission,
      },
      {
        label: 'การจัดทำข้อเสนอ RISO',
        key: routesData.offerRISO.route,
        permission: routesData.offerRISO.permission,
      },
      {
        label: 'การพิจารณาข้อเสนอ',
        key: routesData.offerReview.route,
        permission: routesData.offerReview.permission,
      },
      {
        label: 'การจัดทำข้อเสนอโดย ชท',
        key: routesData.offerOfficer.route,
        permission: routesData.offerOfficer.permission,
      },
      {
        label: 'การมอบหมายงาน',
        key: routesData.offerAssignment.route,
        permission: routesData.offerAssignment.permission,
      },
      {
        label: 'ลิงก์ข้อเสนอ โดย ชท',
        key: routesData.offerPublishUrl.route,
        permission: routesData.offerPublishUrl.permission,
      },
      {
        label: 'ข้อมูลผู้ประกอบการ (LMS)',
        key: routesData.offerOperatorLMS.route,
        permission: routesData.offerOperatorLMS.permission,
      },
    ],
  },
  {
    label: 'การจัดทำข้อพิพาท',
    // icon: <UserOutlined />,
    key: routeGroup.disagreement,
    children: [
      {
        label: 'กระบวนการระงับข้อพิพาท',
        key: routesData.disagreementList.route,
        permission: routesData.disagreementList.permission,
      },
    ],
  },
  {
    label: 'การจัดทำสัญญา',
    // icon: <UserOutlined />,
    key: routeGroup.contract,
    children: [
      {
        label: 'สัญญาการใช้ฯ',
        key: routesData.contractUsage.route,
        permission: routesData.contractUsage.permission,
      },
      {
        label: 'สัญญาการเชื่อมต่อฯ',
        key: routesData.contractConnect.route,
        permission: routesData.contractConnect.permission,
      },
      {
        label: 'สัญญาการใช้บริการข้ามโครงข่ายฯ',
        key: routesData.contractNetwork.route,
        permission: routesData.contractNetwork.permission,
      },
      {
        label: 'สัญญาการใช้โครงสร้างพื้นฐาน',
        key: routesData.contractInfra.route,
        permission: routesData.contractInfra.permission,
      },
      {
        label: 'การพิจารณาสัญญา',
        key: routesData.contractReview.route,
        permission: routesData.contractReview.permission,
      },
      {
        label: 'จัดทำสัญญาโดย ชท.',
        key: routesData.contractOfficer.route,
        permission: routesData.contractOfficer.permission,
      },
      {
        label: 'ลิงก์สัญญา โดย ชท',
        key: routesData.contractPublishUrl.route,
        permission: routesData.contractPublishUrl.permission,
      },
      {
        label: 'การมอบหมายงาน',
        key: routesData.contractAssignment.route,
        permission: routesData.contractAssignment.permission,
      },
    ],
  },
  {
    label: 'รายงาน',
    // icon: <UserOutlined />,
    key: routeGroup.report,
    children: [
      {
        label: 'รายงานภาพรวม',
        key: routesData.reportDisputeOverview.route,
        permission: routesData.reportDisputeOverview.permission,
      },
      {
        label: 'รายงานการติดตาม',
        key: routesData.reportDisputeTracking.route,
        permission: routesData.reportDisputeTracking.permission,
      },
      {
        label: 'รายงานสถานะการพิจารณาสัญญา',
        key: routesData.reportContractReviewStatus.route,
        permission: routesData.reportContractReviewStatus.permission,
      },
      {
        label: 'รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว',
        key: routesData.reportApprovedContract.route,
        permission: routesData.reportApprovedContract.permission,
      },
      {
        label: 'รายงานสถานะการพิจารณาข้อเสนอ',
        key: routesData.reportOfferReviewStatus.route,
        permission: routesData.reportOfferReviewStatus.permission,
      },
      {
        label: 'รายงานข้อมูลข้อเสนอที่ได้รับความเห็นชอบแล้ว',
        key: routesData.reportApprovedOffer.route,
        permission: routesData.reportApprovedOffer.permission,
      },
      {
        label: 'รายงานผลงานส่วนงานข้อเสนอ',
        key: routesData.reportOfferAuditResults.route,
        permission: routesData.reportOfferAuditResults.permission,
      },
      {
        label: 'รายงานปริมาณข้อมูล',
        key: routesData.reportDataConnection.route,
        permission: routesData.reportDataConnection.permission,
      },
    ],
  },
  {
    label: 'การจัดการข้อมูลผู้ใช้',
    // icon: <UserOutlined />,
    key: mainRoutePaths.manageUser,
    permission: routesData.manageUser.permission,
  },
  {
    label: 'ข้อมูลการใช้และเชื่อมต่อ',
    // icon: <UserOutlined />,
    key: routeGroup.dataConnection,
    children: [
      {
        label: 'นำส่งข้อมูล',
        key: routesData.dataConnectionOperator.route,
        permission: routesData.dataConnectionOperator.permission,
      },
      {
        label: 'การพิจารณาข้อมูล',
        key: routesData.dataConnectionReview.route,
        permission: routesData.dataConnectionReview.permission,
      },
      {
        label: 'นำส่งข้อมูล โดย ชท.',
        key: routesData.dataConnectionOfficer.route,
        permission: routesData.dataConnectionOfficer.permission,
      },
    ],
  },
  {
    label: 'ดาวน์โหลดแบบฟอร์ม',
    // icon: <UserOutlined />,
    key: routeGroup.download,
    children: [
      {
        label: 'แบบฟอร์ม',
        key: routesData.downloadForm.route,
        permission: routesData.downloadForm.permission,
      },
    ],
  },
  {
    label: 'ประวัติการเข้าใช้งาน',
    // icon: <UserOutlined />,
    key: routeGroup.auditLog,
    children: [
      {
        label: 'ตรวจสอบประวัติการเข้าใช้งานระบบ',
        key: routesData.auditLogLoginHistory.route,
        permission: routesData.auditLogLoginHistory.permission,
      },
    ],
  },
  {
    label: 'ข้อมูลตั้งต้น',
    // icon: <UserOutlined />,
    key: routeGroup.masterData,
    children: [
      {
        label: 'ข้อมูลสกุลเงิน',
        key: routesData.masterDataCurrencies.route,
        permission: routesData.masterDataCurrencies.permission,
      },
      {
        label: 'ข้อมูลหน่วยนับ',
        key: routesData.masterDataUnits.route,
        permission: routesData.masterDataUnits.permission,
      },
      {
        label: 'ข้อมูลสิ่งอำนวยความสะดวก',
        key: routesData.masterDataFacilities.route,
        permission: routesData.masterDataFacilities.permission,
      },
      {
        label: 'ข้อมูลประเภทบริการ',
        key: routesData.masterDataServiceTypes.route,
        permission: routesData.masterDataServiceTypes.permission,
      },
      {
        label: 'ข้อมูลบริการ',
        key: routesData.masterDataServices.route,
        permission: routesData.masterDataServices.permission,
      },
    ],
  },
  {
    label: 'สอบถามข้อมูล',
    // icon: <UserOutlined />,
    key: routeGroup.inquiry,
    children: [
      {
        label: 'สอบถามข้อมูลข้อเสนอ',
        key: routesData.inquiryOffer.route,
        permission: routesData.inquiryOffer.permission,
      },
      {
        label: 'สอบถามข้อมูลสัญญา',
        key: routesData.inquiryContract.route,
        permission: routesData.inquiryContract.permission,
      },
      {
        label: 'สอบถามข้อมูลข้อพิพาท',
        key: routesData.inquiryDisagreement.route,
        permission: routesData.inquiryDisagreement.permission,
      },
    ],
  },
];

export const getDropDownItems = ({ onLogout }: { onLogout: Function }) => {
  const dropDownitems: MenuProps['items'] = [
    {
      key: 'profile',
      label: <span onClick={() => window.location.replace('/profile')}>โปรไฟล์</span>,
      icon: <UserOutlined />,
    },
    {
      key: 'logout',
      label: <span onClick={() => onLogout()}>Logout</span>,
      icon: <LogoutOutlined />,
    },
  ];
  return dropDownitems;
};
