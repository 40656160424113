import { programCode } from './programCode';
import { mainRoutePaths } from './routes';

interface RolesPermissions {
  [key: string]: { permissions: string[]; landingPage: string };
}

export const rolesPermissions: RolesPermissions = {
  OFF_OFFER_LDR: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000063, // 🖥️ Main Menu: รายงานสถานะการพิจารณาข้อเสนอ
      programCode.A000064, // 🖥️ Main Menu: รายงานข้อมูลข้อเสนอที่ได้รับความเห็นชอบแล้ว
      programCode.A000065, // 🖥️ Main Menu: รายงานผลงานส่วนงานข้อเสนอ
      programCode.A000030, // 🖥️ Main Menu: การมอบหมายงานข้อเสนอ
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท

      programCode.A000027, // 🖥️ Main Menu: การพิจารณาข้อเสนอ
      programCode.A000028, // 📴 Sub Route: การพิจารณาข้อเสนอ > Edit/Detail

      programCode.A000031, // 🖥️ Main Menu: ลิงก์ข้อเสนอ โดย ชท
      programCode.A000032, // 🖥️ Main Menu: ข้อมูลผู้ประกอบการ  (LMS)
      programCode.A000029, // 🖥️ Main Menu: การจัดทำข้อเสนอโดย ชท
      programCode.A000004, // 📴 Sub Route: RAO Add New
      programCode.A000005, // 📴 Sub Route: RAO Edit
      programCode.A000006, // 📴 Sub Route: RAO Detail
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000010, // 📴 Sub Route: RIO Add New
      programCode.A000011, // 📴 Sub Route: RIO Edit
      programCode.A000012, // 📴 Sub Route: RIO Detail
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000016, // 📴 Sub Route: RDRO Add New
      programCode.A000017, // 📴 Sub Route: RDRO Edit
      programCode.A000018, // 📴 Sub Route: RDRO Detail
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000022, // 📴 Sub Route: RISO Add New
      programCode.A000023, // 📴 Sub Route: RISO Edit
      programCode.A000024, // 📴 Sub Route: RISO Detail
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_OFFER: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000063, // 🖥️ Main Menu: รายงานสถานะการพิจารณาข้อเสนอ
      programCode.A000064, // 🖥️ Main Menu: รายงานข้อมูลข้อเสนอที่ได้รับความเห็นชอบแล้ว
      programCode.A000065, // 🖥️ Main Menu: รายงานผลงานส่วนงานข้อเสนอ
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท

      programCode.A000027, // 🖥️ Main Menu: การพิจารณาข้อเสนอ
      programCode.A000028, // 📴 Sub Route: การพิจารณาข้อเสนอ > Edit/Detail

      programCode.A000031, // 🖥️ Main Menu: ลิงก์ข้อเสนอ โดย ชท
      programCode.A000032, // 🖥️ Main Menu: ข้อมูลผู้ประกอบการ  (LMS)
      programCode.A000029, // 🖥️ Main Menu: การจัดทำข้อเสนอโดย ชท
      programCode.A000004, // 📴 Sub Route: RAO Add New
      programCode.A000005, // 📴 Sub Route: RAO Edit
      programCode.A000006, // 📴 Sub Route: RAO Detail
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000010, // 📴 Sub Route: RIO Add New
      programCode.A000011, // 📴 Sub Route: RIO Edit
      programCode.A000012, // 📴 Sub Route: RIO Detail
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000016, // 📴 Sub Route: RDRO Add New
      programCode.A000017, // 📴 Sub Route: RDRO Edit
      programCode.A000018, // 📴 Sub Route: RDRO Detail
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000022, // 📴 Sub Route: RISO Add New
      programCode.A000023, // 📴 Sub Route: RISO Edit
      programCode.A000024, // 📴 Sub Route: RISO Detail
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_DIS_LDR: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000059, // 🖥️ Main Menu: รายงานภาพรวม
      programCode.A000060, // 🖥️ Main Menu: รายงานการติดตาม
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000033, // 🖥️ Main Menu: กระบวนการระงับข้อพิพาท
      programCode.A000034, // 📴 Sub Route: เพิ่มกระบวนการระงับข้อพิพาท
      programCode.A000035, // 📴 Sub Route: แก้ไขกระบวนการระงับข้อพิพาท

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_DIS: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000059, // 🖥️ Main Menu: รายงานภาพรวม
      programCode.A000060, // 🖥️ Main Menu: รายงานการติดตาม
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000033, // 🖥️ Main Menu: กระบวนการระงับข้อพิพาท
      programCode.A000034, // 📴 Sub Route: เพิ่มกระบวนการระงับข้อพิพาท
      programCode.A000035, // 📴 Sub Route: แก้ไขกระบวนการระงับข้อพิพาท

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OPERATOR: {
    permissions: [
      programCode.A000002, // 🖥️ Main Menu: ข้อมูลสรุปผู้ประกอบการ
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000003, // 🖥️ Main Menu: การจัดทำข้อเสนอ RAO
      programCode.A000004, // 📴 Sub Route: RAO Add New
      programCode.A000005, // 📴 Sub Route: RAO Edit
      programCode.A000006, // 📴 Sub Route: RAO Detail
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000009, // 🖥️ Main Menu: การจัดทำข้อเสนอ RIO
      programCode.A000010, // 📴 Sub Route: RIO Add New
      programCode.A000011, // 📴 Sub Route: RIO Edit
      programCode.A000012, // 📴 Sub Route: RIO Detail
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000015, // 🖥️ Main Menu: การจัดทำข้อเสนอ RDRO
      programCode.A000016, // 📴 Sub Route: RDRO Add New
      programCode.A000017, // 📴 Sub Route: RDRO Edit
      programCode.A000018, // 📴 Sub Route: RDRO Detail
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000021, // 🖥️ Main Menu: การจัดทำข้อเสนอ RISO
      programCode.A000022, // 📴 Sub Route: RISO Add New
      programCode.A000023, // 📴 Sub Route: RISO Edit
      programCode.A000024, // 📴 Sub Route: RISO Detail
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History

      programCode.A000036, // 🖥️ Main Menu: สัญญาการใช้ฯ
      programCode.A000037, // 📴 Sub Route: สัญญาการใช้ฯ > Add New
      programCode.A000038, // 📴 Sub Route: สัญญาการใช้ฯ > Edit
      programCode.A000039, // 📴 Sub Route: สัญญาการใช้ฯ > History
      programCode.A000040, // 🖥️ Main Menu: สัญญาการเชื่อมต่อฯ
      programCode.A000041, // 📴 Sub Route: สัญญาการเชื่อมต่อฯ > Add New
      programCode.A000042, // 📴 Sub Route: สัญญาการเชื่อมต่อฯ > Edit
      programCode.A000043, // 📴 Sub Route: สัญญาการเชื่อมต่อฯ > History
      programCode.A000044, // 🖥️ Main Menu: สัญญาการใช้บริการข้ามโครงข่ายฯ
      programCode.A000045, // 📴 Sub Route: สัญญาการใช้บริการข้ามโครงข่ายฯ > Add New
      programCode.A000046, // 📴 Sub Route: สัญญาการใช้บริการข้ามโครงข่ายฯ > Edit
      programCode.A000047, // 📴 Sub Route: สัญญาการใช้บริการข้ามโครงข่ายฯ > History
      programCode.A000048, // 🖥️ Main Menu: สัญญาการใช้โครงสร้างพื้นฐาน
      programCode.A000049, // 📴 Sub Route: สัญญาการใช้โครงสร้างพื้นฐาน > Add New
      programCode.A000050, // 📴 Sub Route: สัญญาการใช้โครงสร้างพื้นฐาน > Edit
      programCode.A000051, // 📴 Sub Route: สัญญาการใช้โครงสร้างพื้นฐาน > History

      programCode.A000068, // 🖥️ Main Menu: นำส่งข้อมูล
      programCode.A000069, // 📴 Sub Route: นำส่งข้อมูล > Add New
      programCode.A000070, // 📴 Sub Route: นำส่งข้อมูล > Edit
    ],
    landingPage: mainRoutePaths.dashboardOperator,
  },
  OFF_SEN_LDR: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000066, // 🖥️ Main Menu: รายงานปริมาณข้อมูล
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000075, // 🖥️ Main Menu: นำส่งข้อมูล โดย ชท.
      programCode.A000071, // 🖥️ Main Menu: การพิจารณาข้อมูล
      programCode.A000072, // 📴 Sub Route: การพิจารณาข้อมูล > Add New
      programCode.A000073, // 📴 Sub Route: การพิจารณาข้อมูล > Detail
      programCode.A000074, // 📴 Sub Route: การพิจารณาข้อมูล > Edit

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_SEN: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000066, // 🖥️ Main Menu: รายงานปริมาณข้อมูล
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000075, // 🖥️ Main Menu: นำส่งข้อมูล โดย ชท.
      programCode.A000071, // 🖥️ Main Menu: การพิจารณาข้อมูล
      programCode.A000072, // 📴 Sub Route: การพิจารณาข้อมูล > Add New
      programCode.A000073, // 📴 Sub Route: การพิจารณาข้อมูล > Detail
      programCode.A000074, // 📴 Sub Route: การพิจารณาข้อมูล > Edit

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_CONTC_LDR: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000061, // 🖥️ Main Menu: รายงานสถานะการพิจารณาสัญญา
      programCode.A000062, // 🖥️ Main Menu: รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000057, // 🖥️ Main Menu: ลิงก์สัญญา โดย ชท
      programCode.A000058, // 🖥️ Main Menu: การมอบหมายงานสัญญา
      programCode.A000052, // 🖥️ Main Menu: การพิจารณาสัญญา
      programCode.A000053, // 📴 Sub Route: การพิจารณาสัญญา > Edit

      programCode.A000054, // 🖥️ Main Menu: จัดทำสัญญาโดย ชท.
      programCode.A000055, // 📴 Sub Route: จัดทำสัญญาโดย ชท. > Add New
      programCode.A000056, // 📴 Sub Route: จัดทำสัญญาโดย ชท. > Edit

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_CONTC: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000061, // 🖥️ Main Menu: รายงานสถานะการพิจารณาสัญญา
      programCode.A000062, // 🖥️ Main Menu: รายงานข้อมูลสัญญาที่ได้รับความเห็นชอบแล้ว
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม

      programCode.A000057, // 🖥️ Main Menu: ลิงก์สัญญา โดย ชท
      programCode.A000052, // 🖥️ Main Menu: การพิจารณาสัญญา
      programCode.A000053, // 📴 Sub Route: การพิจารณาสัญญา > Edit

      programCode.A000054, // 🖥️ Main Menu: จัดทำสัญญาโดย ชท.
      programCode.A000055, // 📴 Sub Route: จัดทำสัญญาโดย ชท. > Add New
      programCode.A000056, // 📴 Sub Route: จัดทำสัญญาโดย ชท. > Edit

      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  OFF_NBTC: {
    permissions: [
      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.inquiryOffer,
  },
  OFF_DIR: {
    permissions: [
      programCode.A000001, // 🖥️ Main Menu: ข้อมูลสรุป
      programCode.A000083, // 🖥️ Main Menu: สอบถามข้อมูลข้อเสนอ
      programCode.A000007, // 📴 Sub Route: RAO Inquiry
      programCode.A000008, // 📴 Sub Route: RAO History
      programCode.A000013, // 📴 Sub Route: RIO Inquiry
      programCode.A000014, // 📴 Sub Route: RIO History
      programCode.A000019, // 📴 Sub Route: RDRO Inquiry
      programCode.A000020, // 📴 Sub Route: RDRO History
      programCode.A000025, // 📴 Sub Route: RISO Inquiry
      programCode.A000026, // 📴 Sub Route: RISO History
      programCode.A000084, // 🖥️ Main Menu: สอบถามข้อมูลสัญญา
      programCode.A000085, // 📴 Sub Route: สอบถามข้อมูลสัญญา > Detail
      programCode.A000086, // 🖥️ Main Menu: สอบถามข้อมูลข้อพิพาท
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
  ADMIN: {
    permissions: [
      programCode.A000076, // 🖥️ Main Menu: ดาวน์โหลดแบบฟอร์ม > แบบฟอร์ม
      programCode.A000077, // 🖥️ Main Menu: ตรวจสอบประวัติการเข้าใช้งานระบบ
      programCode.A000067, // 🖥️ Main Menu: การจัดการข้อมูลผู้ใช้

      programCode.A000078, // 🖥️ Main Menu: ข้อมูลสกุลเงิน
      programCode.A000079, // 🖥️ Main Menu: ข้อมูลหน่วยนับ
      programCode.A000080, // 🖥️ Main Menu: ข้อมูลสิ่งอำนวยความสะดวก
      programCode.A000081, // 🖥️ Main Menu: ข้อมูลประเภทบริการ
      programCode.A000082, // 🖥️ Main Menu: ข้อมูลบริการ
    ],
    landingPage: mainRoutePaths.dashboardGraph,
  },
};
