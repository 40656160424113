import { Row, Col, Form, Button, Input, FormInstance } from 'antd';
import { SearchOutlined, ClearOutlined } from '@ant-design/icons';

import { formLayout } from 'constants/form';
import { dayJsformatDateObjToString } from 'utils/date';

import ThaiDatepickerComponent from 'components/ThaiDatepickerComponent';
import ContractStateDropdown from '../ContractStateDropdown';

import ContractStatusDropdown from '../ContractStatusDropdown';

import buttonCSS from 'styles/components/button.module.css';
import ContractTypeDropdown from '../ContractTypeDropdown';

type SearchContractProps = {
  onSearchContract: Function;
  formRef: FormInstance<any>;
  isShowOfferFormType?: boolean;
  isShowContractStateFilter?: boolean;
};

const SearchContract = ({
  onSearchContract,
  formRef,
  isShowOfferFormType = false,
  isShowContractStateFilter = true,
}: SearchContractProps) => {
  const onFinish = (values: any) => {
    let _values = { ...values };
    _values.contractStartDate = values.contractStartDate
      ? dayJsformatDateObjToString(values.contractStartDate)
      : null;
    _values.contractEndDate = values.contractEndDate
      ? dayJsformatDateObjToString(values.contractEndDate)
      : null;
    onSearchContract(_values);
  };

  const onReset = () => {
    formRef.resetFields();
  };

  return (
    <div className="content-box">
      <Form
        {...formLayout}
        labelWrap
        form={formRef}
        name="searchContractForm"
        onFinish={onFinish}
        size="large"
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="เลขที่สัญญา"
              name="contractNo"
              // rules={[{ required: true, message: 'กรุณากรอกเลขที่สัญญา' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          {isShowContractStateFilter && (
            <Col span={12}>
              <ContractStateDropdown />
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่จัดทำสัญญาตั้งแต่"
              name="contractStartDate"
              // rules={[{ required: true, message: 'กรุณากรอกวันที่จัดทำสัญญาตั้งแต่' }]}
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่จัดทำสัญญาถึง"
              name="contractEndDate"
              // rules={[{ required: true, message: 'กรุณากรอกวันที่จัดทำสัญญาถึง' }]}
            >
              <ThaiDatepickerComponent />
            </Form.Item>
          </Col>
          {isShowOfferFormType && (
            <Col span={12}>
              <ContractTypeDropdown label="รูปแบบสัญญา" isRequired={false} />
            </Col>
          )}
          <Col span={12}>
            <ContractStatusDropdown />
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              onClick={onReset}
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchContract;
