import { useContext } from 'react';
import { CancelContractModalContext } from '../context/CancelContractModalContext';

const useCancelContractModalContext = () => {
  const context = useContext(CancelContractModalContext);
  if (!context) throw new Error('Error produced by initial CancelContractModalContext');
  return context;
};

export default useCancelContractModalContext;
