import { useContext } from 'react';
import { ContractDataContext } from '../context/ContractDataContext';

const useContractDataContext = () => {
  const context = useContext(ContractDataContext);
  if (!context) throw new Error('Error produced by initial ContractDataContext');
  return context;
};

export default useContractDataContext;
