import { dayJsformatDateToBCYear } from 'utils/date';

type getOfferFormNoProps = {
  formNo: string | undefined;
  date: string | undefined;
};

export const getFormattedString = ({ formNo, date }: getOfferFormNoProps) => {
  const _formNo = formNo ? formNo + ' ' : ' - ';
  const _formDate = date ? dayJsformatDateToBCYear(date) : ' - ';
  return _formNo + '\n' + _formDate;
};

export const getFormattedApproveDate = ({ formNo, date }: getOfferFormNoProps) => {
  const formattedFormNo = formNo ? `${formNo} ${date ? '/' : ''}` : ' - ';
  const formattedDate = date ? dayJsformatDateToBCYear(date) : ' - ';

  return `${formattedFormNo}\n${formattedDate}`;
};
