import { useCallback, useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { Spin } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import useModal from 'hooks/useModal';
import useDialogAlert from 'hooks/useDialogAlert';

import { getReviewOfferState, updateReviewOfferState, getReviewOfferById } from 'api/review';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import OfferDetail from '../components/OfferDetail';
import StepsModal from '../components/StepsModal';
import StepsDetail from '../components/StepsDetail';
import EditOfferForReview from '../components/EditOfferForReview';
// import { mockedData } from '../mockResp';

const EditOfferReview = () => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { error: errorModal } = useDialogAlert();
  const { offerId } = useParams<{ offerId: string }>();
  const { isModalOpen, showModal, handleOk, handleCancel } = useModal();

  const {
    makeRequest: _getReviewOfferState,
    data: offerStateData,
    loading: isLoadingOfferState,
  }: TApiResponse = useApi();
  const {
    makeRequest: _getReviewOfferById,
    data: offerData,
    loading: isLoadingReviewData,
  }: TApiResponse = useApi();
  const {
    makeRequest: _updateReviewOfferState,
    data: updatedOfferStateData,
    loading: isLoadingUpdateState,
  }: TApiResponse = useApi();

  const [stepsData, setStepsData] = useState<any>([]);
  const [stepModalIndex, setStepModalIndex] = useState<number | undefined>(undefined);
  const [currentStepData, setCurrentStepData] = useState(undefined);

  const onOpenModalForm = useCallback(
    (stepNumber: number) => {
      setStepModalIndex(stepNumber);
      setCurrentStepData(stepsData[stepNumber]);
      showModal();
    },
    [showModal, stepsData]
  );

  const onRefetchOfferData = useCallback(() => {
    if (offerId) {
      _getReviewOfferById(() => getReviewOfferById(offerId));
    } else {
      errorModal({
        header: 'เกิดข้อผิดพลาดขึ้น: ไม่พบ OfferId',
        sub: 'กรุณารีเฟรชหน้า',
      });
    }
  }, [offerId, _getReviewOfferById, errorModal]);

  const onUpdateOfferState = useCallback(
    async (data: any, api?: any) => {
      try {
        if (!offerId) throw new Error('เกิดข้อผิดพลาด: ไม่พบ OfferId');
        if (api) await _updateReviewOfferState(() => api(offerId, data));
        else await _updateReviewOfferState(() => updateReviewOfferState(offerId, data));
        handleOk();
      } catch (e: any) {
        errorModal({
          header: e.message || 'เกิดข้อผิดพลาดขึ้น (error: #8205)',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [handleOk, offerId, _updateReviewOfferState, errorModal]
  );

  useEffect(() => {
    if (offerId) {
      _getReviewOfferById(() => getReviewOfferById(offerId));
    }
    _getReviewOfferState(() => getReviewOfferState(offerId));
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [_getReviewOfferById, _getReviewOfferState, offerId, setHeaderTitle]);

  useEffect(() => {
    setStepsData(offerStateData);
    // setStepsData(mockedData);
  }, [offerStateData]);

  useEffect(() => {
    setStepsData(updatedOfferStateData);
  }, [updatedOfferStateData]);

  return (
    <div>
      <Spin tip="กำลังดำเนินการ..." spinning={isLoadingOfferState || isLoadingReviewData}>
        <HeaderTitle>การพิจารณาข้อเสนอ</HeaderTitle>
        <EditOfferForReview
          OfferReviewComponent={
            <>
              <OfferDetail reviewData={offerData} />
              <StepsModal
                offerData={offerData}
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
                stepModalIndex={stepModalIndex}
                stepData={currentStepData}
                onUpdateOfferState={onUpdateOfferState}
                isLoadingUpdateState={isLoadingUpdateState}
                onRefetchOfferData={onRefetchOfferData}
              />
              <StepsDetail
                stepsData={stepsData}
                showModal={onOpenModalForm}
                offerData={offerData}
              />
            </>
          }
        />
      </Spin>
    </div>
  );
};

export default EditOfferReview;
