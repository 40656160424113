import { Button } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { OTHER_OPTION } from 'features/Offer/constant';
import { IContServePay } from 'features/Contract/interfaces';

import { getPayPeriodUnitName } from 'utils/payPeriodUnitName';

type ColumnsProps = {
  onEditContServePay: (servePay: IContServePay) => void;
  onDeleteContServePay: (servePay: IContServePay) => void;
};

export const getColumns = ({ onEditContServePay, onDeleteContServePay }: ColumnsProps) => {
  const columns: ColumnsType<IContServePay> = [
    {
      title: 'ลบ',
      dataIndex: 'delete',
      key: 'delete',
      width: '40px',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Button
              type="primary"
              size="middle"
              danger
              icon={<DeleteOutlined />}
              onClick={() => onDeleteContServePay(record)}
            />
          </div>
        );
      },
    },
    {
      title: 'ลำดับที่',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      width: '100px',
      render: (_, __, index) => <>{index + 1}</>,
    },
    {
      title: 'เงื่อนไขราคา',
      dataIndex: 'payCondition',
      key: 'payCondition',
      align: 'center',
    },
    {
      title: 'อัตราค่าตอบแทน',
      dataIndex: 'payCompensation',
      key: 'payCompensation',
      align: 'center',
    },
    {
      title: 'สกุลเงิน',
      dataIndex: 'currencyName',
      key: 'currencyName',
      align: 'center',
      render: (value, record) => {
        if (value === OTHER_OPTION) return record.currencyDesc;
        return value;
      },
    },
    {
      title: 'หน่วย (Unit)',
      dataIndex: 'unitName',
      key: 'unitName',
      align: 'center',
      render: (value, record) => {
        if (value === OTHER_OPTION) return record.unitDesc;
        return value;
      },
    },
    {
      title: 'ระยะเวลา',
      dataIndex: 'payPeriod',
      key: 'payPeriod',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            {record.payPeriod} {getPayPeriodUnitName(record.payPeriodUnit)}
          </div>
        );
      },
    },

    {
      title: 'แก้ไข',
      dataIndex: 'edit',
      key: 'edit',
      width: '40px',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Button
              size="middle"
              icon={<EditOutlined />}
              onClick={() => onEditContServePay(record)}
            />
          </div>
        );
      },
    },
  ];
  return columns;
};
