import axiosInstance from 'utils/axios';
import { ListContractPublishUrlQuery, ContractPublicUrlData } from './interface';

export const listContractPublishUrl = (query: ListContractPublishUrlQuery) => {
  return axiosInstance.get('contract/view-public-url', {
    params: query,
  });
};

export const updateContractPublicUrl = (data: ContractPublicUrlData) => {
  return axiosInstance.put('contract/updateLink', data);
};
