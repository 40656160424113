import OfferNOForm from '../OfferNOForm';
import ContactInfoForm from '../ContactInfoForm';
import OfferServices from '../OfferServices';
import ActionButtonGroup from '../ActionButtonGroup';

const EditOfferTab = () => {
  return (
    <div>
      <OfferNOForm />
      <ContactInfoForm />
      <OfferServices />
      <ActionButtonGroup />
    </div>
  );
};

export default EditOfferTab;
