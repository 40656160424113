/* eslint-disable */

import { useNavigate, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SearchForm from './SearchForm/SearchForm';
import { ButtonComponent, HeaderTitleComponent } from 'components';
import TableData, { TableDataType } from './TableData/TableData';
import { PlusOutlined } from '@ant-design/icons';
import { TApiResponse, useApi } from 'hooks/useApi';
import { Pageable } from 'api/disagreement/interface';
import useLoading from 'hooks/useLoading';
import useDialogAlert from 'hooks/useDialogAlert';
import { useForm } from 'antd/es/form/Form';
import { searchUsageForm } from 'api/usageForm';
import { SearchUsageFormResponse } from 'api/usageForm/interface';
import { subRoutePaths } from 'constants/routes';

export interface DataSource {
  data: TableDataType[];
  pagination?: Pageable;
}

const defaultDatasource: DataSource = {
  data: [],
  pagination: undefined,
};

const Status: Record<number, string> = {
  1: 'อยู่ระหว่างจัดทำ',
  2: 'อยู่ระหว่างพิจารณา',
  3: 'อยู่ระหว่างผู้ประกอบการแก้ไข',
  4: 'พิจารณาครบถ้วน',
};

const ListDocument = (): JSX.Element => {
  const navigate = useNavigate();
  const [form] = useForm();
  const { makeRequest: searchUsageFormRequest } = useApi();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { setLoad } = useLoading();
  const { error } = useDialogAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSource, setDataSource] = useState<DataSource>(defaultDatasource);

  const mapResponseToDataSource = (payload: SearchUsageFormResponse): DataSource => {
    return {
      data: payload.data.map((i) => {
        return {
          id: i.usageFormId,
          key: i.usageFormId,
          quarter: i.quarter,
          status: Status[i.formStatus],
          year: i.year,
          onDetailClick: () => {
            navigate(subRoutePaths.dataConOperatorEdit(i.usageFormId));
          },
        };
      }),
      pagination: payload.pageable,
    };
  };

  const onSearch = async (pageSize?: number, currentPage?: number, reset?: boolean) => {
    setLoad(true);
    if (reset) {
      setCurrentPage(1);
    }
    const formData = form.getFieldsValue();
    try {
      const result: TApiResponse<SearchUsageFormResponse> = await searchUsageFormRequest(() => {
        return searchUsageForm({
          currentPageNumber: currentPage || 0,
          pageSize: pageSize || 5,
          year: formData.year,
          quarter: formData.quarter,
        });
      });
      const newData = mapResponseToDataSource(result.data);
      setDataSource({
        data: [...newData.data],
        pagination: newData.pagination,
      });
      setLoad(false);
    } catch (e) {
      setLoad(false);
      error({
        header: 'เกิดข้อผิดพลาด',
        sub: 'เกิดข้อผิดพลาดกรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  useEffect(() => {
    setHeaderTitle('ข้อมูลการใช้และเชื่อมต่อ');
    onSearch();
  }, []);
  return (
    <div>
      <HeaderTitleComponent>ค้นหาโดย</HeaderTitleComponent>
      <SearchForm
        onSearch={() => {
          onSearch(undefined, undefined, true);
        }}
        form={form}
      />
      <HeaderTitleComponent>ผลการค้นหา</HeaderTitleComponent>
      <div
        style={{
          padding: '16px',
        }}
      >
        <ButtonComponent
          htmlType="button"
          onClick={() => {
            navigate(subRoutePaths.dataConOperatorNew);
          }}
        >
          <PlusOutlined />
          เพิ่มข้อมูลนำส่งรายไตรมาส
        </ButtonComponent>
      </div>
      <div style={{ padding: '16px' }}>
        <TableData
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          dataSource={dataSource}
          onSearch={onSearch}
        />
      </div>
    </div>
  );
};
export default ListDocument;
