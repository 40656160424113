import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Content } from 'antd/es/layout/layout';
import styled from 'styled-components';

import ModalTable from '../../features/ManageOffer/components/ModalRAO/ModalTable/ModalTable';
import { dayJsformatDateToBCYear } from 'utils/date';
import { getOfferHistoryPath } from 'utils/getOfferUrl';

const Div = styled.div`
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

type Props = {
  attachHistory: any;
};

const ModalHistoryContent = (props: Props) => {
  const { attachHistory } = props;
  const { pathname } = useLocation();

  const getLinkItem = useCallback(
    (val: any, index: number) => {
      if (index === 0)
        return (
          <p>
            {index + 1}. เลขที่/วันที่หนังสือผู้ประกอบการ : {val.offerFormNo} (
            {dayJsformatDateToBCYear(val.offerFormDate)})
          </p>
        );
      else
        return (
          <Link
            key={index}
            // to={`/search-${val.offerAttachFiles[0].docType}/${val.offerFormId}/details/history`} // FIXME:M แล้วถ้ามันไม่มี file แล้วมันจะหา offerType ถูกมั้ย ?
            to={getOfferHistoryPath({
              offerType: val?.offerAttachFiles[0]?.docType,
              offerId: val?.offerFormId,
            })}
            state={{ from: pathname }}
          >
            {index + 1}. เลขที่/วันที่หนังสือผู้ประกอบการ : {val.offerFormNo} (
            {dayJsformatDateToBCYear(val.offerFormDate)})
          </Link>
        );
    },
    [pathname]
  );

  if (!attachHistory) return <p>มีข้อผิดพลาดเกิดขึ้น กรุณาลองใหม่อีกครั้ง</p>;
  if (attachHistory.length === 0) return <p>ไม่พบข้อมูล</p>;

  return (
    <Content style={{ overflowY: 'scroll', maxHeight: '500px' }}>
      {attachHistory.map((val: any, index: number) => (
        <Div key={index}>
          {getLinkItem(val, index)}
          <ModalTable data={val} isCurrentOffer={index === 0} />
        </Div>
      ))}
    </Content>
  );
};

export default ModalHistoryContent;
