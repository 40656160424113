import { rolesPermissions } from 'constants/permission';

export const getUserPerms = (roles: string[] | undefined): string[] => {
  let perms: string[] = [];
  roles?.forEach((role: string) => {
    perms.push(...rolesPermissions[role].permissions);
  });
  return Array.from(new Set(perms));
};

export const getUserLandingPage = (roles: string[] | undefined): string => {
  const firstRole = roles?.[0];
  return firstRole ? rolesPermissions[firstRole]?.landingPage || '/' : '/';
};
