import React from 'react';
import 'antd/dist/reset.css';
import './App.css';
import { LoadProvider } from './contexts/LoadContext';
import { AuthProvider } from './contexts/AuthContext';
import Router from './routers/Routes';
import LoadComponent from './components/LoadingComponent/LoadingComponent';
import { DialogAlerProvider } from './contexts/DialogAlertContext';
import { DataAllProjectProvider } from './contexts/DataAllProjectContext';
import { ApiProvider } from 'contexts/RequestContext';

function App() {
  return (
    <React.StrictMode>
      <LoadProvider>
        <DialogAlerProvider>
          <AuthProvider>
            <DataAllProjectProvider>
              <ApiProvider>
                <LoadComponent />
                <Router />
              </ApiProvider>
            </DataAllProjectProvider>
          </AuthProvider>
        </DialogAlerProvider>
      </LoadProvider>
    </React.StrictMode>
  );
}

export default App;
