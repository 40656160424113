import { FormInstance } from 'antd';

import { IOfferData, IOfferFacility, IOfferServes } from '../interfaces';

type composeSaveOfferDataParams = {
  offerData: IOfferData;
  editOfferFormRef: FormInstance<any>;
  currentOfferServices: IOfferServes[];
  currentOfferFacilities: IOfferFacility[];
};

export const composeSaveOfferData = ({
  offerData,
  editOfferFormRef,
  currentOfferServices,
  currentOfferFacilities,
}: composeSaveOfferDataParams) => {
  const _operators = editOfferFormRef.getFieldsValue();
  const operatorInfo = offerData?.operators;

  const requestData = {
    offerFormNo: offerData?.offerFormNo,
    offerFormDate: offerData?.offerFormDate,
    offerFormId: offerData?.offerFormId,
    offerFormType: offerData?.offerFormType,
    offerServes: currentOfferServices,
    offerFacilities: currentOfferFacilities,
    operators: {
      operatorsId: operatorInfo?.operatorsId,
      taxId: operatorInfo?.taxId,
      contactType: operatorInfo?.contactType,
      ..._operators,
    },
  };

  return requestData;
};
