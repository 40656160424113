import Cookie, { CookieSetOptions } from 'universal-cookie';
const cookie = new Cookie();
class CookieService {
  get(key: string) {
    return cookie.get(key);
  }
  set(key: string, value: string, options?: Object) {
    cookie.set(key, value, { path: '/' });
  }
  remove(key: string, options?: CookieSetOptions) {
    cookie.remove(key, { path: '/' });
  }
}
export default new CookieService();
