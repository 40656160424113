import { FormState } from 'types/state';

// NOTE: Contract Path Type
export const CONTRACT_USAGE = 'usage';
export const CONTRACT_CONNECT = 'connect';
export const CONTRACT_NETWORK = 'network';
export const CONTRACT_INFRASTRUCTURE = 'infrastructure';

// NOTE: Contract Tab
export const CONTRACT_TAB = 'contract-tab';
export const ATTACHMENT_TAB = 'attachment-tab';
export const REVIEW_CONTRACT_TAB = 'review-contract-tab';

// NOTE: Edit Contract
export const ATTACHMENT_STATE = [2];
export const EDITABLE_STATE = [1, 2, 6, 12];
export const OFFICER_UNEDITABLE_STATE = [17];
export const REVIEW_EDITABLE_STATE = [1, 2];
export const FINISHED_STATE = [17];
export const CONFIRM_SAVE_STATE = [1, 6, 12];
export const ALLOW_GO_TO_ATTACHMENT_TAB_STATE = [2];
export const PROHIBIT_ATTACHMENT_TAB_STATE = [1];
export const CONFIRM_DOC_STATE = [2, 6, 12];
export const formStateData: FormState = {
  stateName: '',
  stateCode: '',
  actionCode: '',
  actionName: '',
  actionDate: '',
  approvedNo: '',
  approvedDate: '',
  isCompleteState: false,
  isCurrentState: true,
};
