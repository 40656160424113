import { useContext } from 'react';
import { ConractServicesContext } from '../context/ContractServicesContext';

const useContractServicesContext = () => {
  const context = useContext(ConractServicesContext);
  if (!context) throw new Error('Error produced by initial ConractServicesContext');
  return context;
};

export default useContractServicesContext;
