import { Space } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';

interface IStepWithApprovedDate extends IStepDescription {
  label?: string;
}

const StepWithApprovedDate = ({ stepData, label }: IStepWithApprovedDate) => {
  return (
    <div>
      <Space>
        <label>{label ? label : 'วันที่ ชท. ได้รับเรื่องสัญญาฯ'} :</label>
        <span>
          {stepData?.approvedDate ? dayJsformatDateToBCYear(stepData?.approvedDate) : '-'}
        </span>
      </Space>
    </div>
  );
};

export default StepWithApprovedDate;
