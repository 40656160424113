import { Form, Row } from 'antd';

import { HeaderTitleComponent } from 'components';

import { formLayout } from 'constants/form';

import useUploadButton from 'features/Offer/hooks/useUploadButton';
import useConfirmDocModal from 'features/Offer/hooks/useConfirmDocModal';
import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';

import ConfirmDocModal from '../ConfirmDocModal';
import OfferUploadTable from '../OfferUploadTable';
import DocTypeUploadButton from '../DocTypeUploadButton';
import ActionButtonAttachmentGroup from '../ActionButtonAttachmentGroup';

const UploadOfferFilesSection = () => {
  const { currentOfferServices } = useOfferServicesContext();
  const {
    isDisabledUploadFile,
    isHideConfirmDocButton,
    isHistoryPage,
    offerId,
    offerType,
    offerData,
    downloadOfferFileAPI,
    editOfferFormRef,
    setOfferData,
    setActiveTab,
  } = useOfferDataContext();
  const {
    isOpenFileDialog,
    setIsOpenFileDialog,
    setSelectedDocTypeOption,
    uploadFormRef,
    onUploadFile,
    setCurrentUploadFile,
    onValidateDocType,
    isUploadingFile,
    isDeletingFile,
    fileList,
    onRemoveRecordFile,
    onDownloadRecordFile,
    onTableUploadFile,
    validateRequiredFiles,
  } = useUploadButton({
    offerId,
    isHistoryPage,
    downloadOfferFileAPI,
    offerAttachFiles: offerData?.offerAttachFiles,
    offerType: offerData?.offerFormType,
  });
  const {
    confirmDocFormRef,
    isModalOpen,
    isShowPublicUrlInput,
    isConfirmingDoc,
    onHandleCancel,
    onConfirmDoc,
    onSubmitConfirmDoc,
  } = useConfirmDocModal({
    offerFormType: offerData?.offerFormType,
    offerId,
    editOfferFormRef,
    currentOfferServices,
    validateRequiredFiles,
    setOfferData,
    setActiveTab,
  });

  return (
    <div>
      <HeaderTitleComponent style={{ marginBottom: 15 }}>
        รายละเอียดการแนบเอกสาร: สามารถแนบไฟล์ (pdf, word, excel) ครั้งละ 1 ไฟล์ ไฟล์ละไม่เกิน 20 MB
      </HeaderTitleComponent>
      <ConfirmDocModal
        isModalOpen={isModalOpen}
        onHandleCancel={onHandleCancel}
        confirmDocFormRef={confirmDocFormRef}
        isConfirmingDoc={isConfirmingDoc}
        onSubmitConfirmDoc={onSubmitConfirmDoc}
        isShowPublicUrlInput={isShowPublicUrlInput}
      />
      <Form
        {...formLayout}
        labelWrap
        labelAlign="left"
        name="uploadAttachmentForm"
        form={uploadFormRef}
      >
        <Row gutter={[16, 16]}>
          <DocTypeUploadButton
            offerId={offerId}
            offerType={offerType}
            isOpenFileDialog={isOpenFileDialog}
            isDisabledUploadFile={isDisabledUploadFile}
            setSelectedDocTypeOption={setSelectedDocTypeOption}
            setIsOpenFileDialog={setIsOpenFileDialog}
            onUploadFile={onUploadFile}
            setCurrentUploadFile={setCurrentUploadFile}
            onValidateDocType={onValidateDocType}
          />
          <OfferUploadTable
            isLoading={isUploadingFile || isDeletingFile}
            isDisabled={isDisabledUploadFile}
            onRemoveRecordFile={onRemoveRecordFile}
            onDownloadRecordFile={onDownloadRecordFile}
            fileList={fileList}
            onTableUploadFile={onTableUploadFile}
            setCurrentUploadFile={setCurrentUploadFile}
            offerId={offerId}
          />
          <ActionButtonAttachmentGroup
            onConfirmDoc={onConfirmDoc}
            isDisabled={isDisabledUploadFile}
            isHideConfirmDocButton={isHideConfirmDocButton}
          />
        </Row>
      </Form>
    </div>
  );
};

export default UploadOfferFilesSection;
