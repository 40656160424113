import { Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';
import useOfferServicesContext from 'features/Offer/hooks/useOfferServicesContext';

import PrimaryButton from 'components/PrimaryButton';

import { OFFER_TYPE_RIO } from 'constants/offerFormType';

const ServiceActionButton = () => { // NOTE: RIO, RAO
  const { isDisabled, offerType } = useOfferDataContext();
  const { currentOfferFacilities } = useOfferFacilititesContext();
  const {
    isDisableServiceAnnounceRate,
    onAddServiceAnnounceRate,
    onAddNewRIOService,
    onDeleteRIOServices,
  } = useOfferServicesContext();

  return (
    <div>
      <Space>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<PlusOutlined />}
          onClick={onAddNewRIOService}
        >
          เพิ่มบริการ
        </PrimaryButton>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<DeleteOutlined />}
          onClick={() => onDeleteRIOServices(currentOfferFacilities)}
        >
          ลบบริการ
        </PrimaryButton>
        {offerType === OFFER_TYPE_RIO && (
          <PrimaryButton
            size="middle"
            disabled={isDisabled || isDisableServiceAnnounceRate}
            icon={<PlusOutlined />}
            onClick={onAddServiceAnnounceRate}
          >
            เพิ่มอัตราตามประกาศ
          </PrimaryButton>
        )}
      </Space>
    </div>
  );
};

export default ServiceActionButton;
