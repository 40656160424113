import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import usePagination from 'hooks/usePagination';
import usePrevious from 'hooks/usePrevious';
import { useApi, TApiResponse } from 'hooks/useApi';

import { getContractList } from 'api/contract';

import HeaderTitle from 'components/HeaderTitleComponent/HeaderTitleComponent';
import PrimaryButton from 'components/PrimaryButton';
import SearchContract from '../components/SearchContract';
import ContractListTable from '../components/ContractListTable';
import WithHistoryModal from '../components/WithHistoryModal';

import { getOfferFormType, getNewContractPath, getEditContractPath } from '../utils/offerFormType';

type contractListQuery = {
  pageSize: number;
  currentPageNumber: number;
  offerFormType: string;
  contractNo?: string;
  contractStartDate?: string;
  contractEndDate?: string;
};

const ListContract = () => {
  const [searchContractFormRef] = Form.useForm();
  const navigator = useNavigate();
  const { pathname } = useLocation();
  const { setHeaderTitle, setIsClearHeader }: headerTitleContext = useOutletContext();
  const { currentPageNumber, pageSize, paginationSetting, setCurrentPageNumber } = usePagination();
  const {
    makeRequest,
    data: contractListData = [],
    loading: isLoadingContractList,
    pageable,
    setData: setContractListData,
  }: TApiResponse = useApi();
  const [queryParam, setQueryParam] = useState<contractListQuery>({} as contractListQuery);

  const offerFormType = useMemo(() => {
    if (!pathname) return '';
    return getOfferFormType(pathname.split('/')[2]);
  }, [pathname]);
  const prevOfferFormType = usePrevious(offerFormType);

  const editContractPath = useMemo(() => {
    return getEditContractPath(offerFormType);
  }, [offerFormType]);

  const requestContractList = useCallback(
    (params: contractListQuery) => {
      makeRequest(() =>
        getContractList({
          ...params,
        })
      );
    },
    [makeRequest]
  );

  useEffect(() => {
    // NOTE: Reset when path change
    setQueryParam({} as contractListQuery);
    setContractListData([]);
    searchContractFormRef.resetFields();
  }, [searchContractFormRef, pathname, setContractListData]);

  useEffect(() => {
    // NOTE: Fetch when change page size or current page number
    if (prevOfferFormType === offerFormType) {
      // NOTE: If not the same page, do not fetch
      requestContractList({ ...queryParam, pageSize, currentPageNumber, offerFormType });
    }
  }, [
    pageSize,
    currentPageNumber,
    queryParam,
    requestContractList,
    offerFormType,
    prevOfferFormType,
  ]);

  useEffect(() => {
    setIsClearHeader(false);
    setHeaderTitle('ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม');
  }, [setHeaderTitle, setIsClearHeader]);

  return (
    <div>
      <HeaderTitle>ค้นหาโดย</HeaderTitle>
      <SearchContract
        isShowContractStateFilter={false}
        formRef={searchContractFormRef}
        onSearchContract={(_values: any) => {
          setQueryParam(_values);
          setCurrentPageNumber(1);
        }}
      />
      <HeaderTitle>ผลการค้นหา</HeaderTitle>
      <div className="content-box">
        <PrimaryButton
          icon={<PlusOutlined />}
          onClick={() =>
            navigator(getNewContractPath(offerFormType), { state: { from: pathname } })
          }
        >
          จัดทำสัญญาใหม่
        </PrimaryButton>
      </div>
      <WithHistoryModal>
        {(props: any) => {
          return (
            <ContractListTable
              data={contractListData}
              loading={isLoadingContractList}
              pageSize={pageSize}
              currentPageNumber={currentPageNumber}
              paginationSetting={paginationSetting(pageable?.totalItems)}
              getEditPath={editContractPath}
              {...props}
            />
          );
        }}
      </WithHistoryModal>
    </div>
  );
};

export default ListContract;
