import { useCallback, useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Form, Table } from 'antd';

import useModal from 'hooks/useModal';
import { useApi, TApiResponse } from 'hooks/useApi';
import { UnitData } from 'api/master/interface';
import { listAllUnits, createNewUnit, updateUnit } from 'api/master/adminData';

import PrimaryButton from 'components/PrimaryButton';
import { HeaderTitleComponent } from 'components';

import EditModal from '../components/EditModal';
import { columns } from '../components/columns';

const ListUnits = () => {
  const [formRef] = Form.useForm();
  const { isModalOpen, showModal, handleCancel } = useModal();
  const {
    makeRequest: _getUnitsList,
    data: units = [],
    loading: isLoadingUnitsList,
  }: TApiResponse = useApi();
  const { makeRequest: _updateUnit, loading: isUpdatingUnit }: TApiResponse = useApi();

  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);

  const onOpenEditModal = (record: any) => {
    setSelectedRecord(record);
    showModal();
  };

  const onCloseModal = () => {
    setSelectedRecord(undefined);
    formRef.resetFields();
    handleCancel();
  };

  const onFetchCurrencyList = useCallback(() => {
    _getUnitsList(() => listAllUnits());
  }, [_getUnitsList]);

  const onSaveUnit = useCallback(
    async (values: UnitData) => {
      if (values.unitId) {
        await _updateUnit(() => updateUnit(values.unitId, values));
      } else {
        await _updateUnit(() => createNewUnit(values));
      }
      setSelectedRecord(undefined);
      formRef.resetFields();
      handleCancel();
      onFetchCurrencyList();
    },
    [handleCancel, _updateUnit, onFetchCurrencyList, formRef]
  );

  useEffect(() => {
    onFetchCurrencyList();
  }, [onFetchCurrencyList]);

  return (
    <div>
      <EditModal
        isModalOpen={isModalOpen}
        isUpdating={isUpdatingUnit}
        selectedRecord={selectedRecord}
        onCloseModal={onCloseModal}
        onSaveRecord={onSaveUnit}
        titleName={'แก้ไขข้อมูลหน่วยนับ'}
        fieldName={'unitName'}
        fieldTitle={'ชื่อหน่วยนับ'}
        formRef={formRef}
      />
      <HeaderTitleComponent>จัดการข้อมูลหน่วยนับ</HeaderTitleComponent>
      <div className="content-box" style={{ paddingLeft: 0 }}>
        <PrimaryButton icon={<PlusOutlined />} onClick={showModal}>
          เพิ่มหน่วยนับ
        </PrimaryButton>
      </div>
      <Table
        size="middle"
        columns={columns({ onOpenEditModal, titleName: 'หน่วยนับ', valueName: 'unitName' })}
        dataSource={units}
        loading={isLoadingUnitsList}
        rowKey={'unitId'}
        pagination={false}
      />
    </div>
  );
};

export default ListUnits;
