import { IOfficerUser, IOfficerUserAdd, IRoles } from '../interfaces';

interface IComposeSaveData {
  formData: any;
  recordData: IOfficerUser;
  roles: IRoles[];
}

interface IComposeCreateData {
  formData: any;
  roles: IRoles[];
}

export const composeSaveData = ({ formData, recordData, roles }: IComposeSaveData) => {
  const rolesArray = [];
  for (let i = 0; i < formData.roles.length; i++) {
    const [filter] = roles.filter((val) => val.groupCode === formData.roles[i]);
    const [_filter] = recordData.roles.filter((val) => val.groupCode === filter.groupCode);

    rolesArray.push({
      groupCode: filter.groupCode,
      groupDesc: filter.groupDesc,
      groupName: filter.groupName,
      userMapId: _filter ? _filter.userMapId : null,
      groupId: filter.groupId,
    });
  }

  let requestData = { ...recordData };
  requestData.empNo = formData.referenceNo;
  requestData.referenceNo = formData.referenceNo;
  requestData.titleName = formData.name.title;
  requestData.firstName = formData.name.titleName;
  requestData.lastName = formData.lastName;
  requestData.positionName = formData.positionName;
  requestData.departmentName = formData.departmentName;
  requestData.leaderIdNo = formData.leaderIdNo;
  requestData.status = formData.status === 1 ? true : false;
  requestData.email = formData.email ? formData.email : '';
  requestData.phoneNumber = formData.phoneNumber ? formData.phoneNumber : '';
  requestData.roles = rolesArray;
  return requestData;
};

export const composeCreateDate = ({ formData, roles }: IComposeCreateData) => {
  const rolesArray = [];
  for (let i = 0; i < formData.roles.length; i++) {
    const [filter] = roles.filter((val) => val.groupCode === formData.roles[i]);

    rolesArray.push({
      groupCode: filter.groupCode,
      userMapId: filter.groupId,
    });
  }
  const data: IOfficerUserAdd = {
    departmentName: formData.departmentName,
    email: formData.email ? formData.email : '',
    empNo: formData.referenceNo,
    firstName: formData.name.titleName,
    lastName: formData.lastName,
    leaderIdNo: formData.leaderIdNo,
    password: formData.userLogin,
    phoneNumber: formData.phoneNumber ? formData.phoneNumber : '',
    positionName: formData.positionName,
    referenceNo: formData.referenceNo,
    roles: rolesArray,
    status: formData.status === 1 ? true : false,
    titleName: formData.name.title,
    userLogin: formData.userLogin,
  };
  return data;
};
