import { Col, Form, Row, Table } from 'antd';

import useOfferDataContext from 'features/Offer/hooks/useOfferDataContext';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';

import { formLayout } from 'constants/form';
import { getColumns } from './columns';

const RDROFacilityTable = () => {
  const { isDisabled } = useOfferDataContext();
  const {
    currentOfferFacilities,
    facilityTableFormRef,
    selectedFacilities,
    onSelectMultipleFacilities,
    onEditFacility,
  } = useOfferFacilititesContext();

  const rowSelection = {
    onChange: onSelectMultipleFacilities,
    selectedRowKeys: selectedFacilities,
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form
          {...formLayout}
          labelWrap
          size="large"
          name="facilityTableForm"
          form={facilityTableFormRef}
          disabled={isDisabled}
        >
          <Table
            bordered
            size="small"
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            columns={getColumns({ onEditFacility })}
            dataSource={currentOfferFacilities ? currentOfferFacilities : []}
            rowKey={(value) => value.key}
            pagination={false}
            scroll={{ x: true }}
          />
        </Form>
      </Col>
    </Row>
  );
};

export default RDROFacilityTable;
