/* eslint-disable */

import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { getUsageForm, updateUsageFormState } from 'api/usageForm';
import { GetUsageFormResponse, RawReportAttachFile, UsageForm } from 'api/usageForm/interface';
import { Col, Row } from 'antd';
import { ButtonComponent, HeaderTitleComponent } from 'components';
import FormFilesUpload from '../components/Form-Files-Upload';
import { parseInt } from 'lodash';
import TelecomForm from 'features/DataAndConnection/Operator/components/modals/telecom/TelecomForm';
import { AuditOutlined, RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import ConnectForm from 'features/DataAndConnection/Operator/components/modals/connect-form/connect-form';
import FormFileUploadWithOutDropDown from 'features/DataAndConnection/Operator/ImportDocument/components/FormFileUploadWithOutDropDown';
import { mainRoutePaths } from 'constants/routes';

const DetailImportData = (): JSX.Element => {
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const { makeRequest: getUsageFormRequest } = useApi();
  const { makeRequest: updateStatusStateRequest } = useApi();
  const [usageForm, setUsageForm] = useState<UsageForm>();
  const [telecomModal, setTelecomModal] = useState(false);
  const [connectModal, setConnectModal] = useState(false);
  const { id } = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [disableUsageType, setDisableUsageType] = useState<number[]>([]);
  const [disableTelecomModal, setDisableTelecom] = useState(true);
  const [disableConnectModal, setDisableConnect] = useState(true);
  const [rawReportAttachFile, setRawReportAttachFile] = useState<RawReportAttachFile[]>([]);
  const navigate = useNavigate();

  const onUpdateStatus = () => {
    if (id) {
      updateStatusStateRequest(async () => {
        await updateUsageFormState(id);
        const res: any = await getUsageForm(parseInt(id));
        const result: GetUsageFormResponse = res.data;
        setUsageForm(result.data);
        return res;
      });
    }
  };

  const fetchUsageForm = async () => {
    if (id) {
      await getUsageFormRequest(async () => {
        const res: any = await getUsageForm(parseInt(id));
        const result: GetUsageFormResponse = res.data;
        setUsageForm(result.data);
        const hasTelecom = result.data.usageAttachFiles.some(
          (i) => i.usageTypeId == 1 && i.status === 'COMPLETED'
        );
        const hasConnection = result.data.usageAttachFiles.some(
          (i) => [2, 3, 4, 5, 6].includes(i.usageTypeId) && i.status === 'COMPLETED'
        );
        if (hasTelecom) {
          setDisableTelecom(false);
        } else {
          setDisableTelecom(true);
        }

        if (hasConnection) {
          setDisableConnect(false);
        } else {
          setDisableConnect(true);
        }

        const disableUsageType: number[] = [];
        result.data.usageAttachFiles.forEach((i) => {
          if (i.considerResult === 'ผ่าน') {
            disableUsageType.push(i.usageTypeId);
          }
        });
        setRawReportAttachFile(result.data.rawReportAttachFiles);
        setDisableUsageType(disableUsageType);
        return res;
      });
    }
  };

  useEffect(() => {
    setHeaderTitle('เพิ่ม/แก้ไข ข้อมูลนำส่งรายไตรมาศ');
    if (id) {
      fetchUsageForm();
    }
  }, [id]);
  return (
    <div>
      {usageForm && id && (
        <TelecomForm setTelecomModal={setTelecomModal} isOpen={telecomModal} id={parseInt(id)} />
      )}
      {usageForm && id && (
        <ConnectForm setConnectModal={setConnectModal} isOpen={connectModal} id={parseInt(id)} />
      )}

      <HeaderTitleComponent>รายละเอียด</HeaderTitleComponent>
      <div style={{ padding: 24 }}>
        <Row gutter={[16, 16]}>
          <Col span={6}>ปี พ.ศ. : {usageForm?.year}</Col>
          <Col span={6}>ไตรมาสที่ : {usageForm?.quarter}</Col>
          <Col span={12}>สถานะการนำส่งข้อมูล: {usageForm?.formStatusName}</Col>
        </Row>

        <Row
          style={{
            marginTop: 64,
          }}
        >
          <Col span={24}>
            {usageForm && (
              <FormFilesUpload
                onRefresh={() => fetchUsageForm()}
                disabledUsageTypes={disableUsageType}
                id={parseInt(id || '1', 0)}
                status={usageForm.formStatus}
              ></FormFilesUpload>
            )}
          </Col>

          <Col span={24}>
            {usageForm && (
              <FormFileUploadWithOutDropDown
                files={rawReportAttachFile}
                onRefresh={() => fetchUsageForm()}
                id={parseInt(id || '1', 0)}
                status={usageForm.formStatus}
              />
            )}
          </Col>
        </Row>

        <Row justify={'end'} gutter={[16, 16]}>
          <Col flex={'80px'}>
            <ButtonComponent
              htmlType="button"
              key={'navigate-back'}
              onClick={() => {
                navigate(mainRoutePaths.dataConnectionOperator);
              }}
            >
              <RollbackOutlined />
              ย้อนกลับ
            </ButtonComponent>
          </Col>
          <Col flex={'150px'}>
            <ButtonComponent
              key="summit-usage-form"
              htmlType="button"
              disabled={disableConnectModal}
              onClick={() => {
                setConnectModal(true);
              }}
            >
              <AuditOutlined />
              บันทึกแบบแจ้งข้อมูลการเชื่อมต่อ
            </ButtonComponent>
          </Col>

          <Col flex={'150px'}>
            <ButtonComponent
              key="summit-usage-form"
              htmlType="button"
              disabled={disableTelecomModal}
              onClick={() => {
                setTelecomModal(true);
              }}
            >
              <AuditOutlined />
              บันทึกแบบแจ้งข้อมูลการใช้
            </ButtonComponent>
          </Col>

          <Col flex={'80px'}>
            <ButtonComponent
              disabled={usageForm?.formStatus === 2 || usageForm?.formStatus === 4}
              htmlType="button"
              onClick={() => {
                setSubmitted(true);
              }}
            >
              <SaveOutlined />
              บันทึกรายการ
            </ButtonComponent>
          </Col>
          <Col flex={'150px'}>
            <ButtonComponent
              disabled={usageForm?.formStatus === 2 || usageForm?.formStatus === 4 || !submitted}
              htmlType="button"
              onClick={onUpdateStatus}
            >
              <SaveOutlined />
              ยืนยันการนำส่งข้อมูล
            </ButtonComponent>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default DetailImportData;
