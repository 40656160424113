// NOTE: [24 Nov 2023] config for ReviewPage
/*
  STATUS: 6, 12
    - Enable everything
  STATUS: OHTER THAN 6, 12
    - Hide ยืนยันการบันทึก, ยืนยันการจัดส่งเอกสาร button
    - Enable to edit
  STATUS: 17, 18 (Finished Status)
    - Hide every buttons except back button
*/

// NOTE: UPDATED [9 Jan 2024] config for ReviewPage
/*
  STATUS: 1 - 16
    - Hide ยืนยันการบันทึก, ยืนยันการจัดส่งเอกสาร button
    - Enable to edit
  STATUS: 17, 18 (Finished Status)
    - Hide every buttons except back button
    - Disable upload file
*/

const ALLOW_IMPROVE_STATUS: any = [];
const FINISHED_STATUS = [17];

const isNotFinishedStatus = (offerFormStatus: number) => !FINISHED_STATUS.includes(offerFormStatus);
const isShowSaveContractButton = isNotFinishedStatus;

const isShowConfirmContractButton = (offerFormStatus: number) =>
  ALLOW_IMPROVE_STATUS.includes(offerFormStatus);
const isShowConfirmDocButton = isShowConfirmContractButton;

const isDisabled = (offerFormStatus: number) => FINISHED_STATUS.includes(offerFormStatus);
const isShowEditContract = false;

const configReviewPage = {
  isDisabled,
  isShowEditContract,
  isShowSaveContractButton,
  isShowConfirmContractButton,
  isShowConfirmDocButton,
};

export default configReviewPage;
