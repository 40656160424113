import { createContext } from 'react';
import { FormInstance } from 'antd';

import useManageContractServices from '../hooks/useManageContractServices';

import {
  DropdownType,
  IContServePay,
  IContServesData,
  IContractData,
  IFacilitiesType,
} from '../interfaces';

// -----------------------
// NOTE: Context
// -----------------------
type TContractServicesContext = {
  initial: string;
  isContServeModalOpen: boolean;
  isContServePayModalOpen: boolean;
  isDisabled: boolean;
  contServeModalFormRef: FormInstance<any>;
  contServePayModalFormRef: FormInstance<any>;
  currentContServes: IContServesData[];
  currentContServePays: IContServePay[];
  selectedMultipleServices: React.Key[];
  selectedContServe: IContServesData | undefined;
  selectedServiceOption: DropdownType | undefined;
  selectedContServePay: IContServePay | undefined;
  onEditContServes: (value: { contServes: IContServesData }) => void;
  onSelectMultipleServices: (selectedRowKeys: React.Key[], selectedRows: any[]) => void;
  onAddNewContServes: () => void;
  onDeleteContServes: (currentContFacilities: IFacilitiesType[]) => void;
  onCloseContServesModal: () => void;
  onSubmitContServesModalForm: () => void;
  onConfirmChangeOfferServe: (option: DropdownType) => void;
  onAddNewContServePay: () => void;
  onEditContServePay: (servePay: IContServePay) => void;
  onDeleteContServePay: (servePay: IContServePay) => void;
  onCloseContServePayModal: () => void;
  onSubmitContServePayModalForm: () => void;
  onResetCurrentContServes: () => void;
};

const ConractServicesContext = createContext<TContractServicesContext>(
  {} as TContractServicesContext
);

// -----------------------
// NOTE: Provider
// -----------------------

type TContractServicesProvider = {
  children: React.ReactNode;
  contractData: IContractData;
  isDisabled: boolean;
};

const ContractServicesProvider = ({
  children,
  contractData,
  isDisabled,
}: TContractServicesProvider) => {
  const {
    isContServeModalOpen,
    isContServePayModalOpen,
    contServeModalFormRef,
    contServePayModalFormRef,
    currentContServes,
    currentContServePays,
    selectedMultipleServices,
    selectedContServe,
    selectedServiceOption,
    selectedContServePay,
    onEditContServes,
    onSelectMultipleServices,
    onAddNewContServes,
    onDeleteContServes,
    onCloseContServesModal,
    onSubmitContServesModalForm,
    onConfirmChangeOfferServe,
    onAddNewContServePay,
    onEditContServePay,
    onDeleteContServePay,
    onCloseContServePayModal,
    onSubmitContServePayModalForm,
    onResetCurrentContServes,
  } = useManageContractServices({
    contServes: contractData?.contServes,
  });

  return (
    <ConractServicesContext.Provider
      value={{
        initial: 'initial',
        isContServeModalOpen,
        isContServePayModalOpen,
        isDisabled,
        contServeModalFormRef,
        contServePayModalFormRef,
        currentContServes,
        currentContServePays,
        selectedMultipleServices,
        selectedContServe,
        selectedServiceOption,
        selectedContServePay,
        onEditContServes,
        onSelectMultipleServices,
        onAddNewContServes,
        onDeleteContServes,
        onCloseContServesModal,
        onSubmitContServesModalForm,
        onConfirmChangeOfferServe,
        onAddNewContServePay,
        onEditContServePay,
        onDeleteContServePay,
        onCloseContServePayModal,
        onSubmitContServePayModalForm,
        onResetCurrentContServes,
      }}
    >
      {children}
    </ConractServicesContext.Provider>
  );
};

export { ConractServicesContext, ContractServicesProvider };
