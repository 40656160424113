import { Space, Row, Col, Button } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear, getYearFromDateString } from 'utils/date';

interface IStepWithApproveFile extends IStepDescription {
  dateLabel?: string;
}

const StepWithApproveFile = ({
  stepData,
  isDisable,
  dateLabel,
  isShowApproveStatus = false,
}: IStepWithApproveFile) => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Space>
            <label>{dateLabel ? dateLabel : 'วันที่ส่งออก'} :</label>
            <span>
              {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
            </span>
          </Space>
        </Col>
        {isShowApproveStatus && (
          <Col span={24}>
            <Space>
              <label>สถานะสัญญา :</label>
              <span>{stepData?.actionName ? stepData?.actionName : '-'}</span>
            </Space>
          </Col>
        )}
        <Col span={24}>
          <Space>
            <label>มติครั้งที่ :</label>
            <span>
              {stepData?.approvedNo
                ? `${stepData?.approvedNo}/${getYearFromDateString(stepData?.approvedDate)}`
                : '-'}
            </span>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            <label>วันที่ลงมติ :</label>
            <span>
              {stepData?.approvedDate ? dayJsformatDateToBCYear(stepData?.approvedDate) : '-'}
            </span>
          </Space>
        </Col>
        <Col span={24}>
          <Space>
            <label>ไฟล์แนบ :</label>
            <Button size="small" disabled={isDisable}>
              -- กรุณาคลิกเพื่อดูไฟล์แนบ --
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithApproveFile;
