import axiosInstance from 'utils/axios';
import { ICreateAndAssingContract, ListContractQuery } from './interface';

// NOTE: GET
export const getContractById = (id: string | number) => {
  return axiosInstance.get(`/contract/${id}`);
};

// NOTE: List
export const getOfferServes = (offerId: string | number) => {
  return axiosInstance.get(`/contract/offer-serves`, {
    params: {
      offerId,
    },
  });
};

export const getContractList = (query: ListContractQuery) => {
  return axiosInstance.get(`/contract`, {
    params: query,
  });
};

export const getContractListForOfficer = (query: ListContractQuery) => {
  return axiosInstance.get(`/contract-form`, {
    params: query,
  });
};

export const listContNotiDocType = (state?: string) => {
  return axiosInstance.get(`/master/doc/doc-type/CONT-NOTI`, {
    params: { state },
  });
};

export const listCONTDocType = () => {
  return axiosInstance.get(`/master/doc/doc-type/CONT`);
};

export const listCONTCancelDocType = () => {
  return axiosInstance.get(`/master/doc/doc-type/CONT-CANCEL`);
};

export const listContractState = () => {
  return axiosInstance.get(`/contract/review/contract-state`);
};

export const listContractType = () => {
  return axiosInstance.get(`/contract/contract-type`);
};

export const listContractStatus = () => {
  return axiosInstance.get(`/contract/contract-status`);
};

export const listContractOfficer = () => {
  return axiosInstance.get(`/contract/contract-officer`);
};

export const listContractReviewState = (conFormId: number | string) => {
  return axiosInstance.get(`/contract/review/${conFormId}/state`);
};

// NOTE: PUT
export const updateContract = (conFormId: number, data: any) => {
  return axiosInstance.put(`/contract/${conFormId}/update`, data);
};

export const updateNewContract = (conFormId: number | string) => {
  return axiosInstance.put(`/contract/${conFormId}/updateAndNew`);
};

export const cancelContract = (conFormId: number | string, data: string) => {
  return axiosInstance.put(`/contract/${conFormId}/cancel`, data);
};

export const confirmContractDoc = ({ conFormId, data }: { conFormId: number; data: any }) => {
  return axiosInstance.put(`/contract/${conFormId}/operator/confirm`, data);
};

// NOTE: PATCH
export const updateContractToWaitFile = (conFormId: number | string) => {
  return axiosInstance.patch(`/contract/${conFormId}/changeFormStatusToWaitFile`);
};

// NOTE: POST
export const createContract = (data: any) => {
  return axiosInstance.post(`/contract/create`, data);
};

export const createAndAssignContract = (data: ICreateAndAssingContract) => {
  return axiosInstance.post(`/contract/createAndAssign`, data);
};

// NOTE: File
export const uploadAttachmentFile = (conFormId: number, formData: any) => {
  return axiosInstance.post(`/contract/${conFormId}/attachment`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const downloadAttachmentFile = (attachFileId: string | number) => {
  return axiosInstance.get(`/contract/attachment/${attachFileId}/download`, {
    responseType: 'blob', // This tells Axios to return the response as a blob object
  });
};

export const deleteUploadedFile = (attachFileId: string | number) => {
  return axiosInstance.delete(`/contract/attachment/${attachFileId}/delete`);
};
