import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';

import { TApiResponse, useApi } from 'hooks/useApi';
import useDialogAlert from 'hooks/useDialogAlert';
import useFileManagement from './useFileManagement';

import { cancelContract, uploadAttachmentFile } from 'api/contract';

import { getContractNameFromOfferFormType } from '../utils/offerFormType';
import { IContAttachFiles } from '../interfaces';

type UseCancelContractModalProps = {
  handleCancel: Function;
  offerFormType: string;
  conFormId: string | undefined;
  contAttachFiles: IContAttachFiles[];
  setContractData: React.Dispatch<any>;
};

const useCancelContractModal = ({
  handleCancel,
  offerFormType,
  conFormId,
  contAttachFiles,
  setContractData,
}: UseCancelContractModalProps) => {
  const [formRef] = Form.useForm();
  const [uploadFormRef] = Form.useForm();

  const { error } = useDialogAlert();
  const { makeRequest, loading }: TApiResponse = useApi();
  const {
    fileList,
    isUploadingFile,
    isDeletingFile,
    handleUpload,
    setCurrentUploadFile,
    setFileList,
    onRemoveFile,
    downloadFile,
    validateRequiredFiles,
    sortRequiredDoc,
  } = useFileManagement();

  const [docId, setDocId] = useState<number | undefined>(undefined);
  const [docName, setDocName] = useState<string>('');
  const [isOpenFileDialog, setIsOpenFileDialog] = useState(false);
  const [selectedDocTypeOption, setSelectedDocTypeOption] = useState<any>(undefined);
  const contractTypeName = useMemo(() => {
    return getContractNameFromOfferFormType(offerFormType);
  }, [offerFormType]);

  const onUploadFile = useCallback(async () => {
    if (docId && conFormId) {
      await handleUpload({
        docId,
        docName,
        required: selectedDocTypeOption?.requied,
        id: Number(conFormId),
        api: uploadAttachmentFile,
      });
      uploadFormRef.setFieldValue('docId', undefined);
      setDocId(undefined);
    }
  }, [docId, docName, handleUpload, uploadFormRef, conFormId, selectedDocTypeOption]);

  const onValidateDocType = useCallback(() => {
    try {
      uploadFormRef.validateFields(['docId']);
    } catch (error) {
      console.error(error);
    }
  }, [uploadFormRef]);

  const onValidateRequiredDoc = () => {
    const isDocValid = validateRequiredFiles();
    if (isDocValid !== -1) throw new Error('กรุณาแนบไฟล์ที่จำเป็นให้ครบ');
    return;
  };

  const onSubmitCancelContract = async (values: any) => {
    try {
      onValidateRequiredDoc();
      if (!conFormId) throw new Error('ไม่พบรหัสสัญญา');
      const response = await makeRequest(() => cancelContract(conFormId, values));
      setContractData(response.data?.data);
      handleCancel();
    } catch (e: any) {
      error({
        header: e.message || 'มีข้อผิดพลาดเกิดขึ้น',
        sub: '',
      });
    }
  };

  useEffect(() => {
    // NOTE: กรณีที่เคยอัพโหลด doc เสร็จแล้วต้อง check doctype ใหม่
    if (docId === undefined) {
      setIsOpenFileDialog(false);
    }
  }, [docId, setIsOpenFileDialog]);

  useEffect(() => {
    if (contAttachFiles) {
      setFileList(sortRequiredDoc(contAttachFiles));
    }
  }, [contAttachFiles, setFileList, sortRequiredDoc]);

  return {
    loading,
    isOpenFileDialog,
    isUploadingFile,
    isDeletingFile,
    formRef,
    uploadFormRef,
    contractTypeName,
    fileList,
    onSubmitCancelContract,
    onUploadFile,
    onValidateDocType,
    onRemoveFile,
    downloadFile,
    handleUpload,
    setDocId,
    setDocName,
    setIsOpenFileDialog,
    setSelectedDocTypeOption,
    setCurrentUploadFile,
  };
};

export default useCancelContractModal;
