import { Space } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

import PrimaryButton from 'components/PrimaryButton';

const FacilitiesActionButtons = () => {
  const { onAddNewContractFacility, onDeleteContractFacilities, isDisabled } =
    useContractFacilitiesContext();

  return (
    <div>
      <Space>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<PlusOutlined />}
          onClick={onAddNewContractFacility}
        >
          เพิ่มสิ่งอำนวยความสะดวก
        </PrimaryButton>
        <PrimaryButton
          size="middle"
          disabled={isDisabled}
          icon={<DeleteOutlined />}
          onClick={onDeleteContractFacilities}
        >
          ลบสิ่งอำนวยความสะดวก
        </PrimaryButton>
      </Space>
    </div>
  );
};

export default FacilitiesActionButtons;
