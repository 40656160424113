/* eslint-disable */

import { Col, Form, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { ButtonComponent } from 'components';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import { mainRoutePaths } from 'constants/routes';

interface ImportInput {
  form: FormInstance;
  onSubmit: () => void;
}

const ImportDataForm = ({ form, onSubmit }: ImportInput): JSX.Element => {
  const [years, setYears] = useState<any[]>();
  const [quarter, setQuarter] = useState<any[]>();
  const { setHeaderTitle }: headerTitleContext = useOutletContext();
  const navigate = useNavigate();

  const filterOption = (input: string, option?: { label: string; value: number }) => {
    return option?.value.toString().includes(input) || false;
  };

  useEffect(() => {
    setHeaderTitle('เพิ่ม/แก้ไข ข้อมูลนำส่งรายไตรมาศ');
    const listYear: any[] = [];
    for (let i = 2539; i <= new Date().getFullYear() + 544; i++) {
      listYear.push({
        value: i,
        label: i,
      });
    }
    setQuarter([
      {
        value: 1,
        label: 1,
      },
      {
        value: 2,
        label: 2,
      },
      {
        value: 3,
        label: 3,
      },
      {
        value: 4,
        label: 4,
      },
    ]);
    setYears(listYear);
  }, [setHeaderTitle]);
  return (
    <div className="padding-conten-main">
      <div className="padding-content">
        <Form form={form} style={{ width: '100%' }}>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="year" label="ปี พ.ศ.">
                <Select
                  showSearch
                  placeholder="ระบุปี พ.ศ."
                  onChange={() => {}}
                  allowClear
                  options={years}
                  filterOption={filterOption}
                ></Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="quarter" label="ไตรมาสที่ :">
                <Select
                  placeholder="ระบุไตรมาส"
                  onChange={() => {}}
                  allowClear
                  options={quarter}
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="end" gutter={[16, 16]} style={{ marginTop: '16px' }}>
            <Col flex={'80px'} style={{ textAlign: 'right' }}>
              <ButtonComponent
                style={{
                  width: '120px',
                }}
                htmlType="button"
                onClick={() => {
                  navigate(mainRoutePaths.dataConnectionOperator);
                }}
              >
                <RollbackOutlined />
                ย้อนกลับ
              </ButtonComponent>
            </Col>
            <Col flex={'80px'} style={{ textAlign: 'right' }}>
              <ButtonComponent
                style={{
                  width: '200px',
                }}
                htmlType="submit"
                onClick={onSubmit}
              >
                <SaveOutlined />
                บันทึกรายการ
              </ButtonComponent>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
export default ImportDataForm;
