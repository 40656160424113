import { useEffect } from 'react';
import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listUnitByService } from 'api/master';
import { onFilterOption } from 'utils/select';

type ServiceUnitDropdownProps = {
  onChangeOption: (value: any) => void;
  raoServId: number | string | undefined | null;
  label?: string;
  disabled?: boolean;
  isRequired?: boolean;
};

const ServiceUnitDropdown = ({
  onChangeOption,
  raoServId,
  label,
  disabled = false,
  isRequired = true,
}: ServiceUnitDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    if (raoServId !== undefined && raoServId !== null)
      makeRequest(() => listUnitByService(raoServId));
  }, [makeRequest, raoServId]);

  return (
    <>
      <Form.Item
        colon
        name="unitId"
        label={label ? label : 'หน่วย (Unit)'}
        labelCol={{ span: 10 }}
        rules={
          isRequired
            ? [{ required: true, message: `กรุณาเลือก${label ? label : 'หน่วย (Unit)'}` }]
            : []
        }
      >
        <Select
          labelInValue
          showSearch
          filterOption={onFilterOption}
          allowClear={isRequired ? false : true}
          loading={loading}
          disabled={disabled}
          placeholder="-- กรุณาระบุ --"
          onChange={(_, options) => onChangeOption(options)}
          options={
            data
              ? data.map((item: any) => ({
                  value: item.unitId,
                  label: item.unitName,
                  desc: item.unitDesc,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ServiceUnitDropdown;
