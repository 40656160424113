import { Space } from 'antd';
import AttachmentOperatorForm from '../AttachmentOperatorForm';
import UploadOfferFilesSection from '../UploadOfferFilesSection';

const OfferAttachmentTab = () => {
  return (
    <Space direction="vertical" size="middle">
      <AttachmentOperatorForm />
      <UploadOfferFilesSection />
    </Space>
  );
};

export default OfferAttachmentTab;
