import OperatorDashboardComponent from 'features/Dashboard/OperatorDashboard/components/operator-dashboard';

const OperatorDashboard = () => {
  return (
    <div>
      <h1>Dashboard</h1>
      <OperatorDashboardComponent />
    </div>
  );
};

export default OperatorDashboard;
