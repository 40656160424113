import { useState, useCallback, useEffect } from 'react';
import { Steps, Row, Col, StepProps } from 'antd';

import { StepsDataType } from 'features/OfferConsideration/interfaces';

import StepWithDate from '../StepsDescription/StepWithDate';
import StepWithFile from '../StepsDescription/StepWithFile';
// import StepWithApprove from '../StepsDescription/StepWithApprove';
import StepWithApproveFile from '../StepsDescription/StepWithApproveFile';
import StepWithLink from '../StepsDescription/StepWithLink';
import StepWithStatus from '../StepsDescription/StepWithStatus';
import StepWithEmailFile from '../StepsDescription/StepWithEmailFile';

import { stateCode } from 'constants/stateCode';
import { getIsDisableModal, getStepStatus } from 'utils/steps';

type StepsDetailProps = {
  stepsData?: StepsDataType[];
  showModal: Function;
  offerData: any;
};

const StepsDetail = ({ stepsData, showModal, offerData }: StepsDetailProps) => {
  const [stepItems, setStepItems] = useState<StepProps[] | undefined>(undefined);

  const populateStepsItemData = useCallback(() => {
    let stepsItems: StepProps[] | undefined = [];

    if (stepsData) {
      for (let i = 0; i < stepsData.length; i++) {
        let item = stepsData[i];
        const isDisable = getIsDisableModal(item.isCurrentState, item.isCompleteState);
        const _status = getStepStatus(item.isCurrentState, item.isCompleteState);

        let _item: StepProps = {
          title: `${item.stateName}`,
          status: _status,
          disabled: isDisable,
          onClick: isDisable ? undefined : () => showModal(i),
        };

        switch (i) {
          case stateCode.step3:
          case stateCode.step7:
          case stateCode.step10:
            _item['description'] = <StepWithDate stepData={item} />;
            break;
          case stateCode.step17:
            _item['description'] = <StepWithDate stepData={item} dateLabel="วันที่ตรวจสอบ" />;
            break;
          case stateCode.step4:
            _item['description'] = <StepWithStatus stepData={item} />;
            break;
          case stateCode.step5:
          case stateCode.step11:
          case stateCode.step15:
            _item['description'] = (
              <StepWithFile stepData={item} isDisable={isDisable} label="วันที่หนังสือ" />
            );
            break;
          case stateCode.step8:
          case stateCode.step13:
            _item['description'] = (
              <StepWithFile stepData={item} isDisable={isDisable} label="วันที่บันทึก" />
            );
            break;
          case stateCode.step9:
          case stateCode.step14:
            _item['description'] = (
              <StepWithApproveFile
                stepData={item}
                isDisable={isDisable}
                dateLabel="วันที่รับเรื่อง"
              />
            );
            break;
          case stateCode.step16:
            _item['description'] = (
              <StepWithLink stepData={item} offerFormPublicUrl={offerData?.offerFormPublicUrl} />
            );
            break;
          case stateCode.step1:
          case stateCode.step2:
            _item['description'] = <div style={{ marginBottom: 15 }} />;
            break;
          case stateCode.step6:
          case stateCode.step12:
            _item['description'] = <StepWithEmailFile isDisable={isDisable} stepData={item} />;
            break;
          default:
            break;
        }

        stepsItems.push(_item);
      }
    }

    return stepsItems;
  }, [stepsData, showModal, offerData]);

  useEffect(() => {
    const stepsItems = populateStepsItemData();
    setStepItems(stepsItems);
  }, [populateStepsItemData]);

  return (
    <div className="content-box" style={{ marginLeft: 40 }}>
      <Row>
        <Col>
          <Steps direction="vertical" items={stepItems} />
        </Col>
      </Row>
    </div>
  );
};

export default StepsDetail;
