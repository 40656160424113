// NOTE: Please DON'T USE this file anymore.
// Use setHeaderTitle in the component instead
// (For example pls look at ListOffer.tsx)
import { mainRoutePaths, subRoutePaths } from 'constants/routes';

export const headerText = (pathname: any) => {
  switch (pathname) {
    case subRoutePaths.offerRAONew:
      return 'บันทึกข้อเสนอ RAO ';
    case mainRoutePaths.offerRAO:
      return 'ค้นหาข้อเสนอ RAO';
    case mainRoutePaths.offerRIO:
      return 'ค้นหาข้อเสนอ RIO';
    case mainRoutePaths.offerReview:
      return 'ระบบฐานข้อมูลการใช้และเชื่อมต่อโครงข่ายโทรคมนาคม';
  }
};
