import { useEffect, useMemo } from 'react';
import { Col, FormInstance } from 'antd';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import useQuery from 'hooks/useQuery';
import useAuth from 'hooks/useAuth';
import { DETAIL_PAGE, EDIT_PAGE } from 'constants/page';

import { getPageFunction } from 'features/Contract/utils/offerFormType';
import OwnerDropdown from '../OwnerDropdown';
import ContractTypeDropdown from '../ContractTypeDropdown';

type OfferTypeOwnerFormProps = {
  contractFormRef: FormInstance<any>;
  onChangeOwner: Function;
};

const OfferTypeOwnerForm = ({ contractFormRef, onChangeOwner }: OfferTypeOwnerFormProps) => {
  let query = useQuery();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { offerFormType } = useParams<{ offerFormType: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageFunction = useMemo(() => getPageFunction(pathname), [pathname]);

  const _onChangeOwner = (value: any) => {
    // Update the value of the 'exampleParam' parameter
    searchParams.set('ownerId', value);
    // Call setSearchParams to update the URL
    setSearchParams(searchParams);
    onChangeOwner(value);
  };

  useEffect(() => {
    const ownerId = pageFunction === EDIT_PAGE ? user?.referenceNo : query.get('ownerId');
    contractFormRef.setFieldsValue({
      offerFormType: offerFormType,
      ownerId: ownerId,
    });
  }, [contractFormRef, offerFormType, query, user, pageFunction]);

  return (
    <>
      <Col span={16}>
        <OwnerDropdown
          label="ผู้ประกอบการ"
          labelCol={{ span: 6 }}
          rules={[{ required: true, message: `กรุณาเลือกผู้ประกอบการ` }]}
          onChange={_onChangeOwner}
          name="ownerId"
          disabled={pageFunction === EDIT_PAGE || pageFunction === DETAIL_PAGE}
          isLabelInValue={false}
        />
      </Col>
      <Col span={16}>
        <ContractTypeDropdown labelCol={{ span: 6 }} disabled={true} />
      </Col>
    </>
  );
};

export default OfferTypeOwnerForm;
