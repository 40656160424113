import { useMemo } from 'react';

import configReviewPage from 'features/Contract/config/configReviewPage';
import { FINISHED_STATE } from 'features/Contract/constant';

type UseConditionConfigProps = {
  isReviewPage: boolean;
  stateCode: number | undefined;
  isNewPage: boolean;
};

const useConditionConfig = ({ isReviewPage, stateCode, isNewPage }: UseConditionConfigProps) => {
  const isShowEditContract = useMemo(() => {
    if (!stateCode) return false;
    if (isReviewPage) return configReviewPage.isShowEditContract;
    if (stateCode) {
      return FINISHED_STATE.includes(stateCode) && !isNewPage;
    }
    return false;
  }, [stateCode, isNewPage, isReviewPage]);

  const isShowSaveContractButton = useMemo(() => {
    if (isNewPage) return true;
    if (!stateCode) return false;
    if (isReviewPage) return configReviewPage.isShowSaveContractButton(stateCode);
    return true;
  }, [stateCode, isReviewPage, isNewPage]);

  const isShowConfirmContractButton = useMemo(() => {
    if (isNewPage) return true;
    if (!stateCode) return false;
    if (isReviewPage) return configReviewPage.isShowConfirmContractButton(stateCode);
    return true;
  }, [stateCode, isReviewPage, isNewPage]);

  return { isShowEditContract, isShowSaveContractButton, isShowConfirmContractButton };
};

export default useConditionConfig;
