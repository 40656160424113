import { Table } from 'antd';

import useContractServicesContext from 'features/Contract/hooks/useContractServicesContext';

import { expandedRowRenderer, getColumns } from './columns';

const ServicesTable = () => {
  const {
    currentContServes,
    onEditContServes,
    onSelectMultipleServices,
    selectedMultipleServices,
    isDisabled,
  } = useContractServicesContext();

  const rowSelection = {
    onChange: onSelectMultipleServices,
    selectedRowKeys: selectedMultipleServices,
  };

  return (
    <div>
      <Table
        bordered
        size="middle"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={getColumns({ onEditContServes, isDisabled })}
        expandable={{
          expandedRowRender: (record, index, indent, expanded) =>
            expandedRowRenderer({ record, index, indent, expanded }),
          defaultExpandedRowKeys: ['0'],
        }}
        dataSource={currentContServes ? currentContServes : []}
        rowKey={(value) => value.key}
        pagination={false}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default ServicesTable;
