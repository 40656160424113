export const getAddress = (addressObj: any) => {
  return `${addressObj?.conNo ? `${addressObj?.conNo} ` : ''}${
    addressObj?.conMoo ? `${addressObj?.conMoo} ` : ''
  }${addressObj?.conSoi ? `${addressObj?.conSoi} ` : ''}${
    addressObj?.conRoad ? `${addressObj?.conRoad} ` : ''
  }${addressObj?.conDistrictName ? `${addressObj?.conDistrictName} ` : ''}${
    addressObj?.conSubDistrictName ? `${addressObj?.conSubDistrictName} ` : ''
  }${addressObj?.conProvinceName ? `${addressObj?.conProvinceName} ` : ''}${
    addressObj?.conPost ? `${addressObj?.conPost} ` : ''
  }`;
};
