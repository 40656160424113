export const OFFER_TYPE_RISO = 'RISO';
export const OFFER_TYPE_RIO = 'RIO';
export const OFFER_TYPE_RAO = 'RAO';
export const OFFER_TYPE_RDRO = 'RDRO';

export const OFFER_TYPE_RISO_LABEL =
  'ข้อเสนอการใช้โครงสร้างพื้นฐานโทรคมนาคมร่วมกันสำหรับโครงข่ายโทรคมนาคมไร้สาย';
export const OFFER_TYPE_RIO_LABEL = 'ข้อเสนอการเชื่อมต่อโครงข่ายโทรคมนาคม';
export const OFFER_TYPE_RAO_LABEL = 'ข้อเสนอการใช้โครงข่ายโทรคมนาคม';
export const OFFER_TYPE_RDRO_LABEL = 'ข้อเสนอการใช้บริการข้ามโครงข่ายโทรคมนาคมไร้สายภายในประเทศ';
