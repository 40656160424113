/**
 * NOTE: [31 July 2023] From P'Kanith
 * 1 = เดือน
 * 2 = ปี
 * 3 = ตามปริมาณการใช้งาน
 */

import {
  PAY_PERIOD_UNIT_MONTH,
  PAY_PERIOD_UNIT_USAGE,
  PAY_PERIOD_UNIT_YEAR,
} from 'constants/payPeriodUnitName';

export const getPayPeriodUnitName = (payPeriodUnit: number) => {
  switch (payPeriodUnit) {
    case 1:
      return PAY_PERIOD_UNIT_MONTH;
    case 2:
      return PAY_PERIOD_UNIT_YEAR;
    case 3:
      return PAY_PERIOD_UNIT_USAGE;
    default:
      return '-';
  }
};
