import { useCallback } from 'react';

import { IContServesData, IFacilitiesType, IOfferFacilities } from 'features/Contract/interfaces';
import { OTHER_OPTION } from 'features/Offer/constant';

const useValidation = () => {
  const onCheckExistingServiceInFacilites = useCallback(
    ({
      contServes,
      currentContFacilities,
      isThrowError = true,
    }: {
      contServes: IContServesData;
      currentContFacilities: IFacilitiesType[];
      isThrowError?: boolean;
    }) => {
      const isExisting = currentContFacilities.some((facility: IFacilitiesType) => {
        if (facility.contFacilitiesServes) {
          const isExistingService = facility.contFacilitiesServes.some(
            (_service: IOfferFacilities) => {
              if (_service.servName === OTHER_OPTION)
                return _service.serviceDesc === contServes.serviceDesc;
              return _service.servId === contServes.servId;
            }
          );
          if (isExistingService) return true;
        }
        return false;
      });

      if (!isThrowError) return isExisting;
      if (isExisting) {
        throw new Error('ไม่สามารถทำรายการได้');
      }
    },
    []
  );

  const validateIsServicesAtLeastOne = useCallback((currentContServes: IContServesData[]) => {
    if (currentContServes.length <= 0) throw new Error('ต้องมีบริการอย่างน้อย 1 บริการ');
  }, []);

  return { onCheckExistingServiceInFacilites, validateIsServicesAtLeastOne };
};

export default useValidation;
