import axiosInstance from 'utils/axios';
import { DownloadContractHistoryFileQuery } from './interface';

// NOTE: List
export const listContractHistory = (conFormId: number) => {
  return axiosInstance.get(`/contract/history/contract/${conFormId}`);
};

// NOTE: File
export const downloadContractHistoryFile = (query: DownloadContractHistoryFileQuery) => {
  return axiosInstance.get(`/contract/history/attachment/download`, {
    responseType: 'blob',
    params: query,
  });
};
