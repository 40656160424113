import { useCallback } from 'react';

import useFileManagement from 'features/Contract/hooks/useFileManagement';
import { getFileName } from '../utils';

type DownloadReportProps = {
  downloadReportByJobIdApi: Function;
};

const useDownloadReport = ({ downloadReportByJobIdApi }: DownloadReportProps) => {
  const { downloadFile } = useFileManagement();

  const onDownloadReport = useCallback(
    (record: any) => {
      downloadFile({
        fileId: record.jobId,
        fileName: getFileName(record),
        api: () => downloadReportByJobIdApi(record.jobId),
      });
    },
    [downloadFile, downloadReportByJobIdApi]
  );
  return { onDownloadReport };
};

export default useDownloadReport;
