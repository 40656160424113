/* eslint-disable */
import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { getDetailDataOfficer, getUsageFormOfficer, updateTelecomForm } from 'api/usageForm';
import { GetUsageFormResponse, UsageForm } from 'api/usageForm/interface';
import { useApi } from 'hooks/useApi';
import { useForm } from 'antd/es/form/Form';
import InputText from 'components/InputTextComponent';
import { ButtonComponent } from 'components';
import { validateFormNotEmpty } from 'utils/form';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import PDFTelecom, { PDFData } from 'features/DataAndConnection/Operator/components/pdf/PDF-Telecom';
import { DownloadOutlined, FilePdfOutlined } from '@ant-design/icons';

interface TelecomFormInput {
  isOpen: boolean;
  id: number;
  reviewerRole?: string;
  reviewerName?: string;
  frequencyOfServiceQualityProblem?: string;
  frequencyOfTechnicalProblem?: string;
  setTelecomModal: (value: boolean) => void;
}


const TelecomForm = ({ isOpen, id, setTelecomModal }: TelecomFormInput): JSX.Element => {
  const { makeRequest: getUsageFormRequest } = useApi();
  const { makeRequest: updateUsageFormRequest, loading } = useApi();
  const { makeRequest: getFormDetailRequest } = useApi();
  const [disablePdf, setDisablePdf] = useState(true);
  const [previewModal, setPreviewModal] = useState(false);
  const [pdfData, setPdfData] = useState<PDFData>();
  const [validateRules] = useState({
    reviewerRole: true,
    reviewerName: false,
    frequencyOfServiceQualityProblem: false,
    frequencyOfTechnicalProblem: false,
  });
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [usageForm, setUsageForm] = useState<UsageForm>();
  const [form] = useForm();

  const validateForm = (): void => {
    const result = Object.entries(validateRules).every(([key, value]) => {
      if (!value) {
        return true;
      } else {
        return validateFormNotEmpty(form.getFieldValue(key));
      }
    });
    setDisableSubmit(!result);
  };

  useEffect(() => {
    validateForm();
  }, [usageForm]);

  useEffect(() => {
      setTelecomModal(isOpen);
      getUsageFormRequest(async () => {
        const res: any = await getUsageFormOfficer(id);
        const result: GetUsageFormResponse = res.data;
        setUsageForm(result.data);

        if (result.data?.telecomForm) {
          getDetail(id);
        }

        form.setFields([
          {
            name: 'reviewerRole',
            value: result.data?.telecomForm?.reviewerRole,
          },
          {
            name: 'reviewerName',
            value: result.data?.telecomForm?.reviewerName,
          },
          {
            name: 'frequencyOfServiceQualityProblem',
            value: result.data?.telecomForm?.frequencyOfServiceQualityProblem,
          },
          {
            name: 'frequencyOfTechnicalProblem',
            value: result.data?.telecomForm?.frequencyOfTechnicalProblem,
          },
        ]);
        return res;
      });
    }
    , [isOpen]);

  const handleOk = () => {
    setTelecomModal(false);
  };

  const handleCancel = () => {
    setTelecomModal(false);
  };

  const onClosePreviewModal = () => {
    setPreviewModal(false);
  };

  const getDetail = async (id: number) => {
    await getFormDetailRequest(async () => {
      const getDetailResponse = await getDetailDataOfficer(id);
      const res: any = await getUsageFormOfficer(id);
      const result: GetUsageFormResponse = res.data;
      setUsageForm(result.data);

      if (usageForm && getDetailResponse.data.data.accessOffer) {
        setPdfData({
          data: getDetailResponse.data.data.accessOffer,
          operatorName: result.data.operatorName,
          reviewerName: result.data.telecomForm.reviewerName,
          reviewerRole: result.data.telecomForm.reviewerRole,
          frequencyOfServiceQualityProblem: result.data.telecomForm.frequencyOfServiceQualityProblem,
          frequencyOfTechnicalProblem: result.data.telecomForm.frequencyOfTechnicalProblem,
          year: result.data.year,
          quarter: result.data.quarter,
        });
        setDisablePdf(false);
      }
    });
  };

  const onUpdateUsageForm = async () => {
    if (id) {
      updateUsageFormRequest(async () => {
        await updateTelecomForm({
          usageFormId: id,
          reviewerRole: form.getFieldValue('reviewerRole'),
          reviewerName: form.getFieldValue('reviewerName'),
          frequencyOfServiceQualityProblem: form.getFieldValue('frequencyOfServiceQualityProblem') || 0,
          frequencyOfTechnicalProblem: form.getFieldValue('frequencyOfTechnicalProblem') || 0,
        });
        const res = await getUsageFormOfficer(id);
        await getDetail(id);
        return res;
      });
    }
  };
  // @ts-ignore
  return <div>
    <Modal width={850} title='แบบแจ้งข้อมูลการใช้งาน' open={isOpen} onOk={handleOk} onCancel={handleCancel}
           footer={[
             <ButtonComponent
               key='submit'
               htmlType='button'
               disabled={disableSubmit}
               loading={loading}
               onClick={() => {
                 onUpdateUsageForm();
               }}>
               บันทึก
             </ButtonComponent>,
           ]}>
      <Form form={form} onValuesChange={() => {
        validateForm();
      }
      }>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <div style={{
              marginTop: '24px',
            }}><b>ผู้ตรวจสอบข้อมูล</b>
            </div>
          </Col>

          <Col span={24}>
            <Form.Item label='ชื่อ-สกุล' name='reviewerName'>
              <InputText disabled={usageForm?.formStatus === 2 || usageForm?.formStatus === 4} type='text' />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label='ตำแหน่ง' name='reviewerRole'>
              <InputText disabled={usageForm?.formStatus === 2 || usageForm?.formStatus === 4} type='text' />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div style={{
              marginBottom: '16px',
            }}> จำนวนครั้งและความถี่ของปัญหาทางเทคนิคที่เกิดขึ้นในโครงข่ายโทรคมนาคม
            </div>
            <Form.Item style={{ width: '200px' }} name='frequencyOfTechnicalProblem'>
              <InputNumber disabled={usageForm?.formStatus === 2 || usageForm?.formStatus === 4}
                           addonAfter='ครั้ง/ไตรมาส' defaultValue={0} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <div style={{
              marginBottom: '16px',
            }}>จำนวนครั้งและความถี่ของเหตุการณ์ที่ไม่สามารถให้บริการได้ตามคุณภาพการให้บริการขึ้นต่ำ
            </div>
            <Form.Item style={{ width: '200px' }} name='frequencyOfServiceQualityProblem'>
              <InputNumber disabled={usageForm?.formStatus === 2 || usageForm?.formStatus === 4}
                           addonAfter='ครั้ง/ไตรมาส' defaultValue={0} />
            </Form.Item>
          </Col>
          <Col>
            <Button disabled={disablePdf} onClick={() => setPreviewModal(true)}
            >
              <FilePdfOutlined />
              ดูตัวอย่างเอกสาร
            </Button>
          </Col>
          <Col>
            <Button disabled={disablePdf} onClick={() => {
              const downloadBTN = document.querySelector('.PDFDownload');
              if (downloadBTN) {
                // @ts-ignore
                downloadBTN.click();
              }
            }
            }>
              <DownloadOutlined />
              ดาวน์โหลด
            </Button>
          </Col>
          <Col>
            {pdfData && <PDFDownloadLink className='PDFDownload visibility--hidden' key='pdf-download'
                                         document={<PDFTelecom data={pdfData} key={'pdf-file'} />}
                                         fileName={pdfData.year + '_' + pdfData.quarter + '_' + 'telecom.pdf'}
            >
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : ' ดาวน์โหลดเอกสาร')}
            </PDFDownloadLink>}
          </Col>
        </Row>
        <div>
          <Modal open={previewModal} closable={false} width={1000}
                 style={{ justifyContent: 'center', display: 'flex', alignContent: 'center' }} footer={[
            <ButtonComponent key={'preview-pdf'} onClick={onClosePreviewModal}>ปิด</ButtonComponent>,
          ]}>
            {
              pdfData && <PDFViewer style={{ width: '700px', height: '700px' }}>
                <PDFTelecom data={pdfData} />
              </PDFViewer>
            }
          </Modal>
        </div>
      </Form>
    </Modal>
  </div>;
};
export default TelecomForm;
