import axiosInstance from 'utils/axios';
import { clean } from 'utils/object';
import { GetAuditLogInput, GetAuditLogResponse } from 'api/auditLog/interface';


export const getAuditLog = (payload: GetAuditLogInput): Promise<GetAuditLogResponse> => {
  return axiosInstance.post('admin/search-history-log', clean<Partial<GetAuditLogInput>>({
    startDate: payload.startDate,
    endDate: payload.endDate,
    userLogin: payload.userLogin,
  }), {
    params: {
      currentPageNumber: payload.currentPageNumber,
      pageSize: payload.pageSize,
    },
  });
};
