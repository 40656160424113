import { useCallback, useState } from 'react';

import { AxiosResponse } from 'axios';
import { Modal, message } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload';
import { ExclamationCircleFilled } from '@ant-design/icons';

import useDialogAlert from 'hooks/useDialogAlert';
import { useApi, TApiResponse } from 'hooks/useApi';

import { FileListDataType } from '../interfaces';

const { confirm } = Modal;

export type handleUploadProps = {
  docId: number;
  docName: string;
  required: boolean;
  id: number;
  api: Function;
  getData?: Function;
};

export type onRemoveFileProps = {
  fileId: number;
  api: Function;
  getData?: Function;
};

export type downloadFileProps = {
  fileId?: number;
  api: Function;
  fileName?: string;
  params?: any;
  isThrowError?: boolean;
};

const useFileManagement = () => {
  const { error } = useDialogAlert();
  const { makeRequest }: TApiResponse = useApi();
  const { makeRequest: _deleteStateFile, loading: isDeletingFile }: TApiResponse = useApi();

  const [isUploadingFile, setUploadingFile] = useState(false);
  const [fileList, setFileList] = useState<FileListDataType[]>([]);
  const [currentUploadFile, setCurrentUploadFile] = useState<UploadFile>();

  const sortRequiredDoc = useCallback((arr: any) => {
    let requiredDocs: any = [];
    let otherDocs: any = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].required) {
        requiredDocs = [...requiredDocs, { ...arr[i], index: i }];
      } else {
        otherDocs = [...otherDocs, { ...arr[i], index: i }];
      }
    }
    requiredDocs.sort((a: any, b: any) => a.docId - b.docId);
    return [...requiredDocs, ...otherDocs];
  }, []);

  const handleUpload = useCallback(
    async ({
      docId,
      docName,
      required,
      id,
      api,
      getData = (res: any) => res.data?.data,
    }: handleUploadProps) => {
      const formData = new FormData();
      const metaData = new Blob(
        [
          JSON.stringify({
            docId,
            docName,
            required,
          }),
        ],
        {
          type: 'application/json',
        }
      );
      formData.append('attachment', currentUploadFile as RcFile);
      formData.append('metaData', metaData);
      setUploadingFile(true);
      try {
        const response = await makeRequest(() => api(id, formData));
        const filesList = getData(response);
        const sortedData = sortRequiredDoc(filesList);
        setFileList(sortedData);
        setUploadingFile(false);
        message.success('อัพโหลดไฟล์สำเร็จ');
      } catch (errorInfo) {
        console.error(errorInfo);
        setUploadingFile(false);
        error({
          header: 'อัพโหลดไฟล์ไม่สำเร็จ',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [currentUploadFile, error, makeRequest, sortRequiredDoc]
  );

  const handleRemoveFile = useCallback(
    async ({ fileId, api, getData = (res: any) => res.data?.data }: onRemoveFileProps) => {
      try {
        const response = await _deleteStateFile(() => api(fileId));
        const filesList = getData(response);
        const sortedData = sortRequiredDoc(filesList);
        setFileList(sortedData);
        message.success('ลบไฟล์สำเร็จ');
      } catch (errorInfo) {
        error({
          header: 'ลบไฟล์ไม่สำเร็จ',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    },
    [_deleteStateFile, error, sortRequiredDoc]
  );

  const onRemoveFile = useCallback(
    ({ fileId, api, getData = (res: any) => res.data?.data }: onRemoveFileProps) => {
      if (!fileId) {
        error({
          header: 'ไม่พบ attachFileId',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
        return;
      }
      confirm({
        title: 'ต้องการลบไฟล์หรือไม่ ?',
        icon: <ExclamationCircleFilled />,
        content: '',
        okText: 'ลบ',
        okType: 'danger',
        cancelText: 'ยกเลิก',
        onOk() {
          handleRemoveFile({ fileId, api, getData });
        },
        onCancel() {},
      });
    },
    [handleRemoveFile, error]
  );

  const downloadFile = async ({
    fileId,
    fileName = '',
    api,
    params,
    isThrowError = false,
  }: downloadFileProps) => {
    try {
      const response: AxiosResponse = await api(params ? params : fileId);

      // NOTE: Get filename from backend
      const filenameHeader = response.headers['content-disposition'];
      const backEndfilename = filenameHeader?.split('filename=')[1];
      const stringFilename = backEndfilename?.replace(/"/g, '');

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', stringFilename || fileName);
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode?.removeChild(link);
    } catch (errorInfo) {
      if (isThrowError) {
        throw errorInfo;
      }
      error({
        header: 'ดาวโหลดไฟล์ไม่สำเร็จ',
        sub: 'กรุณาลองใหม่อีกครั้ง',
      });
    }
  };

  const validateRequiredFiles = () => {
    return fileList
      .filter((item) => item.required === true)
      .findIndex((item) => !item.attachFileId);
  };

  return {
    fileList,
    setFileList,
    handleUpload,
    isUploadingFile,
    currentUploadFile,
    setCurrentUploadFile,
    onRemoveFile,
    isDeletingFile,
    downloadFile,
    sortRequiredDoc,
    validateRequiredFiles,
  };
};

export default useFileManagement;
