import { ReactNode } from 'react';
import { menuListWithPerms } from '../menuItems';

type menuItemType = {
  key: string;
  label: string;
  icon?: ReactNode;
  permission?: string;
  children?: menuItemType[];
};

function filterMenuWithPermissions(menuList: menuItemType[], permissions: string[]) {
  return menuList.reduce((filteredMenu: menuItemType[], menuItem: menuItemType) => {
    // Check if the menu item has permission
    if (menuItem?.permission && permissions?.some((perm) => perm === menuItem?.permission)) {
      // If it has permission, add it to the filtered menu
      if (menuItem?.children) {
        // If it has children, filter them recursively
        const filteredChildren = filterMenuWithPermissions(menuItem.children, permissions);
        if (filteredChildren.length > 0) {
          // Only add the parent if its children have permission
          filteredMenu.push({
            ...menuItem,
            children: filteredChildren,
          });
        }
      } else {
        filteredMenu.push(menuItem);
      }
    } else if (menuItem?.children) {
      // If it doesn't have permission but has children, filter the children
      const filteredChildren = filterMenuWithPermissions(menuItem.children, permissions);
      if (filteredChildren.length > 0) {
        // Add the parent node with filtered children
        filteredMenu.push({
          ...menuItem,
          children: filteredChildren,
        });
      }
    }
    return filteredMenu;
  }, []);
}

export const getFilterdMenuList = (permissions: string[]) => {
  return filterMenuWithPermissions(menuListWithPerms, permissions);
};
