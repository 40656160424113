import { Form, Modal, Row } from 'antd';

import { formLayout } from 'constants/form';
import useOfferFacilititesContext from 'features/Offer/hooks/useOfferFacilititesContext';

import ModalFooter from 'components/ModalFooter';
import FacilitiesDropdown from '../FacilitiesDropdown';
import ServicesDropdown from '../ServicesDropdown';

const RDROFacilityModal = () => {
  const { isFacilityModalOpen, facilityFormRef, onCloseFacilityModal, onSubmitNewFacility } =
    useOfferFacilititesContext();

  return (
    <Modal
      title="เพิ่มสิ่งอำนวยความสะดวก"
      width="75%"
      maskClosable={false}
      open={isFacilityModalOpen}
      onCancel={() => onCloseFacilityModal()}
      footer={
        <ModalFooter handleCancel={onCloseFacilityModal} onSubmitForm={onSubmitNewFacility} />
      }
    >
      <Form {...formLayout} labelWrap form={facilityFormRef} name="facilityForm" size="large">
        <Row gutter={[16, 16]}>
          <FacilitiesDropdown />
          <ServicesDropdown />
        </Row>
      </Form>
    </Modal>
  );
};

export default RDROFacilityModal;
