import { Badge, Space, Steps } from 'antd';
import { useEffect, useState } from 'react';
import ModalForm from './components/ModalForm';
import { Disagreement, DisAgreementState } from 'api/disagreement/interface';
import { dayJsformatDateToBCYear } from 'utils/date';
import { differenceInCalendarDays } from 'date-fns';
import dayjs from 'dayjs';

interface StepList {
  disagreement?: Disagreement;
  steps: DisAgreementState[];
  currentState: number;
  id: number;
  onNextStateCompleted: () => void;
}

interface GetItemInput {
  title: string;
  disabled: boolean;
  stateCode: number;
  statusGroup: number;
  onClick: () => void;
}

interface GetDesc {
  desc?: JSX.Element;
  onClick: () => void;
  stateCode: number;
}

const StatusColors: Record<number, string> = {
  0: 'orange',
  1: 'cyan',
  2: 'pink',
  3: 'blue',
  4: 'purple',
  5: 'green',
};

const GetItem = ({ title, disabled, onClick, statusGroup }: GetItemInput): JSX.Element => {
  return (
    <>
      <div
        onClick={() => {
          onClick();
        }}
      >
        <div>
          <Badge
            style={{
              padding: 'auto',
              marginRight: '8px',
            }}
            color={StatusColors[statusGroup]}
          />
          {title}
        </div>
      </div>
    </>
  );
};

const getDescription = ({ onClick, stateCode, desc }: GetDesc) => {
  return (
    <>
      <div
        onClick={() => {
          onClick();
        }}
      >
        {desc}
      </div>
    </>
  );
};

const StepDes = ({
  state,
  completedDate,
  agreeDicuss,
  negotiated,
  disagreement,
}: {
  state: number;
  completedDate: string | null;
  agreeDicuss?: boolean | null;
  negotiated?: boolean | null;
  disagreement?: Disagreement;
}): JSX.Element => {
  let counter = 0;
  if (disagreement) {
    const days = differenceInCalendarDays(
      dayjs(disagreement.receiveDiscussAccusedFormDate).toDate(),
      dayjs(disagreement.concludeFormDate).toDate()
    );
    counter = Math.abs(days);
  }
  return (
    <div>
      {state === 7 && typeof agreeDicuss === 'boolean' && (
        <div>ผลสรุป: {agreeDicuss ? 'ไกล่เกลี่ย' : 'ไม่ไกล่เกลี่ย'}</div>
      )}
      {state === 8 && typeof negotiated === 'boolean' && (
        <div>ผลสรุป: {negotiated ? 'ตกลงยุติข้อพิพาท' : 'ไม่สามารถตกลงยุติข้อพิพาท'}</div>
      )}
      {state === 14 && <div>รวมจำนวนวันที่ดำเนินการ: {counter}</div>}
      {completedDate && (
        <div>
          <span>วันที่ : </span> {completedDate ? dayJsformatDateToBCYear(completedDate) : ''}
        </div>
      )}
    </div>
  );
};

const DisagreementSteps = (prob: StepList): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [currentStateModal, setCurrentStateModal] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [id, setId] = useState(0);
  const [date, setDate] = useState<string | null>();
  const [formTitle, setFromTitle] = useState('');
  const [stepState, setStepState] = useState(0);

  useEffect(() => {}, [prob.currentState]);
  return (
    <>
      <ModalForm
        disagreement={prob.disagreement}
        title={formTitle}
        open={showModal}
        setOpen={setShowModal}
        id={id}
        state={currentStateModal}
        onUpdateStateCompleted={prob.onNextStateCompleted}
        currentState={prob.currentState}
        completeDate={date || undefined}
        stepState={stepState}
        isCompleted={isCompleted}
      ></ModalForm>
      <Space>
        <Steps
          direction="vertical"
          current={prob.currentState - 1}
          items={prob.steps.map((i) => {
            const disabled = i.isActiveState ? false : !i.isCompleteState;
            return {
              title: GetItem({
                title: i.stateName,
                disabled,
                stateCode: i.stateCode,
                statusGroup: i.statusGroup,
                onClick: () => {
                  if (!disabled) {
                    setFromTitle(i.stateName);
                    setId(prob.id);
                    setDate(i.completeDate);
                    setIsCompleted(i.isCompleteState);
                    setCurrentStateModal(i.stateCode);
                    setStepState(i.formStateId);
                    setShowModal(true);
                  }
                },
              }),
              description: getDescription({
                stateCode: i.stateCode,
                onClick: () => {
                  if (!disabled) {
                    setFromTitle(i.stateName);
                    setId(prob.id);
                    setDate(i.completeDate);
                    setIsCompleted(i.isCompleteState);
                    setCurrentStateModal(i.stateCode);
                    setStepState(i.formStateId);
                    setShowModal(true);
                  }
                },
                desc: (
                  <StepDes
                    state={i.stateCode}
                    completedDate={i.completeDate}
                    disagreement={prob.disagreement}
                    agreeDicuss={prob.disagreement?.agreeDiscuss}
                    negotiated={prob.disagreement?.negotiated}
                  />
                ),
              }),
              status: i.isCompleteState ? 'finish' : i.isActiveState ? 'process' : 'wait',
            };
          })}
        />
      </Space>
    </>
  );
};

export default DisagreementSteps;
