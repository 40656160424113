import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'hooks/useAuth';

type RoleBasedGuardProp = {
  children: ReactNode | string;
  permission?: string;
};

export default function RoleBasedGuard({ children, permission }: RoleBasedGuardProp) {
  const { user } = useAuth();
  const { pathname } = useLocation();

  const hasPermission = user?.permissions?.includes(permission);

  if (!hasPermission) return <Navigate to="/forbidden" state={{ from: pathname }} />;
  return <>{children}</>;
}
