import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Row, Space } from 'antd';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';

import useDialogAlert from 'hooks/useDialogAlert';
import { useApi, TApiResponse } from 'hooks/useApi';
import useConditionConfig from './hooks/useConditionConfig';

import PrimaryButton from 'components/PrimaryButton';
import useContractDataContext from 'features/Contract/hooks/useContractDataContext';

import { confirmContractDoc } from 'api/contract';

type ButtonGroupAttachmentTabProps = {
  isDisabled: boolean;
  onConfirmDoc: Function;
  onGetContractById: Function;
  conFormId: number;
  stateCode: number | undefined;
  setIsUpdatingState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ButtonGroupAttachmentTab = ({
  isDisabled,
  onConfirmDoc,
  onGetContractById,
  conFormId,
  stateCode,
  setIsUpdatingState,
}: ButtonGroupAttachmentTabProps) => {
  const { onPrepareContractData, isReviewPage } = useContractDataContext();
  const { isNotConfirmDocState, isShowConfirmDocButton } = useConditionConfig({
    stateCode,
    isReviewPage,
  });

  const navigate = useNavigate();
  const { state } = useLocation();
  const from = state ? state.from : '/';

  const { error } = useDialogAlert();
  const { makeRequest, loading }: TApiResponse = useApi();

  const onConfirmAttachment = useCallback(async () => {
    if (conFormId) {
      try {
        await makeRequest(() => confirmContractDoc({ conFormId, data: onPrepareContractData() }));
        onGetContractById();
      } catch (errorInfo) {
        error({
          header: 'ไม่สามารถอัพเดทสถานะได้',
          sub: 'กรุณาลองใหม่อีกครั้ง',
        });
      }
    }
  }, [conFormId, error, makeRequest, onGetContractById, onPrepareContractData]);

  useEffect(() => {
    setIsUpdatingState(loading);
  }, [loading, setIsUpdatingState]);

  return (
    <Row justify={'center'} style={{ marginTop: 15 }}>
      <Space>
        {isShowConfirmDocButton && (
          <PrimaryButton
            disabled={isDisabled || isNotConfirmDocState}
            icon={<SaveOutlined />}
            onClick={() => onConfirmDoc(onConfirmAttachment)}
          >
            ยืนยันการจัดส่งเอกสาร
          </PrimaryButton>
        )}
        <PrimaryButton icon={<RollbackOutlined />} onClick={() => navigate(from)}>
          ย้อนกลับหน้าค้นหา
        </PrimaryButton>
      </Space>
    </Row>
  );
};

export default ButtonGroupAttachmentTab;
