import { useEffect } from 'react';
import { ColProps, Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listAllServiceTypes } from 'api/master/adminData';

type ServTypesDropdownProps = {
  label?: string;
  labelCol?: ColProps | undefined;
  disabled?: boolean;
  fieldName?: string;
  onChangeServType?: Function;
};

const ServTypesDropdown = ({
  label,
  labelCol,
  disabled = false,
  fieldName,
  onChangeServType,
}: ServTypesDropdownProps) => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listAllServiceTypes());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        name={fieldName ? fieldName : 'servType'}
        label={label ? label : 'ประเภทบริการ'}
        labelCol={labelCol}
      >
        <Select
          showSearch
          allowClear
          loading={loading}
          disabled={disabled}
          filterOption={(input, option) => ((option?.label ?? '') as string).includes(input)}
          placeholder="-- กรุณาระบุ --"
          onChange={(_, option) => {
            if (onChangeServType) onChangeServType(option);
          }}
          options={
            data
              ? data.map((item: any) => ({
                  value: item.servTypeId,
                  label: item.servTypeName,
                  offerType: item.offerType,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ServTypesDropdown;
