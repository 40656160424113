import { useEffect } from 'react';

import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContNotiDocType } from 'api/contract';

type DocTypeDropdownProps = {
  isDisabled: boolean;
  setIsOpenFileDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setDocId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDocName: React.Dispatch<React.SetStateAction<string>>;
  setSelectedDocTypeOption: React.Dispatch<React.SetStateAction<any>>;
  api?: Function;
  stateCode?: string;
};

const DocTypeDropdown = ({
  isDisabled,
  setDocId,
  setDocName,
  setIsOpenFileDialog,
  setSelectedDocTypeOption,
  api,
  stateCode,
}: DocTypeDropdownProps) => {
  const { makeRequest, data: docTypeList, loading: isLoadingDocType }: TApiResponse = useApi();

  useEffect(() => {
    if (api) {
      makeRequest(() => api());
    } else {
      // NOTE: For review contract modal file attachment
      makeRequest(() => listContNotiDocType(stateCode));
    }
  }, [makeRequest, api, stateCode]);

  return (
    <>
      <Form.Item
        colon
        label="เลือกกลุ่มเอกสาร"
        name="docId"
        rules={[{ required: true, message: 'กรุณาเลือกกลุ่มเอกสาร' }]}
      >
        <Select
          loading={isLoadingDocType}
          placeholder="-- กรุณาระบุ --"
          size="large"
          disabled={isDisabled}
          onChange={(value, option: any) => {
            setSelectedDocTypeOption(option);
            setDocId(value);
            setDocName(option.label);
            setIsOpenFileDialog(true);
          }}
          options={
            docTypeList
              ? docTypeList.map((item: any) => ({
                  value: item.docId,
                  label: item.docName,
                  required: item.required,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default DocTypeDropdown;
