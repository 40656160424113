import { Space, Row, Col } from 'antd';
import { dayJsformatDateToBCYear } from 'utils/date';
import { IStepDescription } from './interfaces';

interface IStepWithLink extends IStepDescription {
  offerFormPublicUrl: string | undefined | null;
}

const StepWithLink = ({ stepData, offerFormPublicUrl }: IStepWithLink) => {
  return (
    <div>
      <Row>
        <Col>
          <Space>
            <label>วันที่ได้รับ Link เปิดเผยข้อเสนอฯ :</label>
            <span>
              {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
            </span>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col>
          <Space>
            <label>Link เปิดเผยข้อเสนอฯ :</label>
            <span>{offerFormPublicUrl || ' - '}</span>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default StepWithLink;
