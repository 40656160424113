/* eslint-disable */
import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
// @ts-ignore
import Sarabun from 'fonts/THSarabunNew.ttf';
// @ts-ignore
import SarabunBold from 'fonts/THSarabunNew Bold.ttf';
import LOGO from 'assets/pdf/NBTC_logo.png';

Font.register({
  family: 'Sarabun',
  fonts: [
    {
      src: Sarabun,
    },
    {
      src: SarabunBold,
      fontWeight: 'bold',
    },
  ],
});
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    border: '1px solid black',
    fontFamily: 'Sarabun',
    fontSize: '14px',
    paddingBottom: '80px',
    paddingTop: '16px',
  },
  header: {
    fontWeight: 'bold',
    fontSize: '16px',
    display: 'flex',
    alignContent: 'center',
    width: '100%',
    height: '90px',
    textAlign: 'center',
    borderBottom: '1px solid black',
  },
  logo: {
    width: '60px',
    marginTop: '0px',
    position: 'absolute',
  },
  padding: {
    paddingTop: '8px',
  },
  container: {
    display: 'flex',
    textAlign: 'center',
    alignContent: 'center',
  },
  alignLeft: {
    textAlign: 'left',
    alignContent: 'flex-start',
  },
  yearSection: {
    display: 'flex',
    textAlign: 'center',
    alignContent: 'center',
  },
  year: {
    display: 'flex',
    textAlign: 'center',
    marginLeft: '16px',
    marginRight: '16px',
    borderBottom: '1px dotted black',
    width: '80px',
  },
  quarter: {
    display: 'flex',
    width: '80px',
    marginLeft: '16px',
    marginRight: '16px',
    textAlign: 'center',
    borderBottom: '1px dotted black',
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  textCenter: {
    display: 'flex',
    textAlign: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  sectionOne: {
    padding: '16px',
    borderBottom: '2px solid black',
  },
  operator: {
    display: 'flex',
    width: '100%',
    marginLeft: '16px',
    textAlign: 'left',
    borderBottom: '1px dotted black',
  },
  marginL16: {
    marginLeft: 16,
  },
  marginLR16: {
    marginLeft: 16,
    marginRight: 16,
  },
  reviewer: {
    display: 'flex',
    width: '100%',
  },
  reviewerValue: {
    display: 'flex',
    width: '100%',
    textAlign: 'left',
    borderBottom: '1px dotted black',
  },
  sectionOneWidth: {
    width: '120px',
  },
  w100: {
    width: '100%',
  },
  lineValue: {
    display: 'flex',
    width: '100%',
    marginLeft: '16px',
    textAlign: 'left',
    borderBottom: '1px dotted black',
  },
  underLine: {
    paddingLeft: '16px',
    paddingRight: '16px',
    borderBottom: '1px dotted black',
    flexGrow: 1,
  },
  sectionTwo: {
    padding: '16px',
  },
});


interface Payload {
  data: PDFData;
}

export interface Voice {
  qualityOfDomesticVoiceTrafficIn: number;
  qualityOfDomesticVoiceTrafficOut: number;
  qualityOfInternationalVoiceTrafficIn: number;
  qualityOfInternationalVoiceTrafficOut: number;
  receiveCallOrigination: number;
  receiveCallTermination: number;
  receiveCallTransit: number;
  paymentCallOrigination: number;
  paymentCallTermination: number;
  paymentCallTransit: number;
}

export interface Sms {
  qualityOfDomesticSmsTrafficIn: number;
  qualityOfDomesticSmsTrafficOut: number;
  qualityOfInternationalSmsTrafficIn: number;
  qualityOfInternationalSmsTrafficOut: number;
  receiveCompensateSms: number;
  paymentCompensateSms: number;
}

export interface Mms {
  qualityOfDomesticMmsTrafficIn: number;
  qualityOfDomesticMmsTrafficOut: number;
  qualityOfInternationalMmsTrafficIn: number;
  qualityOfInternationalMmsTrafficOut: number;
  receiveCompensateMms: number;
  paymentCompensateMms: number;
}

export interface QualityOfDomesticCircuit {
  consumerCode: string;
  value: number;
}

export interface QualityOfInternationalCircuit {
  consumerCode: string;
  value: number;
}

export interface BandWidth {
  qualityOfDomesticCircuit: QualityOfDomesticCircuit[];
  qualityOfInternationalCircuit: QualityOfInternationalCircuit[];
}

export interface PDFData {
  operatorName: string;
  reviewerName: string;
  reviewerRole: string;
  frequencyOfTechnicalProblem: number;
  frequencyOfServiceQualityProblem: number;
  year: number;
  quarter: number;
  bandWidth?: BandWidth;
  voice?: Voice;
  sms?: Sms;
  mms?: Mms;
}

const PDFBandwidth = (input: Payload) => {
  return <Document key={'document-connect-pdf'}>
    <Page size='A4' key={'page-pdf'} style={{ ...styles.page }}>
      <View key={'v1'} style={styles.header}>
        <Image src={LOGO} style={styles.logo}></Image>
        <Text key={'t1'} style={{ marginTop: 0 }}>แบบแจ้งข้อมูลการเชื่อมต่อโครงข่ายโทรคมนาคม</Text>
        <Text key={'t2'}>ตามข้อ 7 (10) ของประกาศ กสทช. เรื่อง การใช้และเชื่อมต่อโครงข่ายโทรคมนาคม พ.ศ. 2556</Text>
        <View key={'v1-1'} style={styles.yearSection}>
          <View key={'v1-1-1'} style={{ ...styles.textCenter, ...styles.flex }}>
            <Text key={'t3'}>ข้อมูลของไตรมาส</Text>
            <View key={'v1-1-2'} style={styles.quarter}>
              <Text key={'t4'} style={{ fontWeight: 'normal' }}>
                {input.data.quarter}
              </Text>
            </View>
            <Text key={'t5'}>พ.ศ</Text>
            <View key={'v1-1-3'} style={styles.year}>
              <Text key={'t6'} style={{ fontWeight: 'normal' }}>
                {input.data.year}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View key={'v2'} style={{ ...styles.sectionOne }}>
        <Text key={'t7'} style={{ fontWeight: 'bold' }}>
          ส่วนที่ 1 ข้อมูลทั่วไป
        </Text>
        <View key={'v2-1'} style={{ ...styles.container, ...styles.alignLeft }}>
          <View key={'v2-1-1'} style={{ ...styles.flex }}>
            <Text key={'t8'} style={{ fontWeight: 'bold' }}>1.1</Text>
            <Text key={'t9'}
                  style={{ ...styles.marginL16, ...styles.sectionOneWidth, fontWeight: 'bold' }}>ชื่อใบอนุญาต</Text>
            <View key={'v2-1-2'} style={styles.lineValue}>
              <Text key={'t10'}>
                {input.data.operatorName}
              </Text>
            </View>
          </View>
        </View>
        <View key={'v2-2-1'} style={{ ...styles.container, ...styles.alignLeft, marginTop: 4 }}>
          <View key={'v2-2-2'} style={{ ...styles.flex }}>
            <Text key={'t11'} style={{ fontWeight: 'bold' }}>1.2</Text>
            <Text key={'t12'}
                  style={{ ...styles.marginL16, ...styles.sectionOneWidth, fontWeight: 'bold' }}>ประเภทโครงข่าย</Text>
            <View key={'v2-2-3'} style={styles.lineValue}>
              <Text key={'t13'}>

              </Text>
            </View>
          </View>
        </View>
        <View key={'v2-3-1'} style={{ ...styles.container, ...styles.alignLeft, marginTop: 4 }}>
          <View key={'v2-3-2'} style={{ ...styles.flex }}>
            <Text key={'t14'} style={{ fontWeight: 'bold' }}>1.3</Text>
            <Text key={'t15'}
                  style={{ ...styles.marginL16, ...styles.sectionOneWidth, fontWeight: 'bold' }}>ผู้ตรวจสอบข้อมูล</Text>
            <View key={'v2-3-3'} style={{
              display: 'flex',
              width: '100%',
              marginLeft: '16px',
              textAlign: 'left',
            }}>
              <View key={'v2-3-4'} style={{ ...styles.flex }}>
                <Text key={'t16'} style={{ width: '40px' }}>
                  ชื่อ-สกุล
                </Text>
                <Text key={'t17'} style={{ ...styles.underLine, paddingLeft: 0 }}>
                  {input.data.reviewerName}
                </Text>
              </View>
              <View key={'v2-3-5'} style={styles.flex}>
                <Text key={'t18'} style={{ width: '40px' }}>
                  ตำแหน่ง
                </Text>
                <Text key={'t19'} style={{ ...styles.underLine, paddingLeft: 0 }}>
                  {input.data.reviewerRole}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View key={'v3'} style={{ borderBottom: '1px solid black', ...styles.sectionTwo }}>
        <Text key={'t20'} style={{ fontWeight: 'bold' }}>
          ส่วนที่ 2 สรุปข้อมูลเกี่ยวกับการเชื่อมต่อโครงข่ายโทรคมนาคม
        </Text>
        <View key={'v3-1'} style={{ ...styles.container, ...styles.alignLeft }}>
          <View key={'v3-2'} style={{ ...styles.flex }}>
            <Text key={'t21'} style={{ fontWeight: 'bold' }}>2.1</Text>
            <Text key={'t22'} style={{ ...styles.marginL16, fontWeight: 'bold' }}>
              ข้อมูลปริมาณวงจรที่เชื่อมต่อกับโครงข่ายโทรคมนาคมของตน
            </Text>
            <View key={'v3-3'}>
              <Text key={'t23'} style={{ paddingLeft: 2 }}>
                (แยกตามผู้ขอเชื่อมต่อ)
              </Text>
            </View>
          </View>
        </View>
        <View key={'2.1.1'} style={{ ...styles.marginL16 }}>
          <Text style={{ ...styles.marginL16 }}>
            2.1.1 จำนวนวงจรที่เชื่อมต่อกัน (ภายในประเทศ)
          </Text>
          <View key={'2.1.1.1'} style={{ paddingLeft: '40px' }}>
            {input.data?.bandWidth?.qualityOfDomesticCircuit.map((i, index) => {
              return <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1' + index}>{'2.1.1.' + (index + 1)}</Text>
                <View key={'b2.1.1.1' + index} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1' + index} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    {i.consumerCode}
                  </Text>
                </View>
                <Text key={'e2.1.1.1' + index} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{i.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Text>
                <Text key={'f2.1.1.1' + index} style={{ paddingLeft: '4px' }}>E1</Text>
              </View>;
            })}
          </View>
        </View>
        <View key={'2.1.2'} style={{ ...styles.marginL16 }}>
          <Text style={{ ...styles.marginL16 }}>
            2.1.2 จำนวนวงจรที่เชื่อมต่อกับ International Gateway Provider
          </Text>
          <View key={'2.1.2.1'} style={{ paddingLeft: '40px' }}>
            {input.data?.bandWidth?.qualityOfInternationalCircuit.map((i, index) => {
              return <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.2.1' + index}>{'2.1.1.' + (index + 1)}</Text>
                <View key={'b2.1.2.1' + index} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.2.1' + index} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    {i.consumerCode}
                  </Text>
                </View>
                <Text key={'e2.1.2.1' + index} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{i.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</Text>
                <Text key={'f2.1.2.1' + index} style={{ paddingLeft: '4px' }}>E1</Text>
              </View>;
            })}
          </View>
        </View>
        <View key={'v3-4'} style={{ ...styles.container, ...styles.alignLeft }}>
          <View key={'v3-5'} style={{ ...styles.flex }}>
            <Text key={'t24'} style={{ fontWeight: 'bold' }}>2.2</Text>
            <Text key={'t25'} style={{ ...styles.marginL16, fontWeight: 'bold' }}>
              ข้อมูลปริมาณการเรียกเข้า-ออก ผ่านวงจรที่เชื่อมต่อกับโครงข่ายโทรคมนาคมของตน
            </Text>
            <View key={'v3-6'}>
              <Text key={'t26'} style={{ paddingLeft: 2 }}>
                (แยกตามผู้ขอใช้โครงข่าย)
              </Text>
            </View>
          </View>
          <View key={'2.2.1'} style={{ ...styles.marginL16 }}>
            <Text style={{ ...styles.marginL16 }}>
              2.2.1 ปริมาณการเรียกเข้าโครงข่าย (ภายในประเทศ)
            </Text>
            <View key={'2.2.1.1'} style={{ paddingLeft: '40px' }}>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.2.1.1-a'}>2.2.1.1</Text>
                <View key={'b2.2.1.1-a'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.1.1-a'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Voice Traffic
                  </Text>
                </View>
                <Text key={'e2.2.1.1-a'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.qualityOfDomesticVoiceTrafficIn) === 'number' ? input.data?.voice?.qualityOfDomesticVoiceTrafficIn : '-'}</Text>
                <Text key={'f2.2.1.1-a'} style={{ paddingLeft: '4px' }}>นาที</Text>
              </View>
              <View key={'v3-7-x-2'} style={styles.flex}>
                <Text key={'a2.2.1.1-b'}>2.2.1.2</Text>
                <View key={'b2.2.1.1-b'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.1.1-b'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    SMS
                  </Text>
                </View>
                <Text key={'e2.2.1.1-b'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.sms?.qualityOfDomesticSmsTrafficIn) === 'number' ? input.data?.sms?.qualityOfDomesticSmsTrafficIn : '-'}</Text>
                <Text key={'f2.2.1.1-b'} style={{ paddingLeft: '4px' }}>ข้อความ</Text>
              </View>
              <View key={'v3-7-x-3'} style={styles.flex}>
                <Text key={'a2.2.1.1-c'}>2.2.1.3</Text>
                <View key={'b2.2.1.1-c'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.1.1-c'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    MMS
                  </Text>
                </View>
                <Text key={'e2.2.1.1-c'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.mms?.qualityOfDomesticMmsTrafficIn) === 'number' ? input.data?.mms?.qualityOfDomesticMmsTrafficIn : '-'}</Text>
                <Text key={'f2.2.1.1-c'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
              <View key={'v3-7-x-4'} style={styles.flex}>
                <Text key={'a2.2.1.1-d'}>2.2.1.4</Text>
                <View key={'b2.2.1.1-d'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.1.1-d'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Data traffic
                  </Text>
                </View>
                <Text key={'e2.2.1.1-d'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>-</Text>
                <Text key={'f2.2.1.1-d'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
            </View>
            <Text style={{ ...styles.marginL16 }}>
              2.2.2 ปริมาณการเรียกออกโครงข่าย (ภายในประเทศ)
            </Text>
            <View key={'2.2.2.1'} style={{ paddingLeft: '40px' }}>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.2.2.1-a'}>2.2.2.1</Text>
                <View key={'b2.2.2.1-a'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-a'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Voice Traffic
                  </Text>
                </View>
                <Text key={'e2.2.2.1-a'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.qualityOfDomesticVoiceTrafficOut) === 'number' ? input.data?.voice?.qualityOfDomesticVoiceTrafficOut : '-'}</Text>
                <Text key={'f2.2.2.1-a'} style={{ paddingLeft: '4px' }}>นาที</Text>
              </View>
              <View key={'v3-7-x-2'} style={styles.flex}>
                <Text key={'a2.2.2.1-b'}>2.2.2.2</Text>
                <View key={'b2.2.2.1-b'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-b'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    SMS
                  </Text>
                </View>
                <Text key={'e2.2.2.1-b'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.sms?.qualityOfDomesticSmsTrafficOut) === 'number' ? input.data?.sms?.qualityOfDomesticSmsTrafficOut : '-'}</Text>
                <Text key={'f2.2.2.1-b'} style={{ paddingLeft: '4px' }}>ข้อความ</Text>
              </View>
              <View key={'v3-7-x-3'} style={styles.flex}>
                <Text key={'a2.2.2.1-c'}>2.2.2.3</Text>
                <View key={'b2.2.2.1-c'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-c'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    MMS
                  </Text>
                </View>
                <Text key={'e2.2.2.1-c'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.mms?.qualityOfDomesticMmsTrafficOut) === 'number' ? input.data?.mms?.qualityOfDomesticMmsTrafficOut : '-'}</Text>
                <Text key={'f2.2.2.1-c'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
              <View key={'v3-7-x-4'} style={styles.flex}>
                <Text key={'a2.2.2.1-d'}>2.2.2.4</Text>
                <View key={'b2.2.2.1-d'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-d'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Data traffic
                  </Text>
                </View>
                <Text key={'e2.2.2.1-d'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>-</Text>
                <Text key={'f2.2.21-d'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
            </View>
            <Text style={{ ...styles.marginL16 }}>
              2.2.3 ปริมาณการเรียกเข้าโครงข่าย (จากต่างประเทศ)
            </Text>
            <View key={'2.2.2.1'} style={{ paddingLeft: '40px' }}>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.2.2.1-a'}>2.2.2.1</Text>
                <View key={'b2.2.2.1-a'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-a'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Voice Traffic
                  </Text>
                </View>
                <Text key={'e2.2.2.1-a'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.qualityOfInternationalVoiceTrafficIn) === 'number' ? input.data?.voice?.qualityOfInternationalVoiceTrafficIn : '-'}</Text>
                <Text key={'f2.2.2.1-a'} style={{ paddingLeft: '4px' }}>นาที</Text>
              </View>
              <View key={'v3-7-x-2'} style={styles.flex}>
                <Text key={'a2.2.2.1-b'}>2.2.2.2</Text>
                <View key={'b2.2.2.1-b'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-b'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    SMS
                  </Text>
                </View>
                <Text key={'e2.2.2.1-b'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.sms?.qualityOfInternationalSmsTrafficIn) === 'number' ? input.data?.sms?.qualityOfInternationalSmsTrafficIn : '-'}</Text>
                <Text key={'f2.2.2.1-b'} style={{ paddingLeft: '4px' }}>ข้อความ</Text>
              </View>
              <View key={'v3-7-x-3'} style={styles.flex}>
                <Text key={'a2.2.2.1-c'}>2.2.2.3</Text>
                <View key={'b2.2.2.1-c'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-c'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    MMS
                  </Text>
                </View>
                <Text key={'e2.2.2.1-c'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.mms?.qualityOfInternationalMmsTrafficIn) === 'number' ? input.data?.mms?.qualityOfInternationalMmsTrafficIn : '-'}</Text>
                <Text key={'f2.2.2.1-c'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
              <View key={'v3-7-x-4'} style={styles.flex}>
                <Text key={'a2.2.2.1-d'}>2.2.2.4</Text>
                <View key={'b2.2.2.1-d'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-d'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Data traffic
                  </Text>
                </View>
                <Text key={'e2.2.2.1-d'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>-</Text>
                <Text key={'f2.2.21-d'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
            </View>
            <Text style={{ ...styles.marginL16 }}>
              2.2.4 ปริมาณการเรียกออกโครงข่าย (ไปจากต่างประเทศ)
            </Text>
            <View key={'2.2.2.1'} style={{ paddingLeft: '40px' }}>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.2.2.1-a'}>2.2.2.1</Text>
                <View key={'b2.2.2.1-a'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-a'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Voice Traffic
                  </Text>
                </View>
                <Text key={'e2.2.2.1-a'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.qualityOfInternationalVoiceTrafficOut) === 'number' ? input.data?.voice?.qualityOfInternationalVoiceTrafficOut : '-'}</Text>
                <Text key={'f2.2.2.1-a'} style={{ paddingLeft: '4px' }}>นาที</Text>
              </View>
              <View key={'v3-7-x-2'} style={styles.flex}>
                <Text key={'a2.2.2.1-b'}>2.2.2.2</Text>
                <View key={'b2.2.2.1-b'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-b'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    SMS
                  </Text>
                </View>
                <Text key={'e2.2.2.1-b'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.sms?.qualityOfInternationalSmsTrafficOut) === 'number' ? input.data?.sms?.qualityOfInternationalSmsTrafficOut : '-'}</Text>
                <Text key={'f2.2.2.1-b'} style={{ paddingLeft: '4px' }}>ข้อความ</Text>
              </View>
              <View key={'v3-7-x-3'} style={styles.flex}>
                <Text key={'a2.2.2.1-c'}>2.2.2.3</Text>
                <View key={'b2.2.2.1-c'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-c'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    MMS
                  </Text>
                </View>
                <Text key={'e2.2.2.1-c'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.mms?.qualityOfInternationalMmsTrafficOut) === 'number' ? input.data?.mms?.qualityOfInternationalMmsTrafficOut : '-'}</Text>
                <Text key={'f2.2.2.1-c'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
              <View key={'v3-7-x-4'} style={styles.flex}>
                <Text key={'a2.2.2.1-d'}>2.2.2.4</Text>
                <View key={'b2.2.2.1-d'} style={{ width: '300px', paddingLeft: '4px' }}>
                  <Text key={'c2.2.2.1-d'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Data traffic
                  </Text>
                </View>
                <Text key={'e2.2.2.1-d'} style={{
                  // borderBottom: '1px dotted black',
                  width: 120,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>-</Text>
                <Text key={'f2.2.21-d'} style={{ paddingLeft: '4px' }}>Mb</Text>
              </View>
            </View>
          </View>
        </View>
        <View key={'x3-4'} style={{ ...styles.container, ...styles.alignLeft }}>
          <View key={'x3-5'} style={{ ...styles.flex }}>
            <Text key={'x24'} style={{ fontWeight: 'bold' }}>2.3</Text>
            <Text key={'x25'} style={{ ...styles.marginL16, fontWeight: 'bold' }}>
              ข้อมูลจำนวนค่าตอบแทนการเชื่อมต่อโครงข่ายโทรคมนาคมที่ได้รับและชำระแก่ผู้ได้รับใบอนุญาตรายอื่น
            </Text>
          </View>
          <View key={'2.1.1'} style={{ ...styles.marginL16 }}>
            <Text style={{ ...styles.marginL16 }}>
              2.3.1 ค่าตอบแทนการเชื่อมต่อโครงข่ายโทรคมนาคมที่ได้รับ
            </Text>
            <View key={'2.1.1.1'} style={{ paddingLeft: '40px' }}>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Call Origination
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.receiveCallOrigination) === 'number' ? input.data?.voice?.receiveCallOrigination : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Call Termination
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.receiveCallTermination) === 'number' ? input.data?.voice?.receiveCallTermination : '-'}</Text>

                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Call Transit
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.receiveCallTransit) === 'number' ? input.data?.voice?.receiveCallTransit : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    SMS
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.sms?.receiveCompensateSms) === 'number' ? input.data?.sms?.receiveCompensateSms : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    MMS
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.mms?.receiveCompensateMms) === 'number' ? input.data?.mms?.receiveCompensateMms : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
            </View>
          </View>
          <View key={'2.1.1'} style={{ ...styles.marginL16 }}>
            <Text style={{ ...styles.marginL16 }}>
              2.3.2 ค่าตอบแทนการเชื่อมต่อโครงข่ายโทรคมนาคมที่ต้องชำระ
            </Text>
            <View key={'2.1.1.1'} style={{ paddingLeft: '40px' }}>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Call Origination
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.paymentCallOrigination) === 'number' ? input.data?.voice?.paymentCallOrigination : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Call Termination
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.paymentCallTermination) === 'number' ? input.data?.voice?.paymentCallTermination : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    Call Transit
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.voice?.paymentCallTransit) === 'number' ? input.data?.voice?.paymentCallTransit : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    SMS
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.sms?.paymentCompensateSms) === 'number' ? input.data?.sms?.paymentCompensateSms : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
              <View key={'v3-7-x'} style={styles.flex}>
                <Text key={'a2.1.1.1'}>-</Text>
                <View key={'b2.1.1.1'} style={{ width: '200px', paddingLeft: '4px' }}>
                  <Text key={'c2.1.1.1'} style={{
                    // borderBottom: '1px dotted black'
                  }}
                  >
                    MMS
                  </Text>
                </View>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 80,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>จำนวน</Text>
                <Text key={'e2.1.1.1'} style={{
                  // borderBottom: '1px dotted black',
                  width: 140,
                  marginLeft: '4px',
                  textAlign: 'center',
                  height: '20px',
                }}>{typeof (input.data?.mms?.paymentCompensateMms) === 'number' ? input.data?.mms?.paymentCompensateMms : '-'}</Text>
                <Text key={'f2.1.1.1'} style={{ paddingLeft: '4px' }}>บาท</Text>
              </View>
            </View>
          </View>
        </View>
      </View>

      <View key={'xx22'} style={styles.sectionTwo}>
        <View key={'v3-8'}
              style={{ ...styles.container, ...styles.alignLeft, marginTop: 8, height: 50 }} wrap={false}>
          <View key={'v3-9'} style={{ ...styles.flex }}>
            <Text key={'t28'} style={{ fontWeight: 'bold' }}>2.4</Text>
            <View key={'v3-10'} style={{ ...styles.marginL16, fontWeight: 'bold' }}>
              <Text key={'t29'}>จำนวนและถี่ของปัญหาทางเทคนิคที่เกิดขึ้นในโครงข่ายโทรคมนาคม</Text>
              <View key={'v28'} style={{ fontWeight: 'normal', ...styles.flex }}>
                <Text key={'t31'} style={{ paddingRight: '8px' }}>{input.data.frequencyOfServiceQualityProblem}</Text>
                <Text key={'t32'}>ครั้ง/ไตรมาส</Text>
              </View>
            </View>

          </View>
        </View>
        <View key={'v3-11'} style={{ ...styles.container, ...styles.alignLeft, height: 50 }} wrap={false}>
          <View key={'v3-12'} style={{ ...styles.flex }}>
            <Text key={'t33'} style={{ fontWeight: 'bold' }}>2.5</Text>
            <View key={'v3-13'} style={{ ...styles.marginL16, fontWeight: 'bold' }}>
              <Text
                key={'t34'}>จำนวนครั้งและความถี่ของเหตุการณ์ที่ไม่สามารถให้บริการได้ตามคุณภาพการให้บริการขึ้นต่ำ</Text>
              <View style={{ fontWeight: 'normal', ...styles.flex }}>
                <Text key={'t35'} style={{ paddingRight: '8px' }}>{input.data.frequencyOfTechnicalProblem}</Text>
                <Text key={'t36'}>ครั้ง/ไตรมาส</Text>
              </View>
            </View>

            <View key={'v3-14'}>
              <Text key={'t37'} style={{ paddingLeft: 2 }}>
                (แยกตามผู้ขอใช้โครงข่าย)
              </Text>
            </View>
          </View>
        </View>
        <Text key={'t38'}
              style={{
                textAlign: 'center',
                marginTop: 16,
              }}>(โปรดดูรายละเอียดคำแนะนำการกรอกข้อมูลในแบบแจ้งข้อมูลการใช้โครงข่ายโทรคนาคมตามเอกสารท้ายแบบแจ้งข้อมูลนี้)</Text>
      </View>

      <View key={'v4'} wrap={false} style={{
        position: 'absolute', bottom: 0,
        height: '100px',
        borderTop: '1px solid black',
        width: '100%',
        ...styles.flex,
      }}>
        <View key={'v4-1'} style={{ width: '50%', borderRight: '1px solid black' }}></View>
        <View key={'v4-2'} style={{
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          display: 'flex',
          width: '50%',
        }}>
          <Text key={'t39'} style={{ width: '120px', borderBottom: '1px dotted black' }}></Text>
          <Text key={'t40'} style={{ marginTop: 8 }}>ลายมือชื่อผู้ตรวจสอบข้อมูล</Text>
          <View key={'v4-3'} style={styles.flex}>
            <Text key={'t41'}>ว/ด/ป</Text>
            <Text key={'t42'}
                  style={{ width: '80px', height: 14, marginLeft: 8, borderBottom: '1px dotted black' }}></Text>
          </View>
        </View>
      </View>

    </Page>
  </Document>;
};
export default PDFBandwidth;
