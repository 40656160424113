import { useEffect, useState } from 'react';

const useAttachHistoryOffer = (
  attachHistory: any,
  error: (
    text?:
      | {
          header: string;
          sub: string;
        }
      | undefined
  ) => void
) => {
  const [modalOpenHistory, setModalOpenHistory] = useState<boolean>(false);
  useEffect(() => {
    if (attachHistory) {
      if (attachHistory.length === 0) {
        error({ header: 'ไม่มีประวัติไฟล์ข้อเสนอ', sub: '' });
      } else {
        setModalOpenHistory(true);
      }
    }
  }, [attachHistory, error, setModalOpenHistory]);
  const onModalHistoryCancel = () => {
    setModalOpenHistory(false);
  };
  return { modalOpenHistory, setModalOpenHistory, onModalHistoryCancel };
};
export default useAttachHistoryOffer;
