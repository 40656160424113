import axiosInstance from 'utils/axios';
import { IOfficerCTAdd } from './interface';

export const listOfficerOffer = (
  pageSize: number | string,
  currentPageNumber: number | string,
  referenceNo: string | undefined,

  filter: {
    offerFormTypeCode: string | null;
    taxId: string | null;
    offerFormNo: string | null;
    offerFormStateCode: string | null;
  }
) => {
  return axiosInstance.get(
    `/offer-forms?currentPageNumber=${currentPageNumber}&pageSize=${pageSize}&referenceNo=${referenceNo}`,
    {
      params: {
        taxId: filter.taxId,
        offerFormStateCode: filter.offerFormStateCode,
        offerFormNo: filter.offerFormNo,
        offerType: filter.offerFormTypeCode,
      },
    }
  );
};
export const listReviewOfferOfficer = (
  pageSize: number | string,
  currentPageNumber: number | string
) => {
  return axiosInstance.get(`/review-offer/readAll`, {
    params: {
      pageSize,
      currentPageNumber,
    },
  });
};
export const listOfferFormStates = () => {
  return axiosInstance.get(`/offer/officer/offer-form-states`);
};
export const createOfferofficerCT = (data: IOfficerCTAdd) => {
  return axiosInstance.post(`/offer/createAndAssign`, data);
};
