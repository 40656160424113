import { Form, Select } from 'antd';

import { useApi, TApiResponse } from 'hooks/useApi';
import { listContractState } from 'api/contract';
import { useEffect } from 'react';

const ContractStateDropdown = () => {
  const { makeRequest, data, loading }: TApiResponse = useApi();

  useEffect(() => {
    makeRequest(() => listContractState());
  }, [makeRequest]);

  return (
    <>
      <Form.Item
        colon
        labelAlign="right"
        label="สถานะการจัดทำสัญญา"
        name="formStatus"
        // rules={[{ required: true, message: 'กรุณาเลือกสถานะการจัดทำสัญญา' }]}
      >
        <Select
          allowClear
          loading={loading}
          placeholder="-- กรุณาระบุ --"
          options={
            data
              ? data.map((item: { stateCode: string; stateName: string }) => ({
                  value: item.stateCode,
                  label: item.stateName,
                }))
              : []
          }
        />
      </Form.Item>
    </>
  );
};

export default ContractStateDropdown;
