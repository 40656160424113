import { Tag } from 'antd';

import { IContServesData, IOfferFacilities } from 'features/Contract/interfaces';
import { OFFER_TYPE_RISO } from 'constants/offerFormType';
import { OTHER_OPTION } from 'features/Offer/constant';

export const getLabelValue = (item: IContServesData | IOfferFacilities) => {
  if (item.servName === OTHER_OPTION) return item.serviceDesc;
  return item.servName;
};

export const getServiceDropdownValueByName = (item: IContServesData | IOfferFacilities) => {
  if (item.servTypeName) {
    if (item.servName === OTHER_OPTION) return `[${item.servTypeName}]${item.serviceDesc}`;
    return `[${item.servTypeName}]${item.servName}`;
  }
  if (item.servName === OTHER_OPTION) return item.serviceDesc;
  return item.servName;
};

export const getServiceLabelTextDropdown = (item: IContServesData | IOfferFacilities) => {
  return item.servTypeName ? (
    <>
      <Tag style={{ fontSize: 16 }}>{item.servTypeName}</Tag>
      {getLabelValue(item)}
    </>
  ) : (
    getLabelValue(item)
  );
};

export const setTitleData = (item: IContServesData | IOfferFacilities) => {
  return {
    desc: item.serviceDesc,
    label: getLabelValue(item),
    value: item.servId,
    servTypeName: item.servTypeName,
  };
};

// NOTE: ContractOfferServesDropdown
export const getOfferServeLabelValue = (item: IContServesData | IOfferFacilities) => {
  if (item.servName === OTHER_OPTION && item.serviceDesc === '') return item.servName;
  if (item.servName === OTHER_OPTION) return item.serviceDesc;
  return item.servName;
};

export const getOfferServeLabelTextDropdown = (item: IContServesData | IOfferFacilities) => {
  if (item.offerType === OFFER_TYPE_RISO) return getOfferServeLabelValue(item);
  return item.servTypeName ? (
    <>
      <Tag style={{ fontSize: 16 }}>{item.servTypeName}</Tag>
      {getOfferServeLabelValue(item)}
    </>
  ) : (
    getOfferServeLabelValue(item)
  );
};

export const setOfferServeTitleData = (item: IContServesData | IOfferFacilities) => {
  return {
    desc: item.serviceDesc,
    label: getOfferServeLabelValue(item),
    value: item.servId,
    servTypeName: item.servTypeName,
    servePays: item.servePays,
  };
};
