import { ButtonComponent, HeaderTitleComponent } from 'components';
import React from 'react';
import SearchTable from './SearchTable/SearchTable';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  // listOffer: ILestOffer | undefined;
  data: any;
  onModalOpenOffer_: (val: any) => void;
  onModalOpenOffer: (val: any) => void;
  loading: boolean;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  currentPageNumber: number;
  setCurrentPageNumber: React.Dispatch<React.SetStateAction<number>>;
  pageable: any;
  onModalOpenHistory: (id: number | string) => void;
};

const SearchResults = (props: Props) => {
  const {
    onModalOpenOffer,
    data,
    loading,
    pageSize,
    setPageSize,
    currentPageNumber,
    setCurrentPageNumber,
    pageable,
    onModalOpenHistory,
    onModalOpenOffer_,
  } = props;
  return (
    <div>
      <HeaderTitleComponent>ผลการค้นหา</HeaderTitleComponent>
      <div className="padding-conten-main">
        <div className="padding-conten">
          <ButtonComponent htmlType="button" onClick={onModalOpenOffer_}>
            <PlusOutlined />
            เพื่อจัดทำข้อเสนอใหม่
          </ButtonComponent>
        </div>

        <SearchTable
          data={data}
          loading={loading}
          pageSize={pageSize}
          setPageSize={setPageSize}
          currentPageNumber={currentPageNumber}
          setCurrentPageNumber={setCurrentPageNumber}
          pageable={pageable}
          onModalOpenOffer={onModalOpenOffer}
          onModalOpenHistory={onModalOpenHistory}
        />
      </div>
    </div>
  );
};

export default SearchResults;
