import { Key, createContext } from 'react';
import { FormInstance } from 'antd';

import useManageContractFacilities from '../hooks/useManageContractFacilities';

import { IContractData, IFacilitiesType } from '../interfaces';
import useContractServicesContext from '../hooks/useContractServicesContext';

// -----------------------
// NOTE: Context
// -----------------------
type TContractFacilitiesContext = {
  isFacilityModalOpen: boolean;
  isAddExtraServePay: boolean;
  isDisabled: boolean;
  facilityTableFormRef: FormInstance<any>;
  facilityModalFormRef: FormInstance<any>;
  selectedFacilities: Key[];
  currentContFacilities: IFacilitiesType[];
  editingFacility: IFacilitiesType | undefined;
  onSelectMultipleFacilities: (selectedRowKeys: Key[], _: any[]) => void;
  onEditContractFacility: (record: IFacilitiesType) => void;
  onAddNewContractFacility: () => void;
  onDeleteContractFacilities: () => void;
  onCloseContractFacilityModal: () => void;
  onSubmitContractFacility: () => void;
  onCheckDuplicateFacility: (option: any) => void;
  onToggleExtraServePay: () => void;
  onResetCurrentContFacilities: () => void;
};

const ConractFacilitiesContext = createContext<TContractFacilitiesContext>(
  {} as TContractFacilitiesContext
);

// -----------------------
// NOTE: Provider
// -----------------------
type TContractFacilitiesProvider = {
  children: React.ReactNode;
  contractData: IContractData;
  isDisabled: boolean;
};

const ContractFacilitiesProvider = ({
  children,
  contractData,
  isDisabled,
}: TContractFacilitiesProvider) => {
  const { currentContServes } = useContractServicesContext();
  const {
    isFacilityModalOpen,
    isAddExtraServePay,
    facilityModalFormRef,
    facilityTableFormRef,
    selectedFacilities,
    currentContFacilities,
    editingFacility,
    onSelectMultipleFacilities,
    onEditContractFacility,
    onAddNewContractFacility,
    onDeleteContractFacilities,
    onCloseContractFacilityModal,
    onSubmitContractFacility,
    onCheckDuplicateFacility,
    onToggleExtraServePay,
    onResetCurrentContFacilities,
  } = useManageContractFacilities({
    contFacilities: contractData?.contFacilities,
    currentContServes,
  });
  return (
    <ConractFacilitiesContext.Provider
      value={{
        isFacilityModalOpen,
        isAddExtraServePay,
        isDisabled,
        facilityModalFormRef,
        facilityTableFormRef,
        selectedFacilities,
        currentContFacilities,
        editingFacility,
        onSelectMultipleFacilities,
        onEditContractFacility,
        onAddNewContractFacility,
        onDeleteContractFacilities,
        onCloseContractFacilityModal,
        onSubmitContractFacility,
        onCheckDuplicateFacility,
        onToggleExtraServePay,
        onResetCurrentContFacilities,
      }}
    >
      {children}
    </ConractFacilitiesContext.Provider>
  );
};

export { ConractFacilitiesContext, ContractFacilitiesProvider };
