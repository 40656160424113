import { Form, Modal, Row } from 'antd';

import useContractFacilitiesContext from 'features/Contract/hooks/useContractFacilitiesContext';

import ModalFooter from 'components/ModalFooter';
import ContractFacilitiesDropdown from '../ContractFacilitiesDropdown';
import ContractServiceDropdown from '../ContractServiceDropdown';

import { formLayout } from 'constants/form';
import ContServePayForm from 'features/Contract/components/ContServePayForm';

const ContractFacilityModal = () => {
  const {
    isFacilityModalOpen,
    isAddExtraServePay,
    isDisabled,
    facilityModalFormRef,
    onCloseContractFacilityModal,
    onSubmitContractFacility,
  } = useContractFacilitiesContext();

  return (
    <Modal
      title="สิ่งอำนวยความสะดวก"
      width="75%"
      maskClosable={false}
      open={isFacilityModalOpen}
      onCancel={() => onCloseContractFacilityModal()}
      footer={
        <ModalFooter
          handleCancel={onCloseContractFacilityModal}
          onSubmitForm={() => facilityModalFormRef.submit()}
          isDisableConfirmButton={isDisabled}
        />
      }
    >
      <Form
        {...formLayout}
        labelWrap
        form={facilityModalFormRef}
        name="facilityForm"
        size="large"
        labelAlign="left"
        disabled={isDisabled}
        onFinish={onSubmitContractFacility}
      >
        <Row gutter={[16, 16]} align="middle">
          <ContractFacilitiesDropdown />
          <ContractServiceDropdown />
          {isAddExtraServePay && <ContServePayForm />}
        </Row>
      </Form>
    </Modal>
  );
};

export default ContractFacilityModal;
