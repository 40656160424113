import { APIS, HOST } from 'constants/apis';
import axiosInstance from 'utils/axios';

export const createRao = () => {
  return `/api/access-offer/create`;
};
export const updateRao = (id: number | string) => {
  return `/api/access-offer/${id}/update`;
};
export const improveRao = (id: number | string) => {
  return `/api/access-offer/${id}/improve`;
};
export const getRaoByid = (id: number | string) => {
  return `/api/access-offer/${id}`;
};
export const listRao = (data: {
  pageSize: number;
  currentPageNumber: number;
  taxId: string;
  offerType: string;
}) => {
  return axiosInstance.get(
    `/offer?pageSize=${data.pageSize}&currentPageNumber=${data.currentPageNumber}&taxId=${data.taxId}&offerType=${data.offerType}&referenceNo=${data.taxId}`
  );
};

export async function downloadFileRao(id: number, nameFile: string) {
  return await fetch(
    `${HOST.url_ICDS_APP + HOST.api_context}/offer/attachment/${id}/download`
  ).then((response: any) => {
    response.blob().then((blob: any) => {
      let url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', nameFile);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
    return response;
  });
}
export const comfirmDoc = (val: any, id: number) => {
  return axiosInstance.put(`/offer/${id}/operator/confirm`, {
    ...val,
  });
};
export async function updateRaoAccessOffer(dataValue: any, raoId: string | undefined) {
  return axiosInstance.put(`/offer/${raoId}/update`, {
    ...dataValue,
  });
}
export default async function uploadFileRAO(
  data: {
    meta: {
      attachFileId: number | null;
      attachFileName: String;
      docId: number;
      docName: String;
      docType: String;
      required: Boolean;
    };
    file: File;
  },
  raoId: string | undefined
) {
  const formData = new FormData();
  const jsonString = JSON.stringify(data.meta);
  const blob = new Blob([jsonString], {
    type: 'application/json',
  });
  formData.append('metaData', blob);
  formData.append('attachment', data.file);
  return axiosInstance.post(`/offer/${raoId}/attachment`, formData);
}
export async function downloadRaoAccessOffer(id: number | undefined, fileName: string) {
  try {
    // const res = await axiosInstance
    //   .get(APIS.downloadRaoDoc + `${id}/print-offer`)
    //   .then(function (response) {
    //     const href = URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement('a');
    //     link.href = href;
    //     link.setAttribute('download', fileName);
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    //   })
    //   .catch(function (response) {
    //     return response;
    //   });

    // return res;
    let _data = fetch(HOST.url_ICDS_APP + APIS.downloadRaoDoc + `${id}/print-offer`)
      .then((response) => {
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${fileName}.docx`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
        return response;
      })
      .catch(function (response) {
        return response;
      });
    return _data;
  } catch (error) {
    console.log({ error });
  }
}
export async function createRaoAccessOffer(dataValue: any) {
  return axiosInstance.post('/offer/create', { ...dataValue });
}
export async function getServiceAnnounceRate() {
  return axiosInstance.get('/offer/RIO/serviceAnnounceRate');
}
export async function getRaoFacilities() {
  return axiosInstance.get('/master/facilities');
}
export async function deleteFileRao(id: number) {
  return axiosInstance.delete(`/offer/attachment/${id}/delete`);
}
export async function improveRao_(val: any, id: number) {
  return axiosInstance.put(`/offer/${id}/improve`, { ...val });
}
export async function getListDocument(type: string) {
  return axiosInstance.get(`/master/doc/doc-type/${type}`);
}
export const offerUnit = (Id: number) => {
  return axiosInstance.get(`/master/offer-unit?raoServId=${Id}`);
};
export const RaoId = (Id: number) => {
  return axiosInstance.get(`/offer/${Id}`);
};
export const Currencies = () => {
  return axiosInstance.get(`/master/currencies`);
};
export const listCT = (pageSize: number, currentPageNumber: number) => {
  return `/offer-forms?currentPageNumber=${currentPageNumber}&pageSize=${pageSize}`;
};
export const downloadRaoDoc = (id: number | string) => {
  return `/api/offer/${id}/print-offer`;
};
export const uploadRaoFile = (id: number | string) => {
  return `/api/access-offer/${id}/attachment`;
};
export const downloadRaoFile = (id: number | string) => {
  return `/api/access-offer/attachment/${id}/download`;
};
export const deleteRaoFile = (id: number | string) => {
  return `/api/access-offer/attachment/${id}/delete`;
};
export const createOfferHistory = (id: number | string) => {
  return `/api/access-offer/${id}/createOfferHistory`;
};
export async function accessOffer(id: number | undefined) {
  return axiosInstance.patch(`/offer/${id}/changeOfferFormStatusToWaitFile`);
}
