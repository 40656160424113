import { Space } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

import PrimaryButton from 'components/PrimaryButton';

type ModalFooterProps = {
  handleCancel: Function;
  onSubmitForm: Function;
  confirmLabel?: string;
  isDisableConfirmButton?: boolean;
};

const ModalFooter = ({
  handleCancel,
  onSubmitForm,
  confirmLabel,
  isDisableConfirmButton = false,
}: ModalFooterProps) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Space>
        <PrimaryButton
          icon={<SaveOutlined />}
          onClick={() => onSubmitForm()}
          disabled={isDisableConfirmButton}
        >
          {confirmLabel ? confirmLabel : 'ยืนยัน'}
        </PrimaryButton>
        <PrimaryButton icon={<CloseOutlined />} onClick={() => handleCancel()}>
          ยกเลิก
        </PrimaryButton>
      </Space>
    </div>
  );
};

export default ModalFooter;
