/* eslint-disable */

import { ColumnsType } from 'antd/es/table';
import { Button, Col, Form, Row, Table, Upload, UploadProps } from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { useApi } from 'hooks/useApi';
import { deleteRawReportFile, downloadRawReportFile, uploadRawReportFile } from 'api/usageForm';
import { RawReportAttachFile } from 'api/usageForm/interface';
import { UploadFile } from 'antd/es/upload';

interface DataType {
  key: React.Key;
  id: number;
  type: string;
  formStatus: number;
  attachFile: string;
  uploadFile: JSX.Element;
  onDownloadClick: () => void;
  onDeleteClick: () => void;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ดาวน์โหลดเอกสาร',
    dataIndex: 'id',
    width: '180px',
    align: 'center',
    render: (_, record) => <div>
      <Button disabled={!record.attachFile} onClick={record.onDownloadClick}>
        <DownloadOutlined />
      </Button>
    </div>,
  },
  {
    title: 'กลุ่มเอกสาร',
    dataIndex: 'type',
  },
  {
    title: 'รายการเอกสาร',
    dataIndex: 'attachFile',
  },
];

interface FormFileUploadProp {
  id: number;
  status: number;
  files: RawReportAttachFile[];
}

const FormFileUploadWithOutDropDown = ({
                                         id,
                                         status,
                                         files,
                                       }: FormFileUploadProp): JSX.Element => {
  const [form] = useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { makeRequest: UploadFile } = useApi();
  const { makeRequest: DownloadAttachFileList } = useApi();
  const { makeRequest: DeleteAttachFileList } = useApi();
  const [data, setData] = useState<DataType[]>([]);
  const [uploading, setUploading] = useState(false);


  const uploadFile = async (file: any, reportTypeId: number) => {
    UploadFile(async () => {
      setUploading(true);
      const formData = new FormData();
      formData.append('attachment', file);
      const res = await uploadRawReportFile(id, reportTypeId, formData).catch(() => setUploading(false));
      setUploading(false);
      return res;
    });
  };

  const getProps = (reportTypeId: number): UploadProps => {
    return {
      beforeUpload: (file) => {
        setFileList([file]);
        return false;
      },
      onChange: (file) => {
        uploadFile(file.file, reportTypeId);
      },
      showUploadList: false,
      fileList,
    };
  };


  useEffect(() => {
    setData(files.map(i => {
      return {
        key: i.reportTypeId,
        id: i.reportTypeId,
        formStatus: status,
        type: i.reportTypeName,
        attachFile: i.attachFileName,
        uploadFile: <Upload accept={'.pdf'} {...getProps(i.reportTypeId)} maxCount={1}>
          <Button
            style={{ width: '60px' }}
            disabled={status === 2 || status === 4}
            icon={<UploadOutlined />}
            loading={uploading}
          >
          </Button>
        </Upload>,
        onDeleteClick: async () => {
          DeleteAttachFileList(async () => {
            const res = await deleteRawReportFile(i.rawReportAttachFileId);
            return res;
          });
        },
        onDownloadClick: async () => {
          DownloadAttachFileList(async () => {
            const res = await downloadRawReportFile(i.rawReportAttachFileId);
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', i.attachFileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return res;
          });
        },
      };
    }));
  }, [files, status]);

  return <div style={{
    marginBottom: 24,
  }}>
    <div style={{ marginBottom: 16 }}><h2>แบบแจ้งข้อมูล</h2></div>
    <Form form={form}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Table columns={columns} dataSource={data} pagination={false} />
        </Col>
      </Row>
    </Form>
  </div>;
};

export default FormFileUploadWithOutDropDown;
