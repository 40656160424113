import { Space } from 'antd';
import { IStepDescription } from './interfaces';
import { dayJsformatDateToBCYear } from 'utils/date';

interface IStepWithDate extends IStepDescription {
  dateLabel?: string;
}

const StepWithDate = ({ stepData, dateLabel }: IStepWithDate) => {
  return (
    <div>
      <Space>
        <label>{dateLabel ? dateLabel : 'วันที่รับเรื่อง'} :</label>
        <span>
          {stepData?.actionDate ? dayJsformatDateToBCYear(stepData?.actionDate) : '-'}
        </span>
      </Space>
    </div>
  );
};

export default StepWithDate;
