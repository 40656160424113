import { FormInstance } from 'antd';

import { IContServesData } from '../interfaces';
import {
  OFFER_TYPE_RAO,
  OFFER_TYPE_RDRO,
  OFFER_TYPE_RIO,
  OFFER_TYPE_RISO,
} from 'constants/offerFormType';

type useValidationProps = {
  formRef: FormInstance<any>;
  offerFormType: string;
};

export type ValidateContractPageProps = {
  currentContServes: IContServesData[];
};

const useValidation = ({ formRef, offerFormType }: useValidationProps) => {
  const validateContractPage = async ({ currentContServes }: ValidateContractPageProps) => {
    switch (offerFormType) {
      case OFFER_TYPE_RAO:
      case OFFER_TYPE_RIO:
      case OFFER_TYPE_RDRO:
      case OFFER_TYPE_RISO:
        await validateRISOForm({ currentContServes });
        break;
      default:
        await validateRISOForm({ currentContServes });
        break;
    }
  };

  const validateRISOForm = async ({ currentContServes }: ValidateContractPageProps) => {
    await validateContractForm(formRef);
    await validateServiceAtleastOne(currentContServes);
    await validateServiceTableForm(currentContServes);
  };

  const validateContractForm = async (formRef: FormInstance<any>) => {
    try {
      await formRef.validateFields();
    } catch (errorInfo) {
      throw new Error('กรุณากรอกฟอร์มให้ครบ');
    }
  };

  const validateServiceAtleastOne = async (currentContServes: IContServesData[]) => {
    // NOTE: Must have at least one service
    if (currentContServes.length === 0) throw new Error('ต้องมีบริการอย่างน้อย 1 บริการ');
  };

  const validateServiceTableForm = async (currentContServes: IContServesData[]) => {
    // NOTE: All service must be selected
    if (currentContServes.length > 0) {
      const isOk = currentContServes.findIndex((item) => item.servId === undefined);
      if (isOk !== -1) throw new Error('กรุณาเลือกบริการก่อน');
    }
  };

  return { validateContractPage, validateServiceAtleastOne };
};

export default useValidation;
