import { addHours } from "date-fns"
import { ILocalStore } from "../types/stores"

const getstore = <T = any>(key: string): T | null => {
    const storeditem = localStorage.getItem(key)
    if (!storeditem) { return null }
    const { data } = JSON.parse(storeditem)
    return data
}

const getstoreValidation = <T = any>(key: string): T | null => {
    const storeditem = localStorage.getItem(key)
    if (!storeditem) { return null }
    const { data, expire } = JSON.parse(storeditem) as ILocalStore<T>
    const currentTime = Date.now()
    if (expire <= currentTime) { return null }
    return data
}

const makePersistable = (key: string, data: any, expireHours = 24) => {
    const expireTime = addHours(new Date(), expireHours).getTime()
    const storeItems: ILocalStore = { data, expire: expireTime }
    const storage = JSON.stringify(storeItems)
    localStorage.setItem(key, storage)
}

const removestore = (key: string) => {
    localStorage.removeItem(key)
}

export {
    makePersistable,
    getstore,
    getstoreValidation,
    removestore,
}