import { Col, Row } from 'antd';

import ContractFacilitiesTable from './components/ContractFacilitiesTable';
import FacilitiesActionButtons from './components/FacilitiesActionButtons';
import ContractFacilityModal from './components/ContractFacilityModal';

const ContractFacilities = () => {
  return (
    <>
      <ContractFacilityModal />
      <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
        <Col span={24}>
          <FacilitiesActionButtons />
        </Col>
        <Col span={24}>
          <ContractFacilitiesTable />
        </Col>
      </Row>
    </>
  );
};

export default ContractFacilities;
