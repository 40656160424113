import axiosInstance from 'utils/axios';
import { ListOfferPublishUrlQuery, OfferPublicUrlData } from './interface';

export const listOfferPublishUrl = (query: ListOfferPublishUrlQuery) => {
  return axiosInstance.get('offer/view-public-url', {
    params: query,
  });
};

export const updateOfferPublicUrl = (data: OfferPublicUrlData) => {
  return axiosInstance.put('access-offer/updateLink', data);
};
