import { Table } from 'antd';
import useAuth from 'hooks/useAuth';
import { useLocation } from 'react-router-dom';

export default function ListManageRaoTable(
  columnsFunction: any,
  onModalWeb: any,
  modalFile: any,
  navigate: any,
  lestRoa: any,
  setLoad: any,
  getData: any,
  listRao: any,
  key: any,
  onModalOpenHistory: (id: number | string) => void
) {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const tableJXE = (
    <Table
      columns={columnsFunction(onModalWeb, modalFile, key, pathname, navigate, onModalOpenHistory)}
      dataSource={lestRoa && lestRoa.data}
      // scroll={{ x: 'calc(700px + 100%)', y: 600 }}
      size="small"
      pagination={{
        onChange: async (e, size) => {
          setLoad(true);
          getData(size, e - 1, user?.referenceNo, setLoad);

          // await getListROA(
          //   listRao(size, e - 1, 9000000000002, key),
          //   {},
          //   'http://143.198.197.17/'
          // ).finally(() => {
          //   setLoad(false);
          // });
        },
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        total: lestRoa && lestRoa?.pageable?.totalItems,
        defaultPageSize: 5,
        defaultCurrent: 1,
        pageSizeOptions: [5, 10, 20, 50, 100],
      }}
    />
  );
  return tableJXE;
}
