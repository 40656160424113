import { SearchOutlined, ClearOutlined } from '@ant-design/icons';
import { Button, Col, Form, FormInstance, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import buttonCSS from 'styles/components/button.module.css';
// import { offerType } from './options';
import { ThaiDatepickerComponent } from 'components';
import useDialogAlert from 'hooks/useDialogAlert';
import OfferTypeDropdown from 'features/OfferConsideration/components/OfferTypeDropdown';

type Props = {
  form: FormInstance<any>;
  onFinishSearch: (values: any) => void;
  onFinishFailedSearch: (errorInfo: any) => void;

  operatorList:
    | {
        value: any;
        label: any;
      }[]
    | undefined;
  inquiryStatus: {
    status: string;
    statusName: string;
  }[];
};

const FormSearch = (props: Props) => {
  const { form, onFinishFailedSearch, onFinishSearch, operatorList, inquiryStatus } = props;
  const [valueDate, setValueDate] = useState<any>();
  const [valueDate2, setValueDate2] = useState<any>();
  const { error } = useDialogAlert();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  useEffect(() => {
    if (form.getFieldValue('offerFormStartDate') && form.getFieldValue('offerFormEndDate')) {
      if (
        form.getFieldValue('offerFormEndDate').format('DD MM YYYY') <
        form.getFieldValue('offerFormStartDate').format('DD MM YYYY')
      ) {
        form.setFieldsValue({
          offerFormEndDate: form.getFieldValue('offerFormStartDate'),
        });
        error({
          header: 'วันที่อนุมัติถึง ต้องมากกว่า วันที่อนุมัติตั้งแต่',
          sub: '',
        });
      }
    }
  }, [valueDate, valueDate2, error, form]);
  return (
    <Form
      name="basic"
      form={form}
      initialValues={{ remember: true }}
      onFinish={onFinishSearch}
      onFinishFailed={onFinishFailedSearch}
      autoComplete="off"
      requiredMark={false}
      {...layout}
      labelWrap
      size="large"
    >
      <div className="content-box">
        <Row gutter={[16, 16]}>
          <Col span={12}>
            {/* <Form.Item colon labelAlign="right" label="ประเภทข้อเสนอ" name="offerType">
              <Select
                placeholder="-- กรุณาระบุ --"
                showSearch
                allowClear
                options={offerType}
                filterOption={(input, option) =>
                  (option?.label?.toString().toLowerCase() ?? '').includes(input)
                }
              />
            </Form.Item> */}
            <OfferTypeDropdown fieldName="offerType" />
          </Col>
          <Col span={12}>
            <Form.Item colon labelAlign="right" label="ผู้ประกอบการ" name="client">
              <Select
                placeholder="-- กรุณาระบุ --"
                filterOption={(input, option) =>
                  (option?.label?.toString().toLowerCase() ?? '').includes(input)
                }
                showSearch
                allowClear
                options={operatorList}
              />
            </Form.Item>
          </Col>

          {/* <Col span={12}></Col> */}
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่อนุมัติตั้งแต่"
              name="offerFormStartDate"
              rules={[
                {
                  required: false,
                  message: 'วันที่อนุมัติตั้งแต่',
                },
              ]}
            >
              <ThaiDatepickerComponent
                key={'offerFormStartDate'}
                onChange={(e: any) => {
                  setValueDate(e);
                  form.setFieldsValue({ offerFormStartDate: e, offerFormEndDate: e });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="วันที่อนุมัติถึง"
              name="offerFormEndDate"
              rules={[
                {
                  required: false,
                  message: 'กรุณากรอกวันที่อนุมัติถึง',
                },
              ]}
            >
              <ThaiDatepickerComponent
                key={'offerFormEndDate'}
                onChange={(e: any) => {
                  form.setFieldsValue({
                    offerFormEndDate: e,
                  });
                  setValueDate2(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              colon
              labelAlign="right"
              label="สถานะข้อเสนอ"
              name="offerInquiryStatus"
              rules={[{ required: false }]}
            >
              <Select
                placeholder="-- กรุณาระบุ --"
                filterOption={(input, option) =>
                  (option?.label?.toString().toLowerCase() ?? '').includes(input)
                }
                showSearch
                allowClear
                options={
                  inquiryStatus &&
                  inquiryStatus.map((val) => {
                    return { label: val.statusName, value: val.status };
                  })
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 15 }}>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<SearchOutlined />}
              size="large"
              htmlType="submit"
            >
              ค้นหา
            </Button>
          </Col>
          <Col>
            <Button
              className={buttonCSS.primary}
              type="primary"
              shape="round"
              icon={<ClearOutlined />}
              size="large"
              htmlType="reset"
            >
              ล้างเงื่อนไข
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default FormSearch;
