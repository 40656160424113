import { Form, Row, Col, Input } from 'antd';
import { useEffect } from 'react';

import { getJSDateObj } from 'utils/date';
import { formLayout } from 'constants/form';
import { ThaiDatepickerComponent } from 'components';

import { IStepModalForm } from './interfaces';

interface IStepFormWithApprovedDate extends IStepModalForm {
  label?: string;
}

const StepFormWithApprovedDate = ({
  form,
  stepData,
  isDisable,
  label,
}: IStepFormWithApprovedDate) => {
  const onFinish = (_: any) => {
    // let data: any = {
    //   // formStateId: stepData?.formStateId,
    //   // stateName: stepData?.stateName,
    //   stateCode: stepData?.stateCode,
    //   actionCode: '',
    //   // actionName: '',
    //   approvedNo: '',
    //   approvedDate: null,
    //   isCompleteState: false,
    //   isCurrentState: true,
    // };
    // onUpdateOfferState(data);
  };

  useEffect(() => {
    if (stepData) {
      let _stepData: any = { ...stepData };
      _stepData.approvedDate = stepData.approvedDate
        ? getJSDateObj(stepData.approvedDate)
        : new Date();
      form.setFieldsValue({
        ..._stepData,
      });
    }
  }, [form, stepData]);

  return (
    <Form
      {...formLayout}
      labelWrap
      labelAlign="left"
      name="stepModalForm"
      size="large"
      form={form}
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item colon label="สถานะสัญญา" name="stateName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            colon
            label={label ? label : 'วันที่ ชท. ได้รับเรื่องสัญญาฯ'}
            name="approvedDate"
            rules={[
              { required: true, message: `กรุณาเลือก${label ?? 'วันที่ ชท. ได้รับเรื่องสัญญาฯ'}` },
            ]}
          >
            <ThaiDatepickerComponent disabled={isDisable} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default StepFormWithApprovedDate;
