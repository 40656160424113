import { convertToMonths, dayJsformatDateObjToString } from 'utils/date';
import { IContServesData, IFacilitiesType } from '../interfaces';

interface IComposeSaveData {
  formData: any;
  currentContServes: IContServesData[];
  currentContFacilities: IFacilitiesType[];
}

interface IComposeCreateData extends IComposeSaveData {
  offerFormType: string;
  ownerId: string | null;
}

export const composeSaveData = ({
  formData,
  currentContServes,
  currentContFacilities,
}: IComposeSaveData) => {
  /**
   * NOTE:
   * 1. Handle Date Format
   * 2. Handle ระยะเวลาสัญญา ต้องแปลงกลับเป็นจำนวนเดือน
   * 3. Assign new contServes
   */
  let requestData = { ...formData };

  requestData.extendContract = requestData.extendContract ? true : false;
  requestData.formDate = requestData.formDate
    ? dayJsformatDateObjToString(new Date(requestData.formDate))
    : null;
  requestData.contractDate = requestData.contractDate
    ? dayJsformatDateObjToString(new Date(requestData.contractDate))
    : null;
  requestData.contractStartDate = requestData.contractStartDate
    ? dayJsformatDateObjToString(new Date(requestData.contractStartDate))
    : null;
  requestData.contractEndDate = requestData.contractEndDate
    ? dayJsformatDateObjToString(new Date(requestData.contractEndDate))
    : null;
  requestData.signedDate = requestData.signedDate
    ? dayJsformatDateObjToString(new Date(requestData.signedDate))
    : null;
  requestData.contractPeriod = convertToMonths(requestData.periodYears, requestData.periodMonths);
  if (formData.ownerId?.key) {
    requestData.ownerId = formData.ownerId.value;
  }
  if (formData.selectedRenter?.key) {
    requestData.renter = {
      operatorsId: formData.selectedRenter?.key,
      operName: formData.selectedRenter?.label,
      taxId: formData.selectedRenter?.value,
    };
  } else {
    requestData.renter = {
      operatorsId: formData.renter?.operatorsId,
      operName: formData.renter?.operName,
      taxId: formData.renter?.taxId,
    };
  }
  requestData.contServes = currentContServes;
  requestData.contFacilities = currentContFacilities;

  return requestData;
};

export const composeCreateData = ({
  formData,
  offerFormType,
  ownerId,
  currentContServes,
  currentContFacilities,
}: IComposeCreateData) => {
  const _data = composeSaveData({
    formData,
    currentContServes,
    currentContFacilities,
  });
  _data.ownerId = ownerId;
  _data.offerFormType = offerFormType;
  return _data;
};
